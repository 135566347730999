import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useIcEditorStore } from 'components/item-editor/instructional-content/store';
import { useCreateICVideoAnnotation } from 'hooks/useCreateICVideoAnnotation';
import { IItem } from 'models/item';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { TAssignment } from 'types';

interface Props {
  item: IItem;
  assignment: TAssignment;
}

export const AddVideoAnnotationButton = (props: Props) => {
  const { item, assignment } = props;

  const { addIcAnnotation, setIsCreatingAnnotation } = useIcEditorStore();

  const [createICVideoAnnotation] = useCreateICVideoAnnotation(item.id, assignment.id);

  const videoFileInputRef = useRef<HTMLInputElement>(null);

  const useStyles = makeStyles((theme) => ({
    annotationButton: {
      paddingHorizontal: theme.spacing(4),
      paddingVertical: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  const handleAddVideoClick = () => {
    if (videoFileInputRef.current) {
      videoFileInputRef.current.click();
    }
  };

  const handleVideoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCreatingAnnotation(true);

    try {
      const file = event.target.files?.[0];
      if (file) {
        const newAnnotation = await createICVideoAnnotation({ itemId: item.id, video: file });
        if (!newAnnotation) {
          console.warn('No new video annotation was created');
          return;
        }

        addIcAnnotation(newAnnotation);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreatingAnnotation(false);
    }
  };

  return (
    <>
      <Button
        className={classes.annotationButton}
        variant="outlined"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faVideo} />}
        onClick={handleAddVideoClick}
      >
        Video
      </Button>
      <FileInput ref={videoFileInputRef} type="file" accept="video/*" onChange={handleVideoUpload} />
    </>
  );
};

const FileInput = styled.input`
  display: none;
`;
