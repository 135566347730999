import LoadingComponent from 'components/LoadingComponent';
import { ContentSection, LightDividingLine } from 'core/layout';
import * as React from 'react';
import styled from 'styled-components';

const Article = styled.article`
  margin: 1.5rem 0 3rem;
  text-align: center;
`;

const ArticleTitle = styled.h3`
  font-size: 1em;
  display: inline;
  padding: 0.15em 0;
  margin: 0;
`;

const ArticleIcon = styled.img`
  width: 65px;
  height: 65px;
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0.5em;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.15);
`;

interface IOwnProps {
  iconImage?: string;
  showIcon: boolean;
  segmentNav?: any;
  title?: string;
  isLoading?: boolean;
  children?: any;
}

export default class ArticleContainer extends React.PureComponent<IOwnProps> {
  public render() {
    const { title, iconImage, showIcon = true, children, segmentNav = null, isLoading = false } = this.props;
    const placeholderImage =
      iconImage && showIcon ? <ArticleIcon src={iconImage} style={{ marginTop: '3em' }} /> : null;

    return (
      <Article>
        <ContentSection>
          <div style={{ marginBottom: '1em', textAlign: 'left' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ArticleTitle>{title}</ArticleTitle>
              {segmentNav}
            </div>
            <LightDividingLine />
          </div>
        </ContentSection>

        <LoadingComponent
          isLoading={isLoading}
          component={
            <ContentSection>
              {placeholderImage}
              {children}
            </ContentSection>
          }
        />
      </Article>
    );
  }
}
