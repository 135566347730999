import { CALL_API } from 'middleware/api';

export const handleGetPaymentMethod = (partnerId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v4/partners/${partnerId}/payment_methods`,
      httpMethod: 'GET'
    }
  };
};
