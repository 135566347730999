import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  position: relative;
  height: 250px;
`;

const LoadingSpinner = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

interface ILoadingComponent {
  component?: any;
  isLoading: boolean;
}

const LoadingComponent = (props: ILoadingComponent) => {
  if (props.isLoading) {
    return (
      <SpinnerWrapper>
        <LoadingSpinner />
      </SpinnerWrapper>
    );
  }

  return <>{props.component}</>;
};

export default LoadingComponent;
