import { CALL_API } from 'middleware/api';
import { IAccount } from 'models/account';

export const updateAccount = (accountData: IAccount, partnerId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v4/partners/${partnerId}/accounts`,
      httpMethod: 'PUT',
      data: accountData
    }
  };
};
