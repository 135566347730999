import { useUserRole } from 'hooks/useUserRole';
import React from 'react';
import { UserRole } from 'types';

interface Props {
  allowedRoles: Array<UserRole>;
  component: any;
}

export const Restricted = (props: Props) => {
  const { allowedRoles, component } = props;
  const { role, isSiteAdmin } = useUserRole();

  if (allowedRoles.includes(role) || isSiteAdmin) {
    return component;
  }

  return <></>;
};
