import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMutatePartner } from 'hooks/useMutatePartner';
import { debounce } from 'lodash';
import useStore from 'zstore';

export const useDebouncedPartnerUpdate = (debounceMs: number) => {
  const user = useCurrentUser();
  const [mutatePartner] = useMutatePartner();
  const { makeToast } = useStore();

  return debounce(async (payload: any) => {
    try {
      mutatePartner({
        id: user?.primaryPartner?.id,
        payload
      })
      makeToast({
        text: 'Changes saved successfully!',
        isError: false
      });
    } catch (error: any) {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  }, debounceMs);
}
