import { devlog, immer } from 'zstore';
import create from 'zustand';
import { IItem } from 'models/item';
import { TAssignment } from 'types';

type ItemEditorState = {
  isShowingItemEditor: boolean;
  showItemEditor: () => void;
  hideItemEditor: () => void;
  activeItem: IItem | null;
  setActiveItem: (item: IItem | null) => void;
  updateActiveItem: (updatedFields: Partial<IItem>) => void;
  activeAssignment: TAssignment | null;
  setActiveAssignment: (assignment: TAssignment | null) => void;
};

const makeItemEditorStore = () => {
  return create<ItemEditorState>(
    devlog(
      immer((set: any) => ({
        isShowingItemEditor: false,
        showItemEditor: () => set(() => ({ isShowingItemEditor: true })),
        hideItemEditor: () => set(() => ({ isShowingItemEditor: false })),
        activeItem: null,
        setActiveItem: (item: IItem | null) => set(() => ({ activeItem: item })),
        updateActiveItem: (updatedFields: Partial<IItem>) =>
          set((state: ItemEditorState) => {
            const newActiveItem = { ...state.activeItem, ...updatedFields };
            if (updatedFields.facets) {
              newActiveItem.facets = updatedFields.facets.map((facet) => ({
                ...facet,
                anchor: facet.anchor ? { ...facet.anchor } : null,
              }));
            }
            return { activeItem: newActiveItem };
          }),
        activeAssignment: null,
        setActiveAssignment: (assignment: TAssignment | null) => set(() => ({ activeAssignment: assignment })),
      }))
    )
  );
};

export const useItemEditorStore = makeItemEditorStore();
