import axios from 'axios';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ModalPosition } from 'components/Modal';
import { ModalHeader } from 'components/modals/common';
import { Card, CardHeaderContainer, CardSubHeader, PageTitle } from 'core/layout';
import { ErrorMessage } from 'core/typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { REMOVE_LEARNER_STATS } from 'store/learner-stats';
import styled from 'styled-components';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const ErrorContainer = styled.div`
  margin-top: 12px;
`;

interface IProps {
  courseId: string;
  close: () => void;
  learnerCount: number;
}

export const RemoveLearners = React.memo<IProps>((props) => {
  const { t } = useTranslation();
  const { courseId, learnerCount } = props;
  const [confirmIsPending, setConfirmIsPending] = useState(false);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmClick = async () => {
    setConfirmIsPending(true);
    try {
      await axios.delete(`/api/v3/courses/${courseId}/users/students`);
      dispatch({ type: REMOVE_LEARNER_STATS, courseId });
      setConfirmIsPending(false);
      props.close();
    } catch (err) {
      setConfirmIsPending(false);
      setHasError(true);
    }
  };

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>{t('Remove All Learners')}</CenterTitle>
      </ModalHeader>
      <Card>
        <CardHeaderContainer>
          {<CardSubHeader>{t('Remove Learners Text', { count: learnerCount })}</CardSubHeader>}
        </CardHeaderContainer>
      </Card>
      {
        <ConfirmOrCancel
          cancelText={t('Cancel')}
          confirmText={t('Remove All Learners')}
          handleCancelClick={props.close}
          handleConfirmClick={handleConfirmClick}
          confirmIsPending={confirmIsPending}
          confirmIsDisabled={false}
          destructive={true}
        />
      }
      {hasError && (
        <ErrorContainer>
          <ErrorMessage>{t('Oops! Something went wrong. Please try again.')}</ErrorMessage>
        </ErrorContainer>
      )}
    </ModalPosition>
  );
});
