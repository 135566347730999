export const defaultCardWidth = 710;
export const wideCardWidth = 900;
export const iPadWidth = 768;
export const phoneWidth = 480;

export const reportWidth = 1144;
export const reportControlTextWidth = 750;
export const reportBreakpoint = 600;
export const reportPageWidth = 1395;
export const sideNavWidth = 200;

export const defaultBreakpoint = sideNavWidth + wideCardWidth;
