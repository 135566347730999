import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getCourse = async (_key: string, courseId: string) => {
  const response = await axios.get(`/api/v3/courses/${courseId}?include=partner,partner.image`);
  return normalizeJSONAPIResponse(response.data);
};

export const useCourse = (courseId: string) => {
  return useQuery(['course', courseId], getCourse, {
    enabled: courseId,
  });
};
