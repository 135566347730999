import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { sleep } from 'utils/timeUtils';

const MAX_FETCHES: number = 30;

const cloneSet = async ({ id, payload }) => {
  let cloneResponse = await axios.post(`/api/v3/sets/${id}/set_clones`, payload);
  let fetches: number = 0;
  while (cloneResponse.data.status !== 'completed' && fetches <= MAX_FETCHES) {
    await sleep(2000);
    cloneResponse = await axios.get(`/api/v3/set_clones/${cloneResponse.data.id}`);
    fetches += 1;
  }
  return cloneResponse;
};

const useCloneSet = () => {
  return useMutation(cloneSet, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
    },
    onSettled: data => {
      queryCache.invalidateQueries('assignments');
    }
  });
};

export default useCloneSet;
