// Utility functions for string formatting and substitution
// Mostly used by FillInTheBlankAnswer.tsx component

import SubstitutionDict from 'utils/FillInTheBlankSubstitutionDictionary.json';
import getStringDistance from 'utils/getStringDistance';

const strippedText = (text: string): string => {
  const to = /\sto\s/g;
  const parens = /(\(|\)|\s)/g;
  const leadingAndTrailingNonWordCharacters = /^[^\u00BF-\u1FFF\u2C00-\uD7FF\w]+|[^\u00BF-\u1FFF\u2C00-\uD7FF\w]+$/gi;
  return text
    .replace(to, '-')
    .replace(parens, '')
    .replace(leadingAndTrailingNonWordCharacters, '');
};

class StringSubstitutions {
  public sourceObj: object;
  public mapping: Map<string, string>;
  public constructor(substitutions: object) {
    this.sourceObj = substitutions;
    if (this.isFlatObject()) {
      const entries = Object.entries(substitutions).sort((a, b) => b[0].length - a[0].length);
      this.mapping = new Map(entries);
    } else {
      throw new Error('Object too deep!');
    }
  }
  private isFlatObject(): boolean {
    for (const key in this.sourceObj) {
      if (!this.sourceObj.hasOwnProperty(key)) {
        continue;
      }
      if (typeof this.sourceObj[key] == 'object') {
        return false;
      }
    }
    return true;
  }
}

export default class StringForComparison {
  private original: string;
  private substitutions: StringSubstitutions;
  private normalized: string;
  public constructor(str) {
    this.original = str;
    this.substitutions = new StringSubstitutions(SubstitutionDict);
    this.normalized = this.makeReplacements();
  }
  public isEquivalentInSomeFormTo(comparison: StringForComparison) {
    return (
      this.original === comparison.original ||
      this.formatted() === comparison.formatted() ||
      this.normalized === comparison.normalized
    );
  }
  public isPrettyCloseTo(comparison: StringForComparison) {
    return getStringDistance(this.formatted(), comparison.formatted()) / comparison.formatted().length <= 0.2;
  }
  private formatted(): string {
    return this.original.trim().toLowerCase();
  }
  private cleaned(): string {
    return strippedText(this.formatted());
  }
  private makeReplacements(): string {
    let newText = this.cleaned();
    this.substitutions.mapping.forEach((substitution, target) => {
      const re = new RegExp(strippedText(target), 'gi');
      newText = newText.replace(re, substitution);
    });
    return newText;
  }
}
