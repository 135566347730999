import { IUser } from 'models/user';
import { isDevelopment, isGovcloud } from 'utils/environmentUtils';
import { setUserId, setUserProperties, logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from './firebase';

// Note that in Firebase, in order to filter based on one of these properties,
// you must first add it as a "custom dimension" in the Firebase console.
// There is a **max of 50** custom dimensions you can have.
interface ITrackingAttributes {
  button_type?: string;
  edit_category?: string;
  filter_selected?: string;
  topic?: string;
}

export class AnalyticsService {
  private static instance: AnalyticsService;
  public static getInstance(): AnalyticsService {
    this.instance = this.instance || new AnalyticsService();
    return this.instance;
  }

  private enabled = false;

  public initialize(user: IUser) {
    if (!user?.primaryPartner) {
      return;
    }

    this.enabled = !isGovcloud() && !isDevelopment();

    if (this.enabled) {
      const role = this.getRoleForAnalytics(user);
      const traits = {
        partner: String(user.primaryPartner.id),
        stage: user.primaryPartner.stage,
        primary_partner_role: role,
        locale: user.locale,
      };

      setUserId(firebaseAnalytics, user.id);
      setUserProperties(firebaseAnalytics, traits);
    }
  }

  public track(eventName: string, attributes?: ITrackingAttributes) {
    if (this.enabled) {
      logEvent(firebaseAnalytics, eventName, attributes);
    }
  }

  private getRoleForAnalytics(user: IUser) {
    const partner: any = user.primaryPartner;
    if (user.meta.isAdmin) {
      return 'site admin';
    } else if (partner.meta?.role === 'admin') {
      return 'partner admin';
    } else {
      return partner.meta?.role || 'course learner';
    }
  }
}
