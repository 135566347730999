import { CALL_API } from 'middleware/api';

export const handleGetCourseGroups = courseId => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/courses/${courseId}/groups`,
      httpMethod: 'GET'
    }
  };
};
