import Box from '@mui/material/Box';
import * as colours from 'core/colors';
import React from 'react';

type Props = {
  children: React.ReactNode;
  flexDirection?: 'column' | 'row';
  sx?: React.CSSProperties;
};

export default function MemreSection({ children, flexDirection = 'column', sx = {} }: Props) {
  return (
    <Box
      sx={{
        border: `1px solid ${colours.veryLightGray}`,
        ...sx,
      }}
    >
      <Box sx={{ bgcolor: colours.white, display: 'flex', flexDirection }}>{children}</Box>
    </Box>
  );
}
