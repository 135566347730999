import React from 'react';
import styled from 'styled-components';
import { determineMenuOffset } from 'utils/menuUtils';

const RelativeContainer = styled.div`
  position: relative;
`;

interface IClickToEdit {
  id: string;
  column: string;
  click: (offset: number[], id: string, column: string) => void;
  children: any;
}

export const ClickToEdit = React.memo<IClickToEdit>((props) => {
  const { column, id, click, children } = props;

  const handleClick = (e: any): void => {
    const offset = determineMenuOffset(e, 20, 0);
    click(offset, id, column);
  };

  return (
    <RelativeContainer>
      <span style={{ cursor: 'pointer' }} onClick={handleClick}>
        {children}
      </span>
    </RelativeContainer>
  );
});
