import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from 'zstore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ceregoCurve from 'assets/images/cerego-curve.svg';
import Breadcrumb, { BreadcrumbRoutes, pathHasBreadcrumb } from 'components/Breadcrumb';
import { Color } from 'core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getBaseReactUrl } from 'utils/pathUtils';

export const navbarHeight: string = '3em';

const Container = styled.div`
  height: ${navbarHeight};
  line-height: ${navbarHeight};
  border-bottom: 1px solid ${Color.lightGray};
  z-index: 12;
  box-shadow: 0 3px 5px 0 rgba(235, 238, 242, 0.8);

  @media (max-width: 767px) {
    position: fixed;
    width: 100%;
  }
`;

const MobileLogo = styled.img`
  float: right;
  margin-top: 15px;
  margin-right: 15px;
  width: ${navbarHeight};
  cursor: pointer;
  display: none;

  @media (max-width: 767px) {
    display: inline-block;
  }
`;

const BurgerContainer = styled.div`
  width: ${navbarHeight};
  text-align: center;
  border-right: 1px solid ${Color.lightGray};
  color: ${Color.primaryBlue};
  cursor: pointer;
  display: none;

  @media (max-width: 767px) {
    display: inline-block;
  }
`;

interface IBurgerMenu {
  onClick: () => void;
  icon: any;
}

const BurgerMenu = (props: IBurgerMenu) => {
  return (
    <BurgerContainer onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} />
    </BurgerContainer>
  );
};

const goToDashboard = (): void => {
  window.location.href = `${getBaseReactUrl()}/app/nav/dashboard`;
};

interface INavbar {
  menuIcon?: any;
  onMenuClick?: any;
}

const Navbar = (props: INavbar) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const loc = useLocation();
  const hasBreadcrumb = pathHasBreadcrumb(loc.pathname);
  const { showModal } = useStore();

  const emptyDesktopNavbar = !isMobile && !hasBreadcrumb;

  if (emptyDesktopNavbar) return <></>;

  return (
    <Container
      id="navbar-container"
      aria-hidden={showModal ? 'true' : 'false'}
      style={showModal ? { display: 'none' } : { display: 'flex' }}
    >
      <BurgerMenu icon={props.menuIcon} onClick={props.onMenuClick} />
      <BreadcrumbRoutes navComponent={Breadcrumb} />
      <MobileLogo src={ceregoCurve} onClick={goToDashboard} />
    </Container>
  );
};

export default Navbar;
