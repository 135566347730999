import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

import SoundImg from 'assets/images/sound.svg';
import { Color } from 'core';
import { IButton } from 'core/button';

interface IProps extends IButton, WithTranslation {}

const PlaySoundImage = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 5px;
  align-self: center;
  margin-bottom: 5px;
`;
const PlaySoundText = styled.p`
  margin: 0;
  align-self: center;
`;
const SoundButtonObj = styled.button`
  justify-content: center;
  width: 125px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid ${Color.lightGray};
  background-color: white;
  font-size: 12px;
  color: ${Color.textGray};
  letter-spacing: 0.4px;
  padding-top: 5px;
  display: flex;
  :focus {
    box-shadow: 0 1px 4px 0 #4a90e2;
    outline: none;
  }
`;

class SoundButton extends React.Component<IProps> {
  public render() {
    const { type, t } = this.props;

    return (
      <SoundButtonObj type={type || 'button'} onClick={this.handleClick}>
        <PlaySoundImage src={SoundImg} />
        <PlaySoundText>{t('Play Audio')}</PlaySoundText>
      </SoundButtonObj>
    );
  }

  private handleClick = () => {
    this.props.click();
  };
}

export default withTranslation()(SoundButton);
