import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import circleCheck from 'assets/images/circle-check.svg';
import { Color } from 'core';
import styled from 'styled-components';
import { autohide } from 'utils/animations';
import useStore from 'zstore';

const CloseBox = styled.span`
  position: absolute;
  right: 20px;
  color: ${Color.lightGray};
  font-size: 1.5em;
  cursor: pointer;
`;

const ToastWrapper = styled.div`
  width: 400px;
  height: 70px;
  line-height: 70px;
  border-radius: 4px;
  padding-left: 10px;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.5);
  color: ${Color.lightGray};
  background-color: ${Color.primaryBlack};
  position: absolute;
  text-align: left;
  bottom: 40px;
  left: 240px;
  z-index: 11;
  animation-name: ${autohide};
  animation-duration: 0.01s;
  animation-delay: 6s;
  animation-fill-mode: forwards;
`;

const CircleCheck = styled.img`
  padding: 0px 10px;
  color: ${Color.successGreen};
  height: 25px;
  width: 25px;
  vertical-align: middle;
`;

const Toast = () => {
  const text: string = useStore((state) => state.toast);
  const isErrorToast = useStore((state) => state.isErrorToast);
  const removeToast = useStore((state) => state.clearToast);

  return (
    <>
      {text && (
        <ToastWrapper onAnimationEnd={removeToast}>
          <CloseBox onClick={removeToast}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseBox>
          {isErrorToast ? (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ padding: '0px 10px', color: Color.redText, fontSize: '25px', verticalAlign: 'middle' }}
            />
          ) : (
            <CircleCheck src={circleCheck} />
          )}
          {text}
        </ToastWrapper>
      )}
    </>
  );
};

export default Toast;
