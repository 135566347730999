import CircularProgress from '@material-ui/core/CircularProgress';
import SecondaryButton from 'components/buttons/v4/SecondaryButton';
import * as Color from 'core/colors';
import * as CommonButton from 'core/v4button';
import React from 'react';
import styled from 'styled-components';
import generateId from 'utils/generateId';

const ButtonElement = styled(SecondaryButton).attrs(
  ({ shape = 'rectangle', context = 'light', size = 'medium', disabled = false }: CommonButton.IButton) => {
    const defaultStyles: any = {
      borderColor: Color.red,
      color: Color.red,
      backgroundColor: Color.primaryGray,
    };
    const attributeStyles: any = {
      ...CommonButton.BaseStyles,
    };
    let stateStyles: any = {};

    return {
      ...defaultStyles,
      ...attributeStyles[shape],
      ...attributeStyles[context],
      ...attributeStyles[size],
      ...stateStyles,
    };
  }
)`
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.borderColor};
  color: ${(props) => props.color};
`;

interface Dimensions {
  height: number;
  width: number;
}

function DeleteButton({ type = 'button', ...props }: CommonButton.IButton) {
  const buttonId: string = generateId();
  const { children, click, pending } = props;

  const [buttonDimensions, setButtonDimensions] = React.useState<Dimensions | null>(null);

  const getButtonDimensions = (id: string): Dimensions | undefined => {
    const button = document.getElementById(id);
    if (button) {
      const dims: Dimensions = button.getBoundingClientRect();
      return dims;
    }
    return undefined;
  };

  const arbitraryMagicNumber: number = 19;
  const spinnerSize: number = buttonDimensions ? buttonDimensions.height / 2 : arbitraryMagicNumber;
  const buttonStyle = buttonDimensions
    ? { height: `${buttonDimensions.height}px`, width: `${buttonDimensions.width}px` }
    : {};

  React.useEffect(() => {
    const buttonDimensions = getButtonDimensions(buttonId);
    if (buttonDimensions) setButtonDimensions(buttonDimensions);
  }, []);

  return (
    <ButtonElement id={buttonId} type={type} onClick={click} {...props} style={buttonStyle}>
      {pending && <CircularProgress size={spinnerSize} style={{ color: Color.red }} />}
      {!pending && <span>{children}</span>}
    </ButtonElement>
  );
}

export default DeleteButton;
