import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MirroredTooltip } from 'components/MirroredTooltip';
import { Color } from 'core';
import { ContentText } from 'courses/components/ContentText';
import useAssignmentLabel from 'hooks/useAssignmentLabel';
import { PRIVACY_TYPES } from 'models/assignment';
import styled from 'styled-components';
import { TAssignment } from 'types';

const SmallGreen = styled(FontAwesomeIcon)`
  color: ${Color.brightGreen};
  margin-right: 5px;
  width: 0.8em !important;
  vertical-align: middle;
`;

const SmallBlue = styled(FontAwesomeIcon)`
  color: ${Color.reportBlue};
  margin-right: 5px;
  width: 0.8em !important;
  vertical-align: middle;
`;

interface IShareStatusCircle {
  isShared: boolean;
  isMirrored: boolean;
}

const ShareStatusCircle = (props: IShareStatusCircle) => {
  if (props.isMirrored) {
    return <SmallBlue icon={faCircle} />;
  }

  if (props.isShared) {
    return <SmallGreen icon={faCircle} />;
  }

  return null;
};

const LabelWrapper = styled.div`
  display: inline-block;
  margin: 5px;
`;

interface Props {
  assignment: TAssignment;
}

export const AssignmentLabel = (props: Props) => {
  const { assignment } = props;

  const isShared: boolean = assignment.privacyType.id === PRIVACY_TYPES.COURSES_ONLY;
  const label: string = useAssignmentLabel(assignment.goalType, isShared, assignment.meta.isMirrored);

  return (
    <LabelWrapper>
      <ContentText text={assignment.name} textDecoration="underline" display="block" />
      <ShareStatusCircle isShared={isShared} isMirrored={assignment.meta.isMirrored} />
      <ContentText text={label} size="small" display="inline" />
      {assignment.meta.isMirrored && <MirroredTooltip />}
    </LabelWrapper>
  );
};
