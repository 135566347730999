import * as Color from 'core/colors';

export default function StripePattern({
  id,
  color = Color.hexToRGB(Color.reportBlue, 0.07, 0.07),
  width = 6,
}: {
  id: string;
  color?: string;
  width?: number;
}): JSX.Element {
  return (
    <svg width="0" height="0">
      <defs>
        <pattern id={id} patternUnits="userSpaceOnUse" width={width} height={width} patternTransform="rotate(45)">
          <line x1="0" y1="0" x2="0" y2={width} stroke={color} strokeWidth={width} />
        </pattern>
      </defs>
    </svg>
  );
}
