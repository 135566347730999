import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ceregoIconGreyRounded from 'assets/images/cerego-icon-grey-rounded.png';
import { ImageEditOverlay } from 'components/images/ImageBadge';
import { useItemEditorStore } from 'components/item-editor/store';
import { Color } from 'core';
import { useMutateConcept } from 'hooks/useMutateConcept';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { TAnswer } from 'types';
import { createImage } from 'utils/createImage';
import useStore from 'zstore';

interface AnswerImageProps {
  answer: TAnswer;
  updateAnswer: (answerId: string, updates: Partial<TAnswer>) => void;
}

export const AnswerImage = (props: AnswerImageProps) => {
  const { answer, updateAnswer } = props;
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id, answer.questionId);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { makeToast } = useStore();

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.[0];

    try {
      if (file) {
        const image = await createImage(file);

        updateAnswer(answer.conceptId, { image });

        // Update the image in the database
        await mutateConcept({
          id: answer.conceptId,
          payload: {
            data: {
              relationships: {
                image: {
                  data: {
                    id: image.id,
                  },
                },
              },
            },
          },
        });
      }
    } catch (error: any) {
      console.error('Failed to upload image:', error);
      // TODO: roll back optimistic update
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  };

  const handleDeleteImage = async (): Promise<void> => {
    try {
      updateAnswer(answer.conceptId, { image: null });

      // update the image in the database
      await mutateConcept({
        id: answer.conceptId,
        payload: {
          data: {
            relationships: {
              image: {
                data: {
                  id: null,
                },
              },
            },
          },
        },
      });
    } catch (error: any) {
      // TODO: roll back optimistic update
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <ImageEditOverlay
        style={{
          width: '85px',
          height: '85px',
          borderRadius: '20%',
          background: `${Color.sideNavDark}`,
          objectFit: 'cover',
          cursor: 'pointer',
        }}
        onClick={handleImageClick}
      >
        <FontAwesomeIcon icon={faEdit} style={{ color: 'white', fontSize: '22px' }} />
      </ImageEditOverlay>
      <img
        src={answer.image ? answer.image.url : ceregoIconGreyRounded}
        style={{
          objectFit: 'cover',
          width: '85px',
          height: '85px',
          borderRadius: '20%',
        }}
      />
      <FileInput ref={fileInputRef} type="file" accept="image/*" onChange={handleImageUpload} />
      {answer.image && (
        <DeleteButton onClick={handleDeleteImage}>
          <DeleteIcon />
        </DeleteButton>
      )}
    </div>
  );
};

const FileInput = styled.input`
  display: none;
`;

const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8); // Light background for better visibility
  &:hover {
    background: rgba(250, 50, 50, 0.8); // Change background on hover for emphasis
  }
`;
