import { AssessmentLearnersTable } from 'components/AssessmentLearnersTable';
import { AssignmentContentInsightsTable } from 'components/AssignmentContentInsightsTable';
import { AssignmentLearnersTable } from 'components/AssignmentLearnersTable';
import { Restricted } from 'components/Restricted';
import TableFrame from 'components/TableFrame';
import { MenuButton } from 'components/buttons';
import { PageContainer } from 'core/layout';
import * as Size from 'core/size';
import { ExportToCsv } from 'export-to-csv';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { getLearnerStats } from 'hooks/useLearnerStats';
import { AssignmentDetailsPageHeader } from 'learner_stats/components/AssignmentDetailsPageHeader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { SurveyDetailsReport } from 'sets/components/SurveyDetailsReport';
import { handleGetSet } from 'store/set';
import { TAssignment } from 'types';
import convertProgressToInteger from 'utils/convertProgressToInteger';
import { formatReadiness } from 'utils/formatReadiness';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { AnalyticsService } from 'utils/AnalyticsService';
import { transformMilliseconds } from 'utils/timeUtils';
import useStore from 'zstore';

interface Props {
  match: { params: { courseId: string; setId: string } };
}

export const AssignmentDetailsPage = (props: Props) => {
  const { courseId, setId } = props.match.params;
  const dispatch = useDispatch();
  const set: TAssignment = useSelector(getSetData);
  const [isDownloading, setIsDownloading] = useState(false);
  const { t } = useTranslation();

  // Maintain active courseId in zstore
  const selectCourse = useStore((state) => state.selectCourse);
  useEffect(() => {
    selectCourse(courseId);
  }, [courseId]);

  useEffect(() => {
    dispatch(handleGetSet(setId));
  }, [setId, dispatch]);

  function parseStatsForCsv(
    stats: Array<any>,
    setType: string,
    uniqueGroupUsers: string[] = [],
    uniqueUserPartnerUserTags: string[] = []
  ) {
    return stats.map((stat: any) => {
      const headers = {
        [t('Last Study Time')]: stat.lastStudyTime ? stat.lastStudyTime.slice(0, 10) : '',
        [t('User ID')]: stat.user.id,
        [t('Name')]: stat.user.name,
        [t('Email')]: stat.user.email,
        [t('Goal Reached At')]: stat.goalReachedAt ?? '',
        [t('Total Study Time')]: transformMilliseconds(stat.totalStudyTimeMillis, 'total'),
        [t('Member ID')]: stat.memberId ? stat.memberId : '',
      };
      if (setType === 'assessment') {
        headers[t('Assessment Score')] = `${stat.assessmentScore}%`;
        headers[t('Percent Completed')] = `${convertProgressToInteger(stat.percentStarted)}%`;
      } else if (setType === 'set') {
        headers[t('Readiness')] = formatReadiness(stat.readiness);
        headers[t('Progress')] = convertProgressToInteger(stat.progress) + '%';
        headers[t('Percent Studied')] = `${convertProgressToInteger(stat.percentStarted)}%`;
        headers[t('Percent Reviewed')] = `${convertProgressToInteger(stat.percentReviewed)}%`;
      }
      uniqueGroupUsers.forEach((group: string) => {
        headers[group] = stat.groupUsers.includes(group);
      });
      uniqueUserPartnerUserTags.forEach((tag: any) => {
        const userTag = stat.userPartnerUserTags?.find((userTag: any) => userTag.label === tag.label);
        headers[tag.label] = userTag ? userTag.value : '';
      });
      return headers;
    });
  }

  function downloadCsv(data: Array<any>, setType: string) {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Download report' });
    if (!data) {
      return;
    }

    const uniqueGroupUsers: string[] = Array.from(new Set(data.map((stat: any) => stat.groupUsers).flat()));
    const uniqueUserPartnerUserTags: string[] = Array.from(
      new Set(data.map((stat: any) => stat.userPartnerUserTags).flat())
    );
    const parsedStatsForCsv = parseStatsForCsv(data, setType, uniqueGroupUsers, uniqueUserPartnerUserTags);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: t('Set Report'),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'assignment_report',
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(parsedStatsForCsv);
    setIsDownloading(false);
  }

  const handleCsvButtonClick = async (): Promise<void> => {
    setIsDownloading(true);
    const data = await getAllLearnerStats(courseId, setId);
    const goalType = set && set.goalType;
    downloadCsv(data, goalType);
  };

  function getSetData(state: any) {
    return build(state.data, 'sets', setId);
  }

  var title = t('Assignment Details');
  if (set?.name) {
    title += ' | ' + set.name;
  }
  useDocumentTitle(title);

  if (!set) {
    return <></>;
  }

  return (
    <PageContainer>
      {set.goalType === 'survey' ? (
        <SurveyDetailsReport set={set} params={props.match.params} />
      ) : (
        <div>
          <AssignmentDetailsPageHeader set={set} courseId={courseId} />

          <Restricted
            allowedRoles={['admin', 'course manager', 'course viewer', 'instructor']}
            component={
              <TableFrame
                mainComponent={
                  <div style={{ maxWidth: Size.reportPageWidth, marginLeft: 'auto', marginRight: 'auto' }}>
                    {set.goalType === 'assessment' && <AssessmentLearnersTable courseId={courseId} assessment={set} />}
                    {set.goalType !== 'assessment' && <AssignmentLearnersTable courseId={courseId} assignment={set} />}
                  </div>
                }
                segmentNav={
                  <div>
                    <MenuButton
                      pending={isDownloading}
                      text={t('Download')}
                      click={handleCsvButtonClick}
                      hideArrow={true}
                    />
                  </div>
                }
                title={t('Assignment Progress')}
              />
            }
          />

          {set.goalType !== 'scorm' && <AssignmentContentInsightsTable courseId={courseId} assignment={set} />}
        </div>
      )}
    </PageContainer>
  );
};

const getAllLearnerStats = async (courseId: string, setId: string): Promise<Array<any>> => {
  let learnerStats: Array<any> = [];

  const page = 1;
  const pageSize = 200;

  const res = await getLearnerStats('key', courseId, '', 'asc', page, pageSize, '', '', setId);
  if (!res) {
    throw new Error('Failed to fetch learner stats.');
  }
  const totalPages = res.data.meta['total-pages'];
  const resData: any = normalizeJSONAPIResponse(res.data);
  learnerStats.push(...resData);

  for (let i = 2; i <= totalPages; i++) {
    const res = await getLearnerStats('key', courseId, '', 'asc', i, pageSize, '', '', setId);
    if (!res) {
      throw new Error('Failed to fetch learner stats.');
    }
    const resData: any = normalizeJSONAPIResponse(res.data);
    learnerStats.push(...resData);
  }

  return learnerStats;
};
