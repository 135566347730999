import _ from 'lodash';

export enum DENOMINATION {
  YEARS = 'Years',
  MONTHS = 'Months',
  DAYS = 'Days',
  HOURS = 'Hours',
  MINUTES = 'Minutes',
  SECONDS = 'Seconds',
  MILLISECONDS = 'Milliseconds',
}

const denominationInfo = {
  [DENOMINATION.YEARS]: { toDays: 365 },
  [DENOMINATION.MONTHS]: { toDays: 30 },
  [DENOMINATION.DAYS]: { toDays: 1 },
  [DENOMINATION.HOURS]: { toDays: 1 / 24 },
  [DENOMINATION.MINUTES]: { toDays: 1 / (24 * 60) },
  [DENOMINATION.SECONDS]: { toDays: 1 / (24 * 60 * 60) },
  [DENOMINATION.MILLISECONDS]: { toDays: 1 / (24 * 60 * 60 * 1000) },
};

const denominationOrder = Object.keys(denominationInfo) as DENOMINATION[];

export const convertToDenomination = (
  denomination: DENOMINATION,
  startingFrom: DENOMINATION,
  value: number
): number => {
  const valueInDays = value * denominationInfo[startingFrom].toDays;
  const conversionToTarget = 1 / denominationInfo[denomination].toDays;
  return _.round(valueInDays * conversionToTarget);
};

export default function getTimeDenomination(
  time: number,
  startingFrom: DENOMINATION = DENOMINATION.MILLISECONDS
): [DENOMINATION, (value: number) => number] {
  const startIndex = denominationOrder.findIndex((denom) => denom === startingFrom);

  let finalDenomination = startingFrom;

  for (let i = startIndex; i >= 0; i--) {
    const currentDenomination = denominationOrder[i];
    const convertedValue = convertToDenomination(currentDenomination, startingFrom, time);

    if (convertedValue >= 2) {
      finalDenomination = currentDenomination;
    } else {
      break;
    }
  }

  return [finalDenomination, (value: number) => convertToDenomination(finalDenomination, startingFrom, value)];
}
