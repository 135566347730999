import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import React from 'react';

type Props = {
  children: React.ReactNode;
  direction?: 'row' | 'column' | { [key: string]: 'row' | 'column' };
  align?: 'stretch' | 'start' | 'center' | 'end';
  gap?: number;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  sx?: SxProps;
};

export default function MemreFlexBox({
  children,
  direction = 'row',
  align = 'stretch',
  gap = 0,
  justify = 'flex-start',
  sx = {},
}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: justify,
        alignItems: align,
        gap,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
