import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { queryParams } from 'utils/pathUtils';
import styled from 'styled-components';
import { Card, CardHeader, CardHeaderContainer } from 'core/layout';
import ceregoLogo from 'assets/images/cerego-logo.svg';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateUser } from 'store/user';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'store/profile';
import { sleep } from 'utils/timeUtils';
import { PrimaryButton } from './buttons/v4';

interface ILtiUserConfirmPageProps {
  match: { params: { courseId: string } };
}

const LtiConfirmFrame = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LtiOpenNewTabPage = React.memo<ILtiUserConfirmPageProps>((props) => {
  const courseId = queryParams(window.location)?.course_id;
  const partnerId = queryParams(window.location)?.partner_id;
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  // link to specific course details if courseId is present, fallback to the /courses list if missing
  let pathname = `/app/nav/courses`;
  if (courseId) {
    pathname += `/${courseId}`;
  }
  const onClick = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    if (partnerId) {
      await dispatch(updateUser({ primary_partner_id: partnerId }, currentUser.id));
    }
    window.open(pathname, '_blank', 'noopener');
    setLoading(false);
  };

  return (
    <LtiConfirmFrame>
      <Card>
        <CardHeaderContainer>
          <img src={ceregoLogo}></img>
        </CardHeaderContainer>
        <CardHeader>
          <PrimaryButton click={onClick} pending={loading} disabled={loading}>
            {t('Click to start using Cerego in a new tab')} <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>
          </PrimaryButton>
        </CardHeader>
      </Card>
    </LtiConfirmFrame>
  );
});
