import Typography, { TypographyProps } from '@mui/material/Typography';
import * as colours from 'core/colors';
import { ReactNode } from 'react';

type MemreTextProps = {
  children: ReactNode;
  variant?: TypographyProps['variant'] | 'meta';
  sx?: any;
};

export default function MemreText({ children, variant = 'body1', sx = {} }: MemreTextProps) {
  if (variant === 'meta') {
    return (
      <Typography
        sx={{
          fontSize: '0.65rem',
          fontStyle: 'italic',
          fontWeight: 400,
          color: colours.darkGray,
          lineHeight: 1,
          ...sx,
        }}
      >
        {children}
      </Typography>
    );
  }

  return (
    <Typography variant={variant} sx={{ maxWidth: '800px', ...sx }}>
      {children}
    </Typography>
  );
}
