import { Color, Size } from 'core';
import { isRightToLeft } from 'i18n';
import AudioPlayer from 'learn/components/AudioPlayer';
import { TitleText } from 'learn/components/TitleText';
import ZoomableImageContainer from 'learn/components/ZoomableImageContainer';
import { IQuiz } from 'models/quiz';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getTitleTextKey } from 'utils/quizUtils';

interface IQuizCardExpandedProps extends WithTranslation {
  goalType: string;
  quiz?: IQuiz;
}

type OwnState = {
  isModalOpen?: boolean;
};

const CardContainer = styled.div`
  max-width: 100%;
  min-height: 164px;
  background-color: white;
  display: flex;
  overflow: auto;
  border-radius: 4px;
  border: solid 1px ${Color.veryLightBlue};
  margin-bottom: 25px;
`;

const HeaderTextContainer = styled.div`
  width: 115px;
  height: 30px;
  background-color: ${Color.quizCardHeaderBackground};
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const LeftHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-left: 150px;
  max-width: ${Size.defaultCardWidth}px;
  margin: auto 15px auto 15px;
  @media (max-width: ${Size.defaultCardWidth}px) {
    margin-left: 20px;
    max-width: 90%;
  }
`;

const SoundContainer = styled.div`
  margin-bottom: 25px;
`;

const QuizCardExpandedImageContainer = styled.div`
  margin: auto 0px auto auto
  display: flex;
  cursor: pointer;
  @media (max-width: ${Size.defaultCardWidth}px) {
    margin: 0px auto 15px auto;
  }

  & img {
    max-height: 119px;
    max-width: 159px;
    display: flex;
    align-self: center;
  }
`;

const Container = styled.div`
  width: 710px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  @media (max-width: ${Size.iPadWidth}px) {
    flex-direction: column;
  }
`;

class QuizCardExpanded extends React.Component<IQuizCardExpandedProps, OwnState> {
  private rightToLeft: boolean;
  constructor(props) {
    super(props);
    this.rightToLeft = isRightToLeft();
    this.state = { isModalOpen: false };
  }

  public render() {
    const { quiz, t } = this.props;
    return (
      <CardContainer>
        <Container>
          <LeftHeader style={{ textAlign: this.rightToLeft ? 'right' : 'left' }}>
            <HeaderTextContainer
              style={{
                width: this.rightToLeft ? '100%' : '115px',
                justifyContent: this.rightToLeft ? 'flex-end' : 'center',
                paddingRight: this.rightToLeft ? '25px' : '0px',
                backgroundColor: this.rightToLeft ? 'transparent' : Color.quizCardHeaderBackground
              }}
            >
              <TitleText
                text={t(getTitleTextKey(this.props.goalType))}
                name={'card-title'}
                style={{
                  letterSpacing: '2px'
                }}
              />
            </HeaderTextContainer>
            <TitleText
              text={quiz ? quiz.questionShortenedText || '' : ''}
              style={{ margin: '15px 0px 0px 0px' }}
              name="card-paragraph"
            />
            {quiz && quiz.questionAudio && (
              <SoundContainer>
                <AudioPlayer src={quiz.questionAudio.url} />
              </SoundContainer>
            )}
          </LeftHeader>
          {quiz && quiz.questionImage && (
            <QuizCardExpandedImageContainer data-type="card-image-container">
              {/* Transcoded size is large because the large one will already have been downloaded and cached by the recall screen */}
              <ZoomableImageContainer image={quiz.questionImage} transcodedSize={'large'} />
            </QuizCardExpandedImageContainer>
          )}
        </Container>
      </CardContainer>
    );
  }
}

export default withTranslation()(QuizCardExpanded);
