import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import correctIcon from 'assets/images/correct.svg';
import incorrectIcon from 'assets/images/incorrect.svg';
import { Color, Size } from 'core';
import { isRightToLeft } from 'i18n';
import { TitleText } from 'learn/components/TitleText';
import { QuizResult } from 'models/presentation';
import { IFillInTheBlankQuizPart } from 'models/quiz';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

export type FillInTheBlankAnswerStyle = 'default' | 'correct' | 'incorrect';
interface IOwnProps extends WithTranslation {
  userAnswer: string;
  answer: IFillInTheBlankQuizPart;
  quizResult?: QuizResult;
  style: FillInTheBlankAnswerStyle;
  classes: { root: string; underline: string };
  onFillInTheBlankChange: (value: string, quizResult?: QuizResult) => void;
  showAnswers?: boolean;
}
type OwnState = {};

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  max-width: ${Size.defaultCardWidth}px;
  min-height: 175px;
  border-radius: 4px;
  border: 1px solid ${Color.veryLightBlue};
  margin: 0px auto 5px auto;
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 500;
  color: ${Color.textGray};
  margin-left: 25px;
`;

// Material UI overrides
const styles = {
  root: {
    color: Color.textGray,
    lineHeight: '22px',
    fontSize: '18px',
    paddingBottom: '10px',
    margin: '50px 25px 0px 25px'
  },
  underline: {
    '&:after': {
      border: `1px solid ${Color.darkGray}`
    }
  }
};

export class FillInTheBlankAnswer extends React.Component<IOwnProps, OwnState> {
  private rightToLeft: boolean;
  constructor(props) {
    super(props);
    this.rightToLeft = isRightToLeft();
    // If we're showing answers, like in Preview Mode, autofill the input with the correct answer
    if (this.props.showAnswers) {
      this.handleChange({ target: { value: this.props.answer.text } });
    }
  }

  public render() {
    const {
      classes,
      style,
      userAnswer,
      answer: { text: correctAnswer },
      t
    } = this.props;

    return (
      <Container>
        <Title
          style={{ textAlign: this.rightToLeft ? 'right' : 'left', paddingRight: this.rightToLeft ? '25px' : '0px' }}
        >
          {t('Type the Answer')}
        </Title>
        <Input
          aria-label={t('answer')}
          value={userAnswer}
          autoFocus={true}
          onChange={this.handleChange}
          multiline={true}
          classes={{
            root: classes.root,
            underline: style === 'incorrect' ? '' : classes.underline
          }}
          style={{ direction: this.rightToLeft ? 'rtl' : 'ltr' }}
          error={style === 'incorrect'}
          readOnly={style !== 'default'}
          endAdornment={
            <InputAdornment position="end">
              {(style === 'incorrect' || style === 'correct') && (
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={style === 'correct' ? correctIcon : incorrectIcon}
                  alt={style === 'correct' ? 'correct icon' : 'incorrect icon'}
                />
              )}
            </InputAdornment>
          }
        />
        {style === 'incorrect' && (
          <div>
            <TitleText
              name="error-message"
              text={
                this.props.quizResult === 'AlmostCorrect' ? t('So close, you almost got it!') : t('That was incorrect.')
              }
              style={{
                marginLeft: '25px',
                textAlign: this.rightToLeft ? 'right' : 'left',
                marginRight: this.rightToLeft ? '25px' : '0px'
              }}
            />
            <Title
              style={{ textAlign: this.rightToLeft ? 'right' : 'left', marginRight: this.rightToLeft ? '25px' : '0px' }}
            >
              Correct Answer: {correctAnswer}
            </Title>
          </div>
        )}
        {style === 'correct' && (
          <TitleText
            name="success-message"
            text={t('Yay, you got that right!')}
            style={{
              marginLeft: '25px',
              textAlign: this.rightToLeft ? 'right' : 'left',
              marginRight: this.rightToLeft ? '25px' : '0px'
            }}
          />
        )}
      </Container>
    );
  }

  private handleChange = (event): void => {
    this.props.onFillInTheBlankChange(event.target.value);
  };
}

export default withStyles(styles)(withTranslation()(FillInTheBlankAnswer));
