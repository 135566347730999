import * as Plot from '@observablehq/plot';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import roundNumber from 'utils/roundNumberUp';
import XTicks from './Atoms/XTicks';
import { COLOR, FILL, STROKE } from './constants';

export default function MemreHistogram({
  data,
  bucketCount = 10,
}: {
  data: number[];
  bucketCount?: 5 | 10 | 15 | 20 | 25 | 30;
}) {
  const chartRef = useRef<HTMLDivElement>(null);

  const topResult = roundNumber(_.max(data) || 0);

  const bucketSize = topResult / bucketCount;

  const bucketCounts = new Array(bucketCount).fill(0);
  data.forEach((value) => {
    const index = _.clamp(_.floor(value / bucketSize), 0, bucketCount - 1);
    bucketCounts[index]++;
  });

  const maxY = Math.max(...bucketCounts);
  const bins = bucketCounts.map((count, index) => ({ bin: index, count }));

  useEffect(() => {
    const chart = Plot.plot({
      marks: [
        Plot.rectY([{}], {
          x1: 0,
          x2: bucketCount * bucketSize,
          y1: 0,
          y2: maxY * 1.2,
          fill: FILL.LIGHT_BLUE,
        }),
        Plot.barY(bins, {
          x: 'bin',
          y: 'count',
          stroke: COLOR.WHITE,
          strokeWidth: STROKE.WIDTH.MEDIUM,
          fill: COLOR.DARK_BLUE,
        }),
        ...XTicks({
          start: bucketCount / 5,
          stop: bucketCount - bucketCount / 5,
          textFormat: (x) => String(_.floor(x * bucketSize)),
        }),
      ],
      height: 90,
      width: 400,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
    });

    if (chartRef.current) {
      chartRef.current.innerHTML = '';
      chartRef.current.appendChild(chart);
    }

    return () => {
      chart.remove();
    };
  }, [bins, maxY, topResult]);

  return <div ref={chartRef} />;
}
