import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

// NOTE: id is user-id
type UpdateUserPayload = {
  id: string;
  partner_id: number;
  password: string;
  password_confirmation: string;
};

const updateUser = (payload: UpdateUserPayload) => {
  return axios.put(`/api/v3/partners/${payload.partner_id}/users/${payload.id}`, payload);
};

export const useMutateUser = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();

  return useMutation(updateUser, {
    onSuccess: () => {
      makeToast({
        text: 'Successfully updated user.',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Failed to update user. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['user', user?.primaryPartner.id, user?.id]);
    },
  });
};
