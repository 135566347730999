import {
  faArrowsAlt,
  faFileAlt,
  faMousePointer,
  faQuestionCircle,
  faLanguage,
  faClone,
  faPencil,
  faGauge,
  faChartBar,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Color } from 'core';
import { useCreateItem } from 'hooks/useCreateItem';
import { IItem, SurveyAnswerType, TemplateType } from 'models/item';
import { useTranslation } from 'react-i18next';

interface ContentTemplateCopy {
  title: string;
  description: string;
}

const useTranslatedTemplate = (
  template: TemplateType,
  answerType: SurveyAnswerType | undefined
): ContentTemplateCopy => {
  const { t } = useTranslation();

  switch (template) {
    case 'question_and_answers':
      return { title: t('Question & Answer'), description: t('Choose the correct answer(s).') };
    case 'sequences':
      return { title: t('Sequences'), description: t('Drag and drop each step in the correct order.') };
    case 'vocabulary':
      return { title: t('Vocabulary'), description: t('Match the foreign word to the correct English word.') };
    case 'regions':
      return {
        title: t('Diagrams'),
        description: t('Click on hotspots and choose the correct region.'),
      };
    case 'instructional_contents':
      return { title: t('Instructional Content'), description: t('Multimedia learning and reference materials.') };
    case 'associations':
      return { title: t('Flashcards'), description: t('Choose the correct answer.') };
    case 'free_responses':
      return { title: t('Free Response'), description: t('Type in a free response.') };
    case 'ratings':
      return { title: t('Rating'), description: t('Rate on a scale between 1 and 5.') };
    case 'survey_question_and_answers':
      if (answerType === 'single_correct') {
        return { title: t('Single Select Question'), description: t('Choose a response.') };
      } else {
        return { title: t('Multiple Select Question'), description: t('Choose one or more responses.') };
      }
    default:
      throw new Error(`Cannot use translated template for template type: ${template}`);
  }
};

interface IContentTemplateCard {
  item?: IItem;
  template: TemplateType;
  redirectToItem: (itemId: string) => void;
  setId: string;
  answerType?: SurveyAnswerType;
}

export const ContentTemplateCard = (props: IContentTemplateCard) => {
  const { template, setId, answerType } = props;
  const { title, description } = useTranslatedTemplate(template, answerType);

  if (template === 'survey_question_and_answers' && !answerType) {
    throw new Error('Survey question and answers must have an answer type.');
  }

  const [createItem, { status: itemCreationStatus, data: itemCreationData }] = useCreateItem();

  // TODO: add PDF handler
  const getIcon = () => {
    switch (template) {
      case 'question_and_answers':
        return faQuestionCircle;
      case 'vocabulary':
        return faLanguage;
      case 'sequences':
        return faArrowsAlt;
      case 'regions':
        return faMousePointer;
      case 'instructional_contents':
        return faFileAlt;
      case 'associations':
        return faClone;
      case 'free_responses':
        return faPencil;
      case 'ratings':
        return faChartBar;
      case 'survey_question_and_answers':
        return answerType === 'single_correct' ? faQuestionCircle : faCheckSquare;
      default:
        throw new Error(`Cannot get icon for template: ${template}`);
    }
  };

  const selectTemplate = () => {
    let payload: any = {
      data: {
        attributes: {
          'template-type': template,
        },
      },
    };

    if (template === 'survey_question_and_answers') {
      payload.data.attributes['answer-type'] = answerType;
    } else if (template === 'instructional_contents') {
      payload.data.attributes['ic-subtype'] = 'instructional_page';
    } else if (template === 'free_responses') {
      payload.data.attributes['template-type'] = 'free_response';
    }

    const createFacetTemplates: TemplateType[] = [
      'question_and_answers',
      'sequences',
      'vocabulary',
      'associations',
      'free_responses',
      'survey_question_and_answers',
    ];

    const createFacet = createFacetTemplates.includes(template);

    createItem({
      setId,
      createFacet,
      payload,
    });
  };

  if (itemCreationStatus === 'success') {
    props.redirectToItem(itemCreationData.id);
  }

  return (
    <div
      className="content-template-card"
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${Color.lightGray}`,
        borderRadius: '4px',
        marginBottom: '15px',
        cursor: 'pointer',
      }}
      onClick={selectTemplate}
    >
      <div
        className="content-template-name"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px',
          backgroundColor: Color.lightSkyBlue,
        }}
      >
        <FontAwesomeIcon
          icon={getIcon()}
          style={{ margin: '15px 5px 15px 15px', fontSize: '1.4em', color: Color.mainBlue }}
        />
        <h4 style={{ margin: '10px' }}>{title}</h4>
      </div>
      <div className="content-template-description" style={{ textAlign: 'left', padding: '5px' }}>
        <p style={{ margin: '10px 15px' }}>{description}</p>
      </div>
    </div>
  );
};
