import { TableSearchBar } from 'components/tables/TableSearchBar';
import { useStaffStore } from 'pages/admin-dashboard/staff/store';
import styled from 'styled-components';

export const StaffTableToolbar = () => {
  const { setPageNumber, name, setName } = useStaffStore();

  return (
    <ToolbarContainer>
      <TableSearchBar
        value={name}
        onChange={setName}
        resetPage={() => setPageNumber(1)}
        placeholder="Search staff by name or email..."
      />
    </ToolbarContainer>
  );
};

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
`;
