import CellWithPill from 'components/tables/CellWithPill';
import { Color } from 'core';
import moment from 'moment';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ICoursePublishDateCellProps extends WithTranslation {
  state: string;
  stateUpdatedAt: string;
}

class CoursePublishDateCell extends React.Component<ICoursePublishDateCellProps> {
  public render() {
    const { state, t } = this.props;
    const stateName = state === 'building' ? t('Building') : state.charAt(0).toUpperCase() + state.slice(1);
    if (state === 'published' && this.props.stateUpdatedAt) {
      return (
        <CellWithPill
          text={t(stateName)}
          textColor={Color.reportGray}
          pillText={moment(this.props.stateUpdatedAt).format('MM/DD/YY')}
          pillColor={Color.primaryGreen}
          pillBackgroundColor={Color.hexToRGB(Color.primaryGreen, 0.1)}
        />
      );
    } else {
      return <div>{t(stateName)}</div>;
    }
  }
}

export default withTranslation()(CoursePublishDateCell);
