import { ColumnDef } from '@tanstack/react-table';

export type TableParmas = {
  id: string;
  pageNumber: number;
  searchTerm?: string;
  pageSize: number;
  sortByColumn?: string;
};

export const TABLE_DEFAULT = {
  PAGE_NUMBER: 1,
  PAGE_SIZE: 25,
  SORT_BY_COLUMN: 'position',
  SEARCH_TERM: '',
};

export type TableColumnDef<TData> = ColumnDef<TData> & {
  sortable?: string | undefined;
  searchable?: boolean;
  rightAlign?: boolean;
  fillWidth?: boolean;
};
