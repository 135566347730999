import produce from 'immer';
import create from 'zustand';

// Log every time state is changed
const log = (config) => (set, get, api) =>
  config(
    (args) => {
      console.group('zstore report');
      console.log('old state:', get());
      set(args);
      console.log('new state:', get());
      console.groupEnd();
    },
    get,
    api
  );

// Make state updates smoother
export const immer = (config) => (set, get, api) => config((fn) => set(produce(fn)), get, api);

type ZustandFn = (set: any, get: any, api: any) => any;

// Don't run the logger middleware on production
export const devlog = (setStore: ZustandFn): ZustandFn => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return log(setStore);
    case 'test':
      return log(setStore);
    case 'production':
      return setStore;
    default:
      throw new Error(`Unrecognized process.env.NODE_ENV, ${process.env.NODE_ENV}`);
  }
};

type MakeToastParams = {
  text: string;
  isError: boolean;
};

export type State = {
  toast: string;
  isErrorToast: boolean;
  makeToast: (params: MakeToastParams) => void;
  clearToast: () => void;
  assignmentId: string;
  selectAssignment: (id: string) => void;
  courseId: string;
  selectCourse: (id: string) => void;
  showModal: boolean;
  setShowModal: (b: boolean) => void;
};

const makeStore = () => {
  return create<State>(
    devlog(
      immer((set: any) => ({
        toast: '',
        isErrorToast: false,
        makeToast: (params: MakeToastParams) =>
          set((_state: State) => ({ toast: params.text, isErrorToast: params.isError })),
        clearToast: () => set((_state: State) => ({ toast: '', isErrorToast: false })),
        assignmentId: '',
        selectAssignment: (id: string) => set((_state: State) => ({ assignmentId: id })),
        courseId: '',
        selectCourse: (id: string) => set((_state: State) => ({ courseId: id })),
        showModal: false,
        setShowModal: (b: boolean) => set((_state: State) => ({ showModal: b })),
      }))
    )
  );
};

const useStore = makeStore();

export default useStore;

// State management for the Learner Dashboard
export type LearnerDashboardState = {
  sort: string;
  setSort: (sort: string) => void;
  page: number;
  setPage: (page: number) => void;
  query: string;
  setQuery: (q: string) => void;
  paymentRequiredCourseIds: Array<number>;
  setPaymentRequiredCourseIds: (arr: Array<number>) => void;
};

const makeLearnerDashboardStore = () => {
  return create<LearnerDashboardState>(
    devlog(
      immer((set) => ({
        sort: '',
        setSort: (sort: string) => set((_state: LearnerDashboardState) => ({ sort })),
        page: 1,
        setPage: (page: number) => set((_state: LearnerDashboardState) => ({ page })),
        query: '',
        setQuery: (query: string) => set((_state: LearnerDashboardState) => ({ query })),
        paymentRequiredCourseIds: [],
        setPaymentRequiredCourseIds: (arr: Array<number>) =>
          set((_state: LearnerDashboardState) => ({ paymentRequiredCourseIds: arr })),
      }))
    )
  );
};

export const useLearnerDashboardStore = makeLearnerDashboardStore();

// State management for the LearnersTable component
export type LearnerSort =
  | 'name'
  | 'progress'
  | 'assignments_started'
  | 'total_time'
  | 'last_visit'
  | 'completed_at'
  | 'assessment_score'
  | 'studied'
  | 'reviewed';

export type LearnersTableState = {
  page: number;
  setPage: (n: number) => void;
  sort: LearnerSort;
  setSort: (s: LearnerSort) => void;
  sortDirection: 'asc' | 'desc';
  setSortDirection: (dir: 'asc' | 'desc') => void;
  group: string | null;
  setGroup: (g: string | null) => void;
  name: string;
  setName: (n: string) => void;
};

const makeLearnersTableStore = () => {
  return create<LearnersTableState>(
    devlog(
      immer((set: any) => ({
        page: 1,
        setPage: (n: number) => set(() => ({ page: n })),
        sort: 'progress',
        setSort: (s: LearnerSort) => set(() => ({ sort: s })),
        sortDirection: 'desc',
        setSortDirection: (d: 'asc' | 'desc') => set(() => ({ sortDirection: d })),
        group: null,
        setGroup: (g: string) => set(() => ({ group: g })),
        name: '',
        setName: (n: string) => set(() => ({ name: n })),
      }))
    )
  );
};

export const useLearnersTableStore = makeLearnersTableStore();

type ReportState = {
  courseId: string | null;
  setCourseId: (i: string | null) => void;
  assignmentId: string | null;
  setAssignmentId: (i: string | null) => void;
  studentId: string | null;
  setStudentId: (i: string | null) => void;
  startDate: Date;
  setStartDate: (d: Date) => void;
  endDate: Date;
  setEndDate: (d: Date) => void;
  userNameQuery: string;
  setUserNameQuery: (q: string) => void;
  page: number;
  setPage: (p: number) => void;
  group: string | null;
  setGroup: (g: string | null) => void;
  userId: string | null;
  setUserId: (i: string | null) => void;
};

const oneMonthAgo = (): Date => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date;
};

const makeReportStore = () => {
  return create<ReportState>(
    devlog(
      immer((set: any) => ({
        courseId: null,
        setCourseId: (i: string | null) => set(() => ({ courseId: i })),
        assignmentId: null,
        setAssignmentId: (i: string | null) => set(() => ({ assignmentId: i })),
        studentId: null,
        setStudentId: (i: string | null) => set(() => ({ studentId: i })),
        startDate: oneMonthAgo(),
        setStartDate: (d: Date) => set(() => ({ startDate: d })),
        endDate: new Date(),
        setEndDate: (d: Date) => set(() => ({ endDate: d })),
        userNameQuery: '',
        setUserNameQuery: (q: string) => set(() => ({ userNameQuery: q })),
        page: 1,
        setPage: (p: number) => set(() => ({ page: p })),
        group: null,
        setGroup: (g: string | null) => set(() => ({ group: g })),
        userId: null,
        setUserId: (i: string | null) => set(() => ({ userId: i })),
      }))
    )
  );
};

export const useReportStore = makeReportStore();

type AdminDashState = {
  page: number;
  setPage: (n: number) => void;
  query: string;
  setQuery: (q: string) => void;
};

const makeAdminDashStore = () => {
  return create<AdminDashState>(
    devlog(
      immer((set: any) => ({
        page: 1,
        setPage: (n: number) => set(() => ({ page: n })),
        query: '',
        setQuery: (q: string) => set(() => ({ query: q })),
      }))
    )
  );
};

export const useAdminDashStore = makeAdminDashStore();
