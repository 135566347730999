import axios from 'axios';
import { useQuery } from 'react-query';

const getMyCourses = async (_key: string, page: number, sort: string, query: string) => {
  const response = await axios.get(
    `/api/v3/my/courses?filter=${encodeURIComponent(
      '{"ready":true}'
    )}&include=image,partner,partner.partner-library&page[number]=${page}&page[size]=15&query=${query}&sort=${sort}&tab=progress`
  );
  return response;
};

export const useMyCourses = (page: number, sort: string, query: string) => {
  return useQuery(['myCourses', page, sort, query], getMyCourses);
};
