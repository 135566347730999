import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox as MaterialCheckBox, Radio, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Modal, ModalPosition } from 'components/Modal';
import { CheckBoxGrid, List, ListIcon, ModalHeader } from 'components/modals/common';
import { PublishOptions } from 'components/modals/PublishOptions';
import { Color, Size } from 'core';
import { Card, CardHeader, CardHeaderContainer, PageTitle } from 'core/layout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

interface IProps {
  courseName: string;
  learnerCount: number;
  libraryName: string;
  libraryEnabled: boolean;
  onCancel: () => void;
  onPublish: (inLibrary, inviteLearners, shareAssignments) => void;
  showShareOptions: boolean;
}

const PublishCard = styled(Card)`
  margin: 1em auto;
`;

const BlueCheckBox = withStyles({
  root: {
    padding: 0,
    '&$checked': {
      color: Color.primaryBlue,
    },
  },
  checked: {},
})(MaterialCheckBox);

const BlueRadio = withStyles({
  root: {
    '&$checked': {
      color: Color.primaryBlue,
    },
  },
  checked: {},
})(Radio);

export const PublishCourse = (props: IProps) => {
  const [checked, setChecked] = useState(false);
  const [share, setShare] = useState(1);
  const [showPublishOptions, setShowPublishOptions] = useState(false);
  const { t } = useTranslation();

  const handlePublishClick = (): void => {
    if (props.libraryEnabled) {
      setShowPublishOptions(true);
    } else {
      props.onPublish(false, checked, share === 2);
    }
  };

  const handleRadioChange = (event): void => {
    setShare(parseInt(event.target.value));
  };

  if (showPublishOptions) {
    return (
      <Modal
        show={showPublishOptions}
        element={
          <PublishOptions
            inviteLearners={checked}
            shareAssignments={share === 2}
            libraryName={props.libraryName}
            onCancel={() => setShowPublishOptions(false)}
            onPublish={props.onPublish}
          />
        }
      />
    );
  }

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>
          {t("You're about to publish:")} {props.courseName}
        </CenterTitle>
      </ModalHeader>
      <PublishCard>
        <CardHeaderContainer>
          <CardHeader>{t('What happens when you publish a course?')}</CardHeader>
        </CardHeaderContainer>
        <div>
          <List>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('Learners will be able to join your course and get started')}
            </li>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('Your Course Enrollment Link will become active')}
            </li>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('Your course gradebook and analytics will become active')}
            </li>
          </List>
        </div>
      </PublishCard>
      {props.learnerCount > 0 && (
        <PublishCard>
          <CardHeaderContainer>
            <CardHeader>
              Invite {props.learnerCount} {props.learnerCount > 1 ? 'learners' : 'learner'}
            </CardHeader>
          </CardHeaderContainer>
          <div>
            <CheckBoxGrid>
              <BlueCheckBox onClick={() => setChecked((checked) => !checked)} />
              <div>{t("Send email invitations to learners you've added to course rosters.")}</div>
            </CheckBoxGrid>
          </div>
        </PublishCard>
      )}

      {props.showShareOptions && (
        <PublishCard>
          <CardHeaderContainer>
            <CardHeader>{t('Share all assignments?')}</CardHeader>
          </CardHeaderContainer>
          <p>
            {t(
              'Other courses can use the shared assignments you have created. Optionally, you can select specific assignments to share from the course detail page.'
            )}
          </p>
          <div>
            <RadioGroup name="share" value={share} onChange={handleRadioChange}>
              <List>
                <li>
                  <label>
                    <BlueRadio value={1} checked={share === 1} />{' '}
                    {t('No, but I may share individual assignments from my course detail page')}
                  </label>
                </li>
                <li>
                  <label>
                    <BlueRadio value={2} checked={share === 2} />{' '}
                    {t('Yes, allow any course to utilize these assignments')}
                  </label>
                </li>
              </List>
            </RadioGroup>
          </div>
        </PublishCard>
      )}

      <div style={{ width: `${Size.defaultCardWidth}px`, margin: '1em auto' }}>
        <ConfirmOrCancel
          cancelText={t('Cancel')}
          confirmText={t('Publish')}
          handleCancelClick={props.onCancel}
          handleConfirmClick={handlePublishClick}
          confirmIsDisabled={false}
          confirmIsPending={false}
        />
      </div>
    </ModalPosition>
  );
};
