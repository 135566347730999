import { Box, TextField, Tooltip, Typography } from '@mui/material';
import { MemreFlexBox } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { IImage } from 'models/image';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import React, { useRef, useState } from 'react';
import { createImage } from 'utils/createImage';

export const OrganizationDetails: React.FC = () => {
  const user = useCurrentUser();
  const [organizationName, setOrganizationName] = useState(user?.primaryPartner?.name || '');
  const [logo, setLogo] = useState<IImage | null>(user?.primaryPartner?.image || null);
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);

  const fileInputRef = useRef<HTMLInputElement>(null);

  // Handle organization name change
  const handleOrganizationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(event.target.value);
    debouncedPartnerUpdate({
      name: event.target.value,
    });
  };

  // Handle logo upload
  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    try {
      if (file) {
        const image = await createImage(file);
        setLogo(image);
        debouncedPartnerUpdate({
          icon_image_id: image.id,
        });
      }
    } catch (error: any) {
      console.error('Error uploading logo:', error);
    }
  };

  // Trigger file input click
  const handleLogoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <ConfigItem title="Organization Details">
      <MemreFlexBox gap={2} direction="row" align="center" justify="center">
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Tooltip title="Click to upload logo" arrow>
            <Box
              onClick={handleLogoClick}
              sx={{
                cursor: 'pointer',
                width: 120,
                height: 120,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 3,
                border: '1px solid #ccc',
                '&:hover .hoverOverlay': {
                  opacity: 1,
                },
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              {logo ? (
                <img
                  src={logo.url}
                  alt="Logo Preview"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#f9f9f9',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    No Logo
                  </Typography>
                </Box>
              )}
              {/* Hover overlay */}
              <Box
                className="hoverOverlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                }}
              >
                <Typography variant="subtitle1">Change Logo</Typography>
              </Box>
            </Box>
          </Tooltip>
        </Box>
        <TextField
          label="Organization Name"
          fullWidth
          margin="normal"
          value={organizationName}
          onChange={handleOrganizationNameChange}
          sx={{ mt: 2 }}
        />
      </MemreFlexBox>
    </ConfigItem>
  );
};
