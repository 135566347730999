import { MemreTabbedSection } from 'components/core';
import CourseItemsTable from './CourseItemsTable';
import InstructionalItemsTable from './InstructionalItemsTable';

export default function CourseContentTabs({ assignmentCount, instructionalItemsCount, courseId }) {
  const tabs = [
    {
      title: `Assignment${assignmentCount ? 's' : ''} (${assignmentCount})`,
      element: <CourseItemsTable courseId={courseId} assignmentCount={assignmentCount} />,
    },
    {
      title: `Instructional Item${instructionalItemsCount ? 's' : ''} (${instructionalItemsCount})`,
      element: <InstructionalItemsTable instructionalItemsCount={instructionalItemsCount} courseId={courseId} />,
    },
  ];
  return <MemreTabbedSection tabs={tabs} />;
}
