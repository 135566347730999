import { devlog, immer } from 'zstore';
import create from 'zustand';

type GroupsState = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  name: string;
  setName: (name: string) => void;
  sortColumn: string;
  setSortColumn: (sortColumn: string) => void;
  sortDirection: 'asc' | 'desc';
  setSortDirection: (sortDirection: 'asc' | 'desc') => void;
};

const makeGroupsStore = () => {
  return create<GroupsState>(
    devlog(
      immer((set: any) => ({
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => set(() => ({ pageNumber })),
        pageSize: 10,
        setPageSize: (pageSize: number) => set(() => ({ pageSize })),
        name: '',
        setName: (name: string) => set(() => ({ name })),
        sortColumn: 'name',
        setSortColumn: (sortColumn: string) => set(() => ({ sortColumn })),
        sortDirection: 'asc',
        setSortDirection: (sortDirection: 'asc' | 'desc') => set(() => ({ sortDirection })),
      }))
    )
  );
};

export const useGroupsStore = makeGroupsStore();

const makeGroupMembersStore = () => {
  return create<GroupsState>(
    devlog(
      immer((set: any) => ({
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => set(() => ({ pageNumber })),
        pageSize: 10,
        setPageSize: (pageSize: number) => set(() => ({ pageSize })),
        name: '',
        setName: (name: string) => set(() => ({ name })),
        sortColumn: 'users.name',
        setSortColumn: (sortColumn: string) => set(() => ({ sortColumn })),
        sortDirection: 'asc',
        setSortDirection: (sortDirection: 'asc' | 'desc') => set(() => ({ sortDirection })),
      }))
    )
  );
};

export const useGroupMembersStore = makeGroupMembersStore();

type TStatusFilter = 'published' | 'unpublished' | 'archived';

type GroupCoursesState = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  name: string;
  setName: (name: string) => void;
  sortColumn: string;
  setSortColumn: (sortColumn: string) => void;
  sortDirection: 'asc' | 'desc';
  setSortDirection: (sortDirection: 'asc' | 'desc') => void;
  statusFilter: TStatusFilter;
  setStatusFilter: (statusFilter: TStatusFilter) => void;
};

const makeGroupCoursesStore = () => {
  return create<GroupCoursesState>(
    devlog(
      immer((set: any) => ({
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => set(() => ({ pageNumber })),
        pageSize: 10,
        setPageSize: (pageSize: number) => set(() => ({ pageSize })),
        name: '',
        setName: (name: string) => set(() => ({ name })),
        sortColumn: 'content_name',
        setSortColumn: (sortColumn: string) => set(() => ({ sortColumn })),
        sortDirection: 'asc',
        setSortDirection: (sortDirection: 'asc' | 'desc') => set(() => ({ sortDirection })),
        statusFilter: 'published',
        setStatusFilter: (statusFilter: TStatusFilter) => set(() => ({ statusFilter }))
      }))
    )
  );
};

export const useGroupCoursesStore = makeGroupCoursesStore();
