import { CALL_API } from 'middleware/api';

export const handleGetSurveyResults = (courseId: string, setId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v4/sets/${setId}/survey_results?course_id=${courseId}`,
      httpMethod: 'GET'
    }
  };
};
