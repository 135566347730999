export async function performActionWithConfirmation(confirmationMessage: string, action: () => Promise<void>) {
  if (window.confirm(confirmationMessage)) {
    try {
      await action();
    } catch (error: any) {
      console.error('Error: ', error);
      window.alert(`Error: ${error.message}`);
    }
  }
}
