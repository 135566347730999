import axios from 'axios';
import { DropDown, DropDownListItem } from 'components/DropDown';
import { API_DATA_SUCCESS } from 'middleware/api';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import { handleDeleteCourseUser } from 'store/course-user';
import { REMOVE_LEARNER_STAT } from 'store/learner-stats';
import { TCourse } from 'types';
import { AnalyticsService } from 'utils/AnalyticsService';
import useStore from 'zstore';

interface ICourseLearnersTableMenuProps {
  course: TCourse;
  top: number;
  left: number;
  handleOutsideClick: () => void;
  userId: string;
  userEmail: string;
  onEmailLearner: () => void;
}

const CourseLearnersTableMenu = (props: ICourseLearnersTableMenuProps) => {
  const dispatch = useDispatch();
  const { makeToast } = useStore();
  const { t } = useTranslation();

  const sendInvitation = async (): Promise<void> => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Send learner invite' });
    await axios.post(`/api/v3/courses/${props.course.id}/invitations`, { user_ids: [props.userId] });
    makeToast({
      text: t('1 invitation was sent'),
      isError: false,
    });

    props.handleOutsideClick();
  };

  const removeLearner = async (): Promise<void> => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Remove learner' });
    const actionResponse: any = await dispatch(handleDeleteCourseUser(props.course.id, props.userId));

    if (actionResponse.type === API_DATA_SUCCESS) {
      makeToast({
        text: t('1 learner was removed'),
        isError: false,
      });
      dispatch({ type: REMOVE_LEARNER_STAT, userId: props.userId, courseId: props.course.id });
    }

    props.handleOutsideClick();
  };

  return (
    <OutsideClickHandler onOutsideClick={props.handleOutsideClick}>
      <DropDown top={props.top} left={props.left - 3} style={{ zIndex: 11 }} unwrapped={true}>
        {props.course.state !== 'unpublished' && (
          <DropDownListItem>
            <button onClick={sendInvitation}>{t('Send Invitation')}</button>
          </DropDownListItem>
        )}
        <DropDownListItem>
          <button onClick={props.onEmailLearner}>{t('Email Learner')}</button>
        </DropDownListItem>
        <DropDownListItem>
          <button onClick={removeLearner}>{t('Remove From Course')}</button>
        </DropDownListItem>
      </DropDown>
    </OutsideClickHandler>
  );
};

export default CourseLearnersTableMenu;
