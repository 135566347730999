import React from 'react';
import { Color } from 'core';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ValidationText = styled.li`
  color: ${Color.red};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0em 0em;
`;

const Container = styled.ul`
  padding: 0.5em 0.5em 0em 0.5em;
  margin: 0em 0em;
`;

const isValid = (validationTexts: string[]): Boolean => {
  return validationTexts.length == 0;
};

interface IValidationSummaryProps {
  validationTexts: string[];
}

export const ValidationSummary = (props: IValidationSummaryProps) => {
  const { validationTexts } = props;

  return (
    <>
      {!isValid(validationTexts) && (
        <Container>
          {validationTexts.map((text, index) => {
            return (
              <ValidationText key={index}>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color={Color.red}
                  size={'sm'}
                  style={{ paddingRight: '0.5em' }}
                />
                {text}
              </ValidationText>
            );
          })}
        </Container>
      )}
    </>
  );
};
