import * as _ from 'lodash';
import { API_DATA_FAILURE, API_DATA_REQUEST, API_DATA_SUCCESS } from 'middleware/api';
import { IAPIModels } from 'models/apiModel';
import { removeInstructorFromCourse, REMOVE_INSTRUCTOR_FROM_COURSE } from 'store/course';
import { removeLearnerStat, removeLearnerStats, REMOVE_LEARNER_STAT, REMOVE_LEARNER_STATS } from 'store/learner-stats';
import { removeSet, REMOVE_SET } from 'store/set';
import { removeUserEmail, REMOVE_USER_EMAIL } from 'store/user-email';
import { createReducer } from 'utils/reducerUtilities';

const initialState: IAPIModels = {
  meta: {},
  sets: {},
  quizPartMultipleChoices: {},
  images: {},
  error: undefined
};

export default createReducer(initialState, {
  [API_DATA_SUCCESS]: apiDataSuccess,
  [API_DATA_REQUEST]: apiDataRequest,
  [API_DATA_FAILURE]: apiDataFailure,
  [REMOVE_LEARNER_STAT]: removeLearnerStat,
  [REMOVE_LEARNER_STATS]: removeLearnerStats,
  [REMOVE_INSTRUCTOR_FROM_COURSE]: removeInstructorFromCourse,
  [REMOVE_USER_EMAIL]: removeUserEmail,
  [REMOVE_SET]: removeSet
});

function apiDataSuccess(state, action) {
  const newState = _.merge(
    {},
    state,
    _.merge({}, action.response, {
      meta: { [action.endpoint]: { loading: false } }
    })
  );

  // replace the meta from each API call because we want fresh data
  if (action.response.meta) {
    const endpoint = action.endpoint.split('?')[0];
    newState.meta[endpoint] = action.response.meta[endpoint];
  }

  return newState;
}

function apiDataRequest(state, action) {
  return _.merge({}, state, {
    meta: { [action.endpoint]: { loading: true } }
  });
}

function apiDataFailure(state, action) {
  return _.merge({}, state, {
    error: action.error,
    meta: { [action.endpoint]: { loading: false } }
  });
}
