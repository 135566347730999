import { ThemeProvider } from '@mui/material';
import LoadingComponent from 'components/LoadingComponent';
import { MemreText } from 'components/core';
import { useGroup } from 'hooks/useGroup';
import { GroupMembersTable } from 'pages/admin-dashboard/groups/GroupMembersTable';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React from 'react';

type Props = {
  match: {
    params: {
      groupId: string;
    };
  };
};

export const GroupMembersPage: React.FC<Props> = ({ match }) => {
  const { groupId } = match.params;
  const { data: group, isLoading } = useGroup(groupId);

  return (
    <LoadingComponent
      isLoading={isLoading}
      component={
        <ThemeProvider theme={theme}>
          <div style={{ padding: '2rem' }}>
            <MemreText variant="overline">{group?.name}</MemreText>
            <MemreText variant="h4">Members</MemreText>
            <GroupMembersTable groupId={groupId} />
          </div>
        </ThemeProvider>
      }
    />
  );
};
