import { useLearnerStats } from 'hooks/useLearnerStats';
import { usePaginatedMeta } from 'hooks/usePaginatedMeta';
import { useEffect, useState } from 'react';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { LearnersTableState, useLearnersTableStore } from 'zstore';

type Signature = {
  isLoading: boolean;
  data: Array<any>;
  totalCount: number | null;
  totalPages: number | null;
};

export const useLearnersTableData = (courseId: string, assignmentId: string | null): Signature => {
  const [data, setData] = useState<any>([]);
  const pageSize = 10;
  const page = useLearnersTableStore((state: LearnersTableState) => state.page);
  const sort = useLearnersTableStore((state: LearnersTableState) => state.sort);
  const sortDirection = useLearnersTableStore((state: LearnersTableState) => state.sortDirection);
  const name = useLearnersTableStore((state: LearnersTableState) => state.name);
  const group = useLearnersTableStore((state: LearnersTableState) => state.group);

  const { data: response, isLoading } = useLearnerStats(
    courseId,
    sort,
    sortDirection,
    page,
    pageSize,
    name,
    group,
    assignmentId
  );
  const { totalCount, totalPages } = usePaginatedMeta(response);

  useEffect(() => {
    if (!!response) {
      setData(normalizeJSONAPIResponse(response.data));
    }
  }, [JSON.stringify(response)]);

  return { isLoading, data, totalCount, totalPages };
};
