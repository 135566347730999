import { TitleSection } from 'core/layout';
import { usePartnerUserTagTypes } from 'hooks/usePartnerUserTagTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getBaseReactUrl } from 'utils/pathUtils';

interface IProps {
  partnerId: string;
  userId: string;
}
export const ManageTagsLink = (props: IProps) => {
  const { t } = useTranslation();
  const { partnerId, userId } = props;
  const { data: tags, isLoading } = usePartnerUserTagTypes();

  const goToManageTags = () => {
    window.location.assign(`${getBaseReactUrl()}/app/nav/partners/${partnerId}/users/${userId}`);
  };

  if (isLoading || tags.length === 0) {
    return <></>;
  } else {
    return (
      <TitleSection>
        <a onClick={goToManageTags} href="#">
          {t('Manage Custom Fields')}
        </a>
      </TitleSection>
    );
  }
};
