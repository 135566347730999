export const quotes = [
  {
    name: 'Natalya O.',
    quote:
      'I am really enjoying the process of learning on Cerego. I am memorizing and doing so well in my class just from practicing on Cerego.',
  },
  {
    name: 'Ashley T.',
    quote:
      "I've always struggled to study subjects that I find hard, but I feel like I'm having fun when I'm using Cerego.",
  },
  {
    name: 'Sameeh K.',
    quote: "Cerego's very useful and entertaining. I feel that I would use it more than Facebook.",
  },
  {
    name: 'Ng H.',
    quote: "Cerego is exactly what I've been looking for all these years.",
  },
  {
    name: 'Kelly K.',
    quote: 'Cerego is awesome! It really helps me learn topics and concepts. I wish more classes utilized this.',
  },
  {
    name: 'Sarah D.',
    quote:
      "Cerego is a fantastic site that has helped me study and retain information that would've been hard to do by just reading the book.",
  },
  {
    name: 'David S.',
    quote:
      'It usually takes me quite awhile to learn keywords for a course. Cerego has helped me learn them in less than week. The review schedule is also very helpful and effective!',
  },
  {
    name: 'Sophie G.',
    quote:
      "It's a very easy method to learn. All the information is in one app, I don't have to search in different books about what I have to learn. ",
  },
  {
    name: 'Danielle',
    quote: 'I love that I can review wherever I am!',
  },
  {
    name: 'Jo',
    quote: 'Cerego has helped me tremendously in learning and retaining information learned in the course.',
  },
  {
    name: 'Jo',
    quote: "It's incredible how much I remember thanks to Cerego.",
  },
  {
    name: 'Laura C.',
    quote:
      "Cerego has been a lifesaver for me. I'm going through school while working full time. It's too time consuming to write flashcards by hand. I'm able to study without having to haul all my books around.",
  },
  {
    name: 'G. W.',
    quote: 'The right tool for the right job. Visually engaging. Prompts you with the right quizzes at the right time.',
  },
  {
    name: 'P.',
    quote:
      "I love that it automatically reminds you when you need to review. It's been so useful in studying and having it accessible on my phone makes it easy to review.",
  },
  {
    name: 'B.',
    quote:
      "This is by far one of the best study tools that I've used. If you have a lot of material that you need to become knowledgeable about then Cerego is right for you.",
  },
  {
    name: 'B.',
    quote: 'I remember more using Cerego for 2 weeks than I did spending 3 months studying.',
  },
  {
    name: 'Tye H.',
    quote: 'Love this app. Makes studying almost any subject much more manageable.',
  },
  {
    name: 'Cole S.',
    quote:
      'Easy. Simple. Effective. This is a great way to learn! A variety of question types are utilized to keep us engaged. Progress is tracked so we feel accomplished and rewarded while working towards attainable goal.s',
  },
  {
    name: 'Ari',
    quote:
      "I'm in love! Cerego makes my study time on my phone more efficient. Technology like this makes it easy for me to study on the go.",
  },
  {
    name: 'Lanes',
    quote: 'I wish I had this app in all my classes from the very start of college!',
  },
  {
    name: 'H.G.',
    quote:
      'Beyond looking good and being easy to use, Cerego makes learning engaging and, most importantly, addictive. ',
  },
  {
    name: 'E.M.',
    quote: 'I love that Cerego improves learning by making it easier and more fun to do.',
  },
  {
    name: 'Meg',
    quote: "Where has Cerego been all my life?! I'll never be able to study without it again.",
  },
  {
    name: 'A.',
    quote: "I love Cerego. It's easy for me to stay focused and not forget about my assignments.",
  },
  {
    name: 'R.',
    quote:
      "Be careful, I can't seem to put my iPad down since I downloaded Cerego's app. I think I need to go to rehab. ",
  },
  {
    name: 'A.',
    quote: "The flashcard app you've been looking for all your life.",
  },
  {
    name: 'Ian T.',
    quote: 'Cerego is far better than flashcards; very effective in my study of Hebrew vocabulary.',
  },
  {
    name: 'S. L.',
    quote:
      "This is exactly what I've been looking for. It uses science to make learning efficient and addicting. Brilliant.",
  },
  {
    name: 'S. L.',
    quote: 'Best learning app available. I was amazed by how much I learned and how fast!',
  },
  {
    name: 'Sonya',
    quote: "Lifesaver!! Don't know how I'd get through nursing school without it!!",
  },
  {
    name: 'B.',
    quote: "Life changing! Cerego is amazing and I'm constantly studying on my phone!",
  },
  {
    name: 'R.',
    quote: 'I recommend this for anyone still using flashcards - save your paper and ink. Try Cerego!',
  },
  {
    name: 'J.',
    quote:
      'My professor assigned Cerego sets as homework for our class, and I loved it. Cerego helped me use good study habits by encouraging me to study a little every day, and I love that I can study on my phone.',
  },
];
