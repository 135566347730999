import axios from 'axios';
import { useQuery } from 'react-query';

const SIZE = 200;

type ApiResponse = {
  data: any[];
  included: any[];
  links: Record<string, any>;
  meta: {
    'total-count': number;
    'payment-required-course-ids': number[];
    'total-pages': number;
  };
};

type AllResults = {
  data: any[];
  included: any[];
  links: Record<string, any>;
  meta: {
    'total-count': number;
    'payment-required-course-ids': number[];
  };
};

const getAllMyCourses = async (_key: string): Promise<AllResults> => {
  const allResults: AllResults = {
    data: [],
    included: [],
    links: {},
    meta: {
      'total-count': 0,
      'payment-required-course-ids': [],
    },
  };
  let hasMore = true;
  let currentPage = 1;

  while (hasMore) {
    const response = await axios.get<ApiResponse>(
      `/api/v3/my/courses?filter=${encodeURIComponent(
        '{"ready":true}'
      )}&include=image,partner,partner.partner-library&page[number]=${currentPage}&page[size]=${SIZE}&tab=progress`
    );

    const results = response.data;

    allResults.data = [...allResults.data, ...results.data];
    allResults.included = [...allResults.included, ...results.included];
    allResults.links = { ...allResults.links, ...results.links };
    allResults.meta['total-count'] += results.meta['total-count'];
    allResults.meta['payment-required-course-ids'] = [
      ...results.meta['payment-required-course-ids'],
      ...allResults.meta['payment-required-course-ids'],
    ];

    if (results.meta['total-pages'] !== currentPage) {
      currentPage += 1;
    } else {
      hasMore = false;
    }
  }
  return allResults;
};

export const useAllMyCourses = () => {
  return useQuery(['allMyCourses'], getAllMyCourses);
};
