import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageTitle } from 'core/layout';
import useMutateSet from 'hooks/useMutateSet';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useStore from 'zstore';

interface Props {
  assignment: any;
  courseId: string;
}

export const EditableName = (props: Props) => {
  const { assignment, courseId } = props;
  const [mutateSet] = useMutateSet(courseId);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(assignment?.name);
  const { t } = useTranslation();
  const { makeToast, clearToast } = useStore();

  useEffect(() => {
    if (assignment?.name) {
      setEditedName(assignment.name);
    }
  }, [assignment]);

  const handleNameClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(event.target.value);
  };

  const handleNameKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSaveChanges();
    }
  };

  const handleSaveChanges = async () => {
    if (editedName !== assignment.name) {
      try {
        await mutateSet({
          id: assignment?.id,
          payload: {
            data: {
              attributes: {
                name: editedName,
              },
            },
          },
        });
        setIsEditing(false);
        clearToast();
      } catch (error) {
        makeToast({
          text: t('Failed to update assignment name. Please try again.'),
          isError: true,
        });
      }
    } else {
      setIsEditing(false);
    }
  };

  return (
    <AssignmentName onClick={handleNameClick}>
      {isEditing ? (
        <EditInput
          type="text"
          value={editedName}
          onChange={handleNameChange}
          onKeyPress={handleNameKeyPress}
          onBlur={handleSaveChanges}
          autoFocus
        />
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span>{editedName}</span>
          <FontAwesomeIcon icon={faEdit} style={{ fontSize: '26px', paddingLeft: '20px' }} />
        </div>
      )}
    </AssignmentName>
  );
};

const AssignmentName = styled(PageTitle)`
  width: 100%;
  cursor: pointer;
  margin-left: 30px;
  &:hover {
    text-decoration: underline;
  }
`;

const EditInput = styled.input`
  font-size: inherit;
  font-weight: inherit;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  padding: 4px;
  width: 100%;
`;
