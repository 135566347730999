import * as React from 'react';
import styled from 'styled-components';
import { Color } from 'core';

interface IProps {
  top?: number;
  left?: number;
  style?: any;
  unwrapped?: boolean;
  children?: any;
  listItems?: IDropDownListItemProps[];
  close?: () => any;
}

interface IDropDownContainerProps {
  top?: number;
  left?: number;
}

const menuWidth = 15;

const DropDownContainer = styled.div`
  position: absolute;
  top: ${(props: IDropDownContainerProps) => props.top}em;
  left: ${(props: IDropDownContainerProps) => props.left}em;
  z-index: 11; // the tables uses z-index 1 to 10, so 11
  padding: 0em 0em 2em 0em;
`;

const DropDownInnerContainer = styled.div`
  background-color: ${Color.white};
  color: ${Color.lightBlack};
  font-size: 1rem;
  box-shadow: 0 0.3125em 0.625em 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.125em;
`;

export const DropDownList = styled.div`
  background: ${Color.white};
  list-style-type: none;
  text-align: left;
  width: ${menuWidth}em;
`;

interface IDropDownListItemProps {
  disabled?: boolean;
  click?: () => any;
  text?: string;
}

export const DropDownListItem = styled.div`
  border-bottom: solid 1px ${Color.veryLightGray};

  button {
    border: none;
    background-color: transparent;
    margin: 0px;
    text-decoration: none;
    color: ${(props: IDropDownListItemProps) => (props.disabled ? Color.textGray : 'inherit')};
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    padding: 1em;
    cursor: ${(props: IDropDownListItemProps) => (props.disabled ? 'default' : 'pointer')};
    pointer-events: ${(props: IDropDownListItemProps) => (props.disabled ? 'none' : 'auto')};
    text-align: left;
    font-family: inherit;
    font-size: inherit;

    &:hover {
      background: ${Color.primaryGray};

      :first-child {
        color: ${Color.primaryBlue};
      }
    }
    &:focus {
      background: ${Color.primaryGray};

      :first-child {
        color: ${Color.primaryBlue};
      }
    }
  }
`;
// tslint:disable

export const DropDown = (props: IProps) => {
  const left = props.left || 0.5;
  const top = props.top || 0.5;
  const { style, unwrapped, children, listItems } = props;

  const handleItemClick = (item: any) => {
    item.click();
    if (props.close) {
      props.close();
    }
  };

  return (
    <div style={{ position: unwrapped ? 'static' : 'relative' }}>
      <DropDownContainer style={style} top={top} left={left}>
        <DropDownInnerContainer>
          <DropDownList>
            {listItems &&
              listItems.map((item: any, i) => (
                <DropDownListItem disabled={item.disabled} key={i}>
                  <button onClick={() => handleItemClick(item)}>{item.text}</button>
                </DropDownListItem>
              ))}
          </DropDownList>
          {children || <></>}
        </DropDownInnerContainer>
      </DropDownContainer>
    </div>
  );
};
