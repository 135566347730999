import { useState } from 'react';

export function SiteAdminUserDetailNotifications(props: any) {
  const { user } = props;
  const [showNotifications, setShowNotifications] = useState(false);

  return (
    <>
      <h2>
        <a
          href="#"
          className="alt-button"
          onClick={(e) => {
            e.preventDefault();
            setShowNotifications(!showNotifications);
          }}
        >
          <span>
            {showNotifications ? '-' : '+'} Notifications ({user.notifications.length})
          </span>
        </a>
      </h2>

      {showNotifications && (
        <article>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Next Reminder (UTC)</th>
                  <th>Reminder(s) since last study</th>
                </tr>
              </thead>
              <tbody>
                {user.leStudyHabit ? (
                  <tr>
                    <td>{user.leStudyHabit.nextReminder}</td>
                    <td>{user.leStudyHabit.remindersSinceLastStudy}</td>
                  </tr>
                ) : (
                  <tr>
                    <td>Nothing here yet</td>
                    <td>Nothing here yet</td>
                  </tr>
                )}
              </tbody>
            </table>

            {user.notifications.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Notification</th>
                    <th>Type</th>
                    <th>Created at</th>
                    <th>Delivered at</th>
                    <th>Failed at</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {user.notifications.map((notification) => (
                    <tr key={notification.id}>
                      <td>{notification.id}</td>
                      <td>{notification.notificationType}</td>
                      <td>{notification.rpushNotificationType}</td>
                      <td>{notification.createdAt}</td>
                      <td>{notification.rpushNotification?.deliveredAt || 'N/A'}</td>
                      <td>{notification.rpushNotification?.failedAt || 'N/A'}</td>
                      <td>{JSON.stringify(notification.rpushNotification?.data)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </article>
      )}
    </>
  );
}
