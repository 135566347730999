import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CancelButton } from 'components/buttons/v4';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import useCreateSmartCourse from 'hooks/useCreateSmartCourse';
import { useSmartCourseForm } from 'hooks/useSmartCourseForm';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SMART_CREATE_TOPIC_PLACEHOLDERS } from 'utils/constants';
import { AnalyticsService } from 'utils/AnalyticsService';
import { BuildingSmartCourse } from 'views/course-create/smart-create/BuildingSmartCourse';
import { CurriculumForm } from 'views/course-create/smart-create/CurriculumForm';
import { IntroCard } from 'views/course-create/smart-create/IntroCard';

const PLACEHOLDER = 'e.g. '.concat(
  SMART_CREATE_TOPIC_PLACEHOLDERS[Math.floor(Math.random() * SMART_CREATE_TOPIC_PLACEHOLDERS.length)]
);

interface Props {
  handleCancel: () => void;
  handleSmartCreate: () => void;
}

export const SmartCreate = (props: Props) => {
  const { t } = useTranslation();
  const [isBuilding, setIsBuilding] = useState(false);
  const [createSmartCourse, { status: smartCourseCreationStatus }] = useCreateSmartCourse();
  const form = useSmartCourseForm((values) => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Smart Course Search', topic: values.topic });
    createSmartCourse({
      title: values.topic || PLACEHOLDER.replace('e.g. ', ''),
      level: values.level,
      language: values.language,
      pdf_file: values.pdfFile,
      assignments: values.assignments,
    });
  });
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [advancedOptionsVisible, setAdvancedOptionsVisible] = useState(false);

  const handleFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    formGroup: {
      marginBottom: theme.spacing(2),
    },
    formControl: {
      width: '100%',
    },
    input: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fileInput: {
      display: 'none',
    },
    uploadButton: {
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (['success', 'loading'].includes(smartCourseCreationStatus)) {
      setIsBuilding(true);
    }
  }, [smartCourseCreationStatus]);

  const handleCancel = () => {
    props.handleCancel();
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const handleSubmit = () => {
    if (!isBuilding) {
      form.handleSubmit();
    } else {
      console.warn('Cannot create course, course is already building.');
    }
  };

  const confirmIsDisabled: boolean = smartCourseCreationStatus === 'loading';

  const listenForEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !confirmIsDisabled) {
      handleSubmit();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      form.setPdfFile(event.target.files[0]);
    }
  };

  if (isBuilding) {
    return (
      <div>
        <Card style={{ marginBottom: '10px' }}>
          <BuildingSmartCourse title={form.topic} />
        </Card>
        <CancelButton click={reloadPage}>{t('Close')}</CancelButton>
      </div>
    );
  }

  return (
    <div>
      <Card>
        <IntroCard />
        <CardContent>
          <Typography variant="body1">
            {t(
              "Enter the details below to generate a custom course based on your preferences. You'll get a complete learning path tailored to your chosen topic, language, and difficulty level."
            )}
          </Typography>
        </CardContent>

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.formGroup}>
              <Typography variant="subtitle1" id="topic-header">
                {t('Topic')}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {t('Enter a topic you want to learn about, the more specific the better')}
              </Typography>
              <TextField
                className={classes.input}
                placeholder={PLACEHOLDER}
                autoFocus={true}
                onChange={(event) => form.setTopic(event.target.value)}
                onKeyPress={listenForEnterKey}
                required={true}
                fullWidth
                aria-labelledby="topic-header"
              />
            </Grid>

            <Grid container item xs={12} alignItems="center">
              <Grid item>
                <Switch
                  checked={advancedOptionsVisible}
                  onChange={() => setAdvancedOptionsVisible(!advancedOptionsVisible)}
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {advancedOptionsVisible ? 'Hide advanced options' : 'Show advanced options'}
                </Typography>
              </Grid>
            </Grid>

            <Collapse in={advancedOptionsVisible}>
              <Grid container spacing={3} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <Grid item xs={12} className={classes.formGroup}>
                  <Typography variant="subtitle1" id="language-header">
                    {t('Language')}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Choose the language you want to generate the course in
                  </Typography>
                  <TextField
                    className={classes.input}
                    placeholder="English"
                    onChange={(event) => form.setLanguage(event.target.value)}
                    required={true}
                    fullWidth
                    aria-labelledby="language-header"
                  />
                </Grid>

                <Grid item xs={12} className={classes.formGroup}>
                  <Typography variant="subtitle1" id="level-header">
                    {t('Level')}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Select the difficulty level of the course
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.input}
                      placeholder="Beginner"
                      onChange={(event) => form.setLevel(event.target.value)}
                      required={true}
                      fullWidth
                      aria-labelledby="level-header"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.formGroup}>
                  <CurriculumForm />
                </Grid>

                <Grid item xs={12} className={classes.formGroup}>
                  <Typography variant="subtitle1" id="pdf-header">
                    {t('PDF (optional)')}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Upload a PDF to include as additional learning material in the course
                  </Typography>
                  <>
                    <input
                      ref={inputFileRef}
                      className={classes.fileInput}
                      id="pdf-upload"
                      type="file"
                      accept="application/pdf"
                      onChange={handleFileChange}
                      aria-labelledby="pdf-header"
                    />
                    <Button
                      className={classes.uploadButton}
                      variant="contained"
                      color="primary"
                      onClick={handleFileClick}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload PDF
                    </Button>
                    {form.pdfFile && (
                      <Typography variant="caption" display="block" gutterBottom>
                        {form.pdfFile.name}
                      </Typography>
                    )}
                  </>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
      <ConfirmOrCancel
        cancelText={t('Cancel')}
        confirmText={t('Create')}
        handleCancelClick={handleCancel}
        handleConfirmClick={handleSubmit}
        confirmIsDisabled={confirmIsDisabled}
        confirmIsPending={false}
      />
    </div>
  );
};
