import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageBadge from 'components/images/ImageBadge';
import { imageShapeMap, ImageViewStyles } from 'components/ImageView';
import { Color } from 'core';
import { BaseButton, IButton } from 'core/button';
import { IImage } from 'models/image';
import * as React from 'react';
import styled from 'styled-components';

const ImageButton = styled(BaseButton)`
  width: 85px;
  height: 85px;
  border-radius: 20%;
  border: 1px ${Color.lightGray} solid;
  padding: 0;
  overflow: hidden;
`;
const RiderIcon = styled(FontAwesomeIcon)`
  position: absolute;
  display: block;
  padding: 0.75em;
  bottom: -0.75em;
  right: -0.5em;
  border: 1px ${Color.lightGray} solid;
  border-radius: 50%;
  background-color: white;
  color: ${Color.primaryBlue};
`;

export interface IAvatarButton extends IButton {
  image?: IImage | null;
  style?: any;
  imageContainerStyle?: any;
  entity?: string;
  canEdit?: any;
}

export default class AvatarButton extends React.Component<IAvatarButton> {
  public render() {
    const { canEdit = true, entity = 'course', style = {}, image, click, imageContainerStyle } = this.props;
    const onClick = canEdit ? click : () => { };

    return (
      <ImageButton type="button" onClick={onClick} style={ImageViewStyles[imageShapeMap[entity]]}>
        <ImageBadge
          image={image}
          entity={entity}
          style={Object.assign({ height: '100%', width: '100%' }, style)}
          imageContainerStyle={imageContainerStyle}
        />
        {canEdit && <RiderIcon icon={faCamera} />}
      </ImageButton>
    );
  }
}
