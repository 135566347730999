import Box from '@mui/material/Box';
import { MemreTrianglePointer } from './index';

type Props = {
  items: string[];
  justify?: 'space-around' | 'space-between';
  padding?: 'normal' | 'left';
};
export default function MemrePointer({ items, justify = 'space-around', padding = 'normal' }: Props) {
  const p = {
    normal: { px: '10%' },
    left: { pl: '30px' },
  }[padding];

  return (
    <Box sx={{ display: 'flex', justifyContent: justify, ...p, pb: 2 }}>
      {items.map((t) => (
        <MemreTrianglePointer key={t} text={t} />
      ))}
    </Box>
  );
}
