import axios from 'axios';
import { TableParmas } from 'models/table';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getCourses = async (_: string, { id, pageNumber, searchTerm, pageSize, sortByColumn }: TableParmas) => {
  const response = await axios.get(
    `/api/v3/my/courses?filter=${encodeURIComponent(
      '{"ready":true}'
    )}&include=image,partner,partner.partner-library&page[number]=${pageNumber}&page[size]=${pageSize}&query=${searchTerm}&sort=${sortByColumn}&tab=progress`
  );
  return normalizeJSONAPIResponse(response.data);
};

const useCoursesV3 = ({ id, pageNumber, searchTerm, pageSize, sortByColumn }: TableParmas) => {
  return useQuery(['myCoursesV3', { id, pageNumber, searchTerm, pageSize, sortByColumn }], getCourses, {
    enabled: !!id,
  });
};

export default useCoursesV3;
