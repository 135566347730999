import { MemreFlexBox, MemreIcon, MemreText } from 'components/core';
import * as Color from 'core/colors';
import { Component, ErrorInfo, ReactNode } from 'react';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <MemreFlexBox direction="column" align="center" justify="center" sx={{ height: '100%' }}>
          <MemreIcon iconName="alertTriangle" fontSize="2.5em" color={Color.reportRed} />
          <MemreText sx={{ mt: 1 }} variant="h6">
            Ooops. Something has gone wrong.
          </MemreText>
          <MemreText sx={{ color: Color.darkGray }}>Please try again later</MemreText>
        </MemreFlexBox>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
