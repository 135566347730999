import { TablePagination } from '@material-ui/core';
import { AddBox, ArrowDownward, ChevronLeft, ChevronRight, FirstPage, LastPage, Search } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material';
import { PrimaryButton } from 'components/buttons/v4';
import ImageBadge from 'components/images/ImageBadge';
import { Color } from 'core';
import { ContentText } from 'courses/components/ContentText';
import MaterialTable, { MTableBodyRow, MTableHeader } from 'material-table';
import { IImage } from 'models/image';
import { Fragment, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { stringSort } from 'utils/sortingUtils';

const TableContainer = styled.div`
  color: ${Color.textGray};
  background: ${Color.primaryGray};
`;

interface ILtiLinkResourceTableProps {
  data: any;
  course: any;
  linkResource: (id: any) => {};
}

export const LtiLinkResourceTable = (props: ILtiLinkResourceTableProps) => {
  const { t } = useTranslation();

  const renderContentTitle = (assignment: any) => {
    return (
      <div>
        <ContentText text={assignment.name} display="block" />
        <ContentText text={assignment.assignmentType} size="small" display="inline" />
      </div>
    );
  };

  const renderImageView = (assignmentType: string, image: IImage): any => {
    return <ImageBadge image={image} transcodedSize="small" entity={assignmentType} />;
  };

  const renderLinkResource = id => {
    return (
      <PrimaryButton
        click={() => {
          props.linkResource(id);
        }}
      >
        Link Resource
      </PrimaryButton>
    );
  };

  const getImageColumn = () => {
    return {
      title: '',
      field: 'image',
      cellStyle: {
        padding: '10px 0 10px 0'
      },
      render: (rowData: any) => renderImageView(rowData.assignmentType, rowData.image)
    };
  };

  const getTitleColumn = () => {
    return {
      title: t('Title'),
      field: 'name',
      cellStyle: {
        padding: '10px 0'
      },
      headerStyle: {
        paddingLeft: '5px'
      },
      render: (rowData: any) => renderContentTitle(rowData),
      customSort: stringSort('name', 'asc')
    };
  };

  const getLinkResourceColumn = () => {
    return {
      field: 'id',
      cellStyle: {},
      headerStyle: {},
      render: (rowData: any) => renderLinkResource(rowData)
    };
  };

  const getColumns = () => {
    return [getImageColumn(), getTitleColumn(), getLinkResourceColumn()];
  };

  const getComponents = () => {
    return {
      Header: (props: any) => <MTableHeader {...props} />,
      Pagination: (props: any) => <TablePagination {...props} style={{ display: 'none' }} />,
      Toolbar: () => null,
      Row: (props: any) => <MTableBodyRow draggable={false} {...props} />
    };
  };

  const defaultMaterialTheme = createTheme();

  return (
    <Fragment>
      <TableContainer>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            columns={getColumns()}
            actions={[]}
            data={props.data}
            components={getComponents()}
            title=""
            icons={{
              Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
              Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
              FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
              LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
              NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
              PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
              SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
            }}
            options={{
              headerStyle: {
                textTransform: 'uppercase',
                background: Color.primaryGray,
                paddingLeft: '0'
              },
              pageSize: props.data.length,
              actionsColumnIndex: -1
            }}
            style={{ boxShadow: 'none' }}
          />
        </ThemeProvider>
      </TableContainer>
    </Fragment>
  );
};
