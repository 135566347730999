import { faFont } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useIcEditorStore } from 'components/item-editor/instructional-content/store';
import { useCreateICTextAnnotation } from 'hooks/useCreateICTextAnnotation';
import { IItem } from 'models/item';
import { TAssignment } from 'types';

interface Props {
  item: IItem;
  assignment: TAssignment;
}

export const AddTextAnnotationButton = (props: Props) => {
  const { item, assignment } = props;
  const { addIcAnnotation, setIsCreatingAnnotation } = useIcEditorStore();
  const [createTextAnnotation] = useCreateICTextAnnotation(item.id, assignment.id);

  const useStyles = makeStyles((theme) => ({
    annotationButton: {
      paddingHorizontal: theme.spacing(4),
      paddingVertical: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  const handleAddTextClick = async () => {
    setIsCreatingAnnotation(true);
    try {
      const newAnnotation = await createTextAnnotation({ itemId: item.id });
      if (!newAnnotation) {
        console.warn('No new text annotation was created');
        return;
      }

      addIcAnnotation(newAnnotation);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreatingAnnotation(false);
    }
  };

  return (
    <Button
      className={classes.annotationButton}
      variant="outlined"
      color="primary"
      startIcon={<FontAwesomeIcon icon={faFont} />}
      onClick={handleAddTextClick}
    >
      Text
    </Button>
  );
};
