import React from 'react';
import { Switch } from 'react-router';
import { SignInPage } from './SignInPage';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import { CourseRegistrationPage } from './CourseRegistrationPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { NativeAppLauncherPage } from './NativeAppLauncherPage';
import { ConfirmEmailPage } from './ConfirmEmailPage';
import { UnauthenticatedLayoutRoute } from './UnauthenticatedLayoutRoute';
import { PartnerRegistrationPage } from './PartnerRegistrationPage';

export const nativeAppLauncherPath = '/signin/native-app-launcher';

export const UnauthenticatedPageContainer = React.memo(() => {
  return (
    <>
      <Switch>
        <UnauthenticatedLayoutRoute exact path="/signin" component={SignInPage} showSignIn={false} />
        <UnauthenticatedLayoutRoute exact path={nativeAppLauncherPath} component={NativeAppLauncherPage} />
        <UnauthenticatedLayoutRoute exact path="/signin/reset-password" component={ResetPasswordPage} />
        <UnauthenticatedLayoutRoute exact path="/signin/confirm-email" component={ConfirmEmailPage} />
        <UnauthenticatedLayoutRoute exact path="/signin/forgot-password" component={ForgotPasswordPage} />

        <UnauthenticatedLayoutRoute exact path="/join/:partnerSlug/:courseSlug" component={CourseRegistrationPage} />
        <UnauthenticatedLayoutRoute exact path="/join/courses" component={CourseRegistrationPage} />
        <UnauthenticatedLayoutRoute exact path="/join/partners" component={PartnerRegistrationPage} />
      </Switch>
    </>
  );
});
