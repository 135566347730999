import { CALL_API } from 'middleware/api';

export const handleGetInvoices = partnerId => {
  return {
    [CALL_API]: {
      endpoint: `/api/v4/partners/${partnerId}/invoices`,
      httpMethod: 'GET'
    }
  };
};
