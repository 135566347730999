import { MemreContainer, MemreFlexBox, MemrePeopleList } from 'components/core';
import { MemreBubblePlot } from 'components/visualisations';
import { useTranslation } from 'react-i18next';
import getMedian from 'utils/getMedian';
import AreaHeading from '../learner-dashboard/visulisations/AreaHeading';
import LearnMore from '../learner-dashboard/visulisations/LearnMore';

const OVERLINE = {
  STUDENTS: 'Goal Progress',
  SETS: 'Goal Progress',
};

export default function GoalProgress({ data, isStudentsView }) {
  const { t } = useTranslation();

  const distanceToGoal = data.map((d) => d.distanceToGoal);

  const median = getMedian(distanceToGoal);

  const filteredData = data.filter((d) => d.distanceToGoal > 0 && d.distanceToGoal < 100);
  const completedData = data.filter((d) => d.distanceToGoal >= 100);

  const heading = t(`${median}% (${completedData.length} out of ${data.length} completed)`);
  const overline = t(isStudentsView ? OVERLINE.STUDENTS : OVERLINE.SETS);

  return (
    <MemreFlexBox direction="column" sx={{ flexGrow: 1 }}>
      <MemreFlexBox justify="space-between" align="center">
        <AreaHeading overline={overline} heading={heading} />
        <LearnMore width={500}>
          <AreaHeading overline={overline} heading={heading} />
          <MemrePeopleList
            data={data}
            denomination="%"
            fields={['distanceToGoal']}
            fieldNames={['%']}
            topNumber={100}
          />
        </LearnMore>
      </MemreFlexBox>
      <MemreContainer sx={{ gap: 2, pr: '5px', justifyContent: 'space-between' }}>
        <MemreBubblePlot data={filteredData} median={median} topNumber={100} />
      </MemreContainer>
    </MemreFlexBox>
  );
}
