import { Color } from 'core';
import styled from 'styled-components';

interface IListIconProps {
  color?: string;
}

export const BoldText = styled.span`
  font-weight: bold;
`;

export const BlueText = styled.span`
  color: ${Color.primaryBlue};
`;

export const CenteredText = styled.div`
  text-align: center;
`;

export const ModalHeader = styled.div`
  font-size: 1.875em;
  margin-bottom: 0.5em;
`;

export const Box = styled.div`
  color: ${Color.darkGray}
  background: ${Color.white};
  border: 1px solid ${Color.veryLightGray};
  border-radius: 0.125em;
  margin-bottom: 1em;
`;

export const ClickableBox = styled(Box)`
  &:hover {
    box-shadow: 0 0.3125em 0.625em 0 rgba(0, 0, 0, 0.2);
    .hover-effect {
      color: ${Color.primaryBlue};
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 50em;
`;

export const Content = styled.div`
  padding: 1em;
`;

export const ContentHeading = styled(Content)`
  border-bottom: 1px solid ${Color.veryLightGray};
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 0.5em;
  }
`;

export const ListIcon = styled.div`
  display: inline-block;
  margin-right: 0.5em;
  color: ${(props: IListIconProps) => (props.color ? props.color : 'inherit')};
`;

export const CheckBoxGrid = styled.div`
  display: grid;
  grid-template-columns: 2em 1fr;
  grid-gap: 0.25em;
  div {
    padding-top: 0.1875em; // override
  }
`;

export const SpacedOut = styled.div`
  > * {
    display: inline-block;
    margin-right: 1em;

    &:last-child {
      margin: 0em;
    }
  }
`;
