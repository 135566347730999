import TextField from '@material-ui/core/TextField';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { useItemEditorStore } from 'components/item-editor/store';
import { useMutateConcept } from 'hooks/useMutateConcept';
import React, { useEffect, useState } from 'react';
import { ConceptImage } from '../ConceptImage';
import { ConceptSound } from '../ConceptSound';
import { useAutosaveStatus } from 'hooks/useAutosaveStatus';
import { useCreateFacet } from 'hooks/useCreateFacet';
import { TConcept } from 'types';
import { AnswersFormFields } from '../AnswersFormFields';

export const FlashcardBacksFormFields = () => {
  const { activeItem: item } = useItemEditorStore();

  const associations = item?.facets.map((facet) => facet.association) ?? [];

  return (
    <>
      {associations.map((association, index) => (
        <div key={association.id}>
          <FlashcardBack associationConcept={association} backNumber={index + 1} />
        </div>
      ))}
      <AddFlashcardBackButton />
    </>
  );
};

interface FlashcardBackProps {
  associationConcept: TConcept;
  backNumber: number;
}

const AddFlashcardBackButton = () => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const [createFacet] = useCreateFacet(item?.id || '', assignment?.id || '');

  if (!item) {
    return null;
  }

  const handleClick = async () => {
    const facet = await createFacet({
      itemId: item.id,
    } as any);

    updateActiveItem({
      facets: [...item.facets, facet],
    });
  };

  return (
    <div>
      <button onClick={handleClick}>Add Flashcard Back</button>
    </div>
  );
};

const FlashcardBack = (props: FlashcardBackProps) => {
  const { associationConcept, backNumber } = props;
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const [conceptText, setConceptText] = useState(associationConcept.text);
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useAutosaveStatus();

  const handleConceptTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConceptText(event.target.value);
  };

  // update concept text automatically on changed input
  // debounce to avoid making too many requests
  useEffect(() => {
    if (item == null) {
      return;
    }
    if (conceptText !== associationConcept.text) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateConcept({
          id: associationConcept.id,
          payload: {
            data: {
              attributes: {
                'text-html': conceptText,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));

        // update app state (This must be done after sending the mutation request)

        const updatedFacets = item.facets.map((facet) =>
          facet.association?.id === associationConcept.id
            ? {
                ...facet,
                association: {
                  ...facet.association,
                  text: conceptText,
                },
              }
            : facet
        );
        updateActiveItem({ facets: updatedFacets });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    conceptText,
    item?.id,
    mutateConcept,
    associationConcept.text,
    item,
    associationConcept.id,
    setAutosaveStatus,
    updateActiveItem,
  ]);

  return (
    <>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <TextField
          label={`Back ${backNumber} of Flashcard`}
          value={conceptText}
          onChange={handleConceptTextChange}
          placeholder={'Enter the back of the flashcard'}
          variant="outlined"
          style={{
            fontSize: '24px',
            fontWeight: 500,
            padding: '5px 0',
            width: '100%',
          }}
        />
        <ConceptImage concept={associationConcept} />
        <ConceptSound concept={associationConcept} />
      </div>
      <AutosaveStatus status={autosaveStatus} />
      <div>
        <AnswersFormFields
          wrongAnswersOnly={true}
          conceptIdForDistractors={associationConcept.id}
          title={`Wrong Answer Options For Back ${backNumber} of the Flashcard`}
        />
      </div>
    </>
  );
};
