import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const ITEM_INCLUDES: string = 'image,sound';

const getDistractors = async (_key: string, conceptId: string) => {
  const response = await axios.get(
    `/api/v3/concepts/${conceptId}/distractors?include=${ITEM_INCLUDES}&include_unstudiable=true`
  );
  return normalizeJSONAPIResponse(response.data);
};

export const useDistractors = (conceptId: string) => {
  return useQuery(['distractors', conceptId], getDistractors, {
    enabled: conceptId,
    refetchOnWindowFocus: false,
  });
};
