import { AttachIc } from 'components/item-editor/AttachIc';
import { CautionTape } from 'components/item-editor/CautionTape';
import { AnswersFormFields } from 'components/item-editor/AnswersFormFields';
import { GraduatedDifficultyFormFields } from 'components/item-editor/GraduatedDifficultyFormFields';
import { QuestionFormFields } from 'components/item-editor/question-and-answer/QuestionFormFields';
import { useItemEditorStore } from 'components/item-editor/store';
import styled from 'styled-components';

export const QuestionAndAnswerEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }
  const isSurvey = assignment?.goalType === 'survey';

  return (
    <Container>
      <QuestionFormFields />
      <CautionTape />
      <AnswersFormFields />
      {!isSurvey && (
        <>
          <AttachIc />
          <GraduatedDifficultyFormFields />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
