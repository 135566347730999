import axios from 'axios';
import { queryCache, useMutation } from 'react-query';

const deleteAssignment = ({ courseId, setId }) => {
  return axios.delete(`/api/v3/courses/${courseId}/sets/${setId}`);
};

const useDeleteAssignment = () => {
  return useMutation(deleteAssignment, {
    onSuccess: () => {
      queryCache.invalidateQueries('assignments');
    },
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
    }
  });
};

export default useDeleteAssignment;
