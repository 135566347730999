import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BrightInput } from 'components/forms/BrightInput';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Color } from 'core';

const PositionContainer = styled.div`
  position: fixed;
  top: 1.5em;
`;

const EmailContainer = styled.div`
  color: ${Color.darkGray}
  background: ${Color.white};
  border: 1px solid ${Color.veryLightGray};
  border-radius: 0.125em;
  width: 50em;
  padding: 1em;
`;

const TitleContainer = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: ${Color.lightBlack}
  border-bottom: 1px solid ${Color.veryLightGray};
  padding-bottom: 1em;
`;

const InputContainer = styled.div`
  margin-top: 20px;
  text-align: left;
  font-size: 1.25em;
`;

interface IProps {
  userId: string;
  userEmail: string;
  courseId: string;
  close: () => void;
}

export const EmailLearnerModal = React.memo<IProps>((props) => {
  const [userId, setUserId] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setUserId(props.userId);
  }, []);

  async function handleSendEmailClick() {
    await axios.post(`/api/v3/courses/${props.courseId}/mailers`, { recipients: [userId], subject, body });

    props.close();
  }

  function handleSubjectChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSubject(event.target.value);
  }

  function handleBodyChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setBody(event.target.value);
  }

  return (
    <PositionContainer>
      <EmailContainer>
        <TitleContainer>
          <div>{t('Email Learners Modal Title Text', { userEmail: props.userEmail })}</div>
        </TitleContainer>
        <InputContainer>
          <div>{t('Subject')}</div>
          <BrightInput placeholder="General Education 101" onChange={handleSubjectChange} />
        </InputContainer>
        <InputContainer>
          <div>{t('Write your message')}</div>
          <BrightInput placeholder="Keep up the good work!" onChange={handleBodyChange} />
        </InputContainer>
        <ConfirmOrCancel
          cancelText={t('Cancel')}
          confirmText={t('Send Email')}
          handleCancelClick={props.close}
          handleConfirmClick={handleSendEmailClick}
          confirmIsDisabled={false}
          confirmIsPending={false}
        />
      </EmailContainer>
    </PositionContainer>
  );
});
