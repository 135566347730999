import useFormattedAssignmentType from 'hooks/useFormattedAssignmentType';
import { useTranslation } from 'react-i18next';

const useAssignmentLabel = (assignmentType: string, isShared: boolean = false, isMirrored: boolean = false) => {
  const { t } = useTranslation();
  const formattedAssignmentType: string = useFormattedAssignmentType(assignmentType);

  if (isMirrored) {
    return formattedAssignmentType.concat(t(' (mirrored)'));
  }

  if (isShared) {
    return formattedAssignmentType.concat(t(' (shared)'));
  }

  return formattedAssignmentType;
};

export default useAssignmentLabel;
