import partnerLibraryBgImage from 'assets/images/default-learning-library.png';
import { MemreContainer, MemreFlexBox, MemreSection, MemreText } from 'components/core';
import { Color } from 'core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePartnerLibrary } from 'hooks/usePartnerLibrary';
import { useTranslation } from 'react-i18next';
import { getBaseReactUrl } from 'utils/pathUtils';

export default function PartnerLibraryCTA() {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { data: partnerLibrary } = usePartnerLibrary();
  const partnerId = user?.primaryPartner.id;
  const partnerLibraryLabel = user?.primaryPartner.partnerSettings.libraryEnabled
    ? user?.primaryPartner.partnerLibrary?.name
    : null;
  const partnerLibraryCoursesCount = partnerLibrary?.coursesCount || 0;

  const goToPartnerLibrary = () => {
    window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerId}/learning_library`;
  };

  return (
    (!!partnerLibraryLabel && partnerLibraryCoursesCount > 0 && (
      <MemreSection sx={{ flex: 1, cursor: 'pointer', borderRadius: '6px' }}>
        <div onClick={goToPartnerLibrary}>
          <MemreContainer
            size="large"
            sx={{
              backgroundImage: `url(${partnerLibraryBgImage})`,
              backgroundSize: 'cover',
              borderRadius: '6px',
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
            }}
          >
            <MemreFlexBox direction="column" gap={2}>
              <MemreText variant="h4" sx={{ color: Color.white }}>
                {partnerLibraryLabel}
              </MemreText>
              <MemreText sx={{ color: Color.white }}>
                {t('Enroll in optional courses provided by your organization →')}
              </MemreText>
            </MemreFlexBox>
          </MemreContainer>
        </div>
      </MemreSection>
    )) || <></>
  );
}
