import { ResponsiveBar } from '@nivo/bar';
import { Color } from 'core';
import React from 'react';
import styled from 'styled-components';

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: ${Color.textGray};
  padding: 0.5em;
  max-width: 25em;
`;
const Responses = styled.strong`
  color: ${Color.lightBlack};
`;

const RatingContainer = styled.div`
  width: inherit;
  height: inherit;
  position: relative;
`;
const Legend = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 55px 30px;
  margin: 0;
  width: auto;
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: 0.7em;
  font-weight: 600;
  color: ${Color.textGray};
`;
const segmentDisplayOffset = 0.01;

interface IOwnProps {
  data: object[];
  scale: string[];
  title?: string;
  sub?: string;
  height?: string;
}

export class Rating extends React.Component<IOwnProps> {
  public render() {
    const { data, scale } = this.props;

    if (!data[0]) {
      return null;
    } // No data, no chart

    const ratingTotal = scale.reduce((total, key) => total + data[0][key], 0);
    const margins = { top: 0, right: 50, bottom: 30, left: 50 };
    const chartProps: any = {
      data: this.normalizeRatingData(data, scale, ratingTotal),
      indexBy: 'questionText',
      layout: 'horizontal',
      keys: scale,
      innerPadding: 4,
      margin: margins,
      padding: 0.3,
      label: ({ value }) => this.normalizeRatingLabels(value),
      labelTextColor: '#FFF',
      borderRadius: 3,
      borderWidth: 0.5,
      borderColor: { from: 'color', modifiers: [['darker', 0.5]] },
      colors: Color.diverging,
      animate: true,
      axisLeft: null,
      axisBottom: null,
      enableGridY: false,
      tooltip: ({ indexValue, value }) => this.normalizeRatingTooltips(value, ratingTotal)
    };

    return (
      <RatingContainer>
        <ResponsiveBar {...chartProps} />
        <Legend>
          <li>{scale[0]}</li>
          <li>{scale[scale.length - 1]}</li>
        </Legend>
      </RatingContainer>
    );
  }
  public normalizeRatingData(data, scale, ratingTotal) {
    return [
      scale.reduce(
        (dataObj, key) =>
          Object.assign(dataObj, {
            [key]: ratingTotal ? data[0][key] / ratingTotal + segmentDisplayOffset : segmentDisplayOffset
          }),
        {}
      )
    ];
  }
  public normalizeRatingLabels(value) {
    const labelVal = Math.round((value - segmentDisplayOffset) * 100);

    return labelVal ? labelVal + '%' : null;
  }
  public normalizeRatingTooltips(value, ratingTotal) {
    const responseSum = Math.round((value - segmentDisplayOffset) * ratingTotal);
    const s = responseSum !== 1 ? 's' : '';

    return (
      <Tooltip>
        <Responses>
          {responseSum} Response{s}
        </Responses>
      </Tooltip>
    );
  }
}
