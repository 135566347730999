import { useState } from 'react';

export function SiteAdminUserDetailPartnerRoles(props: any) {
  const { user } = props;
  const [showRoles, setShowRoles] = useState(false);

  return (
    <>
      <h2>
        <a
          href="#"
          className="alt-button"
          onClick={(e) => {
            e.preventDefault();
            setShowRoles(!showRoles);
          }}
        >
          <span>
            {showRoles ? '-' : '+'} Partner Roles ({user.partnerUserRoles.length})
          </span>
        </a>
      </h2>
      {showRoles && (
        <article>
          <div style={{ textAlign: 'left' }}>
            <table>
              <thead>
                <tr>
                  <th>Partner ID</th>
                  <th>Partner Name</th>
                  <th>Partner Role</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {user.partnerUserRoles.map((partnerUserRole) => (
                  <tr key={partnerUserRole.id}>
                    <td>
                      <a href={`#`} target="_blank" rel="noopener noreferrer">
                        {partnerUserRole.partner.id}
                      </a>
                    </td>
                    <td>{partnerUserRole.partner.name}</td>
                    <td>{partnerUserRole.role}</td>
                    <td>{partnerUserRole.partner.accountType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      )}
    </>
  );
}
