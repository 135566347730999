import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

// TODO: consolidate with useGetCourses
const getCourses = async (_key: string, partnerId: string) => {
  const response = await axios.get(
    `/api/v4/partners/${partnerId}/courses?limited=true&include=sets&page[number]=1&page[size]=${MAX_PAGE_SIZE}&status_filter=all&sort=-last_updated`
  );
  return normalizeJSONAPIResponse(response.data);
};

export const useCourses = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;

  return useQuery(['courses', partner.id], getCourses, {
    enabled: partner,
  });
};
