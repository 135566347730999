import ImageBadge from 'components/images/ImageBadge';
import useMutateSet from 'hooks/useMutateSet';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useStore from 'zstore';

interface Props {
  assignment: any;
  courseId: string;
}

export const ImageUploader = (props: Props) => {
  const { assignment, courseId } = props;
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [mutateSet] = useMutateSet(courseId);
  const { makeToast } = useStore();
  const { t } = useTranslation();

  const handleFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        const imageResponse = await fetch('/api/v3/images', {
          method: 'POST',
          body: formData,
        });
        const imageData = await imageResponse.json();
        const imageId = imageData.data.id;
        await mutateSet({
          id: assignment?.id,
          payload: {
            data: {
              attributes: {
                icon_image_id: imageId,
              },
            },
          },
        });
      } catch (err) {
        console.error(err);
        makeToast({
          text: t('Uh-oh! Something went wrong. Please try again.'),
          isError: true,
        });
      }
    }
  };

  return (
    <ImageBadge
      entity="course"
      image={assignment?.image}
      style={{ height: '75px', width: '75px', position: 'relative' }}
      onEdit={handleFileClick}
    >
      <input
        ref={inputFileRef}
        id="image-upload"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        aria-labelledby="image-upload"
        style={{ display: 'none' }}
      />
    </ImageBadge>
  );
};
