import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CancelButton, PrimaryButton } from 'components/buttons/v4';
import { ModalPosition } from 'components/Modal';
import { Color } from 'core';
import { Card, CardHeader, CardHeaderContainer, PageTitle } from 'core/layout';
import { BlueText, List, ListIcon, ModalHeader, SpacedOut } from './common';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

interface IProps {
  courseName: string;
  libraryName: string;
  onCancel: () => void;
  onPublish: (inLibrary, inviteLearners, shareAssignments) => void;
}

export const LibraryCourse = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>
          <b>{t("You're about to publish:")}</b> {props.courseName}
        </CenterTitle>
      </ModalHeader>
      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('What happens when you publish to your learning library?')}</CardHeader>
        </CardHeaderContainer>
        <div>
          <List>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              <Trans i18nKey="Library Course Publish Modal Item">
                Your course will be available in the <BlueText>{props.libraryName}</BlueText>
              </Trans>
            </li>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('Anyone will be able to opt-in and study your course')}
            </li>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('You can view all usage and progress in the gradebook and analytics tools')}
            </li>
          </List>
        </div>
      </Card>
      <SpacedOut>
        <CancelButton click={props.onCancel}>{t('Cancel')}</CancelButton>
        <PrimaryButton click={() => props.onPublish(true, false, false)}>{t('Publish')}</PrimaryButton>
      </SpacedOut>
    </ModalPosition>
  );
};
