import Chip from '@mui/material/Chip';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { usePartnerSummaryCounts } from 'hooks/usePartnerSummaryCounts';
import { AddUserModal } from 'pages/admin-dashboard/people/AddUserModal';
import { CsvUploadModal } from 'pages/admin-dashboard/people/CsvUploadModal';
import { useState } from 'react';

export const Header = () => {
  const { data: partnerSummaryCounts } = usePartnerSummaryCounts();
  const userCount = partnerSummaryCounts?.usersCount;
  const plusDepartmentsCount = partnerSummaryCounts?.usersCountIncludingDepartments - partnerSummaryCounts?.usersCount;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);

  // TODO: make departments chip clickable to navigate to departments page
  return (
    <>
      <MemreFlexBox gap={4} direction="row" justify="space-between" align="center">
        <div>
          <MemreText variant="h3">People</MemreText>
          <MemreFlexBox gap={1} direction="row" align="center">
            <MemreText variant="subtitle2">{userCount} active accounts</MemreText>
            <Chip label={`${plusDepartmentsCount} in departments`} size="small" color="secondary" variant="outlined" />
          </MemreFlexBox>
        </div>
        <MemreFlexBox gap={2} direction="row" align="center">
          <MemreButton
            text="Add by Email"
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => setIsModalOpen(true)}
          />
          <MemreButton
            text="Add/Remove via CSV"
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => setIsCsvModalOpen(true)}
          />
        </MemreFlexBox>
      </MemreFlexBox>

      {/* Add User Modal */}
      {isModalOpen && <AddUserModal onClose={() => setIsModalOpen(false)} />}
      {isCsvModalOpen && <CsvUploadModal onClose={() => setIsCsvModalOpen(false)} />}
    </>
  );
};
