import { CALL_API } from 'middleware/api';

export const handleGetGroups = partnerId => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/partners/${partnerId}/groups`,
      httpMethod: 'GET'
    }
  };
};
