import { RESET_ERROR } from 'middleware/api';

// Resets the currently visible error message.
export const resetError = () => {
  return {
    type: RESET_ERROR
  };
};

// Updates error message to notify about the failed fetches.
export default function(state = null, action) {
  const { type, error } = action;
  if (type === RESET_ERROR) {
    return null;
  } else if (error) {
    return error;
  }
  return state;
}
