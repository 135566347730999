import { Autocomplete, FormControl, InputLabel, MenuItem, TextField } from '@mui/material';
import Select from '@mui/material/Select';

export default function MemreSelectMenu({ items, onChange, value, label, withSearch = false, displayEmpty = false }) {
  return (
    <FormControl fullWidth={true}>
      {withSearch ? (
        <Autocomplete
          disablePortal={true}
          color="secondary"
          options={items}
          getOptionLabel={(option: { label: string; value: string }) => option.label}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      ) : (
        <>
          <InputLabel color="secondary">{label}</InputLabel>
          <Select color="secondary" value={value} label={label} onChange={onChange} displayEmpty={displayEmpty}>
            {items.map((item: any) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </FormControl>
  );
}
