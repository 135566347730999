import { ThemeProvider } from '@mui/material';
import { MemreFlexBox, MemreHeader, MemrePageContainer } from 'components/core';
import { Nav } from 'pages/admin-dashboard/Nav';
import { UserMetricsTable } from 'pages/admin-dashboard/UserMetricsTable';
import theme from 'pages/learner-dashboard/visulisations/theme';

export const AdminDashboard = () => {
  return (
    <ThemeProvider theme={theme}>
      <MemreHeader text="Admin Dashboard" />
      <MemrePageContainer>
        <MemreFlexBox gap={4} direction="column" sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Nav />
          <UserMetricsTable />
        </MemreFlexBox>
      </MemrePageContainer>
    </ThemeProvider>
  );
};

// TODO: touch up, make pretty
