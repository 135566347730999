import { TablePagination } from '@material-ui/core';
import { AddBox, ArrowDownward, ChevronLeft, ChevronRight, FirstPage, LastPage, Search } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material';
import { Color } from 'core';
import MaterialTable, { Action, MTableHeader } from 'material-table';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { stringSort } from '../../utils/sortingUtils';

interface ITableProps {
  columns: any;
  actions?: Array<Action<object>>;
  data: any;
  toolbar: any;
  filterValue: string;
  enablePagination: boolean;
  disableSearch?: boolean;
  emptyValue?: any;
  selection?: boolean;
  parentChildData?: any;
  headerFontSize?: string;
}

const TableContainer = styled.div`
  background-color: white;
`;

export const Table = React.memo<ITableProps>((props) => {
  const disableSearch = props.disableSearch || false;
  const { columns, data, enablePagination, parentChildData, selection, headerFontSize } = props;
  const totalRowCount = (props.data || []).length;
  const { t } = useTranslation();

  // Filter to sorted columns, and order them as rendered
  if (columns && data) {
    const sortedColumns = columns.filter((column) => column.defaultSort).reverse();

    // Align the default sorting strategy with the first sorted column
    if (sortedColumns.length > 0) {
      data.sort(sortedColumns[0].customSort || stringSort(sortedColumns[0].field, sortedColumns[0].defaultSort));
    }
  }

  let rowsPerPageOptions: number[];
  if (totalRowCount <= 10) {
    rowsPerPageOptions = [totalRowCount];
  } else if (totalRowCount <= 25) {
    rowsPerPageOptions = [10, totalRowCount];
  } else if (totalRowCount <= 100) {
    rowsPerPageOptions = [10, 25, totalRowCount];
  } else {
    rowsPerPageOptions = [10, 25, 100, totalRowCount];
  }

  const defaultMaterialTheme = createTheme();

  return (
    <TableContainer>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          columns={columns}
          actions={props.actions}
          components={{
            Header: (props) => <MTableHeader {...props} />,
            Pagination: (props) =>
              enablePagination ? <TablePagination {...props} rowsPerPageOptions={rowsPerPageOptions} /> : null,
            Toolbar: props.toolbar,
          }}
          data={data}
          title=""
          icons={{
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          }}
          options={{
            rowStyle: {
              color: Color.reportGray,
            },
            headerStyle: {
              textTransform: 'uppercase',
              background: Color.veryLightBlue,
              fontWeight: 700,
              fontSize: headerFontSize,
            },
            search: !disableSearch,
            pageSize: enablePagination ? rowsPerPageOptions[0] : totalRowCount,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            selection,
          }}
          parentChildData={parentChildData}
          style={{ boxShadow: 'none' }}
          localization={{
            toolbar: {
              searchPlaceholder: t('All'),
            },
            body: {
              emptyDataSourceMessage: props.emptyValue ?? t('No records to display'),
            },
          }}
        />
      </ThemeProvider>
    </TableContainer>
  );
});
