import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getUserCourses = async (key: string, partnerId: string, userId: string, isOwnProfile: boolean) => {
  if (isOwnProfile) {
    return getProfileUserCourses();
  } else {
    return getPartnerUserCourses(partnerId, userId);
  }
};
const getPartnerUserCourses = async (partnerId: string, userId: string, currentPage: number = 1,  allCourses = []) => {
  const endpoint: string = `/api/v3/courses?&filter[partner_id]=${partnerId}&user_id=${userId}&page[number]=${currentPage}`;
  const response = await axios.get(endpoint);
  const courses = normalizeJSONAPIResponse(response.data);
  allCourses = allCourses.concat(courses);

  const totalPages = response?.data?.meta?.['total-pages'];
  if ((totalPages ?? 1) > currentPage) {
    return await getPartnerUserCourses(partnerId, userId, ++currentPage, allCourses)
  } else {
    return allCourses;
  }
}

const getProfileUserCourses = async (currentPage: number = 1,  allCourses = []) => {
  const endpoint: string = `/api/v3/my/courses?page[number]=${currentPage}`;
  const response = await axios.get(endpoint);
  const courses = normalizeJSONAPIResponse(response.data);
  allCourses = allCourses.concat(courses);

  const totalPages = response?.data?.meta?.['total-pages'];
  if ((totalPages ?? 1) > currentPage) {
    return await getProfileUserCourses(++currentPage, allCourses)
  } else {
    return allCourses;
  }
}

export const useUserCourses = (partnerId: string, userId: string, isOwnProfile: boolean = false) => {
  return useQuery(['userCourses', partnerId, userId, isOwnProfile], getUserCourses, {
    enabled: partnerId && userId
  });
};
