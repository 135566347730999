import React from 'react';
import { Typography } from '@material-ui/core';
import LoadingComponent from 'components/LoadingComponent';
import { PageContainer, PageTitle } from 'core/layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { PartnerSettingsForm } from './PartnerSettingsForm';

export const ControlPanel = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const isSiteAdmin = user?.meta.isAdmin;

  if (user && !isSiteAdmin) {
    return (
      <PageContainer>
        <PageTitle>Control Panel</PageTitle>
        <Typography variant="h5">You are not authorized to view this page.</Typography>
      </PageContainer>
    );
  }

  return (
    <LoadingComponent
      isLoading={!user}
      component={
        <PageContainer style={{ margin: '20px' }}>
          <PageTitle>Control Panel</PageTitle>
          <PartnerSettingsForm title={partner?.name} partner={partner} />
        </PageContainer>
      }
    />
  );
};
