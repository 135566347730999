import * as React from 'react';
import styled from 'styled-components';

export interface IProps {
  endFocusRef: any;
}

const EndFocusObj = styled.div`
  position: absolute;
  end: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
`;

export const EndFocus = React.memo<IProps>(props => {
  return <EndFocusObj ref={props.endFocusRef} tabIndex={0} />;
});
