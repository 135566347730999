import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroupsStore } from 'pages/admin-dashboard/groups/store';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

type UpdateGroupPayload = {
  id: string;
  name?: string;
  description?: string;
};

const updateGroup = ({ id, payload }: { id: string; payload: UpdateGroupPayload }) => {
  return axios.put(`/api/v3/groups/${id}`, payload);
};

export const useMutateGroup = (groupId: string) => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const { makeToast } = useStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupsStore();

  return useMutation(updateGroup, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to update group',
        isError: true,
      });
    },
    onSuccess: () => {
      makeToast({
        text: 'Group updated successfully',
        isError: false,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['group', groupId]);
      queryCache.invalidateQueries(['groups', partner?.id, pageNumber, pageSize, sortColumn, sortDirection, name]);
    },
  });
};
