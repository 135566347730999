import { PageTitle, TitleSection } from 'core/layout';
import React from 'react';

interface IUserProfileHeader {
  name: string;
  email: string;
}

export const UserProfileHeader = (props: IUserProfileHeader) => {
  return (
    <TitleSection style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
      <PageTitle>{props.name}</PageTitle>
      <p>{props.email}</p>
    </TitleSection>
  );
};
