import LoadingComponent from 'components/LoadingComponent';
import { ContentSection, LightDividingLine } from 'core/layout';
import React, { MutableRefObject, useRef } from 'react';
import styled from 'styled-components';
import generateId from 'utils/generateId';
import { EndFocus } from './buttons/EndFocus';
import { SkipContentButton } from './buttons/SkipContentButton';

export const ArticleIcon = styled.img`
  width: 65px;
  height: 65px;
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0.5em;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.15);
`;

const TitleText = styled.h3`
  font-size: 1em;
  display: inline;
  padding: 0.15em 0;
  margin: 0;
`;

interface ITableFrame {
  mainComponent: any;
  emptyComponent?: any;
  hasContent?: boolean;
  isLoading?: boolean;
  segmentNav: any;
  title: string;
  id?: string;
}

const TableFrame = (props: ITableFrame) => {
  const showMain: boolean = props.hasContent ?? true;
  const isLoading: boolean = props.isLoading ?? false;
  const endFocusRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  return (
    <div
      id={!!props.id ? props.id : generateId()}
      style={{ marginTop: '25px', marginBottom: '50px', textAlign: 'center' }}
    >
      <ContentSection>
        <div style={{ marginBottom: '1em', textAlign: 'left' }}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', justifyItems: 'center', alignItems: 'center' }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <TitleText>{props.title}</TitleText>
              <SkipContentButton endFocusRef={endFocusRef} />
            </div>
            {props.segmentNav}
          </div>
          <LightDividingLine />
        </div>
      </ContentSection>
      <LoadingComponent
        isLoading={isLoading}
        component={<ContentSection>{showMain ? props.mainComponent : props.emptyComponent}</ContentSection>}
      />
      <EndFocus endFocusRef={endFocusRef} />
    </div>
  );
};

export default TableFrame;
