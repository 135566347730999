import { TAssignment } from 'types';
import { AssignmentStatus } from './set';

export const PRIVACY_TYPES = {
  PRIVATE_ONLY: 1,
  COURSES_ONLY: 4
};

export interface ICourseAssignmentTableData extends TAssignment {
  status: AssignmentStatus;
}
