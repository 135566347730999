import { Switch, Typography } from '@material-ui/core';
import { useItemEditorStore } from 'components/item-editor/store';
import { useMutateItem } from 'hooks/useMutateItem';
import styled from 'styled-components';

export const GraduatedDifficultyFormFields = () => {
  const { activeItem: item, activeAssignment: assignment, updateActiveItem } = useItemEditorStore();
  const [mutateItem] = useMutateItem(item?.id, assignment?.id);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const { checked } = event.target;

    // update in store
    updateActiveItem({
      graduateDifficultyEnabled: checked,
    });

    // update in database
    mutateItem({
      id: item.id,
      payload: {
        data: {
          attributes: {
            'graduate-difficulty-enabled': checked,
          },
        },
      },
    });
  };

  if (!item) {
    console.warn('Item not found');
    return null;
  }

  return (
    <GraduatedDifficultySwitch>
      <Typography variant="h6">Enable Graduated Difficulty</Typography>
      <Switch checked={item.graduateDifficultyEnabled} onChange={handleChange} />
    </GraduatedDifficultySwitch>
  );
};

const GraduatedDifficultySwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
