import TextField from '@material-ui/core/TextField';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { useItemEditorStore } from 'components/item-editor/store';
import { useMutateItem } from 'hooks/useMutateItem';
import React, { useEffect, useState } from 'react';

export const Title = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();
  const [titleText, setQuestionText] = useState(item?.title);
  const [mutateItem] = useMutateItem(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');

  const handleTitleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionText(event.target.value);
  };

  // update title text automatically on changed input
  // debounce to avoid making too many requests
  useEffect(() => {
    if (titleText !== item?.title) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateItem({
          id: item?.id,
          payload: {
            data: {
              attributes: {
                title: titleText,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [titleText, item?.id, mutateItem, item?.title]);

  useEffect(() => {
    if (autosaveStatus === 'success' || autosaveStatus === 'error') {
      const timer = setTimeout(() => {
        setAutosaveStatus('idle');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autosaveStatus]);

  if (!item) {
    console.warn('Item not found');
    return null;
  }

  return (
    <div>
      <TextField
        id="question-text"
        label="Title"
        value={titleText}
        onChange={handleTitleTextChange}
        placeholder={TITLE_TEXT_PLACEHOLDERS[Math.floor(Math.random() * TITLE_TEXT_PLACEHOLDERS.length)]}
        variant="outlined"
        style={{
          fontSize: '24px',
          fontWeight: 500,
          padding: '5px 0',
          width: '100%',
        }}
      />
      <AutosaveStatus status={autosaveStatus} />
    </div>
  );
};

const TITLE_TEXT_PLACEHOLDERS = [
  'Arithmetic Order of Operations',
  'The Scientific Method',
  'The Five Stages of Grief',
  'Stages of Cell Division',
  'Planets in Our Solar System',
  'Layers of the Earth',
  '4 Blood Types (from Most to Least Common)',
  "Steps in a Hero's Journey",
];
