import React, { useState, useEffect } from 'react';
import { ltiLauncherPresets } from './ltiLauncherPresets';
import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { sortBy } from 'lodash';

export function SiteAdminLtiLauncher() {
  const origin = window.location.origin;
  const urlOrigin = /127.0.0.1|localhost/.test(origin) ? 'https://testing.cerego.com' : origin;
  const ltiPresets = ltiLauncherPresets;
  const [oauthClients, setOauthClients] = useState<any[]>([]);
  const [selectedOauthClient, setSelectedOauthClient] = useState<any>(null);
  const currentUser = useCurrentUser();

  const customScoringTypes = ['progress_to_level', 'progress_to_permanence', 'progress_to_score']; // output of CustomScoringType.all.map(&:slug).to_json
  const roles = ['', 'Learner', 'Student', 'Instructor'];

  const initialFields = {
    custom_scoring_target: 3,
    custom_scoring_type: 'progress_to_level',
    launch_presentation_locale: 'en_US',
    lti_version: 'LTI-1p0',
    lti_message_type: 'basic-lti-launch-request',
    custom_department_id: '',
    roles: '',
    debug: '',
    user_id: '',
    lis_result_sourcedid: '',
    lis_person_name_full: '',
    lis_person_name_given: '',
    lis_person_name_family: '',
    lis_person_contact_email_primary: '',
  };

  const [formData, setFormData] = useState({
    oauthClientId: 0,
    method: 'POST',
    url: `${urlOrigin}/lti/study/731323`,
    fields: initialFields,
  });
  const [finalized, setFinalized] = useState(false);

  const getPartnerName = (oauthClient) => {
    let name = `${oauthClient.partner_name} (${oauthClient.id}) (Client: ${oauthClient.oauth2_client_id})`;
    if (clientHasPreset(oauthClient.oauth2_client_id)) {
      name += ' ✓';
    }
    return name;
  };
  const clientHasPreset = (oauthClientId) => ltiPresets.hasOwnProperty(oauthClientId);

  const finalizeForm = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/v2/admin/lti/sign', formData);

      const data = response.data;
      setFinalized(true);
      setFormData((prevFormData) => ({
        ...prevFormData,
        fields: { ...prevFormData.fields, ...data.response },
      }));
    } catch (error) {
      window.alert('An error occurred while finalizing the form. ' + JSON.stringify(error));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const oauth2ClientsResponse = await axios.get('/api/v2/admin/oauth2_clients');
        const sortedClients = sortBy(oauth2ClientsResponse.data, [
          (x) => !clientHasPreset(x.oauth2_client_id), // Presets up top
          'partner_name',
          'oauth2_client_id',
        ]);
        setOauthClients(sortedClients);
      } catch (error) {
        window.alert('An error occurred while fetching OAuth2 clients. ' + JSON.stringify(error));
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedOauthClient) {
      const oauthClientId = selectedOauthClient.oauth2_client_id;
      const presets = ltiPresets[oauthClientId];
      if (presets) {
        setFormData((prevFormData) => {
          const newUrl = `${urlOrigin}/lti/study/${presets.id || presets.custom_set_id}`;

          return {
            ...prevFormData,
            oauthClientId,
            url: newUrl,
            fields: {
              ...prevFormData.fields,
              ...presets,
            },
          } as any;
        });
      }
    }
  }, [ltiPresets, selectedOauthClient, urlOrigin]);

  useEffect(() => {
    if (currentUser) {
      const nameParts = currentUser?.name?.split(' ') ?? [];
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          fields: {
            ...prevFormData.fields,
            user_id: currentUser.username ?? '',
            lis_result_sourcedid: currentUser.username ?? '',
            lis_person_name_full: currentUser.name ?? '',
            lis_person_name_given: nameParts[0] ?? '',
            lis_person_name_family: nameParts[1] ?? '',
            lis_person_contact_email_primary: currentUser.email ?? '',
          },
        };
      });
    }
  }, [currentUser]);

  return (
    <div style={{ margin: 20 }}>
      <h1>LTI Launcher</h1>
      <br />

      <select
        value={selectedOauthClient?.oauth2_client_id ?? ''}
        onChange={(e) => {
          const selectedOauthClientId = e.target.value;
          const selectedOauthClient = oauthClients.find((p) => p.oauth2_client_id.toString() === selectedOauthClientId);
          setSelectedOauthClient(selectedOauthClient);
        }}
      >
        <option value="">Select OAuth Client</option>
        {oauthClients.map((oauthClient) => (
          <option key={oauthClient.oauth2_client_id} value={oauthClient.oauth2_client_id}>
            {getPartnerName(oauthClient)}
          </option>
        ))}
      </select>
      <input
        type="text"
        value={formData.method}
        onChange={(e) => setFormData({ ...formData, method: e.target.value })}
      />
      <input
        type="text"
        style={{ width: 400 }}
        value={formData.url}
        onChange={(e) => setFormData({ ...formData, url: e.target.value })}
      />
      <br />
      <br />

      <form className="lti-form" action={formData.url} method={formData.method} target="_blank">
        {Object.entries(formData.fields).map(([key, value]) => (
          <div key={key} style={{ marginTop: 10 }}>
            <label>{key}</label>
            <div style={{ display: 'inline-block', marginLeft: 10 }}>
              {key === 'custom_scoring_type' ? (
                <select
                  name={key}
                  value={value}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fields: { ...formData.fields, [key]: e.target.value },
                    })
                  }
                >
                  {customScoringTypes.map((customScoringType) => (
                    <option key={customScoringType} value={customScoringType}>
                      {customScoringType}
                    </option>
                  ))}
                </select>
              ) : key === 'roles' ? (
                <select
                  name={key}
                  value={value}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fields: { ...formData.fields, roles: e.target.value },
                    })
                  }
                >
                  {roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  name={key}
                  type="text"
                  value={value}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fields: { ...formData.fields, [key]: e.target.value },
                    })
                  }
                />
              )}
            </div>
          </div>
        ))}
        <br />

        <button onClick={finalizeForm}>Finalize</button>
        <input type="submit" value="Open" disabled={!finalized} />
      </form>
    </div>
  );
}
