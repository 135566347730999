import { EditorEvents, EditorProvider } from '@tiptap/react';
import { DeleteButton } from 'components/buttons/v4';
import { TipTapMenuBar, tipTapExtensions } from 'components/item-editor/TipTapMenuBar';
import { ImageWithPolygon } from 'components/item-editor/regions/ImageWithPolygon';
import { ImageDimensions } from 'components/item-editor/regions/RegionsEditor';
import { useItemEditorStore } from 'components/item-editor/store';
import { useDeleteFacet } from 'hooks/useDeleteFacet';
import { useMutateFacet } from 'hooks/useMutateFacet';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type AutosaveStatus = 'idle' | 'saving' | 'success' | 'error';

interface QuestionWithPolygonProps {
  question: string;
  index: number;
  facet: any;
  item: any;
  highlightDimensions: ImageDimensions;
  containerWidth: number;
}

export type Shape =
  | { type: 'ellipse'; cx: number; cy: number; rx: number; ry: number }
  | { type: 'rectangle'; x: number; y: number; width: number; height: number }
  | { type: 'polygon'; points: Array<{ x: number; y: number }> };

export const QuestionWithPolygon: React.FC<QuestionWithPolygonProps> = ({
  question,
  index,
  facet,
  item,
  highlightDimensions,
  containerWidth,
}) => {
  const { updateActiveItem, activeAssignment: assignment } = useItemEditorStore();
  const [editedQuestion, setEditedQuestion] = useState<string>(question);
  const [editedExplanation, setEditedExplanation] = useState<string>(facet.association.explanationHtml);
  const [autosaveStatus, setAutosaveStatus] = useState<AutosaveStatus>('idle');
  const [drawingMode, setDrawingMode] = useState<'ellipse' | 'rectangle' | 'polygon' | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentShape, setCurrentShape] = useState<Shape | null>(null);
  const [shape, setShape] = useState<Shape | null>(null);

  const [mutateFacet] = useMutateFacet(item?.id, assignment?.id);
  const [deleteFacet] = useDeleteFacet('deleteFacet', assignment?.id || '', item?.id || '');

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const regionPolygon = facet.anchor?.regionPolygon;
    if (regionPolygon) {
      const points = regionPolygon.split(',').map((pair: string) => {
        const [x, y] = pair.trim().split(' ');
        return { x: parseFloat(x), y: parseFloat(y) };
      });

      setShape({ type: 'polygon', points });
    }
  }, [facet.anchor?.regionPolygon]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (drawingMode !== 'ellipse' && drawingMode !== 'rectangle') return;

    e.preventDefault();

    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;

    setIsDrawing(true);

    if (drawingMode === 'ellipse') {
      setCurrentShape({ type: 'ellipse', cx: x, cy: y, rx: 0, ry: 0 });
    } else if (drawingMode === 'rectangle') {
      setCurrentShape({ type: 'rectangle', x: x, y: y, width: 0, height: 0 });
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDrawing || !currentShape) return;
    if (drawingMode !== 'ellipse' && drawingMode !== 'rectangle') return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;

    if (currentShape.type === 'ellipse') {
      const rx = Math.abs(x - currentShape.cx);
      const ry = Math.abs(y - currentShape.cy);
      setCurrentShape({ ...currentShape, rx, ry });
    } else if (currentShape.type === 'rectangle') {
      const width = x - currentShape.x;
      const height = y - currentShape.y;
      setCurrentShape({ ...currentShape, width, height });
    }
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDrawing) return;
    if (drawingMode !== 'ellipse' && drawingMode !== 'rectangle') return;

    setIsDrawing(false);

    if (currentShape) {
      handleShapeComplete(currentShape);
      setCurrentShape(null);
    }
  };

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (drawingMode !== 'polygon') return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;

    if (!isDrawing) {
      setIsDrawing(true);
      setCurrentShape({ type: 'polygon', points: [{ x, y }] });
    } else if (currentShape && currentShape.type === 'polygon') {
      const points = currentShape.points;
      const firstPoint = points[0];
      const distance = Math.hypot(x - firstPoint.x, y - firstPoint.y);
      const closeEnough = distance < 0.02; // Adjust threshold as needed

      if (closeEnough && points.length > 2) {
        // Close the polygon
        handleShapeComplete({ ...currentShape, points });
        setIsDrawing(false);
        setCurrentShape(null);
      } else {
        setCurrentShape({
          ...currentShape,
          points: [...points, { x, y }],
        });
      }
    }
  };

  const handleShapeComplete = async (completedShape: Shape) => {
    let regionPolygon = '';

    if (completedShape.type === 'ellipse') {
      const points = approximateEllipse(completedShape);
      regionPolygon = points.map((point) => `${point.x} ${point.y}`).join(', ');
    } else if (completedShape.type === 'rectangle') {
      const points = [
        { x: completedShape.x, y: completedShape.y },
        { x: completedShape.x + completedShape.width, y: completedShape.y },
        { x: completedShape.x + completedShape.width, y: completedShape.y + completedShape.height },
        { x: completedShape.x, y: completedShape.y + completedShape.height },
      ];
      regionPolygon = points.map((point) => `${point.x} ${point.y}`).join(', ');
    } else if (completedShape.type === 'polygon') {
      regionPolygon = completedShape.points.map((point) => `${point.x} ${point.y}`).join(', ');
    }

    if (!facet.id) {
      console.error('Facet ID is missing.');
      return;
    }

    setAutosaveStatus('saving');

    try {
      await mutateFacet({
        id: facet.id,
        payload: {
          data: {
            id: facet.id,
            attributes: {
              'quiz-settings': {
                enabled: ['region_reverse_multiple_choice', 'region_reverse_click_in_the_dark'],
              },
            },
            relationships: {
              anchor: {
                data: {
                  attributes: {
                    'region-polygon': regionPolygon,
                  },
                },
              },
            },
          },
        },
      });

      if (isMounted.current) {
        setAutosaveStatus('success');
        setShape(completedShape);
      }
    } catch (error) {
      console.error('Error saving facet:', error);
      if (isMounted.current) {
        setAutosaveStatus('error');
      }
    }
  };

  const approximateEllipse = (ellipse: {
    cx: number;
    cy: number;
    rx: number;
    ry: number;
  }): Array<{ x: number; y: number }> => {
    const numPoints = 36;
    const points: any = [];

    for (let i = 0; i < numPoints; i++) {
      const angle = (i / numPoints) * 2 * Math.PI;
      const x = ellipse.cx + ellipse.rx * Math.cos(angle);
      const y = ellipse.cy + ellipse.ry * Math.sin(angle);
      points.push({ x, y });
    }

    return points;
  };

  const debouncedSave = useRef(
    debounce(async (updatedQuestion: string, updatedExplanation: string) => {
      if (!facet.id) {
        console.error('Facet ID is missing.');
        return;
      }

      setAutosaveStatus('saving');

      try {
        await mutateFacet({
          id: facet.id,
          payload: {
            data: {
              id: facet.id,
              relationships: {
                association: {
                  data: {
                    attributes: {
                      'explanation-html': updatedExplanation,
                    },
                  },
                },
              },
              attributes: {
                'association-question-html': updatedQuestion,
              },
            },
          },
        });

        if (isMounted.current) {
          setAutosaveStatus('success');
        }
      } catch (error) {
        console.error('Error saving facet:', error);
        if (isMounted.current) {
          setAutosaveStatus('error');
        }
      }
    }, 1000)
  ).current;

  useEffect(() => {
    if (autosaveStatus === 'success' || autosaveStatus === 'error') {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setAutosaveStatus('idle');
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autosaveStatus]);

  const debouncedExplanationTextChange = debounce(async (editor: any) => {
    debouncedSave(editedQuestion, editor.getHTML());
  }, 500);

  // Handlers for input changes
  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditedQuestion(value);
    debouncedSave(value, editedExplanation);
  };

  const handleExplanationTextChange = ({ editor }: EditorEvents['update']) => {
    setEditedExplanation(editor.getHTML());
    debouncedExplanationTextChange(editor);
  };

  const handleDeleteQuestion = async () => {
    await deleteFacet(facet.id);
    updateActiveItem({ facets: item.facets.filter((f: any) => f.id !== facet.id) });
  };

  // Sync local state with props if props change
  useEffect(() => {
    setEditedQuestion(question);
    setEditedExplanation(facet.association.explanationHtml);
  }, [question, facet.association.explanationHtml]);

  return (
    <Container>
      <Header>
        <h2>Question {index + 1}</h2>
        <AutosaveIndicator status={autosaveStatus} />
        <DeleteButton click={handleDeleteQuestion}>Delete</DeleteButton>
      </Header>
      <Field>
        <label htmlFor={`question-${index}`}>Question:</label>
        <input
          id={`question-${index}`}
          type="text"
          value={editedQuestion}
          onChange={handleQuestionChange}
          placeholder="Enter your question here"
        />
      </Field>
      <Field>
        <label htmlFor={`explanation-${index}`}>Explanation:</label>
        <EditorProvider
          slotBefore={<TipTapMenuBar />}
          extensions={tipTapExtensions}
          content={editedExplanation}
          onUpdate={handleExplanationTextChange}
        >
          <></>
        </EditorProvider>
      </Field>
      <ImageContainer
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onClick={handleImageClick}
      >
        <ImageWithPolygon
          imageUrl={item.image?.url || ''}
          imageAlt={item.image?.altTag || ''}
          shape={shape}
          currentShape={currentShape}
          highlightDimensions={highlightDimensions}
          containerWidth={containerWidth}
        />
        {isDrawing ? (
          <button
            onClick={() => {
              setIsDrawing(false);
              setCurrentShape(null);
            }}
          >
            Cancel Drawing
          </button>
        ) : (
          <DrawingModeSelector>
            <button onClick={() => setDrawingMode('ellipse')}>Draw Ellipse</button>
            <button onClick={() => setDrawingMode('rectangle')}>Draw Rectangle</button>
            <button onClick={() => setDrawingMode('polygon')}>Draw Polygon</button>
          </DrawingModeSelector>
        )}
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Field = styled.div`
  margin-bottom: 16px;

  label {
    display: block;
    font-weight: 600;
    margin-bottom: 4px;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  cursor: ${(props) => (props.onClick ? 'crosshair' : 'default')};
`;

const DrawingModeSelector = styled.div`
  margin-bottom: 16px;

  button {
    margin-right: 8px;
  }
`;

interface AutosaveIndicatorProps {
  status: AutosaveStatus;
}

const AutosaveIndicator: React.FC<AutosaveIndicatorProps> = ({ status }) => {
  let content: React.ReactNode;

  switch (status) {
    case 'saving':
      content = <span style={{ color: 'orange' }}>Saving...</span>;
      break;
    case 'success':
      content = <span style={{ color: 'green' }}>Saved</span>;
      break;
    case 'error':
      content = <span style={{ color: 'red' }}>Error saving</span>;
      break;
    default:
      content = null;
  }

  return <div>{content}</div>;
};
