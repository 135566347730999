import {
  faBell,
  faBook,
  faBuilding,
  faBullseye,
  faChevronDown,
  faCreditCard,
  faIdCard,
  faLink,
  faUserCog,
  faUserGraduate
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { MemreFlexBox, MemreText } from 'components/core';
import { Color } from 'core';
import React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const ConfigItem = (props: Props) => {
  const { title, children } = props;
  let icon: any = null;

  switch (title) {
    case 'Organization Details':
      icon = faBuilding;
      break;
    case 'Member IDs':
      icon = faIdCard;
      break;
    case 'Course Library Settings':
      icon = faBook;
      break;
    case 'Product Notifications':
      icon = faBell;
      break;
    case 'Course Enrollment Links':
      icon = faLink;
      break;
    case 'Payment Settings':
      icon = faCreditCard;
      break;
    case 'Default Goal Level':
      icon = faBullseye;
      break;
    case 'Learner Experience':
      icon = faUserGraduate;
      break;
    case 'Custom User Fields':
      icon = faUserCog;
      break;
    default:
      break;
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <MemreFlexBox gap={2} direction="row" align="center">
          <FontAwesomeIcon icon={icon} style={{ color: Color.mainBlue, width: '1.3rem' }} />
          <MemreText variant="h4">{title}</MemreText>
        </MemreFlexBox>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
