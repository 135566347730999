import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { TAnnotation } from 'types';
import { createSound } from 'utils/createSound';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

interface CreateICSoundAnnotationParams {
  itemId: string;
  sound: File;
}

const createICSoundAnnotation = async ({ itemId, sound }: CreateICSoundAnnotationParams): Promise<TAnnotation> => {
  const soundId = (await createSound(sound)).id;

  // create annotation
  const createAnnotationResponse = await axios.post(`/api/v3/items/${itemId}/annotations`, {
    item_id: itemId,
    include: 'image,document,sound,video',
    data: {
      relationships: {
        sound: {
          data: {
            id: soundId,
          },
        },
      },
    },
  });
  const newImageAnnotation = normalizeJSONAPIResponse(createAnnotationResponse.data);
  return newImageAnnotation;
};

export const useCreateICSoundAnnotation = (itemId: string = '', setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(createICSoundAnnotation, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
