import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import ceregoLogo from 'assets/images/cerego-logo.svg';
import poweredByCeregoLogo from 'assets/images/powered-by-logo.png';
import previewImage from 'assets/images/right-arrow.png';
import { Color, Size } from 'core';
import ImageView from 'components/ImageView';
import { ISet } from 'models/set';

interface ILearnHeaderProps extends WithTranslation {
  set?: ISet;
  totalItemCount?: number;
  progressCount?: number;
  isPreview?: boolean;
  showAnswers?: boolean;
  showExit: boolean;
  toggleShowAnswers?: () => void;
  exit: () => void;
  itemIndex?: number;
  decrementItemIndex?: () => void;
  incrementItemIndex?: () => void;
  goalType?: string;
}

const NavigationBar = styled.nav`
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  font-weight: 500;
  color: ${Color.darkGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 5px 0 rgba(235, 238, 242, 0.8);
`;

const LearnHeaderDivider = styled.div`
  border-left: 1px solid ${Color.lightGray};
  height: 30px;
  display: flex;
  align-self: center;
  margin-left: 15px;
  margin-right: 15px;
`;

const LearnHeaderTitle = styled.h4`
  flex: 1 0 auto;
  font-size: 18px;
  font-weight: 500;
  color: ${Color.darkGray};
  text-align: center;
  margin-left: 15px;
  @media (max-width: ${Size.iPadWidth}px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  & > img {
    max-height: 50px;
    max-width: 200px;
    display: flex;
  }
`;

export const Img = styled.img`
  max-height: 50px;
  max-width: 200px;
  object-fit: contain;
  display: flex;
`;

const LeftContainer = styled.div`
  display: flex;
  width: 33%;
  margin-left: 20px;
`;

const RightContainer = styled.div`
  width: 33%;
  margin-right 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${Size.iPadWidth}px) {
    width: 50%;
  }
`;

const MiddleContainer = styled.div`
  width: 33%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const PoweredByCerego = styled(Img)`
  display: flex;
  height: 30px;
  object-fit: contain;
`;
export const SmallImg = styled(Img)`
  height: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  font-size: 22px;
  color: ${Color.textGray};
  background-color: ${Color.primaryGray};
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;

  &:hover {
    background-color: ${Color.veryLightBlue};
  }
  &:focus {
    background-color: ${Color.veryLightBlue};
  }
`;

const HeaderText = styled.h4`
  color: ${Color.textGray};
  font-size: 16px;
  margin-left: 10px;
  letter-spacing: 0.4px;
`;

const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
  color: ${Color.textGray};
  font-size: 16px;
  margin-left: 10px;
  letter-spacing: 0.4px;
  font-weight: 700;
`;

const ShowAnswersText = styled.div`
  color: ${Color.textGray};
  font-size: 14px;
  margin-left: 20px;
  letter-spacing: 0.4px;
  font-weight: 700;
`;

class LearnHeader extends React.PureComponent<ILearnHeaderProps> {
  public render() {
    const { totalItemCount, progressCount, set, isPreview, showExit, t } = this.props;
    let progressText = '';
    const itemIndex = (this.props.itemIndex || 0) + 1;
    // check against undefined so that we can show the string "0"
    if (typeof totalItemCount !== 'undefined' && typeof progressCount !== 'undefined') {
      progressText = `${isPreview ? itemIndex : progressCount} / ${totalItemCount}`;
    }
    const partnerLogoImage = set?.creator?.logoImage;
    const showArrow = isPreview && !!totalItemCount && totalItemCount > 1;
    return (
      <NavigationBar>
        {set && (
          <LeftContainer>
            {partnerLogoImage && !isPreview && (
              <FlexContainer>
                <ImageContainer>
                  <ImageView image={partnerLogoImage} transcodedSize={'medium'} />
                </ImageContainer>
                <LearnHeaderDivider />
                <PoweredByCerego src={poweredByCeregoLogo} />
              </FlexContainer>
            )}
            {!partnerLogoImage && !isPreview && (
              <LeftContainer>
                <SmallImg src={ceregoLogo} alt={t('Cerego')} />
              </LeftContainer>
            )}
            {isPreview && (
              <FlexContainer>
                <SmallImg src={previewImage} alt={t('Preview Mode')} />
                <HeaderText>{t('Preview Mode')} </HeaderText>
              </FlexContainer>
            )}
            {isPreview && this.props.goalType !== 'survey' && (
              <FlexContainer>
                <ShowAnswersText id="showAnswersText">{t('Show Answers')}</ShowAnswersText>
                <input
                  type="checkbox"
                  checked={this.props.showAnswers}
                  onChange={this.props.toggleShowAnswers}
                  aria-labelledby="showAnswersText"
                />
              </FlexContainer>
            )}
          </LeftContainer>
        )}
        <MiddleContainer>
          <LearnHeaderTitle>{set && set.name}</LearnHeaderTitle>
        </MiddleContainer>
        <RightContainer>
          {showArrow && (
            <ArrowButton onClick={this.props.decrementItemIndex} aria-label={t('Back')}>
              &lt;
            </ArrowButton>
          )}
          <HeaderText>{progressText}</HeaderText>
          {showArrow && (
            <ArrowButton onClick={this.props.incrementItemIndex} aria-label={t('Forward')}>
              &gt;
            </ArrowButton>
          )}
          {showExit && (
            <FlexContainer>
              <LearnHeaderDivider />
              <CloseButton onClick={this.props.exit} aria-label={t('Close')}>
                ✕
              </CloseButton>
            </FlexContainer>
          )}
        </RightContainer>
      </NavigationBar>
    );
  }
}

export default withTranslation()(LearnHeader);
