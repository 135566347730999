import { Color } from 'core';
import React from 'react';
import { CompoundChart } from 'sets/components/charts/CompoundChart';
import styled from 'styled-components';

const colorizeBackground = props => {
  let bg = '';

  switch (props.color) {
    case 'red':
      bg = `linear-gradient(170deg, ${Color.veryDarkOrange}, ${Color.orange})`;
      break;
    case 'blue':
      bg = `linear-gradient(170deg, ${Color.darkBlue}, ${Color.lightSkyBlue})`;
      break;
    case 'purple':
      bg = `linear-gradient(170deg, ${Color.darkPurple}, ${Color.brightPurple})`;
      break;
    case 'gray':
      bg = `linear-gradient(170deg, ${Color.darkGray}, ${Color.lightGray})`;
      break;
    default:
      bg = `linear-gradient(170deg, ${Color.darkGreen}, ${Color.lightGreen})`;
  }

  return bg;
};
const ChartSet = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
`;
const Header = styled.div`
  color: white;
  background: ${colorizeBackground};
  padding: 0.8rem 1.5rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.3);
`;
const Title = styled.h1`
  font-size: 1.5rem;
  padding: 0 0 0.25rem;
  margin: 0;
`;
const Subtitle = styled.h2`
  font-size: 0.75rem;
  padding: 0 0 0.65rem;
  margin: 0;
`;
const Body = styled.div`
  border: 1px ${Color.deepBlueGray} solid;
`;
const Footer = styled.div`
  min-height: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: ${colorizeBackground};
  box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.25);
`;

interface IOwnProps {
  title?: string;
  sub?: string;
  color?: 'red' | 'green' | 'blue' | 'gray';
  height?: string;
  children: any;
}

export class Container extends React.Component<IOwnProps> {
  public render() {
    const { title, sub, color, children, height = 'auto' } = this.props;
    const header = title ? (
      <Header color={color}>
        <Subtitle>{sub}</Subtitle>
        <Title>{title}</Title>
      </Header>
    ) : null;
    const footer = title ? <Footer color={color} /> : null;
    const charts = children && children.length ? children : [children];

    const chartSet = (
      <CompoundChart height={height}>
        {charts.map(child => {
          const { height, title, sub } = child.props || {};
          return (
            <CompoundChart key={child.key || Math.random()} height={height} title={title} sub={sub}>
              {child}
            </CompoundChart>
          );
        }) || null}
      </CompoundChart>
    );

    return (
      <ChartSet>
        {header}
        <Body>{children.length > 0 && chartSet}</Body>
        {footer}
      </ChartSet>
    );
  }
}
