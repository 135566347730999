import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InlineButton } from 'components/buttons/v4';
import { Color } from 'core';
import { useIsInPartner } from 'hooks/useIsInPartner';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import { TAssignment } from 'types';
import { bounceInLeft } from 'utils/animations';
import { PARTNER_IDS } from 'utils/constants';
import { copyToClipboard } from 'utils/navigatorUtils';

interface ICopyLTIButtonProps {
  set: TAssignment;
}

const VerticalSeparator = (
  <svg
    style={{
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '4px',
      height: '2rem',
      margin: '-0.5rem 0.75rem',
    }}
  >
    <line x1="0" x2="0" y1="0" y2="100%" stroke={Color.primaryBlue} strokeWidth="4px" />
  </svg>
);

const RightBorderArrow = (
  <svg style={{ width: '1em', height: '2em' }}>
    <line x1="0" x2="0" y1="0" y2=".5em" stroke="gray" strokeWidth="2" />
    <line x1="0" x2=".5em" y1=".5em" y2="1em" stroke="gray" strokeWidth="1" />
    <line x1=".5em" x2="0" y1="1em" y2="1.5em" stroke="gray" strokeWidth="1" />
    <line x1="0" x2="0" y1="1.5em" y2="2em" stroke="gray" strokeWidth="2" />
  </svg>
);

export const CopyLTIButton = React.memo<ICopyLTIButtonProps>((props) => {
  const { set } = props;
  const [LTILinkCopiedToClipboard, setLTILinkCopiedToClipboard] = useState(false);
  const [showLTIURL, setShowLTIURL] = useState(false);
  const isQLearn = useIsInPartner(PARTNER_IDS.QLEARN);
  const { t } = useTranslation();
  const CopyLTIButtonText = showLTIURL ? getLTIStudyPath() : t('Copy LTI');
  const CopiedToClipboard = styled.div`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    animation-name: ${bounceInLeft};
    animation-duration: 0.8s;
    animation-fill-mode: both;
    white-space: nowrap;

    > div {
      border: 1px solid gray;
      border-right: none;
      padding: 0.35em;
      margin-right: 1em;
      color: gray;

      > svg {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  `;

  const ButtonWrapper = styled.button`
    background: transparent;
    border: none;
    color: ${Color.primaryBlue};
    font-size: 12px;
    text-align: center;
    padding: 0em;
    margin: 0em;
    font-weight: 500;
    font-family: inherit;
  `;

  const CopyLTIButtonContent = (
    <>
      <ButtonWrapper onClick={copyLTILink}>{CopyLTIButtonText}</ButtonWrapper>
      {VerticalSeparator}
      <ButtonWrapper
        onClick={() => {
          setShowLTIURL(!showLTIURL);
          setLTILinkCopiedToClipboard(false);
        }}
        aria-label={showLTIURL ? t('Hide LTI URL') : t('Show LTI URL')}
      >
        <FontAwesomeIcon icon={faLink} />
      </ButtonWrapper>
    </>
  );

  function copyLTILink() {
    copyToClipboard(getLTIStudyPath());
    setLTILinkCopiedToClipboard(!LTILinkCopiedToClipboard);
  }

  function getLTIStudyPath() {
    const urlPrefix = window.location.protocol + '//' + window.location.host;
    const v3LtiStudyPath = `${urlPrefix}/lti/study/${set.id}`;
    const v4LtiStudyPath = `${urlPrefix}/lti/app/learn/sets/${set.id}`;
    if (set.learnVersion === 4) {
      return v4LtiStudyPath;
    } else {
      return v3LtiStudyPath;
    }
  }

  if (isQLearn) {
    return <></>;
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowLTIURL(false);
        setLTILinkCopiedToClipboard(false);
      }}
      display="inline"
    >
      {LTILinkCopiedToClipboard && (
        <CopiedToClipboard>
          <div>
            <span>{t('Copied to clipboard!')}</span>
            {RightBorderArrow}
          </div>
        </CopiedToClipboard>
      )}
      <InlineButton shape="pill" size="small" click={() => { }} tabIndex={-1}>
        {CopyLTIButtonContent}
      </InlineButton>
    </OutsideClickHandler>
  );
});
