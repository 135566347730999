import * as React from 'react';

type CellWithPillProps = {
  text: string;
  textColor: string;
  pillText: string;
  pillColor: string;
  pillBackgroundColor: string;
};

export default class CellWithPill extends React.Component<CellWithPillProps> {
  public render() {
    return (
      <span>
        <span
          style={{
            color: this.props.textColor,
            width: '25px'
          }}
        >
          {this.props.text}
        </span>
        <span
          style={{
            backgroundColor: this.props.pillBackgroundColor,
            borderRadius: '10px',
            padding: '0.5em 1em',
            color: this.props.pillColor,
            marginLeft: '10px',
            fontSize: '10px',
            whiteSpace: 'pre'
          }}
        >
          {this.props.pillText}
        </span>
      </span>
    );
  }
}
