import React, { useState } from 'react';
import styled from 'styled-components';
import { KeyCodes } from 'utils/keyCodes';
import { Text } from 'courses/components/Text';

interface IProps {
  value: string;
  onChange: any;
  text: string;
  doneEditingCallback?: any;
  canEdit?: boolean;
  'aria-label'?: string;
  'aria-labelledby'?: string;
}

const Textarea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
`;

export const EditableText = (props: IProps) => {
  const [edit, setEdit] = useState(false);

  const doneEditing = () => {
    props.doneEditingCallback();
    setEdit(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === KeyCodes.enter) {
      e.preventDefault();
      doneEditing();
    } else {
      return true;
    }
  };

  const moveCaretToEnd = (e) => {
    const temp = e.target.value;
    e.target.value = '';
    e.target.value = temp;
  };

  const enableEdit = () => {
    if (props.canEdit) {
      setEdit(true);
    }
  };

  return (
    <div onClick={enableEdit}>
      {edit ? (
        <Textarea
          autoFocus={true}
          value={props.value}
          onChange={props.onChange}
          onBlur={doneEditing}
          onKeyDown={handleKeyDown}
          onFocus={moveCaretToEnd}
          aria-label={props['aria-label']}
          aria-labelledby={props['aria-labelledby']}
        />
      ) : (
        <Text text={props.text} canEdit={props.canEdit} enableEdit={enableEdit} />
      )}
    </div>
  );
};
