export const formatStringForSortComparison = (s: string): any => {
  const formatted = s.toLowerCase().replaceAll(' ', '_');
  switch (formatted) {
    case 'course_progress':
      return 'progress';
    case 'progress_to_goal':
      return 'progress';
    case 'last_studied':
      return 'last_visit';
    case 'total_study_time':
      return 'total_time';
    default:
      return formatted;
  }
};
