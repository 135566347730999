import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import assessContentDifficulty, { CONTENT_DIFFICULTIES } from 'utils/assessContentDifficulty';
import { formatDifficulty } from '../cells/ItemDifficulty';
import DifficultyDistributionCard from './DifficultyDistribution.card';
import OutlierCard, { OUTLIER_RANGE, OUTLIERS } from './Outlier.card';

const HEADING_TEXT = 'Recommended Improvements';

export default function Cards({ contentInsights }) {
  const { t } = useTranslation();
  const heading = t(HEADING_TEXT);

  const recommendationCards: React.ReactElement[] = [];

  const emptyDifficulties = {
    Easy: 0,
    Hard: 0,
    Moderate: 0,
    'Very Easy': 0,
    'Very Hard': 0,
  };

  const modifiers = contentInsights.map((item) => item.retention_modifier);
  const difficulties = modifiers.map(formatDifficulty);
  const countDifficulties = difficulties.reduce((counts, difficulty) => {
    counts[difficulty] = counts[difficulty] ? counts[difficulty] + 1 : 1;
    return counts;
  }, emptyDifficulties);
  const overallQuestionDifficulty = assessContentDifficulty(countDifficulties);
  const showDifficultyDistributionCard = overallQuestionDifficulty !== CONTENT_DIFFICULTIES.BALANCED;

  if (showDifficultyDistributionCard) {
    recommendationCards.push(
      <DifficultyDistributionCard
        overallQuestionDifficulty={overallQuestionDifficulty}
        countDifficulties={countDifficulties}
      />
    );
  }

  const outlierAbove = contentInsights
    .filter(({ retention_modifier }) => retention_modifier > OUTLIER_RANGE[OUTLIERS.UPPER])
    .sort((a, b) => b.retention_modifier - a.retention_modifier)[0];
  const outlierBelow = contentInsights
    .filter(({ retention_modifier }) => retention_modifier < OUTLIER_RANGE[OUTLIERS.LOWER])
    .sort((a, b) => a.retention_modifier - b.retention_modifier)[0];

  const outlierDirection = outlierAbove ? OUTLIERS.UPPER : OUTLIERS.LOWER;
  const outlier = outlierAbove ?? outlierBelow;

  if (outlier) {
    recommendationCards.push(<OutlierCard direction={outlierDirection} outlier={outlier} />);
  }

  if (recommendationCards.length === 1) {
    recommendationCards.push(<></>);
  }

  return recommendationCards.length > 0 ? (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        m: 3,
      }}
    >
      <Typography variant="h6">{heading}</Typography>

      <Stack direction={{ sm: 'column', md: 'row' }} spacing={2}>
        {recommendationCards.map((el, i) => (
          <Box key={i} sx={{ flexGrow: 1, flexBasis: 0 }}>
            {el}
          </Box>
        ))}
      </Stack>
    </Stack>
  ) : null;
}
