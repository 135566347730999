import { useCurrentUser } from 'hooks/useCurrentUser';
import React from 'react';

interface Props {
  component: any;
}

export const Prototype = (props: Props) => {
  const user = useCurrentUser();
  const isSiteAdmin = user?.meta.isAdmin;
  const inDevEnv: boolean = process.env.NODE_ENV === 'development';
  const isHanko = user?.email === 'hjones@cerego.com';

  if (isSiteAdmin || inDevEnv || isHanko) {
    return <>{props.component}</>;
  }

  return <></>;
};
