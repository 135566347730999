import axios from 'axios';
import { sleep } from './timeUtils';

// Gets the data from a background job by polling until it's complete
export const pollForJobData = async (jobId: number, progressCallback: (any) => void = () => {}): Promise<any> => {
  const WAIT_TIME_BETWEEN_REQUESTS = 1000;

  while (true) {
    const response = await axios.get(`/api/v3/background_job_data/${jobId}`);
    const jobData = response.data.data.attributes;

    progressCallback(jobData.data);
    if (jobData.state === 'completed') {
      return jobData.data;
    } else if (jobData.state === 'failed') {
      throw new Error('Job failed');
    }

    await sleep(WAIT_TIME_BETWEEN_REQUESTS);
  }
};
