import TextField from '@material-ui/core/TextField';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { useItemEditorStore } from 'components/item-editor/store';
import { useMutateConcept } from 'hooks/useMutateConcept';
import React, { useEffect, useState } from 'react';
import { ConceptImage } from '../ConceptImage';
import { ConceptSound } from '../ConceptSound';

export const QuestionFormFields = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();
  const [questionText, setQuestionText] = useState(item?.facets[0].anchor.text);
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');

  const handleQuestionTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionText(event.target.value);
  };

  // update question text automatically on changed input
  // debounce to avoid making too many requests
  useEffect(() => {
    if (questionText !== item?.facets[0].anchor.text) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateConcept({
          id: item?.facets[0].anchor.id,
          payload: {
            data: {
              attributes: {
                'text-html': questionText,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [questionText, item?.id, mutateConcept, item?.facets[0].anchor.text]);

  useEffect(() => {
    if (autosaveStatus === 'success' || autosaveStatus === 'error') {
      const timer = setTimeout(() => {
        setAutosaveStatus('idle');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autosaveStatus]);

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  const anchor = item.facets[0].anchor;

  return (
    <>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <TextField
          id="question-text"
          label="Ask a question"
          value={questionText}
          onChange={handleQuestionTextChange}
          placeholder={QUESTION_TEXT_PLACEHOLDERS[Math.floor(Math.random() * QUESTION_TEXT_PLACEHOLDERS.length)]}
          variant="outlined"
          style={{
            fontSize: '24px',
            fontWeight: 500,
            padding: '5px 0',
            width: '100%',
          }}
        />
        <ConceptImage concept={anchor} />
        <ConceptSound concept={anchor} />
      </div>
      <AutosaveStatus status={autosaveStatus} />
    </>
  );
};

const QUESTION_TEXT_PLACEHOLDERS = [
  'How many hearts does an octopus have?',
  'Which planet in our solar system has the most moons?',
  'What gas do plants absorb from the atmosphere for photosynthesis?',
  'Name the ancient city known for its hanging gardens, one of the Seven Wonders of the ancient world.',
  'What year did humans first land on the moon?',
  'Which is the smallest country in the world?',
  'Name the longest river in Asia.',
  'Which country is known for building the first large-scale pyramids?',
  'Who is considered the inventor of the World Wide Web?',
  'What renewable energy source is the most widely used in the world?',
  'Which artist cut off his own ear and later painted a famous self-portrait with a bandage?',
  'What is the main ingredient in traditional Japanese miso soup?',
  'Who holds the record for the most Olympic gold medals?',
  'In tennis, what term is used for a score of zero?',
  'Who wrote the book "1984"?',
  'What is the name of the philosophical theory that focuses on the morality of actions based solely on their outcomes?',
  'In what type of building would you find a narthex?',
  'What chemical element was named after the inventor of dynamite?',
  'What term describes the fear of palindromes (which ironically is itself a palindrome)?',
  'What mythical creature is said to lure sailors to their doom with its enchanting voice?',
];
