import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { postWithCSRFToken } from './registrationUtils';

import { PrimaryButton } from 'components/buttons/v4';
import { PageTitle } from 'core/layout';
import { BrightInput } from 'components/forms/BrightInput';
import { ErrorMessage } from 'core/typography';
import { StandardLink } from 'components/links/StandardLink';

export const SignInPage = () => {
  const [emailOrID, setEmailOrID] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const redirectParam = new URLSearchParams(window.location.search).get('redirect');

  const handleLogin = async (e: any = null) => {
    e?.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      await postWithCSRFToken('/api/v3/sessions', {
        email: emailOrID,
        password,
      });
      document.location.href = redirectParam ?? '/app/nav/v4/dashboard';
    } catch (err) {
      console.error('Login error:', err);
      setError('Invalid login credentials. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="mb-4">
        <PageTitle>Sign In</PageTitle>{' '}
      </div>

      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <label className="font-bold">Email Address or ID</label>
          <BrightInput
            className="!my-1"
            placeholder=" "
            type="text"
            value={emailOrID}
            onChange={(e) => setEmailOrID(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="font-bold">Password</label>
          <BrightInput
            className="!my-1"
            placeholder=" "
            type="password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        <div className="mb-4">
          <StandardLink to="/signin/forgot-password">Forgot password?</StandardLink>
        </div>

        <PrimaryButton pending={isSubmitting} click={() => handleLogin()}>
          Sign In
        </PrimaryButton>
      </form>
    </>
  );
};
