import { MemreLayout } from 'components/core';
import { useCourse } from 'hooks/useCourse';
import { useCurrentUser } from 'hooks/useCurrentUser';
import CourseContentTabs from './components/CourseContentTabs';
import CourseDetails from './components/CourseDetails';

type LearnerCourseDetailProps = {
  match: { params: { courseId: string } };
};

export const CoursePage = (props: LearnerCourseDetailProps) => {
  const { courseId } = props.match.params;
  const { data: course } = useCourse(courseId || '');

  const assignmentCount = course?.assignmentsCount;
  const instructionalItemsCount = course?.icItemsCount;

  const user = useCurrentUser();

  return (
    <MemreLayout isLoading={!course} headerText={course?.name} backLink="/nav/v4/dashboard">
      <CourseDetails course={course} user={user} />
      <CourseContentTabs
        assignmentCount={assignmentCount}
        instructionalItemsCount={instructionalItemsCount}
        courseId={courseId}
      />
    </MemreLayout>
  );
};
