import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStripe } from '@stripe/react-stripe-js';
import { RedirectToCheckoutClientOptions } from '@stripe/stripe-js';
import { PrimaryButton } from 'components/buttons/v4';
import { Box } from 'components/modals/common';
import { Color } from 'core';
import { ContentSection, DividingLine } from 'core/layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMyCourses } from 'hooks/useMyCourses';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { LearnerDashboardState, useLearnerDashboardStore } from 'zstore';

interface PaymentRequiredCTAProps {
  closeCTA: () => void;
}

export const PaymentRequiredCTA = (props: PaymentRequiredCTAProps) => {
  const { closeCTA } = props;
  const { t } = useTranslation();
  const stripe = useStripe();
  const user = useCurrentUser();

  const page = useLearnerDashboardStore((state: LearnerDashboardState) => state.page);
  const sort = useLearnerDashboardStore((state: LearnerDashboardState) => state.sort);
  const query = useLearnerDashboardStore((state: LearnerDashboardState) => state.query);
  const setPaymentRequiredCourseIds = useLearnerDashboardStore(
    (state: LearnerDashboardState) => state.setPaymentRequiredCourseIds
  );

  const { data: myCourses } = useMyCourses(page, sort, query);

  const meta = myCourses?.data?.meta;
  const paymentRequiredCourseIds = meta?.['payment-required-course-ids'] || [];
  const courses = normalizeJSONAPIResponse(myCourses?.data);
  const paymentRequiredCourse = courses?.find((c: any) => Number(c.id) === paymentRequiredCourseIds[0]);

  const clientReferenceId = `userId=${user?.id}&stripeSkuId=${paymentRequiredCourse?.stripeSkuId}`;

  useEffect(() => {
    setPaymentRequiredCourseIds(paymentRequiredCourseIds);
  }, [JSON.stringify(paymentRequiredCourseIds)]);

  const goToCheckout = async () => {
    const priceId = paymentRequiredCourse.stripeSkuId;
    if (!priceId) {
      throw new Error('Cannot complete checkout without a Stripe SKU ID for the course requiring payment');
    }

    const item = {
      price: priceId,
      quantity: 1,
    };
    const options: RedirectToCheckoutClientOptions = {
      lineItems: [item],
      mode: 'payment',
      successUrl: `${window.location.origin}${window.location.pathname}?success=true`,
      cancelUrl: `${window.location.origin}${window.location.pathname}?success=false`,
      clientReferenceId,
    };

    if (!stripe) {
      throw new Error('Cannot complete checkout without stripe');
    }

    const { error } = await stripe.redirectToCheckout(options);
    if (error) {
      console.error(`Stripe checkout error: ${error}`);
    }
  };

  return (
    <section style={{ padding: '0 1rem' }}>
      <Box
        style={{
          borderBottomWidth: '10px',
          borderBottomColor: 'orange',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        }}
      >
        <button
          onClick={closeCTA}
          style={{
            marginBottom: 0,
            marginTop: '20px',
            marginRight: '20px',
            paddingBottom: 0,
            border: 'none',
            backgroundColor: 'inherit',
            fontSize: '16px',
            float: 'right',
            color: Color.lightGray,
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', paddingTop: '20px', paddingLeft: '20px' }}>
          <div style={{ marginRight: '20px' }}>
            <FontAwesomeIcon icon={faExclamationCircle} color={'#eeb100'} style={{ fontSize: '50px' }} />
          </div>
          <div>
            <h3
              style={{
                fontWeight: 700,
                marginRight: '20px',
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {t('One of your courses requires payment')}
            </h3>
            <p style={{ marginTop: '5px' }}>{t(`To access ${paymentRequiredCourse?.name} please complete payment`)}</p>
          </div>
        </div>
        <DividingLine style={{ backgroundColor: Color.grayTransparent, marginBottom: '20px' }} />
        <div style={{ marginLeft: '20px', marginBottom: '20px' }}>
          <PrimaryButton click={goToCheckout}>{t('Complete Payment')}</PrimaryButton>
        </div>
      </Box>
    </section>
  );
};
