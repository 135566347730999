import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Switch,
  TextField,
} from '@mui/material';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { Color } from 'core';
import { useCreatePartnerDomain } from 'hooks/useCreatePartnerDomain';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { useDeletePartnerDomain } from 'hooks/useDeletePartnerDomain';
import { usePartnerDomains } from 'hooks/usePartnerDomains';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import React, { useEffect, useState } from 'react';

export const CourseEnrollmentLinks: React.FC = () => {
  const user = useCurrentUser();
  const { data: partnerDomains } = usePartnerDomains();
  const [isEnrollmentLinksEnabled, setIsEnrollmentLinksEnabled] = useState(
    user?.primaryPartner.partnerSettings.joinUrlEnabled || false
  );
  const [onlyVerifiedEmails, setOnlyVerifiedEmails] = useState(
    user?.primaryPartner.partnerSettings.emailDomainWhitelistingEnabled || false
  );
  const [allowedDomains, setAllowedDomains] = useState<any[]>(partnerDomains || []);
  const [domainInput, setDomainInput] = useState('');
  const [domainInputError, setDomainInputError] = useState('');
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);
  const [createPartnerDomain] = useCreatePartnerDomain();
  const [deletePartnerDomain] = useDeletePartnerDomain();

  useEffect(() => {
    setAllowedDomains(partnerDomains || []);
  }, [partnerDomains]);

  const handleCourseEnrollmentLinksToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEnrollmentLinksEnabled(e.target.checked);
    debouncedPartnerUpdate({
      partner_settings: {
        join_url_enabled: e.target.checked,
      },
    });
  };

  const handleVerifiedEmailWhitelistingToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyVerifiedEmails(e.target.checked);
    debouncedPartnerUpdate({
      partner_settings: {
        email_domain_whitelisting_enabled: e.target.checked,
      },
    });
  };

  const validateDomain = (domain: string) => {
    // Simple regex for domain validation
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/;
    return domainRegex.test(domain.toLowerCase());
  };

  const handleAddDomain = async () => {
    if (domainInput) {
      if (!validateDomain(domainInput)) {
        setDomainInputError('Please enter a valid domain');
        return;
      }
      try {
        const newPartnerDomain = await createPartnerDomain({
          id: user?.primaryPartner?.id,
          payload: {
            partner_id: user?.primaryPartner?.id,
            domain_name: domainInput,
          },
        });
        setAllowedDomains([...allowedDomains, newPartnerDomain]);
        setDomainInput('');
        setDomainInputError('');
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  const handleDeleteDomain = async (domainId: string) => {
    try {
      await deletePartnerDomain({
        partnerId: user?.primaryPartner?.id,
        domainId: domainId,
      });
      setAllowedDomains(allowedDomains.filter((domain) => domain.id !== domainId));
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <ConfigItem title="Course Enrollment Links">
      <MemreFlexBox direction="column" gap={3}>
        {/* Enrollment Link Settings */}
        <MemreFlexBox direction="row" align="center" justify="space-between">
          <MemreText variant="h6">Enrollment Link Settings</MemreText>
          <FormControlLabel
            control={
              <Switch
                checked={isEnrollmentLinksEnabled}
                onChange={handleCourseEnrollmentLinksToggle}
                color="secondary"
              />
            }
            label={<MemreText variant="body1">Enable Enrollment Links</MemreText>}
          />
        </MemreFlexBox>

        {isEnrollmentLinksEnabled && (
          <Paper elevation={1} sx={{ padding: 2 }}>
            <MemreFlexBox direction="column" gap={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyVerifiedEmails}
                    onChange={handleVerifiedEmailWhitelistingToggle}
                    color="secondary"
                  />
                }
                label={
                  <MemreText variant="body1">
                    Only allow people with verified email addresses to join using these links
                  </MemreText>
                }
              />

              {onlyVerifiedEmails && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <MemreText variant="subtitle1">Allowed Email Domains</MemreText>
                  <MemreFlexBox gap={2} align="center" sx={{ mt: 1 }}>
                    <TextField
                      label="Domain"
                      value={domainInput}
                      onChange={(e) => {
                        setDomainInput(e.target.value);
                        setDomainInputError('');
                      }}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      error={Boolean(domainInputError)}
                      helperText={domainInputError}
                    />
                    <MemreButton
                      text="Add Domain"
                      variant="contained"
                      color="secondary"
                      onClick={handleAddDomain}
                      iconName="plus"
                      disabled={!domainInput}
                    />
                  </MemreFlexBox>
                  <List>
                    {allowedDomains.map((domain) => (
                      <ListItem key={domain.id} divider>
                        <MemreText variant="body1">{domain.domainName}</MemreText>
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={() => handleDeleteDomain(domain.id)}
                            style={{ padding: '0.5rem', color: Color.veryDarkOrange }}
                            size="small"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </MemreFlexBox>
          </Paper>
        )}
      </MemreFlexBox>
    </ConfigItem>
  );
};
