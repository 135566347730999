import { AttachIc } from 'components/item-editor/AttachIc';
import { CautionTape } from 'components/item-editor/CautionTape';
import { AnswersFormFields } from 'components/item-editor/AnswersFormFields';
import { GraduatedDifficultyFormFields } from 'components/item-editor/GraduatedDifficultyFormFields';
import { useItemEditorStore } from 'components/item-editor/store';
import styled from 'styled-components';
import { ForeignAndNativeTermFormFields } from './ForeignAndNativeTermFormFields';
import { SentencesFormFields } from './SentencesFormFields';

export const VocabularyEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  const associationId = item.facets[0].association.id;

  return (
    <Container>
      <ForeignAndNativeTermFormFields />
      <CautionTape />
      <AnswersFormFields
        wrongAnswersOnly={true}
        conceptIdForDistractors={associationId}
        title="Wrong Answer Options for Native Term"
      />
      <SentencesFormFields />
      <AnswersFormFields
        wrongAnswersOnly={true}
        title="Wrong Answer Options for Foreign Term (Required for Sentence Quizzes)"
      />
      <AttachIc />
      <GraduatedDifficultyFormFields />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
