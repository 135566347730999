import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ModalPosition } from 'components/Modal';
import { ContentWrapper } from 'components/modals/common';
import { SharedContentTable } from 'components/SharedContentTable';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/profile';

interface Props {
  courseId: string;
  onCancel: () => void;
}

export const SearchSharedContent = (props: Props) => {
  const currentUser = useSelector((state) => getCurrentUser(state)) || {};
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();

  function handleTabChange(_event: any, newValue: any) {
    setCurrentTab(newValue);
  }

  return (
    <ModalPosition top={1.5}>
      {currentUser?.primaryPartner?.id && (
        <ContentWrapper>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label={t('Shared Assignments')} />
            {currentUser.primaryPartner.parent?.id && <Tab label={t('Parent Shared Assignments')} />}
          </Tabs>
          {currentTab === 0 && (
            <SharedContentTable
              value={currentTab}
              index={0}
              partnerId={currentUser.primaryPartner.id}
              courseId={props.courseId}
              onCancel={props.onCancel}
              allowHardCopy={true}
            />
          )}
          {currentTab === 1 && (
            <SharedContentTable
              value={currentTab}
              index={1}
              partnerId={currentUser.primaryPartner.parent.id}
              courseId={props.courseId}
              onCancel={props.onCancel}
              allowHardCopy={false}
            />
          )}
        </ContentWrapper>
      )}
    </ModalPosition>
  );
};
