import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

const createItem = async ({ setId, payload, createFacet }) => {
  // Create the templated item
  const resFromCreateItem = await axios.post(`/api/v3/sets/${setId}/items`, payload);
  const normalized = normalizeJSONAPIResponse(resFromCreateItem.data);

  if (createFacet) {
    await axios.post(`/api/v3/items/${normalized.id}/facets`);
  }

  return normalizeJSONAPIResponse(resFromCreateItem.data);
};

export const useCreateItem = () => {
  const { makeToast, assignmentId } = useStore();

  return useMutation(createItem, {
    onSuccess: () => {
      queryCache.invalidateQueries(['assignmentItems', assignmentId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
