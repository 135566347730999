import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingComponent from 'components/LoadingComponent';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { CautionTape } from 'components/item-editor/CautionTape';
import { AddAnnotationToolbar } from 'components/item-editor/instructional-content/AddAnnotationToolbar';
import { Annotation } from 'components/item-editor/instructional-content/Annotation';
import { useIcEditorStore } from 'components/item-editor/instructional-content/store';
import { useItemEditorStore } from 'components/item-editor/store';
import * as Color from 'core/colors';
import { useCreateICEmbedAnnotation } from 'hooks/useCreateICEmbedAnnotation';
import { useMutateItem } from 'hooks/useMutateItem';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TAnnotation } from 'types';

export const InstructionalContentEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  const [title, setTitle] = useState(item?.title || '');
  const [mutateItem] = useMutateItem(item?.id, assignment?.id);
  const [createEmbedAnnotation] = useCreateICEmbedAnnotation(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');
  const [embedCode, setEmbedCode] = useState('');
  const {
    icAnnotations,
    setIcAnnotations,
    addIcAnnotation,
    isCreatingAnnotation,
    setIsCreatingAnnotation,
    isShowingEmbedForm,
    hideEmbedForm,
  } = useIcEditorStore();

  useEffect(() => {
    setIcAnnotations(item?.annotations || []);
  }, [item]);

  useEffect(() => {
    if (title !== item?.title) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateItem({
          id: item?.id,
          payload: {
            data: {
              attributes: {
                title: title,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [title, item?.id, mutateItem, item?.title]);

  useEffect(() => {
    if (autosaveStatus === 'success' || autosaveStatus === 'error') {
      const timer = setTimeout(() => {
        setAutosaveStatus('idle');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autosaveStatus]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleEmbedCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmbedCode(event.target.value);
  };

  const handleCreateEmbedAnnotation = async () => {
    hideEmbedForm();
    setIsCreatingAnnotation(true);
    try {
      const newAnnotation = await createEmbedAnnotation({ itemId: item?.id || '', embedCode });
      if (!newAnnotation) {
        console.warn('No new embed annotation was created');
        return;
      }

      addIcAnnotation(newAnnotation);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreatingAnnotation(false);
    }
  };

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <Container>
      <TextField
        id="item-title"
        label="Page title"
        value={title}
        onChange={handleTitleChange}
        placeholder={PAGE_TITLE_PLACEHOLDERS[Math.floor(Math.random() * PAGE_TITLE_PLACEHOLDERS.length)]}
        variant="outlined"
        style={{
          fontSize: '24px',
          fontWeight: 500,
          marginBottom: '20px',
          padding: '5px 0',
        }}
      />
      <AutosaveStatus status={autosaveStatus} />

      <CautionTape />

      {icAnnotations.length === 0 ? (
        <EmptyEditorContainer>
          This page is empty
          <br />
          Click on a segment below.
        </EmptyEditorContainer>
      ) : (
        <>
          {icAnnotations.map((annotation: TAnnotation) => (
            <Annotation key={annotation.id} itemId={item.id} annotation={annotation} setId={assignment.id} />
          ))}
        </>
      )}

      {isCreatingAnnotation && (
        <Card>
          <LoadingComponent isLoading={isCreatingAnnotation} />
        </Card>
      )}

      {isShowingEmbedForm && (
        <Card style={{ padding: '20px 40px' }}>
          <Typography variant="h4" gutterBottom>
            Enter embed code
          </Typography>
          <TextField
            id="embed-annotation-textarea"
            label="Embed code"
            placeholder={`<iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>`}
            multiline
            maxRows={4}
            value={embedCode}
            onChange={handleEmbedCodeChange}
            variant="outlined"
            style={{ width: '100%' }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={handleCreateEmbedAnnotation}
            >
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => hideEmbedForm()}>
              Cancel
            </Button>
          </div>
        </Card>
      )}

      <AddAnnotationToolbar item={item} assignment={assignment} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const EmptyEditorContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${Color.lightGray};
  border-radius: 4px;
  font-size: 18px;
  color: ${Color.lightGray};
`;

const PAGE_TITLE_PLACEHOLDERS = [
  'Invisible Hand in Economics: Definition & Theory',
  'The Magic of Photosynthesis: How Plants Make Food and Oxygen',
  'Quantum Entanglement: When Particles Mysteriously Connect',
  "Unraveling the Fibonacci Sequence: Nature's Secret Code",
  'The Lore of Loch Ness: Searching for Nessie',
  "Understanding Schrödinger's Cat: A Thought Experiment in Quantum Mechanics",
  'The Art of Origami: Folding Paper into Dreams',
  'Decrypting Cryptography: Securing Information in the Digital Age',
  'The Evolution of Languages: How Words Change Over Time',
  'Mysteries of the Mayan Calendar: Science and Predictions',
  'Black Holes and Beyond: Warping Space and Time',
  'The Science of Happiness: What Really Makes Us Feel Good?',
  'Exploring Impressionism: Monet, Degas, and the Movement that Shocked the Art World',
  'From Dinosaurs to Birds: The Evolution of Feathers',
  'The Human Genome: Unzipping the Code of Life',
  'Riddles of the Sphinx: Myths and Archaeology in Ancient Egypt',
  'The History of Pizza: A Slice of Culinary Delight',
  'Cybersecurity Basics: Protecting Your Digital Footprint',
  'The Psychology of Colors: What Your Favorite Color Says About You',
  'Bizarre Foods of the World: Culinary Adventures for the Brave',
];
