import { combineReducers } from 'redux';
import presentationData from './presentation';
import timer from './timer';
const rootReducer = combineReducers({
  presentationData,
  timer
});

export type LearnState = ReturnType<typeof rootReducer>;

export default rootReducer;
