import React from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

type StyleProps = {
  rightToLeft?: boolean;
};

const Wrapper = styled.div<StyleProps>`
  font-weight: 400;
  text-align: ${(props) => (props.rightToLeft ? 'right' : 'left')};
`;

export const allowIframeConfig = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['iframe']),
  allowedAttributes: {
    iframe: [
      'src',
      'width',
      'height',
      'allowfullscreen',
      'webkitallowfullscreen',
      'mozallowfullscreen',
      'frameborder',
      'scrolling',
    ],
  },
};

const getSanitizedText = (source: any) => {
  if (source) {
    return sanitizeHtml(source, {
      disallowedTags: ['br'],
    });
  }
  return;
};

interface ISanitizedHtml {
  source?: string;
  style?: any;
  rightToLeft?: boolean;
}

const SanitizedHtml = (props: ISanitizedHtml) => {
  const sanitizedHtml = getSanitizedText(props.source);
  const { style, rightToLeft } = props;

  return <Wrapper style={style} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} rightToLeft={rightToLeft} />;
};

export default SanitizedHtml;
