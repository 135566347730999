import { TableContainer } from 'components/tables/learner-stats/elements';
import React from 'react';

interface Props {
  toolbar: JSX.Element;
  data: JSX.Element;
}

export const Container = (props: Props) => {
  const { toolbar, data } = props;

  return (
    <TableContainer>
      {toolbar}
      {data}
    </TableContainer>
  );
};
