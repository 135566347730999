import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ceregoIconGreyRounded from 'assets/images/cerego-icon-grey-rounded.png';
import { ImageEditOverlay } from 'components/images/ImageBadge';
import { useItemEditorStore } from 'components/item-editor/store';
import { Color } from 'core';
import { useMutateSentence } from 'hooks/useMutateSentence';
import { IImage } from 'models/image';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { createImage } from 'utils/createImage';
import useStore from 'zstore';

interface SentenceImageProps {
  image: IImage | null;
  sentenceId: string;
}

export const SentenceImage = (props: SentenceImageProps) => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const { image, sentenceId } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [mutateSentence] = useMutateSentence(item?.id, assignment?.id);
  const { makeToast } = useStore();

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updateSentence = async (image: IImage | null) => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const updatedSentences = item.sentences.map((sentence) =>
      sentence.id === sentenceId ? { ...sentence, image } : sentence
    );

    // update app state
    updateActiveItem({ sentences: updatedSentences });

    // update the image in the database
    await mutateSentence({
      id: sentenceId,
      payload: {
        data: {
          relationships: {
            image: {
              data: {
                id: image?.id,
              },
            },
          },
        },
      },
    });
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const file = event.target.files?.[0];

    try {
      if (file) {
        const image = await createImage(file);

        await updateSentence(image);
      }
    } catch (error: any) {
      // TODO: roll back optimistic update
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  };

  const handleDeleteImage = async () => {
    await updateSentence(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <ImageEditOverlay
          style={{
            width: '85px',
            height: '85px',
            borderRadius: '20%',
            background: `${Color.sideNavDark}`,
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={handleImageClick}
        >
          <FontAwesomeIcon icon={faEdit} style={{ color: 'white', fontSize: '22px' }} />
        </ImageEditOverlay>
        <img
          src={image ? image.url : ceregoIconGreyRounded}
          style={{
            objectFit: 'cover',
            width: '85px',
            height: '85px',
            borderRadius: '20%',
          }}
        />
      </div>
      <FileInput ref={fileInputRef} type="file" accept="image/*" onChange={handleImageUpload} />
      {!!image && (
        <DeleteButton onClick={handleDeleteImage}>
          <DeleteIcon />
        </DeleteButton>
      )}
    </div>
  );
};

const FileInput = styled.input`
  display: none;
`;

const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8); // Light background for better visibility
  &:hover {
    background: rgba(250, 50, 50, 0.8); // Change background on hover for emphasis
  }
`;
