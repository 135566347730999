import { Color } from 'core';
import { MemreFlexBox } from './index';

export default function MemreProgressBar({
  progress,
  hideNumber,
  minWidth,
}: {
  progress: number;
  hideNumber?: boolean;
  minWidth?: string;
}) {
  return (
    <MemreFlexBox align="center">
      {!hideNumber && <div style={{ marginRight: '12px' }}>{Math.round(progress)}%</div>}
      <div
        style={{
          height: '12px',
          width: '100%',
          minWidth,
          backgroundColor: Color.veryLightGray,
          borderRadius: '2px',
        }}
      >
        <div
          style={{
            height: '100%',
            width: `${progress}%`,
            backgroundColor: Color.ceregoGreen,
            borderRadius: '2px',
          }}
        />
      </div>
    </MemreFlexBox>
  );
}
