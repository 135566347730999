import {
  Box,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material';
import { MemreFlexBox, MemreText } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import React, { useState } from 'react';

export const CoursePaymentSettings = () => {
  const user = useCurrentUser();
  const [isPaymentRequired, setIsPaymentRequired] = useState(
    user?.primaryPartner.partnerSettings.paymentsRequired || false
  );
  const [pricePerCourse, setPricePerCourse] = useState<string>(
    user?.primaryPartner.partnerSettings.price
      ? user.primaryPartner.partnerSettings.price.toFixed(2)
      : '0.50'
  );
  const [priceError, setPriceError] = useState('');
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);

  const handleRequiredPaymentToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    setIsPaymentRequired(checked);
    debouncedPartnerUpdate({
      partner_settings: {
        payments_required: checked,
        price: parseFloat(pricePerCourse),
      },
    });
  };

  const handlePricePerCourseChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    // Regular expression to allow only numbers with up to two decimal places
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setPricePerCourse(value);
      setPriceError('');
      if (parseFloat(value) > 0) {
        debouncedPartnerUpdate({
          partner_settings: {
            payments_required: true,
            price: parseFloat(value),
          },
        });
      }
    } else {
      setPriceError('Please enter a valid price with up to two decimal places.');
    }
  };

  const handlePriceBlur = () => {
    if (pricePerCourse) {
      const formattedPrice = parseFloat(pricePerCourse).toFixed(2);
      setPricePerCourse(formattedPrice);
      debouncedPartnerUpdate({
        partner_settings: {
          payments_required: true,
          price: parseFloat(formattedPrice),
        },
      });
    }
  };

  return (
    <ConfigItem title="Payment Settings">
      <MemreFlexBox direction="column" gap={3}>
        {/* Description */}
        <MemreText variant="body1">
          Configure payment settings for course enrollments.
        </MemreText>

        {/* Payment Toggle */}
        <FormControlLabel
          control={
            <Switch
              checked={isPaymentRequired}
              onChange={handleRequiredPaymentToggle}
              color="secondary"
            />
          }
          label={<MemreText variant="body1">Require Payment for Courses</MemreText>}
        />

        {/* Price Input */}
        {isPaymentRequired && (
          <Box mt={2}>
            <MemreFlexBox direction="column" gap={1}>
              <TextField
                label="Price Per Course"
                type="text"
                fullWidth
                value={pricePerCourse}
                onChange={handlePricePerCourseChange}
                onBlur={handlePriceBlur}
                color="secondary"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputMode: 'decimal',
                }}
                error={Boolean(priceError)}
                helperText={priceError}
              />
            </MemreFlexBox>
          </Box>
        )}
      </MemreFlexBox>
    </ConfigItem>
  );
};
