import * as Color from 'core/colors';
import * as Size from 'core/size';
import styled from 'styled-components';
import { TFlexProps } from 'types';

export const PageContainer = styled.main`
  min-height: 200px;
  width: 100%;
`;
export const TableContainer = styled.div`
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 2em 0 0 0;
`;

export const Card = styled.div`
  width: ${Size.defaultCardWidth}px;
  border-radius: 4px;
  border: solid 1px ${Color.veryLightGray};
  background-color: ${Color.white};
  margin-bottom: 1em;

  & > * {
    padding: 1.5rem 2rem 1.5rem;
  }
`;

export const ResponsiveCard = styled(Card)`
  width: auto;

  @media (min-width: ${Size.defaultBreakpoint}px) {
    width: ${Size.wideCardWidth}px;
  }
`;

export const CardHeaderContainer = styled.div`
  border-bottom: solid 1px ${Color.veryLightGray};
  padding-bottom: 1.25em;
`;

export const CardHeader = styled.h3`
  margin: 0;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color: ${Color.slateGray};
`;

export const CardSubHeader = styled.h4`
  margin: 0;
  font-size: 16px;
  line-height: 1.56;
  font-weight: normal;
  color: ${Color.reportGray};
`;

export const Masthead = styled.div`
  position: relative;
  background-color: white;
  padding-bottom: 1.5em;

  > * {
    padding: 0 1.75em;
  }

  h3 {
    margin-bottom: 0.5em;
  }
`;

export const TitleSection = styled.section`
  max-width: ${Size.reportPageWidth}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 1.75em;
  margin: 0 auto;
`;
export const ContentSection = styled.section`
  max-width: ${Size.reportPageWidth}px;
  margin: 0 auto;
  padding: 0 1.75em;
`;

export const PageTitle = styled.h1`
  font-size: 2.25rem;
  margin: 0;
  vertical-align: middle;
`;

export const PageSubTitle = styled.h3`
  font-size: 1em;
  font-weight: 500;
  margin: 0;
`;

export const HeaderIconButton = styled.div`
  width: 28px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  margin: 0px 13px;
  text-align: center;
  border-radius: 50%;
  background-color: ${Color.reportButtonGray};
  color: white;
  font-weight: 600;
  cursor: pointer;
`;

export const TitlePrefix = styled.div`
  color: ${Color.lightGray};
  white-space: nowrap;
  padding: 2rem 2rem 0;
  font-size: 0.8rem;
  margin: 0 auto;
  max-width: ${Size.reportPageWidth}px;
  color: ${Color.reportGray};
  span.CourseStatus {
    font-weight: 900;
    color: ${(props: { color: string }) => props.color};
  }
  span.StatusIcon,
  span.CourseStatus {
    margin-right: 0.5em;
  }
`;

export const StatusCallout = styled.span`
  color: white;
  background-color: ${Color.reportButtonGray};
  border-radius: 15px;
  padding: 0.5em 1em;
  margin-right: 1em;
  text-transform: capitalize;
`;

export const DividingLine = styled.hr`
  background: ${Color.primaryBlack};
  height: 1px;
  border: none;
  padding: 0;
  margin: 0;
`;

export const LightDividingLine = styled(DividingLine)`
  background-color: ${Color.lightGray};
  margin: 1em 0;
`;

export const CardContainer = styled.div`
  background: ${Color.white};
  margin: 2em 0;
  padding: 1em;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: ${(props: TFlexProps) => props.alignItems};
  justify-content: ${(props: TFlexProps) => props.justifyContent};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: ${(props: TFlexProps) => props.alignItems};
  justify-content: ${(props: TFlexProps) => props.justifyContent};
`;
