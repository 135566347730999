import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

// NOTE: id is the partner slug for some reason lol
type UpdateOAuthClientPayload = {
  id: string;
  identifier: string;
  name: string;
  website: string;
  redirect_uri: string;
  access_token_url: string;
  public_jwk_url?: string | null;
};

const updateOAuthClient = ({ clientId, payload }: { clientId: string; payload: UpdateOAuthClientPayload }) => {
  return axios.put(`/api/v3/oauth2_clients/${clientId}`, payload);
};

export const useMutateOAuthClient = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();

  return useMutation(updateOAuthClient, {
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['oauthClients', user?.primaryPartner.slug]);
    },
  });
};
