import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCourses } from 'hooks/useCourses';
import React, { useState } from 'react';
import { TCourse } from 'types';
import { useReportStore } from 'zstore';

export const CourseFilter = () => {
  const courseId = useReportStore((state) => state.courseId);
  const setCourseId = useReportStore((state) => state.setCourseId);
  const setAssignmentId = useReportStore((state) => state.setAssignmentId);
  const setStudentId = useReportStore((state) => state.setStudentId);
  const setPage = useReportStore((state) => state.setPage);
  const { data: courses, isLoading } = useCourses();
  const [value, setValue] = useState<any>(courses?.find((course: TCourse) => course.id === courseId) || null);
  const [inputValue, setInputValue] = useState(courses?.find((course: TCourse) => course.id === courseId)?.name || '');
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id="course-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={isLoading}
      value={value}
      onChange={(_event: any, newValue: any) => {
        if (!!newValue) {
          setValue(newValue);
          setCourseId(newValue.id);
          // Assignments and students are dependent on the selected course
          setAssignmentId(null);
          setStudentId(null);
          setPage(1);
        } else {
          setValue(null);
          setCourseId(null);
          setAssignmentId(null);
          setStudentId(null);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      options={courses?.sort((a: TCourse, b: TCourse) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) || []}
      getOptionLabel={(option: any) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ margin: '20px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select course"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      selectOnFocus
    />
  );
};
