import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as colours from 'core/colors';

type Props = {
  month: string;
  date: number;
  score: number;
};
export default function MemreCalendarDay({ month, date, score }: Props) {
  return (
    <Box
      sx={{
        height: 55,
        border: `1px solid ${colours.lightGray}`,
        bgcolor: colours.white,
        boxShadow: `5px 5px 0px 0px ${colours.hexToRGB(colours.brightGreen, score)}`,
        display: 'flex',
        flexGrow: 1,
        gap: 0.2,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="overline">{month}</Typography>
      <Typography variant="h5">{date}</Typography>
    </Box>
  );
}
