import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroupsStore } from 'pages/admin-dashboard/groups/store';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getGroups = async (
  _key: string,
  partnerId: string,
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
  name: string
) => {
  const endpoint = `/api/v3/partners/${partnerId}/groups`;
  const sortParam = sortDirection === 'desc' ? `-${sortColumn}` : sortColumn;
  let queryParams = `page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sortParam}&tab=default`;
  if (name) {
    queryParams += `&query=${name}`;
  }

  try {
    const response = await axios.get(`${endpoint}?${queryParams}`);
    return normalizeJSONAPIResponse(response.data);
  } catch (err) {
    console.error(err);
  }
};

export const usePaginatedGroups = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupsStore();

  return useQuery(['groups', partner?.id, pageNumber, pageSize, sortColumn, sortDirection, name], getGroups, {
    enabled: !!partner,
  });
};
