import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getStudents = async (_key: string, courseId: string | null): Promise<any> => {
  if (!courseId) {
    return;
  }

  let page = 1;
  let pageSize = 200;
  let endpoint = `/api/v3/courses/${courseId}/users?page[number]=${page}&page[size]=${pageSize}`;
  let students: Array<any> = [];

  try {
    const response = await axios.get(endpoint);
    const totalPages = response.data.meta['total-pages'];
    const responseData: any = normalizeJSONAPIResponse(response.data);
    students.push(...responseData);

    for (let page = 2; page <= totalPages; page++) {
      const endpoint = `/api/v3/courses/${courseId}/users?page[number]=${page}&page[size]=${pageSize}`;
      const response = await axios.get(endpoint);
      const responseData: any = normalizeJSONAPIResponse(response.data);
      students.push(...responseData);
    }

    return students;
  } catch (err) {
    console.error(err);
  }
};

export const useStudents = (courseId: string | null) => {
  return useQuery(['students', courseId], getStudents, {
    enabled: courseId,
  });
};
