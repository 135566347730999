import { Box, Typography } from '@mui/material';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createImage } from 'utils/createImage';
import useStore from 'zstore';

export const AppBrandingConfig: React.FC = () => {
  const user = useCurrentUser();
  const [iosBrandImageUrl, setIosBrandImageUrl] = useState<string | null>(
    user?.primaryPartner?.iosBrandImage?.url
  );
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);
  const { makeToast } = useStore();

  // Refs to hold mutable values that don't trigger re-renders
  const userIdRef = useRef(user?.primaryPartner?.id);

  useEffect(() => {
    userIdRef.current = user?.primaryPartner?.id;
  }, [user?.primaryPartner?.id]);

  useEffect(() => {
    if (!iosBrandImageUrl) {
      setIosBrandImageUrl(user?.primaryPartner?.ios_brand_image?.url);
    }
  }, [user]);

  // Define the debounced function for updating partner branding with the new image
  const debouncedPartnerBrandingUpdate = useCallback(
    debounce(async (payload: any) => {
      try {
        await debouncedPartnerUpdate(payload);
      } catch (error: any) {
        console.error(error);
        makeToast({
          text: 'Uh-oh! Something went wrong. Please try again.',
          isError: true,
        });
      }
    }, 1000),
    [] // Empty dependency array ensures this function is created only once
  );

  // Cancel debounce on unmount
  useEffect(() => {
    return () => {
      debouncedPartnerBrandingUpdate.cancel();
    };
  }, [debouncedPartnerBrandingUpdate]);

  // Refactored function to handle file uploads
  const handleFileUpload = async (file: File) => {
    if (file) {
      try {
        const image = await createImage(file); // Create image
        setIosBrandImageUrl(URL.createObjectURL(file)); // Preview the uploaded image

        // Update partner with the new image ID
        debouncedPartnerBrandingUpdate({
          ios_brand_image_id: image.id,
          include:
            'logo_image,ios_brand_image,icon_image,image,background_image,partner_domains,partner_library,partner_library.image',
        });

        makeToast({
          text: 'Image uploaded successfully!',
          isError: false,
        });
      } catch (error) {
        console.error(error);
        makeToast({
          text: 'Failed to upload image. Please try again.',
          isError: true,
        });
      }
    }
  };

  // Handle image upload from file input
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  // Handle drag over event
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // Handle drop event
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  // Handle deleting the uploaded image
  const handleDeleteImage = () => {
    // Set image URL to null in the state and update the partner to remove the image
    setIosBrandImageUrl(null);

    // Update the partner to set the ios_brand_image_id to null
    debouncedPartnerBrandingUpdate({
      ios_brand_image_id: null,
      include:
        'logo_image,ios_brand_image,icon_image,image,background_image,partner_domains,partner_library,partner_library.image',
    });

    makeToast({
      text: 'Image deleted successfully!',
      isError: false,
    });
  };

  return (
    <MemreFlexBox gap={2} direction="column" align="start" justify="center">
      <MemreText variant="h4" sx={{ mb: 2 }}>
        Add a custom welcome or white label brand name.
      </MemreText>

      <MemreFlexBox gap={2} direction="column" align="start" justify="center">
        {iosBrandImageUrl ? (
          <MemreFlexBox
            gap={2}
            direction="row"
            align="center"
            justify="flex-start"
          >
            <Box
              sx={{
                width: 120,
                height: 120,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 3,
                border: '1px solid #ccc',
                '&:hover .hoverOverlay': {
                  opacity: 1,
                },
              }}
            >
              <img
                src={iosBrandImageUrl}
                alt="Uploaded Preview"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
            <MemreButton
              text="Delete"
              variant="contained"
              color="error"
              onClick={handleDeleteImage}
              sx={{ marginTop: '10px' }}
            />
          </MemreFlexBox>
        ) : (
          <MemreFlexBox
            gap={2}
            direction="row"
            align="center"
            justify="flex-start"
          >
            <label
              htmlFor="imageUpload"
              style={{ cursor: 'pointer', display: 'block' }}
            >
              <Box
                sx={{
                  width: 120,
                  height: 120,
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: 3,
                  border: '1px dashed #ccc',
                  backgroundColor: '#f9f9f9',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Click or Drag to upload an image
                </Typography>
              </Box>
            </label>
          </MemreFlexBox>
        )}
        <input
          id="imageUpload"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </MemreFlexBox>

      <div style={{ marginBottom: '20px' }}>
        <MemreText>Tips:</MemreText>
        <ul>
          <li>
            Add a custom welcome or product name to your iOS loading screen.
          </li>
          <li>
            This will only show for students who are members of a course. 400 x
            120px.
          </li>
        </ul>
      </div>

      <div
        style={{
          textAlign: 'center',
          border: '1px solid #ddd',
          padding: '10px',
        }}
      >
        <img
          src="https://stable.assets.cerego.com/_assets/v2/partner-profile/load-ios-template-acd5670fbef78116da71e86d7554c5d8a7af0f0301096f7c14bb1d4da4392ec5.png"
          alt="Reference Template"
          style={{
            maxWidth: '100%',
            maxHeight: '400px',
            objectFit: 'contain',
          }}
        />
      </div>
    </MemreFlexBox>
  );
};

