import CellWithPill from 'components/tables/CellWithPill';
import { Color } from 'core';
import * as React from 'react';
import convertProgressToInteger from 'utils/convertProgressToInteger';

interface GoalProgressCellProps {
  progress: number;
  goalReachedAt: string;
}

export const GoalProgressCell = (props: GoalProgressCellProps) => {
  const { progress, goalReachedAt } = props;

  let color: string;
  let pillText: string;
  if (progress === 0) {
    color = Color.reportRed;
    pillText = 'Unstarted';
  } else if (progress < 1) {
    color = Color.reportBlue;
    pillText = 'In Progress';
  } else {
    color = Color.primaryGreen;
    pillText = goalReachedAt ? goalReachedAt.slice(0, 10) : 'Complete';
  }

  return (
    <CellWithPill
      text={convertProgressToInteger(progress) + '%'}
      textColor={color}
      pillText={pillText}
      pillColor={color}
      pillBackgroundColor={Color.hexToRGB(color, 0.1)}
    />
  );
};
