import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelButton, DeleteButton, PrimaryButton } from 'components/buttons/v4';
import { BrightTextarea } from 'components/forms/BrightTextarea';
import { InstructorList } from 'components/InstructorList';
import { List, ListIcon } from 'components/modals/common';
import { Color, Size } from 'core';
import { Card, CardHeader, CardHeaderContainer, CardSubHeader, PageTitle } from 'core/layout';
import { API_DATA_SUCCESS } from 'middleware/api';
import { IUser } from 'models/user';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleGetCourse, REMOVE_INSTRUCTOR_FROM_COURSE } from 'store/course';
import { handleCreateCourseUser, handleDeleteCourseUser } from 'store/course-user';
import styled from 'styled-components';
import { AnalyticsService } from 'utils/AnalyticsService';
import useStore from 'zstore';

interface Props {
  instructors: IUser[];
  courseId: string;
  close: () => void;
  handleSuccessfulInvites: (successfulInviteCount: number) => void;
}

export const ManageInstructorsModal = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { instructors } = props;
  const placeholder = t('e.g., name1@organization.com, name2@organization.com');
  const [inviteList, setInviteList] = useState('');
  const [invitesArePending, setInvitesArePending] = useState(false);
  const [successfulInvites, setSuccessfulInvites] = useState<string[]>([]);
  const [failedInvites, setFailedInvites] = useState<string[]>([]);
  const { makeToast } = useStore();
  const [isRemovingInstructors, setIsRemovingInstructors] = useState(false);

  const instructorActions = {
    allowed: [
      t('Add or remove assignments'),
      t('Add or remove learners'),
      t('Assign goals and schedules'),
      t('See analytics and reports'),
      t('Publish, archive or delete a course'),
    ],
    disallowed: [t('Create new courses'), t('View other courses'), t('Edit assignments from shared library')],
  };

  const updateInviteList = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInviteList(event.target.value);
  };

  const handleInviteClick = async () => {
    setInvitesArePending(true);
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add instructor' });
    const emails = inviteList
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email !== '');
    const promises = emails.map((emailAddress) => {
      return dispatch(handleCreateCourseUser(props.courseId, emailAddress, true, 'enrolled_via_direct_invite', true));
    });
    const results = await Promise.all(promises);
    setInvitesArePending(false);
    setInviteList('');

    const goodResults: string[] = [];
    const badResults: string[] = [];
    results.forEach((result, i) => {
      if ((result as any).type === API_DATA_SUCCESS) {
        dispatch(handleGetCourse(props.courseId));
        goodResults.push(emails[i]);
      } else {
        badResults.push(emails[i]);
      }
    });
    setSuccessfulInvites(goodResults);
    setFailedInvites(badResults);

    if (goodResults.length > 0 && badResults.length === 0) {
      props.handleSuccessfulInvites(goodResults.length);
    }
  };

  const removeAllInstructors = async () => {
    const confirmed = window.confirm(t('Are you sure you want to remove all instructors?'));
    if (!confirmed) {
      return;
    }

    try {
      setIsRemovingInstructors(true);
      const promises = instructors.map((instructor) => {
        return dispatch(handleDeleteCourseUser(props.courseId, instructor.id));
      });
      await Promise.all(promises);
      for (const instructor of instructors) {
        dispatch({
          type: REMOVE_INSTRUCTOR_FROM_COURSE,
          courseId: props.courseId,
          userId: instructor.id,
        });
      }
      makeToast({
        text: t('All instructors removed'),
        isError: false,
      });
    } catch (error: any) {
      console.error(error.message);
      makeToast({
        text: t('Something went wrong'),
        isError: true,
      });
    } finally {
      setIsRemovingInstructors(false);
      props.close();
    }
  };

  return (
    <Container>
      <CenterTitle>{t('Manage Instructors')}</CenterTitle>
      <Card>
        <CardHeaderContainer>
          <CardHeader
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          >
            {t('Current Instructor(s)')}
            <DeleteButton
              click={removeAllInstructors}
              disabled={isRemovingInstructors || instructors.length === 0}
              pending={isRemovingInstructors}
            >
              {t('Remove All')}
            </DeleteButton>
          </CardHeader>
        </CardHeaderContainer>
        <InstructorList courseId={props.courseId} instructors={instructors} />
      </Card>
      <Card>
        <CardHeaderContainer>
          <CardHeader id="addEmailHeader">{t('Add by email')}</CardHeader>
          <CardSubHeader>{t('Invite any number of instructors to manage your course')}</CardSubHeader>
        </CardHeaderContainer>
        <div>
          <BrightTextarea
            value={inviteList}
            placeholder={placeholder}
            onChange={updateInviteList}
            aria-labelledby="addEmailHeader"
          />
          {successfulInvites.length > 0 && (
            <SuccessfulInvitesContainer>
              <div>{t('Manage Instructors Modal Instructors Added', { count: successfulInvites.length })}</div>
              <div>{successfulInvites.length} instructors were added.</div>
              {successfulInvites.map((invite, i) => (
                <div key={i}>{invite}</div>
              ))}
            </SuccessfulInvitesContainer>
          )}
          {failedInvites.length > 0 && (
            <FailedInvitesContainer>
              <div>{t('Manage Instructors Modal Emails Invalid', { count: failedInvites.length })}</div>
              {failedInvites.map((invite, i) => (
                <div key={i}>{invite}</div>
              ))}
            </FailedInvitesContainer>
          )}
          <PrimaryButton type="submit" click={handleInviteClick} pending={invitesArePending}>
            {t('Invite')}
          </PrimaryButton>
        </div>
      </Card>
      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('What can instructors do?')}</CardHeader>
        </CardHeaderContainer>
        <ListContainer>
          <List>
            {instructorActions.allowed.map((text, i) => (
              <li key={i}>
                <ListIcon color={Color.darkGreen}>
                  <FontAwesomeIcon icon={faCheck} />
                </ListIcon>
                {text}
              </li>
            ))}
          </List>
          <List>
            {instructorActions.disallowed.map((text, i) => (
              <li key={i}>
                <ListIcon color={Color.regularRed}>
                  <FontAwesomeIcon icon={faTimes} />
                </ListIcon>
                {text}
              </li>
            ))}
          </List>
        </ListContainer>
        <p style={{ color: Color.mainBlue, fontSize: '14px' }}>
          {t('To add more privileges, have an admin switch a user to a staff role: ADMIN > STAFF')}
        </p>
      </Card>
      <CancelButton click={props.close}>{t('Cancel')}</CancelButton>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: ${Size.defaultCardWidth}px;

  > * {
    margin-bottom: 20px;
  }

  p {
    color: ${Color.reportGray};
  }
`;

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const ListContainer = styled.div`
  display: flex;

  > * {
    width: 50%;
    color: ${Color.slateGray};
  }
`;

const SuccessfulInvitesContainer = styled.div`
  margin: 10px 0 10px 0;
`;

const FailedInvitesContainer = styled.div`
  color: ${Color.red};
  margin: 10px 0 10px 0;
`;
