import DateFnsUtils from '@date-io/date-fns';
import { faArrowDown, faArrowUp, faChartPie, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from '@material-ui/core/Switch';
import Skeleton from '@material-ui/lab/Skeleton';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingTableState,
  useReactTable,
} from '@tanstack/react-table';
import { DownloadCSV } from 'components/DownloadCSV';
import { Pagination } from 'components/reports/controls/Pagination';
import { AssignmentFilter } from 'components/reports/filters/AssignmentFilter';
import { CourseFilter } from 'components/reports/filters/CourseFilter';
import { StudentFilter } from 'components/reports/filters/StudentFilter';
import { SortArrow } from 'components/SortArrow';
import { Color } from 'core';
import { useDailyStudyTime } from 'hooks/useDailyStudyTime';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import styled from 'styled-components';
import { transformMilliseconds } from 'utils/timeUtils';
import { useReportStore } from 'zstore';

export const DailyStudyTime = () => {
  return (
    <>
      <Filters />
      <Data />
    </>
  );
};

const Filters = () => {
  return (
    <div>
      <CourseFilter />
      <AssignmentFilter />
      <StudentFilter />
      <DateRangeFilter />
    </div>
  );
};

const DateRangeFilter = () => {
  const startDate = useReportStore((state) => state.startDate);
  const setStartDate = useReportStore((state) => state.setStartDate);
  const endDate = useReportStore((state) => state.endDate);
  const setEndDate = useReportStore((state) => state.setEndDate);

  const handleStartDateChange = (date: string): void => {
    setStartDate(new Date(date));
  };

  const handleEndDateChange = (date: string): void => {
    setEndDate(new Date(date));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
        <p style={{ marginRight: '10px' }}>Start Date: </p>
        <DatePicker date={startDate} onChange={handleStartDateChange} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <p style={{ marginRight: '10px' }}>End Date: </p>
        <DatePicker date={endDate} onChange={handleEndDateChange} />
      </div>
    </div>
  );
};

interface DatePickerProps {
  date: Date;
  onChange: (event: any) => void;
}

const DatePicker = (props: DatePickerProps) => {
  const [date, setDate] = useState<Date>(props.date);

  const handleDateChange = (event: any): void => {
    const date = moment(event).startOf('day').toDate();

    setDate(date);
    props.onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        variant="inline"
        inputVariant="standard"
        format="MM/dd/yy"
        value={date}
        InputAdornmentProps={{ position: 'start' }}
        disableToolbar={false}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};

const Data = () => {
  const courseId = useReportStore((state) => state.courseId);
  const assignmentId = useReportStore((state) => state.assignmentId);
  const studentId = useReportStore((state) => state.studentId);
  const startDate = useReportStore((state) => state.startDate);
  const endDate = useReportStore((state) => state.endDate);
  const [display, setDisplay] = useState<'table' | 'viz'>('viz');
  const [vizPage, setVizPage] = useState(0);
  const vizPageSize = 10;

  const [totalCount, setTotalCount] = useState(0);

  const page = 1;
  const pageSize = 200;

  const [columns, setColumns] = useState<ColumnDef<any>[]>([]);

  const { data: response, isLoading } = useDailyStudyTime(
    courseId,
    assignmentId,
    studentId,
    startDate,
    endDate,
    page,
    pageSize
  );

  const data = response?.data || null;
  const meta = response?.meta;

  useEffect(() => {
    if (!!response) {
      setTotalCount(meta['total_count']);
    }
  }, [JSON.stringify(data)]);

  const handleDisplayChange = () => {
    setDisplay(display === 'table' ? 'viz' : 'table');
  };

  const renderHeader = (title: string) => {
    return (
      <TableHeaderContainer>
        <TableHeaderText>{title.toLowerCase() === 'user_name' ? 'Name' : title}</TableHeaderText>
      </TableHeaderContainer>
    );
  };

  useEffect(() => {
    if (response?.data.length > 0) {
      const columnHeaders = Object.keys(response.data[0]);
      let cols: ColumnDef<any>[] = [];
      columnHeaders.forEach((header: string) => {
        if (['course', 'assignment', 'user_name', 'email'].includes(header)) {
          cols.push({
            id: header,
            accessorFn: (row: any) => row[header].toLowerCase(),
            cell: (info: any) => info.row.original[header],
            header: () => renderHeader(header),
          });
        } else {
          cols.push({
            id: header,
            accessorFn: (row: any) => transformMilliseconds(row[header] * 1000, 'total', header !== 'total_study_time'),
            header: () => renderHeader(header === 'total_study_time' ? 'Total Study Time' : header),
          });
        }
      });
      setColumns(cols);
    }
  }, [JSON.stringify(response)]);

  const defaultSort: SortingTableState = {
    sorting: [
      {
        id: 'total_study_time',
        desc: true,
      },
    ],
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: defaultSort,
    debugTable: true,
  });

  const goToPageOne = (): void => {
    if (table.getCanPreviousPage()) {
      table.setPageIndex(0);
    }
  };

  const goToPreviousPage = (): void => {
    if (table.getCanPreviousPage()) {
      table.previousPage();
    }
  };

  const goToNextPage = (): void => {
    if (table.getCanNextPage()) {
      table.nextPage();
    }
  };

  const goToLastPage = (): void => {
    if (table.getCanNextPage()) {
      table.setPageIndex(table.getPageCount() - 1);
    }
  };

  let lastVizPage = Math.floor(totalCount / vizPageSize);
  if (totalCount % vizPageSize === 0) {
    lastVizPage -= 1;
  }

  const goToVizPageOne = (): void => {
    setVizPage(0);
  };

  const goToPreviousVizPage = (): void => {
    if (vizPage > 0) {
      setVizPage(vizPage - 1);
    }
  };

  const goToNextVizPage = (): void => {
    if (vizPage < lastVizPage) {
      setVizPage(vizPage + 1);
    }
  };

  const goToLastVizPage = (): void => {
    setVizPage(lastVizPage);
  };

  // Show skeleton UI while waiting for table data
  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={500} />;
  }

  // Show empty space if data hasn't yet been requested
  if (!data || !columns || columns.length === 0) {
    return <></>;
  }

  // Show empty table message if we know no data is available for this report
  if (data.length === 0) {
    return (
      <DataContainer>
        <div style={{ height: '100%', margin: '20px' }}>
          <p>No records found.</p>
        </div>
      </DataContainer>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}>
          <FontAwesomeIcon icon={faTable} style={{ fontSize: '24px', color: Color.mainBlue }} />
          <Switch
            checked={display === 'viz'}
            onChange={handleDisplayChange}
            name="dataVizSwitch"
            style={{ color: Color.mainBlue }}
          />
          <FontAwesomeIcon icon={faChartPie} style={{ fontSize: '24px', color: Color.mainBlue }} />
        </div>

        <span style={{ marginRight: '20px' }}>
          <DownloadCSV data={data || []} filename={`daily-study-time-${new Date().toISOString()}.csv`}>
            Download
          </DownloadCSV>
        </span>
      </div>

      {display === 'viz' && (
        <>
          <Viz
            data={data
              .sort((a: number, b: number) => b['total_study_time'] - a['total_study_time'])
              .slice(vizPage * vizPageSize, vizPage * vizPageSize + vizPageSize)}
          />

          <Pagination
            totalCount={totalCount}
            page={vizPage + 1}
            pageSize={vizPageSize}
            goToPageOne={goToVizPageOne}
            goToPreviousPage={goToPreviousVizPage}
            goToNextPage={goToNextVizPage}
            goToLastPage={goToLastVizPage}
          />
        </>
      )}

      {display === 'table' && (
        <>
          <DataContainer>
            <DataTable>
              <thead style={{ backgroundColor: Color.grayTransparent }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          padding: '2px 5px',
                          width: header.getSize(),
                          minWidth: ['user_name', 'email', 'total_study_time'].includes(header.id) ? '150px' : '100px',
                          paddingLeft: header.id === 'user_name' ? '20px' : '5px',
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                            {...{ onClick: header.column.getToggleSortingHandler() }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getIsSorted() === 'asc' && <SortArrow icon={faArrowUp} />}
                            {header.column.getIsSorted() === 'desc' && <SortArrow icon={faArrowDown} />}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    style={{
                      borderTop: '1px solid rgb(224, 224, 224, 1)',
                      borderBottom: '1px solid rgb(224, 224, 224, 1)',
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          padding: '18px',
                          paddingLeft: cell.column.id === 'user_name' ? '20px' : '5px',
                          paddingRight: '5px',
                          color: Color.reportGray,
                          fontSize: '14px',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </DataTable>
          </DataContainer>

          <Pagination
            totalCount={totalCount}
            page={table.getState().pagination.pageIndex + 1}
            pageSize={table.getState().pagination.pageSize}
            goToPageOne={goToPageOne}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </>
      )}
    </>
  );
};

const parseDomain = (rowData: any) => {
  return [0, Math.max.apply(null, rowData.map((row: any) => row.data.map((d) => d.value)).flat())];
};

interface BubbleRowProps {
  data: any;
  dataKey: string;
  domain: any;
  range: any;
  xTick?: any;
  label: string;
  labelWidth: number;
  renderTooltip: any;
}

const BubbleRow = (props: BubbleRowProps) => {
  const { data, dataKey, domain, range, xTick, label, labelWidth, renderTooltip } = props;

  return (
    <ResponsiveContainer width="100%" height={60}>
      <ScatterChart
        width={800}
        height={60}
        margin={{
          top: 10,
          right: 50,
          bottom: 0,
          left: 0,
        }}
      >
        <XAxis
          type="category"
          dataKey={dataKey}
          name={dataKey}
          interval={Math.ceil(data.length / 10)}
          tick={!!xTick ? xTick : {}}
          tickLine={{ transform: 'translate(0, -6)' }}
        />
        <YAxis
          type="number"
          dataKey="index"
          name={label.toLowerCase()}
          height={10}
          width={Math.max(100, labelWidth)}
          tick={false}
          tickLine={false}
          axisLine={false}
          label={{ value: label, position: 'insideRight', fontSize: '14px' }}
        />
        <ZAxis type="number" dataKey="value" domain={domain} range={range} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{ zIndex: 100 }} content={renderTooltip} />
        <Scatter data={data} fill={Color.mainBlue} />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

interface VizProps {
  data: any;
}

const Viz = (props: VizProps) => {
  const { data } = props;

  const longestName = Math.max(...data?.map((d: any) => d['user_name'].length));

  const rowData = data.map((d: any) => {
    const keys = Object.keys(d);
    let row: any = {
      name: d['user_name'],
      email: d['email'],
      data: [],
    };

    const SKIP_KEYS = ['course', 'assignment', 'user_name', 'email', 'total_study_time'];

    for (const key of keys) {
      if (!SKIP_KEYS.includes(key)) {
        row.data.push({ day: key, index: 1, value: d[key] });
      }
    }

    return row;
  });

  const renderTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10,
          }}
        >
          <p>{data.day}</p>
          <p>{data.value === 0 ? '0 seconds' : transformMilliseconds(data.value * 1000, 'total', true)}</p>
        </div>
      );
    }

    return null;
  };

  const domain = parseDomain(rowData);
  const range = [0, 300];

  if (!rowData) {
    return <></>;
  }

  return (
    <div style={{ width: '100%' }}>
      {rowData.map((row: any, i: number) => (
        <BubbleRow
          key={row.name.concat('::').concat(row.email)}
          data={row.data}
          dataKey="day"
          xTick={i === rowData.length - 1 ? {} : { fontSize: 0 }}
          domain={domain}
          range={range}
          label={row.name}
          labelWidth={longestName * 10}
          renderTooltip={renderTooltip}
        />
      ))}
    </div>
  );
};

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.54);
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 520px;
  overflow: auto;
`;

const DataTable = styled.table`
  border-collapse: collapse;
  border: 1px solid ${Color.grayTransparent};
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  color: ${Color.primaryBlack};
  font-size: 1em;
  font-weight: 400;
`;
