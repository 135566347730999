import { MemreContainer, MemreHeroSection, MemreLoading, MemreSection, MemreText } from 'components/core';
import { useRecommendedAssignments } from 'hooks/useRecommendedAssignments';
import { useEffect, useState } from 'react';
import { getBaseAngularUrl, getBaseReactUrl } from '../../utils/pathUtils';
import IdealNextSession from './visulisations/IdealNextSession';

type Props = {
  coursesCount: number;
};

export const StudyBlock = ({ coursesCount }: Props) => {
  const { data, isLoading } = useRecommendedAssignments();
  const [learningJourney, setLearningJourney] = useState<LearningJourney[]>([]);

  useEffect(() => {
    const filteredItems = filterItems(data);
    const formattedItems = filteredItems?.map(formatItems);
    setLearningJourney(formattedItems);
  }, [data]);

  if (isLoading) {
    return (
      <MemreSection>
        <MemreContainer size="large" sx={{ height: '140px', justifyContent: 'center', alignItems: 'center' }}>
          <MemreLoading />
        </MemreContainer>
      </MemreSection>
    );
  }

  if (learningJourney && learningJourney.length > 0) {
    return <MemreHeroSection learningJourney={learningJourney} />;
  }

  if (coursesCount === 0) {
    return null;
  }

  return <IdealNextSession />;
};

type Item = {
  studyType: string;
  set: {
    id: string;
    name: string;
    learnVersion: number;
  };
};

type LearningJourney = {
  cta: string | undefined;
  url: string | undefined;
  name: string | undefined;
};

const filterItems = (items: Item[]) => {
  return items
    ?.filter((a: any) => ['fading_only', 'recommended'].includes(a.studyType) || [3, 4].includes(a.learnVersion))
    .slice(0, 4);
};

const formatItems = (item: Item) => {
  const {
    studyType,
    set: { id, name, learnVersion },
  } = item;

  const cta = studyType === 'recommended' ? 'Learn Now' : 'Review Now';
  const currentLocation = encodeURIComponent(window.location.pathname);
  const url = {
    3: `${getBaseAngularUrl()}/sets/${id}/learn/quiz?studyType=${studyType}&returnUrl=${currentLocation}`,
    4: `${getBaseReactUrl()}/app/sets/${id}/journey?studyType=${studyType}&returnUrl=${currentLocation}`,
  }[learnVersion];

  return {
    cta,
    url,
    name,
  };
};
