import { Button } from '@mui/material';
import { MemreIcon } from './index';

export default function MemreButton({
  text,
  iconName,
  variant = 'contained',
  onClick,
  color = 'primary',
  size = 'medium',
  href,
  sx = {},
  disabled = false,
}: {
  text: string;
  iconName?: any;
  variant?: 'contained' | 'outlined' | 'text';
  onClick?: (e?: any) => void;
  color?: 'primary' | 'secondary' | 'warning' | 'error';
  size?: 'small' | 'medium' | 'large';
  href?: string;
  sx?: any;
  disabled?: boolean;
}) {
  return (
    <Button
      sx={{ cursor: 'pointer', ...sx }}
      size={size}
      color={color}
      href={href}
      onClick={onClick}
      variant={variant}
      endIcon={iconName ? <MemreIcon iconName={iconName} /> : null}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
