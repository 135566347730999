import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableSearchBar } from 'components/tables/TableSearchBar';
import { useGroupCoursesStore } from 'pages/admin-dashboard/groups/store';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export const GroupCoursesTableToolbar = () => {
  const { setPageNumber, name, setName } = useGroupCoursesStore();

  return (
    <ToolbarContainer>
      <TableSearchBar
        value={name}
        onChange={setName}
        resetPage={() => setPageNumber(1)}
        placeholder="Search courses..."
        style={{ width: '100%' }}
      />
      <StatusFilter />
    </ToolbarContainer>
  );
};

const StatusFilter = () => {
  const { statusFilter, setStatusFilter } = useGroupCoursesStore();

  const statusFilterOptions = [
    { value: 'published', label: 'Published' },
    { value: 'unpublished', label: 'Unpublished' },
    { value: 'archived', label: 'Archived' },
  ];

  const [value, setValue] = useState(statusFilterOptions.find((option) => option.value === statusFilter) || null);
  const [inputValue, setInputValue] = useState(
    statusFilterOptions.find((option) => option.value === statusFilter)?.label || ''
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const selectedOption: any = statusFilterOptions.find((option) => option.value === statusFilter);
    setValue(selectedOption);
    setInputValue(selectedOption ? selectedOption.label : '');
  }, [statusFilter]);

  return (
    <Autocomplete
      id="status-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={(_event, newValue: any) => {
        if (newValue) {
          setValue(newValue);
          setStatusFilter(newValue.value);
        } else {
          setValue(null);
          setStatusFilter('published');
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={statusFilterOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      style={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Status Filter" variant="outlined" />}
    />
  );
};

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
`;
