import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

const deleteSentence = (id: string) => {
  return axios.delete(`/api/v3/sentences/${id}`);
};

export const useDeleteSentence = (itemId: string = '', setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(deleteSentence, {
    onSuccess: () => {
      makeToast({
        text: 'Sentence deleted successfully!',
        isError: false,
      });
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      makeToast({
        text: 'Failed to delete sentence',
        isError: true,
      });
      console.error(`ERROR! ${error}`);
    },
  });
};
