import { ClickToEdit } from 'components/ClickToEdit';
import { ContentText } from 'courses/components/ContentText';
import { format } from 'date-fns';
import { AssignmentStatus } from 'models/set';
import { TAssignment } from 'types';
import getAssignmentStatus from 'utils/getAssignmentStatus';

interface Props {
  assignment: TAssignment;
  course: any;
  handleClickToEdit: (offset: number[], assignmentId: string, column: string) => void;
}

export const StatusCell = (props: Props) => {
  const { assignment, course, handleClickToEdit } = props;

  const assignmentStatus: AssignmentStatus = getAssignmentStatus(assignment, course);
  const prereqIndicator: string = `Level ${assignment.meta.prereqValue}`;

  if (assignmentStatus === 'Building' || assignmentStatus === 'Error') {
    return <ContentText text={assignmentStatus} />;
  }

  return (
    <ClickToEdit id={assignment.id} column="publishType" click={handleClickToEdit}>
      <ContentText text={assignmentStatus} textDecoration="underline" />
      {assignment.status === 'Scheduled' && (
        <ContentText text={format(new Date(assignment.meta.publishAt), 'MM/dd/yy')} size="small" />
      )}
      {assignmentStatus === 'Gated' && <ContentText text={prereqIndicator} size="small" />}
    </ClickToEdit>
  );
};
