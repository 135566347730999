import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';

type GetPeopleParams = {
  partnerId: string;
  page: string;
  pageSize: string;
  group?: string;
  userId?: string;
  sort: string;
  sortDirection: string;
  limited?: string;
};

export const getPeople = async (_key: string, partnerId: string, hookParams: UsePeopleParams): Promise<any> => {
  const { page, pageSize, group, userId, sort, sortDirection, limited } = hookParams;

  let queryParams: GetPeopleParams = {
    partnerId: `partner_id=${partnerId}`,
    page: `page[number]=${page}`,
    pageSize: `page[size]=${pageSize}`,
    sort: `sort_by=${sort}`,
    sortDirection: `sort_direction=${sortDirection}`,
  };

  if (!!group) {
    queryParams.group = `group=${encodeURIComponent(group)}`;
  }

  if (!!userId) {
    queryParams.userId = `user_id=${userId}`;
  }

  if (!!limited) {
    queryParams.limited = `limited=${limited}`;
  }

  let queryString = '';
  Object.keys(queryParams).forEach((param) => {
    if (queryString.length > 0) {
      queryString = queryString.concat('&');
    }
    queryString = queryString.concat(queryParams[param]);
  });

  const endpoint = `/api/v4/people?${queryString}`;

  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export type UsePeopleParams = {
  page: number;
  pageSize: number;
  group: string | null;
  userId: string | null;
  sort: string;
  sortDirection: 'asc' | 'desc';
  limited: boolean | null;
};

export const usePeople = (params: UsePeopleParams): any => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;

  return useQuery(['people', partner?.id, params], getPeople, { enabled: partner?.id });
};
