import { BarProps, ResponsiveBar } from '@nivo/bar';
import { Color } from 'core';
import React from 'react';
import styled from 'styled-components';

const TooltipElement = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: ${Color.textGray};
  padding: 0.5em;
  max-width: 25em;
`;
const Responses = styled.strong`
  color: ${Color.lightBlack};
`;
const standardMargins = { top: 10, right: 50, bottom: 50, left: 200 };
const wideLabelMargins = { top: 10, right: 50, bottom: 50, left: 500 };
const labelWidth = 35;
const wideLabelWidth = 100;

interface IOwnProps {
  data: any[];
  title?: string;
  height?: string;
  chartKeys: string[];
}
interface IOwnState {
  margins: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  labelWidth: number;
}
interface IBarProps extends BarProps {
  data: any;
  animate: any;
  axisLeft: any;
  borderColor: any;
}

export class Bar extends React.Component<IOwnProps, IOwnState> {
  constructor(props) {
    super(props);

    this.state = {
      margins: standardMargins,
      labelWidth
    };
    this.renderLabel = this.renderLabel.bind(this);
    this.toggleLongLabels = this.toggleLongLabels.bind(this);
  }
  public render() {
    const { data, chartKeys } = this.props;
    const { margins } = this.state;
    const mostTicks = 5;
    const modeCount = Math.max(...data.map(bar => bar[Object.keys(bar)[1]]));
    const chartProps: IBarProps = {
      data,
      layout: 'horizontal',
      keys: chartKeys,
      indexBy: 'answer',
      margin: margins,
      padding: 0.3,
      enableLabel: false,
      borderRadius: 3,
      borderWidth: 0.5,
      borderColor: { from: 'color', modifiers: [['darker', 0.5]] },
      colors: Color.categorical,
      animate: true,
      axisLeft: {
        renderTick: this.renderLabel
      },
      axisBottom: {
        tickValues: modeCount < mostTicks ? modeCount : mostTicks
      },
      tooltip: this.renderTooltip
    };
    return <ResponsiveBar {...chartProps} />;
  }

  private renderTooltip({ value }) {
    const s = value !== 1 ? 's' : '';

    return (
      <TooltipElement>
        <Responses>
          {value} Response{s}
        </Responses>
      </TooltipElement>
    );
  }

  private renderLabel(tick) {
    const maxWidth = this.state.labelWidth;
    const maxLines = 3;
    const fontSize = 11;
    const tickLabel = this.wrapLabel(tick.value, maxWidth) || '';
    const isLongLabel = tickLabel.length > maxLines || this.state.labelWidth === wideLabelWidth;
    const yOffset = Math.min(tickLabel.length, maxLines) * Math.ceil(fontSize / 2);
    const interactivity = isLongLabel ? { onClick: this.toggleLongLabels } : null;
    const labelElements = tickLabel
      .map((item, i) => {
        const isLastLine = i === maxLines - 1;
        const ellip = isLongLabel && isLastLine ? '...' : null;

        return (
          <tspan key={'tick_' + i} x="0" y={16 * i - yOffset}>
            {item}
            {ellip}
          </tspan>
        );
      })
      .slice(0, maxLines);

    return (
      <g transform={`translate(${tick.x - 10},${tick.y})`}>
        <text
          dominantBaseline="hanging"
          textAnchor="end"
          style={{
            fill: Color.textGray,
            fontWeight: 700,
            fontSize,
            cursor: isLongLabel ? 'pointer' : 'default'
          }}
          {...interactivity}
        >
          {labelElements}
        </text>
      </g>
    );
  }

  private toggleLongLabels(e) {
    if (this.state.labelWidth === labelWidth) {
      this.setState(() => ({ labelWidth: wideLabelWidth, margins: wideLabelMargins }));
    } else {
      this.setState(() => ({ labelWidth, margins: standardMargins }));
    }
  }

  private wrapLabel(label: string, max: number): string[] {
    const words = label.split(' ');
    const lines: string[] = [];
    let line = '';

    for (let i = 0; i < words.length; i++) {
      line = [line, words[i]].join(' ').trim();
      if (i + 1 === words.length || line.length + words[i + 1].length + i > max) {
        lines.push(line);
        line = '';
      }
    }

    return lines;
  }
}
