import { useCurrentUser } from 'hooks/useCurrentUser';
import { UserRole } from 'types';
import { getRole, isSiteAdmin } from 'utils/userManager';

type Signature = {
  role: UserRole;
  isSiteAdmin: boolean;
};

export const useUserRole = (): Signature => {
  const user = useCurrentUser();

  return { role: getRole(user), isSiteAdmin: isSiteAdmin(user) };
};
