export enum CONTENT_DIFFICULTIES {
  VERY_HARD = 'Very Hard',
  HARD = 'Hard',
  EASY = 'Easy',
  VERY_EASY = 'Very Easy',
  EXTREME = 'Extreme',
  BALANCED = 'Balanced',
}

export type DifficultyCounts = {
  Easy: number;
  Hard: number;
  Moderate: number;
  'Very Easy': number;
  'Very Hard': number;
};

export default function assessContentDifficulty(difficulties: DifficultyCounts): string {
  const totalQuestions = Object.values(difficulties).reduce((acc, curr) => acc + curr, 0);

  const easyPercent = ((difficulties.Easy + difficulties['Very Easy']) / totalQuestions) * 100;
  const veryEasyPercent = (difficulties['Very Easy'] / totalQuestions) * 100;
  const hardPercent = ((difficulties.Hard + difficulties['Very Hard']) / totalQuestions) * 100;
  const veryHardPercent = (difficulties['Very Hard'] / totalQuestions) * 100;

  if (veryHardPercent > 30) {
    return CONTENT_DIFFICULTIES.VERY_HARD;
  } else if (veryEasyPercent > 30) {
    return CONTENT_DIFFICULTIES.VERY_EASY;
  } else if (veryEasyPercent + veryHardPercent > 50) {
    return CONTENT_DIFFICULTIES.EXTREME;
  } else if (hardPercent > 50) {
    return CONTENT_DIFFICULTIES.HARD;
  } else if (easyPercent > 50) {
    return CONTENT_DIFFICULTIES.EASY;
  }
  return CONTENT_DIFFICULTIES.BALANCED;
}
