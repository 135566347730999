import { useState } from 'react';
import { performActionWithConfirmation } from '../siteAdminUtils';
import axios from 'axios';

export function SiteAdminUserDetailSummary(props: any) {
  const { user } = props;
  const userId = user.id;
  const [userIdToMerge, setUserIdToMerge] = useState<string>('');

  const resetFailedLogins = async () => {
    await performActionWithConfirmation('Are you sure you wish to reset the failed logins for this user?', async () => {
      await axios.patch(`/api/v3/admin/users/${userId}`, { failed_logins: 0 });
      window.alert('Failed logins reset, please refresh');
    });
  };

  const impersonateUser = async () => {
    await performActionWithConfirmation(
      'Are you sure you wish to impersonate this user? Any actions you take will be indistinguishable from theirs.',
      async () => {
        await axios.post(`/api/v2/admin/users/${userId}/impersonate`);
        window.location.href = '/app/nav/dashboard';
      }
    );
  };

  const deleteUser = async () => {
    await performActionWithConfirmation(
      'Are you sure you wish to permanently delete this user? This action CANNOT BE UNDONE',
      async () => {
        await axios.delete(`/api/v2/admin/users/${userId}`);
        window.location.href = '/app/nav/v4/site-admin/users';
      }
    );
  };

  const resetUserMemories = async () => {
    await performActionWithConfirmation(
      "Are you sure you wish to permanently delete this user's memories? This action CANNOT BE UNDONE",
      async () => {
        await axios.post(`/api/v2/admin/users/${userId}/reset_memories`);
        window.alert('User memories deleted, please refresh');
      }
    );
  };

  const mergeUser = async () => {
    await performActionWithConfirmation(
      `Are you sure you wish to permanently merge user with ID ${userIdToMerge} into user with ID ${user.id}? This action CANNOT BE UNDONE. This may take a few minutes...`,
      async () => {
        await axios.post('/api/v3/admin/user_merges', {
          good_user_id: user.id,
          bad_user_id: userIdToMerge,
        });
        window.alert('User has been merged, please refresh');
      }
    );
  };

  return (
    <article>
      <h2>
        {user.id} - {user.name}
      </h2>
      <table style={{ textAlign: 'left' }}>
        <tbody>
          <tr>
            <th>Primary Partner:</th>
            <td>
              {user.primaryPartner.id} - {user.primaryPartner.name}
            </td>
          </tr>
          <tr>
            <th>User Created:</th>
            <td>{user.createdAt}</td>
          </tr>
          <tr>
            <th>User last logged in on:</th>
            <td>{user.lastLoggedInAt}</td>
          </tr>
          <tr>
            <th># of failed logins:</th>
            <td>{user.failedLogins}</td>
            <td>
              <input
                style={{ paddingLeft: '30px' }}
                onClick={resetFailedLogins}
                value="Reset Failed Logins"
                type="button"
              />
            </td>
          </tr>
          <tr>
            <th>Impersonate</th>
            <td>This will log you in as the user. Do this in an incognito window only.</td>
            <td>
              <input style={{ paddingLeft: '30px' }} onClick={impersonateUser} value="Impersonate" type="button" />
            </td>
          </tr>
          <tr>
            <th>Erase Memories</th>
            <td>This will permanently erase the user's memories</td>
            <td>
              <input style={{ paddingLeft: '30px' }} onClick={resetUserMemories} value="Erase Memories" type="button" />
            </td>
          </tr>
          <tr>
            <th>Delete account</th>
            <td>This will permanently delete the user's account</td>
            <td>
              <input style={{ paddingLeft: '30px' }} onClick={deleteUser} value="Delete" type="button" />
            </td>
          </tr>
          <tr>
            <th>Merge account</th>
            <td>This will permanently merge the account of the entered ID into this user</td>
            <td>
              <input
                type="text"
                placeholder="Bad UserID"
                value={userIdToMerge}
                onChange={(e) => setUserIdToMerge(e.target.value)}
                style={{ width: '200px', padding: '5px' }}
                aria-invalid="false"
              />
              <input
                style={{ paddingLeft: '30px', marginLeft: '10px' }}
                onClick={mergeUser}
                value="Merge"
                type="button"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  );
}
