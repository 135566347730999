import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { TAnnotation } from 'types';
import { createImage } from 'utils/createImage';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

interface CreateICImageAnnotationParams {
  itemId: string;
  image: File;
}

const createICImageAnnotation = async ({ itemId, image }: CreateICImageAnnotationParams): Promise<TAnnotation> => {
  const imageId = (await createImage(image)).id;

  // create annotation
  const createAnnotationResponse = await axios.post(`/api/v3/items/${itemId}/annotations`, {
    item_id: itemId,
    include: 'image,document,sound,video',
    data: {
      relationships: {
        image: {
          data: {
            id: imageId,
          },
        },
      },
    },
  });
  const newImageAnnotation = normalizeJSONAPIResponse(createAnnotationResponse.data);
  return newImageAnnotation;
};

export const useCreateICImageAnnotation = (itemId: string = '', setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(createICImageAnnotation, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
