import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Color } from 'core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSmartCreateStore } from 'views/course-create/smart-create/store';

export const CurriculumForm: React.FC = () => {
  const { assignments, addAssignment, removeAssignment } = useSmartCreateStore();
  const [title, setTitle] = useState('');
  const { t } = useTranslation();

  const handleAddAssignment = () => {
    addAssignment({ title });
    setTitle('');
  };

  return (
    <div>
      <Typography variant="subtitle1">Curriculum Design</Typography>
      <Typography variant="caption" display="block">
        {t(
          'Defining assignments with specific learning goals will help Smart Create build a course that is tailored to your curriculum.'
        )}
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        {t('If you do not define any assignments, Smart Create will build a strong general-purpose course.')}
      </Typography>
      {assignments.map((assignment, index) => (
        <div
          key={index}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              {assignment.title}
            </Typography>
          </div>
          <Button onClick={() => removeAssignment(index)}>
            <RemoveCircleOutlineIcon style={{ color: Color.activeRed }} />
          </Button>
        </div>
      ))}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField label="Assignment Title" value={title} onChange={(e) => setTitle(e.target.value)} />
        <Button variant="contained" color="primary" onClick={handleAddAssignment} style={{ marginTop: '15px' }}>
          Add Assignment
        </Button>
      </div>
    </div>
  );
};
