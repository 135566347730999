import { faCog, faUser, faUsers, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { MemreFlexBox, MemreText } from 'components/core';
import { Color } from 'core';
import { useGroups } from 'hooks/useGroups';
import { usePartnerSummaryCounts } from 'hooks/usePartnerSummaryCounts';
import styled from 'styled-components';
import { getBaseReactUrl } from 'utils/pathUtils';

export const Nav = () => {
  const { data: partnerSummaryCounts } = usePartnerSummaryCounts();
  const { data: groups } = useGroups();

  const navItems = [
    {
      rawLabel: `${partnerSummaryCounts?.usersCount || 0} ${
        partnerSummaryCounts?.usersCount !== 1 ? 'People' : 'Person'
      }`,
      icon: faUser,
      href: `${getBaseReactUrl()}/app/nav/v4/admin/people`,
    },
    {
      rawLabel: `${groups?.length || 0} Group${groups?.length !== 1 ? 's' : ''}`,
      icon: faUsers,
      href: `${getBaseReactUrl()}/app/nav/v4/admin/groups`,
    },
    {
      rawLabel: `${partnerSummaryCounts?.staffUsersCount || 0} Staff`,
      icon: faUserTie,
      href: `${getBaseReactUrl()}/app/nav/v4/admin/staff`,
    },
    {
      rawLabel: 'Settings',
      icon: faCog,
      href: `${getBaseReactUrl()}/app/nav/v4/admin/config`,
    },
  ];

  // Separate count and label if the label starts with a number:
  // Example: "286 People" -> count = "286", label = "People"
  // If no leading number, just treat entire thing as a label.
  const parseLabel = (rawLabel: string) => {
    const parts = rawLabel.trim().split(' ');
    if (parts.length > 1 && !isNaN(Number(parts[0]))) {
      const count = parts[0];
      const label = parts.slice(1).join(' ');
      return { count, label };
    } else {
      return { count: null, label: rawLabel };
    }
  };

  const handleClick = (href: string) => {
    window.location.href = href;
  };

  return (
    <NavContainer>
      {navItems.map((item, index) => {
        const { count, label } = parseLabel(item.rawLabel);
        return (
          <NavCard key={index} onClick={() => handleClick(item.href)}>
            <MemreFlexBox gap={1} direction="row" align="center" justify="space-between" sx={{ width: '100%' }}>
              <MemreFlexBox gap={1} direction="row" align="center" justify="flex-start">
                <NavCardIcon icon={item.icon} />
                <MemreText variant="h4">{label}</MemreText>
              </MemreFlexBox>
              {count && <Chip label={count} size="small" color="secondary" variant="outlined" />}
            </MemreFlexBox>
          </NavCard>
        );
      })}
    </NavContainer>
  );
};

const NavContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const NavCard = styled.div`
  background-color: ${Color.white};
  border: 1px solid ${Color.lightGray};
  border-radius: 8px;
  padding: 30px 20px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, transform 0.1s ease-in-out, background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    background-color: ${Color.white};
  }
`;

const NavCardIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: ${Color.textGray};
`;
