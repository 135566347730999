import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BillingAlertBanner from 'admin/components/BillingAlertBanner';
import { BillingDetails } from 'admin/components/BillingDetails';
import { CreateNewOAuthPage } from 'admin/components/CreateNewOAuthPage';
import { EditOAuthPage } from 'admin/components/EditOAuthPage';
import { OAuthPage } from 'admin/components/OAuthPage';
import { UpdateSAMLSettingsPage } from 'admin/components/UpdateSAMLSettingsPage';
import { AngularEmbed } from 'components/AngularEmbed';
import { Modal } from 'components/Modal';
import Navbar, { navbarHeight } from 'components/Navbar';
import { PrivateRoute } from 'components/PrivateRoute';
import { ReportsPage } from 'components/ReportsPage';
import { SideNav } from 'components/SideNav';
import Toast from 'components/Toast';
import { UserProfile } from 'components/UserProfile';
import { UserSettingsPage } from 'components/UserSettingsPage';
import { AssignmentItems } from 'components/assignment-items/AssignmentItems';
import { ControlPanel } from 'components/control-panel/ControlPanel';
import { UpdateLicensing } from 'components/modals/UpdateLicensing';
import { CoursesPage } from 'courses/components/CoursesPage';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useOverage from 'hooks/useOverage';
import { MINIMUM_LICENSE_QUANTITY } from 'models/account';
import { AdminDashboard } from 'pages/admin-dashboard/AdminDashboard';
import { GroupCoursesPage } from 'pages/admin-dashboard/groups/GroupCoursesPage';
import { GroupDetailPage } from 'pages/admin-dashboard/groups/GroupDetailPage';
import { GroupMembersPage } from 'pages/admin-dashboard/groups/GroupMembersPage';
import { GroupsPage } from 'pages/admin-dashboard/groups/GroupsPage';
import { OAuthConfig } from 'pages/admin-dashboard/oauth-config/OAuthConfig';
import { PartnerConfig } from 'pages/admin-dashboard/partner-config/PartnerConfig';
import { PeoplePage } from 'pages/admin-dashboard/people/PeoplePage';
import { StaffPage } from 'pages/admin-dashboard/staff/StaffPage';
import { CourseDetailPage } from 'pages/course-detail/CourseDetailPage';
import { Dashboard } from 'pages/learner-dashboard/Dashboard';
import { CoursePage } from 'pages/learner-dashboard/courses/CoursePage';
import ManagerKnowledgeBank from 'pages/manager-knowledge-bank/ManagerKnowledgeBank';
import { SiteAdminCloningLab } from 'pages/site-admin/SiteAdminCloningLab';
import { SiteAdminLtiLauncher } from 'pages/site-admin/SiteAdminLtiLauncher';
import { SiteAdminPage } from 'pages/site-admin/SiteAdminPage';
import { SiteAdminPartners } from 'pages/site-admin/SiteAdminPartners';
import { SiteAdminUserSearch } from 'pages/site-admin/SiteAdminUserSearch';
import { SiteAdminUserDetail } from 'pages/site-admin/user-detail/SiteAdminUserDetail';
import React, { useEffect, useState } from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';
import { RouteProps } from 'react-router';
import { Switch, useLocation } from 'react-router-dom';
import { AssignmentDetailsPage } from 'sets/components/AssignmentDetailsPage';
import styled from 'styled-components';
import { AnalyticsService } from 'utils/AnalyticsService';
import { getBaseReactUrl, queryParams } from 'utils/pathUtils';

const Main = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SideNavPageContainer = React.memo<RouteProps>(() => {
  const [showUpdateLicensing, setShowUpdateLicensing] = useState<boolean>(false);
  const updateUrl = (path: string) => {
    window.location.assign(`${getBaseReactUrl()}/app/nav/${path}`);
  };
  const isMobile = useMediaQuery('(max-width:767px)');
  const [showSideNav, setShowSideNav] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    setShowSideNav(!isMobile);
    document.body.style.overflow = isMobile ? 'hidden' : 'auto';
  }, [isMobile]);

  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const isSelfServe: boolean = partner.isSelfServe;
  const isSiteAdmin = user?.meta.isAdmin;
  const isPartnerAdmin: boolean = partner.meta.role === 'admin';
  const stripeAccount = partner.account || {};
  const stripePlan = stripeAccount.plan || {};
  const purchasedSeats = stripeAccount.licenseQuantity || 0;
  const occupiedSeats = partner.usersCount;
  const billingInterval = stripePlan.interval || 'monthly';
  const pricePerSeat = stripePlan.price || 0;
  const minUsers = MINIMUM_LICENSE_QUANTITY;
  const mainFocusRef: any = React.createRef();
  const [blocked, setBlocked] = useState(
    partner?.partnerSettings.partnerAdminBlockEnabled && isPartnerAdmin && !isSiteAdmin
  );

  useEffect(() => {
    setBlocked(partner?.partnerSettings.partnerAdminBlockEnabled && isPartnerAdmin && !isSiteAdmin);
  }, [partner]);

  const { data: overage } = useOverage();

  const handleBurgerClick = (): void => {
    setShowSideNav(!showSideNav);
  };

  const handleUpgradeClick = () => {
    AnalyticsService.getInstance().track('upgrade_now_clicked');
    setShowUpdateLicensing(true);
  };

  useEffect(() => {
    // listen for messages from the angular app inside the iframe
    window.addEventListener('message', handleMessageReceived, true);
    // clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener('message', handleMessageReceived);
    };
  });

  const handleMessageReceived = (event: any) => {
    if (event.data === 'skipToMain') {
      mainFocusRef.current?.focus();
    }
  };

  if (blocked) {
    return (
      <Modal
        show={true}
        element={
          <div>
            <h1>Your account is temporarily suspended.</h1>
            <h2>Please contact your company's Cerego representative.</h2>
          </div>
        }
      />
    );
  }

  if (showUpdateLicensing) {
    return (
      <Modal
        show={showUpdateLicensing}
        element={
          <UpdateLicensing
            partnerId={partner.id}
            purchasedSeats={purchasedSeats}
            occupiedSeats={occupiedSeats}
            interval={billingInterval}
            pricePerSeatInPennies={pricePerSeat}
            minUsers={minUsers}
            onCancel={() => setShowUpdateLicensing(false)}
          />
        }
      />
    );
  }

  return (
    <>
      {isMobile && <Navbar menuIcon={showSideNav ? faTimes : faBars} onMenuClick={handleBurgerClick} />}
      <div
        style={{
          display: 'flex',
          paddingTop: `${isMobile ? navbarHeight : '0'}`,
        }}
      >
        {showSideNav && (
          <SideNav isMobile={isMobile} updateUrl={updateUrl} billingStatus={queryParams(location).billingStatus} />
        )}
        <Main ref={mainFocusRef} tabIndex={-1}>
          <ReactQueryDevtools initialIsOpen={false} />
          {!isMobile && <Navbar menuIcon={showSideNav ? faTimes : faBars} onMenuClick={handleBurgerClick} />}
          {overage && isPartnerAdmin && (
            <BillingAlertBanner
              startDate={overage.createdAt}
              daysInGracePeriod={overage.meta.daysInGracePeriod}
              upgradeClick={handleUpgradeClick}
            />
          )}
          <Toast />
          <Switch>
            <PrivateRoute exact path="/nav/user/:userId" component={UserProfile} />
            <PrivateRoute exact path="/nav/courses" component={CoursesPage} />
            <PrivateRoute exact path="/nav/courses/:courseId" component={CourseDetailPage} />
            <PrivateRoute exact path="/nav/courses/:courseId/sets/:setId/items" component={AssignmentItems} />
            <PrivateRoute exact path="/nav/courses/:courseId/sets/:setId/" component={AssignmentDetailsPage} />
            <PrivateRoute exact path="/nav/v3/scorm_upload" component={AngularEmbed} />
            <PrivateRoute exact path="/nav/v4/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/nav/v4/admin" component={AdminDashboard} />
            <PrivateRoute exact path="/nav/v4/admin/config" component={PartnerConfig} />
            <PrivateRoute exact path="/nav/v4/admin/staff" component={StaffPage} />
            <PrivateRoute exact path="/nav/v4/admin/people" component={PeoplePage} />
            <PrivateRoute exact path="/nav/v4/admin/groups" component={GroupsPage} />
            <PrivateRoute exact path="/nav/v4/admin/groups/:groupId" component={GroupDetailPage} />
            <PrivateRoute exact path="/nav/v4/admin/groups/:groupId/members" component={GroupMembersPage} />
            <PrivateRoute exact path="/nav/v4/admin/groups/:groupId/courses" component={GroupCoursesPage} />
            <PrivateRoute exact path="/nav/v4/admin/oauth" component={OAuthConfig} />
            <PrivateRoute exact path="/nav/v4/dashboard/courses/:courseId" component={CoursePage} />
            <PrivateRoute path="/nav/dashboard" component={AngularEmbed} currentPath="dashboard" />
            <PrivateRoute exact path="/nav/reports" component={ReportsPage} />
            <PrivateRoute exact path="/nav/knowledge-bank/:courseId" component={ManagerKnowledgeBank} />
            <PrivateRoute
              exact
              path="/nav/partners/:partnerSlug/oauth"
              component={OAuthPage}
              permittedRoles={['admin', 'site admin']}
            />
            <PrivateRoute
              exact
              path="/nav/partners/:partnerSlug/oauth/update_saml_settings"
              component={UpdateSAMLSettingsPage}
              permittedRoles={['admin', 'site admin']}
            />
            <PrivateRoute
              exact
              path="/nav/partners/:partnerSlug/oauth/new"
              component={CreateNewOAuthPage}
              permittedRoles={['admin', 'site admin']}
            />
            <PrivateRoute
              exact
              path="/nav/partners/:partnerSlug/oauth/:authId/edit"
              component={EditOAuthPage}
              permittedRoles={['admin', 'site admin']}
            />
            <PrivateRoute path="/nav/control-panel" component={ControlPanel} siteAdminOnly={true} />
            <PrivateRoute
              path="/nav/partners/:partnerSlug"
              component={AngularEmbed}
              permittedRoles={['admin', 'site admin']}
            />
            {isSelfServe && (
              <PrivateRoute
                path="/nav/admin/billing"
                component={BillingDetails}
                permittedRoles={['admin', 'site admin']}
              />
            )}
            <PrivateRoute path="/nav/admin" component={AngularEmbed} siteAdminOnly={true} />

            <PrivateRoute exact path="/nav/v4/site-admin" component={SiteAdminPage} siteAdminOnly={true} />
            <PrivateRoute exact path="/nav/v4/site-admin/users" component={SiteAdminUserSearch} siteAdminOnly={true} />
            <PrivateRoute
              exact
              path="/nav/v4/site-admin/users/:userId"
              component={SiteAdminUserDetail}
              siteAdminOnly={true}
            />
            <PrivateRoute exact path="/nav/v4/site-admin/partners" component={SiteAdminPartners} siteAdminOnly={true} />
            <PrivateRoute
              exact
              path="/nav/v4/site-admin/cloning-lab"
              component={SiteAdminCloningLab}
              siteAdminOnly={true}
            />
            <PrivateRoute
              exact
              path="/nav/v4/site-admin/lti-launcher"
              component={SiteAdminLtiLauncher}
              siteAdminOnly={true}
            />

            <PrivateRoute path="/nav/settings" component={UserSettingsPage} />
            {/* fall back to AngularEmbed so all our angular URLs work */}
            {/*NOTE: Be sure to specify any PrivateRoutes rendering AngularEmbed at paths that have permissions */}
            {/*restrictions (e.g. the /nav/partners routes) prior to this one, else they will be rendered here unrestricted!*/}
            <PrivateRoute component={AngularEmbed} />
          </Switch>
        </Main>
      </div>
    </>
  );
});
