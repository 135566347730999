import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { SelectableCoursesTable } from 'pages/admin-dashboard/groups/SelectableCoursesTable';

type Props = {
  groupId: string;
  close: () => void;
};

export const GroupCoursesEnrollmentModal = ({ groupId, close }: Props) => {
  return (
    <div style={{ width: '100%', height: '100%', padding: '20px' }}>
      <MemreFlexBox justify="space-between" align="center" direction="row" sx={{ marginBottom: '2rem' }}>
        <MemreText variant="h3">Enroll Courses</MemreText>
        <MemreButton text="Cancel" variant="outlined" color="secondary" iconName="close" onClick={close} />
      </MemreFlexBox>
      <SelectableCoursesTable groupId={groupId} close={close} />
    </div>
  );
};
