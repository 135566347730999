import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField, ThemeProvider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { MemreButton, MemreFlexBox, MemrePageContainer, MemreSelectMenu, MemreText } from 'components/core';
import { Color } from 'core';
import { useCreateGroup } from 'hooks/useCreateGroup';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePartnerUserTagTypes } from 'hooks/usePartnerUserTagTypes';
import { GroupsTable } from 'pages/admin-dashboard/groups/GroupsTable';
import { PartnerUserTagTypesSelectMenu } from 'pages/admin-dashboard/groups/PartnerUserTagTypesSelectMenu';
import { useGroupsStore } from 'pages/admin-dashboard/groups/store';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

interface Rule {
  id: string;
  tagTypeId: string;
  tagTypeLabel: string;
  operator: '=' | '!=';
  value: string;
}

interface MatchedUser {
  id: string;
  attributes: {
    name: string;
    email: string;
  };
}

export const GroupsPage: React.FC = () => {
  const user = useCurrentUser();
  const partnerId = user?.primaryPartner?.id;
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [groupType, setGroupType] = useState<'group' | 'smartGroup'>('group');
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [rules, setRules] = useState<Rule[]>([]);
  const [matchedUsers, setMatchedUsers] = useState<MatchedUser[]>([]);
  const [isFetchingPreview, setIsFetchingPreview] = useState(false);

  const [createGroup] = useCreateGroup();
  const { pageNumber } = useGroupsStore();
  const { data: partnerUserTagTypes } = usePartnerUserTagTypes();

  // Scroll to top when page number changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber]);

  // Handle form submission
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let payload: any = {
      name: groupName,
      description: groupDescription,
    };

    if (groupType === 'smartGroup') {
      const rulesJson = buildRulesJson(rules);
      payload.rules_json = rulesJson;
    }

    await createGroup({
      partnerId,
      payload,
    });
    // Reset form
    resetForm();
  };

  const resetForm = () => {
    setIsCreatingGroup(false);
    setGroupName('');
    setGroupDescription('');
    setRules([]);
    setMatchedUsers([]);
  };

  // Handlers for managing rules
  const handleAddRule = () => {
    const newRule: Rule = {
      id: uuid(),
      tagTypeId: '',
      tagTypeLabel: '',
      operator: '=',
      value: '',
    };
    setRules((prevRules) => [...prevRules, newRule]);
  };

  const handleRuleChange = (id: string, updatedFields: Partial<Rule>) => {
    setRules((prevRules) => prevRules.map((rule) => (rule.id === id ? { ...rule, ...updatedFields } : rule)));
  };

  const handleRemoveRule = (id: string) => {
    setRules((prevRules) => prevRules.filter((rule) => rule.id !== id));
  };

  // Build rules_json for API payload
  const buildRulesJson = (rules: Rule[]) => {
    const conditions = rules.map((rule) => ({
      [rule.operator]: {
        tag_type_id: rule.tagTypeId,
        tag_type_label: rule.tagTypeLabel,
        value: rule.value,
      },
    }));

    const rulesObject = {
      AND: conditions,
    };

    return JSON.stringify(rulesObject);
  };

  // Fetch matched users for smart group preview
  const fetchSmartGroupPreview = async (rulesJson: string) => {
    if (!partnerId) return;

    setIsFetchingPreview(true);
    try {
      const response = await axios.post(`/api/v3/partners/${partnerId}/smart_group_preview`, {
        rules_json: rulesJson,
        include: 'user_partner_id',
      });
      const users = response.data.data.map((user: any) => ({
        id: user.id,
        attributes: {
          name: user.attributes.name,
          email: user.attributes.email,
        },
      }));
      setMatchedUsers(users);
    } catch (error) {
      console.error('Error fetching smart group preview', error);
      setMatchedUsers([]);
    } finally {
      setIsFetchingPreview(false);
    }
  };

  // Debounced effect to fetch preview when rules change
  useEffect(() => {
    if (groupType === 'smartGroup' && rules.length > 0) {
      const debounceTimeout = setTimeout(() => {
        const rulesJson = buildRulesJson(rules);
        fetchSmartGroupPreview(rulesJson);
      }, 500);

      return () => clearTimeout(debounceTimeout);
    } else {
      setMatchedUsers([]);
    }
  }, [rules, groupType]);

  return (
    <ThemeProvider theme={theme}>
      <MemrePageContainer>
        <MemreFlexBox gap={4} direction="column" sx={{ padding: '2rem' }}>
          <MemreFlexBox gap={4} direction="row" justify="space-between" align="center">
            <MemreText variant="h3">Groups</MemreText>
            {!isCreatingGroup && (
              <MemreButton
                text="New Group"
                size="medium"
                color="secondary"
                variant="contained"
                iconName="plus"
                onClick={() => setIsCreatingGroup(true)}
              />
            )}
          </MemreFlexBox>

          {isCreatingGroup && (
            <MemreFlexBox gap={4} direction="column" sx={{ padding: '2rem' }}>
              <form onSubmit={handleFormSubmit}>
                <FormGroup>
                  <MemreSelectMenu
                    label="Group Type"
                    items={[
                      { label: 'Classic Group', value: 'group' },
                      { label: 'Smart Group', value: 'smartGroup' },
                    ]}
                    value={groupType}
                    onChange={(_e: any, n: any) => {
                      setGroupType(n.props ? n.props.value : 'group');
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <TextField
                    id="group-name"
                    color="secondary"
                    label="Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    fullWidth
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    id="group-description"
                    color="secondary"
                    label="Description"
                    value={groupDescription}
                    onChange={(e) => setGroupDescription(e.target.value)}
                    fullWidth
                  />
                </FormGroup>

                {groupType === 'smartGroup' && (
                  <>
                    {/* Rules */}
                    <div>
                      <SectionTitle>Define Rules:</SectionTitle>
                      {rules.map((rule) => (
                        <RuleEditor
                          key={rule.id}
                          rule={rule}
                          partnerUserTagTypes={partnerUserTagTypes || []}
                          onRuleChange={handleRuleChange}
                          onRemoveRule={handleRemoveRule}
                        />
                      ))}
                      <MemreButton
                        text="Add Rule"
                        size="medium"
                        color="secondary"
                        variant="contained"
                        iconName="plus"
                        onClick={handleAddRule}
                      />
                    </div>

                    {/* Matched Users */}
                    <MatchedUsersSection>
                      <SectionTitle>
                        {isFetchingPreview ? 'Fetching matching users...' : `Matching Users (${matchedUsers.length})`}
                      </SectionTitle>
                      {matchedUsers.length > 0 ? (
                        <UserList>
                          {matchedUsers.map((user) => (
                            <UserItem key={user.id}>
                              <strong>{user.attributes.name}</strong> ({user.attributes.email})
                            </UserItem>
                          ))}
                        </UserList>
                      ) : (
                        <p>No users match the current rules.</p>
                      )}
                    </MatchedUsersSection>
                  </>
                )}

                <ButtonGroup>
                  <MemreButton
                    text="Create Group"
                    size="medium"
                    color="secondary"
                    variant="contained"
                    onClick={handleFormSubmit}
                    sx={{ marginRight: '1rem' }}
                  />
                  <MemreButton text="Cancel" size="medium" color="secondary" variant="outlined" onClick={resetForm} />
                </ButtonGroup>
              </form>
            </MemreFlexBox>
          )}

          <GroupsTable />
        </MemreFlexBox>
      </MemrePageContainer>
    </ThemeProvider>
  );
};

interface RuleEditorProps {
  rule: Rule;
  partnerUserTagTypes: PartnerUserTagType[];
  onRuleChange: (id: string, updatedFields: Partial<Rule>) => void;
  onRemoveRule: (id: string) => void;
}

interface PartnerUserTagType {
  id: string;
  label: string;
}

const RuleEditor: React.FC<RuleEditorProps> = ({ rule, partnerUserTagTypes, onRuleChange, onRemoveRule }) => {
  return (
    <MemreFlexBox gap={2} direction="row" align="center" justify="space-between" sx={{ marginBottom: '1rem' }}>
      <PartnerUserTagTypesSelectMenu
        value={rule.tagTypeId || ''}
        onSelect={(tagTypeId) => {
          const tagType = partnerUserTagTypes.find((tt) => tt.id === tagTypeId);
          if (tagType) {
            onRuleChange(rule.id, { tagTypeId: tagType.id, tagTypeLabel: tagType.label });
          } else {
            onRuleChange(rule.id, { tagTypeId: '', tagTypeLabel: '' });
          }
        }}
      />
      <div style={{ width: '20%' }}>
        <MemreSelectMenu
          label="Operator"
          value={rule.operator}
          onChange={(operator: any) => {
            onRuleChange(rule.id, { operator: operator.target.value });
          }}
          items={[
            { label: 'is', value: '=' },
            { label: 'is not', value: '!=' },
          ]}
        />
      </div>
      <TextField
        id="rule-value"
        color="secondary"
        label="Value"
        value={rule.value}
        onChange={(e) => onRuleChange(rule.id, { value: e.target.value })}
        fullWidth
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
        <Tooltip title="Remove">
          <IconButton onClick={() => onRemoveRule(rule.id)} size="medium" style={{ padding: '1rem', color: Color.activeRed }}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
      </div>
    </MemreFlexBox>
  );
};

// Styled Components
const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  margin-top: 20px;
`;

const MatchedUsersSection = styled.div`
  margin-top: 20px;
`;

const UserList = styled.ul`
  list-style: none;
  padding: 0;
`;

const UserItem = styled.li`
  margin-bottom: 5px;
`;
