// Remove me when we remove ie11/edge support
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from 'components/App';
import 'components/index.css';
import { LtiOpenNewTabPage } from 'components/LtiOpenNewTabPage';
import { PrivateRoute } from 'components/PrivateRoute';
import { SideNavPageContainer } from 'components/SideNavPageContainer';
import ToggleNewLook from 'components/toggleNewLook/ToggleNewLook';
import 'core-js/features/array/flat';
import 'core-js/features/array/from';
import 'core-js/features/object/assign';
import 'core-js/features/promise';
import 'core-js/features/string/includes';
import 'core-js/features/symbol';
import { MuiTheme } from 'core/themes';
import Learn from 'learn/components/Learn';
import UserSessionJourneyPage from 'learn/components/UserSessionJourneyPage';
import middleware from 'middleware';
import { AthenaPrototypePage } from 'pages/athena/AthenaPrototypePage';
import * as ReactDOM from 'react-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createStore } from 'redux';
import reducer from 'store/index';
import * as HistoryListenerUtils from 'utils/historyListenerUtils';
import { LtiUserConfirmPage } from './components/LtiUserConfirmPage';
import { LtiLinkResourcePage } from './courses/components/LtiLinkResourcePage';
import './i18n';
import { AssignmentDetailsPage } from './sets/components/AssignmentDetailsPage';
import { UnauthenticatedPageContainer } from 'pages/registration/UnauthenticatedPageContainer';

HistoryListenerUtils.registerHistoryListeners();

declare global {
  // tslint:disable-next-line
  interface Window {
    // allow us to access Stripe that we added to window via script in index.html
    // Stripe: any;
    // allow us to access Segment that we added in segment.ts
    analytics: any;
  }
}

const store = createStore(reducer, middleware);

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

const ESCAPE = 27;
const handleEscapePress = (event: any) => {
  if (event.keyCode === ESCAPE && window.location.pathname.includes('/lti/')) {
    event.stopImmediatePropagation();
  }
};
document.addEventListener('keydown', handleEscapePress);

ReactDOM.render(
  <MuiThemeProvider theme={MuiTheme}>
    <Provider store={store}>
      <ReactQueryConfigProvider config={queryConfig} />
      <Router basename={'/app'}>
        <Route path="/" component={App} />
        <Route path="/signin" component={UnauthenticatedPageContainer} />
        <Route path="/join" component={UnauthenticatedPageContainer} />
        <Route path="/lti_user_confirm" component={LtiUserConfirmPage} />
        <PrivateRoute path="/lti/courses/embed" component={LtiOpenNewTabPage} />
        <PrivateRoute path="/sets/:id/journey" component={UserSessionJourneyPage} />
        <PrivateRoute path="/learn/sets/:id" component={Learn} />
        <PrivateRoute exact={true} path="/courses/:courseId/sets/:setId" component={AssignmentDetailsPage} />
        <PrivateRoute exact={true} path="/courses/:courseId/lti/link_resource" component={LtiLinkResourcePage} />
        <PrivateRoute path="/nav" component={SideNavPageContainer} />
        <PrivateRoute path="/athena" component={AthenaPrototypePage} siteAdminOnly={true} />
        <ToggleNewLook />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root') as HTMLElement
);

// TODO: reconsider registerServiceWorker().
// It helps cache assets in production (can help with offline/low internet mode)
// but has some potential issues that are worth understanding fully before using.
// https://github.com/facebook/create-react-app/issues/2554
// registerServiceWorker();
