import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useItemEditorStore } from 'components/item-editor/store';
import { SentenceField } from './SentenceField';
import { useCreateSentence } from 'hooks/useCreateSentence';

export const SentencesFormFields = () => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const [createSentence] = useCreateSentence(item?.id, assignment?.id);

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  const handleAddSentence = async (): Promise<void> => {
    // create in the database
    const newSentence = await createSentence(item.id);
    if (!newSentence) {
      return;
    }
    // update app state
    updateActiveItem({ sentences: [...item.sentences, newSentence] });
  };

  const sentences = item.sentences;
  const term = item.facets[0].anchor.text;

  return (
    <Paper style={{ padding: '16px', marginBottom: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Sentences
      </Typography>

      {sentences.map((sentence) => (
        <SentenceField sentence={sentence} key={`sentence-${sentence.id}`} term={term} />
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddSentence}
        style={{ marginBottom: '20px', padding: '10px' }}
      >
        Add a sentence
      </Button>
    </Paper>
  );
};
