import { TextField } from '@mui/material';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import React, { useState } from 'react';

export const MemberIdSlug: React.FC = () => {
  const user = useCurrentUser();
  const [memberIdSlug, setMemberIdSlug] = useState(user?.primaryPartner?.partnerSettings?.memberIdSlug);
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);

  const handleMemberIdSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMemberIdSlug(event.target.value);
    debouncedPartnerUpdate({
      partner_settings: {
        member_id_slug: event.target.value,
      },
    });
  };

  return (
    <ConfigItem title="Member IDs">
      <TextField
        label="Member ID Name"
        fullWidth
        margin="normal"
        value={memberIdSlug}
        onChange={handleMemberIdSlugChange}
      />
    </ConfigItem>
  );
};
