import { MemreContainer, MemreFlexBox, MemrePeopleList } from 'components/core';
import { MemreDenisityPlot } from 'components/visualisations';
import getMedian from 'utils/getMedian';
import AreaHeading from '../learner-dashboard/visulisations/AreaHeading';
import LearnMore from '../learner-dashboard/visulisations/LearnMore';

const OVERLINE = {
  STUDENTS: 'Average Study Time',
  SETS: 'Average Study Time',
};

export default function StudyDuration({ data, isStudentsView }) {
  const studyDurationInMiliseconds = data.map((d) => d.totalStudyTimeMillis);
  const denomination = data[0]?.totalStudyDenom;
  const median = getMedian(studyDurationInMiliseconds);

  const overline = isStudentsView ? OVERLINE.STUDENTS : OVERLINE.SETS;
  const heading = `${median} ${denomination}`;

  return (
    <MemreContainer
      size="large"
      direction={{
        xs: 'column',
        lg: 'row',
      }}
    >
      <MemreFlexBox direction="column" gap={2} sx={{ flexGrow: 1 }}>
        <MemreFlexBox justify="space-between" align="center">
          <AreaHeading overline={overline} heading={heading} />
          <LearnMore width={500}>
            <AreaHeading overline={overline} heading={heading} />
            <MemrePeopleList
              data={data}
              denomination={denomination}
              fields={['totalStudyTimeMillis']}
              fieldNames={['Duration']}
            />
          </LearnMore>
        </MemreFlexBox>
        <MemreContainer sx={{ gap: 2, pr: '5px', justifyContent: 'space-between' }}>
          <MemreDenisityPlot data={studyDurationInMiliseconds} />
        </MemreContainer>
      </MemreFlexBox>
    </MemreContainer>
  );
}
