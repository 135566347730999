import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CardHeader } from 'core/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
}

export const BuildingSmartCourse = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: '1rem' }}>
      <CardContent>
        <CardHeader>
          <span style={{ fontWeight: 'normal' }}>{t('Building')} </span>
          <span style={{ fontWeight: 'bold' }}>{props.title}</span>
        </CardHeader>
      </CardContent>
      <Typography
        variant="body1"
        style={{
          margin: '1rem',
        }}
      >
        {t('Feel free to leave this page, your new smart course will be available shortly.')}
      </Typography>
      <Typography
        variant="body1"
        style={{
          margin: '1rem',
        }}
      >
        {t('If you uploaded a PDF, your course may take quite a bit longer to process. Be patient!')}
      </Typography>
    </div>
  );
};
