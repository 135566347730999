import { PrimaryButton } from 'components/buttons/v4';
import { Color } from 'core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IProps {
  title: string;
  subtitle: string;
  dollarAmount: string;
  centAmount: string;
  buttonText: string;
  showButton: boolean;
  click: any;
}

const Title = styled.h2`
  text-align: center;
  font-size: 1em;
`;

const Subtitle = styled.h4`
  text-align: center;
  color: ${Color.textGray};
  margin: 0;
  font-size: 0.8em;
`;

const PriceContainer = styled.span`
  margin-top: 1em;
  display: flex;
  margin-bottom: 1.5em;
`;

const DollarAmount = styled.h1`
  font-size: 2em;
  font-weight: bold;
`;

const MonthText = styled.h2`
  font-weight: 300;
  margin-top: 1em;
  margin-bottom: 0;
`;

const CentAmount = styled.h2`
  font-weight: bold;
  margin-top: 1em;
`;

const PerUser = styled.p`
  font-weight: 300;
  color: ${Color.primaryBlack};
  font-size: 0.7em;
  text-align: right;
  margin-top: 0;
`;

const SmallerPricingTextContainer = styled.span`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BillingOption = React.memo<IProps>((props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{props.title}</Title>
      <Subtitle>{props.subtitle}</Subtitle>
      <PriceContainer>
        <span>
          <DollarAmount>${props.dollarAmount}</DollarAmount>
        </span>
        <SmallerPricingTextContainer>
          <CentAmount>.{props.centAmount}</CentAmount>
          <div>
            <MonthText>{t('/month')}</MonthText>
            <PerUser>{t('per user*')}</PerUser>
          </div>
        </SmallerPricingTextContainer>
      </PriceContainer>
      <PrimaryButton click={props.click}>{props.buttonText}</PrimaryButton>
    </Container>
  );
});
