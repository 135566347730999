import Skeleton from '@material-ui/lab/Skeleton';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MoreVertButton } from 'components/buttons/MoreVertButton';
import CourseLearnersTableMenu from 'components/CourseLearnersTableMenu';
import { LearnersTableToolbar } from 'components/LearnersTableToolbar';
import { Modal } from 'components/Modal';
import { ReadinessBadge } from 'components/ReadinessBadge';
import { Pagination } from 'components/reports/controls/Pagination';
import { ColumnHeader } from 'components/tables/learner-stats/ColumnHeader';
import { Container } from 'components/tables/learner-stats/Container';
import { DataContainer, DataTable, TableHeaderText } from 'components/tables/learner-stats/elements';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { Color } from 'core';
import { EmailLearnerModal } from 'courses/components/EmailLearnerModal';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useLearnersTableData } from 'hooks/useLearnersTableData';
import moment from 'moment';
import React, { useState } from 'react';
import { GoalProgressCell } from 'sets/components/GoalProgressCell';
import { TCourse } from 'types';
import { determineMenuOffset } from 'utils/menuUtils';
import { formatStringForSortComparison } from 'utils/tableUtils';
import { transformMilliseconds } from 'utils/timeUtils';
import { getRole } from 'utils/userManager';
import { LearnersTableState, useLearnersTableStore } from 'zstore';

interface CourseLearnersTableProps {
  course: TCourse;
}

export const CourseLearnersTable = (props: CourseLearnersTableProps) => {
  const { course } = props;

  return <Container toolbar={<LearnersTableToolbar />} data={<Data course={course} />} />;
};

interface DataProps {
  course: TCourse;
}

const Data = (props: DataProps) => {
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showEmailLearnerModal, setShowEmailLearnerModal] = useState(false);
  const { course } = props;
  const [offset, setOffset] = useState<number[]>([0.5, 0.5]);

  const user = useCurrentUser();

  // Permissions Related Values
  const role = getRole(user);
  const isContentManager = role === 'content manager';
  const canEditContent = course?.meta?.canEdit ?? false;
  const canEditLearners = canEditContent && !isContentManager;

  const pageSize = 10;
  const page = useLearnersTableStore((state: LearnersTableState) => state.page);
  const setPage = useLearnersTableStore((state: LearnersTableState) => state.setPage);
  const sort = useLearnersTableStore((state: LearnersTableState) => state.sort);
  const setSort = useLearnersTableStore((state: LearnersTableState) => state.setSort);
  const sortDirection = useLearnersTableStore((state: LearnersTableState) => state.sortDirection);
  const setSortDirection = useLearnersTableStore((state: LearnersTableState) => state.setSortDirection);

  const { isLoading, data: learnerStats, totalCount, totalPages } = useLearnersTableData(course.id, null);

  const renderActionsCell = (row: any) => {
    if (!canEditLearners) {
      return <></>;
    }

    return (
      <span style={{ display: 'flex', justifyContent: 'center' }}>
        <MoreVertButton
          click={(event) => handleActionsClick(event, row.original)}
          aria-controls="course-learners-actions-menu"
        />
      </span>
    );
  };

  const renderReadinessCell = (row: any) => {
    return (
      <ReadinessBadge readinessScore={row.original.readiness} style={{ fontSize: '16px', color: Color.reportGray }} />
    );
  };

  const columns: ColumnDef<any>[] = [
    {
      id: 'name',
      accessorFn: (row) => row.user.name,
      header: () => <ColumnHeader title="Name" />,
    },
    {
      id: 'courseProgress',
      accessorFn: (row: any) => row.progress,
      cell: (props: any) => (
        <GoalProgressCell progress={props.row.original.progress} goalReachedAt={props.row.original.goalReachedAt} />
      ),
      header: () => <ColumnHeader title="Course Progress" />,
    },
    {
      id: 'assignmentsStarted',
      accessorFn: (row) => `${row.goalsStarted} / ${course.goalsCount}`,
      header: () => <ColumnHeader title="Assignments Started" />,
    },
    {
      id: 'totalTime',
      accessorFn: (row) => transformMilliseconds(row.totalStudyTimeMillis, 'total'),
      header: () => <ColumnHeader title="Total Time" />,
    },
    {
      id: 'lastVisit',
      accessorFn: (row) => (row.lastStudyTime ? moment(row.lastStudyTime).fromNow() : ''),
      header: () => <ColumnHeader title="Last Visit" />,
    },
    {
      id: 'readiness',
      accessorFn: (row: any) => row.readiness,
      cell: (props: any) => renderReadinessCell(props.row),
      header: () => <ColumnHeader title="Readiness" />,
    },
    {
      id: 'actions',
      header: () => (
        <span style={{ textAlign: 'center' }}>
          <TableHeaderText>Actions</TableHeaderText>
        </span>
      ),
      cell: (props) => renderActionsCell(props.row),
    },
  ];

  const table = useReactTable({
    data: learnerStats,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleActionsClick = (event: any, rowData: any) => {
    if (showActionsMenu) {
      closeActionsMenu();
      return;
    }
    const offset = determineMenuOffset(event, 0, -190);
    setOffset(offset);
    setShowActionsMenu(true);
    setUserId(rowData.user.id);
    setUserEmail(rowData.userEmail);
  };

  const closeActionsMenu = (): void => {
    setShowActionsMenu(false);
  };

  const toggleSortDirection = (): void => {
    if (sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
  };

  const handleHeaderClick = (e: any): void => {
    // TODO: Ensure this approach works with localization ... or fix it
    const header = formatStringForSortComparison(e.currentTarget.innerText);
    if (header === 'actions' || header === 'readiness') {
      return;
    }
    setPage(1);
    if (header === sort) {
      toggleSortDirection();
    } else {
      setSort(header);
      setSortDirection('asc');
    }
  };

  const emailLearner = (): void => {
    setShowEmailLearnerModal(true);
  };

  const goToPageOne = () => {
    setPage(1);
  };

  const goToNextPage = () => {
    if (!!totalPages && page < totalPages) {
      setPage(page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToLastPage = () => {
    if (!!totalPages) {
      setPage(totalPages);
    }
  };

  // Show skeleton UI while waiting for table data
  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={650} />;
  }

  if (showEmailLearnerModal) {
    return (
      <Modal
        show={showEmailLearnerModal}
        element={
          <EmailLearnerModal
            userId={userId}
            userEmail={userEmail}
            courseId={props.course.id}
            close={() => setShowEmailLearnerModal(false)}
          />
        }
      />
    );
  }

  return (
    <>
      <DataContainer>
        <DataTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} />
        </DataTable>

        <Pagination
          totalCount={totalCount}
          page={page}
          pageSize={pageSize}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </DataContainer>

      <div id="course-learners-actions-menu">
        {showActionsMenu && (
          <CourseLearnersTableMenu
            course={course}
            userId={userId}
            userEmail={userEmail}
            top={offset[1] / 16}
            left={offset[0] / 16}
            handleOutsideClick={closeActionsMenu}
            onEmailLearner={emailLearner}
          />
        )}
      </div>
    </>
  );
};
