import axios from 'axios';
import { BrightInput } from 'components/forms/BrightInput';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Box, Content } from 'components/modals/common';
import { ContentSection, PageContainer, PageSubTitle, PageTitle, TitleSection } from 'core/layout';
import { ErrorMessage } from 'core/typography';
import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getBaseAngularUrl, getBaseReactUrl, queryParams } from 'utils/pathUtils';

const ClientEditHeader = styled.h3`
  margin-bottom: 0px;
`;

const PublicJWKArea = styled.div`
  overflow: scroll;
`;

interface IEditOAuthPageProps {
  match: { params: { partnerSlug: string; authId: string } };
}

export const EditOAuthPage = React.memo<IEditOAuthPageProps>(props => {
  const { partnerSlug, authId } = props.match.params;
  const isFirstTimeSetup = queryParams(window.location).firstSetup;
  const [authData, setAuthData]: any[] = React.useState();
  const [publicKey, setPublicKey] = React.useState();
  const [saveOAuthClientIsPending, setSaveOAuthClientIsPending] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`/api/v3/oauth2_clients/${authId}?include_generated_public_key=true`).then((res: any): void => {
      // On first setup, nil out key, as the user must copy this from canvas
      if (isFirstTimeSetup) {
        res.data.data.attributes.key = '';
      }
      setAuthData(res.data.data);
    });
  }, [partnerSlug]);

  const saveOAuthClient = async () => {
    setSaveOAuthClientIsPending(true);
    try {
      await axios.put(`/api/v3/oauth2_clients/${authId}`, {
        id: partnerSlug,
        identifier: authData.attributes['key'],
        name: authData.attributes['name'],
        website: authData.attributes['website'],
        redirect_uri: authData.attributes['redirect-uri'],
        access_token_url: authData.attributes['access-token-url'],
        public_jwk_url: authData.attributes['public-jwk-url']
      });
      setSaveOAuthClientIsPending(false);
      window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerSlug}/oauth`;
    } catch {
      setSaveOAuthClientIsPending(false);
      setShowError(true);
    }
  };

  //https://docs.google.com/presentation/d/1hPVYWn3PksP3CjjG7Tfq70y05cs158QthXPfc7mPep0/edit#slide=id.ga85e9b345d_0_14
  const generatePublicJWK = async () => {
    try {
      const response = await axios.get(`/api/v3/oauth2_clients/${authId}?include_generated_public_key=true`);
      setPublicKey(response.data.data.relationships['public-key'].data);
    } catch { }
  };

  const onCancel = () => {
    window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerSlug}/oauth`;
  };

  const validLTIClient = () => {
    return (
      authData &&
      ((authData.attributes['client-type'] === 'lti_12' && !_.isEmpty(authData.attributes['name'])) ||
        (authData.attributes['client-type'] === 'lti_13' &&
          !_.isEmpty(authData.attributes['name']) &&
          !_.isEmpty(authData.attributes['key']) &&
          !_.isEmpty(authData.attributes['website']) &&
          !_.isEmpty(authData.attributes['redirect-uri']) &&
          !_.isEmpty(authData.attributes['access-token-url']) &&
          !_.isEmpty(authData.attributes['public-jwk-url'])))
    );
  };

  return (
    <PageContainer>
      <TitleSection>
        <PageTitle>{t('OAuth Client Details')}</PageTitle>
        <PageSubTitle>{t('For use with LTI and SAML integrations')}</PageSubTitle>
      </TitleSection>
      {authData && authData.attributes['client-type'] === 'lti_13' && (
        <ContentSection>
          <Box>
            <Content>
              <ClientEditHeader>Redirect URI/Target URI</ClientEditHeader>
              {`${getBaseAngularUrl()}/lti/v13/redirect`}
              <ClientEditHeader>OpenID Connect Initiation URL</ClientEditHeader>
              {`${getBaseAngularUrl()}/lti/v13/connect`}
              <ClientEditHeader>JWK Keyset URL</ClientEditHeader>
              {`${getBaseAngularUrl()}/lti/v13/jwks/${authData.id}`}
              <ClientEditHeader>Public JWK</ClientEditHeader>
              <PublicJWKArea>{authData.relationships['public-key'].data}</PublicJWKArea>
            </Content>
          </Box>
        </ContentSection>
      )}
      <ContentSection>
        <Box>
          <Content>
            {authData && authData.attributes['client-type'] === 'lti_13' && (
              <div>
                <ClientEditHeader>{t('Client Identifier')}</ClientEditHeader>
                <div>
                  {t(
                    'This must match the LMS "sub" claim. In canvas, this is located in Admin -> Developer Keys, under the "Details" column. In Brightspace, you find this in your LTI Advantage -> Brightspace Registration Details, under "Client Id".'
                  )}
                </div>
                <BrightInput
                  placeholder={'18310000000014'}
                  onChange={e =>
                    setAuthData({ ...authData, attributes: { ...authData.attributes, key: e.target.value } })
                  }
                  required={true}
                  value={authData.attributes['key']}
                />
              </div>
            )}
            {!isFirstTimeSetup && (
              <div>
                <ClientEditHeader>{t('Client Name')}</ClientEditHeader>
                {authData && (
                  <BrightInput
                    placeholder={'Cerego LTI Canvas OAuth'}
                    onChange={e =>
                      setAuthData({ ...authData, attributes: { ...authData.attributes, name: e.target.value } })
                    }
                    required={true}
                    value={authData.attributes['name']}
                  />
                )}
                {authData && authData.attributes['client-type'] === 'lti_13' && (
                  <div>
                    <ClientEditHeader>{t('Client Website')}</ClientEditHeader>
                    <div>{t('This must match the client that is issuing keys')}</div>
                    <BrightInput
                      placeholder={'https://canvas.instructure.com'}
                      onChange={e =>
                        setAuthData({ ...authData, attributes: { ...authData.attributes, website: e.target.value } })
                      }
                      value={authData.attributes['website']}
                      required={true}
                    />
                    <ClientEditHeader>{t('Client Redirect URI')}</ClientEditHeader>
                    <div>{t('This must match the OpenID redirect given by the LMS')}</div>
                    <BrightInput
                      placeholder={'https://canvas.instructure.com/api/lti/authorize_redirect'}
                      onChange={e =>
                        setAuthData({
                          ...authData,
                          attributes: { ...authData.attributes, 'redirect-uri': e.target.value }
                        })
                      }
                      required={true}
                      value={authData.attributes['redirect-uri']}
                    />
                    <ClientEditHeader>{t('Client Access Token URL')}</ClientEditHeader>
                    <div>{t('This must match the OAuth token endpoint given by the LMS')}</div>
                    <BrightInput
                      placeholder={'https://canvas.instructure.com/login/oauth2/token'}
                      onChange={e =>
                        setAuthData({
                          ...authData,
                          attributes: { ...authData.attributes, 'access-token-url': e.target.value }
                        })
                      }
                      required={true}
                      value={authData.attributes['access-token-url']}
                    />
                    <ClientEditHeader>{t('Client Public JWK URL')}</ClientEditHeader>
                    <div>{t('This URL must give a response in the form of:')}</div>
                    <div></div>
                    <div>{'keys: [{ kty:RSA, e:AQAB, n:---, kid:2000-01-01T00:00:00Z, alg:RS246, use:sig }, ...]'}</div>
                    <BrightInput
                      placeholder={'https://canvas.instructure.com/api/lti/security/jwks'}
                      onChange={e =>
                        setAuthData({
                          ...authData,
                          attributes: { ...authData.attributes, 'public-jwk-url': e.target.value }
                        })
                      }
                      required={true}
                      value={authData.attributes['public-jwk-url']}
                    />
                  </div>
                )}
              </div>
            )}
            {showError && (
              <div>
                <ErrorMessage>{t('An error occurred while saving your oauth client.')}</ErrorMessage>
                {authData.attributes['client-type'] === 'lti_13' && (
                  <ErrorMessage>{t('Please ensure your client public JWK URL gives a valid response.')}</ErrorMessage>
                )}
              </div>
            )}
            <ConfirmOrCancel
              cancelText={t('Cancel')}
              confirmText={t('Update')}
              handleCancelClick={onCancel}
              handleConfirmClick={saveOAuthClient}
              confirmIsDisabled={!validLTIClient()}
              confirmIsPending={saveOAuthClientIsPending}
            />
          </Content>
        </Box>
      </ContentSection>
    </PageContainer>
  );
});
