import { faCheck, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AutosaveStatus = ({ status }: { status: 'idle' | 'saving' | 'success' | 'error' }) => {
  const { t } = useTranslation();

  let message = '';
  let icon: any = null;

  switch (status) {
    case 'saving':
      message = t('Saving...');
      icon = <FontAwesomeIcon icon={faSpinner} spin />;
      break;
    case 'success':
      message = t('Saved');
      icon = <FontAwesomeIcon icon={faCheck} />;
      break;
    case 'error':
      message = t('Save failed');
      icon = <FontAwesomeIcon icon={faExclamationCircle} />;
      break;
    default:
      return null;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <span style={{ marginLeft: '8px' }}>{message}</span>
    </div>
  );
};
