import axios from 'axios';
import { useGroupCoursesStore } from 'pages/admin-dashboard/groups/store';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

type DeleteGroupCourseParams = {
  groupId: string;
  courseId: string;
};

const deleteGroupCourse = (params: DeleteGroupCourseParams) => {
  // NOTE: makes no sense that this endpoint is courses/{courseId}/groups/{groupId}
  //       instead of groups/{groupId}/courses/{courseId}
  // TODO: fix this
  return axios.delete(`/api/v3/courses/${params.courseId}/groups/${params.groupId}`);
};

export const useDeleteGroupCourse = (groupId: string) => {
  const { makeToast } = useStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name, statusFilter } = useGroupCoursesStore();

  return useMutation(deleteGroupCourse, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to remove group from course',
        isError: true,
      });
    },
    onSuccess: () => {
      makeToast({
        text: 'Successfully removed group from course',
        isError: false,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries([
        'groupCourses',
        groupId,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        name,
        statusFilter,
      ]);
    },
  });
};
