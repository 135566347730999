import { DeleteButton, PrimaryButton } from 'components/buttons/v4';
import { useItemEditorStore } from 'components/item-editor/store';
import { Card, CardHeaderContainer } from 'core/layout';
import { useDeleteItem } from 'hooks/useDeleteItem';
import { IItem, SurveyAnswerType, TemplateType } from 'models/item';
import { useTranslation } from 'react-i18next';

const getHeader = (item: IItem): string => {
  switch (item.templateType) {
    case 'instructional_contents':
    case 'sequences':
      return item.title || 'Untitled';

    case 'regions':
      return `Diagram: ${item.facets.length} regions`;

    case 'ratings':
      return item.question || 'Untitled';

    case 'associations':
    case 'free_responses':
    case 'vocabulary':
    case 'question_and_answers':
    case 'survey_question_and_answers':
      return item.facets[0]?.anchor?.text || 'Untitled';
    default:
      console.warn(`Cannot get header for item.templateType: ${item.templateType}`);
      return item.templateType;
  }
};

type ItemWrapperStyle = {
  border: string;
};

const getItemWrapperStyle = (templateType: TemplateType): ItemWrapperStyle => {
  switch (templateType) {
    case 'instructional_contents':
      return {
        border: '1px solid green',
      };
    default:
      return {
        border: '1px solid blue',
      };
  }
};

interface IAssignmentItemCard {
  item: IItem;
  onClick: (item: IItem) => void;
}

export const AssignmentItemCard = (props: IAssignmentItemCard) => {
  const { item } = props;
  const { t } = useTranslation();
  const header: string = getHeader(item);
  const style: object = Object.assign(
    {},
    { marginRight: 'auto', marginLeft: 'auto', width: '100%' },
    getItemWrapperStyle(item.templateType)
  );
  const { activeAssignment: assignment } = useItemEditorStore();
  const [deleteItem] = useDeleteItem(item.id, assignment?.id);

  const formatItemType = (itemType: TemplateType, answerType: SurveyAnswerType | undefined): string => {
    switch (itemType) {
      case 'instructional_contents':
        return t('Instructional Content');
      case 'question_and_answers':
        return t('Question and Answer');
      case 'sequences':
        return t('Sequence');
      case 'regions':
        return t('Diagram');
      case 'associations':
        return t('Flashcard');
      case 'free_responses':
        return t('Free Response');
      case 'ratings':
        return t('Ratings');
      case 'survey_question_and_answers':
        return answerType === 'multi_correct' ? t('Multiple Select Question') : t('Single Select Question');
      default:
        return itemType;
    }
  };

  const formattedItemType: string = formatItemType(item.templateType, item.answerType);

  return (
    <Card style={style}>
      <CardHeaderContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>{header}</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PrimaryButton click={() => props.onClick(item)}>Edit</PrimaryButton>
            <DeleteButton click={() => deleteItem(item.id)}>Delete</DeleteButton>
          </div>
        </div>
        <p style={{ fontSize: '0.8em', color: 'grey' }}>{formattedItemType}</p>
      </CardHeaderContainer>
    </Card>
  );
};
