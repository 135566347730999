import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

import { Size } from 'core';
import { IQuiz } from 'models/quiz';

type RatingProps = {
  quiz: IQuiz;
  onRatingValueChange: (ratingValue: number) => void;
};

type RatingState = {
  ratingValue?: number;
};

const initialRatingState: RatingState = {
  ratingValue: undefined
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  flex: 1 1 auto;
  overflow-y: auto;
  max-width: ${Size.wideCardWidth}px;
  @media (max-width: ${Size.iPadWidth}px) {
    max-width: ${Size.phoneWidth}px;
  }
  margin: 0px auto;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
`;

const FormControlLabelStyleWrapper = styled.span`
  @media (max-width: ${Size.wideCardWidth}px) {
    margin: 0px;
    top: 17px;
  }
  margin: 16px;
`;

const Label = styled.span`
  padding-top: 52px;
  position: relative;
  text-overflow: ellipsis;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  @media (max-width: ${Size.wideCardWidth}px) {
    padding-top: 36px;
  }
  @media (max-width: ${Size.iPadWidth}px) {
    font-size: 10px;
    width: 100px;
    top: 25px;
    margin-bottom: 16px;
  }
  @media (max-width: ${Size.phoneWidth}px) {
    display: none;
  }
`;

const LabelLeft = styled(Label)`
  @media (max-width: ${Size.iPadWidth}px) {
    left: 87px;
  }
`;

const LabelRight = styled(Label)`
  @media (max-width: ${Size.iPadWidth}px) {
    left: -87px;
  }
`;

const MobileLabelContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: ${Size.phoneWidth}px) {
    display: none;
  }
`;

const MobileLabel = styled.span`
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default class Rating extends React.Component<RatingProps, RatingState> {
  constructor(props) {
    super(props);
    this.state = initialRatingState;
  }

  public render() {
    const { quiz } = this.props;

    const ratingOptions = [1, 2, 3, 4, 5].map(index => (
      <FormControlLabelStyleWrapper key={index}>
        <FormControlLabel
          value={index}
          onClick={this.selectAnswer}
          checked={this.state.ratingValue === index}
          control={<Radio value={index} onClick={this.selectAnswer} checked={this.state.ratingValue === index}></Radio>}
          labelPlacement={'top'}
          label={index}
          style={{ margin: 0 }}
        />
      </FormControlLabelStyleWrapper>
    ));

    return (
      <Container>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <RadioGroup row style={{ margin: 'auto' }}>
            <LabelLeft>{quiz.parts[0].minLabel}</LabelLeft>
            {ratingOptions}
            <LabelRight>{quiz.parts[0].maxLabel}</LabelRight>
          </RadioGroup>
        </div>
        <MobileLabelContainer>
          <MobileLabel style={{ fontSize: '10px', margin: 'auto' }}>{quiz.parts[0].minLabel}</MobileLabel>
          <MobileLabel style={{ fontSize: '10px', margin: 'auto' }}>{quiz.parts[0].maxLabel}</MobileLabel>
        </MobileLabelContainer>
      </Container>
    );
  }

  private selectAnswer = e => {
    const ratingValue = parseInt(e.target.value);
    this.setState({ ratingValue });
    this.props.onRatingValueChange(ratingValue);
  };
}
