import { ReactNode } from 'react';
import create from 'zustand';

type Direction = 'top' | 'bottom' | 'left' | 'right' | null;

type Position = {
  top?: number | null;
  left?: number | null;
};

type TooltipState = {
  visible: boolean;
  x: number;
  y: number;
  direction: Direction;
  content: ReactNode | string;
};

type Restraints = {
  snapping?: Array<{
    x?: number;
    y?: number;
  }>;
  snappingDistance?: number;
  bounds?: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
    x?: number;
    y?: number;
  };
};

type TooltipStore = {
  tooltipState: TooltipState;
  restraints: Restraints;
  showTooltip: (params: {
    content: ReactNode | string;
    pos?: Position;
    dir?: Direction;
    restraints?: Restraints;
  }) => void;
  hideTooltip: () => void;
  updatePosition: (x: number, y: number) => void;
};

export const useTooltipStore = create<TooltipStore>((set) => ({
  tooltipState: {
    visible: false,
    x: 0,
    y: 0,
    direction: null,
    content: '',
  },
  restraints: {},

  showTooltip: ({ content, pos = { top: null, left: null }, dir = null, restraints = {} }) =>
    set(() => ({
      tooltipState: {
        visible: true,
        x: pos.left ?? 0,
        y: pos.top ?? 0,
        direction: dir,
        content,
      },
      restraints,
    })),

  hideTooltip: () =>
    set((state) => ({
      tooltipState: {
        ...state.tooltipState,
        visible: false,
      },
      restraints: {},
    })),

  updatePosition: (x, y) =>
    set((state) => ({
      tooltipState: {
        ...state.tooltipState,
        x: state.restraints.bounds?.x || x,
        y: state.restraints.bounds?.y || y,
      },
    })),
}));
