import React from 'react';
import { TAnnotation } from 'types';

export const SoundAnnotation = ({ annotation }: { annotation: TAnnotation }) => {
  return (
    <audio controls>
      <source src={annotation.sound.url} type={annotation.sound.mimeType || 'audio/mpeg'} />
            Your browser does not support the audio element.
    </audio>
  )
}
