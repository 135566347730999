import * as Color from 'core/colors';
import * as CommonButton from 'core/v4button';
import React from 'react';
import styled from 'styled-components';

const ButtonElement = styled(CommonButton.BaseButton).attrs(
  ({ shape = 'rectangle', context = 'light', size = 'medium', disabled = false }: CommonButton.IButton) => {
    const defaultStyles: any = {
      borderColor: Color.primaryGreen,
      backgroundColor: Color.primaryGray,
      hoverBackgroundColor: `linear-gradient(120deg, ${Color.white}, ${Color.primaryGray})`,
      color: Color.primaryGreen,
      borderRadius: '4px',
      width: 'unset',
      height: 'unset',
      fontSize: '1rem'
    };
    const attributeStyles: any = {
      ...CommonButton.BaseStyles,
      dark: {
        borderColor: Color.white,
        backgroundColor: Color.lightGray,
        color: Color.white
      }
    };
    let stateStyles: any = {};

    return {
      ...defaultStyles,
      ...attributeStyles[shape],
      ...attributeStyles[context],
      ...attributeStyles[size],
      ...stateStyles
    };
  }
)`
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};
  color: ${props => props.color};
  width: ${props => props.width};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  padding: ${props => props.padding};
  text-align: ${props => props.padding};

  :hover {
    background: ${props => (props.disabled ? props.backgroundColor : props.hoverBackgroundColor)};
  }
`;

function SecondaryButton({ type = 'button', ...props }: CommonButton.IButton) {
  const { children, click } = props;

  return (
    <ButtonElement type={type} onClick={click} {...props}>
      <span>{children}</span>
    </ButtonElement>
  );
}

export default SecondaryButton;
