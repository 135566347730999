import Box from '@mui/material/Box';
import * as colours from 'core/colors';
import _ from 'lodash';

function TimeLine({
  children,
  border,
  height,
  width,
}: {
  children?: any;
  border: boolean;
  height: number;
  width?: string;
}) {
  return (
    <Box
      sx={{
        height,
        flex: width ? undefined : 1,
        opacity: 0.8,
        borderRight: border ? '' : `2px solid ${colours.white}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        width,
      }}
    >
      {children}
    </Box>
  );
}

function getLinesArray(date) {
  const minutes = date.getMinutes();

  let lines = [
    [2, 2],
    [2, 2],
    [2, 2],
    [2, 2],
    [2, 2],
  ];

  switch (minutes) {
    case 0:
      lines[0] = [2];
      lines.push([2]);
      break;
    case 30:
      break;
    case 15:
      lines[0] = [1, 2];
      lines.push([1]);
      break;
    case 45:
      lines[0] = [1];
      lines.push([2, 1]);
      break;
    default:
      lines = [];
      break;
  }

  return lines;
}

type Props = {
  time: Date;
};

export default function MemreIdealStudyTime({ time }: Props) {
  const lines = getLinesArray(time);
  const totalSegments = _.flatten(lines).length;

  const calculatePercentage = (part) => `${(part / totalSegments) * 100}%`;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        gap: 2,
        mb: '5px',
        mr: '5px',
      }}
    >
      <Box sx={{ flexGrow: 1, height: 55, width: '100%', bgcolor: colours.hexToRGB(colours.brightGreen, 0.2) }}>
        <Box sx={{ height: 55, width: '60%', mx: 'auto', bgcolor: colours.hexToRGB(colours.brightGreen, 0.4) }}>
          <Box sx={{ height: 55, width: '34%', mx: 'auto', bgcolor: colours.hexToRGB(colours.brightGreen, 0.6) }} />
        </Box>
      </Box>
      <Box
        sx={{
          height: 55,
          width: '100%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
        }}
      >
        {lines.map((halves, i) => (
          <TimeLine border={i + 1 === lines.length} key={i} height={40} width={calculatePercentage(_.sum(halves))}>
            {halves.map((quarters, i) => (
              <TimeLine border={i + 1 === halves.length} key={i} height={25}>
                {new Array(quarters).fill(0).map((_, i) => (
                  <TimeLine border={i + 1 === quarters} key={i} height={10} />
                ))}
              </TimeLine>
            ))}
          </TimeLine>
        ))}
      </Box>
    </Box>
  );
}
