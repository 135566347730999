import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox as MaterialCheckBox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CancelButton, DeleteButton } from 'components/buttons/v4';
import { ModalPosition } from 'components/Modal';
import { Color } from 'core';
import { Card, CardHeader, CardHeaderContainer, PageTitle } from 'core/layout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CheckBoxGrid, List, ListIcon, ModalHeader, SpacedOut } from './common';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

interface IProps {
  courseName: string;
  onCancel: () => void;
  onDelete: () => void;
}

const RedCheckBox = withStyles({
  root: {
    padding: 0,
    '&$checked': {
      color: Color.regularRed,
    },
  },
  checked: {},
})(MaterialCheckBox);

export const DeleteCourse = (props: IProps) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>
          <b>{t("You're about to delete:")}</b> {props.courseName}
        </CenterTitle>
      </ModalHeader>

      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('What happens when you delete a course?')}</CardHeader>
        </CardHeaderContainer>
        <div>
          <List>
            <li>
              <ListIcon color={Color.regularRed}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </ListIcon>
              {t('You will delete all assignments created within this course')}
            </li>
            <li>
              <ListIcon color={Color.regularRed}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </ListIcon>
              {t('Learners will lose access')}
            </li>
            <li>
              <ListIcon color={Color.regularRed}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </ListIcon>
              {t('Assignments added to the shared assignment library will be removed, unless in use by other courses')}
            </li>
            <li>
              <ListIcon color={Color.regularRed}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </ListIcon>
              {t('All analytics and reports will be deleted;')} <b>{t('export any reports you need for grading')}</b>
            </li>
          </List>
        </div>
      </Card>
      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('Are you sure?')}</CardHeader>
        </CardHeaderContainer>
        <div>
          <CheckBoxGrid>
            <RedCheckBox onClick={() => setChecked((checked) => !checked)} />
            <div>{t('Yes, I want to delete this course and understand this cannot be undone.')}</div>
          </CheckBoxGrid>
        </div>
      </Card>
      <SpacedOut>
        <CancelButton click={props.onCancel}>{t('Cancel')}</CancelButton>
        <DeleteButton click={props.onDelete} disabled={!checked}>
          {t('Delete this Course')}
        </DeleteButton>
      </SpacedOut>
    </ModalPosition>
  );
};
