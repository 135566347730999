import produce from 'immer';
import create from 'zustand';

// Make state updates smoother
const immer = (config) => (set, get, api) => config((fn) => set(produce(fn)), get, api);

export type CurriculumAssignment = {
  title: string;
};

export type State = {
  assignments: CurriculumAssignment[];
  addAssignment: (assignment: CurriculumAssignment) => void;
  removeAssignment: (index: number) => void;
};

const makeStore = () => {
  return create<State>(
    immer((set) => ({
      assignments: [],
      addAssignment: (assignment: CurriculumAssignment) => {
        set((state) => {
          state.assignments.push(assignment);
        });
      },
      removeAssignment: (index: number) => {
        set((state) => {
          state.assignments.splice(index, 1);
        });
      },
    }))
  );
};

export const useSmartCreateStore = makeStore();
