import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import { ChangeEvent, useEffect, useState } from 'react';

// Define the shape of the local notifications state
interface ProductNotificationsState {
  assignmentGoalAchieved: boolean;
  newAssignmentLearner: boolean;
  newAssignmentInstructor: boolean;
  reviewReminder: boolean;
  newCourseAssignedLearner: boolean;
  newCourseAssignedInstructor: boolean;
}

// Define the mapping between local state keys and backend keys
const notificationKeyMap: Record<keyof ProductNotificationsState, string> = {
  assignmentGoalAchieved: 'goal-achievement',
  newAssignmentLearner: 'hourly-published-assignments-for-student',
  newAssignmentInstructor: 'hourly-published-assignments-for-teacher',
  reviewReminder: 'review-reminder',
  newCourseAssignedLearner: 'course-student-welcome',
  newCourseAssignedInstructor: 'course-admin-notification',
};

export const ProductNotifications: React.FC = () => {
  const user = useCurrentUser();
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);

  // Initialize state only when user data is available
  const [productNotifications, setProductNotifications] = useState<ProductNotificationsState>({
    assignmentGoalAchieved: user?.primaryPartner.notifications.goalAchievement ?? false,
    newAssignmentLearner: user?.primaryPartner.notifications.hourlyPublishedAssignmentsForStudent ?? false,
    newAssignmentInstructor: user?.primaryPartner.notifications.hourlyPublishedAssignmentsForTeacher ?? false,
    reviewReminder: user?.primaryPartner.notifications.reviewReminder ?? false,
    newCourseAssignedLearner: user?.primaryPartner.notifications.courseStudentWelcome ?? false,
    newCourseAssignedInstructor: user?.primaryPartner.notifications.courseAdminNotification ?? false,
  });

  // Update state when user data changes
  useEffect(() => {
    if (user) {
      setProductNotifications({
        assignmentGoalAchieved: user.primaryPartner.notifications.goalAchievement,
        newAssignmentLearner: user.primaryPartner.notifications.hourlyPublishedAssignmentsForStudent,
        newAssignmentInstructor: user.primaryPartner.notifications.hourlyPublishedAssignmentsForTeacher,
        reviewReminder: user.primaryPartner.notifications.reviewReminder,
        newCourseAssignedLearner: user.primaryPartner.notifications.courseStudentWelcome,
        newCourseAssignedInstructor: user.primaryPartner.notifications.courseAdminNotification,
      });
    }
  }, [user]);

  // Function to map local state to backend notification keys
  const mapNotificationsToBackend = (notifications: ProductNotificationsState) => {
    const backendNotifications: Record<string, boolean> = {};
    for (const key in notifications) {
      const backendKey = notificationKeyMap[key as keyof ProductNotificationsState];
      backendNotifications[backendKey] = notifications[key as keyof ProductNotificationsState];
    }
    return backendNotifications;
  };

  // Handler for checkbox changes
  const handleCheckboxChange = (key: keyof ProductNotificationsState) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setProductNotifications((prevNotifications) => {
      const updatedNotifications = {
        ...prevNotifications,
        [key]: newValue,
      };

      if (user) {
        const payload = {
          notifications: mapNotificationsToBackend(updatedNotifications),
        };

        debouncedPartnerUpdate({
          id: user.primaryPartner.id,
          ...payload,
        });
      }

      return updatedNotifications;
    });
  };

  // Helper function to format label text
  const formatLabel = (key: string) => {
    return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  };

  // Render nothing if user data is not available
  if (!user) {
    return null;
  }

  return (
    <ConfigItem title="Product Notifications">
      <FormGroup>
        {Object.keys(productNotifications).map((key) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                checked={productNotifications[key as keyof ProductNotificationsState]}
                onChange={handleCheckboxChange(key as keyof ProductNotificationsState)}
                color="secondary"
              />
            }
            label={formatLabel(key)}
          />
        ))}
      </FormGroup>
    </ConfigItem>
  );
};
