import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Step } from 'components/item-editor/sequence/Step';
import { useItemEditorStore } from 'components/item-editor/store';
import { useCreateFacet } from 'hooks/useCreateFacet';
import { useCallback, useEffect, useState } from 'react';

export const Steps = () => {
  const { activeItem: item, updateActiveItem, activeAssignment: assignment } = useItemEditorStore();
  const [createFacet] = useCreateFacet(item?.id || '', assignment?.id || '');
  const [sortedFacets, setSortedFacets] = useState<any[]>([]);

  const sortFacets = useCallback((facets: any[]) => {
    return [...facets].sort((a, b) => {
      if (a.anchor && b.anchor && 'position' in a.anchor && 'position' in b.anchor) {
        return a.anchor.position - b.anchor.position;
      }
      if (a.anchor && 'position' in a.anchor) return -1;
      if (b.anchor && 'position' in b.anchor) return 1;
      return 0;
    });
  }, []);

  useEffect(() => {
    if (!item) return;
    setSortedFacets(sortFacets(item.facets));
  }, [item, sortFacets]);

  const createNewStep = async () => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const facet = await createFacet({
      itemId: item.id,
      payload: null,
    });

    const newFacets = [
      ...item.facets,
      {
        id: facet.id,
        association: facet.association,
        anchor: { ...facet.anchor, position: item.facets.length },
        sound: facet.sound,
        image: facet.image,
      },
    ];

    updateActiveItem({ facets: newFacets });
  };

  const reorderSteps = async (currentIndex: number, direction: 'up' | 'down') => {
    if (!item || !item.facets.length) return;

    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= sortedFacets.length) return;

    const newSortedFacets = [...sortedFacets];
    [newSortedFacets[currentIndex], newSortedFacets[newIndex]] = [
      newSortedFacets[newIndex],
      newSortedFacets[currentIndex],
    ];

    // Update positions based on new order
    const updatedFacets = newSortedFacets.map((facet, index) => ({
      ...facet,
      anchor: facet.anchor ? { ...facet.anchor, position: index } : { position: index },
    }));

    setSortedFacets(updatedFacets);
    updateActiveItem({ facets: updatedFacets });

    // Call the reordering endpoint
    try {
      await fetch(`/api/v3/items/${item.id}/facets_ordering`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ordering: updatedFacets.map((facet) => Number(facet.id)),
          set_id: Number(assignment?.id),
        }),
      });
    } catch (error) {
      console.error('Error reordering facets:', error);
      // TODO: Handle error (e.g., show error message to user, revert changes)
    }
  };

  if (!item) {
    console.warn('Item not found');
    return null;
  }

  return (
    <div>
      <h2>Steps</h2>
      {sortedFacets.map((facet: any, index: number) => (
        <div key={facet.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ marginRight: '10px' }}>
            <IconButton onClick={() => reorderSteps(index, 'up')} disabled={index === 0} size="small">
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => reorderSteps(index, 'down')}
              disabled={index === sortedFacets.length - 1}
              size="small"
            >
              <ArrowDownwardIcon />
            </IconButton>
          </div>
          <Step facet={facet} index={index} />
        </div>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={createNewStep}
        style={{ marginBottom: '20px', padding: '10px' }}
      >
        Add another step
      </Button>
    </div>
  );
};
