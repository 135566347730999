import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MenuButton } from 'components/buttons';
import { ReadinessBadge } from 'components/ReadinessBadge';
import { ReadinessTooltip } from 'components/ReadinessTooltip';
import { Pagination } from 'components/reports/controls/Pagination';
import { AssignmentFilter } from 'components/reports/filters/AssignmentFilter';
import { CourseFilter } from 'components/reports/filters/CourseFilter';
import { GroupFilter } from 'components/reports/filters/GroupFilter';
import { SortArrow } from 'components/SortArrow';
import { TableSearchBar } from 'components/tables/TableSearchBar';
import { Color } from 'core';
import { ExportToCsv } from 'export-to-csv';
import { useAssignments } from 'hooks/useAssignments';
import { useCourse } from 'hooks/useCourse';
import { getLearnerStats, useLearnerStats } from 'hooks/useLearnerStats';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoalProgressCell } from 'sets/components/GoalProgressCell';
import styled from 'styled-components';
import { TAssignment } from 'types';
import convertProgressToInteger from 'utils/convertProgressToInteger';
import { formatReadiness } from 'utils/formatReadiness';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { AnalyticsService } from 'utils/AnalyticsService';
import { transformMilliseconds } from 'utils/timeUtils';
import { useReportStore } from 'zstore';

export const LearnerStats = () => {
  return (
    <>
      <Filters />
      <Data />
    </>
  );
};

const Filters = () => {
  const userNameQuery = useReportStore((state) => state.userNameQuery);
  const setUserNameQuery = useReportStore((state) => state.setUserNameQuery);
  const setPage = useReportStore((state) => state.setPage);
  const { t } = useTranslation();

  return (
    <div>
      <CourseFilter />
      <AssignmentFilter includeAll={true} />
      <GroupFilter />
      <TableSearchBar
        value={userNameQuery}
        onChange={setUserNameQuery}
        resetPage={() => setPage(1)}
        placeholder={t('Search by name')}
        style={{ margin: 20 }}
      />
    </div>
  );
};

const Data = () => {
  const courseId = useReportStore((state) => state.courseId);
  const { data: course } = useCourse(courseId || '');
  const assignmentId = useReportStore((state) => state.assignmentId);
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const page = useReportStore((state) => state.page);
  const setPage = useReportStore((state) => state.setPage);
  const [sort, setSort] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const userNameQuery = useReportStore((state) => state.userNameQuery);
  const group = useReportStore((state) => state.group);

  const pageSize = 10;

  const { data: learnerStatsResponse, isLoading } = useLearnerStats(
    courseId,
    sort,
    sortDirection,
    page,
    pageSize,
    userNameQuery,
    group,
    assignmentId
  );

  const learnerStats = normalizeJSONAPIResponse(learnerStatsResponse?.data);
  const { data: assignments } = useAssignments(courseId);
  const assignment = assignments?.find((a: TAssignment) => a.id === assignmentId);

  useEffect(() => {
    if (!!learnerStatsResponse) {
      setTotalCount(learnerStatsResponse?.data.meta['total-count']);
      setTotalPages(learnerStatsResponse?.data.meta['total-pages']);
    }
  }, [JSON.stringify(learnerStatsResponse)]);

  const renderHeader = (title: string) => {
    return (
      <TableHeaderContainer
        style={{
          justifyContent: ['Assessment Score', 'Studied', 'Reviewed'].includes(title) ? 'right' : 'left',
        }}
      >
        <TableHeaderText>{title}</TableHeaderText>
        {sort === title.toLowerCase().replace(' ', '_') && sortDirection === 'desc' && <SortArrow icon={faArrowDown} />}
        {sort === title.toLowerCase().replace(' ', '_') && sortDirection === 'asc' && <SortArrow icon={faArrowUp} />}
        {title === 'Readiness' && <ReadinessTooltip />}
      </TableHeaderContainer>
    );
  };

  const renderReadinessCell = (row: any) => {
    return <ReadinessBadge readinessScore={row.original.readiness} style={{ fontSize: '16px' }} />;
  };

  const [columns, setColumns] = useState<ColumnDef<any>[]>([]);

  const COLUMNS = {
    NAME: {
      id: 'name',
      accessorFn: (row: any) => row.user.name,
      header: () => renderHeader('Name'),
    },
    COMPLETED_AT: {
      id: 'completedAt',
      accessorFn: (row: any) => (row.goalReachedAt ? moment(row.goalReachedAt).format('MMMM DD YYYY, h:mm a') : ''),
      header: () => renderHeader('Completed At'),
    },
    ASSESSMENT_SCORE: {
      id: 'assessmentScore',
      accessorFn: (row: any) => (row.assessmentScore ? `${row.assessmentScore}%` : ''),
      header: () => renderHeader('Assessment Score'),
    },
    PROGRESS: {
      id: 'progress',
      accessorFn: (row: any) => row.progress,
      cell: (props: any) => (
        <GoalProgressCell progress={props.row.original.progress} goalReachedAt={props.row.original.goalReachedAt} />
      ),
      header: () => renderHeader('Progress'),
    },
    LAST_VISIT: {
      id: 'lastVisit',
      accessorFn: (row: any) => (row.lastStudyTime ? moment(row.lastStudyTime).fromNow() : ''),
      header: () => renderHeader('Last Visit'),
    },
    TOTAL_TIME: {
      id: 'totalTime',
      accessorFn: (row: any) => transformMilliseconds(row.totalStudyTimeMillis, 'total'),
      header: () => renderHeader('Total Time'),
    },
    READINESS: {
      id: 'readiness',
      accessorFn: (row: any) => row.readiness,
      cell: (props: any) => renderReadinessCell(props.row),
      header: () => renderHeader('Readiness'),
    },
    PERCENT_STUDIED: {
      id: 'percentStudied',
      accessorFn: (row: any) => convertProgressToInteger(row.percentStarted).toString().concat('%'),
      header: () => renderHeader('Studied'),
    },
    PERCENT_REVIEWED: {
      id: 'percentReviewed',
      accessorFn: (row: any) => convertProgressToInteger(row.percentReviewed).toString().concat('%'),
      header: () => renderHeader('Reviewed'),
    },
  };

  const assessmentColumns = [COLUMNS.NAME, COLUMNS.COMPLETED_AT, COLUMNS.ASSESSMENT_SCORE];

  const assignmentColumns = [
    COLUMNS.NAME,
    COLUMNS.PROGRESS,
    COLUMNS.LAST_VISIT,
    COLUMNS.TOTAL_TIME,
    COLUMNS.READINESS,
    COLUMNS.PERCENT_STUDIED,
    COLUMNS.PERCENT_REVIEWED,
  ];

  const defaultColumns = [
    COLUMNS.NAME,
    COLUMNS.PROGRESS,
    COLUMNS.LAST_VISIT,
    COLUMNS.TOTAL_TIME,
    COLUMNS.PERCENT_STUDIED,
    COLUMNS.PERCENT_REVIEWED,
  ];

  const determineColumns = () => {
    // If no assignment is selected, use defaultColumns for learner stats course report
    if (!assignmentId || !assignment) {
      setColumns(assignmentColumns);
      return;
    }

    switch (assignment.goalType) {
      case 'assessment':
        setColumns(assessmentColumns);
        break;
      case 'set':
        setColumns(assignmentColumns);
        break;
      default:
        setColumns(defaultColumns);
    }
  };

  useEffect(() => {
    determineColumns();
  }, [JSON.stringify(assignment)]);

  const table = useReactTable({
    data: learnerStats,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const toggleSortDirection = (): void => {
    if (sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
  };

  const handleHeaderClick = (e: any): void => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (header === 'readiness') {
      return;
    }
    setPage(1);
    if (header === sort) {
      toggleSortDirection();
    } else {
      setSort(header);
      setSortDirection('asc');
    }
  };

  function parseStatsForCsv(
    stats: Array<any>,
    setType: string,
    uniqueGroupUsers: string[] = [],
    uniqueUserPartnerUserTags: string[] = []
  ) {
    return stats.map((stat: any) => {
      const headers = {
        [t('Course')]: course?.name || 'N/A',
        [t('Assignment')]: assignment?.name || 'N/A',
        [t('Group')]: group || 'N/A',
        [t('Last Study Time')]: stat.lastStudyTime ? moment(stat.lastStudyTime).format('YYYY-MM-DD HH:mm:ss') : '',
        [t('User ID')]: stat.user.id,
        [t('Name')]: stat.user.name,
        [t('Email')]: stat.user.email,
        [t('Goal Reached At')]: stat.goalReachedAt ?? '',
        [t('Total Study Time')]: transformMilliseconds(stat.totalStudyTimeMillis, 'total'),
        [t('Member ID')]: stat.memberId ? stat.memberId : '',
      };
      if (setType === 'assessment') {
        headers[t('Assessment Score')] = `${stat.assessmentScore}%`;
        headers[t('Percent Completed')] = `${convertProgressToInteger(stat.percentStarted)}%`;
      } else if (!setType || setType === 'set') {
        headers[t('Readiness')] = formatReadiness(stat.readiness);
        headers[t('Progress')] = convertProgressToInteger(stat.progress) + '%';
        headers[t('Percent Studied')] = `${convertProgressToInteger(stat.percentStarted)}%`;
        headers[t('Percent Reviewed')] = `${convertProgressToInteger(stat.percentReviewed)}%`;
      }
      uniqueGroupUsers.forEach((group: string) => {
        headers[group] = stat.groupUsers.includes(group);
      });
      uniqueUserPartnerUserTags.forEach((tag: any) => {
        const userTag = stat.userPartnerUserTags?.find((userTag: any) => userTag.label === tag.label);
        headers[tag.label] = userTag ? userTag.value : '';
      });
      return headers;
    });
  }

  function downloadCsv(data: Array<any>, setType: string) {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Download report' });
    if (!data) {
      return;
    }

    const uniqueGroupUsers: string[] = Array.from(new Set(data.map((stat: any) => stat.groupUsers).flat()));
    const uniqueUserPartnerUserTags: string[] = Array.from(
      new Set(data.map((stat: any) => stat.userPartnerUserTags).flat())
    );
    const parsedStatsForCsv = parseStatsForCsv(data, setType, uniqueGroupUsers, uniqueUserPartnerUserTags);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `learner-stats-${new Date().toISOString()}`,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(parsedStatsForCsv);
    setIsDownloading(false);
  }

  const handleCsvButtonClick = async (): Promise<void> => {
    if (!courseId) {
      console.warn('Cannot download CSV of learner stats with null courseId');
      return;
    }

    if (!assignmentId || !assignment) {
      console.warn('Cannot download CSV of learner stats with null assignmentId');
      return;
    }

    setIsDownloading(true);
    const data = await getAllLearnerStats(courseId, assignmentId || '', group);
    const assignmentType = assignment && assignment.goalType;
    downloadCsv(data, assignmentType);
  };

  const goToPageOne = () => {
    setPage(1);
  };

  const goToNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToLastPage = () => {
    setPage(totalPages);
  };

  // Show skeleton UI while waiting for table data
  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={500} />;
  }

  // Show empty space if data hasn't yet been requested
  if (!learnerStats || !columns) {
    return <></>;
  }

  // Show empty table message if we know no data is available for this report
  if (learnerStats.length === 0) {
    return (
      <DataContainer>
        <div style={{ height: '100%', margin: '20px' }}>
          <p>No records found.</p>
        </div>
      </DataContainer>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <span style={{ marginRight: '20px' }}>
          <MenuButton pending={isDownloading} text={t('Download')} click={handleCsvButtonClick} hideArrow={true} />
        </span>
      </div>
      <DataContainer>
        <DataTable>
          <thead style={{ backgroundColor: Color.grayTransparent }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ padding: '2px 20px' }}
                    onClick={handleHeaderClick}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row: any) => (
              <tr
                key={row.id}
                style={{
                  borderTop: '1px solid rgb(224, 224, 224, 1)',
                  borderBottom: '1px solid rgb(224, 224, 224, 1)',
                }}
              >
                {row.getVisibleCells().map((cell: any) => (
                  <td
                    key={cell.id}
                    style={{
                      padding: assignment?.goalType === 'assessment' ? '20px' : '10px 20px',
                      textAlign: ['assessmentScore', 'percentStudied', 'percentReviewed'].includes(cell.column.id)
                        ? 'right'
                        : 'left',
                      color: Color.reportGray,
                      fontSize: '14px',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </DataTable>

        <Pagination
          totalCount={totalCount}
          page={page}
          pageSize={pageSize}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </DataContainer>
    </>
  );
};

const getAllLearnerStats = async (courseId: string, setId: string, groupFilter: string | null): Promise<Array<any>> => {
  let learnerStats: Array<any> = [];
  if (setId === 'All Assignments') {
    setId = '';
  }

  const page = 1;
  const pageSize = 200;

  const res = await getLearnerStats('key', courseId, '', 'asc', page, pageSize, '', groupFilter ?? '', setId);
  if (!res) {
    throw new Error('Failed to fetch learner stats.');
  }
  const totalPages = res.data.meta['total-pages'];
  const resData: any = normalizeJSONAPIResponse(res.data);
  learnerStats.push(...resData);

  for (let i = 2; i <= totalPages; i++) {
    const res = await getLearnerStats('key', courseId, '', 'asc', i, pageSize, '', groupFilter ?? '', setId);
    if (!res) {
      throw new Error('Failed to fetch learner stats.');
    }
    const resData: any = normalizeJSONAPIResponse(res.data);
    learnerStats.push(...resData);
  }

  return learnerStats;
};

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.54);
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 520px;
  overflow: auto;
`;

const DataTable = styled.table`
  border-collapse: collapse;
  border: 1px solid ${Color.grayTransparent};
  text-align: left;
  width: 100%;
  color: ${Color.primaryBlack};
  font-size: 1em;
  font-weight: 400;
`;
