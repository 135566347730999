import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Color, Size } from 'core';
import { PrimaryButton } from 'components/buttons/v4';
import { TitleText } from 'learn/components/TitleText';

interface IRetryProps extends WithTranslation {
  open?: any;
  totalCount: number;
  totalCorrect: number;
  retryClicked: () => void;
}

const CardHeader = styled.div`
  background-color: #d6f3f7;
  height: 42px;
  display: flex;
  align-items: center;
`;

const Text = styled.h3`
  font-size: 1.25em;
  color: #2e353f;
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
`;

const RetryCardContainer = styled.div`
  max-width: ${Size.defaultCardWidth}px;
  min-height: 200px;
  margin: 25px auto 0px auto;
`;

const RetryButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CardContainer = styled.div`
  max-width: ${Size.defaultCardWidth}px;
  min-height: 200px;
  border-radius: 4px;
  border: solid 1px ${Color.veryLightBlue};
  background-color: white;
`;

const CardContent = styled.div`
  min-height: 158px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

class RetrySummaryPage extends React.Component<IRetryProps> {
  public render() {
    const { totalCount, totalCorrect, t } = this.props;
    const numberCorrectText = `You got ${totalCorrect} out of ${totalCount} correct.`;
    const retryCount = totalCount - totalCorrect;
    return (
      <div style={{ paddingTop: '25px', paddingBottom: '20px' }}>
        <RetryCardContainer>
          <CardContainer>
            <CardHeader>
              <TitleText
                text={t('REVIEW IT')}
                name={'card-title'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '2px',
                  marginLeft: '25px'
                }}
              />
            </CardHeader>
            <CardContent>
              <Text>{t('Retry Summary total correct', { totalCorrect, totalCount })}</Text>
              <Text>{t("Make it stick! Review the ones you missed so you'll remember them better later.")}</Text>
            </CardContent>
          </CardContainer>
        </RetryCardContainer>
        <RetryButtonContainer>
          <PrimaryButton click={this.buttonClicked.bind(this)}>
            {t('Retry Summary Button', { count: retryCount })}
          </PrimaryButton>
        </RetryButtonContainer>
      </div>
    );
  }

  private buttonClicked = () => {
    this.props.retryClicked();
  };
}

export default withTranslation()(RetrySummaryPage);
