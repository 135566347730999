import { Color } from 'core';
import SanitizedHtml from 'learn/components/SanitizedHtml';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type StyleProps = {
  rightToLeft?: boolean;
  correct?: boolean;
};

const Confirmation = styled.div<StyleProps>`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 710px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: solid 1px ${Color.veryLightBlue};
  background-color: ${(props) => (props.correct ? Color.successGreen : Color.incorrectRed)};
`;

const Header = styled.h4<StyleProps>`
  font-size: 18px;
  color: ${Color.lightBlack};
  width: 100%;
  margin-left: ${(props) => (props.rightToLeft ? '-25px' : '25px')};
  margin-bottom: 0px;
  text-align: ${(props) => (props.rightToLeft ? 'right' : 'left')};
`;

const Subtitle = styled.h3`
  font-size: 14px;
  color: ${Color.lightBlack};
  width: 100%;
  margin: 0px 25px 5px 25px;
  text-align: left;
`;

interface Props {
  correct: boolean;
  subtitle: string;
  style?: React.CSSProperties;
  rightToLeft?: boolean;
  children?: React.ReactNode;
}

export const ConfirmationText: React.FC<Props> = ({ correct, subtitle, style = {}, rightToLeft, children }) => {
  const { t } = useTranslation();

  return (
    <Confirmation style={style} correct={correct}>
      <Header rightToLeft={rightToLeft}>{correct ? t("Why it's right:") : t("Why it's incorrect:")}</Header>
      <Subtitle>
        <SanitizedHtml style={{ marginRight: '50px' }} source={subtitle} rightToLeft={rightToLeft} />
      </Subtitle>
      {children}
    </Confirmation>
  );
};
