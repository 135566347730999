import { Size } from 'core';
import { ConfirmationText } from 'learn/components/ConfirmationText';
import { SequenceTabs } from 'learn/components/SequenceTabs';
import { IQuizPart } from 'models/quiz';
import React, { FC } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  correctCount: number;
  items: IQuizPart[];
  rightToLeft: boolean;
  selectedTab: 'Your Answer' | 'Correct Answer';
  showUserAnswers: () => void;
  showCorrectOrderItems: () => void;
  flipKey: string;
  renderSequenceItems: (isDraggable: boolean, displayItems: IQuizPart[]) => JSX.Element[];
  displayItems: IQuizPart[];
}

export const IncorrectSequenceBody: FC<Props> = ({
  correctCount,
  items,
  rightToLeft,
  selectedTab,
  showUserAnswers,
  showCorrectOrderItems,
  flipKey,
  renderSequenceItems,
  displayItems,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ConfirmationText
        correct={false}
        subtitle={t('Sequence Quiz Confirmation Text Subtitle', {
          correctCount,
          totalCount: items.length,
        })}
        rightToLeft={rightToLeft}
      >
        <SequenceTabs
          rightToLeft={rightToLeft}
          selectedTab={selectedTab}
          onUserAnswerClick={showUserAnswers}
          onCorrectAnswerClick={showCorrectOrderItems}
        />
      </ConfirmationText>
      <List>
        <Flipper flipKey={flipKey}>{renderSequenceItems(false, displayItems)}</Flipper>
        <div />
      </List>
    </>
  );
};

const List = styled.ul`
  margin: 0px auto;
  max-width: ${Size.defaultCardWidth}px;
  position: relative;
  padding: 0px;
`;
