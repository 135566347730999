import { faQuestionCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { MemreFlexBox } from 'components/core';
import * as colours from 'core/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const HELP_TEXT = 'Learn More';

export default function LearnMore({ children, width = 400 }) {
  const { t } = useTranslation();
  const helpText = t(HELP_TEXT);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Typography onClick={handleOpen} variant="caption" sx={{ color: colours.primaryBlue, mr: 1 }}>
        <MemreFlexBox direction="row" gap={0.5} align="center">
          {helpText} <FontAwesomeIcon icon={faQuestionCircle} />
        </MemreFlexBox>
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width,
            bgcolor: 'background.paper',
            boxShadow: 24,
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            p: 3,
          }}
        >
          <Box sx={{ position: 'absolute', top: 3, right: 3 }}>
            <IconButton onClick={handleClose} aria-label="close">
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Modal>
    </>
  );
}
