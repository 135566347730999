import TextField from '@material-ui/core/TextField';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import { useIcEditorStore } from 'components/item-editor/instructional-content/store';
import * as Color from 'core/colors';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TAnnotation } from 'types';
import { createImage } from 'utils/createImage';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { updateImage } from 'utils/updateImage';

interface ImageAnnotationProps {
  annotation: TAnnotation;
}

export const ImageAnnotation = (props: ImageAnnotationProps) => {
  const { annotation } = props;
  const { updateIcAnnotation } = useIcEditorStore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [altTag, setAltTag] = useState(annotation.image.altTag || '');
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageLoaded(false);

    try {
      const file = event.target.files?.[0];
      if (file) {
        const imageId = (await createImage(file)).id;

        const updateAnnotationResponse = await axios.put(`/api/v3/annotations/${annotation.id}`, {
          include: 'image,document,sound,video',
          data: {
            relationships: {
              image: {
                data: {
                  id: imageId,
                },
              },
            },
          },
        });

        const updatedAnnotation = normalizeJSONAPIResponse(updateAnnotationResponse.data);
        updateIcAnnotation(annotation.id, { ...updatedAnnotation });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setImageLoaded(true);
    }
  };

  useEffect(() => {
    if (altTag !== annotation.image.altTag) {
      const delayDebounceFn = setTimeout(async () => {
        const updatedImage = await updateImage({
          id: annotation.image.id,
          payload: {
            data: {
              id: annotation.image.id,
              type: 'images',
              attributes: {
                'alt-tag': altTag,
              },
            },
          },
        });
        updateIcAnnotation(annotation.id, { image: updatedImage });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [altTag, annotation.id]);

  const handleAltTagInputChange = (event: any) => {
    setAltTag(event.target.value);
  };

  return (
    <>
      <div style={{ position: 'relative', paddingBottom: '56.25%', marginBottom: '16px' }}>
        {!imageLoaded && (
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <Skeleton variant="rect" style={{ height: '100%' }} />
          </div>
        )}
        {annotation.image.url && (
          <img
            src={annotation.image.url}
            alt={annotation.image.altTag || 'Annotation Image'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              border: `1px solid ${Color.lightGray}`,
              borderRadius: '4px',
              cursor: 'pointer',
              opacity: imageLoaded ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
            onClick={handleImageClick}
            onLoad={handleImageLoad}
          />
        )}
        {!annotation.image.url && <ImagePlaceholder />}
      </div>
      <TextField
        id={`image-annotation-alt-tag-${annotation.id}`}
        label="Alt Tag"
        value={altTag}
        onChange={handleAltTagInputChange}
      />
      <FileInput ref={fileInputRef} type="file" accept="image/*" onChange={handleImageUpload} />
    </>
  );
};

const FileInput = styled.input`
  display: none;
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  background-color: ${Color.lightGray};
  border-radius: 4px;
`;
