import axios from 'axios';
import { useMutation } from 'react-query';
import useStore from 'zstore';

const updatePartner = ({ id, payload }) => {
  return axios.put(`/api/v3/partners/${id}`, payload);
};

export const useMutatePartner = () => {
  const { makeToast } = useStore();

  return useMutation(updatePartner, {
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
