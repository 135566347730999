import { SkipToMainButton } from 'components/buttons/SkipToMainButton';
import 'components/index.css';
import { Modal } from 'components/Modal';
import { FreeTrialExpired } from 'components/modals/FreeTrialExpired';
import { IUser } from 'models/user';
import moment from 'moment';
import { nativeAppLauncherPath } from 'pages/registration/UnauthenticatedPageContainer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps, useLocation } from 'react-router';
import { getCurrentUser, handleGetProfile } from 'store/profile';
import { handleCreateSession } from 'store/session';
import { HttpStatusCodes } from 'utils/httpStatusCodes';
import { getBaseAngularUrl, isLoggedInPath, queryParams, redirectToSignin } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';

interface AppProps extends RouteProps {}

export const App: React.FC<AppProps> = () => {
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const error = useSelector(
    (state: any) => state.data.error && state.data.error.status === HttpStatusCodes.Unauthorized
  );

  useEffect(() => {
    const getProfile = async () => {
      const params = queryParams(window.location);

      // If this is the native app launcher then we don't want to use up the temp_login_token
      // because it will be used in the native app
      const isNativeAppLauncher = location.pathname === nativeAppLauncherPath;
      if (params.lti || (params.temp_login_token && !isNativeAppLauncher)) {
        const tempLoginToken = queryParams(window.location).temp_login_token;
        await dispatch(handleCreateSession({ temp_login_token: tempLoginToken }));
      }
      if (isLoggedInPath(window.location.pathname)) {
        dispatch(handleGetProfile());
      }
    };
    getProfile();
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setupSegment(currentUser);
      handleV4Nav(currentUser);
      handlePartnerExpiry(currentUser);
    }

    if (error && isLoggedInPath(window.location.pathname)) {
      handleAuthError();
    }
  }, [currentUser, error]);

  const setupSegment = (user: IUser) => {
    if (!analyticsInitialized) {
      AnalyticsService.getInstance().initialize(user);
      setAnalyticsInitialized(true);
    }
  };

  const handleV4Nav = (user: IUser) => {
    const useV4Nav = user.primaryPartner?.partnerSettings?.showNewNav || false;
    document.cookie = `use_v4_nav=${useV4Nav}; max-age=${60 * 60 * 24 * 365}; path=/;secure;`;
    if (!useV4Nav && window.location.pathname.startsWith('/app/nav')) {
      window.location.href = `${getBaseAngularUrl()}/dashboard?partnerId=${user.primaryPartner.id}`;
    }
  };

  const handlePartnerExpiry = (user: IUser) => {
    const expiryDate = user.primaryPartner?.expiresAt;
    const isExpiredPartner = expiryDate && moment(expiryDate).isBefore(moment());
    if (
      isExpiredPartner &&
      (window.location.pathname.startsWith('/app/nav/courses') ||
        window.location.pathname.startsWith('/app/nav/partners'))
    ) {
      setShowExpiredModal(true);
    }
  };

  const handleAuthError = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const returnUrl = isDevelopment ? process.env.APP_HOST || 'http://localhost:3000' : '';
    if (queryParams(window.location).lti) {
      setTimeout(() => {
        window.location.href = `${returnUrl}/500`;
      }, 1000);
    } else if (isDevelopment) {
      window.location.href = '/app/signin';
    } else {
      redirectToSignin();
    }
  };

  return (
    <div>
      <SkipToMainButton />
      <Modal show={showExpiredModal} element={<FreeTrialExpired />} />
    </div>
  );
};

export default App;
