import { Color, Size } from 'core';
import { isRightToLeft } from 'i18n';
import AudioPlayer from 'learn/components/AudioPlayer';
import { TitleText } from 'learn/components/TitleText';
import ZoomableImageContainer from 'learn/components/ZoomableImageContainer';
import { IImage } from 'models/image';
import { IQuiz } from 'models/quiz';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getTitleTextKey } from 'utils/quizUtils';

interface IQuizCardProps extends WithTranslation {
  quiz?: IQuiz;
  goalType: string;
  image?: IImage;
}

const CardContainer = styled.div`
  max-width: ${Size.defaultCardWidth}px;
  min-height: 200px;
  border-radius: 4px;
  border: solid 1px ${Color.veryLightBlue};
  background-color: white;
`;

const CardHeader = styled.div`
  background-color: #d6f3f7;
  height: 42px;
  display: flex;
  align-items: center;
`;

const SoundContainer = styled.div`
  margin-left: 25px;
`;

const CardContent = styled.div`
  min-height: 158px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const QuestionHeading = styled.h3`
  font-size: 1.25em;
  color: #2e353f;
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
`;

const QuestionBody = styled.h3`
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
`;

const QuizCardImageContainer = styled.div`
  display: flex;
  justify-content: center;

  & img {
    max-height: 200px;
    max-width: 660px;
    margin: 0px auto;
    margin: 25px;
    max-height: 450px;
  }
`;

class QuizCard extends React.Component<IQuizCardProps> {
  private rightToLeft: boolean;
  constructor(props) {
    super(props);
    this.rightToLeft = isRightToLeft();
    this.state = { isModalOpen: false };
  }

  public render() {
    const { quiz, t } = this.props;
    return (
      <CardContainer>
        <CardHeader
          style={{
            justifyContent: this.rightToLeft ? 'flex-end' : 'flex-start',
            paddingRight: this.rightToLeft ? '15px' : '0px'
          }}
        >
          <TitleText
            text={t(getTitleTextKey(this.props.goalType))}
            name={'card-title'}
            style={{
              fontSize: '13px',
              letterSpacing: '2px',
              marginLeft: '25px',
              textAlign: this.rightToLeft ? 'right' : 'left'
            }}
          />
        </CardHeader>
        <CardContent>
          <div style={{ flexDirection: 'row', width: '100%' }}>
            <QuestionHeading style={{ flexDirection: this.rightToLeft ? 'row-reverse' : 'row' }}>
              {quiz ? quiz.questionHeading || '' : ''}
            </QuestionHeading>
            {quiz && quiz.questionBodyText && (
              <QuestionBody style={{ textAlign: this.rightToLeft ? 'right' : 'left' }}>
                {quiz.questionBodyText}
              </QuestionBody>
            )}

            {quiz && quiz.questionAudio && (
              <SoundContainer>
                <AudioPlayer src={quiz.questionAudio.url} />
              </SoundContainer>
            )}
            {quiz && quiz.questionImage && (
              <QuizCardImageContainer data-type="card-image-container">
                <ZoomableImageContainer image={quiz.questionImage} transcodedSize={'large'} />
              </QuizCardImageContainer>
            )}
          </div>
        </CardContent>
      </CardContainer>
    );
  }
}

export default withTranslation()(QuizCard);
