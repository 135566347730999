import axios from 'axios';
import LoadingComponent from 'components/LoadingComponent';
import { ReadinessBadge } from 'components/ReadinessBadge';
import { ReadinessTooltip } from 'components/ReadinessTooltip';
import { Table } from 'components/tables/Table';
import { ContentSection } from 'core/layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useUserCourses } from 'hooks/useUserCourses';
import { ICourse } from 'models/course';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

interface IReadinessTable {
  userId: string;
}

interface IReadinessTableRow {
  id: string;
  name?: string;
  readiness?: number;
  courseId?: number;
  setId?: number;
  type?: 'sets' | 'courses';
}

export const ReadinessTable = (props: IReadinessTable) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const partner = currentUser?.primaryPartner;
  const isOwnProfile = currentUser?.id === props.userId;
  const { data: userCourses, isLoading } = useUserCourses(partner?.id, props.userId, isOwnProfile);
  const [tableData, setTableData] = useState<any[]>([]);
  const [sets, setSets] = useState<any[]>([]);
  // material table UI jumps if no child data on the first load, so flag whether there is readiness (child data)
  const [readinessLoaded, setReadinessLoaded] = useState(false);

  useEffect(() => {
    if (userCourses) {
      setTableData(processTableContent(userCourses, []));
      if (userCourses.length > 0) {
        for (const course of userCourses) {
          getReadiness(course.id);
        }
      } else {
        setReadinessLoaded(true);
      }
    }
  }, [userCourses]);

  useEffect(() => {
    if (userCourses) {
      setTableData(processTableContent(userCourses, sets));
      if (!readinessLoaded) {
        setReadinessLoaded(true);
      }
    }
  }, [sets]);

  const getReadiness = async (courseId) => {
    const endpoint: string = `/api/v4/readiness?user_id=${
      props.userId
      }&course_id=${courseId}&is_cerego_commercial=${true}`;
    const response = await axios.get(endpoint);
    const res = normalizeJSONAPIResponse(response.data);
    const course = userCourses.find((c) => c.id === courseId);
    course.readiness = res.readiness;
    course.sets = normalizeJSONAPIResponse(res.sets);
    setSets((sets) => sets.concat(course.sets));
  };

  const formatUserCourses = (courses: [ICourse]) => {
    return courses.map((course) => ({
      type: course.type,
      id: +course.id,
      name: course.name,
      readiness: course.readiness,
    }));
  };

  const processTableContent = (courses: any, courseSets: any) => {
    const tableContent = formatUserCourses(courses).concat(courseSets);
    return tableContent;
  };

  const renderNameCell = (rowData: any) => {
    switch (rowData.type) {
      case 'coursesReadiness':
      case 'courses':
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ fontSize: '16px' }} key={`Course ${rowData.id}`}>
              {rowData.name}
            </p>
          </span>
        );
      case 'setsReadiness':
        return (
          <span>
            <p key={`Set ${rowData.id}`} style={{ paddingLeft: '40px' }}>
              <li>{rowData.name}</li>
            </p>
          </span>
        );
      default:
        throw new Error(`Unrecognized data type: ${rowData.type}`);
    }
  };

  const renderReadinessCell = (rowData: IReadinessTableRow) => {
    return <ReadinessBadge readinessScore={rowData.readiness} />;
  };

  const getNameColumn = () => {
    return {
      title: t('Course'),
      field: 'name',
      cellStyle: {
        width: '500px',
      },
      render: (rowData: any) => renderNameCell(rowData),
    };
  };

  const getReadinessColumn = () => {
    return {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t('Readiness')}
          <ReadinessTooltip />
        </div>
      ),
      field: 'readiness',
      cellStyle: {
        width: '500px',
      },
      render: (rowData: any) => renderReadinessCell(rowData),
    };
  };

  const getColumns = () => {
    return [getNameColumn(), getReadinessColumn()];
  };

  if (isLoading || !readinessLoaded) {
    return <LoadingComponent isLoading={true} />;
  }

  return (
    <ContentSection>
      <Table
        columns={getColumns()}
        data={tableData}
        toolbar={() => {
          return <span />;
        }}
        filterValue=""
        disableSearch={true}
        enablePagination={false}
        emptyValue={t('This user is not enrolled in any available courses for the current partner.')}
        parentChildData={(row, rows) => rows.find((a) => a.id === row.courseId)}
        headerFontSize={'14px'}
      />
    </ContentSection>
  );
};
