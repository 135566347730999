import styled from 'styled-components';
import { Color } from 'core/index';

export const CenteredButtonText = styled.span`
  position: relative;
  padding-top: 0.11em;
  line-height: 1em;
`;

export const ErrorMessage = styled.h4`
  color: ${Color.red};
  font-size: 14px;
  margin: 0;
  padding-top: 0;
`;
