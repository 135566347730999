import React from 'react';
import { Color } from 'core';

interface IErrorMessage {
  error: string;
}

const ErrorMessage = (props: IErrorMessage) => {
  if (props.error) {
    return (
      <div
        style={{
          backgroundColor: Color.reportRed,
          color: Color.white,
          borderRadius: '4px',
          padding: '1px 10px',
          margin: '10px 0'
        }}
      >
        <p>{props.error}</p>
      </div>
    );
  }

  return <></>;
};

export default ErrorMessage;
