import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import styled from 'styled-components';

interface AddMembersModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddMembers: (identifiers: string[], addBy: 'email' | 'memberId') => void;
  memberIdEnabled: boolean;
  memberIdSlug?: string;
}

export const AddGroupMembersModal = ({
  isOpen,
  onClose,
  onAddMembers,
  memberIdEnabled,
  memberIdSlug,
}: AddMembersModalProps) => {
  const [identifiersInput, setIdentifiersInput] = useState('');
  const [error, setError] = useState('');
  const [addBy, setAddBy] = useState<'email' | 'memberId'>('email');

  const handleIdentifiersChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIdentifiersInput(e.target.value);
  };

  const handleAddByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddBy(e.target.value as 'email' | 'memberId');
    setIdentifiersInput('');
    setError('');
  };

  const handleAddMembersSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const identifiers = identifiersInput
      .split(',')
      .map((id) => id.trim())
      .filter((id) => id.length > 0);

    if (identifiers.length === 0) {
      setError(`Please enter at least one ${addBy === 'email' ? 'email address' : memberIdSlug}.`);
      return;
    }

    if (addBy === 'email') {
      // Validate emails
      const invalidEmails = identifiers.filter((email) => !/^\S+@\S+\.\S+$/.test(email));
      if (invalidEmails.length > 0) {
        setError(`Invalid email addresses: ${invalidEmails.join(', ')}`);
        return;
      }
    } else {
      // Additional validation for member IDs can be added here if necessary
    }

    setError('');
    onAddMembers(identifiers, addBy);
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <MemreFlexBox gap={1} direction="column" align="start" justify="space-between" sx={{ mb: 2, width: '100%' }}>
          <MemreText variant="h5" sx={{ mb: 2 }}>
            Add Members
          </MemreText>
          <form onSubmit={handleAddMembersSubmit} style={{ width: '100%' }}>
            {memberIdEnabled && (
              <FormGroup>
                <Label>Add by:</Label>
                <RadioGroup>
                  <label>
                    <input type="radio" value="email" checked={addBy === 'email'} onChange={handleAddByChange} />
                    Email
                  </label>
                  <label>
                    <input type="radio" value="memberId" checked={addBy === 'memberId'} onChange={handleAddByChange} />
                    {memberIdSlug}
                  </label>
                </RadioGroup>
              </FormGroup>
            )}
            <TextField
              label={addBy === 'email' ? 'Email Addresses (comma-separated)' : `${memberIdSlug}s (comma-separated)`}
              color="secondary"
              value={identifiersInput}
              onChange={handleIdentifiersChange}
              variant="outlined"
              size="small"
              minRows={3}
              multiline
              fullWidth
              required
            />
            {error && <ErrorText>{error}</ErrorText>}
            <ModalButtons>
              <MemreButton
                text="Add Members"
                onClick={handleAddMembersSubmit}
                color="secondary"
                variant="contained"
                iconName="plus"
                size="medium"
              />
              <MemreButton
                text="Cancel"
                onClick={onClose}
                color="secondary"
                variant="outlined"
                size="medium"
              />
            </ModalButtons>
          </form>
        </MemreFlexBox>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  margin-top: 5px;
  margin-bottom: 15px;
`;

const ErrorText = styled.p`
  color: red;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 1rem;
`;

const ModalButton = styled.button`
  padding: 8px 12px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 5px;

  label {
    display: flex;
    align-items: center;
  }
`;
