import axios from 'axios';
import { TSound } from 'types';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

export const createSound = async (sound: File): Promise<TSound> => {
  const uploadData = new FormData();
  uploadData.append('file', sound);
  const response = await axios.post('/api/v3/sounds', uploadData);
  const soundData = normalizeJSONAPIResponse(response.data);

  return soundData;
};
