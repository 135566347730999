import { FormControl, InputLabel, MenuItem, Select as MuiSelect, TextField as MuiTextField } from '@mui/material';
import { MemreButton, MemreFlexBox } from 'components/core';
import { PartnerUserTagTypesSelectMenu } from 'pages/admin-dashboard/groups/PartnerUserTagTypesSelectMenu';
import React from 'react';

interface PartnerUserTagType {
  id: string;
  label: string;
}

export interface Rule {
  id: string;
  tagTypeId: string;
  tagTypeLabel: string;
  operator: '=' | '!=';
  value: string;
}

export const RuleEditor: React.FC<{
  rule: Rule;
  partnerUserTagTypes: PartnerUserTagType[];
  onRuleChange: (id: string, updatedFields: Partial<Rule>) => void;
  onRemoveRule: (id: string) => void;
}> = ({ rule, partnerUserTagTypes, onRuleChange, onRemoveRule }) => {
  return (
    <MemreFlexBox direction="row" align="center" gap={2}>
      <PartnerUserTagTypesSelectMenu
        value={rule.tagTypeId || ''}
        onSelect={(tagTypeId) => {
          const tagType = partnerUserTagTypes.find((tt) => tt.id === tagTypeId);
          if (tagType) {
            onRuleChange(rule.id, { tagTypeId: tagType.id, tagTypeLabel: tagType.label });
          } else {
            onRuleChange(rule.id, { tagTypeId: '', tagTypeLabel: '' });
          }
        }}
      />

      <FormControl variant="outlined" size="small">
        <InputLabel color="secondary">Operator</InputLabel>
        <MuiSelect
          label="Operator"
          value={rule.operator}
          onChange={(e) => onRuleChange(rule.id, { operator: e.target.value as '=' | '!=' })}
          color="secondary"
          size="medium"
        >
          <MenuItem value="=">is</MenuItem>
          <MenuItem value="!=">is not</MenuItem>
        </MuiSelect>
      </FormControl>

      <MuiTextField
        variant="outlined"
        size="medium"
        color="secondary"
        value={rule.value}
        onChange={(e) => onRuleChange(rule.id, { value: e.target.value })}
        placeholder="Value"
      />

      <MemreButton
        text="Remove"
        variant="outlined"
        color="error"
        size="medium"
        iconName="trash"
        onClick={() => onRemoveRule(rule.id)}
      />
    </MemreFlexBox>
  );
};
