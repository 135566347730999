import slashes from 'assets/images/repeating-slashes.png';
import { PrimaryButton } from 'components/buttons/v4';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import styled from 'styled-components';

const Banner = styled.div`
  width: 100%;
  background-image: url("${slashes}");
  background-color: orangered;
  color: white;
  display: flex;
  padding: 0.7rem 1rem;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0.3125em 0.625em 0 rgba(0, 0, 0, 0.2);
`;

const MainMessaging = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 3;
  h3 {
    color: white;
    margin: 0;
  }
  p {
    margin: 0;
  }
`;

const CountdownMessaging = styled.span`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: auto;
  flex-grow: 0;
  span {
    text-transform: uppercase;
    text-align: center;
    flex-basis: auto;
    flex-grow: 0;
  }
  .automatic-upgrade {
    flex: 2;
    font-size: 0.6rem;
  }
  .countdown {
    flex: 2;
    font-size: 1.2rem;
    font-weight: 900;
    margin-right: 5px;
  }
  .or {
    flex: 1;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    font-size: 0.6rem;
    padding: 0.2rem 0.5rem;
    margin-right: 5px;
  }
`;

const UpgradeCTA = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-grow: 0;
  margin-right: 25px;
  margin-left: 10px;
  button {
    margin: 0 auto;
    border: 2px solid white;
  }
`;

interface IBillingAlertBanner {
  startDate: string;
  daysInGracePeriod: number;
  upgradeClick: () => void;
}

const BillingAlertBanner = (props: IBillingAlertBanner) => {
  const { t } = useTranslation();

  const handleUpgradeClick = (): void => {
    props.upgradeClick();
  };

  return (
    <Banner>
      <MainMessaging>
        <h3>{t("It's getting a little cramped in here.")}</h3>
        <p>{t('The number of seats allotted to your organization has been exceeded.')}</p>
      </MainMessaging>
      <CountdownMessaging>
        <span className="automatic-upgrade">{t('Automatic upgrade in')}</span>
        <span className="countdown">
          <Moment add={{ days: props.daysInGracePeriod }} fromNow ago>
            {props.startDate}
          </Moment>
        </span>
        <span className="or">{t('or')}</span>
      </CountdownMessaging>
      <UpgradeCTA>
        <PrimaryButton click={handleUpgradeClick}>{t('Upgrade Now')}</PrimaryButton>
      </UpgradeCTA>
    </Banner>
  );
};

export default BillingAlertBanner;
