import Typography from '@mui/material/Typography';
import { MemreDuration, MemreFlexBox, MemrePointers } from 'components/core';
import * as colours from 'core/colors';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import AreaHeading from './AreaHeading';
import LearnMore from './LearnMore';

export default function IdealDuration({ idealDuration }) {
  const [points, setPoints] = useState<string[]>([]);

  useEffect(() => {
    if (idealDuration) {
      setPoints([
        _.round(idealDuration / 2.5).toString(),
        _.round(idealDuration / 1.25).toString(),
        _.round(idealDuration * 1.2).toString(),
        _.round(idealDuration * 1.6).toString(),
      ]);
    }
  }, [idealDuration]);

  return (
    <MemreFlexBox direction="column" gap={1} sx={{ flexGrow: 1 }}>
      <MemreFlexBox justify="space-between" align="center">
        <AreaHeading overline="Ideal Duration" heading={`${idealDuration}mins`} />
        <LearnMore>
          <AreaHeading overline="Ideal Duration" heading={`${idealDuration}mins`} />
          <Typography sx={{ color: colours.darkGray, fontSize: 14 }}>
            Our analysis suggests that studying for around {idealDuration} minutes helps you retain more knowledge. This
            duration is based on general learning trends and will be refined as we learn more about your preferences.
          </Typography>
        </LearnMore>
      </MemreFlexBox>
      <MemreFlexBox direction="column">
        <MemreDuration duration={idealDuration} />
        <MemrePointers items={points} />
      </MemreFlexBox>
    </MemreFlexBox>
  );
}
