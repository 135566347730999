import { MemreSnackbar } from 'components/core';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import newLookMappings from './newLookMappings';
import { AnalyticsService } from 'utils/AnalyticsService';

type ToggleProps = {
  heading?: string;
  message?: string;
  cb?: () => void;
  learnMoreLink?: string;
};

export default function ToggleNewLook() {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  const [props, setProps] = useState<ToggleProps>({});

  useEffect(() => {
    const timer = setTimeout(() => {
      // The helper function now returns the learnMoreLink (if defined) from the mapping.
      const matchMapping = (
        mappings: typeof newLookMappings,
        currentPath: string
      ): { type: 'old' | 'new'; path: string; learnMoreLink?: string } | null => {
        const trimmedPath = currentPath.replace(/\/$/, '');

        for (const mapping of mappings) {
          const oldPathPattern = mapping.oldPath.replace(/\[(\w+)\]/g, '([^/]+)');
          const newPathPattern = mapping.newPath.replace(/\[(\w+)\]/g, '([^/]+)');

          const oldPathRegex = new RegExp(`^${oldPathPattern}$`);
          const newPathRegex = new RegExp(`^${newPathPattern}$`);

          const oldMatch = trimmedPath.match(oldPathRegex);
          const newMatch = trimmedPath.match(newPathRegex);

          if (oldMatch) {
            const tokens = oldMatch.slice(1);
            const newPath = mapping.newPath.replace(/\[(\w+)\]/g, () => tokens.shift() || '');
            return { type: 'old', path: newPath, learnMoreLink: mapping.learnMoreLink };
          }

          if (newMatch) {
            const tokens = newMatch.slice(1);
            const oldPath = mapping.oldPath.replace(/\[(\w+)\]/g, () => tokens.shift() || '');
            return { type: 'new', path: oldPath, learnMoreLink: mapping.learnMoreLink };
          }
        }
        return null;
      };

      const match = matchMapping(newLookMappings, path);

      if (match) {
        setProps({
          message:
            match.type === 'new'
              ? 'Looking for the classic experience? Click to go back'
              : 'Want to see the updated look? Click to try it out',
          heading: match.type === 'new' ? 'Updated Page Design' : 'New Page Design Available',
          cb: () => handleClick(match.path),
          learnMoreLink: match.learnMoreLink,
        });
      } else {
        setProps({});
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [path]);

  const handleClick = (url: string) => {
    const button_type = url.includes('v4') ? 'go to v4 dashboard' : 'go to legacy dashboard';
    AnalyticsService.getInstance().track('button_clicked', { button_type });
    history.push(url);
  };

  return (
    <MemreSnackbar message={props.message} heading={props.heading} cb={props.cb} learnMoreLink={props.learnMoreLink} />
  );
}
