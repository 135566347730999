import { useState } from 'react';

export function SiteAdminUserDetailCourses(props: any) {
  const { user } = props;
  const [showCourses, setShowCourses] = useState(false);

  return (
    <>
      <h2>
        <a
          href="#"
          className="alt-button"
          onClick={(e) => {
            e.preventDefault();
            setShowCourses(!showCourses);
          }}
        >
          <span>
            {showCourses ? '-' : '+'} Courses ({user.courseUsers.length})
          </span>
        </a>
      </h2>
      {showCourses && (
        <article>
          <div>
            <table style={{ textAlign: 'left' }}>
              <thead>
                <tr>
                  <th>Course ID</th>
                  <th>Course Name</th>
                  <th>User Status</th>
                  <th>Partner</th>
                  <th>Type</th>
                  <th>Payment Required?</th>
                  <th>Enrolled</th>
                  <th>Enrollment Method</th>
                  <th>Unenrolled</th>
                </tr>
              </thead>
              <tbody>
                {user.courseUsers.map((courseUser) => (
                  <tr key={courseUser.id}>
                    <td>
                      <a href={`/app/nav/courses/${courseUser.courseId}`} target="_blank" rel="noopener noreferrer">
                        {courseUser.courseId}
                      </a>
                    </td>
                    <td>{courseUser.course?.name}</td>
                    <td>{courseUser.isAdmin ? 'Instructor' : 'Student'}</td>
                    <td>{courseUser.course?.partner.name}</td>
                    <td>{courseUser.course?.partner.accountType}</td>
                    <td>{courseUser.paymentRequired ? 'Yes' : 'No'}</td>
                    <td>{courseUser.createdAt}</td>
                    <td>{courseUser.enrolledVia}</td>
                    <td>{courseUser.deletedAt || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      )}
    </>
  );
}
