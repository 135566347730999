import qs from 'query-string';

export function redirectToSignin() {
  const signInSuccessPath = window.parent.location.pathname + window.parent.location.search;
  window.parent.location.href = `/signin?signInSuccessPath=${encodeURIComponent(signInSuccessPath)}`;
}

export function isLoggedInPath(pathname) {
  const loggedOutPathPrefixes = ['/app/signin', '/app/lti_user_confirm', '/app/join/'];
  return !loggedOutPathPrefixes.some((prefix) => pathname.startsWith(prefix));
}

export function redirectToV3(path) {
  let port = '3000';
  if (path?.includes('/app/nav')) {
    port = '3002';
  }
  let returnUrl = process.env.NODE_ENV === 'development' ? process.env.APP_HOST || `http://localhost:${port}` : '';

  if (path) {
    // Route to path
    returnUrl += path;
  } else if (document.referrer && removeQueryString(document.referrer) !== removeQueryString(window.location.href)) {
    // Bounce
    returnUrl = document.referrer;
  } else {
    // Route to V3 Dashboard
    returnUrl += '/dashboard';
  }

  window.location.href = returnUrl;
}

function removeQueryString(url) {
  return url.split('?')[0];
}

export function addQueryString(queryString, location) {
  const existingQueryStrings = location?.search;
  return existingQueryStrings ? `${existingQueryStrings}&${queryString}` : `?${queryString}`;
}

export function queryParams(location) {
  const queryString = (location && location.search) || '';
  return qs.parse(queryString, { ignoreQueryPrefix: true, parseBooleans: true });
}

export function locationState(location) {
  return (location && location.state) || {};
}

export function getBaseAngularUrl() {
  const developmentUrl = process.env.APP_HOST || 'http://localhost:3000';
  return process.env.NODE_ENV === 'development' ? developmentUrl : `${window.location.origin}`;
}

export function getBaseReactUrl() {
  const developmentUrl = process.env.APP_HOST || 'http://localhost:3002';
  return process.env.NODE_ENV === 'development' ? developmentUrl : `${window.location.origin}`;
}

export function getEditSetUrl(learnVersion: number, setId: string, itemId: string = '') {
  const queryParameters = getReturnURLQueryParameter();
  const baseUrl: string = `${getBaseAngularUrl()}/${learnVersion === 4 ? 'edit' : 'create'}/sets/${setId}`;
  if (itemId) {
    return baseUrl.concat(`/items/${itemId}/edit?${queryParameters}`);
  }
  return `${baseUrl}/edit?${queryParameters}`;
}

function getBrowserWindowPath(): string {
  let isV3Embed: boolean;
  if (process.env.NODE_ENV === 'development') {
    // This prevents cross origin browser errors in development, since in dev we have different ports for angular and react.
    // Note that this will result in some strange behavior when actually embedded in V3 in dev,
    // but it at least lets things load.
    isV3Embed = false;
  } else {
    isV3Embed = window.parent.location.pathname.split('/')[1] !== 'app';
  }
  const browserWindowPath = isV3Embed ? window.parent.location.pathname : window.location.pathname;
  return browserWindowPath;
}

export function getPreviewSetUrl(learnVersion: number, setId: string) {
  const queryParameters = `preview=true&studyType=all&${getReturnURLQueryParameter()}`;

  let url = '';
  if (learnVersion === 4) {
    url = `${getBaseReactUrl()}/app/learn/sets/${setId}?${queryParameters}`;
  } else if (learnVersion === 3) {
    url = `${getBaseAngularUrl()}/sets/${setId}/learn/quiz?${queryParameters}`;
  } else {
    throw new Error('Not a valid learn version');
  }

  return url;
}

export function getLearnSetUrl(learnVersion: number, setId: string) {
  const queryParameters = `studyType=all&${getReturnURLQueryParameter()}`;

  if (learnVersion === 4) {
    return `${getBaseReactUrl()}/app/sets/${setId}/journey?${queryParameters}`;
  } else if (learnVersion === 3) {
    return `${getBaseAngularUrl()}/sets/${setId}/learn/quiz?${queryParameters}`;
  } else {
    throw new Error('Not a valid learn version');
  }
}

function getReturnURLQueryParameter(): string {
  return `returnUrl=${encodeURIComponent(getBrowserWindowPath())}`;
}
