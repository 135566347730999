import axios from 'axios';
import normalize from 'json-api-normalizer';
import * as _ from 'lodash';
import build from 'redux-object';

export const API_DATA_REQUEST = 'API_DATA_REQUEST';
export const API_DATA_SUCCESS = 'API_DATA_SUCCESS';
export const API_DATA_FAILURE = 'API_DATA_FAILURE';
export const RESET_ERROR = 'RESET_ERROR';

const callApi = (apiParams: IAPIParams) => {
  const { endpoint, options, data, httpMethod } = apiParams;

  if (httpMethod === 'GET') {
    return axios.get(endpoint, options || {}).then(res => {
      return Object.assign({}, normalize(res.data, { endpoint }));
    });
  } else if (httpMethod === 'POST') {
    return axios.post(endpoint, data || {}, options || {}).then(res => {
      return Object.assign({}, normalize(res.data, { endpoint }));
    });
  } else if (httpMethod === 'DELETE') {
    return axios.delete(endpoint, options || {}).then(res => {
      return Object.assign({}, normalize(res.data, { endpoint }));
    });
  } else if (httpMethod === 'PUT') {
    return axios.put(endpoint, data || {}, options || {}).then(res => {
      return Object.assign({}, normalize(res.data, { endpoint }));
    });
  } else {
    throw new Error('Not a supported HTTP request method');
  }
};

interface IAPIParams {
  endpoint: string;
  httpMethod: 'GET' | 'POST' | 'DELETE' | 'PUT';
  data?: any;
  options?: any;
}

export const CALL_API = Symbol('Call API');

export default store => next => action => {
  const apiParams: IAPIParams = action[CALL_API];

  if (typeof apiParams === 'undefined') {
    return next(action);
  }

  const { endpoint } = apiParams;

  const accessTokens = build(store.getState().data, 'oauth2AccessTokens');
  const bearerToken = accessTokens && accessTokens[0] && accessTokens[0].token;
  if (bearerToken) {
    apiParams.options = _.merge(apiParams.options || {}, { headers: { Authorization: `Bearer ${bearerToken}` } });
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  next(actionWith({ type: API_DATA_REQUEST, endpoint }));

  return callApi(apiParams).then(
    response => next(actionWith({ response, type: API_DATA_SUCCESS, endpoint })),
    error =>
      next(
        actionWith({
          type: API_DATA_FAILURE,
          error: error.response,
          endpoint
        })
      )
  );
};
