import * as Plot from '@observablehq/plot';
import { MemreFlexBox, MemrePlot, MemreText } from 'components/core';
import { useEffect, useState } from 'react';
import DiamondSymbol from './assets/diamondSymbol';
import Gradient from './assets/Gradient';
import StripePattern from './assets/StripePattern';
import { COLOR, FILL, FONT, SHADOW, STROKE } from './constants';

const NEEDS_REVIEW_AT = 85;
const NEEDS_REVIEW_SIZE = 25;
const MAX_COLLECTION_SIZE = 4;
const GAP = 4;

const convertYAxis = (s: string): number => {
  const n = parseFloat(s);

  if (n >= 0 && n <= NEEDS_REVIEW_AT) {
    return (n / NEEDS_REVIEW_AT) * NEEDS_REVIEW_SIZE;
  } else if (n > NEEDS_REVIEW_AT && n <= 100) {
    return NEEDS_REVIEW_SIZE + ((n - NEEDS_REVIEW_AT) / (100 - NEEDS_REVIEW_AT)) * (100 - NEEDS_REVIEW_SIZE);
  } else {
    return 0;
  }
};

function combineDataByXY(data) {
  const combined = {};

  data.forEach((item) => {
    const key = `${item.x}-${item.y}`;

    if (!combined[key]) {
      combined[key] = { x: item.x, y: item.y, data: [] };
    }
    const itemData = {};
    Object.keys(item).forEach((k) => {
      if (k !== 'x' && k !== 'y') {
        itemData[k] = item[k];
      }
    });
    combined[key].data.push(itemData);
    combined[key].size = Math.min(MAX_COLLECTION_SIZE, combined[key].data.length);
  });

  return Object.values(combined);
}

const clampGenerator = (min: number, max: number) => (value: number) => Math.max(min, Math.min(max, value));

function generateGridPointFunction(gridSize: number) {
  return function getGridPoint(xValue: number, yValue: number): { x: number; y: number } {
    const snapToYGrid = (value: number) => Math.round(value / gridSize) * gridSize;
    const snapToXGrid = (value: number) => Math.round(value / (gridSize / 2)) * (gridSize / 2);

    const y = snapToYGrid(yValue);

    const isEvenRow = Math.round(y / gridSize) % 2 === 0;
    const adjustedSize = gridSize / 8;

    const rawXVal = snapToXGrid(xValue);
    const x = isEvenRow ? rawXVal + adjustedSize : rawXVal - adjustedSize;

    return { x, y };
  };
}

const diamondStyle = (size) => {
  const color = (d) => (d.y > NEEDS_REVIEW_SIZE ? FILL.DARK_BLUE : FILL.DARK_RED);
  const isSmall = size < 10000; // size < 10;

  const strokeWidth = isSmall ? 0 : STROKE.WIDTH.MEDIUM / 2;
  const fill = isSmall ? color : FILL.WHITE_BG;

  return {
    fill,
    stroke: (d) => (d.y > NEEDS_REVIEW_SIZE ? FILL.DARK_BLUE : STROKE.COLOR.RED),
    strokeWidth,
  };
};

export default function MemreBubblePlot({ data, median, topNumber }) {
  const [chartData, setChartData] = useState<any>(null);

  const linePatternId = 'bubble-plot-line-pattern';
  const gradientId = 'bubble-plot-gradient';

  const formatTooltip = (key) => {
    const ids = key.split('-');

    return (
      <>
        {ids.map((id, idx) => {
          const item = data.find((d) => d.id === Number(id));
          return item ? (
            <MemreFlexBox
              key={id}
              direction="column"
              gap={0.3}
              sx={{
                borderTop: idx ? '1px dotted #555' : 'none',
                width: '200px',
                mt: idx ? 1 : 0,
                mb: 0.5,
              }}
            >
              <MemreText
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '200px',
                  px: 0,
                  py: 0.3,
                }}
              >
                {item.name}
              </MemreText>
              {item.itemCount && (
                <MemreFlexBox justify="space-between">
                  <MemreText variant="caption">
                    Secure: <b>{item.itemCount.secure}</b>
                  </MemreText>
                  <MemreText variant="caption">
                    Fading: <b>{item.itemCount.fading}</b>
                  </MemreText>
                  <MemreText variant="caption">
                    Not Seen: <b>{item.itemCount.notSeen}</b>
                  </MemreText>
                </MemreFlexBox>
              )}
              {item.itemCount && (
                <svg width="100%" height="2">
                  <rect width="100%" height="2" fill={FILL.BLACK} />
                  <rect width={item.itemCount.securePercent} height="2" fill={COLOR.WHITE} />
                  <rect
                    x={item.itemCount.securePercent}
                    width={item.itemCount.fadingPercent}
                    height="2"
                    fill={FILL.RED}
                  />
                </svg>
              )}
              <MemreFlexBox justify="space-between">
                <MemreText variant="caption">
                  Seen:
                  <b style={{ marginLeft: 2, marginRight: 2 }}>
                    {item.sinceLastStudy} {item.lastStudyDenom}
                  </b>
                  ago
                </MemreText>

                <MemreText variant="caption">
                  Review: <b>{item.nextAt <= 0 ? 'ASAP' : item.nextAt + ' ' + item.nextAtDenom}</b>
                </MemreText>
              </MemreFlexBox>
            </MemreFlexBox>
          ) : (
            <></>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    const gridSizeClamp = clampGenerator(2, 10);
    const gridSize = gridSizeClamp(11 - Math.sqrt(data.length));
    const adjustedGridSize = 100 / Math.round(100 / gridSize);

    const size = adjustedGridSize * MAX_COLLECTION_SIZE;
    const getGridPoint = generateGridPointFunction(adjustedGridSize * 2);

    const domain = {
      x: [-size / 5, 100 + size / 5],
      y: [-size / 5, 100 + size / 5 + GAP * 2],
      gap: GAP,
    };

    const { x: adjustedMedian, y: adjustedReviewAtPoint } = getGridPoint(median, NEEDS_REVIEW_SIZE);

    const formattedData = data.map((d) => {
      const yRaw = convertYAxis(d.goodForNow);
      return {
        ...d,
        ...getGridPoint(d.distanceToGoal, yRaw),
        distanceToGoal: d.distanceToGoal,
        timeToReview: parseInt(d.goodForNow, 10),
      };
    });
    const combinedData = combineDataByXY(formattedData);

    setChartData({
      marks: [
        Plot.rectY([{}], {
          x1: domain.x[0],
          x2: domain.x[1],
          y1: domain.y[1] - domain.gap,
          y2: domain.y[1],
          fill: FILL.LIGHT_BLUE,
        }),
        Plot.rectY([{}], {
          x1: domain.x[0],
          x2: adjustedMedian,
          y1: domain.y[1] - domain.gap,
          y2: domain.y[1],
          fill: COLOR.BLACK,
        }),
        Plot.rectY([{}], {
          x1: domain.x[0],
          x2: domain.x[1],
          y1: domain.y[0],
          y2: domain.y[1] - domain.gap * 2,
          fill: FILL.LIGHT_BLUE,
        }),
        Plot.ruleX([adjustedMedian], {
          stroke: COLOR.DARK_GREEN,
          strokeWidth: STROKE.WIDTH.SMALL,
        }),
        Plot.rectY([{}], {
          x1: domain.x[0],
          x2: domain.x[1],
          y1: domain.y[0],
          y2: adjustedReviewAtPoint,
          fill: `url(#${linePatternId})`,
        }),
        Plot.dot(combinedData, {
          x: 'x',
          y: 'y',
          r: 'size',
          fill: SHADOW.COLOR,
          symbol: DiamondSymbol({ offset: STROKE.WIDTH.LARGE }),
        }),
        Plot.rectX(combinedData, {
          x1: (d) => d.x - 1,
          x2: (d) => Math.max(domain.x[0], d.x + NEEDS_REVIEW_SIZE - d.y),
          y1: (d) => d.y - 0.1,
          y2: (d) => d.y + 0.1,
          fill: (d) => (d.y > NEEDS_REVIEW_SIZE ? `url(#${gradientId})` : 'none'),
        }),
        Plot.dot(combinedData, {
          x: 'x',
          y: 'y',
          r: 'size',
          ariaLabel: (d) => d.data.map((d) => d.id).join('-'),
          ...diamondStyle(size),
          symbol: DiamondSymbol({ offset: 0 }),
        }),
        Plot.axisX({ fontSize: FONT.SIZE.MEDIUM, label: 'Distance To Goal', ticks: [], tickSize: 0 }),
        Plot.axisY({
          fontSize: FONT.SIZE.MEDIUM,
          label: 'Time Since Last Review',
          ticks: [],
          labelAnchor: 'center',
          tickSize: 0,
        }),
        Plot.dot([{ x: adjustedMedian }], {
          fill: COLOR.GREEN,
          stroke: COLOR.WHITE,
          strokeWidth: STROKE.WIDTH.MEDIUM,
          x: 'x',
          y: domain.y[1],
          r: domain.gap * 3,
          symbol: DiamondSymbol({ offset: domain.gap * 2.1 }),
        }),
      ],
      marginLeft: 30,
      x: {
        domain: domain.x,
      },
      y: {
        domain: domain.y,
      },
      r: { range: [1, 15] },
    });
  }, [data]);

  return (
    <>
      <StripePattern id={linePatternId} />
      <Gradient id={gradientId} />
      <MemrePlot data={chartData} tooltipFunction={formatTooltip} />
    </>
  );
}
