import React, { FC, ReactNode, useState, useRef, useEffect, CSSProperties } from 'react';

interface FadeOutAndInContainerProps {
  /** The content that should be faded in/out */
  children: ReactNode;

  /** Duration of the fade in/out animation in milliseconds (default = 1000) */
  duration?: number;
}

export const FadeOutAndInContainer: FC<FadeOutAndInContainerProps> = ({ children, duration = 1000 }) => {
  const [fadeState, setFadeState] = useState<'fade-in' | 'fade-out'>('fade-in');
  const [displayedChildren, setDisplayedChildren] = useState<ReactNode>(children);

  const prevChildrenRef = useRef<ReactNode>(children);

  useEffect(() => {
    if (children !== prevChildrenRef.current) {
      setFadeState('fade-out');

      const timeoutId = setTimeout(() => {
        setDisplayedChildren(children);
        setFadeState('fade-in');
        prevChildrenRef.current = children;
      }, duration);

      return () => clearTimeout(timeoutId);
    }
  }, [children, duration]);

  const transitionStyle: CSSProperties = {
    opacity: fadeState === 'fade-in' ? 1 : 0,
    transition: `opacity ${duration}ms ease-in-out`,
  };

  return <div style={transitionStyle}>{displayedChildren}</div>;
};
