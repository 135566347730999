import FormControl from '@material-ui/core/FormControl';
import { MemreSelectMenu } from 'components/core';
import { usePartnerUserTagTypes } from 'hooks/usePartnerUserTagTypes';

type Props = {
  value: string;
  onSelect: (tagType: any) => void;
};

export const PartnerUserTagTypesSelectMenu = (props: Props) => {
  const { value, onSelect } = props;
  const { data: partnerUserTagTypes } = usePartnerUserTagTypes();

  return (
    <div style={{ display: 'block' }}>
      <FormControl>
        <MemreSelectMenu
          label=""
          value={value || ''}
          onChange={(event: any) => onSelect(event.target.value)}
          items={[
            { label: 'Select a user field', value: '' },
            ...(partnerUserTagTypes || []).map((tagType: any) => ({ label: tagType.label, value: tagType.id })),
          ]}
          displayEmpty={true}
        />
      </FormControl>
    </div>
  );
};
