import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

export function SiteAdminUserSearch() {
  const [users, setUsers] = useState<any[]>([]);
  const [waiting, setWaiting] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const history = useHistory();

  const getUserURL = (userId: number) => `/nav/v4/site-admin/users/${userId}`;

  const doSearch = async () => {
    setWaiting(true);
    setUsersLoaded(false);
    setUsers([]);
    try {
      const response: any = await axios.get(`/api/v2/admin/users`, { params: { with_query: userSearchQuery } });
      const usersFromResponse = response.data.response;

      // sort by last login date
      usersFromResponse?.sort((a, b) => {
        return new Date(b.last_logged_in_at).getTime() - new Date(a.last_logged_in_at).getTime();
      });
      setUsers(usersFromResponse);
      setUsersLoaded(true);

      if (usersFromResponse.length === 1) {
        history.push(getUserURL(response[0].id));
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setWaiting(false);
    }
  };

  return (
    <div style={{ margin: '20px' }}>
      <h2>User Search</h2>
      <br />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          doSearch();
        }}
      >
        <input
          value={userSearchQuery}
          onChange={(e) => setUserSearchQuery(e.target.value)}
          placeholder="User Search Query"
        />
        <button type="submit">Search</button>
        {waiting && <div>Searching...</div>}
        {usersLoaded && users.length < 1 && (
          <div>
            <p>No user was found</p>
          </div>
        )}
        {usersLoaded && users.length > 0 && (
          <table>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={user.id}
                  style={{
                    borderBottom: '1px solid black',
                    padding: '10px',
                    backgroundColor: index % 2 === 0 ? 'lightgray' : 'white',
                  }}
                >
                  <td>
                    <p>User ID: {user.id}</p>
                    <p>Display name: {user.name}</p>
                    <p>Created At: {new Date(user.created_at).toLocaleDateString()}</p>
                    <p>Last login: {new Date(user.last_logged_in_at).toLocaleDateString()}</p>
                    <p>
                      <Link target="_blank" to={getUserURL(user.id)}>
                        Profile
                      </Link>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </form>
    </div>
  );
}
