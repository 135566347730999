import * as COLOURS from 'core/colors';
import { lightBlack } from 'core/colors';

export const STROKE_WIDTH = 1;
export const PADDING = 0.25;
export const PADDING_LARGE = 1.5;
export const BORDER_RADIUS = 2;
export const PRIMARY_COLOR = COLOURS.primaryGreen;
export const BRIGHT_COLOR = COLOURS.primaryGreenBright;
export const GREY = COLOURS.slateGray;
export const WHITE = COLOURS.white;
export const BLACK = COLOURS.lightBlack;
export const MEDIUM_BOX_SIZE = 26;
export const SMALL_BOX_SIZE = 8;
export const TRANSITION_DURATION = 300;

export const FONT_SIZE = '0.8rem';
export const FONT_FAMILY = 'Lato, sans-serif';
