import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Color } from 'core';

interface TableSearchBarProps {
  value: string;
  onChange: (value: string) => void;
  resetPage: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

export const TableSearchBar = ({ value, onChange, resetPage, placeholder, style }: TableSearchBarProps) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const debouncedOnChange = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
      resetPage();
    }, 800),
    [onChange, resetPage]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value); // update immediately for a responsive UI
    debouncedOnChange(e);
  };

  return (
    <SearchContainer hasValue={!!localValue} style={style}>
      <SearchIcon>
        <FontAwesomeIcon icon={faSearch} />
      </SearchIcon>
      <SearchInput type="text" value={localValue} placeholder={placeholder || t('Search')} onChange={handleChange} />
    </SearchContainer>
  );
};

interface ISearchContainerProps {
  hasValue: boolean;
}

const SearchContainer = styled.div<ISearchContainerProps>`
  box-shadow: inset 0 1px 0.25em 0 rgba(0, 0, 0, 0.3);
  border: 1px solid transparent;
  padding: 0.8rem 1.2rem;
  color: ${Color.secondaryGray};
  border-radius: 0.25em;

  display: flex;
  align-items: center;
  flex-grow: 1;

  ${(props) =>
    props.hasValue
      ? `
      box-shadow: inset 0 0 0.25em 0 rgba(51,58,236,0.7);
      border: 1px solid rgba(51,58,236,0.7);
      grid-template-columns: 1fr;
      input {
        color: ${Color.primaryBlue};
        font-weight: bold;
      }
      svg {
        display: none;
      }
    `
      : `
      display: grid;
      grid-template-columns: 1em 1fr;
      grid-gap: 0 0.5em;
    `}
`;

const SearchIcon = styled.div`
  margin-right: 8px;
  svg {
    padding-top: 1px;
  }
`;

const SearchInput = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  font-family: inherit;

  ::placeholder {
    color: ${Color.reportGray};
    opacity: 1;
    font-weight: bold;
  }
`;
