import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTooltipStore } from 'hooks/useTooltipStore';
import { useEffect } from 'react';
import {
  BLACK,
  BORDER_RADIUS,
  FONT_FAMILY,
  FONT_SIZE,
  PADDING_LARGE,
  TRANSITION_DURATION,
  WHITE,
} from './d3Components/constants';

const DISTANCE_FROM_EDGE = 160;
const POINTER_SIZE = 12;
const OVERLAP_MARGIN = 0.5;

export default function MemreTooltip() {
  const { tooltipState, restraints, updatePosition } = useTooltipStore();
  const { visible, content, direction, x, y } = tooltipState;

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const newX = restraints?.bounds?.x ?? event.clientX;
      const newY = restraints?.bounds?.y ?? event.clientY;

      updatePosition(newX, newY);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [visible, restraints, updatePosition]);

  const getDirection = () => {
    const { innerWidth, innerHeight } = window;
    if (x < DISTANCE_FROM_EDGE) {
      return 'right';
    } else if (x > innerWidth - DISTANCE_FROM_EDGE) {
      return 'left';
    } else {
      return y < innerHeight / 2 ? 'bottom' : 'top';
    }
  };

  const computedDirection = direction || getDirection();

  const pointerPositionStyles = {
    bottom: {
      left: '50%',
      top: `-${POINTER_SIZE - OVERLAP_MARGIN}px`,
      transform: 'translateX(-50%)',
      border: `${POINTER_SIZE / 2}px solid ${BLACK}`,
      borderColor: `transparent transparent ${BLACK} transparent`,
    },
    top: {
      left: '50%',
      top: `calc(100% - ${OVERLAP_MARGIN}px)`,
      transform: 'translateX(-50%)',
      border: `${POINTER_SIZE / 2}px solid ${BLACK}`,
      borderColor: `${BLACK} transparent transparent transparent`,
    },
    left: {
      left: `calc(100% - ${OVERLAP_MARGIN}px)`,
      top: '50%',
      transform: 'translateY(-50%)',
      border: `${POINTER_SIZE / 2}px solid ${BLACK}`,
      borderColor: `transparent transparent transparent ${BLACK}`,
    },
    right: {
      left: `-${POINTER_SIZE - OVERLAP_MARGIN}px`,
      top: '50%',
      transform: 'translateY(-50%)',
      border: `${POINTER_SIZE / 2}px solid ${BLACK}`,
      borderColor: `transparent ${BLACK} transparent transparent`,
    },
  }[computedDirection];
  const transform = {
    bottom: visible
      ? `translateX(-50%) translateY(calc(0% + ${POINTER_SIZE}px))`
      : `translateX(-50%) translateY(calc(-10% + ${POINTER_SIZE}px))`,
    top: visible
      ? `translateX(-50%) translateY(calc(-100% - ${POINTER_SIZE}px))`
      : `translateX(-50%) translateY(calc(-90% - ${POINTER_SIZE}px))`,
    left: visible
      ? `translateX(calc(-100% - ${POINTER_SIZE}px)) translateY(-50%)`
      : `translateX(calc(-90% - ${POINTER_SIZE}px)) translateY(-50%)`,
    right: visible
      ? `translateX(calc(0% + ${POINTER_SIZE}px)) translateY(-50%)`
      : `translateX(calc(-10% + ${POINTER_SIZE}px)) translateY(-50%)`,
  }[computedDirection];

  const transitionTime = TRANSITION_DURATION / (visible ? 1000 : 2000);

  const tooltipStyles = {
    position: 'absolute',
    pointerEvents: 'none',
    top: y,
    left: x,
    opacity: visible ? 1 : 0,
    transition: `opacity ${transitionTime}s, transform ${transitionTime}s`,
    transform,
    borderRadius: BORDER_RADIUS,
    backgroundColor: BLACK,
    color: WHITE,
    fontFamily: FONT_FAMILY,
    display: 'inline-block',
    p: PADDING_LARGE,
    '&:after': {
      content: '""',
      position: 'absolute',
      ...pointerPositionStyles,
    },
  };

  return (
    <Box sx={tooltipStyles}>
      <Typography sx={{ fontSize: FONT_SIZE }}>{content}</Typography>
    </Box>
  );
}
