import { ResponsiveLine } from '@nivo/line';
import * as colours from 'core/colors';

export default function Line({ data }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{ left: 32, right: 2, top: 5 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 0 }}
      axisBottom={{
        tickSize: 0,
      }}
      axisLeft={{
        format: () => '',
        tickSize: 0,
        legend: 'Retention Score',
        legendOffset: -15,
        legendPosition: 'middle',
      }}
      enableGridX={false}
      enableGridY={false}
      colors={[colours.brightGreen]}
      lineWidth={2}
      pointSize={0}
      pointBorderColor={{ from: 'serieColor' }}
      enableArea={true}
      areaOpacity={0.3}
      curve="monotoneX"
      isInteractive={false}
    />
  );
}
