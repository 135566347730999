import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Color } from 'core';
import React from 'react';
import styled from 'styled-components';

const SUPPORT_URL = 'https://www.cerego.com/blog/new-feature-release-readiness-score-measures-knowledge-in-real-time';

export const ReadinessTooltip = () => {
  const goToSupportArticle = () => {
    window.open(SUPPORT_URL, '_blank');
  };

  return (
    <LightTooltip
      title={
        <>
          <TooltipParagraph>
            The Readiness Score™ is continuously updated to reflect the current availability of knowledge. Instead of
            showing what people have done, it shows what they know right now.
          </TooltipParagraph>
        </>
      }
      interactive
    >
      <button onClick={goToSupportArticle} style={{ border: 'none', color: Color.reportGray }}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </button>
    </LightTooltip>
  );
};

const TooltipParagraph = styled.p`
  font-size: 14px;
`;

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Color.white,
    color: Color.primaryBlack,
    border: `1px solid ${Color.reportGray}`,
    boxShadow: `3px 3px 3px ${Color.reportGray}`,
  },
}))(Tooltip);
