import moment from 'moment';

// Given a date-formatted string, return true if that date is earlier than today
export const isInPast = (date: string): boolean => {
  return moment(date).isBefore(moment(), 'day');
};

// Given a date-formatted string, return it in "(YYYY MMM DD)" format
export const formatDate = (date: string): string => {
  return moment(date).format('(YYYY MMM DD)');
};

// Return the current datetime as a string
export const now = (): string => {
  return moment().format();
};
