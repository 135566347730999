import * as Color from 'core/colors';
import styled from 'styled-components';

export const dropShadow = `0 3px 7px ${Color.grayTransparent}`;
export const hoverShadow = `0 1px 4px 0 ${Color.darkGray}`;
export const hoverBackgroundColor = `linear-gradient(120deg, ${Color.ceregoGreen}, ${Color.primaryGreen})`;
export const BaseButton = styled.button.attrs(({ disabled = false, pending = false }: IButton) => {
  return { disabled };
})`
  background-color: ${Color.primaryGreen};
  border: 2px solid ${Color.primaryGreen};
  box-shadow: ${dropShadow};
  white-space: nowrap;
  padding: 0.5em 1.5em;
  font-weight: 500;
  opacity: ${({ disabled }) => (disabled ? '0.35' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  :hover {
    background: ${({ disabled }) => (disabled ? `background-color` : hoverBackgroundColor)};
    box-shadow: ${({ disabled }) => (disabled ? dropShadow : hoverShadow)};
  }
  :focus {
    box-shadow: ${hoverShadow};
    outline: none;
  }
`;
export const BaseStyles = {
  rectangle: {},
  round: {
    borderRadius: '50%',
    width: '3em',
    height: '3em',
    padding: '0.4em 0.5em',
    textAlign: 'center'
  },
  pill: {
    borderRadius: '2em'
  },
  light: {},
  dark: {
    borderColor: Color.white
  },
  small: {
    fontSize: '0.75rem'
  },
  medium: {},
  large: {
    fontSize: '1.25rem'
  }
};

export interface IButton {
  context?: 'light' | 'dark';
  size?: 'small' | 'medium' | 'large';
  shape?: 'rectangle' | 'round' | 'pill';
  pending?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  children?: any;
  click: () => void;
  destructive?: boolean;
  tabIndex?: number;
}
