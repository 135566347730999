import { CancelButton, PrimaryButton } from 'components/buttons/v4';
import { Modal, ModalPosition } from 'components/Modal';
import { Billing } from 'components/modals/Billing';
import { Size } from 'core';
import { Card, CardHeader, CardHeaderContainer, PageTitle } from 'core/layout';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleDestroySession } from 'store/session';
import styled from 'styled-components';
import { AnalyticsService } from 'utils/AnalyticsService';
import { ModalHeader, SpacedOut } from './common';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: ${Size.defaultCardWidth}px;
`;

const ContactLink = styled.a`
  padding-left: 0;
  padding-right: 0;
`;

export const FreeTrialExpired = (props: any) => {
  const dispatch = useDispatch();
  const [showBillingModal, setShowBillingModal] = React.useState(!!props.billingStatus);
  const { t } = useTranslation();

  async function handleSignOutClick() {
    await dispatch(handleDestroySession());
    const hostname = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';
    window.location.href = hostname + '/signin';
  }

  function handleUpgradeClick() {
    AnalyticsService.getInstance().track('upgrade_now_clicked');
    setShowBillingModal(true);
  }

  function handleBillingClose() {
    if (props.billingStatus !== 'success') {
      AnalyticsService.getInstance().track('cancel_billing_clicked');
    }
    setShowBillingModal(false);
  }

  if (showBillingModal) {
    return (
      <Modal
        show={showBillingModal}
        element={<Billing onClose={handleBillingClose} isSuccess={props.billingStatus === 'success'} />}
      />
    );
  }

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>{t('All Good Things...')}</CenterTitle>
      </ModalHeader>
      <ContentContainer className="contentContainer">
        <Card className="card">
          <CardHeaderContainer>
            <CardHeader>{t('Your free trial with Cerego has ended')}</CardHeader>
          </CardHeaderContainer>
          <div>
            <Trans i18nKey="Free Trial Expired Modal Text">
              Thank you for trying out Cerego! We sincerely hope that our learning science based approach has elevated
              the way your learners study and retain the valuable training you provide. To continue your trial, please
              contact us at <ContactLink href="mailto:hello@cerego.com">hello@cerego.com</ContactLink> or upgrade now.
            </Trans>
          </div>
        </Card>
        <ButtonContainer>
          <SpacedOut>
            <CancelButton click={handleSignOutClick}>{t('Sign Out')}</CancelButton>
            <PrimaryButton click={handleUpgradeClick}>{t('Upgrade Now')}</PrimaryButton>
          </SpacedOut>
        </ButtonContainer>
      </ContentContainer>
    </ModalPosition>
  );
};
