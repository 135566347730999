import React, { useEffect, useState } from 'react';
import ceregoLogo from 'assets/images/cerego-logo.svg';
import unauthenticatedBackground from 'assets/images/unauthenticatedBackground.svg';
import { StandardLink } from 'components/links/StandardLink';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { handleGetProfile } from 'store/profile';

import { useDispatch } from 'react-redux';
import { handleDestroySession } from 'store/session';
import { FadeOutAndInContainer } from 'components/animations/FadeOutAndInContainer';
import { shuffle } from 'lodash';
import { quotes } from './quotes';

interface UnauthenticatedLayoutProps {
  showSignIn?: boolean;
  children: React.ReactNode;
}

export function UnauthenticatedLayout(props: UnauthenticatedLayoutProps) {
  const { children, showSignIn = true } = props;

  const [shuffledQuotes] = useState(shuffle(quotes));
  const [quoteIndex, setQuoteIndex] = useState(0);
  const currentQuote = shuffledQuotes[quoteIndex % shuffledQuotes.length];
  const currentUser = useCurrentUser();
  const isSignedIn = !!currentUser;
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    await dispatch(handleDestroySession());
    window.location.reload();
  };

  useEffect(() => {
    dispatch(handleGetProfile());
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setQuoteIndex((c) => c + 1);
    }, 9000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col lg:flex-row bg-white">
      {/* Left side */}
      <div id="registration-container-left" className="overflow-y-auto lg:w-[500px] flex-shrink-0">
        <div className="registration-header h-[80px] flex items-end pl-[50px]">
          <a className={`registration-cerego-logo block`} href="https://www.cerego.com" target="_self" rel="noreferrer">
            <img src={ceregoLogo} alt="Cerego logo" style={{ width: '150px', marginBottom: '10px' }} />
          </a>
        </div>
        <div className="registration-body p-[35px_50px_50px_50px]">{children}</div>
      </div>

      {/* Right side */}
      <div
        id="registration-container-right"
        className="
              relative
              flex
              w-full
              bg-no-repeat
              bg-cover
              bg-bottom
              flex-col
              justify-around
              items-center
              p-4
              min-h-[300px]
              "
        style={{ backgroundImage: `url(${unauthenticatedBackground})` }}
      >
        {/* Header link area */}
        <div className="registration-header-link flex flex-col justify-end items-end h-[80px] w-full pr-[50px]">
          <nav className="registration-links flex justify-between items-center h-[40px] space-x-5">
            {isSignedIn ? (
              <StandardLink onClick={handleSignOut}>Sign Out</StandardLink>
            ) : (
              showSignIn && <StandardLink to={'/signin'}>Sign In</StandardLink>
            )}
          </nav>
        </div>

        {/* Quote section */}
        <div
          className="
                registration-quote
                flex-[1_1_auto]
                font-medium
                text-gray-600
                text-center
                flex
                flex-col
                justify-center
                items-center
                max-w-[500px]
              "
        >
          <FadeOutAndInContainer>
            <p>
              <em>{currentQuote.quote}</em>
              <span className="heading-small block mt-2">- {currentQuote.name}</span>
            </p>
          </FadeOutAndInContainer>
        </div>
      </div>
    </div>
  );
}
