import axios from 'axios';
import { useQuery } from 'react-query';

export const getDailyStudyTime = async (
  _key: string,
  courseId: string | null,
  setId: string | null,
  userId: string | null,
  startDate: Date,
  endDate: Date,
  page: number,
  pageSize: number
) => {
  if (!courseId || !setId) {
    console.warn('Cannot get daily study time without both courseId and setId');
    return;
  }

  const params = {
    setId: `set_id=${setId}`,
    userId: userId ? `user_id=${userId}` : '',
    startDate: `start_date=${startDate}`,
    endDate: `end_date=${endDate}`,
    page: `page[number]=${page}`,
    pageSize: `page[size]=${pageSize}`,
  };
  const endpoint = `/api/v4/courses/${courseId}/daily_study_time?${params.setId}&${params.userId}&${params.startDate}&${params.endDate}&${params.page}&${params.pageSize}`;

  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const useDailyStudyTime = (
  courseId: string | null,
  setId: string | null,
  userId: string | null,
  startDate: Date,
  endDate: Date,
  page: number,
  pageSize: number
) => {
  return useQuery(['dailyStudyTime', courseId, setId, userId, startDate, endDate, page, pageSize], getDailyStudyTime, {
    enabled: courseId && setId && startDate && endDate && page && pageSize,
  });
};
