import { createTheme } from '@mui/material';
import * as colours from 'core/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colours.primaryGreen,
    },
    secondary: {
      main: colours.mainBlue,
    },
  },
  typography: {
    fontFamily: "'Lato', sans-serif",
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          margin: '0 1.75rem;',
          padding: '0 !important',
        },
      },
    },
  },
});

theme.typography.h3 = {
  fontSize: '1.7rem',
  fontWeight: 900,
  color: colours.black,
};

theme.typography.h4 = {
  fontSize: '1.13rem',
  fontWeight: 600,
  color: colours.slateGray,
};

theme.typography.h5 = {
  fontSize: '1.3rem',
  fontWeight: 500,
  color: colours.textGray,
  lineHeight: 1,
};

theme.typography.h6 = {
  fontSize: '1rem',
  fontWeight: 500,
  color: colours.black,
};

theme.typography.overline = {
  fontSize: '0.8rem',
  fontWeight: 400,
  color: colours.textGray,
  lineHeight: 1,
};

export default theme;
