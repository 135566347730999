import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePeopleStore } from 'pages/admin-dashboard/people/store';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

type DeletePartnerUserParams = {
  partnerId: string;
  userId: string;
};

const deletePartnerUser = (params: DeletePartnerUserParams) => {
  const { partnerId, userId } = params;
  return axios.delete(`/api/v3/partners/${partnerId}/users/${userId}`);
};

export const useDeletePartnerUser = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = usePeopleStore();

  return useMutation(deletePartnerUser, {
    onSuccess: () => {
      makeToast({
        text: 'Successfully removed user.',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to remove user. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries([
        'partnerUsers',
        user?.primaryPartner.id,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        name,
      ]);
    },
  });
};
