import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { AssetUploader } from 'components/AssetUploader';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ValidationSummary } from 'components/forms/ValidationSummary';
import { ModalPosition } from 'components/Modal';
import { ModalHeader } from 'components/modals/common';
import { Color } from 'core';
import { CardHeader, CardHeaderContainer, PageTitle } from 'core/layout';
import * as Size from 'core/size';
import { useCreateSmartAssignment } from 'hooks/useCreateSmartAssignment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getEditSetUrl } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const Subtitle = styled.div`
  font-size: 0.65em;
  text-align: center;
`;

const ImageUploadGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0 1.5em;
`;

const GreyText = styled.p`
  color: ${Color.textGray};
`;

const InputWrapper = styled.div`
  border: solid 1px ${Color.lightGray};
  background-color: ${Color.primaryGray};
  color: ${Color.lightBlack};
  padding: 0.5em 1em;
  border-radius: 0.25em;
`;

const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: 0.95rem;
  width: 100%;
`;

interface IProps {
  goalType: string;
  courseId: string;
  partnerId: number;
  onCancel: () => void;
}

export const CreateNewSet = (props: IProps) => {
  const [name, setName] = useState<string | undefined>();
  const [image, setImage] = React.useState({} as any);
  const [isPending, setIsPending] = React.useState<boolean>(false);
  const [validationFailures, setValidationFailures] = React.useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const creatingManualAssignment: boolean = currentTab === 0;
  const creatingSmartAssignment: boolean = currentTab === 1;
  const { t } = useTranslation();
  const [createSmartAssignment] = useCreateSmartAssignment(props.courseId);
  const smartCreateForm = useSmartAssignmentForm((values) => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Smart Course Search', topic: values.topic });
    createSmartAssignment({
      title: values.topic || '',
      partnerId: props.partnerId,
      courseId: props.courseId,
      level: values.level,
      language: values.language,
    });
  });

  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    formGroup: {
      marginBottom: theme.spacing(2),
    },
    formControl: {
      width: '100%',
    },
    input: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fileInput: {
      display: 'none',
    },
    uploadButton: {
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (name === undefined) {
      return;
    }
    updateValidation();
  }, [name]);

  const updateValidation = () => {
    let trimmedName = name?.trim() || '';
    var results: string[] = [];
    if (trimmedName.length == 0) {
      results.push(t('Title cannot be blank.'));
    }
    setValidationFailures(results);
  };

  const handleNameBlur = () => {
    updateValidation();
  };

  const confirmIsDisabled = (): boolean => {
    return name === undefined || !isValid() || isPending;
  };

  const isValid = (): boolean => {
    return validationFailures.length === 0;
  };

  const onUploadImage = (image) => {
    setImage(image);
  };

  const onCreate = async () => {
    setIsPending(true);
    const { goalType: goal_type, partnerId: partner_id, courseId } = props;
    // Build POST payload
    const data = {
      learn_version: 4,
      goal_type,
      name,
      partner_id,
      icon_image_id: image.id,
    };
    // Create the base Set and grab its response
    const setCreateResponse = (await axios.post('/api/v3/sets', data)).data.data;
    const newSetId = setCreateResponse.id;
    // Create the course_set association record (AKA "Assignment")
    await axios.post(`/api/v3/courses/${courseId}/sets`, setCreateResponse);
    // Redirect to the edit page for the new Assignment
    window.location.href = getEditSetUrl(4, newSetId);
  };

  const title = {
    assessment: t('Create an Assessment'),
    survey: t('Create a Survey'),
    set: t('Create a Learning Set'),
  };

  const subtitle = {
    assessment: t('Assessments are designed to be taken once.'),
    survey: t('Surveys can help you collect non-observable data, like attitudes and opinions.'),
    set: t('Learning sets help learners master critical concepts and skills.'),
  };

  const submitSmartCreate = () => {
    smartCreateForm.handleSubmit();
    props.onCancel();
  };

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>
          <b>{title[props.goalType]}</b>
        </CenterTitle>
        <Subtitle>
          <GreyText>{subtitle[props.goalType]}</GreyText>
        </Subtitle>
      </ModalHeader>

      <div style={{ width: Size.defaultCardWidth }}>
        {props.goalType === 'set' && (
          <Tabs
            value={currentTab}
            onChange={(_e: React.ChangeEvent<{}>, tabSelection: number) => setCurrentTab(tabSelection)}
          >
            <Tab label={t('Manual Create')} />
            <Tab label={t('Smart Create')} />
          </Tabs>
        )}

        {creatingManualAssignment && (
          <div>
            <Card style={{ padding: '20px', marginTop: '10px', marginBottom: '10px' }}>
              <CardHeaderContainer>
                <CardHeader id="header">{t('Add a title')}</CardHeader>
              </CardHeaderContainer>
              <div>
                <InputWrapper style={{ border: isValid() ? 'solid 1px ' + Color.lightGray : 'solid 1px red' }}>
                  <Input
                    autoFocus={true}
                    value={name}
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    placeholder={t('e.g. Product Marketing 101')}
                    aria-labelledby="header"
                  />
                </InputWrapper>
                <ValidationSummary validationTexts={validationFailures} />
              </div>
            </Card>

            <Card style={{ padding: '20px' }}>
              <CardHeaderContainer>
                <CardHeader>{t('Add an image (Optional)')}</CardHeader>
              </CardHeaderContainer>

              <ImageUploadGrid>
                <AssetUploader onUploadComplete={onUploadImage} />
                <div>
                  <div>
                    <b>{t('120px by 120px works best')}</b>
                  </div>
                  <GreyText>{t('This image appears in the Knowledge Bank and assignment list.')}</GreyText>
                </div>
              </ImageUploadGrid>
            </Card>

            <ConfirmOrCancel
              confirmText={t('Create')}
              handleConfirmClick={onCreate}
              confirmIsDisabled={confirmIsDisabled()}
              confirmIsPending={isPending}
              handleCancelClick={props.onCancel}
            />
          </div>
        )}

        {creatingSmartAssignment && (
          <div>
            <Card>
              <CardContent>
                <Typography variant="body1">
                  {t(
                    "Enter the details below to generate a custom course based on your preferences. You'll get a complete learning path tailored to your chosen topic, language, and difficulty level."
                  )}
                </Typography>
              </CardContent>

              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.formGroup}>
                    <Typography variant="subtitle1" id="topic-header">
                      {t('Topic')}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {t('Enter a topic you want to learn about, the more specific the better')}
                    </Typography>
                    <TextField
                      className={classes.input}
                      placeholder="Assignment name"
                      autoFocus={true}
                      onChange={(event) => smartCreateForm.setTopic(event.target.value)}
                      required={true}
                      fullWidth
                      aria-labelledby="topic-header"
                    />
                  </Grid>

                  <Grid container spacing={3} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <Grid item xs={12} className={classes.formGroup}>
                      <Typography variant="subtitle1" id="language-header">
                        {t('Language')}
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom>
                        Choose the language you want to generate the course in
                      </Typography>
                      <TextField
                        className={classes.input}
                        placeholder="English"
                        onChange={(event) => smartCreateForm.setLanguage(event.target.value)}
                        required={true}
                        fullWidth
                        aria-labelledby="language-header"
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.formGroup}>
                      <Typography variant="subtitle1" id="level-header">
                        {t('Level')}
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom>
                        Select the difficulty level of the course
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <TextField
                          className={classes.input}
                          placeholder="Beginner"
                          onChange={(event) => smartCreateForm.setLevel(event.target.value)}
                          required={true}
                          fullWidth
                          aria-labelledby="level-header"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <ConfirmOrCancel
              cancelText={t('Cancel')}
              confirmText={t('Create')}
              handleCancelClick={props.onCancel}
              handleConfirmClick={submitSmartCreate}
              confirmIsDisabled={false}
              confirmIsPending={false}
            />
          </div>
        )}
      </div>
    </ModalPosition>
  );
};

interface SmartAssignmentFormValues {
  topic: string;
  level: string;
  language: string;
}

const useSmartAssignmentForm = (onSubmit: (values: SmartAssignmentFormValues) => void) => {
  const [topic, setTopic] = useState<string>('');
  const [level, setLevel] = useState<string>('BEGINNER');
  const [language, setLanguage] = useState<string>('English');

  const handleSubmit = () => {
    onSubmit({ topic, level, language });
  };

  return {
    topic,
    setTopic,
    level,
    setLevel,
    language,
    setLanguage,
    handleSubmit,
  };
};
