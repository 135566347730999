import axios from 'axios';
import { useEffect, useState } from 'react';

const fallbacks = {
  idealDay: new Date(),
  idealTime: new Date(),
  idealDuration: 25,
  retentionLevels: [],
};

export default function useIdealNextStudySession({ userId }) {
  const [idealDay, setIdealDay] = useState<Date>(fallbacks.idealDay);
  const [idealTime, setIdealTime] = useState<Date>(fallbacks.idealTime);
  const [retentionLevels, setRetentionLevels] = useState(fallbacks.retentionLevels);
  const [idealDuration, setIdealDuration] = useState(fallbacks.idealDuration);

  useEffect(() => {
    axios.get(`/api/v4/my/ideal_next_study_session`).then((res: any): void => {
      const { ideal_next_study_date, ideal_study_duration, efficiency_by_hour } = res.data.data.attributes;

      if (ideal_study_duration) {
        setIdealDuration(ideal_study_duration / 60);
      }

      if (ideal_next_study_date) {
        setIdealDay(new Date(ideal_next_study_date));
        setIdealTime(new Date(ideal_next_study_date));
      }

      if (efficiency_by_hour) {
        setRetentionLevels(Object.values(efficiency_by_hour));
      }
    });
  }, []);

  return { idealDay, retentionLevels, idealTime, idealDuration };
}
