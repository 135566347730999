import { SurveyAnswerType, TemplateType } from 'models/item';

export type FormattedTemplateType =
  | 'Instructional Content'
  | 'Question and Answer'
  | 'Vocabulary'
  | 'Sequence'
  | 'Diagram'
  | 'Flashcard'
  | 'Free Response'
  | 'Ratings'
  | 'Multiple Select Question'
  | 'Single Select Question';

export const formatItemTemplate = (
  template: TemplateType,
  answerType: SurveyAnswerType | undefined
): FormattedTemplateType => {
  switch (template) {
    case 'question_and_answers':
      return 'Question and Answer';
    case 'vocabulary':
      return 'Vocabulary';
    case 'sequences':
      return 'Sequence';
    case 'regions':
      return 'Diagram';
    case 'instructional_contents':
      return 'Instructional Content';
    case 'associations':
      return 'Flashcard';
    case 'free_responses':
      return 'Free Response';
    case 'ratings':
      return 'Ratings';
    case 'survey_question_and_answers':
      return answerType === 'multi_correct' ? 'Multiple Select Question' : 'Single Select Question';
    default:
      throw new Error(`Cannot format unrecognized item template: ${template}`);
  }
};
