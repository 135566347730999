import { useState } from 'react';
import { CurriculumAssignment, useSmartCreateStore } from 'views/course-create/smart-create/store';

interface SmartCourseFormValues {
  topic: string;
  level: string;
  language: string;
  pdfFile?: File;
  assignments: CurriculumAssignment[];
}

export const useSmartCourseForm = (onSubmit: (values: SmartCourseFormValues) => void) => {
  const [topic, setTopic] = useState<string>('');
  const [level, setLevel] = useState<string>('BEGINNER');
  const [language, setLanguage] = useState<string>('English');
  const [pdfFile, setPdfFile] = useState<File>();
  const assignments = useSmartCreateStore((state) => state.assignments);

  const handleSubmit = () => {
    onSubmit({ topic, level, language, pdfFile, assignments });
  };

  return {
    topic,
    setTopic,
    level,
    setLevel,
    language,
    setLanguage,
    pdfFile,
    setPdfFile,
    handleSubmit,
  };
};
