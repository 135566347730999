import { Color } from 'core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  rightToLeft: boolean;
  selectedTab: 'Your Answer' | 'Correct Answer';
  onUserAnswerClick: () => void;
  onCorrectAnswerClick: () => void;
}

export const SequenceTabs: FC<Props> = ({ rightToLeft, selectedTab, onUserAnswerClick, onCorrectAnswerClick }) => {
  const { t } = useTranslation();

  return (
    <TabContainer rightToLeft={rightToLeft} role="tablist">
      <TabText
        onClick={onUserAnswerClick}
        role="tab"
        aria-selected={selectedTab === 'Your Answer'}
        aria-hidden={selectedTab === 'Your Answer' ? 'false' : 'true'}
        style={
          selectedTab === 'Your Answer'
            ? { color: Color.lightBlack, borderBottom: `2px solid ${Color.lightBlack}` }
            : { color: Color.textGray, borderBottom: `2px solid transparent` }
        }
      >
        {t('Your Answer')}
      </TabText>
      <TabText
        onClick={onCorrectAnswerClick}
        role="tab"
        aria-selected={selectedTab === 'Correct Answer'}
        aria-hidden={selectedTab === 'Correct Answer' ? 'false' : 'true'}
        style={
          selectedTab === 'Correct Answer'
            ? { color: Color.lightBlack, borderBottom: `2px solid ${Color.lightBlack}` }
            : { color: Color.textGray, borderBottom: `2px solid transparent` }
        }
      >
        {t('Correct Answer')}
      </TabText>
    </TabContainer>
  );
};

type StyleProps = {
  rightToLeft?: boolean;
};

const TabText = styled.button`
  border: none;
  background-color: transparent;
  font-size: 15px;
  margin: 10px 15px 0px 0px;
  margin-top: 5px;
  padding: 6px;
  font-family: inherit;
  font-weight: bold;
`;

const TabContainer = styled.div<StyleProps>`
  display: flex;
  flex-direction: ${(props) => (props.rightToLeft ? 'row-reverse' : 'row')};
  margin-left: ${(props) => (props.rightToLeft ? '-25px' : '25px')};
  width: 100%;
`;
