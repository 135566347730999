import { Modal } from 'components/Modal';
import { Restricted } from 'components/Restricted';
import { EndFocus } from 'components/buttons/EndFocus';
import { SkipContentButton } from 'components/buttons/SkipContentButton';
import { PrimaryButton } from 'components/buttons/v4';
import { CoursesTable } from 'components/courses/CoursesTable';
import { ContentSection, PageContainer, PageTitle, TitleSection } from 'core/layout';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AnalyticsService } from 'utils/AnalyticsService';
import { CourseCreate } from 'views/course-create/CourseCreate';

export const CoursesPage = () => {
  const { t } = useTranslation();
  const endFocusRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const closeCourseCreateOverlay = () => {
    setShowOverlay(false);
  };

  const onSmartCreate = () => {
    setShowOverlay(false);
  };

  const openCourseCreateOverlay = () => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add new course' });
    setShowOverlay(true);
  };

  useDocumentTitle(t('Courses'));

  if (showOverlay) {
    return (
      <Modal
        show={showOverlay}
        element={<CourseCreate onCancel={closeCourseCreateOverlay} onSmartCreate={onSmartCreate} />}
      />
    );
  }

  return (
    <MainFrame>
      <PageContainer>
        <TitleSection>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <PageTitle>{t('Courses')}</PageTitle>
            <SkipContentButton endFocusRef={endFocusRef} />
          </div>
          <Restricted
            allowedRoles={['admin', 'course manager']}
            component={<PrimaryButton click={openCourseCreateOverlay}>{t('Add New Course')}</PrimaryButton>}
          />
        </TitleSection>
        <ContentSection>
          <CoursesTable />
          <EndFocus endFocusRef={endFocusRef} />
        </ContentSection>
      </PageContainer>
    </MainFrame>
  );
};

const MainFrame = styled.div`
  height: 100vh;
  display: flex;
  flex-grow: 1;
  border: none;
`;
