import { faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import assessmentIcon from 'assets/images/assessment-icon.svg';
import scormIcon from 'assets/images/scorm-icon.svg';
import surveyIcon from 'assets/images/survey-icon.svg';
import axios from 'axios';
import { DropDown, DropDownList, DropDownListItem } from 'components/DropDown';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Box, Content } from 'components/modals/common';
import { Restricted } from 'components/Restricted';
import { SelectDownArrow, useStyles } from 'components/tables/Controls';
import { Color } from 'core';
import { useUserRole } from 'hooks/useUserRole';
import * as _ from 'lodash';
import MaterialTable from 'material-table';
import { GoalType } from 'models/set';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { handleAddSetToCourse } from 'store/course-set';
import { handleGetPartnerSets } from 'store/partner-set';
import styled from 'styled-components';
import { getBaseAngularUrl } from 'utils/pathUtils';
import { ThemeProvider, createTheme } from '@mui/material'

interface Props {
  partnerId: string;
  value: any;
  index: any;
  courseId: string;
  onCancel: () => void;
  allowHardCopy: boolean;
}

interface ISearchContainerProps {
  hasSearchValue?: boolean;
}

interface ISetImageProps {
  goalType?: GoalType;
}

const ShareContentTableWrapper = styled.div`
  // hide the global checkbox in the column
  thead .MuiCheckbox-root {
    display: none;
  }

  // make the checkbox bigger
  tbody .MuiCheckbox-root {
    transform: scale(1.3);
  }
`;

const SharedContentToolbar = styled.div`
  // display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
  grid-gap: 0em 1em;
`;

const ContentTable = styled.div`
  margin-bottom: 2em;
`;

const SetImageGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const SetImage = styled.img`
  margin-right: 0.75em;
  width: 2.5em;
  height: 2.5em;
  ${(props: ISetImageProps) => (props.goalType === 'set' ? 'border-radius: 50%;' : '')}
  display: inline-block;
  vertical-align: top;
`;

const SetName = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const GrayText = styled.span`
  color: ${Color.reportGray};
`;

const Subtitle = styled.div`
  color: ${Color.reportGray};
  font-size: 0.8rem;
`;

const Pagination = styled.div`
  div {
    display: inline-block;
    margin-right: 1em;
  }
`;

const PaginationArrow = styled.div`
  vertical-align: text-bottom;
  color: ${Color.secondaryGray};
  &:hover {
    color: ${Color.primaryBlue};
  }
`;

const SearchContainer = styled.div`
  box-shadow: inset 0 1px 0.25em 0 rgba(0, 0, 0, 0.3);
  border: 1px solid transparent;
  display: grid;
  padding: 0.8rem 1.2rem;
  color: ${Color.secondaryGray};
  border-radius: 0.25em;

  ${(props: ISearchContainerProps) =>
    props.hasSearchValue
      ? `
      box-shadow: inset 0 0 .25em 0 rgba(51,58,236,.7);
      border: 1px solid rgba(51,58,236,.7);
      grid-template-columns: 1fr;

      input {
        color: ${Color.primaryBlue};
        font-weight: bold;
      }

      svg {
        display:none;
      }
    `
      : `
      grid-template-columns: 1em 1fr;
      grid-gap: 0 .5em;
    `}
`;

const SearchInput = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  font-family: inherit;

  ::placeholder {
    color: ${Color.reportGray};
    opacity: 1; // firefox
    font-weight: bold;
  }
`;

const SearchIcon = styled.div`
  svg {
    padding-top: 1px; // override
  }
`;

const FilterContainer = styled.div`
  display: none; // temporary until backend can support

  box-shadow: 0 0.5em 0.625em 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;

  padding: 8px 1.2rem;

  .MuiSelect-root .subtitle {
    display: none;
  }

  .MuiSelect-root:before {
    content: 'Show:';
    margin-right: 0.25em;
  }
`;

const Subtext = styled.div`
  font-size: 0.8rem;
  color: ${Color.reportGray};
  margin-top: 0.5em;
`;

export const SharedContentTable = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [goalType, setGoalType] = useState('' as GoalType);
  const [copyDisabled, setCopyDisabled] = useState(false);
  const sets = useSelector(getSets) || [];
  const setsMeta = useSelector(getSetsMeta) || {};
  const { totalPages = 0, totalCount = 0 } = setsMeta;
  const pageSize = 15;
  const checkedOnCurrentPage = {};
  const [checkedOnAllPages, setCheckedOnAllPages] = useState({} as any);
  const [showAddOptions, setShowAddOptions] = useState(false);
  const { t } = useTranslation();
  const { role, isSiteAdmin } = useUserRole();

  const subsetData = sets.map((set) => ({
    id: set.id,
    name: set.name,
    goalType: set.goalType,
    imageUrl: set.image?.smallUrl || set.image?.url,
    courseName: set.courseInformation?.courseName,
    numCourses: set.courseInformation?.numCourses,
    tableData: { checked: checkedOnAllPages[set.id]?.checked },
  }));

  useEffect(() => {
    const duration = searchText.length === 0 ? 0 : 800;
    const handler = setTimeout(
      () => dispatch(handleGetPartnerSets(pageNumber, props.partnerId, searchText, goalType)),
      duration
    );
    return () => clearTimeout(handler);
  }, [pageNumber, searchText, goalType, dispatch]);

  function saveChecked() {
    setCheckedOnAllPages(Object.assign(checkedOnAllPages, checkedOnCurrentPage));
  }

  function getChecked() {
    const merged = Object.assign(checkedOnAllPages, checkedOnCurrentPage);
    return Object.keys(merged).filter((row) => merged[row].checked);
  }

  function handleAddToCourseClick() {
    if (props.allowHardCopy && (['admin', 'course manager', 'content manager'].includes(role) || isSiteAdmin)) {
      setShowAddOptions(!showAddOptions);
    } else {
      addSetsToCourse();
    }
  }

  function incrementPage() {
    saveChecked();
    setPageNumber((page) => Math.min(totalPages, page + 1));
  }

  function decrementPage() {
    saveChecked();
    setPageNumber((page) => Math.max(1, page - 1));
  }

  function goalTypeText(type) {
    const texts = {
      assessment: t('Assessment'),
      survey: t('Survey'),
      scorm: t('SCORM'),
      set: t('Learning Set'),
    };

    return texts[type];
  }

  function goalTypeImage(rowData) {
    const images = {
      assessment: assessmentIcon,
      survey: surveyIcon,
      scorm: scormIcon,
      set: rowData.imageUrl,
    };

    return images[rowData.goalType];
  }

  function isSurveyChecked() {
    const rows = Object.assign(checkedOnAllPages, checkedOnCurrentPage);
    return !!_.findKey(rows, { checked: true, goalType: 'survey' });
  }

  function getSets(state) {
    const rawSets = state.data.meta[`/api/v3/partner/${props.partnerId}/sets`]?.data || [];
    const mySet = new Set();
    rawSets.map((set) => mySet.add(set.id));
    const builtSets = build(state.data, 'sets') || [];

    return _.sortBy(
      builtSets.filter((set) => mySet.has(set.id)),
      'name'
    );
  }

  function getSetsMeta(state) {
    return state.data.meta[`/api/v3/partner/${props.partnerId}/sets`]?.meta;
  }

  function onSelectionChange(selectedRows, selectedRow) {
    const ids = selectedRows.map((row) => row.id);

    sets.map((set) => {
      checkedOnCurrentPage[set.id] = { checked: ids.includes(set.id), goalType: set.goalType };
    });

    setCopyDisabled(isSurveyChecked());
  }

  function addSetsToCourse() {
    if (copyDisabled) {
      return;
    }
    getChecked().map((setId) => dispatch(handleAddSetToCourse(props.courseId, setId)));
    props.onCancel();
  }

  async function copySetsToCourse() {
    const jobIds = cloneSets(getChecked());

    const maxRetries = 5;
    let retries = 0;
    const handler = setInterval(async () => {
      for (const [jobId, status] of Object.entries(jobIds)) {
        if (!status) {
          const fetchRes = await axios.get(`/api/v3/set_clones/${jobId}`);
          if (fetchRes.data.status === 'completed') {
            jobIds[jobId] = true;
            dispatch(handleAddSetToCourse(props.courseId, fetchRes.data.target_set_id));
          }
        }
      }
      retries++;
      if (retries === maxRetries) {
        clearInterval(handler);
      }
    }, 1500);

    props.onCancel();
  }

  // clone = copy
  function cloneSets(setIds) {
    const jobIds = {};
    const data = { data: { attributes: { partner_id: props.partnerId, course_id: props.courseId } } };
    setIds.map(async (setId) => {
      const setCloneResponse = await axios.post(`/api/v3/sets/${setId}/set_clones`, data);
      jobIds[setCloneResponse.data.id] = false;
    });
    return jobIds;
  }

  function assignmentColumn(rowData) {
    const { id, goalType, name } = rowData;
    const image = goalTypeImage(rowData);
    const text = goalTypeText(goalType);
    return (
      <SetImageGrid>
        <div>{image && <SetImage src={image} goalType={goalType} />}</div>
        <div>
          <SetName>
            <a href={`${getBaseAngularUrl()}/edit/sets/${id}/edit#grid`} target="_blank">
              {name}
            </a>
          </SetName>
          <div>
            <GrayText>{text}</GrayText>
          </div>
        </div>
      </SetImageGrid>
    );
  }

  const defaultMaterialTheme = createTheme();

  return (
    <div>
      <Box style={{ textAlign: 'left' }}>
        <Content>
          <ShareContentTableWrapper>
            <SharedContentToolbar>
              <SearchContainer hasSearchValue={!!searchText}>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <SearchInput
                  type="text"
                  value={searchText}
                  placeholder={t('Search by title')}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    setPageNumber(1);
                  }}
                />
              </SearchContainer>
              <FilterContainer className="filterContainer">
                <Select
                  value={goalType}
                  onChange={(event) => setGoalType(event.target.value as GoalType)}
                  displayEmpty={true}
                  disableUnderline={true}
                  required={true}
                  className={classes.root}
                  classes={classes}
                  IconComponent={SelectDownArrow}
                >
                  <MenuItem value="">
                    <div>
                      <div className="title">{t('All shared assignments')}</div>
                      <Subtitle className="subtitle">{t('Any course can assign any of these assignments ')}</Subtitle>
                    </div>
                  </MenuItem>
                  <MenuItem value="sets">
                    <div>
                      <div className="title">{t('Learning sets only')}</div>
                      <Subtitle className="subtitle">
                        {t('Essential content, you may assign a practice goal for deeper learning')}
                      </Subtitle>
                    </div>
                  </MenuItem>
                  <MenuItem value="assessments">
                    <div>
                      <div className="title">{t('Assessments only')}</div>
                      <Subtitle className="subtitle">
                        {t('Essential content, you may assign a practice goal for deeper learning')}
                      </Subtitle>
                    </div>
                  </MenuItem>
                  <MenuItem value="survey">
                    <div>
                      <div className="title">{t('Surveys only')}</div>
                      <Subtitle className="subtitle">
                        {t('Essential content, you may assign a practice goal for deeper learning')}
                      </Subtitle>
                    </div>
                  </MenuItem>
                </Select>
              </FilterContainer>
            </SharedContentToolbar>

            <ContentTable>
              <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                  columns={[
                    {
                      title: t('ASSIGNMENT'),
                      field: 'name',
                      render: (rowData) => assignmentColumn(rowData),
                    },
                    {
                      title: t('COURSE'),
                      field: 'courseName',
                      render: (rowData) => (
                        <div>
                          <div>
                            <GrayText>{rowData.courseName}</GrayText>
                          </div>
                          {rowData.numCourses > 2 && (
                            <div>
                              <GrayText style={{ fontSize: '0.8em' }}>
                                {t('and')} {rowData.numCourses - 1} {t('more courses')}
                              </GrayText>
                            </div>
                          )}
                          {rowData.numCourses === 2 && (
                            <div>
                              <GrayText style={{ fontSize: '0.8em' }}>{t('and one other course')}</GrayText>
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  data={subsetData}
                  title={''}
                  options={{
                    toolbar: false,
                    selection: true,
                    pageSize: 15,
                    paging: false,
                    sorting: false,
                  }}
                  onSelectionChange={(rows, row) => onSelectionChange(rows, row)}
                />
              </ThemeProvider>
            </ContentTable>

            <Pagination>
              <div>
                {t('Shared Content Table Assignment Pagination Text', {
                  firstItem: pageSize * (pageNumber - 1) + 1,
                  lastItem: Math.min(pageSize * pageNumber, totalCount || pageSize),
                  totalCount,
                })}
              </div>
              <PaginationArrow onClick={decrementPage}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ cursor: 'pointer', verticalAlign: 'middle' }} />
              </PaginationArrow>
              <PaginationArrow onClick={incrementPage}>
                <FontAwesomeIcon icon={faChevronRight} style={{ cursor: 'pointer', verticalAlign: 'middle' }} />
              </PaginationArrow>
            </Pagination>
          </ShareContentTableWrapper>
        </Content>
      </Box>
      {showAddOptions && (
        <DropDown top={copyDisabled ? -14.5 : -11.5} left={9.5}>
          <DropDownList>
            <Restricted
              allowedRoles={['admin', 'course manager', 'content manager']}
              component={
                <DropDownListItem>
                  <button onClick={copySetsToCourse}>
                    <div>{t('Hard copy this assignment')}</div>
                    {copyDisabled && (
                      <Subtext>
                        <b>{t('Required for surveys')}</b>
                      </Subtext>
                    )}
                    <Subtext>{t('Allows you to edit, and prevents edits from the owners course')}</Subtext>
                  </button>
                </DropDownListItem>
              }
            />
            <DropDownListItem>
              <button
                onClick={copyDisabled ? undefined : addSetsToCourse}
                style={
                  copyDisabled
                    ? {
                      background: Color.lightGray,
                      cursor: 'initial',
                      opacity: 0.5,
                      color: 'inherit',
                    }
                    : {}
                }
              >
                <div>{t('Mirror changes')}</div>
                {copyDisabled && (
                  <Subtext>
                    <b>{t('Not available for surveys')}</b>
                  </Subtext>
                )}
                <Subtext>{t('Updated content will appear automatically in your course')}</Subtext>
              </button>
            </DropDownListItem>
          </DropDownList>
        </DropDown>
      )}

      <ConfirmOrCancel
        confirmText={t('Add to my course')}
        handleConfirmClick={handleAddToCourseClick}
        confirmIsDisabled={false}
        confirmIsPending={false}
        handleCancelClick={props.onCancel}
      />
    </div>
  );
};
