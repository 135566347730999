import axios from 'axios';
import { IImage } from 'models/image';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

// TODO: support creating facet for region question
// example payload:
// {
//  "data": {
//    "attributes": {
//      "association-question-html": "this is the question text",
//      "quiz-settings": {
//        "enabled": [
//          "region_reverse_multiple_choice",
//          "region_reverse_click_in_the_dark"
//        ]
//      }
//    },
//    "relationships": {
//      "association": {
//        "data": {
//          "attributes": {
//            "explanation-html": "<p>explanation here</p>"
//          }
//        }
//      },
//      "anchor": {
//        "data": {
//          "attributes": {
//            "region-polygon": "0.850263852242744 0.367153144239226, 0.8416032253106035 0.4168453166226913, 0.8179419525065963 0.4532225115458589, 0.7856200527704486 0.46653748900615655, 0.7532981530343008 0.4532225115458589, 0.7296368802302936 0.41684531662269136, 0.7209762532981531 0.367153144239226, 0.7296368802302936 0.3174609718557608, 0.7532981530343008 0.2810837769325932, 0.7856200527704486 0.26776879947229554, 0.8179419525065963 0.2810837769325932, 0.8416032253106035 0.3174609718557607"
//          }
//        }
//      }
//    }
//  }
//}

interface CreateFacetParams {
  itemId: string;
  payload: CreateFacetPayload | null;
}

interface CreateFacetPayload {
  relationships: {
    association: {
      data: {
        id: number;
        attributes: CreateFacetAttributes;
        relationships: {
          image: {
            data: {
              id: number;
              type: 'images';
              attributes: IImage;
            } | null;
          };
        };
      };
    };
  };
}

interface CreateFacetAttributes {
  text: string;
  'text-html': string;
  explanation: string;
  'explanation-html': string;
}

const createFacet = async ({ itemId, payload }: CreateFacetParams) => {
  const response = await axios.post(
    `/api/v3/items/${itemId}/facets?include=anchor,association,anchor.image,association.image,facets.tags`,
    { data: { ...payload } }
  );
  return normalizeJSONAPIResponse(response.data);
};

export const useCreateFacet = (itemId: string = '', setId: string = '') => {
  return useMutation(createFacet, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
  });
};
