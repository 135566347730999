import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

const deletePartnerUserTagType = ({ partnerId, tagId }: { partnerId: string; tagId: string }) => {
  return axios.delete(`/api/v3/partners/${partnerId}/partner_user_tag_types/${tagId}`);
};

export const useDeletePartnerUserTagType = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();

  return useMutation(deletePartnerUserTagType, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to delete custom user field',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['partnerUserTagTypes', user?.primaryPartner.id]);
    },
  });
};
