import { Box, Slider } from '@mui/material';
import { MemreFlexBox, MemreText } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import { useState } from 'react';

export const DefaultGoalLevel = () => {
  const user = useCurrentUser();
  const initialGoalLevel = user?.primaryPartner.partnerSettings.defaultGoal || 1.0;
  const [defaultGoalLevel, setDefaultGoalLevel] = useState<number>(initialGoalLevel);
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    const goalLevel = Array.isArray(newValue) ? newValue[0] : newValue;
    setDefaultGoalLevel(goalLevel);
    debouncedPartnerUpdate({
      partner_settings: {
        default_goal: goalLevel,
      },
    });
  };

  const marks = [
    { value: 0.0, label: '0' },
    { value: 0.5, label: '0.5' },
    { value: 1.0, label: '1' },
    { value: 1.5, label: '1.5' },
    { value: 2.0, label: '2' },
    { value: 2.5, label: '2.5' },
    { value: 3.0, label: '3' },
  ];

  const getMasteryRegion = (goal: number): string => {
    if (goal < 1) {
      return 'Exposure';
    } else if (goal < 2) {
      return 'Proficiency';
    } else {
      return 'Mastery';
    }
  };

  const getCommitmentText = (goal: number): string => {
    if (goal < 1.0) {
      return 'Requires a few days of review';
    } else if (goal < 1.5) {
      return 'Requires up to a week of brief daily reviews';
    } else if (goal < 2) {
      return 'Requires up to 1-2 weeks of brief daily reviews';
    } else if (goal < 2.5) {
      return 'Requires about 2-3 weeks of brief reviews (spaced increasingly further apart)';
    } else {
      return 'Requires about 3-4 weeks of brief reviews (spaced increasingly further apart)';
    }
  };

  const masteryRegion = getMasteryRegion(defaultGoalLevel);
  const commitmentText = getCommitmentText(defaultGoalLevel);

  return (
    <ConfigItem title="Default Goal Level">
      <MemreFlexBox direction="column" gap={3}>
        <MemreText variant="body1">
          Set the default mastery goal level for all courses. This level determines the depth of learning your learners will achieve.
        </MemreText>
        <Box sx={{ width: '100%', mt: 2 }}>
          <MemreText variant="subtitle1">Current Goal Level: {defaultGoalLevel.toFixed(1)}</MemreText>
          <Slider
            value={defaultGoalLevel}
            onChange={handleSliderChange}
            aria-labelledby="goal-level-slider"
            step={0.1}
            min={0}
            max={3}
            marks={marks}
            valueLabelDisplay="on"
            color="secondary"
          />
          <MemreText variant="body1" sx={{ mt: 2 }}>
            Mastery Region: <strong>{masteryRegion}</strong>
          </MemreText>
          <MemreText variant="body2" sx={{ mt: 1 }}>
            {commitmentText}
          </MemreText>
        </Box>
      </MemreFlexBox>
    </ConfigItem>
  );
};
