import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type TextSize = 'small' | 'normal';
type DisplayStyle = 'block' | 'inline';

interface IContentText {
  text?: string;
  textDecoration?: string;
  size?: TextSize;
  display?: DisplayStyle;
}

const ContentTextWrapper: any = styled.p`
  font-size: ${(props: any) => (props.size === 'small' ? '0.8em' : '1em')};
  color: ${(props: any) => (props.size === 'small' || !props.text ? '#6b7694' : '#2e353f')};
  display: ${(props: any) => (props.display === 'inline' ? 'inline-block' : 'block')};
  font-style: ${(props: any) => (props.text ? 'normal' : 'italic')};
  margin: 0;
  text-decoration: ${(props: any) => props.textDecoration || 'none'};
`;

export const ContentText = React.memo<IContentText>(props => {
  const { t } = useTranslation();
  return (
    <ContentTextWrapper
      size={props.size}
      color={props.size === 'small' || !props.text ? '#6b7694' : '#2e353f'}
      display={props.display}
      text={props.text}
      textDecoration={props.textDecoration}
    >
      {props.text || t('N/A')}
    </ContentTextWrapper>
  );
});
