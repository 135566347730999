import { AttachIc } from 'components/item-editor/AttachIc';
import { CautionTape } from 'components/item-editor/CautionTape';
import { Steps } from 'components/item-editor/sequence/Steps';
import { Title } from 'components/item-editor/sequence/Title';
import { useItemEditorStore } from 'components/item-editor/store';
import styled from 'styled-components';

export const SequenceEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <Container>
      <CautionTape />
      <Title />
      <Steps />
      <AttachIc />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
