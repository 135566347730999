import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getOAuthClients = async (_key: string, partnerSlug: string) => {
  const response = await axios.get(
    `/api/v3/partners/${partnerSlug}/oauth2_clients`
  );
  return normalizeJSONAPIResponse(response.data);
};

export const useOAuthClients = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;

  return useQuery(['oauthClients', partner.slug], getOAuthClients, {
    enabled: partner,
  });
};
