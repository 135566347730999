import { MemreSection, MemreSectionHeading } from 'components/core';
import { useState } from 'react';

export default function CourseContentTabs({ tabs }) {
  const [tabIdx, setTabIdx] = useState(0);

  const tabStrings = tabs.map((tab) => tab.title);

  return (
    <MemreSection>
      <MemreSectionHeading text={tabStrings} onChange={setTabIdx} />
      {tabs[tabIdx].element}
    </MemreSection>
  );
}
