import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroupsStore } from 'pages/admin-dashboard/groups/store';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getGroups = async (_key: string, partnerId: string | null) => {
  if (!partnerId) return;

  let groups: Array<any> = [];

  const page = 1;
  const pageSize = 200;

  const endpoint = `/api/v3/partners/${partnerId}/groups?page[number]=${page}&page[size]=${pageSize}`;
  try {
    const response = await axios.get(endpoint);
    const totalPages = response.data.meta['total-pages'];
    groups.push(...normalizeJSONAPIResponse(response.data));

    for (let i = 2; i <= totalPages; i++) {
      const res = await axios.get(`/api/v3/partners/${partnerId}/groups?page[number]=${i}&page[size]=${pageSize}`);
      groups.push(...normalizeJSONAPIResponse(res.data));
    }

    return groups;
  } catch (err) {
    console.error(err);
  }
};

export const useGroups = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupsStore();

  return useQuery(['groups', partner?.id, pageNumber, pageSize, sortColumn, sortDirection, name], getGroups, {
    enabled: !!partner,
  });
};
