import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface ILink extends WithTranslation {
  targetRef: any;
}

const Link = styled.a`
  display: block;
  position: absolute;
  white-space: nowrap;
  text-align: right;
  margin-left: 25px;
  margin-right: 25px;
  text-decoration: none;
  right: 0;
  top: -1.5em;
`;
const FSLabel = styled.span`
  padding-left: 0.5em;
`;

class FullScreenLink extends React.Component<ILink> {
  public render() {
    const { targetRef, t } = this.props;

    return (
      <Link href="#" onClick={this.showFullScreen(targetRef)}>
        <FontAwesomeIcon icon={faExpand} />
        <FSLabel>{t('Show Full Screen')}</FSLabel>
      </Link>
    );
  }
  private showFullScreen(targetRef) {
    return () => {
      const target = targetRef.current;
      const requestFullScreen =
        target.requestFullscreen ||
        target.msRequestFullscreen ||
        target.mozRequestFullScreen ||
        target.webkitRequestFullscreen;
      requestFullScreen.bind(target)();

      return false;
    };
  }
}

export default withTranslation()(FullScreenLink);
