import axios from 'axios';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import getTimeDenomination, { DENOMINATION } from 'utils/getTimeDenomination';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

type KnowledgeBankItem = {
  id: number;
  name: string;
  goodForNow: number;
  distanceToGoal: number;
  seeNextAt: string;
  totalStudyTimeMillis: number;
  sinceLastStudy: number;
};

type UseKnowledgeBankData = {
  data: KnowledgeBankItem[];
  isLoading: boolean;
  isStudentsView: boolean;
};

const fetchSet = async (setId: string) => {
  if (!setId) {
    return [];
  }
  try {
    const response = await axios.get(`/api/v4/sets/${setId}/study_items`);

    return normalizeJSONAPIResponse(response.data);
  } catch (error) {
    console.error('Error fetching set data:', error);
    throw error;
  }
};

const fetchAggregateKnowledgeBank = async (userId: string, setId: string) => {
  if (!userId || !setId) {
    return [];
  }
  try {
    const response = await axios.get(`/api/v2/users/${userId}/sets/${setId}/aggregate_memory_bank`);
    return response.data;
  } catch (error) {
    console.error('Error fetching aggregate knowledge bank data:', error);
    throw error;
  }
};

const fetchKnowledgeBank = async (groupId: string, setId: string, userId: string | null) => {
  if (!setId) {
    return [];
  }
  const endpoint = userId
    ? `/api/v2/users/${userId}/sets/${setId}/items_memory_bank`
    : `/api/v2/groups/${groupId}/sets/${setId}/items_memory_bank`;

  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching knowledge bank data:', error);
    throw error;
  }
};

export const useKnowledgeBank = (groupId: string, setId: string, userId: string | null): UseKnowledgeBankData => {
  const { data: allKnowledgeBankData, isLoading: isLoadingKnowledgeBank } = useQuery(
    ['knowledgeBank', groupId, setId, userId],
    () => fetchKnowledgeBank(groupId, setId, userId),
    { enabled: !!groupId && !!setId }
  );

  const { data: aggregateKnowledgeBankData, isLoading: isLoadingAggregate } = useQuery(
    ['aggregateKnowledgeBank', userId, setId],
    () => fetchAggregateKnowledgeBank(userId!, setId),
    { enabled: !!userId && !!setId }
  );

  const { data: assessmentData, isLoading: isLoadingAssessment } = useQuery(['set', setId], () => fetchSet(setId), {
    enabled: !!setId,
  });

  const [data, setData] = useState<KnowledgeBankItem[]>([]);
  const isStudentsView = !!userId;

  const getQuestionText = (id: string): string | undefined =>
    assessmentData.find((i) => i.memoryGuid === id)?.questionShortenedText;

  useEffect(() => {
    if (isLoadingKnowledgeBank || isLoadingAggregate || isLoadingAssessment) {
      return;
    }

    if (allKnowledgeBankData) {
      const now = Date.now();
      const time = (t) => new Date(t).getTime();
      const convertToTimeSince = (d) => now - time(d);
      const convertToTimeTo = (d) => time(d) - now;
      const convertToPercent = (d: number): number => Math.min(100, _.round(d * 100));

      const allLastStudyTime = allKnowledgeBankData.response
        .filter((d) => d.last_study_time)
        .map((d) => convertToTimeSince(d.last_study_time));

      const topLastStudy = Math.max(...allLastStudyTime);
      const [lastStudyDenom, convertLastStudyToDenom] = getTimeDenomination(topLastStudy);

      const allTotalStudyTimes = allKnowledgeBankData.response.map((d) => d.total_study_time_millis);
      const topTotalStudyTime = Math.max(...allTotalStudyTimes);
      const [totalStudyDenom, convertTotalStudyToDenom] = getTimeDenomination(topTotalStudyTime);

      const allSeeNextAt = allKnowledgeBankData.response.map((d) => Math.max(0, convertToTimeTo(d.see_next_at)));
      const topNextAt = Math.max(...allSeeNextAt);
      const [nextAtDenomRaw, convertNextAtDenom] = getTimeDenomination(topNextAt);
      const nextAtDenom = topNextAt > 0 ? nextAtDenomRaw : DENOMINATION.DAYS;

      const getSinceLastStudySession = (d) => convertLastStudyToDenom(now - time(d.last_study_time));
      const getNextAt = (d) => convertNextAtDenom(time(d.see_next_at) - now);

      if (isStudentsView && aggregateKnowledgeBankData && assessmentData) {
        const goal = aggregateKnowledgeBankData.response.find((d) => d.id === parseInt(setId, 10))?.scoring_goal ?? 1;

        setData(
          allKnowledgeBankData.response.map((item, key) => ({
            id: key,
            name: getQuestionText(item.learning_engine_guid) || '',
            goodForNow: convertToPercent(item.current_retention),
            distanceToGoal: convertToPercent(item.level / goal),
            seeNextAt: item.see_next_at,
            totalStudyTimeMillis: convertTotalStudyToDenom(item.total_study_time_millis),
            totalStudyDenom,
            sinceLastStudy: getSinceLastStudySession(item),
            lastStudyDenom,
            nextAt: getNextAt(item),
            nextAtDenom,
          }))
        );
      } else {
        const filteredKnowledgeBankData = allKnowledgeBankData.response.filter((student) => student.progress > 0);

        setData(
          filteredKnowledgeBankData.map((item) => ({
            id: item.user.id,
            name: item.user.name,
            goodForNow: convertToPercent(item.average_current_retention),
            distanceToGoal: convertToPercent(item.score / item.scoring_goal),
            seeNextAt: item.see_next_at,
            totalStudyTimeMillis: convertTotalStudyToDenom(item.total_study_time_millis),
            totalStudyDenom,
            sinceLastStudy: getSinceLastStudySession(item),
            lastStudyDenom,
            nextAt: getNextAt(item),
            nextAtDenom,
            itemCount: {
              secure: item.on_track,
              securePercent: `${(100 / item.items_count) * item.on_track}%`,
              fading: item.studied_items_count - item.on_track,
              fadingPercent: `${(100 / item.items_count) * (item.studied_items_count - item.on_track)}%`,
              notSeen: item.items_count - item.studied_items_count,
              total: item.items_count,
            },
          }))
        );
      }
    }
  }, [
    isLoadingKnowledgeBank,
    isLoadingAggregate,
    isLoadingAssessment,
    allKnowledgeBankData,
    aggregateKnowledgeBankData,
    assessmentData,
    setId,
    userId,
  ]);

  const isLoading = isLoadingKnowledgeBank || isLoadingAggregate || isLoadingAssessment;

  return { data, isLoading, isStudentsView };
};
