import axios from 'axios';
import { useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

type SendGroupMailerParams = {
  groupId: string;
  payload: GroupMailerPayload;
};

type GroupMailerPayload = {
  subject: string;
  body: string;
  recipients: Array<number>;
};

const sendGroupMailer = async (params: SendGroupMailerParams) => {
  const response = await axios.post(`/api/v3/groups/${params.groupId}/mailers`, params.payload);
  return normalizeJSONAPIResponse(response.data);
};

export const useSendGroupMailer = () => {
  const { makeToast } = useStore();

  return useMutation(sendGroupMailer, {
    onSuccess: () => {
      makeToast({
        text: 'Mail sent successfully',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
