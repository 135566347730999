import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export const MemberIdDownloadBanner = () => {
  const user = useCurrentUser();
  const [uploads, setUploads] = useState<any[]>([]);

  // Fetch uploads with member ID passwords
  const fetchUploads = async () => {
    try {
      const response = await axios.get(
        `/api/v3/partners/${user.primaryPartner.id}/user_csv_uploads?include_memberid_passwords=true`
      );
      setUploads(response.data.data);
    } catch (error) {
      console.error('Error fetching uploads:', error);
    }
  };

  useEffect(() => {
    fetchUploads();
    const interval = setInterval(fetchUploads, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, []);

  // Handle download of member ID passwords
  const handleDownload = (uploadId: string, memberIdPasswords: string) => {
    const blob = new Blob([memberIdPasswords], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `memberid_passwords_${uploadId}.csv`;
    link.click();
  };

  if (!uploads || uploads.length === 0 || uploads.every((upload) => !upload.attributes['memberid-passwords'])) {
    return null; // No uploads to display
  }

  return (
    <BannerContainer>
      <p>
        You have new member ID passwords available for download. Please click the button(s) below to download the CSV
        file(s).
      </p>
      {uploads.map((upload) => {
        const memberIdPasswords = upload.attributes['memberid-passwords'];
        if (memberIdPasswords) {
          return (
            <BannerItem key={upload.id}>
              <p>
                <strong>CSV ID:</strong> {upload.id}
              </p>
              <p>
                <strong>Created At:</strong> {new Date(upload.attributes['created-at']).toLocaleString()}
              </p>
              <DownloadButton onClick={() => handleDownload(upload.id, memberIdPasswords)}>
                Download {user.primaryPartner.partnerSettings.memberIdSlug || 'Member ID'} Passwords
              </DownloadButton>
            </BannerItem>
          );
        }
        return null;
      })}
    </BannerContainer>
  );
};

// Styled Components
const BannerContainer = styled.div`
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const BannerItem = styled.div`
  margin-bottom: 10px;
`;

const DownloadButton = styled.button`
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
