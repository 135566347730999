import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePeopleStore } from 'pages/admin-dashboard/people/store';
import { useQuery } from 'react-query';

const getPartnerUsers = async (
  _key: string,
  partnerId: string,
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
  query: string
) => {
  const endpoint: string = `/api/v3/partners/${partnerId}/users`;
  const sortParam = sortDirection === 'desc' ? `-${sortColumn}` : sortColumn;
  const queryParams = `include=user_partner_id&page[size]=${pageSize}&page[number]=${pageNumber}&query=${query}&sort=${sortParam}&tab=default`;
  const response = await axios.get(`${endpoint}?${queryParams}`);
  console.log('getPartnerUsers :: response', response);

  return response;
};

export const usePartnerUsers = () => {
  const user = useCurrentUser();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = usePeopleStore();

  return useQuery(
    ['partnerUsers', user?.primaryPartner.id, pageNumber, pageSize, sortColumn, sortDirection, name],
    getPartnerUsers,
    {
      enabled: user?.primaryPartner.id,
    }
  );
};
