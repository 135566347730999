import { faArrowDown, faArrowUp, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { ThemeProvider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MemreButton, MemreFlexBox } from 'components/core';
import { Pagination } from 'components/reports/controls/Pagination';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import { Color } from 'core';
import { useCreateGroupUser } from 'hooks/useCreateGroupUser';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDeleteGroupUser } from 'hooks/useDeleteGroupUser';
import { useGroupMembers } from 'hooks/useGroupMembers';
import { usePaginatedMeta } from 'hooks/usePaginatedMeta';
import { useSendGroupMailer } from 'hooks/useSendGroupMailer';
import { AddGroupMembersModal } from 'pages/admin-dashboard/groups/AddGroupMembersModal';
import { EmailGroupMembersModal } from 'pages/admin-dashboard/groups/EmailGroupMembersModal';
import { GroupMembersTableToolbar } from 'pages/admin-dashboard/groups/GroupMembersTableToolbar';
import { useGroupMembersStore } from 'pages/admin-dashboard/groups/store';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useState } from 'react';
import styled from 'styled-components';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

type Props = {
  groupId: string;
};

export const GroupMembersTable = ({ groupId }: Props) => {
  // Data fetching hooks
  const { data: groupMembersResponse } = useGroupMembers(groupId);
  const groupMembers = normalizeJSONAPIResponse(groupMembersResponse?.data);
  const { totalCount, totalPages } = usePaginatedMeta(groupMembersResponse);
  const [deleteGroupUser] = useDeleteGroupUser(groupId);
  const [sendGroupMailer] = useSendGroupMailer();
  const [createGroupUser] = useCreateGroupUser(groupId);
  const user = useCurrentUser();
  const memberIdEnabled = user?.primaryPartner?.partnerSettings?.memberIdEnabled;
  const memberIdSlug = user?.primaryPartner?.partnerSettings?.memberIdSlug || 'Member ID';

  // Pagination and sorting state
  const { pageNumber, setPageNumber, pageSize, setPageSize, sortDirection, setSortDirection } = useGroupMembersStore();

  // UI state
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailRecipients, setEmailRecipients] = useState<any[]>([]);

  // Pagination handlers
  const goToPageOne = () => setPageNumber(1);
  const goToNextPage = () => !!totalPages && pageNumber < totalPages && setPageNumber(pageNumber + 1);
  const goToPreviousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
  const goToLastPage = () => !!totalPages && setPageNumber(totalPages);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1);
  };

  // Sorting handler
  const handleSortingChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setPageNumber(1);
  };

  // Selection handlers
  const handleSelectAllMembers = () => {
    if (selectedMembers.length === groupMembers.length) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(groupMembers.map((member: any) => member.id));
    }
  };

  const handleSelectMember = (memberId: string) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(memberId) ? prevSelected.filter((id) => id !== memberId) : [...prevSelected, memberId]
    );
  };

  // Add Members handlers
  const handleOpenAddMembersModal = () => setShowAddMembersModal(true);
  const handleCloseAddMembersModal = () => setShowAddMembersModal(false);

  const handleAddMembers = async (identifiers: string[], addBy: 'email' | 'memberId') => {
    try {
      for (const identifier of identifiers) {
        if (addBy === 'memberId') {
          await createGroupUser({
            groupId,
            memberId: identifier,
          });
        } else {
          await createGroupUser({
            groupId,
            userEmail: identifier,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseAddMembersModal();
    }
  };

  // Delete Member handler
  const handleDeleteMember = async (memberId: string) => {
    try {
      await deleteGroupUser({
        groupId,
        userId: memberId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Email Members handlers
  const handleOpenEmailModal = (recipients?: any[]) => {
    if (recipients) {
      setEmailRecipients(recipients);
    } else {
      const selected = groupMembers.filter((member: any) => selectedMembers.includes(member.id));
      setEmailRecipients(selected);
    }
    setShowEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
    setEmailRecipients([]);
  };

  const handleSendEmail = async (subject: string, body: string) => {
    try {
      const recipientIds = emailRecipients.map((member: any) => parseInt(member.id));
      await sendGroupMailer({
        groupId,
        payload: {
          subject,
          body,
          recipients: recipientIds,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseEmailModal();
    }
  };

  // Table columns
  const columns: ColumnDef<any>[] = [
    {
      id: 'select',
      header: () => (
        <input
          type="checkbox"
          checked={groupMembers && selectedMembers.length === groupMembers.length}
          onChange={handleSelectAllMembers}
        />
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedMembers.includes(row.original.id)}
          onChange={() => handleSelectMember(row.original.id)}
        />
      ),
    },
    {
      id: 'name',
      accessorFn: (row) => row.name,
      cell: ({ row }) => <p>{row.original.name}</p>,
      header: () => (
        <ColumnHeader title="Name" sort="name" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'email',
      accessorFn: (row) => row.email,
      cell: ({ row }) => <p>{row.original.email}</p>,
      header: () => <ColumnHeader title="Email" sort="" sortDirection={sortDirection} onSort={() => { }} />,
    },
    {
      id: 'actions',
      header: () => <ColumnHeader title="Actions" sort="" sortDirection={sortDirection} onSort={() => { }} />,
      cell: ({ row }) => (
        <ActionButtons>
          <Tooltip title="Email Member">
            <IconButton title="Email Member" onClick={() => handleOpenEmailModal([row.original])} size="small" style={{ padding: '0.5rem', color: Color.mainBlue }}>
              <FontAwesomeIcon icon={faEnvelope} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Member">
            <IconButton title="Delete Member" onClick={() => handleDeleteMember(row.original.id)} size="small" style={{ padding: '0.5rem', color: Color.veryDarkOrange }}>
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
        </ActionButtons>
      ),
    },
  ];

  const table = useReactTable({
    data: groupMembers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (!['name'].includes(header)) {
      console.warn('Cannot sort by anything other than name');
      return;
    }
    handleSortingChange();
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        {/* Toolbar with Add Members and Email Members buttons */}
        <MemreFlexBox gap={1} direction="row" align="center" justify="space-between" sx={{ mb: 2 }}>
          <GroupMembersTableToolbar />
          <div>
            <MemreButton
              text="Add Members"
              onClick={handleOpenAddMembersModal}
              color="secondary"
              variant="contained"
              iconName="plus"
              size="medium"
              sx={{ mr: 1 }}
            />
            <MemreButton
              text="Email Members"
              onClick={() => handleOpenEmailModal()}
              disabled={selectedMembers.length === 0}
              color="secondary"
              variant="outlined"
              iconName="mail"
              size="medium"
            />
          </div>
        </MemreFlexBox>
        <StyledTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} />
        </StyledTable>
      </TableContainer>

      {/* Pagination Component */}
      <Pagination
        totalCount={totalCount}
        page={pageNumber}
        pageSize={pageSize}
        goToPageOne={goToPageOne}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        onPageSizeChange={handlePageSizeChange}
      />

      {/* Add Members Modal */}
      <AddGroupMembersModal
        isOpen={showAddMembersModal}
        onClose={handleCloseAddMembersModal}
        onAddMembers={handleAddMembers}
        memberIdEnabled={!!memberIdEnabled}
        memberIdSlug={memberIdSlug}
      />

      {/* Email Modal */}
      <EmailGroupMembersModal
        isOpen={showEmailModal}
        onClose={handleCloseEmailModal}
        recipients={emailRecipients}
        onSendEmail={handleSendEmail}
      />
    </ThemeProvider>
  );
};

const TableContainer = styled.div`
  margin-top: 20px;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

/** Components **/

interface ColumnHeaderProps {
  title: string;
  sort: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
}

const ColumnHeader = ({ title, sort, sortDirection, onSort }: ColumnHeaderProps) => (
  <TableHeaderContainer
    style={Object.assign({ justifyContent: 'left' }, sort === '' ? {} : { cursor: 'pointer' })}
    onClick={onSort}
  >
    <TableHeaderText>{title}</TableHeaderText>
    {sort === 'name' && <SortArrow icon={sortDirection === 'desc' ? faArrowDown : faArrowUp} />}
  </TableHeaderContainer>
);
