import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, LinearProgress, TextField, ThemeProvider, Tooltip, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { Color } from 'core';
import { useCreateAssignment } from 'hooks/useCreateAssignment';
import { useCurrentUser } from 'hooks/useCurrentUser';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useState } from 'react';
import { createImage } from 'utils/createImage';
import useStore from 'zstore';

type ScormImportStatus = 'idle' | 'uploading' | 'importing' | 'complete' | 'error';

type Props = {
  courseId: string;
  onCancel: () => void;
};

export const UploadSCORM: React.FC<Props> = ({ onCancel, courseId }) => {
  const user = useCurrentUser();
  const muiTheme = useTheme();

  const [scormFile, setScormFile] = useState<File | null>(null);
  const [scormStatus, setScormStatus] = useState<ScormImportStatus>('idle');
  const [scormError, setScormError] = useState<string | null>(null);
  const [scormPackageId, setScormPackageId] = useState<string | null>(null);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageId, setImageId] = useState<string | null>(null);
  const [imageUploading, setImageUploading] = useState(false);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitting, setSubmitting] = useState(false);

  const [scormDragOver, setScormDragOver] = useState(false);
  const [imageDragOver, setImageDragOver] = useState(false);

  const [createAssignment] = useCreateAssignment(courseId);

  const { makeToast } = useStore();

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!scormFile) newErrors.scormFile = 'SCORM package is required.';
    if (!title.trim()) newErrors.title = 'Title is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSCORMFile = async (files: FileList | null) => {
    if (!files || files.length === 0) return;
    const file = files[0];

    setScormFile(file);
    setErrors((prev) => ({ ...prev, scormFile: '' }));
    setScormStatus('uploading');
    setScormError(null);

    try {
      const pkgId = await uploadSCORMPackage(file);
      setScormStatus('importing');
      await pollSCORMImportStatus(pkgId);
      setScormStatus('complete');
      setScormPackageId(pkgId);
    } catch (error: any) {
      console.error('SCORM upload error:', error);
      setScormStatus('error');
      setScormError(error?.message || 'Failed to import SCORM package. Please try again.');
    }
  };

  const handleImageFile = async (files: FileList | null) => {
    if (!files || files.length === 0) return;
    const file = files[0];
    setImageUploading(true);
    try {
      const image = await createImage(file);
      setImageId(image.id);
      setImageFile(file);
    } catch (err) {
      console.error('Error uploading image:', err);
    } finally {
      setImageUploading(false);
    }
  };

  const removeImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setImageFile(null);
    setImageId(null);
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    if (scormStatus === 'uploading' || scormStatus === 'importing') return;

    setSubmitting(true);
    try {
      const payload = {
        name: title.trim(),
        description: description.trim() || null,
        scorm_package_id: scormPackageId,
        icon_image_id: imageId || null,
        partner_id: user?.primaryPartner.id,
      };
      const response = await axios.post('/api/v3/sets', payload);
      const setId = response.data.data.id;
      await createAssignment({
        courseId,
        payload: {
          course_id: courseId,
          id: setId,
        },
      });
      onCancel();
    } catch (err) {
      console.error('Error creating set:', err);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleClickInput = (accept: string, handler: (files: FileList | null) => void) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.onchange = (e) => handler((e.target as HTMLInputElement).files);
    input.click();
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    setDragOver: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (setDragOver: React.Dispatch<React.SetStateAction<boolean>>) => {
    setDragOver(false);
  };

  const handleSCORMDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setScormDragOver(false);
    handleSCORMFile(e.dataTransfer.files);
  };

  const handleImageDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setImageDragOver(false);
    handleImageFile(e.dataTransfer.files);
  };

  let scormBorderColor: string;

  if (scormStatus === 'error') {
    scormBorderColor = muiTheme.palette.error.main;
  } else if (scormDragOver) {
    scormBorderColor = muiTheme.palette.primary.light;
  } else if (errors.scormFile && !scormFile) {
    scormBorderColor = muiTheme.palette.error.main;
  } else {
    scormBorderColor = muiTheme.palette.grey[400];
  }

  const baseDropZoneStyles = {
    border: '2px dashed',
    borderRadius: '8px',
    padding: '24px',
    textAlign: 'center' as const,
    color: muiTheme.palette.grey[700],
    cursor: 'pointer',
    transition: 'border-color 0.2s',
    position: 'relative' as const,
    minHeight: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: submitting ? 0.6 : 1,
    pointerEvents: submitting ? 'none' : 'auto',
    backgroundColor: '#fafafa',
  };

  const scormDropStyles = {
    ...baseDropZoneStyles,
    borderColor: scormBorderColor,
  };

  const imageDropStyles = {
    ...baseDropZoneStyles,
    borderColor: imageDragOver ? muiTheme.palette.primary.light : muiTheme.palette.grey[400],
    backgroundColor: '#fff',
    border: imageFile && !imageUploading ? 'none' : '2px dashed',
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '760px',
          margin: '0 auto',
          border: '1px solid #ddd',
          padding: '24px',
          borderRadius: '8px',
          backgroundColor: '#fff',
          position: 'relative',
        }}
      >
        {submitting && (
          <>
            <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 2000 }} />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: 'rgba(255,255,255,0.5)',
                zIndex: 1000,
              }}
            />
          </>
        )}

        <MemreText variant="h3" sx={{ mb: 3, fontWeight: 600 }}>
          Upload SCORM
        </MemreText>

        <MemreFlexBox direction="column" gap={3}>
          <Box>
            <MemreText variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              SCORM Package <span style={{ color: muiTheme.palette.error.main }}>*</span>
            </MemreText>
            <Tooltip title="A SCORM package is a ZIP file containing e-learning content. Drag or select a .zip file.">
              <Box
                sx={scormDropStyles}
                onDragOver={(e) => handleDragOver(e, setScormDragOver)}
                onDragLeave={() => handleDragLeave(setScormDragOver)}
                onDrop={handleSCORMDrop}
                onClick={() => handleClickInput('.zip', handleSCORMFile)}
              >
                {scormStatus === 'uploading' && (
                  <MemreFlexBox direction="column" align="center" gap={1}>
                    <MemreText variant="body2" sx={{ fontWeight: 500 }}>
                      Uploading...
                    </MemreText>
                    <LinearProgress sx={{ width: '100%' }} color="secondary" />
                  </MemreFlexBox>
                )}

                {scormStatus === 'importing' && (
                  <MemreFlexBox direction="column" align="center" gap={1}>
                    <MemreText variant="body2" sx={{ fontWeight: 500 }}>
                      Importing SCORM...
                    </MemreText>
                    <LinearProgress sx={{ width: '100%' }} color="secondary" />
                  </MemreFlexBox>
                )}

                {scormStatus === 'complete' && scormFile && (
                  <MemreFlexBox direction="column" align="center" gap={1}>
                    <Box
                      sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                        backgroundColor: muiTheme.palette.success.light,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} style={{ color: muiTheme.palette.success.main }} />
                    </Box>
                    <MemreText variant="body2" sx={{ fontWeight: 500 }}>
                      {scormFile.name}
                    </MemreText>
                  </MemreFlexBox>
                )}

                {scormStatus === 'error' && (
                  <MemreText variant="body2" sx={{ color: muiTheme.palette.error.main, fontWeight: 500 }}>
                    {scormError || 'Error importing SCORM'}
                  </MemreText>
                )}

                {scormStatus === 'idle' && !scormFile && (
                  <MemreFlexBox direction="column" align="center" gap={1}>
                    <MemreText variant="body2" sx={{ fontStyle: 'italic', color: muiTheme.palette.grey[600] }}>
                      Drag & Drop SCORM (ZIP) or click to select
                    </MemreText>
                  </MemreFlexBox>
                )}
              </Box>
            </Tooltip>
            {errors.scormFile && (
              <MemreText variant="body2" sx={{ color: muiTheme.palette.error.main, mt: 1 }}>
                {errors.scormFile}
              </MemreText>
            )}
          </Box>

          <Box>
            <TextField
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              color="secondary"
              size="small"
              error={Boolean(errors.title)}
              helperText={errors.title}
              required
              disabled={submitting}
            />
          </Box>

          <Box>
            <TextField
              label="Description"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              color="secondary"
              size="small"
              multiline
              minRows={3}
              disabled={submitting}
            />
          </Box>

          <Box>
            <MemreText variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
              Image
            </MemreText>
            <Tooltip title="Attach an image (PNG/JPG) to visually represent this SCORM package.">
              <Box
                sx={imageDropStyles}
                onDragOver={(e) => handleDragOver(e, setImageDragOver)}
                onDragLeave={() => handleDragLeave(setImageDragOver)}
                onDrop={handleImageDrop}
                onClick={() => handleClickInput('image/*', handleImageFile)}
              >
                {imageUploading && !imageFile && (
                  <MemreFlexBox direction="column" align="center" gap={1}>
                    <MemreText variant="body2" sx={{ fontWeight: 500 }}>
                      Uploading image...
                    </MemreText>
                    <LinearProgress sx={{ width: '100%' }} color="secondary" />
                  </MemreFlexBox>
                )}

                {imageFile && !imageUploading && (
                  <Box
                    sx={{
                      position: 'relative',
                      width: '200px',
                      backgroundColor: '#fff',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '8px',
                      '&:hover .remove-button': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={URL.createObjectURL(imageFile)}
                      alt="preview"
                      sx={{
                        width: '100%',
                        borderRadius: '4px',
                        objectFit: 'cover',
                        marginBottom: '8px',
                      }}
                    />
                    <MemreFlexBox direction="row" align="center" justify="space-between" sx={{ width: '100%' }}>
                      <MemreText variant="body2" sx={{ fontWeight: 500, wordBreak: 'break-word', textAlign: 'left' }}>
                        {imageFile.name}
                      </MemreText>
                      <IconButton
                        onClick={removeImage}
                        size="small"
                        className="remove-button"
                        sx={{
                          backgroundColor: 'rgba(255,255,255,0.8)',
                          '&:hover': {
                            backgroundColor: 'rgba(255,255,255,1)',
                          },
                          opacity: 0.6,
                          transition: 'opacity 0.3s ease',
                        }}
                        disabled={submitting}
                      >
                        <FontAwesomeIcon icon={faTrash} style={{ color: Color.activeRed }} />
                      </IconButton>
                    </MemreFlexBox>
                  </Box>
                )}

                {!imageFile && !imageUploading && (
                  <MemreText variant="body2" sx={{ fontStyle: 'italic', color: muiTheme.palette.grey[600] }}>
                    Drag & Drop or click to upload an image
                  </MemreText>
                )}
              </Box>
            </Tooltip>
          </Box>

          <MemreFlexBox
            direction="row"
            gap={2}
            justify="flex-end"
            sx={{ marginTop: '20px', opacity: submitting ? 0.6 : 1 }}
          >
            <MemreButton
              text="CANCEL"
              variant="outlined"
              color="secondary"
              onClick={onCancel}
              size="medium"
              disabled={submitting}
            />
            <MemreButton
              text="DONE"
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              size="medium"
              disabled={scormStatus === 'uploading' || scormStatus === 'importing' || submitting}
            />
          </MemreFlexBox>
        </MemreFlexBox>
      </Box>
    </ThemeProvider>
  );
};

// Upload SCORM Package and return its ID
const uploadSCORMPackage = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await axios.post('/api/v3/scorm_packages', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data.data.id;
};

// Poll the SCORM import status until completion or error
const pollSCORMImportStatus = async (scormPackageId: string): Promise<void> => {
  let attempts = 0;
  const maxAttempts = 60;
  while (attempts < maxAttempts) {
    const response = await axios.get(`/api/v3/scorm_packages/${scormPackageId}/import_status`);
    const status = response.data.response.status;
    if (status.toLowerCase() === 'complete') {
      return;
    } else if (status.toLowerCase() === 'error') {
      const message = response.data.message || 'Unknown import error';
      throw new Error(message);
    }
    await new Promise((res) => setTimeout(res, 1000));
    attempts++;
  }
  throw new Error('Timed out waiting for SCORM import to complete.');
};
