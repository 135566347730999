// I'm not happy about monkey patching functions, but I couldn't think of a better alternative to knowing when the location bar changes.
export function registerHistoryListeners() {
  window.history.pushState = (originalPushState =>
    function pushState(this: any, ...args: any) {
      const originalPushStateResult = originalPushState.apply(this, args);
      window.dispatchEvent(new Event('pushstate'));
      window.dispatchEvent(new Event('locationchange'));
      return originalPushStateResult;
    })(window.history.pushState);

  window.history.replaceState = (originalReplaceState =>
    function replaceState(this: any, ...args: any) {
      const originalReplaceStateResult = originalReplaceState.apply(this, args);
      window.dispatchEvent(new Event('replacestate'));
      window.dispatchEvent(new Event('locationchange'));
      return originalReplaceStateResult;
    })(window.history.replaceState);

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'));
  });
}
