import TextField from '@material-ui/core/TextField';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { useItemEditorStore } from 'components/item-editor/store';
import { useMutateConcept } from 'hooks/useMutateConcept';
import React, { useEffect, useState } from 'react';
import { ConceptImage } from '../ConceptImage';
import { ConceptSound } from '../ConceptSound';
import { useAutosaveStatus } from 'hooks/useAutosaveStatus';
import { AnswersFormFields } from '../AnswersFormFields';

export const FlashcardFrontFormFields = () => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();

  const concept = item?.facets[0]?.anchor;
  const [conceptText, setConceptText] = useState(concept?.text);
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useAutosaveStatus();

  const handleConceptTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConceptText(event.target.value);
  };

  // update concept text automatically on changed input
  // debounce to avoid making too many requests
  useEffect(() => {
    if (item == null) {
      return;
    }
    if (conceptText !== concept.text) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateConcept({
          id: concept.id,
          payload: {
            data: {
              attributes: {
                'text-html': conceptText,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));

        // update app state (This must be done after sending the mutation request)
        // Every facet shares the same anchor and they all must be updated
        const updatedFacets = item.facets.map((facet) => ({
          ...facet,
          anchor: {
            ...facet.anchor,
            text: conceptText,
          },
        }));
        updateActiveItem({ facets: updatedFacets });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [conceptText, mutateConcept, concept, item, setAutosaveStatus, updateActiveItem]);

  return (
    <>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <TextField
          id={`${concept}-text`}
          label={'Front of Flashcard'}
          value={conceptText}
          onChange={handleConceptTextChange}
          placeholder={'Enter the front of the flashcard'}
          variant="outlined"
          style={{
            fontSize: '24px',
            fontWeight: 500,
            padding: '5px 0',
            width: '100%',
          }}
        />
        <ConceptImage concept={concept} />
        <ConceptSound concept={concept} />
      </div>
      <AutosaveStatus status={autosaveStatus} />
      <div>
        <AnswersFormFields
          wrongAnswersOnly={true}
          conceptIdForDistractors={concept.id}
          title={'Wrong Answer Options for the Front of the Flashcard'}
        />
      </div>
    </>
  );
};
