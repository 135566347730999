import { API_DATA_SUCCESS, CALL_API } from 'middleware/api';
import { handleGetAssignments } from './assignment';

export const createCourseSet = (courseId: string, setId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/courses/${courseId}/sets`,
      httpMethod: 'POST',
      data: {
        id: setId
      }
    }
  };
};

export const deleteCourseSet = (courseId: string, setId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/courses/${courseId}/sets/${setId}`,
      httpMethod: 'DELETE',
      data: {
        id: setId
      }
    }
  };
};

export const handleAddSetToCourse = (courseId: string, setId: string) => {
  return async dispatch => {
    const actionResponse = await dispatch(createCourseSet(courseId, setId));

    if (actionResponse.type === API_DATA_SUCCESS) {
      dispatch(handleGetAssignments(courseId));
    }

    if (actionResponse.error) {
      throw new Error('Failed too create CourseSet!');
    }
    return actionResponse;
  };
};

export const handleRemoveSetFromCourse = (courseId: string, setId: string) => {
  return async dispatch => {
    const actionResponse = await dispatch(deleteCourseSet(courseId, setId));

    if (actionResponse.type === API_DATA_SUCCESS) {
      dispatch(handleGetAssignments(courseId));
    }

    if (actionResponse.error) {
      throw new Error('Failed too delete CourseSet!');
    }
    return actionResponse;
  };
};
