import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getRecommendedAssignments = async () => {
  const response = await axios.get(`/api/v4/recommended_sets?include_scorm=true`);
  return normalizeJSONAPIResponse(response.data);
};

export const useRecommendedAssignments = () => {
  const { data, isLoading, error } = useQuery(['assignments'], getRecommendedAssignments);
  return { data, isLoading, error };
};
