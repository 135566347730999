import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { SiteAdminUserDetailNotifications } from './SiteAdminUserDetailNotifications';
import { SiteAdminUserDetailLtiUsers } from './SiteAdminUserDetailLtiUsers';
import { SiteAdminUserDetailPartnerRoles } from './SiteAdminUserDetailPartnerRoles';
import { SiteAdminUserDetailCourses } from './SiteAdminUserDetailCourses';
import { SiteAdminUserDetailLibrary } from './SiteAdminUserDetailLibrary';
import { SiteAdminUserDetailEmails } from './SiteAdminUserDetailEmails';
import { SiteAdminUserDetailSummary } from './SiteAdminUserDetailSummary';

export function SiteAdminUserDetail() {
  const { userId } = useParams<{ userId: string }>();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get(`/api/v3/admin/users/${userId}`);
        const normalized = normalizeJSONAPIResponse(response.data);
        setUser(normalized);
      } catch (error) {
        alert('Error fetching user: ' + JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    };
    initialize();
  }, [userId]);

  if (!user || loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ margin: '20px' }}>
      <SiteAdminUserDetailSummary user={user} />
      <hr />
      <SiteAdminUserDetailEmails user={user} />
      <hr />
      <SiteAdminUserDetailLibrary user={user} />
      <hr />
      <SiteAdminUserDetailCourses user={user} />
      <hr />
      <SiteAdminUserDetailPartnerRoles user={user} />
      <hr />
      <SiteAdminUserDetailLtiUsers user={user} />
      <hr />
      <SiteAdminUserDetailNotifications user={user} />
      <hr />
    </div>
  );
}
