import { BrightInput } from 'components/forms/BrightInput';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Card, CardHeader, CardHeaderContainer } from 'core/layout';
import useCreateCourse from 'hooks/useCreateCourse';
import { useCurrentUser } from 'hooks/useCurrentUser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

interface IManualCreateForm {
  handleCancel: () => void;
}

export const ManualCreateForm = (props: IManualCreateForm) => {
  const user = useCurrentUser();
  const [courseTitle, setCourseTitle] = useState('');
  const { t } = useTranslation();

  const [createCourse, { status: creationStatus, data: responseData }] = useCreateCourse();
  const course = normalizeJSONAPIResponse(responseData?.data);

  const placeholder: string = 'e.g. Product Marketing 101';

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleSubmit = async () => {
    try {
      await createCourse({ name: courseTitle, partner_id: user?.primaryPartner.id });
    } catch (err) {
      console.error(`Error creating course: ${err}`);
    }
  };

  const listenForEnterKey = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const updateCourseTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCourseTitle(event.target.value);
  };

  if (creationStatus === 'success') {
    return (
      <Redirect
        to={{
          pathname: `/nav/courses/${course.id}`,
        }}
      />
    );
  }

  return (
    <>
      <Card>
        <CardHeaderContainer>
          <CardHeader id="header">{t('Add a Title')}</CardHeader>
        </CardHeaderContainer>
        <div>
          <BrightInput
            placeholder={placeholder}
            autoFocus={true}
            onChange={updateCourseTitle}
            onKeyPress={listenForEnterKey}
            required={true}
            bright={!!courseTitle}
            aria-labelledby="header"
          />
        </div>
      </Card>

      <ConfirmOrCancel
        cancelText={t('Cancel')}
        confirmText={t('Create')}
        handleCancelClick={handleCancel}
        handleConfirmClick={handleSubmit}
        confirmIsDisabled={!courseTitle}
        confirmIsPending={creationStatus === 'loading'}
      />
    </>
  );
};
