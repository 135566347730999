import { Chip, Divider, TextField as MuiTextField, Paper, ThemeProvider } from '@mui/material';
import axios from 'axios';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroup } from 'hooks/useGroup';
import { useMutateGroup } from 'hooks/useMutateGroup';
import { usePartnerUserTagTypes } from 'hooks/usePartnerUserTagTypes';
import { Rule, RuleEditor } from 'pages/admin-dashboard/groups/RuleEditor';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

type Props = {
  match: {
    params: {
      groupId: string;
    };
  };
};

interface RuleCondition {
  [operator: string]: {
    tag_type_id: string;
    tag_type_label: string;
    value: string;
  };
}

interface RulesJson {
  AND: RuleCondition[];
}

interface MatchedUser {
  id: string;
  attributes: {
    name: string;
    email: string;
  };
}

export const GroupDetailPage: React.FC<Props> = ({ match }) => {
  const { groupId } = match.params;
  const { data: group, isLoading, isError, error } = useGroup(groupId);
  const [updateGroup, { isLoading: isUpdating }] = useMutateGroup(groupId);
  const { data: partnerUserTagTypes } = usePartnerUserTagTypes();
  const user = useCurrentUser();
  const partnerId = user?.primaryPartner?.id;

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const isSmartGroup = !!group?.rulesJson;

  const [rules, setRules] = useState<Rule[]>([]);
  const [matchedUsers, setMatchedUsers] = useState<MatchedUser[]>([]);
  const [isFetchingPreview, setIsFetchingPreview] = useState(false);

  useEffect(() => {
    if (group) {
      setName(group.name);
      setDescription(group.description || '');

      if (isSmartGroup && group.rulesJson) {
        try {
          const parsedRulesJson: RulesJson = JSON.parse(group.rulesJson);
          const parsedRules: Rule[] = parsedRulesJson.AND.map((condition) => {
            const operator = Object.keys(condition)[0] as '=' | '!=';
            const { tag_type_id, tag_type_label, value } = condition[operator];
            return {
              id: uuid(),
              tagTypeId: tag_type_id,
              tagTypeLabel: tag_type_label,
              operator,
              value,
            };
          });
          setRules(parsedRules);
        } catch (err) {
          console.error('Error parsing rulesJson:', err);
          setRules([]);
        }
      } else {
        setRules([]);
      }
    }
  }, [group, isSmartGroup]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const payload: any = {
        id: groupId,
        name,
        description,
      };

      if (isSmartGroup) {
        const rulesJson = buildRulesJson(rules);
        payload.rules_json = rulesJson;
      }

      await updateGroup({ id: groupId, payload });
      setIsEditing(false);
    } catch (err) {
      console.error('Update failed:', err);
    }
  };

  const buildRulesJson = (rules: Rule[]) => {
    const conditions = rules.map((rule) => ({
      [rule.operator]: {
        tag_type_id: rule.tagTypeId,
        tag_type_label: rule.tagTypeLabel,
        value: rule.value,
      },
    }));
    return JSON.stringify({ AND: conditions });
  };

  const handleAddRule = () => {
    const newRule: Rule = {
      id: uuid(),
      tagTypeId: '',
      tagTypeLabel: '',
      operator: '=',
      value: '',
    };
    setRules((prevRules) => [...prevRules, newRule]);
  };

  const handleRuleChange = (id: string, updatedFields: Partial<Rule>) => {
    setRules((prevRules) => prevRules.map((rule) => (rule.id === id ? { ...rule, ...updatedFields } : rule)));
  };

  const handleRemoveRule = (id: string) => {
    setRules((prevRules) => prevRules.filter((rule) => rule.id !== id));
  };

  const fetchSmartGroupPreview = async (rulesJson: string) => {
    if (!partnerId) return;

    setIsFetchingPreview(true);
    try {
      const response = await axios.post(`/api/v3/partners/${partnerId}/smart_group_preview`, {
        rules_json: rulesJson,
        include: 'user_partner_id',
      });
      const users = response.data.data.map((u: any) => ({
        id: u.id,
        attributes: {
          name: u.attributes.name,
          email: u.attributes.email,
        },
      }));
      setMatchedUsers(users);
    } catch (error) {
      console.error('Error fetching smart group preview', error);
      setMatchedUsers([]);
    } finally {
      setIsFetchingPreview(false);
    }
  };

  useEffect(() => {
    if (isSmartGroup && isEditing && rules.length > 0) {
      const debounceTimeout = setTimeout(() => {
        const rulesJson = buildRulesJson(rules);
        fetchSmartGroupPreview(rulesJson);
      }, 500);
      return () => clearTimeout(debounceTimeout);
    } else {
      setMatchedUsers([]);
    }
  }, [rules, isEditing]);

  const handleCancel = () => {
    if (group) {
      setName(group.name);
      setDescription(group.description || '');
      if (isSmartGroup && group.rulesJson) {
        try {
          const parsedRulesJson: RulesJson = JSON.parse(group.rulesJson);
          const parsedRules: Rule[] = parsedRulesJson.AND.map((condition) => {
            const operator = Object.keys(condition)[0] as '=' | '!=';
            const { tag_type_id, tag_type_label, value } = condition[operator];
            return {
              id: uuid(),
              tagTypeId: tag_type_id,
              tagTypeLabel: tag_type_label,
              operator,
              value,
            };
          });
          setRules(parsedRules);
        } catch (err) {
          console.error('Error parsing rulesJson:', err);
          setRules([]);
        }
      } else {
        setRules([]);
      }
    }
    setIsEditing(false);
    setMatchedUsers([]);
  };

  if (isLoading) return <MemreText variant="body1">Loading...</MemreText>;
  if (isError) {
    console.error(error);
    return <MemreText variant="body1">Error loading group details</MemreText>;
  }

  return (
    <ThemeProvider theme={theme}>
      <MemreFlexBox
        direction="column"
        gap={3}
        sx={{ width: '100%', maxWidth: '800px', margin: '0 auto', padding: '20px' }}
      >
        {!isEditing ? (
          <>
            {/* Header Section */}
            <MemreFlexBox direction="row" align="center" justify="space-between" sx={{ width: '100%' }}>
              <MemreFlexBox direction="column" gap={1}>
                <MemreText variant="h4" sx={{ margin: 0 }}>
                  {group.name}
                </MemreText>
                <Chip
                  label={isSmartGroup ? 'Smart Group' : 'Classic Group'}
                  variant="outlined"
                  size="small"
                  sx={{ alignSelf: 'flex-start', fontSize: '0.75rem' }}
                />
              </MemreFlexBox>
              <MemreButton
                text="Edit Group"
                variant="contained"
                color="secondary"
                onClick={() => setIsEditing(true)}
                iconName="edit"
                size="medium"
              />
            </MemreFlexBox>

            {/* Description Section */}
            <MemreFlexBox direction="column" gap={1}>
              <MemreText variant="subtitle1">
                <strong>Description</strong>
              </MemreText>
              {group.description ? (
                <MemreText variant="body1">{group.description}</MemreText>
              ) : (
                <MemreText variant="body2">No description provided.</MemreText>
              )}
            </MemreFlexBox>

            {/* Rules Section (Smart Group only) */}
            {isSmartGroup && rules.length > 0 && (
              <Paper variant="outlined" sx={{ padding: 2 }}>
                <MemreText variant="h6" sx={{ marginBottom: '10px' }}>
                  Group Rules
                </MemreText>
                <MemreFlexBox direction="column" gap={1}>
                  {rules.map((rule, index) => {
                    const operatorText = rule.operator === '=' ? 'is' : 'is not';
                    return (
                      <MemreFlexBox direction="row" align="center" gap={1} key={index}>
                        <MemreText variant="body2">{rule.tagTypeLabel}</MemreText>
                        <MemreText variant="body2">{operatorText}</MemreText>
                        <MemreText variant="body2">{rule.value}</MemreText>
                      </MemreFlexBox>
                    );
                  })}
                </MemreFlexBox>
              </Paper>
            )}

            {/* Stats Section */}
            <MemreFlexBox direction="row" gap={4} sx={{ marginTop: '20px' }}>
              <MemreFlexBox direction="column" gap={0.5}>
                <MemreText variant="body1">
                  <strong>{group.membersCount}</strong> Member{group.membersCount === 1 ? '' : 's'}
                </MemreText>
                <MemreButton
                  text="View Members"
                  variant="text"
                  color="secondary"
                  onClick={() => (window.location.href = `/nav/v4/admin/groups/${groupId}/members`)}
                  size="small"
                />
              </MemreFlexBox>
              <MemreFlexBox direction="column" gap={0.5}>
                <MemreText variant="body1">
                  <strong>{group.coursesCount}</strong> Course{group.coursesCount === 1 ? '' : 's'}
                </MemreText>
                <MemreButton
                  text="View Courses"
                  variant="text"
                  color="secondary"
                  onClick={() => (window.location.href = `/nav/v4/admin/groups/${groupId}/courses`)}
                  size="small"
                />
              </MemreFlexBox>
            </MemreFlexBox>
          </>
        ) : (
          // Edit Mode
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <MemreFlexBox direction="column" gap={3}>
              <MemreText variant="h5">Edit Group</MemreText>

              <MemreFlexBox direction="column" gap={1}>
                <MemreText variant="body1">Name</MemreText>
                <MuiTextField
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={isUpdating}
                  size="medium"
                  color="secondary"
                  fullWidth
                  required
                />
              </MemreFlexBox>

              <MemreFlexBox direction="column" gap={1}>
                <MemreText variant="body1">Description</MemreText>
                <MuiTextField
                  color="secondary"
                  variant="outlined"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={isUpdating}
                  size="small"
                  placeholder="Describe the purpose of this group..."
                  multiline
                  fullWidth
                />
              </MemreFlexBox>

              {isSmartGroup && (
                <MemreFlexBox direction="column" gap={2}>
                  <MemreText variant="h6">Edit Group Rules</MemreText>
                  {rules.map((rule) => (
                    <RuleEditor
                      key={rule.id}
                      rule={rule}
                      partnerUserTagTypes={partnerUserTagTypes || []}
                      onRuleChange={handleRuleChange}
                      onRemoveRule={handleRemoveRule}
                    />
                  ))}

                  <MemreButton
                    text="Add Rule"
                    variant="outlined"
                    color="secondary"
                    onClick={handleAddRule}
                    iconName="plus"
                    size="small"
                  />

                  <Divider sx={{ my: 2 }} />
                  <MemreText variant="h6">
                    {isFetchingPreview ? 'Fetching matching users...' : `Matching Users (${matchedUsers.length})`}
                  </MemreText>
                  {matchedUsers.length > 0 ? (
                    <MemreFlexBox direction="column" gap={0.5}>
                      {matchedUsers.map((u) => (
                        <MemreText key={u.id} variant="body2">
                          <strong>{u.attributes.name}</strong> ({u.attributes.email})
                        </MemreText>
                      ))}
                    </MemreFlexBox>
                  ) : (
                    <MemreText variant="body2">No users match the current rules.</MemreText>
                  )}
                </MemreFlexBox>
              )}

              <MemreFlexBox direction="row" gap={2} sx={{ marginTop: '20px' }}>
                <MemreButton
                  text={isUpdating ? 'Saving...' : 'Save'}
                  variant="contained"
                  color="secondary"
                  disabled={isUpdating}
                />
                <MemreButton
                  text="Cancel"
                  variant="text"
                  color="secondary"
                  onClick={handleCancel}
                  disabled={isUpdating}
                />
              </MemreFlexBox>
            </MemreFlexBox>
          </form>
        )}
      </MemreFlexBox>
    </ThemeProvider>
  );
};
