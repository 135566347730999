import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Color, Size } from 'core';
import { MTableToolbar } from 'material-table';
import styled from 'styled-components';

const FieldContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 1em;
  color: ${Color.reportButtonGray};

  &:not(:last-child) {
    width: 100%;
    margin-bottom: 1em;

    @media (min-width: ${Size.reportPageWidth}px) {
      width: auto;
      margin-right: 2em;
      margin-bottom: 0;
    }
  }
`;
export const SearchContainer = styled(FieldContainer)`
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  color: ${Color.reportButtonGray};

  @media (min-width: ${Size.reportPageWidth}px) {
    min-width: 540px;
  }
`;

export const FilterContainer = styled(FieldContainer)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  height: 4em;

  @media (min-width: ${Size.reportPageWidth}px) {
    min-width: 285px;
  }
`;

export const BorderedSearchContainer = styled(SearchContainer)`
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const SearchAndFilterContainer = styled.div`
  clear: both;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 2em;
`;

export const ControlTextSpan = styled.span`
  text-transform: uppercase;
  position: relative;
  top: -1.75em;
  @media (max-width: ${Size.reportControlTextWidth}px) {
    display: none;
  }
`;

export const SSelectOption = styled.p`
  color: ${Color.black};
`;

export const SelectDownArrow = props => {
  return (
    <svg
      height="8"
      width="16"
      style={{ position: 'absolute', right: '0', pointerEvents: 'none', userSelect: 'none', display: 'inlineBlock' }}
    >
      <polygon
        points="0,0 8,8 16,0"
        style={{
          fill: Color.black,
          stroke: Color.black,
          strokeWidth: 1,
          position: 'absolute'
        }}
      />
    </svg>
  );
};

export const ColoredVerticalSeparator = props => {
  return (
    <svg height="10" width="3" style={{ margin: '29px 7px' }}>
      <rect
        width="3"
        height={'10'}
        style={{ fill: props.color, stroke: props.color, strokeWidth: 1, position: 'absolute' }}
      />
    </svg>
  );
};

export const StyledMTableToolbar: any = withStyles({
  root: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
      position: 'relative',
      padding: 0,
      margin: 0
    },
    '& .MuiInputAdornment-root': {
      display: 'none'
    },
    '& .MuiInputBase-input': {
      color: Color.reportGray,
      fontWeight: 500
    },
    '& div:first-child': {
      flex: 0
    },
    '[class*=".MTableToolbar-spacer"]': {
      flex: 0
    },
    '& ::-webkit-input-placeholder': {
      opacity: 1
    },
    '& ::-ms-input-placeholder': {
      opacity: 1
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px'
    },
    '& .MuiInput-underline:after': {
      borderColor: Color.reportButtonGray
    }
  }
})(MTableToolbar);

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '&:focus': {
      background: 'none'
    }
  },
  select: {
    minWidth: '100%'
  }
}));
