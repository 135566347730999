import axios from 'axios';
import { IImage } from 'models/image';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

interface CreateDistractorParams {
  conceptId: string;
  payload: CreateDistractorPayload | null;
}

interface CreateDistractorPayload {
  attributes: CreateDistractorAttributes;
  relationships: {
    image?: {
      data: IImage | null;
    };
  };
}

interface CreateDistractorAttributes {
  text: string;
  'text-html': string;
  explanation: string;
  'explanation-html': string;
  'allow-random-distractors': boolean;
  'is-studiable': boolean;
  'created-at': string;
}

const createDistractor = async ({ conceptId, payload }: CreateDistractorParams) => {
  const response = await axios.post(`/api/v3/concepts/${conceptId}/distractors`, { data: { ...payload } });
  return normalizeJSONAPIResponse(response.data);
};

export const useCreateDistractor = (itemId: string, setId: string, conceptIdForDistractors: string) => {
  return useMutation(createDistractor, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
      queryCache.invalidateQueries(['distractors', conceptIdForDistractors]);
    },
  });
};
