import axios from 'axios';
import { useQuery } from 'react-query';
import { TAssignment } from 'types';
import getAssignmentStatus from 'utils/getAssignmentStatus';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

interface JSONAPIPaginationMeta {
  pagination?: {
    current_page?: number;
    total_pages?: number;
  };
}

const getAllAssignments = async (_key: string, courseId: string | null) => {
  if (!courseId) {
    console.warn('Cannot get assignments without courseId');
    return [];
  }

  const allAssignments: any[] = [];
  let page = 1;
  let totalPages = 1; // we’ll discover the real total from meta

  do {
    const response = await axios.get(
      `/api/v4/courses/${courseId}/assignments?include=image&page[number]=${page}&page[size]=200&include_difficulty=true`
    );

    // we assume the meta is like response.data.meta.pagination.total_pages
    const meta: JSONAPIPaginationMeta = response.data.meta || {};
    const pagination = meta.pagination || {};
    totalPages = pagination.total_pages || 1;

    const normalizedData = normalizeJSONAPIResponse(response.data);
    const pageAssignmentsWithStatus = normalizedData.map((assignment: any) => ({
      ...assignment,
      status: getAssignmentStatus(assignment, courseId),
    }));

    allAssignments.push(...pageAssignmentsWithStatus);
    page++;
  } while (page <= totalPages);

  return allAssignments;
};

export const useAssignments = (courseId: string | null) => {
  return useQuery<TAssignment[] | undefined>(['assignments', courseId], getAllAssignments, {
    enabled: !!courseId,
  });
};
