import Typography from '@mui/material/Typography';
import { MemreFlexBox, MemreIdealStudyTime, MemrePointers } from 'components/core';
import * as colours from 'core/colors';
import formatTime from 'utils/formatTime';
import getHighestPercentageAboveAverage from 'utils/getHighestPercentageAboveAverage';
import roundToNearestQuarterHour from 'utils/roundToNearestQuarterHour';
import AreaHeading from './AreaHeading';
import LearnMore from './LearnMore';
import Line from './Line';

function getTimesAround(date: Date): string[] {
  const times: string[] = [];

  const currentTime = new Date(date.getTime());

  currentTime.setMinutes(currentTime.getMinutes() - 90);

  for (let i = 0; i < 4; i++) {
    const roundedTime = roundToNearestQuarterHour(currentTime);
    times.push(formatTime(roundedTime));
    currentTime.setMinutes(currentTime.getMinutes() + 60);
  }

  return times;
}

export default function IdealTime({ retentionLevels, time }) {
  const roundedDate = roundToNearestQuarterHour(time);
  const times = getTimesAround(roundedDate);

  const formattedData = [
    {
      id: 'data',
      data: retentionLevels.map((value, index) => ({ x: index, y: value })),
    },
  ];

  return (
    <MemreFlexBox direction="column" gap={1} sx={{ flexGrow: 1 }}>
      <MemreFlexBox justify="space-between" align="center">
        <AreaHeading overline="Ideal Time" heading={formatTime(roundedDate)} />
        <LearnMore>
          <AreaHeading overline="Ideal Time" heading={formatTime(roundedDate)} />
          <Typography sx={{ color: colours.darkGray, fontSize: 14 }}>
            You retain {retentionLevels[0] && <b>{getHighestPercentageAboveAverage(retentionLevels)}%</b>} more
            knowledge above your average retention rate when you learn them at <b>{formatTime(roundedDate)}</b>
          </Typography>
          {retentionLevels[0] && (
            <MemreFlexBox direction="column" gap={1}>
              <div style={{ height: '160px', width: '100%' }}>
                <Line data={formattedData} />
              </div>
              <MemrePointers
                items={['00:00', '06:00', '12:00', '18:00', '24:00']}
                padding="left"
                justify="space-between"
              />
            </MemreFlexBox>
          )}
        </LearnMore>
      </MemreFlexBox>
      <MemreFlexBox direction="column">
        <MemreIdealStudyTime time={roundedDate} />
        <MemrePointers items={times} />
      </MemreFlexBox>
    </MemreFlexBox>
  );
}
