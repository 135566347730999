export type PartnerUserRole =
  | 'admin'
  | 'content_manager'
  | 'content manager'
  | 'course_manager'
  | 'course manager'
  | 'instructor'
  | 'editor'
  | 'course_viewer'
  | 'course viewer'
  | 'learner';

export const formatPartnerUserRole = (role: PartnerUserRole) => {
  switch (role) {
    case 'admin':
      return 'Admin';
    case 'content_manager':
      return 'Content Manager';
    case 'content manager':
      return 'Content Manager';
    case 'course_manager':
      return 'Course Manager';
    case 'course manager':
      return 'Course Manager';
    case 'instructor':
      return 'Instructor';
    case 'editor':
      return 'Editor';
    case 'course_viewer':
      return 'Course Viewer';
    case 'course viewer':
      return 'Course Viewer';
    case 'learner':
      return 'Learner';
    default:
      console.warn('Unknown role, assuming learner:', role);
      return 'Learner';
  }
};
