import _ from 'lodash';
import { CALL_API } from 'middleware/api';
import { IUser } from 'models/user';
import build from 'redux-object';

export const REMOVE_LEARNER_STAT = Symbol('Remove Learner Stat');
export const REMOVE_LEARNER_STATS = Symbol('Remove Learner Stats');
export const getLearnerStatsEndpoint = (courseId: string, setId?: string): string => {
  const baseUrl: string = `/api/v4/courses/${courseId}/learner_stats`;
  return setId ? baseUrl.concat(`?set_id=${setId}`) : baseUrl;
};

export const handleGetLearnerStats = (courseId: string, setId?: string) => {
  return {
    [CALL_API]: {
      endpoint: getLearnerStatsEndpoint(courseId, setId),
      httpMethod: 'GET'
    }
  };
};

export const selectLearnerStats = (state, courseId: string, setId?: string) => {
  const learnerStats = build(state.data, 'learnerStats')
    ?.filter(stat => stat.courseId === Number(courseId))
    ?.filter(stat => (setId ? stat.setId === Number(setId) : stat));

  return _.uniqBy(learnerStats, (stat: ILearnerStats) => stat.user.id);
};

export interface ILearnerStats {
  lastStudyTime: string;
  user: IUser;
  userEmail: string;
  courseId: number;
  setId?: number;
  progress: number;
  goalReachedAt: string;
  totalStudyTime: number;
  percentStudied: number;
  percentReviewed: number;
  groupUsers: string[];
  userPartnerUserTags: string[];
  memberId: number;
  assessmentScore: number;
}

export interface IParsedLearnerStats {
  lastStudyTime: string;
  userId: number;
  userEmail: string;
  name: string;
  progress: number;
  goalReachedAt: string;
  // TODO: move display stuff (ie readable) out of here
  totalStudyTime: { readable: string; raw: number };
  percentStudied: { readable: string; raw: number };
  percentReviewed: { readable: string; raw: number };
  groupUsers: string[];
  userPartnerUserTags: string[];
  assessmentScore?: string;
  goalsStarted?: number;
  goalsCount?: number;
  completedAt: string;
}

export const removeLearnerStat = (state, action) => {
  const { userId, courseId, setId } = action;
  if (setId) {
    state.learnerStats = _.omitBy(
      state.learnerStats,
      learnerStat =>
        learnerStat.relationships.user.data.id === userId &&
        String(learnerStat.attributes.courseId) === courseId &&
        String(learnerStat.attributes.setId) === setId
    );
  } else {
    state.learnerStats = _.omitBy(
      state.learnerStats,
      learnerStat =>
        learnerStat.relationships.user.data.id === userId && String(learnerStat.attributes.courseId) === courseId
    );
  }

  return {
    ...state
  };
};

export const removeLearnerStats = (state, action) => {
  const { courseId } = action;

  state.learnerStats = _.omitBy(
    state.learnerStats,
    learnerStat => String(learnerStat.attributes.courseId) === courseId
  );
  return {
    ...state
  };
};
