import { FormControlLabel, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';
import AvatarButton from 'components/buttons/AvatarButton';
import { InlineButton, PrimaryButton } from 'components/buttons/v4';
import { ChangeUserLanguageCard } from 'components/ChangeUserLanguageCard';
import { EmailList } from 'components/EmailList';
import { ImageUploaderModal } from 'components/ImageUploaderModal';
import { Modal } from 'components/Modal';
import { Color, Size } from 'core';
import {
  CardHeader,
  CardHeaderContainer,
  ContentSection,
  PageContainer,
  PageTitle,
  ResponsiveCard,
  TitleSection,
} from 'core/layout';
import { BaseSwitch } from 'core/switch';
import { AddUserEmailModal } from 'courses/components/AddUserEmailModal';
import { EditableText } from 'courses/components/EditableText';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useIsInPartner } from 'hooks/useIsInPartner';
import { API_DATA_SUCCESS } from 'middleware/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, handleGetProfile } from 'store/profile';
import { updateUser } from 'store/user';
import {
  handleDeleteUserEmail,
  handleMakePrimaryEmail,
  handleResendEmailConfirmation,
  REMOVE_USER_EMAIL,
} from 'store/user-email';
import styled from 'styled-components';
import { PARTNER_IDS } from 'utils/constants';
import { getRole } from 'utils/userManager';

const minPasswordLen = 8;

const UserAvatar = styled.div`
  position: relative;
  float: right;
  margin: -1em 1em 0.5em;
  top: 12px;
`;

const PasswordsContainer = styled.div`
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    width: 100%;
  }

  @media (min-width: ${Size.defaultBreakpoint}px) {
    flex-direction: row;

    > div:first-child {
      margin-right: 1em;
    }
  }
`;

const ErrorMessage = styled.h4`
  color: ${Color.red};
  font-size: 14px;
  margin: 0;
  padding-top: 0;
`;

const NotificationsContainer = styled.div`
  & > * {
    padding: 1.5rem 2rem 1.5rem;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: ${Color.slateGray};
  }

  p {
    font-size: 12px;
  }

  ul {
    padding-inline-start: 0;

    li {
      list-style-type: none;
    }
  }

  @media (min-width: ${Size.defaultBreakpoint}px) {
    width: ${Size.wideCardWidth}px;

    > div {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const UserSettingsPage = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [showUserImageUploadModal, setShowUserImageUploadModal] = useState(false);
  const [showAddUserEmailModal, setShowAddUserEmailModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFailureMessage, setPasswordFailureMessage] = useState('');
  const user = useSelector((state) => getCurrentUser(state));
  const isQLearn = useIsInPartner(PARTNER_IDS.QLEARN);
  const isStudent = getRole(user) == 'learner';
  const allowAddingPII = !isQLearn || !isStudent; // QLearn students should not be allowed to add *any* PII
  const initialNotifications = {
    achievement: false,
    review_reminder: false,
    retention: false,
    push_review_reminder: false,
  };
  const [notifications, setNotifications] = useState(initialNotifications);
  const [pendingUpdate, setPendingUpdate] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setName(user.name || '');
    }
  }, [user?.name]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const result = await axios.get('/api/v2/subscriptions');

      setNotifications(result.data.response.email);
    };
    fetchNotifications();
  }, []);

  async function toggleNotification(notification: string, checked: boolean) {
    const updatedNotifications = {
      ...notifications,
      [notification]: checked,
    };
    setPendingUpdate(true);
    await axios.put('/api/v2/subscriptions', { email: updatedNotifications });

    setPendingUpdate(false);
    setNotifications(updatedNotifications);
  }

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleAvatarButtonClick() {
    setShowUserImageUploadModal(true);
  }

  function handleImageUploadModalCancel() {
    setShowUserImageUploadModal(false);
  }

  async function handleImageUploadModalSave(image) {
    await dispatch(updateUser({ icon_image_id: image.id }, user.id));
    await dispatch(handleGetProfile());

    setShowUserImageUploadModal(false);
  }

  async function removeEmail(userEmailId) {
    const actionResponse: any = await dispatch(handleDeleteUserEmail(userEmailId));

    if (actionResponse.type === API_DATA_SUCCESS) {
      dispatch({ type: REMOVE_USER_EMAIL, userId: user.id, userEmailId });
    }
  }

  async function resendEmailConfirmation(userEmailId) {
    await dispatch(handleResendEmailConfirmation(userEmailId));
  }

  async function makePrimaryEmail(userEmailId) {
    await dispatch(handleMakePrimaryEmail(userEmailId));
    await dispatch(handleGetProfile());
  }

  function handleChangeCurrentPassword(e) {
    setPasswordFailureMessage('');
    setCurrentPassword(e.target.value);
  }

  function handleChangePassword(e) {
    setPasswordFailureMessage('');
    setPassword(e.target.value);
  }

  async function updatePassword() {
    setPasswordFailureMessage('');
    try {
      await axios.put(`/api/v2/users/${user.id}`, {
        id: Number(user.id),
        current_password: currentPassword,
        password,
        password_confirmation: password,
      });
      setCurrentPassword('');
      setPassword('');
    } catch (error: any) {
      const errorMessage = error.response.data.response;
      setPasswordFailureMessage(errorMessage);
    }
  }

  function handleClickShowPassword() {
    setShowPassword((showPassword) => !showPassword);
  }

  useDocumentTitle(t('Settings'));

  if (showUserImageUploadModal) {
    return (
      <Modal
        show={showUserImageUploadModal}
        element={
          <ImageUploaderModal
            onSaveImage={handleImageUploadModalSave}
            onCancel={handleImageUploadModalCancel}
            title={t('Update User Image')}
          />
        }
      />
    );
  }

  if (showAddUserEmailModal) {
    return (
      <Modal
        show={showAddUserEmailModal}
        element={<AddUserEmailModal close={() => setShowAddUserEmailModal(false)} />}
      />
    );
  }

  return (
    <>
      {user && (
        <PageContainer>
          <TitleSection style={{ justifyContent: 'flex-start' }}>
            <UserAvatar>
              <AvatarButton click={handleAvatarButtonClick} image={user.image} entity="user" canEdit={allowAddingPII} />
            </UserAvatar>
            <PageTitle>
              <EditableText
                value={name}
                onChange={handleNameChange}
                text={user.name}
                aria-label={t('Name')}
                doneEditingCallback={() => dispatch(updateUser({ name }, user.id))}
                canEdit={allowAddingPII}
              />
            </PageTitle>
          </TitleSection>
          <ContentSection style={{ marginBottom: 20 }}>
            <ResponsiveCard>
              <CardHeaderContainer style={{ display: 'flex', alignItems: 'center' }}>
                <CardHeader style={{ marginRight: '10px', marginBottom: 0 }}>{t('Email Addresses')}</CardHeader>
                {allowAddingPII && (
                  <InlineButton click={() => setShowAddUserEmailModal(true)}>{t('Add Email')}</InlineButton>
                )}
              </CardHeaderContainer>
              <EmailList
                emails={user.emails}
                removeEmail={removeEmail}
                resendEmailConfirmation={resendEmailConfirmation}
                makePrimaryEmail={makePrimaryEmail}
              />
            </ResponsiveCard>
          </ContentSection>
          <ContentSection style={{ marginBottom: 20 }}>
            <ResponsiveCard>
              <CardHeaderContainer>
                <CardHeader>{t('Change Your Password')}</CardHeader>
              </CardHeaderContainer>
              <PasswordsContainer>
                <div>
                  <label>{t('Current Password')}</label>
                  <OutlinedInput
                    error={passwordFailureMessage.length > 0}
                    style={{ width: '100%', margin: '20px auto 20px auto' }}
                    type="password"
                    placeholder={t('Password')}
                    onChange={handleChangeCurrentPassword}
                    value={currentPassword}
                    labelWidth={0}
                  />
                </div>
                <div>
                  <label>{t('New Password')}</label>
                  <OutlinedInput
                    error={passwordFailureMessage.length > 0}
                    style={{ width: '100%', margin: '20px auto 20px auto' }}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('Password')}
                    onChange={handleChangePassword}
                    value={password}
                    labelWidth={0}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label={t('toggle password visibility')} onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </PasswordsContainer>
              {passwordFailureMessage && <ErrorMessage>{passwordFailureMessage}</ErrorMessage>}
              <div>
                <PrimaryButton
                  type="submit"
                  click={updatePassword}
                  disabled={currentPassword.length === 0 || password.length < minPasswordLen}
                >
                  {t('Save')}
                </PrimaryButton>
              </div>
            </ResponsiveCard>
          </ContentSection>
          <ContentSection style={{ marginBottom: 20 }}>
            <ChangeUserLanguageCard />
          </ContentSection>
          <ContentSection>
            <NotificationsContainer>
              <div>
                <div>
                  <h3>{t('Email Notifications')}</h3>
                  <p>{t('Select the notifications you will receive:')}</p>
                  <ul>
                    <li>
                      <FormControlLabel
                        control={
                          <BaseSwitch
                            checked={notifications.achievement}
                            onChange={(e) => toggleNotification('achievement', e.target.checked)}
                            disabled={pendingUpdate}
                          />
                        }
                        label={<Typography style={{ fontSize: '16px' }}>{t('Assignment goals')}</Typography>}
                      />
                    </li>
                    <li>
                      <FormControlLabel
                        control={
                          <BaseSwitch
                            checked={notifications.review_reminder}
                            onChange={(e) => toggleNotification('review_reminder', e.target.checked)}
                            disabled={pendingUpdate}
                          />
                        }
                        label={<Typography style={{ fontSize: '16px' }}>{t('Fading memories')}</Typography>}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </NotificationsContainer>
          </ContentSection>
        </PageContainer>
      )}
    </>
  );
};
