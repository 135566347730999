import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import extractUrlFromString from 'utils/extractUrl';

export default function InstructionalItemModal({ item, handleDoneClick }) {
  const done = item?.meta?.alreadySeen;

  return (
    <MemreFlexBox direction="column" sx={{ p: 3 }} gap={2}>
      {item.annotations.map(
        (annotation: any, index: number) =>
          annotation.noteType &&
          {
            embed: (
              <iframe
                key={index}
                src={extractUrlFromString(annotation.externalResourceUrl)}
                title="Embedded Video"
                width="800px"
                height="500px"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              />
            ),
            text: (
              <div key={index}>
                <MemreText>
                  <div dangerouslySetInnerHTML={{ __html: annotation.textHtml }} />
                </MemreText>
              </div>
            ),
            image: (
              <img
                key={index}
                alt={annotation.image?.altTag}
                src={annotation.image?.url}
                style={{ maxWidth: '100%', maxHeight: '500px' }}
              />
            ),
            sound: (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <audio controls={true}>
                  <source src={annotation.sound?.url} type="audio/mpeg" />
                </audio>
              </div>
            ),
            video: (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <video controls={true} style={{ maxWidth: '100%', maxHeight: '500px' }}>
                  <source src={annotation.video?.url} type="video/mp4" />
                </video>
              </div>
            ),
          }[annotation.noteType]
      )}

      <MemreFlexBox justify="center" gap={2}>
        <MemreButton
          text={done ? 'Done' : 'Mark As Done'}
          iconName={done ? 'check' : null}
          variant={done ? 'contained' : 'outlined'}
          onClick={() => handleDoneClick(item)}
        />
      </MemreFlexBox>
    </MemreFlexBox>
  );
}
