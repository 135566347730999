import { AssignmentStatus } from 'models/set';

const getAssignmentStatus = (assignment: any, course: any): AssignmentStatus => {
  const scheduled: boolean = !assignment.meta.published && assignment.meta.publishAt;
  const published: boolean = course.state === 'published' && assignment.meta.published;
  const unpublished: boolean = !published && !scheduled;
  const gated: boolean = assignment.meta.prereqAssignmentId;
  const building: boolean = assignment.meta.status === 'yellow';
  const error: boolean = assignment.meta.status === 'red';

  if (building) return 'Building';
  if (error) return 'Error';
  if (gated) return 'Gated';
  if (published) return 'Published';
  if (scheduled) return 'Scheduled';
  if (unpublished) return 'Unpublished';

  throw new Error(`No assignment status for assignment: ${assignment}`);
};

export default getAssignmentStatus;
