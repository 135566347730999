// This file contains mappings between old and new paths for our application.
// Each mapping object has an 'oldPath' and a 'newPath' property.
// Paths can include tokens in square brackets (e.g., [courseId]) which will be replaced with actual values.
// Optionally, a mapping can include a 'learnMoreLink' to provide additional info for the toggle.

type Mapping = {
  oldPath: string;
  newPath: string;
  learnMoreLink?: string;
};

const mappings: Mapping[] = [
  {
    oldPath: '/nav/dashboard',
    newPath: '/nav/v4/dashboard',
    learnMoreLink: 'https://www.cerego.com/support/getting-started-with-the-new-learner-dashboard',
  },
  {
    oldPath: '/nav/partners/dashboard',
    newPath: '/nav/v4/admin',
    learnMoreLink: 'https://www.cerego.com/support/getting-started-with-the-updated-admin-pages',
  },
];

export default mappings;
