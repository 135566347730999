import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import avatarRobot from 'assets/images/avatar-robot.svg';
import { MoreVertButton } from 'components/buttons/MoreVertButton';
import ImageView from 'components/ImageView';
import { Color } from 'core';
import { IUser } from 'models/user';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleInviteToCourse, REMOVE_INSTRUCTOR_FROM_COURSE } from 'store/course';
import { handleDeleteCourseUser } from 'store/course-user';
import styled from 'styled-components';
import useStore from 'zstore';

const defaultUserImage = {
  id: 'defaultUserImage',
  altTag: 'default user image',
  url: avatarRobot,
};

const instructorImageStyle = {
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  verticalAlign: 'middle',
  boxShadow: `0 1px 4px 0 ${Color.darkBlueGray}`,
  objectFit: 'cover',
  marginRight: '1em',
};

const Container = styled.ul`
  padding: 0;
  margin: 0;

  > li:not(:last-child) {
    border-bottom: solid 1px ${Color.veryLightGray};
  }

  > li {
    height: 70px;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2em;

    > *:first-child {
      margin-right: 15px;
    }

    > *:last-child {
      margin-left: auto;
    }

    p {
      margin: 0;
    }

    p:first-child {
      font-size: 14px;
      color: ${Color.primaryBlack};
    }

    p:last-child {
      font-size: 12px;
    }
  }
`;

interface IInstructorListProps {
  courseId: string;
  instructors: IUser[];
}

export const InstructorList = (props: IInstructorListProps) => {
  const dispatch = useDispatch();
  const { courseId, instructors } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedInstructor, setSelectedInstructor] = useState<IUser | null>(null);
  const { t } = useTranslation();
  const { makeToast } = useStore();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, instructor: IUser) => {
    setAnchorEl(event.currentTarget);
    setSelectedInstructor(instructor);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resendInvitation = async () => {
    try {
      if (!selectedInstructor) {
        console.warn('No instructor selected');
        return;
      }
      dispatch(handleInviteToCourse(courseId, [selectedInstructor.id], true));
      makeToast({
        text: t('1 invitation was sent'),
        isError: false,
      });
    } catch (error: any) {
      console.error(error.message);
    } finally {
      handleClose();
    }
  };

  const removeInstructor = async () => {
    try {
      if (!selectedInstructor) {
        console.warn('No instructor selected');
        return;
      }
      dispatch(handleDeleteCourseUser(courseId, selectedInstructor.id));
      dispatch({
        type: REMOVE_INSTRUCTOR_FROM_COURSE,
        courseId: courseId,
        userId: selectedInstructor.id,
      });
      makeToast({
        text: t('1 instructor removed'),
        isError: false,
      });
    } catch (error: any) {
      console.error(error.message);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      {instructors.length === 0 && <p>{t('This course has no instructors')}</p>}
      {instructors.length > 0 && (
        <Container>
          {instructors.map((instructor, key) => (
            <li key={key}>
              <ImageView
                image={instructor.image || defaultUserImage}
                transcodedSize="medium"
                style={instructorImageStyle}
              />
              <div>
                <p>{instructor.name}</p>
                <p>{instructor.email}</p>
              </div>
              <div>
                <MoreVertButton
                  click={(event) => handleClick(event, instructor)}
                  aria-controls="instructor-actions-menu"
                />
              </div>
            </li>
          ))}
        </Container>
      )}
      <Menu id="instructor-actions-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={removeInstructor}>{t('Remove')}</MenuItem>
        <MenuItem onClick={resendInvitation}>{t('Resend invitation')}</MenuItem>
      </Menu>
    </>
  );
};
