import TextField from '@material-ui/core/TextField';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import React, { useState } from 'react';
import styled from 'styled-components';

interface EmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  recipients: any[];
  onSendEmail: (subject: string, body: string) => void;
}

export const EmailGroupMembersModal = ({ isOpen, onClose, recipients, onSendEmail }: EmailModalProps) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const handleSendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    if (recipients.length === 0) {
      alert('No recipients selected.');
      return;
    }
    onSendEmail(subject, body);
    setSubject('');
    setBody('');
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <MemreFlexBox gap={1} direction="column" align="start" justify="space-between" sx={{ mb: 2, width: '100%' }}>
          <MemreText variant="h5" sx={{ mb: 2 }}>
            Email Members
          </MemreText>
          <MemreText variant="h6">
            <strong>Recipients</strong>
          </MemreText>
          <RecipientList>
            {recipients.map((member: any) => (
              <RecipientItem key={member.id}>{member.email}</RecipientItem>
            ))}
          </RecipientList>
          <form onSubmit={handleSendEmail} style={{ width: '100%' }}>
            <MemreFlexBox
              gap={1}
              direction="column"
              align="start"
              justify="space-between"
              sx={{ mb: 2, width: '100%' }}
            >
              <TextField
                label="Subject"
                value={subject}
                color="secondary"
                onChange={(e) => setSubject(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                required
              />
              <TextField
                label="Body"
                value={body}
                color="secondary"
                onChange={(e) => setBody(e.target.value)}
                variant="outlined"
                size="small"
                minRows={3}
                multiline
                fullWidth
                required
              />
              <ModalButtons>
                <MemreButton
                  text="Send Email"
                  onClick={handleSendEmail}
                  color="secondary"
                  variant="contained"
                  iconName="mail"
                  size="medium"
                />
                <MemreButton
                  text="Cancel"
                  onClick={onClose}
                  color="secondary"
                  variant="outlined"
                  iconName="times"
                  size="medium"
                />
              </ModalButtons>
            </MemreFlexBox>
          </form>
        </MemreFlexBox>
      </ModalContent>
    </ModalOverlay>
  );
};

const RecipientList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 15px 0;
  max-height: 100px;
  overflow-y: auto;
`;

const RecipientItem = styled.li`
  padding: 5px 0;
`;

const ModalContent = styled.div`
  background-color: white;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
