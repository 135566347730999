export default function getOrdinalSuffix(day: number) {
  if (day > 3 && day < 21) {
    return 'th';
  }

  return (
    {
      1: 'st',
      2: 'nd',
      3: 'rd',
    }[day % 10] || 'th'
  );
}
