import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { CurriculumAssignment } from 'views/course-create/smart-create/store';

type Payload = {
  title: string;
  level?: string;
  language?: string;
  pdf_file?: File;
  assignments?: CurriculumAssignment[];
};

const createSmartCourse = async (payload: Payload) => {
  const formData = new FormData();
  formData.append('title', payload.title);
  formData.append('level', payload.level || 'Intermediate');
  formData.append('language', payload.language || 'English');
  if (payload.pdf_file) {
    formData.append('pdf_file', payload.pdf_file);
  }
  if (payload.assignments) {
    formData.append('assignments', JSON.stringify(payload.assignments));
  }

  const response = await axios.post('/api/v4/smart_courses', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return normalizeJSONAPIResponse(response.data);
};

const useCreateSmartCourse = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;

  return useMutation(createSmartCourse, {
    onSuccess: () => {
      queryCache.invalidateQueries(['courses', partner?.id]);
    },
    onMutate: () => {
      queryCache.invalidateQueries(['courses', partner?.id]);
    },
    onSettled: () => {
      queryCache.invalidateQueries(['courses', partner?.id]);
    },
  });
};

export default useCreateSmartCourse;
