export default function DiamondSymbol({ offset = 0 }: { offset?: number }): {
  draw: (context: CanvasRenderingContext2D, size: number) => void;
} {
  return {
    draw(context: CanvasRenderingContext2D, size: number) {
      const r = Math.sqrt(size) / 2;
      context.moveTo(0, -r + offset);
      context.lineTo(r, offset);
      context.lineTo(0, r + offset);
      context.lineTo(-r, offset);
      context.closePath();
    },
  };
}
