import { CALL_API } from 'middleware/api';
import { IUserEmail } from 'store/user-email';

export interface IUserData {
  name?: string;
  primary_partner_id?: number;
  icon_image_id?: string;
  emails?: IUserEmail[];
}

export const updateUser = (userData: IUserData, userId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/users/${userId}`,
      httpMethod: 'PUT',
      data: userData
    }
  };
};
