import { AddEmbedAnnotationButton } from 'components/item-editor/instructional-content/AddEmbedAnnotationButton';
import { AddImageAnnotationButton } from 'components/item-editor/instructional-content/AddImageAnnotationButton';
import { AddSoundAnnotationButton } from 'components/item-editor/instructional-content/AddSoundAnnotationButton';
import { AddTextAnnotationButton } from 'components/item-editor/instructional-content/AddTextAnnotationButton';
import { AddVideoAnnotationButton } from 'components/item-editor/instructional-content/AddVideoAnnotationButton';
import { IItem } from 'models/item';
import styled from 'styled-components';
import { TAssignment } from 'types';

interface Props {
  item: IItem;
  assignment: TAssignment;
}

export const AddAnnotationToolbar = (props: Props) => {
  const { item, assignment } = props;

  return (
    <Toolbar>
      <AddTextAnnotationButton item={item} assignment={assignment} />
      <AddImageAnnotationButton item={item} assignment={assignment} />
      <AddSoundAnnotationButton item={item} assignment={assignment} />
      <AddEmbedAnnotationButton />
      <AddVideoAnnotationButton item={item} assignment={assignment} />
    </Toolbar>
  );
};

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
