import { ContentTemplateCard } from 'components/item-editor/ContentTemplateCard';
import { IItem } from 'models/item';
import { useTranslation } from 'react-i18next';
import { useItemEditorStore } from './store';

interface IContentTemplateForm {
  redirectToItem: (itemId: string) => void;
  item?: IItem;
  setId: string;
}

// TODO: add more clear cancel option to return to assignment items list
// NOTE: outside click works, it's just not clear UX
export const ContentTemplateForm = (props: IContentTemplateForm) => {
  const { t } = useTranslation();
  const { activeAssignment } = useItemEditorStore();
  const isSurvey = activeAssignment?.goalType === 'survey';

  return (
    <div id="content-template-form-wrapper">
      <h4>{t('Select a Template')}</h4>

      <ContentTemplateCard
        item={props.item}
        template="instructional_contents"
        setId={props.setId}
        redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
      />
      {!isSurvey && (
        <>
          <ContentTemplateCard
            item={props.item}
            template="question_and_answers"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
          />
          <ContentTemplateCard
            item={props.item}
            template="associations"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
          />
          <ContentTemplateCard
            item={props.item}
            template="vocabulary"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
          />
          <ContentTemplateCard
            item={props.item}
            template="sequences"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
          />
          <ContentTemplateCard
            item={props.item}
            template="regions"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
          />
        </>
      )}
      {isSurvey && (
        <>
          <ContentTemplateCard
            item={props.item}
            template="survey_question_and_answers"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
            answerType="single_correct"
          />
          <ContentTemplateCard
            item={props.item}
            template="survey_question_and_answers"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
            answerType="multi_correct"
          />
          <ContentTemplateCard
            item={props.item}
            template="free_responses"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
          />
          <ContentTemplateCard
            item={props.item}
            template="ratings"
            setId={props.setId}
            redirectToItem={(itemId: string) => props.redirectToItem(itemId)}
          />
        </>
      )}
    </div>
  );
};
