import { DropDown, DropDownListItem } from 'components/DropDown';
import { Prototype } from 'components/Prototype';
import useCloneSet from 'hooks/useCloneSet';
import useDeleteAssignment from 'hooks/useDeleteAssignment';
import { GoalType } from 'models/set';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getEditSetUrl, getPreviewSetUrl } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';

interface ICourseAssignmentsTableMenuProps {
  handleOutsideClick: () => void;
  course: any;
  isShared: boolean;
  onShare: () => void;
  left: number;
  top: number;
  currentUser: any;
  selectedSet: any;
}

const CourseAssignmentsTableMenu: React.FC<ICourseAssignmentsTableMenuProps> = (props) => {
  const { t } = useTranslation();
  const userRole = props.currentUser.primaryPartner.meta.role;
  function getInstructors() {
    return props.course.instructors;
  }
  const instructors = useSelector(getInstructors) || {};
  const userIsCourseInstructor = instructors.map((instructor) => instructor.id).includes(props.currentUser.id);
  const isScorm: boolean = props.selectedSet.goalType === 'scorm';
  const userCanEditContent = props.course?.meta?.canEdit ?? false;

  const [deleteAssignment] = useDeleteAssignment();
  const [cloneSet] = useCloneSet();

  const onShareClick = (): void => {
    if (props.isShared) {
      AnalyticsService.getInstance().track('button_clicked', { button_type: 'Share assignment' });
    } else {
      AnalyticsService.getInstance().track('button_clicked', { button_type: 'Unshare assignment' });
    }
    props.handleOutsideClick();
    props.onShare();
  };

  const editContent = (_event: any): void => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Edit assignment' });
    window.location.href = getEditSetUrl(props.selectedSet.learnVersion, props.selectedSet.id);
  };

  const previewContent = (_event: any): void => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Preview assignment' });
    props.handleOutsideClick();
    const { selectedSet: set } = props;
    window.location.href = getPreviewSetUrl(set.learnVersion, set.id);
  };

  const handleCopy = (targetType: GoalType, buttonType: string) => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: buttonType });
    // Close action menu
    props.handleOutsideClick();
    // Make clone
    cloneSet({
      id: props.selectedSet.id,
      payload: {
        data: {
          attributes: {
            goal_type: targetType,
            partner_id: props.currentUser.primaryPartner.id,
            course_id: props.course.id,
          },
        },
      },
    });
  };

  const onDeleteClick = () => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Delete assignment' });
    props.handleOutsideClick(); // Close the actions menu
    deleteAssignment({ courseId: props.course.id, setId: props.selectedSet.id });
  };

  const onExportClick = async () => {
    props.handleOutsideClick(); // Close the actions menu

    window.open(`/api/v3/sets/${props.selectedSet.id}/export`);
  };

  const canPreview = (): boolean => {
    return !isScorm && (props.currentUser.primaryPartner.meta.canReadContent || userIsCourseInstructor);
  };

  const canCopy = (): boolean => {
    const isAllowedAssignmentType = ['set', 'assessment', 'survey'].includes(props.selectedSet.goalType);
    return (
      isAllowedAssignmentType && userCanEditContent && ['admin', 'content manager', 'course manager'].includes(userRole)
    );
  };

  const canCopyAsAssessment = (): boolean => {
    return canCopy() && props.selectedSet.goalType === 'set' && props.selectedSet.learnVersion === 4;
  };

  const canCopyAsLearningSet = (): boolean => {
    return canCopy() && props.selectedSet.goalType === 'assessment';
  };

  const canShare = (): boolean => {
    const authorizedRoles = ['admin', 'course manager', 'content manager'];
    return authorizedRoles.includes(userRole);
  };

  const canDelete = (): boolean => {
    return userCanEditContent && ['admin', 'content manager', 'course manager'].includes(userRole);
  };

  const canEdit = (): boolean => {
    if (isScorm) {
      return false;
    } else {
      return props.selectedSet.meta.canEdit;
    }
  };

  const canExport = () => {
    const isAllowedAssignmentType = ['set', 'assessment'].includes(props.selectedSet.goalType);
    const exportEnabled = props.course.partner.partnerSettings.allowContentImportExport;
    return canEdit() && isAllowedAssignmentType && exportEnabled;
  };

  const [redirectUrl, setRedirectUrl] = useState<string>();
  const viewItems = () => {
    setRedirectUrl(`/nav/courses/${props.course.id}/sets/${props.selectedSet.id}/items`);
  };

  if (redirectUrl) {
    return <Redirect to={{ pathname: redirectUrl }} />;
  }

  return (
    <OutsideClickHandler onOutsideClick={props.handleOutsideClick}>
      <DropDown top={props.top} left={props.left - 3} style={{ zIndex: 11 }} unwrapped={true}>
        {canEdit() && (
          <DropDownListItem>
            <button onClick={editContent}>{t('Edit')}</button>
          </DropDownListItem>
        )}
        {canPreview() && (
          <DropDownListItem>
            <button onClick={previewContent}>{t('Preview')}</button>
          </DropDownListItem>
        )}
        {canCopy() && (
          <DropDownListItem>
            <button onClick={() => handleCopy(props.selectedSet.goalType, 'Copy assignment')}>{t('Copy')}</button>
          </DropDownListItem>
        )}
        {canCopyAsAssessment() && (
          <DropDownListItem>
            <button onClick={() => handleCopy('assessment', 'Copy as assessment')}>{t('Copy as an assessment')}</button>
          </DropDownListItem>
        )}
        {canCopyAsLearningSet() && (
          <DropDownListItem>
            <button onClick={() => handleCopy('set', 'Copy as learning set')}>{t('Copy as a learning set')}</button>
          </DropDownListItem>
        )}
        {canDelete() && (
          <DropDownListItem>
            <button onClick={onDeleteClick}>{t('Delete')}</button>
          </DropDownListItem>
        )}
        {canShare() && (
          <DropDownListItem>
            <button onClick={onShareClick}>
              {props.isShared ? t('Remove from shared assignments') : t('Include in shared assignments')}
            </button>
          </DropDownListItem>
        )}
        <Prototype
          component={
            <DropDownListItem>
              <button onClick={viewItems}>{t('Items')}</button>
            </DropDownListItem>
          }
        />
        {canExport() && (
          <DropDownListItem>
            <button onClick={onExportClick}>{t('Export')}</button>
          </DropDownListItem>
        )}
      </DropDown>
    </OutsideClickHandler>
  );
};

export default CourseAssignmentsTableMenu;
