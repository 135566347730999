import ceregoIconGreyRounded from 'assets/images/cerego-icon-grey-rounded.png';
import scormIcon from 'assets/images/scorm-icon.svg';
import surveyIcon from 'assets/images/survey-icon.svg';
import { Color } from 'core';
import { IImage } from 'models/image';
import * as React from 'react';
import styled from 'styled-components';

const StyledImageView = styled.img`
  object-fit: contain;
`;

// Looking for default images? Look no further!
export const defaultImageMap = {
  user: ceregoIconGreyRounded,
  partner: ceregoIconGreyRounded,
  course: ceregoIconGreyRounded,
  set: ceregoIconGreyRounded,
  assessment: ceregoIconGreyRounded,
  survey: surveyIcon,
  scorm: scormIcon
};
// Let's use these constants to avoid 'cicrle' type errors
export type ImageShape = 'circle' | 'square';
interface IImageShapes {
  CIRCLE: ImageShape;
  SQUARE: ImageShape;
}
export const IMAGE_SHAPES: IImageShapes = {
  CIRCLE: 'circle',
  SQUARE: 'square'
};
// Map Cerego entities to the shapes their PhotoIDs should have
interface IImageShapeMap {
  user: ImageShape;
  partner: ImageShape;
  course: ImageShape;
  set: ImageShape;
  assessment: ImageShape;
  survey: ImageShape;
  scorm: ImageShape;
}
export const imageShapeMap: IImageShapeMap = {
  user: IMAGE_SHAPES.CIRCLE,
  partner: IMAGE_SHAPES.CIRCLE,
  course: IMAGE_SHAPES.SQUARE,
  set: IMAGE_SHAPES.SQUARE,
  assessment: IMAGE_SHAPES.SQUARE,
  survey: IMAGE_SHAPES.SQUARE,
  scorm: IMAGE_SHAPES.SQUARE
};

export const ImageViewStyles = {
  base: {
    verticalAlign: 'middle',
    margin: '0'
  },
  square: {
    borderRadius: '20%',
    boxShadow: 'rgb(190, 195, 201) 0px 1px 10px 0px'
  },
  circle: {
    borderRadius: '50%',
    boxShadow: `0 1px 4px 0 ${Color.darkBlueGray}`
  }
};

export type TranscodingSize = 'small' | 'medium' | 'large';

interface IImageView {
  image?: IImage | null;
  fallbackImageUrl?: string;
  transcodedSize?: TranscodingSize;
  style?: any;
}

export default class ImageView extends React.PureComponent<IImageView> {
  public static defaultProps = {
    transcodedSize: 'large',
    fallbackImageUrl: ceregoIconGreyRounded
  };

  public render() {
    const { image, transcodedSize, fallbackImageUrl } = this.props;
    const imageURL = (image && (image[`${transcodedSize}Url`] || image.url)) || fallbackImageUrl;
    const altTag = !!image ? image.altTag : "";

    return (
      <StyledImageView
        src={imageURL}
        aria-label={altTag}
        style={Object.assign({}, ImageViewStyles.base, this.props.style)}
      />
    );
  }
}
