import { useCurrentUser } from 'hooks/useCurrentUser';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteProps } from 'react-router';
import styled from 'styled-components';
import { addQueryString, getBaseAngularUrl } from 'utils/pathUtils';

interface IAngularEmbedProps extends RouteProps {
  currentPath: string;
}

const IFrame = styled.iframe`
  display: flex;
  flex-grow: 1;
  border: none;
`;

export type NavigationPath = 'dashboard' | 'courses' | '';

export const AngularEmbed = React.memo<IAngularEmbedProps>((props) => {
  const { t } = useTranslation();
  const iframeRef: any = React.createRef();
  const [currentPath, setCurrentPath] = React.useState(props.location?.pathname);
  const baseUrl = getBaseAngularUrl();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (props.location?.pathname) {
      setCurrentPath(mapV4PathToV3Path(props.location.pathname));
    }
  }, [props.location?.pathname]);

  useEffect(() => {
    if (currentPath?.startsWith('/nav/partners')) {
      var title = t('Admin') + ' | Cerego';
      if (currentPath?.endsWith('groups')) {
        title = t('Groups') + ' | ' + title;
      } else if (currentPath?.endsWith('people')) {
        title = t('People') + ' | ' + title;
      } else if (currentPath?.endsWith('staff')) {
        title = t('Staff') + ' | ' + title;
      } else if (currentPath?.endsWith('features')) {
        title = t('Account Set-up') + ' | ' + title;
      }
      document.title = title;
      return;
    }

    switch (currentPath) {
      case '/nav/dashboard':
        document.title = t('Dashboard') + ' | Cerego';
        break;
      case '/nav/partners/dashboard':
        document.title = t('Admin') + ' | Cerego';
        break;
      default:
        document.title = t('Cerego');
    }
  }, [currentPath]);

  window.onpopstate = (e) => {
    iframeRef.current.contentWindow.history.back();
  };

  function mapV4PathToV3Path(currentPath) {
    const primaryPartnerSlug = currentUser?.primaryPartner?.slug;
    if (currentPath === '/nav/partners/dashboard') {
      return `/nav/partners/${primaryPartnerSlug}/dashboard`;
    } else if (currentPath === '/nav/partners/api_users') {
      return `/nav/partners/${primaryPartnerSlug}/api_users`;
    } else {
      return currentPath;
    }
  }

  const angularPath = currentPath?.replace('/nav', '');
  const queryParams = addQueryString('isV4Embed=true', props.location);

  return <IFrame id="angular-embed" src={`${baseUrl}${angularPath}${queryParams}`} ref={iframeRef} />;
});
