import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Color } from 'core';
import styled from 'styled-components';

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableHeaderText = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.54);
`;

export const SortArrow = styled(FontAwesomeIcon)`
  height: 0.75rem;
  color: rgb(0, 0, 0, 0.54);
  margin-left: 5px;
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const DataTable = styled.table`
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  color: ${Color.primaryBlack};
  font-size: 1em;
  font-weight: 400;
`;

export const TableContainer = styled.div`
  background-color: ${Color.white};
  border: 1px solid ${Color.lightGray};
  border-radius: 5px;
  padding-bottom: 20px;
`;
