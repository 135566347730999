import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { useItemEditorStore } from 'components/item-editor/store';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { TSound } from 'types';
import { createSound } from 'utils/createSound';
import { useMutateSentence } from 'hooks/useMutateSentence';
import useStore from 'zstore';

interface SentenceSoundProps {
  sound: TSound | null;
  sentenceId: string;
}

export const SentenceSound = (props: SentenceSoundProps) => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const { sound, sentenceId } = props;
  const [mutateSentence] = useMutateSentence(item?.id, assignment?.id);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { makeToast } = useStore();

  const handleSoundClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updateSentence = async (sound: TSound | null) => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const updatedSentences = item.sentences.map((sentence) =>
      sentence.id === sentenceId ? { ...sentence, sound } : sentence
    );

    // update app state
    updateActiveItem({ sentences: updatedSentences });

    // update the image in the database
    await mutateSentence({
      id: sentenceId,
      payload: {
        data: {
          relationships: {
            sound: {
              data: {
                id: sound?.id,
              },
            },
          },
        },
      },
    });
  };

  const handleSoundUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const file = event.target.files?.[0];

    try {
      if (file) {
        const sound = await createSound(file);

        updateSentence(sound);
      }
    } catch (error: any) {
      // TODO: roll back optimistic update
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  };

  const handleDeleteSound = async () => {
    updateSentence(null);
  };

  if (!sound) {
    return (
      <>
        <IconButton onClick={handleSoundClick}>
          <VolumeUpIcon />
        </IconButton>
        <FileInput ref={fileInputRef} type="file" accept="audio/*" onChange={handleSoundUpload} />
      </>
    );
  }

  return (
    <div>
      <audio controls>
        <source src={sound.url} type={sound.mimeType || 'audio/mpeg'} />
        Your browser does not support the audio element.
      </audio>
      <IconButton onClick={handleDeleteSound}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

const FileInput = styled.input`
  display: none;
`;
