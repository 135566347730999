import React from 'react'
import { AssignmentLabel } from 'courses/components/AssignmentLabel';
import { Link } from 'react-router-dom';
import { TAssignment } from 'types';

export const ContentTitle = ({ assignment, courseId }: { assignment: TAssignment; courseId: string }) => {
  return (
    <Link
      to={{
        pathname: `/nav/courses/${courseId}/sets/${assignment.id}`,
        state: {
          canEdit: assignment.meta.canEdit,
        },
      }}
    >
      <AssignmentLabel assignment={assignment} />
    </Link>
  );
};

