import Chip from '@mui/material/Chip';
import { Color } from 'core';
import React from 'react';
import { TItemDifficulty } from 'types';

type Props = {
  retentionModifier: number;
  na?: boolean;
  style?: object;
};

export const ItemDifficulty = (props: Props) => {
  const { retentionModifier } = props;

  const difficultyText = props.na ? 'N/A' : formatDifficulty(retentionModifier);
  const styles = getDifficultyColors(difficultyText);

  const style = { ...styles, borderRadius: '6px', width: '100%' };

  return <Chip style={style} variant="outlined" label={difficultyText} />;
};

type DifficultyColors = {
  backgroundColor: string;
  color: string;
  borderColor: string;
};

const DIFFICULTY_COLOURS = {
  'Very Hard': Color.diverging[0],
  Hard: Color.diverging[1],
  Moderate: Color.diverging[2],
  Easy: Color.diverging[3],
  'Very Easy': Color.diverging[4],
};

const getDifficultyColors = (difficulty: TItemDifficulty): DifficultyColors => {
  const color = DIFFICULTY_COLOURS[difficulty] ?? DIFFICULTY_COLOURS.Moderate;

  return {
    backgroundColor: Color.hexToRGB(color, 0.15),
    color: Color.hexToRGB(color, 1, 0.4),
    borderColor: Color.hexToRGB(color, 1, 0.1),
  };
};

export const formatDifficulty = (retentionModifier: number): TItemDifficulty => {
  if (retentionModifier < -0.13) {
    return 'Very Hard';
  } else if (retentionModifier < -0.04) {
    return 'Hard';
  } else if (retentionModifier < 0.04) {
    return 'Moderate';
  } else if (retentionModifier < 0.09) {
    return 'Easy';
  }
  return 'Very Easy';
};
