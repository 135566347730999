import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import { Color } from 'core';
import { IButton } from 'core/button';
import { hoverShadow } from 'core/v4button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import generateId from 'utils/generateId';

export const MenuButtonObj = styled.button.attrs(({ disabled = false }) => {
  return { disabled };
})`
  display: inline-block;
  color: ${Color.primaryBlue};
  background-color: ${Color.white};
  border: 2px ${Color.primaryBlue} solid;
  border-radius: 2em;
  font-size: 0.75rem;
  font-weight: 700;
  font-family: inherit;
  white-space: nowrap;
  padding: 0.5em 1em;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: none;

  &:hover {
    background-color: ${props => (props.disabled ? Color.white : Color.primaryBlue)};
    color: ${props => (props.disabled ? Color.primaryBlue : Color.white)};
    box-shadow: ${props => (props.disabled ? '' : hoverShadow)};
  }
`;

const MenuButtonSpinner = styled(CircularProgress)`
  color: ${Color.primaryBlue};

  &:hover {
    color: ${Color.white};
  }
`;

interface Dimensions {
  height: number;
  width: number;
}

interface IMenuButtonProps extends IButton {
  hideArrow?: boolean;
  text: string;
  open?: boolean;
  pending?: boolean;
}

const MenuButton = (props: IMenuButtonProps) => {
  const buttonId: string = generateId();
  const { hideArrow, pending, click, open, text } = props;
  const [buttonDimensions, setButtonDimensions] = useState<Dimensions | null>(null);

  const getButtonDimensions = (id: string): Dimensions | undefined => {
    const button = document.getElementById(id);
    if (button) {
      const dims: Dimensions = button.getBoundingClientRect();
      return dims;
    }
    return undefined;
  };

  const arbitraryMagicNumber: number = 19;
  const spinnerSize: number = buttonDimensions ? buttonDimensions.height / 2 : arbitraryMagicNumber;
  const buttonStyle = buttonDimensions
    ? { height: `${buttonDimensions.height}px`, width: `${buttonDimensions.width}px` }
    : {};

  useEffect(() => {
    const buttonDimensions = getButtonDimensions(buttonId);
    if (buttonDimensions) setButtonDimensions(buttonDimensions);
  }, []);

  if (hideArrow) {
    return (
      <MenuButtonObj id={buttonId} onClick={click} style={buttonStyle} disabled={pending}>
        {pending && <MenuButtonSpinner size={spinnerSize} style={{ color: Color.primaryBlue }} />}
        {!pending && <span>{text}</span>}
      </MenuButtonObj>
    );
  } else {
    return (
      <MenuButtonObj id={buttonId} onClick={click}>
        {text}&emsp;
        <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />
      </MenuButtonObj>
    );
  }
};

export default MenuButton;
