import { Avatar } from '@mui/material';
import coursePlaceHolderImage from 'assets/images/courses-placeholder-small.png';
import { MemreProgressBar, MemreTable } from 'components/core';
import useCoursesV3 from 'hooks/useCoursesV3';
import useOpenCoursePage from 'hooks/useOpenCoursePage';
import { TableColumnDef } from 'models/table';
import moment from 'moment/moment';
import convertProgressToInteger from 'utils/convertProgressToInteger';

export default function CoursesTable({ coursesCount }) {
  const openCoursePage = useOpenCoursePage();
  const handleRowClick = (row: any): void => {
    const courseId: number = parseInt(row.original.id, 10);
    openCoursePage(courseId);
  };

  const LastStudyTime = (row: any) => {
    const lastStudyTime = row.row.row.original.meta.lastStudyTime;
    return <span>{lastStudyTime && moment(lastStudyTime).fromNow()}</span>;
  };

  const columns: Array<TableColumnDef<any>> = [
    {
      id: 'Image',
      accessorFn: (row: any) => row.name,
      cell: (row: any) => (
        <Avatar alt={row.row.original.name} src={row.row.original.image?.smallUrl || coursePlaceHolderImage} />
      ),
      header: () => '',
    },
    {
      id: 'Course Name',
      accessorFn: (row: any) => row.name,
      sortable: 'courses.content_name',
      searchable: true,
    },
    {
      id: 'Last Study Time',
      accessorFn: (row: any) => row.meta.lastStudyTime,
      cell: (row: any) => <LastStudyTime row={row} />,
      sortable: 'course_users.last_study_time',
    },
    {
      id: 'Assignments Count',
      accessorFn: (row: any) => row.assignmentsCount,
      rightAlign: true,
    },
    {
      id: 'Course Progress',
      accessorFn: (row: any) => convertProgressToInteger(row.meta.progress).toString().concat('%'),
      cell: (row: any) => <MemreProgressBar minWidth="60px" progress={parseInt(row.getValue(), 10)} />,
      rightAlign: true,
      sortable: 'course_users.progress',
      fillWidth: true,
    },
  ];

  return (
    <MemreTable
      useData={useCoursesV3}
      columns={columns}
      id={1}
      totalCount={coursesCount}
      handleRowClick={handleRowClick}
      sortBy="course_users.progress"
    />
  );
}
