import { Line } from 'rc-progress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Color } from 'core';
import { ContentText } from 'courses/components/ContentText';
import convertProgressToInteger from 'utils/convertProgressToInteger';

interface IContentParticipation {
  percent: number;
}

const ParticipationWrapper = styled.div`
  max-width: 120px;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const styles = {
  progressBar: {
    display: 'inline-block',
    width: '60%',
    height: '5px',
  },
};

export const ContentParticipation = React.memo<IContentParticipation>((props) => {
  const percentage = convertProgressToInteger(props.percent);
  const { t } = useTranslation();

  return (
    <ParticipationWrapper>
      <Line style={styles.progressBar} percent={percentage} strokeWidth={1} strokeColor={Color.primaryBlue} />
      <span style={{ marginLeft: '10px' }}>
        <ContentText text={t('Content Participation Percentage Text', { percentage })} display="inline" />
      </span>
    </ParticipationWrapper>
  );
});
