import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';

export default function MemreModal({ maxWidth = 'xl', children }) {
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setModalContent(children);
    setOpen(!!children);
  }, [children]);

  const handleCloseModal = () => {
    setModalContent(null);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth,
          maxHeight: '90%',
          overflow: 'scroll',
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
          p: 3,
        }}
      >
        <Box sx={{ position: 'fixed', top: 3, right: 3 }}>
          <IconButton onClick={handleCloseModal} aria-label="close">
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        {modalContent}
      </Box>
    </Modal>
  );
}
