export default function roundToNearestQuarterHour(date: Date): Date {
  const roundedDate = new Date(date.getTime());

  const minutes = roundedDate.getMinutes();
  const seconds = roundedDate.getSeconds();

  const totalMinutes = minutes + seconds / 60;

  if (totalMinutes < 7.5) {
    roundedDate.setMinutes(0, 0, 0);
  } else if (totalMinutes < 22.5) {
    roundedDate.setMinutes(15, 0, 0);
  } else if (totalMinutes < 37.5) {
    roundedDate.setMinutes(30, 0, 0);
  } else if (totalMinutes < 52.5) {
    roundedDate.setMinutes(45, 0, 0);
  } else {
    roundedDate.setMinutes(0, 0, 0);
    roundedDate.setHours(roundedDate.getHours() + 1);
  }

  return roundedDate;
}
