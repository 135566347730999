type ObjectLiteral = Record<string, any>;

export function deepCompareArrays(arr1: ObjectLiteral[], arr2: ObjectLiteral[]): boolean {
  const diffs: string[] = [];

  // Helper function to compare two objects
  function compareObjects(obj1: ObjectLiteral, obj2: ObjectLiteral, prefix: string = '') {
    Object.keys({ ...obj1, ...obj2 }).forEach((key) => {
      const fullPath = prefix ? `${prefix}.${key}` : key;
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        compareObjects(obj1[key], obj2[key], fullPath);
      } else if (obj1[key] !== obj2[key]) {
        diffs.push(`Path: ${fullPath}, Old Value: ${obj1[key]}, New Value: ${obj2[key]}`);
      }
    });
  }

  // Compare arrays of objects
  arr1.forEach((obj, index) => {
    if (!arr2[index]) {
      diffs.push(`Extra object in first array at index ${index}: ${JSON.stringify(obj, null, 2)}`);
    } else {
      compareObjects(obj, arr2[index], `Index ${index}`);
    }
  });

  arr2.forEach((obj, index) => {
    if (!arr1[index]) {
      diffs.push(`Extra object in second array at index ${index}: ${JSON.stringify(obj, null, 2)}`);
    }
  });

  return diffs.length > 0;
}
