export const ANONYMOUS_NAME = '(anonymous)';

export const SUPPORT_URLS = {
  SMART_CREATE: 'https://www.cerego.com/support/how-does-smart-course-create-work',
};

export const SMART_CREATE_TOPIC_PLACEHOLDERS = [
  'Asteroid mining',
  'The history of the American flag',
  'Storytelling through animation',
  'Southeast Asian culinary traditions',
  'Beekeeping and honey production',
  'Neurology',
  'Pedagogy',
  'The history and evolution of video games',
  'Cognitive science',
  'The origins and evolution of jazz music',
  'The history and impact of cryptography',
  'The art and technique of portrait photography',
  'Deep oceanography',
  'Ancient Greek mythology',
  'Endangered languages',
  'Lost cities of ancient civilizations',
  'Surrealist art',
  "The role of fungi in Earth's ecosystems",
  'Social media and mental health',
  'The art and science of sustainable architecture',
  'Robotics and artificial intelligence',
  'Psychology and persuasion',
  'Cultural history of tea ceremonies',
  'Urban farming',
  'Documentary filmmaking',
  'History of the universe',
  'Lucid dreaming',
  'Fashion through the ages',
  'Gothic literature',
  'Renewable energy',
  'Exploring the art of calligraphy',
  'The influence of Impressionist painters',
  'Bioluminescence in the natural world',
  'History of the Silk Road',
  'The impact of the printing press',
  'Understanding body language and nonverbal communication',
  'The golden age of radio',
  'Native American folklore and traditions',
  'The psychology of color perception',
  'The culinary art of fermentation',
  'The evolution of modern dance',
  'The role of women in ancient civilizations',
  'The cultural significance of tattoos',
  'Astronomy',
  'The art of bonsai cultivation',
  'History of space exploration',
  'The science of sound and acoustics',
  'Inventions that changed the world',
  'The architectural wonders of ancient Rome',
  'The impact of the Internet on society',
  'The migration patterns of monarch butterflies',
  'Origins and history of martial arts',
  'The world of competitive chess',
  'The role of microbes in human health',
  'The art of papermaking',
  'History and evolution of musical theater',
  'Gemstones and minerals',
  'Principles of economics',
  'The cultural and historical significance of mythology',
  'Biohacking',
  'Fission and fusion',
  'Gene editing and CRISPR',
  'Voluntaryism',
  'Open-source software and innovation',
  'Remote work and digital nomadism',
  'The evolution of the startup ecosystem',
  'History of education',
  'Nuclear energy',
  'Nanotechnology',
  'The science and symbolism of dreams',
  'The art of stop-motion animation',
  'History and techniques of glassblowing',
  'Origins and cultural significance of coffee rituals',
  'The impact of animal-assisted therapy on mental health',
  'Ethnomusicology: the study of music across cultures',
  'Psychedelic substances and their role in therapy and self-exploration',
  'Pioneering women in science and technology',
  'Ancient engineering marvels and their influence on modern architecture',
  'The world of professional esports',
  'The art and practice of mindfulness and meditation',
  'The history and cultural significance of puppetry',
  'The science behind human memory and learning',
  "Exploration of the Earth's polar regions",
  'The history and impact of human rights movements',
  'Aquaculture and the future of sustainable seafood',
  'The golden age of television and its influence on popular culture',
  'The development and impact of wearable technology',
  'The cultural significance of street art and graffiti',
  'The history of alchemy and its influence on modern chemistry',
  'The evolution of human languages and linguistic diversity',
  'The role of zoos and wildlife conservation in the modern world',
  'The art, science, and ethics of taxidermy',
  'Ancient navigation techniques and the age of exploration',
  'The history and development of stand-up comedy',
  'The role of emotions in decision-making and human behavior',
  'The impact of climate change on global ecosystems and biodiversity',
  'The psychology and neuroscience of humor',
  'The influence of indigenous knowledge on modern science and technology',
  'The history and significance of the Olympic Games',
  'The development and impact of virtual and augmented reality',
  'Quantum computing: principles and potential applications',
  'The search for extraterrestrial life in the universe',
  'The history and future of human space colonization',
  'Artificial photosynthesis and its potential for renewable energy',
  'The science and applications of biomimicry in design and engineering',
  'The history and evolution of transportation technology',
  'The role of 3D printing in medicine, manufacturing, and beyond',
  'The emerging field of optogenetics and its potential applications',
  'The exploration and utilization of geothermal energy',
  'The world of synthetic biology and its implications',
  'The development and impact of electric and self-driving vehicles',
  'The science and technology of renewable energy storage systems',
  'The history and future of the World Wide Web',
  'The role of materials science in advancing technology',
  'The ethics and future of genetic engineering in humans',
  'The principles and applications of fluid dynamics',
  'The development and impact of drones and unmanned aerial vehicles',
  'The history and evolution of artificial intelligence and machine learning',
  'The science of exoplanets and the potential for habitable worlds',
  'The role of computer vision in shaping our world',
  'Technological innovations in agriculture and food production',
  'The science and potential of fusion energy',
  'The impact of nanomaterials on science and technology',
  'The development and applications of superconductors',
  'The role of satellites in modern communication and surveillance',
  'The evolution of telecommunications technology',
  'The science and applications of holography',
  'The history and future of robotics in healthcare',
  'Advancements in medical imaging and diagnostic technology',
  'The Rhetoric of Social Movements: Strategies, Tactics, and Impact',
  'Eco-friendly Textiles: Innovations in Sustainable Fashion Design',
  'Deconstructing Utopias: Visions of Ideal Societies in Literature and Philosophy',
  'Sensory Design: The Intersection of Architecture, Psychology, and Neuroscience',
  'Culinary Chemistry: The Science Behind Cooking and Flavor',
  'The Philosophy of Time Travel: Paradoxes, Ethics, and Multiverses',
  "An Introduction to the World's Most Notorious Pirate Histories and Legends",
  'The Art of Memory: Techniques and Strategies for Enhanced Learning',
  'Mathematics in Nature: Patterns, Sequences, and Symmetry',
  'The Intersection of Art and Mathematics: From the Golden Ratio to Fractals',
  'The Science of Happiness: Psychological and Societal Factors',
  'The History of Espionage: Spy Craft, Intelligence, and Counterintelligence',
  'Designing for Inclusivity: Universal Design and Accessibility',
  'Revolutionary Technologies of the 21st Century: A Comparative Analysis',
  'World Music Traditions: Exploring the Diversity of Human Expression',
  'The Science of Sleep: Physiology, Disorders, and Optimization',
  'A Comprehensive Guide to Wildlife Rehabilitation and Conservation',
  'The Evolution of Comedy: From Ancient Greece to Modern Stand-Up',
  'Digital Forensics: Cybersecurity and the Investigation of Cybercrime',
  'Ethnobotany: The Relationship Between Humans and Plant Life',
  'From Quill to Keyboard: The Evolution of Writing Instruments and Techniques',
  'The Archaeology of Food: Culinary Traditions of Ancient Civilizations',
  'Quantum Entanglement and Teleportation: Principles and Applications',
  'The Art of Typography: History, Design, and Techniques',
  'The Psychology of Conspiracy Theories: Origins, Impacts, and Debunking Strategies',
  'Virtual Economies: The Rise of Cryptocurrencies and Digital Assets',
  'The History and Techniques of Bookbinding and Book Conservation',
  'The Science and Art of Perfumery: Creating Scents and Fragrances',
  'The Multiverse Hypothesis: An Exploration of Parallel Realities',
  'The Science of Laughter: Physiological, Psychological, and Social Perspectives',
  'Exploring the Human Microbiome: Health, Disease, and the Microbial World Within Us',
  'Unlocking the Secrets of the Brain: An Introduction to Cognitive Neuroscience',
  'The Art and Craft of Storytelling: From Oral Traditions to Modern Media',
  'The Hidden World of Caves: Speleology, Exploration, and Conservation',
  'The Mathematics of Cryptography: Codes, Ciphers, and Secrets',
  'The History of Animation: Techniques, Innovations, and Cultural Impact',
  'The Magic of Fermentation: Science, Techniques, and Culinary Applications',
  'From Stargazers to Space Travelers: A History of Astronomical Discoveries',
  'The Art of Conversation: Strategies for Effective Communication and Connection',
  'The Science of Climate Change: Causes, Consequences, and Solutions',
  'The Anthropology of Food: Culinary Traditions and Foodways Across Cultures',
  'The Evolution of Human Communication: From Hieroglyphs to Emojis',
  'The Science and Ethics of Organ Transplantation and Regenerative Medicine',
  'The Wonderful World of Whales: Biology, Conservation, and Human Interactions',
  'The Physics of Sound: Acoustics, Music, and Noise Control',
  'The History and Science of Optical Illusions: Perception and Visual Trickery',
  'The World of Tiny Homes: Design, Sustainability, and Lifestyle',
  'The Poetry of Science: The Art of Scientific Communication and Creative Expression',
  'The Art of Propaganda: Persuasion, Manipulation, and Influence in Mass Media',
  'The Science of Aging: Biology, Medicine, and the Quest for Longevity',
  'The Hidden Language of Trees: Forest Ecology, Communication, and Conservation',
  'The History of Timekeeping: Clocks, Calendars, and Chronology',
  'The Science of Taste: Flavor Perception, Food Chemistry, and Sensory Evaluation',
  'The Evolution of Board Games: History, Design, and Cultural Impact',
  'The Language of Cinema: Film Theory, Techniques, and Visual Storytelling',
  'The Physics of Everyday Life: The Science Behind the Mundane and the Extraordinary',
  'The Art and Science of Memory Palaces: Techniques for Enhanced Learning and Recall',
  "Discovering the Depths: The Science and Exploration of the World's Oceans",
  'The History of Medicine: A Journey Through Time and Healing Practices',
  'The Science and Art of Decision-Making: Strategies for Success and Fulfillment',
  'Unlocking the Secrets of the Universe: An Introduction to Astrophysics and Cosmology',
  'The Language of Flowers: Botanical Symbolism and Cultural Significance',
  'The Science of Artificial Intelligence: Algorithms, Ethics, and Future Developments',
  'Pioneering Women in World History: Their Stories, Achievements, and Legacies',
  'The Art of Deception: Magic, Illusion, and the Psychology of Perception',
  'The Global Impact of Epidemics and Pandemics: A Historical and Societal Perspective',
  'The Science and Craft of Brewing: From Ancient Techniques to Modern Innovations',
  'The World of Insects: Entomology, Ecology, and the Importance of the Smallest Creatures',
  'The Philosophy of Artificial Intelligence: Consciousness, Ethics, and the Singularity',
  'The History of Cartography: Mapmaking, Exploration, and the Shaping of the World',
  'The Ancient Art of Alchemy: Theories, Practices, and Influence on Modern Chemistry',
  'The Origins and Development of Language: Exploring Linguistic Evolution and Diversity',
  'The World of Coral Reefs: Ecology, Biodiversity, and Conservation Challenges',
  'The Science and Ethics of Animal Testing: A Comprehensive Exploration',
  'The Architecture of Sacred Spaces: Design, Symbolism, and Cultural Significance',
  'The Psychology of Creativity: Unlocking the Secrets of the Creative Mind',
  'The Science and Art of Chocolate: From Bean to Bar and Beyond',
  'The History of Money: Currency, Trade, and the Evolution of Economic Systems',
  'The Science of Fear: The Biology, Psychology, and Cultural Aspects of What Scares Us',
  'The Lost Art of Penmanship: The History and Techniques of Handwriting and Calligraphy',
  'The Science and Impact of Climate Engineering: Solutions, Risks, and Ethics',
  'The History of Supernatural Beliefs: Ghosts, Spirits, and the Paranormal Across Cultures',
  'The Art of Science Fiction: Exploring Imaginary Worlds and Futuristic Technologies',
  'The Evolution of Sports: Ancient Origins, Modern Developments, and Cultural Significance',
  'The Power of Myth: Exploring the Role of Myths in Culture, Religion, and Psychology',
  'The Science of Forensic Investigation: Solving Crimes Through Biology, Chemistry, and Physics',
  'The Art of Meditation: Techniques, Traditions, and the Science of Mindfulness',
  'The History and Future of Space Tourism: Commercial Spaceflight and Extraterrestrial Travel',
  'The Psychology of Attraction: The Science Behind Love, Lust, and Human Connection',
  'The Science of Weather and Climate: Atmospheric Phenomena and Their Global Impact',
  'Quantum Mechanics',
  'Ancient Philosophy',
  'Modern Sculpture',
  'Plant Intelligence',
  'Art Conservation',
  'Ornithology',
  'Cyber Warfare',
  'Meteorology',
  'Bioethics',
  'Urban Planning',
  'Comparative Mythology',
  'Sustainable Tourism',
  'Animal Behavior',
  'Oceanography',
  'Geomorphology',
  'Parapsychology',
  'Astrobiology',
  'Forensic Psychology',
  'Food Anthropology',
  'Game Theory',
  'Astrochemistry',
  'Digital Art',
  'Cryptology',
  'Ecosystem Restoration',
  'Space Medicine',
  'Hydroponics',
  'Ethnoastronomy',
  'Bioacoustics',
  'Geomancy',
  'Neuromarketing',
  'Aerodynamics',
  'Esperanto',
  'Wildlife Photography',
  'Microbiology',
  'Urban Ecology',
  'Epigenetics',
  'Paleoanthropology',
  'Ethnomusicology',
  'Robot Ethics',
  'Neurophilosophy',
  'Animal Communication',
  'Retrofuturism',
  'Cryptozoology',
  'Fashion History',
  'Dendrochronology',
  'Marine Biology',
  'Cognitive Linguistics',
  'Dark Matter',
  'Theater Production',
  'Phonetics',
  'Agroforestry',
  'Archeoastronomy',
  'Enigmatology',
  'Aquaculture',
  'Particle Physics',
  'Digital Currencies',
  'Virology',
  'Paleoclimatology',
  'Speculative Fiction',
  'Sports Psychology',
  'Herpetology',
  'Biomedical Engineering',
  'Intercultural Communication',
  'Astrogeology',
  'Glassblowing',
  'Permaculture Design',
  'Human-Computer Interaction',
  'Ceramics',
  'Futurology',
  'Computational Biology',
  'Zoology',
  'Puppetry',
  'Sociolinguistics',
  'Graph Theory',
  'Environmental Psychology',
  'Synesthesia Research',
  'Evolutionary Genetics',
  'Digital Archaeology',
  'Earthquake Engineering',
  'Bioinformatics',
  'Cognitive Anthropology',
  'Polar Studies',
  'Symbolic Logic',
  'Myrmecology',
  'Structural Geology',
  'Molecular Gastronomy',
  'Aviation History',
  'Computational Neuroscience',
  'Experimental Typography',
  'Industrial Ecology',
  'Tectonics',
  'Nanobiotechnology',
  'Astrophotography',
  'Affective Neuroscience',
  'Urban Entomology',
  'Ecofeminism',
  'Exoplanetology',
  'Architectural Acoustics',
  'Ichthyology',
  'Positive Psychology',
  'Origami Mathematics',
  'Seismology',
  'Immunology',
  'Biomimicry',
  'Data Visualization',
  'Mineralogy',
  'Mycology',
  'Hydrometeorology',
  'Paleobotany',
  'Exobiology',
  'Ecomusicology',
  'Nematology',
  'Comparative Religion',
  'Petroleum Geology',
  'Soil Science',
  'Systems Biology',
  'Anthropocene Studies',
  'Toxicology',
  'Gastronomy',
  'Biosemiotics',
  'Dance Therapy',
  'Bioelectricity',
  'Teratology',
  'Primatology',
  'Medical Anthropology',
  'Metamaterials',
  'Numerical Analysis',
  'Entomophagy',
  'Biomaterials',
  'Paleoecology',
  'Unlocking the Mysteries of Dreams: A Multidisciplinary Exploration',
  'The Science of Happiness: Psychological, Sociological, and Biological Perspectives',
  'A Journey Through Art Movements: From the Renaissance to Postmodernism',
  'The Science of Sleep: Understanding the Importance of Rest and Its Impact on Health',
  'The Art and History of Storyboarding: Visual Narratives in Film and Animation',
  'The Creative Process: Exploring the Origins and Development of Artistic Inspiration',
  'The World of Butterflies and Moths: Ecology, Behavior, and Conservation',
  'Exploring the Cosmos: An Introduction to Astronomy for Beginners',
  'The History of Magic and Occultism: From Ancient Practices to Modern Revivals',
  'The Psychology of Interpersonal Relationships: Friendship, Love, and Conflict',
  'The Language of Film: Cinematic Techniques and Storytelling Devices',
  'The Science and Art of Cooking: Techniques, Flavors, and Cultural Perspectives',
  "Nature's Wonders: Exploring the Beauty and Science of Natural Phenomena",
  'The World of Collecting: History, Motivations, and the Impact on Culture',
  'The Architecture of Happiness: How Design Influences Well-being and Emotion',
  'The Science of Exercise: Physiology, Benefits, and Personalized Training',
  'The History of Toys and Games: Entertainment, Education, and Cultural Significance',
  'The Art of Street Photography: Techniques, Ethics, and Social Commentary',
  'The Science of Memory: How We Remember, Forget, and Make Sense of the Past',
  'The Philosophy of Humor: Theories, Functions, and Social Implications',
  'The History of Fashion Design: Innovations, Trends, and Cultural Impact',
  'The Science of Coffee: From Bean to Cup and Beyond',
  'The Psychology of Music: How Sound Affects Emotion, Cognition, and Behavior',
  "Nature's Timekeepers: The Science of Biological Clocks and Rhythms",
  'The Art of Collage: Techniques, Materials, and Creative Expression',
  'The Science of Laughter: The Physiology, Psychology, and Social Benefits',
  'The World of Myths and Legends: A Cross-Cultural Exploration',
  'The History and Craft of Bookbinding: Techniques, Materials, and Conservation',
  'Exploring the Animal Mind: Cognition, Emotion, and Intelligence in Non-Human Species',
  'The Science of Optical Illusions: Perception, Neuroscience, and Art',
  'The Art of Origami: Geometry, Culture, and Creative Expression',
  'The World of Spices: History, Flavors, and Culinary Applications',
  'The Science of Plant Communication: Chemical Signals, Symbiosis, and Ecology',
  'The Philosophy of Language: Meaning, Truth, and Communication',
  'The History and Evolution of Typography: From Gutenberg to Digital Fonts',
  'The Science of Emotions: Exploring the Complexities of Human Feelings',
  'The World of Tea: Cultivation, Culture, and Rituals',
  'The Art of Animation: History, Techniques, and Creative Expression',
  'The Science of Time Travel: Theories, Paradoxes, and Cultural Imagination',
  'The World of Ancient Civilizations: An Overview of History, Culture, and Achievements',
];

export const PARTNER_IDS = {
  QLEARN: [15362, 11277, 13183],
};

export const LINKS = {
  HELP: 'https://www.cerego.com/support',
};

export const MAX_PAGE_SIZE = 200; // from kaminari in smart_api
