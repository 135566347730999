import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getGroup = async (_key: string, groupId: string) => {
  const response = await axios.get(`/api/v3/groups/${groupId}`);
  return normalizeJSONAPIResponse(response.data);
};

export const useGroup = (groupId: string) => {
  return useQuery(['group', groupId], getGroup, {
    enabled: groupId,
  });
};
