import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { EmployeeCard } from './EmployeeCard';
import { pollForJobData } from 'utils/pollingUtils';

const Container = styled.div`
  padding: 20px;
`;

const SearchInput = styled.input`
  margin-right: 10px;
`;

export const AthenaPrototypePage = React.memo(() => {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSearch = async () => {
    setLoading(true);
    try {
      setErrorMessage(null);
      setEmployees([]);
      const response = await axios.get(`/api/athena_api/users?topic=${searchQuery}`);
      const jobId = response.data.job_id;
      const jobData = await pollForJobData(jobId, (data) => setEmployees(data ?? []));
      setEmployees(jobData);
    } catch (error) {
      setErrorMessage(`Error fetching data: ${error}`);
      // Handle error accordingly
    }
    setLoading(false);
  };

  return (
    <Container>
      <h1>Athena Prototype</h1>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      <p>
        Type a term to search for employees with knowledge related to that term (Examples: SAML, CI/CD, Infrastructure)
      </p>
      <div>
        <SearchInput type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
        <button onClick={handleSearch}>Search</button>
      </div>
      {loading && <div>Loading...</div>}
      {employees.map((employee, index) => (
        <div style={{ background: index % 2 == 0 ? '#ddd' : 'white', margin: '30px 0', padding: '1em' }}>
          <EmployeeCard key={index} employee={employee}></EmployeeCard>
        </div>
      ))}
    </Container>
  );
});
