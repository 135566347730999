import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { performActionWithConfirmation } from './siteAdminUtils';

export function SiteAdminPartners() {
  const [partner, setPartner] = useState<any>({
    name: null,
    stage: null,
    segment: null,
    vertical: null,
    is_mooc: null,
    is_courseware: null,
    is_paid: null,
    size: null,
  });

  const segmentationOptions = {
    stages: ['Free Trial', 'Demo', 'Pilot', 'Active', 'Internal', 'Legacy-Plus', 'Legacy-Wild', 'Closed'],
    segments: ['Publisher', 'Education', 'Training', 'Internal'],
    verticals: ['Gov', 'Non-Profit', 'Finance', 'K-12', 'Higher Ed', 'EdX', 'Other'],
    sizes: ['SMB', 'Enterprise', 'Startup'],
  };

  const doCreate = async (event) => {
    event.preventDefault();

    performActionWithConfirmation('Are you sure you want to create this partner?', async () => {
      await axios.post('/api/v2/admin/partners', partner);
      alert('Partner created');
      window.location.reload();
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'isMooc' || name === 'isCourseware' || name === 'isPaid') {
      if (value === 'true') {
        newValue = true;
      } else if (value === 'false') {
        newValue = false;
      } else {
        newValue = '';
      }
    }

    setPartner((prevPartner) => ({
      ...prevPartner,
      [name]: newValue,
    }));
  };

  const isCreateDisabled = !partner.name || !partner.stage || (partner.isPaid === true && !partner.segment);

  return (
    <div style={{ margin: '20px' }}>
      <br />
      <form onSubmit={doCreate}>
        <input name="name" placeholder="Partner Name" value={partner.name} onChange={handleChange} />
        <br />
        <br />

        <div>Stage</div>
        <select name="stage" value={partner.stage} onChange={handleChange} style={{ width: '150px' }}>
          <option value="">Select Stage</option>
          {segmentationOptions.stages.map((stage) => (
            <option key={stage} value={stage}>
              {stage}
            </option>
          ))}
        </select>

        <div>Paid</div>
        <select name="isPaid" value={String(partner.isPaid)} onChange={handleChange} style={{ width: '150px' }}>
          <option value="">Select</option>
          <option value="true">true</option>
          <option value="false">false</option>
        </select>

        <div>Segment</div>
        <select name="segment" value={partner.segment} onChange={handleChange} style={{ width: '150px' }}>
          <option value="">Select Segment</option>
          {segmentationOptions.segments.map((segment) => (
            <option key={segment} value={segment}>
              {segment}
            </option>
          ))}
        </select>

        <div>Vertical</div>
        <select name="vertical" value={partner.vertical} onChange={handleChange} style={{ width: '150px' }}>
          <option value="">Select Vertical</option>
          {segmentationOptions.verticals.map((vertical) => (
            <option key={vertical} value={vertical}>
              {vertical}
            </option>
          ))}
        </select>

        <div>MOOC</div>
        <select name="isMooc" value={String(partner.isMooc)} onChange={handleChange} style={{ width: '150px' }}>
          <option value="">Select</option>
          <option value="true">true</option>
          <option value="false">false</option>
        </select>

        <div>Courseware</div>
        <select
          name="isCourseware"
          value={String(partner.isCourseware)}
          onChange={handleChange}
          style={{ width: '150px' }}
        >
          <option value="">Select</option>
          <option value="true">true</option>
          <option value="false">false</option>
        </select>

        <div>Company Size</div>
        <select name="size" value={partner.size} onChange={handleChange} style={{ width: '150px' }}>
          <option value="">Select Size</option>
          {segmentationOptions.sizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>

        <br />
        <br />
        <button type="submit" disabled={isCreateDisabled}>
          Create
        </button>
      </form>
      <br />
      <br />
    </div>
  );
}
