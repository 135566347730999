import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Color } from 'core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAdminDashStore } from 'zstore';

export const Searchbar = () => {
  const { t } = useTranslation();
  const { setPage, query, setQuery } = useAdminDashStore();

  return (
    <ToolbarContainer>
      <SearchContainer hasSearchValue={!!query} style={{ width: '100%', alignItems: 'center' }}>
        <SearchIcon>
          <FontAwesomeIcon icon={faSearch} />
        </SearchIcon>
        <SearchInput
          type="text"
          value={query}
          placeholder={t('Search by learner name')}
          onChange={(e) => {
            setQuery(e.target.value);
            setPage(1);
          }}
        />
      </SearchContainer>
    </ToolbarContainer>
  );
};

const ToolbarContainer = styled.div`
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
  grid-gap: 0em 1em;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;

interface ISearchContainerProps {
  hasSearchValue?: boolean;
}

const SearchContainer = styled.div`
  box-shadow: inset 0 1px 0.25em 0 rgba(0, 0, 0, 0.3);
  border: 1px solid transparent;
  display: grid;
  padding: 0.8rem 1.2rem;
  color: ${Color.secondaryGray};
  border-radius: 0.25em;

  ${(props: ISearchContainerProps) =>
    props.hasSearchValue
      ? `
      box-shadow: inset 0 0 .25em 0 rgba(51,58,236,.7);
      border: 1px solid rgba(51,58,236,.7);
      grid-template-columns: 1fr;

      input {
        color: ${Color.primaryBlue};
        font-weight: bold;
      }

      svg {
        display:none;
      }
    `
      : `
      grid-template-columns: 1em 1fr;
      grid-gap: 0 .5em;
    `}
`;

const SearchInput = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  font-family: inherit;

  ::placeholder {
    color: ${Color.reportGray};
    opacity: 1; // firefox
    font-weight: bold;
  }
`;

const SearchIcon = styled.div`
  svg {
    padding-top: 1px; // override
  }
`;
