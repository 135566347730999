import { Avatar } from '@mui/material';
import { MemreButton, MemreFlexBox, MemreIcon, MemreModal, MemreTable } from 'components/core';
import * as Color from 'core/colors';
import useInstructionalItemsV3, { useInvalidateInstructionalItems } from 'hooks/useInstructionalItemsV3';
import { TableColumnDef } from 'models/table';
import { useEffect, useState } from 'react';
import getInstructionalItemsType from 'utils/getInstructionalItemsType';
import toTitleCase from 'utils/toTitleCase';
import { v4 as uuid } from 'uuid';
import setInstructionalItemsComplete from '../setInstructionalItemsComplete';
import InstructionalItemModal from './InstructionalItemModal';

export default function InstructionalItemsTable({ instructionalItemsCount, courseId }) {
  const [startTime, setStartTime] = useState(+new Date());
  const [sessionGuid, setSessionGuid] = useState(uuid());
  const [setAlreadySeen] = useInvalidateInstructionalItems(setInstructionalItemsComplete);
  const [currentItem, setCurrentItem] = useState<any>(null);

  useEffect(() => {
    setSessionGuid(uuid());
  }, []);

  const handleDoneClick = (item: any) => {
    if (item) {
      setCurrentItem(null);
      const endTime = +new Date();
      const duration = endTime - startTime;
      setAlreadySeen({ id: item.id, duration, sessionGuid });
    }
  };

  const handleOpenModal = (item: any) => {
    setStartTime(+new Date());
    setCurrentItem(item);
  };

  const columns: Array<TableColumnDef<any>> = [
    {
      id: 'Image',
      accessorFn: (row: any) => getInstructionalItemsType(row.annotations) || 'document',
      cell: (row: any) => (
        <Avatar sx={{ bgcolor: Color.darkPurple }}>
          <MemreIcon iconName={row.getValue() || 'document'} color={Color.white} fontSize="1.2em" />
        </Avatar>
      ),
      header: () => '',
    },
    {
      id: 'Assignment Name',
      fillWidth: true,
      accessorFn: (row: any) => row.title,
    },
    {
      id: 'Type',
      accessorFn: (row: any) => toTitleCase(getInstructionalItemsType(row.annotations)),
    },
    {
      id: 'Status',
      accessorFn: (row: any) => row.meta?.alreadySeen,
      cell: (row: any) => (
        <MemreFlexBox gap={1} direction="row" align="center">
          <MemreIcon
            iconName="check"
            color={row.getValue() ? Color.ceregoGreen : Color.veryLightGray}
            fontSize="1.2em"
          />
          {row.getValue() ? 'Done' : 'Not Done'}
        </MemreFlexBox>
      ),
    },
    {
      id: 'CTA',
      header: () => '',
      accessorFn: (row: any) => ({
        text: row.meta?.alreadySeen ? 'Review' : 'Learn',
        item: row,
      }),
      cell: (row: any) => (
        <MemreButton
          text={row.getValue().text}
          variant="outlined"
          color={row.getValue().text === 'Review' ? 'secondary' : 'primary'}
          iconName="arrowFromBracket"
          onClick={() => handleOpenModal(row.getValue().item)}
          size="small"
        />
      ),
    },
  ];

  return (
    <>
      <MemreTable
        useData={useInstructionalItemsV3}
        columns={columns}
        id={courseId}
        totalCount={instructionalItemsCount}
      />
      <MemreModal>
        {currentItem && <InstructionalItemModal item={currentItem} handleDoneClick={handleDoneClick} />}
      </MemreModal>
    </>
  );
}
