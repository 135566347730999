import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Datepicker from 'components/Datepicker';
import ErrorMessage from 'components/ErrorMessage';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import AssignmentFormHeader from 'courses/components/AssignmentFormHeader';
import useMutateAssignment from 'hooks/useMutateAssignment';
import { dueBeforePublishDate } from 'models/set';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, isInPast } from 'utils/dateUtils';

type RadioSelection = 'anytime' | 'specific-date';

const initializeRadioSelection = (assignment: any): RadioSelection => {
  if (assignment.due_at) {
    return 'specific-date';
  } else {
    return 'anytime';
  }
};

interface IAssignmentDueDateForm {
  assignment: any;
  courseId: string;
  onCancel: () => void;
}

const AssignmentDueDateForm = (props: IAssignmentDueDateForm) => {
  const [radioSelection, setRadioSelection] = React.useState<RadioSelection>(
    initializeRadioSelection(props.assignment)
  );
  const [dueDate, setDueDate] = React.useState<string | null>(props.assignment.due_at);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const { t } = useTranslation();

  const [mutateAssignment, { status: mutationStatus }] = useMutateAssignment();

  const handleRadioChange = (event: any): void => {
    setRadioSelection(event.target.value);
  };

  const handleSave = async () => {
    const updateValue = radioSelection === 'anytime' ? null : dueDate;
    await mutateAssignment({
      ids: { setId: props.assignment.id, courseId: props.courseId },
      payload: { due_at: updateValue }
    });
    props.onCancel();
  };

  const validDueDateSelection = (dueDateSelection: string): boolean => {
    if (isInPast(dueDateSelection)) {
      setErrorMessage(t('Cannot set assignment due date in the past'));
      return false;
    } else if (dueBeforePublishDate(dueDateSelection, props.assignment.publish_at)) {
      setErrorMessage(
        t('Cannot set assignment due date before publish date ').concat(formatDate(props.assignment.publish_at))
      );
      return false;
    }
    return true;
  };

  const handleDateChange = (dueDateSelection: string | null): void => {
    if (dueDateSelection && validDueDateSelection(dueDateSelection)) {
      setErrorMessage('');
      setDueDate(dueDateSelection);
    }
  };

  return (
    <div style={{ width: '400px' }}>
      <AssignmentFormHeader assignmentName={props.assignment.name} formTitle={t('Due Date')} />

      <FormControl component="fieldset" style={{ width: '323px' }}>
        <RadioGroup aria-label={t('Due Date')} name="due-date-form" value={radioSelection} onChange={handleRadioChange}>
          <FormControlLabel value="anytime" control={<Radio color="primary" />} label={t('Anytime')} />
          <FormControlLabel value="specific-date" control={<Radio color="primary" />} label={t('On a specific date')} />
          {radioSelection === 'specific-date' && <Datepicker onChange={handleDateChange} />}
        </RadioGroup>

        <div style={{ marginTop: '1em' }}>
          <ConfirmOrCancel
            cancelText={t('Cancel')}
            confirmText={t('Update')}
            handleCancelClick={props.onCancel}
            handleConfirmClick={handleSave}
            confirmIsDisabled={errorMessage !== ''}
            confirmIsPending={mutationStatus === 'loading'}
          />
        </div>

        <ErrorMessage error={errorMessage} />
      </FormControl>
    </div>
  );
};

export default AssignmentDueDateForm;
