import { DndContext, DragEndEvent, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Button from '@material-ui/core/Button';
import LoadingComponent from 'components/LoadingComponent';
import { Modal } from 'components/Modal';
import { AssignmentItemCard } from 'components/assignment-items/AssignmentItemCard';
import { EditableName } from 'components/assignment-items/EditableName';
import { ImageUploader } from 'components/assignment-items/ImageUploader';
import { ContentTemplateForm } from 'components/item-editor/ContentTemplateForm';
import { ItemEditor } from 'components/item-editor/ItemEditor';
import { useItemEditorStore } from 'components/item-editor/store';
import { Card, PageContainer } from 'core/layout';
import { useAssignmentItems } from 'hooks/useAssignmentItems';
import { useAssignments } from 'hooks/useAssignments';
import { useReorderItems } from 'hooks/useReorderItems';
import { IItem } from 'models/item';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import { TAssignment } from 'types';
import useStore from 'zstore';

interface IAssignmentItems {
  match: { params: { courseId: string; setId: string } };
}

export const AssignmentItems = (props: IAssignmentItems) => {
  const { courseId, setId } = props.match.params;
  const { t } = useTranslation();
  const [reorderItems] = useReorderItems(setId);

  // Maintain active courseId in zstore
  const selectCourse = useStore((state) => state.selectCourse);
  useEffect(() => {
    selectCourse(courseId);
  }, [courseId]);

  // Maintain active assignmentId in zstore
  const selectAssignment = useStore((state) => state.selectAssignment);
  useEffect(() => {
    selectAssignment(setId);
  }, [setId]);

  // Fetch assignments and items
  const { data: assignments, isLoading: assignmentsLoading } = useAssignments(courseId);
  const { data: items, isLoading: itemsLoading } = useAssignmentItems(setId);
  const isLoading = itemsLoading || assignmentsLoading;

  const {
    isShowingItemEditor,
    showItemEditor,
    setActiveItem,
    activeAssignment: assignment,
    setActiveAssignment,
  } = useItemEditorStore();

  useEffect(() => {
    setActiveAssignment(assignments?.find((assignment: TAssignment) => assignment.id === setId) as TAssignment);
  }, [assignments]);

  const selectItem = (item: IItem) => {
    setActiveItem(item);
    showItemEditor();
  };

  const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false);

  const addItem = () => {
    setShowTemplateForm(true);
  };

  // State to manage the order of items for drag and drop
  const [itemOrder, setItemOrder] = useState<string[]>([]);

  // Update itemOrder when items change
  useEffect(() => {
    setItemOrder(items?.map((item: any) => item.id));
  }, [items]);

  // Initialize dnd-kit sensors
  const sensors = useSensors(useSensor(PointerSensor));

  // Handle drag end event
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItemOrder((currentOrder) => {
        const oldIndex = currentOrder.indexOf(active.id as string);
        const newIndex = currentOrder.indexOf(over.id as string);
        const newOrder = arrayMove(currentOrder, oldIndex, newIndex);
        // Call reorderItems with the new order of item IDs
        reorderItems({ setId, orderedItemIds: newOrder });
        return newOrder;
      });
    }
  };

  if (isLoading) {
    return <LoadingComponent component={<></>} isLoading={true} />;
  }

  return (
    <>
      <Modal show={isShowingItemEditor} element={<ItemEditor />} angularEmbed={true} />
      <AssignmentItemsContainer>
        <PageContainer>
          <div style={{ width: '720px', margin: '0 auto', paddingBottom: '20px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                margin: '30px auto',
              }}
            >
              <ImageUploader assignment={assignment} courseId={courseId} />
              <EditableName assignment={assignment} courseId={courseId} />
            </div>

            <Button variant="contained" color="primary" onClick={addItem} style={{ marginBottom: '20px' }}>
              {t('Add Item')}
            </Button>

            <div>
              {showTemplateForm ? (
                <OutsideClickHandler onOutsideClick={() => setShowTemplateForm(false)}>
                  <Card>
                    <ContentTemplateForm
                      setId={setId}
                      redirectToItem={(_itemId: string) => setShowTemplateForm(false)}
                    />
                  </Card>
                </OutsideClickHandler>
              ) : items.length === 0 ? (
                <>
                  <h4>{t('There are no items in this assignment!')}</h4>
                  <p>{t('Add one to get started.')}</p>
                </>
              ) : (
                // Wrap items with DndContext and SortableContext for drag-and-drop
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext items={itemOrder} strategy={verticalListSortingStrategy}>
                    {itemOrder.map((id) => {
                      const item = items.find((item) => item.id === id);
                      return item ? <SortableItem key={id} id={id} item={item} onClick={selectItem} /> : null;
                    })}
                  </SortableContext>
                </DndContext>
              )}
            </div>
          </div>
        </PageContainer>
      </AssignmentItemsContainer>
    </>
  );
};

const AssignmentItemsContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-grow: 1;
  border: none;
`;

// SortableItem component to make each item draggable
interface SortableItemProps {
  id: string;
  item: IItem;
  onClick: (item: IItem) => void;
}

const SortableItem = ({ id, item, onClick }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none', // Prevent scrolling while dragging on touch devices
    cursor: 'grab',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <AssignmentItemCard key={id} item={item} onClick={onClick} />
    </div>
  );
};
