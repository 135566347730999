import LoadingComponent from 'components/LoadingComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IBuildingCourse {
  course: any;
}

const BuildingCourse = (props: IBuildingCourse) => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{t('Building Course...', { courseName: props.course.name })}</h3>
      <LoadingComponent component={<></>} isLoading={true} />
    </div>
  );
};

export default BuildingCourse;
