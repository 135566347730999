import { CALL_API } from 'middleware/api';

export const handleGetPartners = (pageNumber: number, query: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/my/partners?include=image&filter[exclude_expired]=1&page[number]=${[
        pageNumber
      ]}&sort=name&query=${query}`,
      httpMethod: 'GET'
    }
  };
};
