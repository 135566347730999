import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrivateRoute } from 'components/PrivateRoute';
import { Color } from 'core';
import React from 'react';
import { RouteProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface IBreadcrumb extends RouteProps {
  text?: string;
  parentPage: string;
  match: { params: { courseId?: string; partnerSlug?: string; groupId?: string } };
}

export const BreadcrumbText = styled(Link)`
  color: ${Color.primaryBlue};
  margin-left: 2rem;
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
`;

type GetPathFromParentPageParams = {
  parentPage: string;
  courseId?: string;
  partnerSlug?: string;
  groupId?: string;
}

const getPathFromParentPage = ({ parentPage, courseId, partnerSlug, groupId }: GetPathFromParentPageParams): string => {
  let path: string;
  switch (parentPage) {
    case 'partner_admin':
      path = '/nav/partners/dashboard';
      break;
    case 'v4_partner_admin':
      path = '/nav/v4/admin';
      break;
    case 'v4_partner_groups':
      path = '/nav/v4/admin/groups';
      break;
    case 'v4_partner_group_details':
      path = `/nav/v4/admin/groups/${groupId}`;
      break;
    case 'partner_admin_oauth':
      path = `/nav/partners/${partnerSlug}/oauth`;
      break;
    case 'account_setup':
      path = `/nav/partners/${partnerSlug}/features`;
      break;
    case 'course_details':
      path = `/nav/courses/${courseId}`;
      break;
    case 'courses':
      path = '/nav/courses';
      break;
    default:
      throw new Error(`Cannot find path for parentPage ${parentPage}`);
  }
  return path;
};

export const getTextFromParentPage = (parentPage: string): string => {
  let text: string;
  switch (parentPage) {
    case 'partner_admin':
      text = 'Admin';
      break;
    case 'v4_partner_admin':
      text = 'Admin';
      break;
    case 'v4_partner_groups':
      text = 'Groups';
      break;
    case 'v4_partner_group_details':
      text = 'Group Details';
      break;
    case 'partner_admin_oauth':
      text = 'OAuth';
      break;
    case 'account_setup':
      text = 'Account Setup';
      break;
    case 'course_details':
      text = 'Course Details';
      break;
    case 'courses':
      text = 'Courses';
      break;
    default:
      throw new Error(`Cannot find text for parentPage ${parentPage}`);
  }
  return text;
};

const BreadcrumbMap = [
  {
    path: '/nav/partners/:partnerSlug/features',
    parentPage: 'partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/features',
  },
  {
    path: '/nav/partners/:partnerSlug/staff',
    parentPage: 'partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/staff',
  },
  {
    path: '/nav/partners/:partnerSlug/groups',
    parentPage: 'partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/groups',
  },
  {
    path: '/nav/partners/:partnerSlug/people',
    parentPage: 'partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/people',
  },
  {
    path: '/nav/partners/:partnerSlug/app_branding',
    parentPage: 'partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/app_branding',
  },
  {
    path: '/nav/partners/:partnerSlug/departments',
    parentPage: 'partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/departments',
  },
  {
    path: '/nav/partners/:partnerSlug/oauth',
    parentPage: 'partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/oauth',
  },
  {
    path: '/nav/partners/:partnerSlug/oauth/new',
    parentPage: 'partner_admin_oauth',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/oauth/new',
  },
  {
    path: '/nav/partners/:partnerSlug/oauth/:id/edit',
    parentPage: 'partner_admin_oauth',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/partners/.*/oauth/.*/edit',
  },
  {
    path: '/nav/courses/:courseId/sets/:setId/items',
    parentPage: 'course_details',
    permittedRoles: null,
    regex: '/nav/courses/.*/sets/.*/items',
  },
  {
    path: '/nav/courses/:courseId/sets/:setId',
    parentPage: 'course_details',
    permittedRoles: null,
    regex: '/nav/courses/.*/sets/.*$',
  },
  {
    path: '/nav/courses/:courseId',
    parentPage: 'courses',
    permittedRoles: null,
    regex: '/nav/courses/.*',
  },
  {
    path: '/nav/admin/billing',
    parentPage: 'account_setup',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/admin/billing',
  },
  {
    path: '/nav/v4/admin/people',
    parentPage: 'v4_partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/v4/admin/people',
  },
  {
    path: '/nav/v4/admin/config',
    parentPage: 'v4_partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/v4/admin/config',
  },
  {
    path: '/nav/v4/admin/groups',
    parentPage: 'v4_partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/v4/admin/groups',
  },
  {
    path: '/nav/v4/admin/groups/:groupId/members',
    parentPage: 'v4_partner_group_details',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/v4/admin/groups/.*/members',
  },
  {
    path: '/nav/v4/admin/groups/:groupId/courses',
    parentPage: 'v4_partner_group_details',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/v4/admin/groups/.*/courses',
  },
  {
    path: '/nav/v4/admin/groups/:groupId',
    parentPage: 'v4_partner_groups',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/v4/admin/groups/.*',
  },
  {
    path: '/nav/v4/admin/staff',
    parentPage: 'v4_partner_admin',
    permittedRoles: ['site admin', 'admin'],
    regex: '/nav/v4/admin/staff',
  },
];

interface IBreadcrumbRoutes {
  navComponent: any;
}

export const BreadcrumbRoutes = (props: IBreadcrumbRoutes) => {
  const routes = BreadcrumbMap.map((crumb) => {
    const siteAdminOnly = crumb.permittedRoles?.length === 1 && crumb.permittedRoles[0] === 'site admin';
    return (
      <PrivateRoute
        exact
        key={crumb.path}
        path={crumb.path}
        component={props.navComponent}
        parentPage={crumb.parentPage}
        permittedRoles={crumb.permittedRoles}
        siteAdminOnly={siteAdminOnly}
      />
    );
  });

  return <>{routes}</>;
};

export const pathHasBreadcrumb = (path: string): boolean => {
  return BreadcrumbMap.some((crumb) => path.match(crumb.regex) != null);
};

const Breadcrumb = (props: IBreadcrumb) => {
  return (
    <BreadcrumbText
      className="breadcrumb"
      to={getPathFromParentPage({
        parentPage: props.parentPage,
        courseId: props.match.params.courseId,
        partnerSlug: props.match.params.partnerSlug,
        groupId: props.match.params.groupId,
      })}
    >
      <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '0.5em' }} />
      {getTextFromParentPage(props.parentPage)}
    </BreadcrumbText>
  );
};

export default Breadcrumb;
