import { QuestionWithPolygon } from 'components/item-editor/regions/QuestionWithPolygon';
import { ASPECT_RATIO, ImageDimensions } from 'components/item-editor/regions/RegionsEditor';
import { useItemEditorStore } from 'components/item-editor/store';
import { useCreateFacet } from 'hooks/useCreateFacet';
import React, { useEffect, useRef, useState } from 'react';

export const Questions: React.FC = () => {
  const { activeItem: item, updateActiveItem, activeAssignment: assignment } = useItemEditorStore();
  const [highlightDimensions, setHighlightDimensions] = useState<ImageDimensions>({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [createFacet] = useCreateFacet(item?.id || '', assignment?.id || '');

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current && item?.image) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerWidth / ASPECT_RATIO;

        const img = new Image();
        img.onload = () => {
          let highlightWidth: number, highlightHeight: number;

          if (img.width / img.height > ASPECT_RATIO) {
            highlightWidth = containerWidth;
            highlightHeight = containerWidth * (img.height / img.width);
          } else {
            highlightHeight = containerHeight;
            highlightWidth = containerHeight * (img.width / img.height);
          }

          setHighlightDimensions({ width: highlightWidth, height: highlightHeight });
        };
        img.src = item.image.url;
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [item]);

  if (!item) {
    console.warn('Item not found');
    return null;
  }

  // Handler to create a new facet (question)
  const handleAddQuestion = async () => {
    const payload = {
      attributes: {
        'association-question-html': '',
        'quiz-settings': {
          enabled: ['region_reverse_multiple_choice', 'region_reverse_click_in_the_dark'],
        },
      },
      relationships: {
        association: {
          data: {
            id: 0,
            attributes: {
              text: '',
              'text-html': '',
              explanation: '',
              'explanation-html': '',
            },
            relationships: {
              image: {
                data: null,
              },
            },
          },
        },
        // anchor: {
        //   data: {
        //     attributes: {
        //       'region-polygon': '0 0, 1 0, 1 1, 0 1', // Default polygon covering the entire image
        //     },
        //   },
        // },
      },
    };

    // create on the server
    const newFacet = await createFacet({ itemId: item.id, payload });
    // update app state
    updateActiveItem({ facets: [...item.facets, newFacet] });
  };

  // TODO: support drawing region polygons by all three methods: draw, circle, rectangle

  return (
    <div ref={containerRef}>
      {item.facets.map((facet: any, index: number) => (
        <QuestionWithPolygon
          key={facet.id || index} // Preferably use a unique identifier
          question={facet.associationQuestionHtml}
          index={index}
          facet={facet}
          item={item}
          highlightDimensions={highlightDimensions}
          containerWidth={containerRef.current?.offsetWidth || 0}
        />
      ))}
      <button onClick={handleAddQuestion}>Ask another question about this image</button>
    </div>
  );
};
