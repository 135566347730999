import axios from 'axios';

export async function fetchWithCSRFToken(
  url: string,
  data: any = null,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET'
) {
  const csrf_token = Math.random().toString();
  const isLocalhost = window.location.hostname === 'localhost';
  const domain = isLocalhost ? 'localhost' : '.cerego.com';
  document.cookie = `cerego_client_generated_csrf_token=${csrf_token};domain=${domain};path=/`;
  return await axios.request({
    url,
    data,
    method,
    headers: {
      'x-csrf-token': csrf_token,
    },
  });
}

export async function postWithCSRFToken(url: string, data: any) {
  return fetchWithCSRFToken(url, data, 'POST');
}
