import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DownloadCSV } from 'components/DownloadCSV';
import { SortArrow } from 'components/SortArrow';
import TableFrame from 'components/TableFrame';
import { ItemDifficulty, formatDifficulty } from 'components/reports/cells/ItemDifficulty';
import { Pagination } from 'components/reports/controls/Pagination';
import { Color } from 'core';
import * as Size from 'core/size';
import { useContentInsights } from 'hooks/useContentInsights';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TAssignment } from 'types';
import { formatStringForCSV } from 'utils/formatStringForCSV';
import { getEditSetUrl } from 'utils/pathUtils';

interface AssignmentContentInsightsTableProps {
  courseId: string;
  assignment: TAssignment;
}

export const AssignmentContentInsightsTable = (props: AssignmentContentInsightsTableProps) => {
  const { courseId, assignment } = props;

  return <Data courseId={courseId} assignment={assignment} />;
};

interface DataProps {
  courseId: string;
  assignment: TAssignment;
}

const Data = (props: DataProps) => {
  const { courseId, assignment } = props;
  const userId = null;
  const [totalCount, setTotalCount] = useState(0);
  const [csvData, setCsvData] = useState<Array<any>>([]);
  const { t } = useTranslation();

  const { data: response } = useContentInsights(courseId, assignment.id, userId, 1, 200);

  const data = response?.data;
  const meta = response?.meta;

  const contentInsights = data ?? [];

  useEffect(() => {
    if (!!contentInsights) {
      setCsvData(
        contentInsights.map((d: any) => ({
          'Item Text': formatStringForCSV(d.anchor),
          'Item Type': formatItemType(d.template),
          Difficulty: d['times_seen'] === 0 ? 'N/A' : formatDifficulty(d['retention_modifier']),
          'Times Seen': d['times_seen'],
          'Times Correct': d.template === 'Ic' ? 'N/A' : d['times_correct'],
          'Percent Correct': formatPercentCorrect(d.template, d['percent_correct']),
          'Student Count': d['students_count'],
        }))
      );
    }
  }, [JSON.stringify(contentInsights)]);

  useEffect(() => {
    if (!!response) {
      setTotalCount(meta['total_count']);
    }
  }, [JSON.stringify(data)]);

  const renderHeader = (title: string) => {
    return (
      <TableHeaderContainer>
        <TableHeaderText>{title}</TableHeaderText>
      </TableHeaderContainer>
    );
  };

  const formatItemType = (itemType: string): string => {
    switch (itemType) {
      case 'Ic':
        return 'Instructional Content';
      case 'Q&A':
        return 'Question & Answer';
      case 'S':
        return 'Sequence';
      case 'R':
        return 'Diagram';
      case 'V':
        return 'Vocabulary';
      case 'A':
        return 'Association Collection';
      case 'Pt':
        return 'Pattern';
      case 'Ps':
        return 'Cloze Container';
      default:
        return itemType;
    }
  };

  const renderDifficultyCell = (row: any) => {
    const isIC = row.row.original['template'] === 'Ic';
    const neverSeen = row.row.original['times_seen'] === 0;

    return <ItemDifficulty na={isIC || neverSeen} retentionModifier={row.row.original['retention_modifier']} />;
  };

  const columns: ColumnDef<any>[] = [
    {
      id: 'itemText',
      accessorFn: (row) => row.anchor,
      header: () => renderHeader('Item Text'),
    },
    {
      id: 'itemType',
      accessorFn: (row) => formatItemType(row.template),
      header: () => renderHeader('Item Type'),
    },
    {
      id: 'difficulty',
      accessorFn: (row) => row['retention_modifier'],
      cell: (row) => renderDifficultyCell(row),
      header: () => renderHeader('Difficulty'),
    },
    {
      id: 'timesSeen',
      accessorFn: (row) => row['times_seen'],
      header: () => renderHeader('Times Seen'),
    },
    {
      id: 'timesCorrect',
      accessorFn: (row) => (row.template === 'Ic' ? 'N/A' : row['times_correct']),
      header: () => renderHeader('Times Correct'),
    },
    {
      id: 'percentCorrect',
      accessorFn: (row) => (row.template === 'Ic' ? 'N/A' : Math.floor(row['percent_correct']).toString().concat('%')),
      header: () => renderHeader('Percent Correct'),
    },
    {
      id: 'studentCount',
      accessorFn: (row) => row['students_count'],
      header: () => renderHeader('Student Count'),
    },
  ];

  const table = useReactTable({
    data: contentInsights,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const goToPageOne = (): void => {
    if (table.getCanPreviousPage()) {
      table.setPageIndex(0);
    }
  };

  const goToPreviousPage = (): void => {
    if (table.getCanPreviousPage()) {
      table.previousPage();
    }
  };

  const goToNextPage = (): void => {
    if (table.getCanNextPage()) {
      table.nextPage();
    }
  };

  const goToLastPage = (): void => {
    if (table.getCanNextPage()) {
      table.setPageIndex(table.getPageCount() - 1);
    }
  };

  const rightAlignedColumns = ['timesSeen', 'timesCorrect', 'percentCorrect', 'studentCount'];

  const handleRowClick = (row: any): void => {
    if (!window.top) {
      console.warn("Can't handle row click, window.top is null");
      return;
    }

    window.top.location.href = getEditSetUrl(assignment.learnVersion, assignment.id, row.original['item_id']);
  };

  return (
    <TableFrame
      mainComponent={
        <div style={{ maxWidth: Size.reportPageWidth, marginLeft: 'auto', marginRight: 'auto' }}>
          <DataContainer>
            <DataTable>
              <thead style={{ backgroundColor: Color.grayTransparent }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan} style={{ padding: '2px 20px' }}>
                        {header.isPlaceholder ? null : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: rightAlignedColumns.includes(header.column.id) ? 'right' : 'left',
                            }}
                            {...{ onClick: header.column.getToggleSortingHandler() }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getIsSorted() === 'asc' && <SortArrow icon={faArrowUp} />}
                            {header.column.getIsSorted() === 'desc' && <SortArrow icon={faArrowDown} />}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    style={{
                      borderTop: '1px solid rgb(224, 224, 224, 1)',
                      borderBottom: '1px solid rgb(224, 224, 224, 1)',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          padding: '10px 20px',
                          textAlign: rightAlignedColumns.includes(cell.column.id) ? 'right' : 'left',
                          color: Color.reportGray,
                          fontSize: '14px',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </DataTable>

            <Pagination
              totalCount={totalCount}
              page={table.getState().pagination.pageIndex + 1}
              pageSize={table.getState().pagination.pageSize}
              goToPageOne={goToPageOne}
              goToPreviousPage={goToPreviousPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </DataContainer>
        </div>
      }
      segmentNav={
        <span style={{ marginRight: '20px' }}>
          <DownloadCSV data={csvData || []} filename="content-insights.csv">
            Download
          </DownloadCSV>
        </span>
      }
      title={t('Content Insights')}
    />
  );
};

const formatPercentCorrect = (template: string, percent: number): string => {
  if (template === 'Ic') {
    return 'N/A';
  }

  if (percent < 100 && percent > 99) {
    return '99%';
  }

  return Math.ceil(percent).toString().concat('%');
};

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.54);
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  background-color: ${Color.white};
  border: 1px solid ${Color.lightGray};
  border-radius: 5px;
  padding-bottom: 20px;
`;

const DataTable = styled.table`
  border-collapse: collapse;
  border: 1px solid ${Color.grayTransparent};
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  color: ${Color.primaryBlack};
  font-size: 1em;
  font-weight: 400;
`;
