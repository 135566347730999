import axios from 'axios';

export default async function setInstructionalItemsComplete({
  id,
  duration,
  sessionGuid,
}: {
  id: string;
  duration: number;
  sessionGuid: string;
}) {
  await axios
    .post('/api/v3/my/instructional_presentations', {
      item_id: id,
      occurred_at: new Date().toISOString(),
      study_time_millis: duration,
      session_guid: sessionGuid,
    })
    .then((response) => {
      return response.data;
    });
}
