import { Color } from 'core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IProps {
  endFocusRef: any;
}

const SkipContentButtonObj = styled.button`
  width: 1px;
  height: 1px;
  overflow: hidden;
  background: transparent;
  color: transparent;
  border: none;
  font-size: 12px;
  font-family: Lato, sans-serif;
  text-decoration: underline;
  text-decoration-color: transparent;
  padding: 0;
  margin-left: 10px;
  align-self: center;

  &:focus {
    width: auto;
    height: auto;
    color: ${Color.textGray};
    text-decoration-color: ${Color.textGray};
  }
`;

export const SkipContentButton = React.memo<IProps>(props => {
  const { t } = useTranslation();

  const handleClick = (e: any) => {
    e.currentTarget.blur();
    props.endFocusRef.current.focus();
  };
  return <SkipContentButtonObj onClick={handleClick}>{t('Skip content')}</SkipContentButtonObj>;
});
