import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDepartmentsStore } from 'pages/admin-dashboard/departments/store';
import { useQuery } from 'react-query';

interface DepartmentsResponse {
  data: any[];
  meta: {
    'total-count': number;
    'total-pages': number;
  };
}

const getDepartments = async (
  _key: string,
  partnerId: string,
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: 'asc' | 'desc',
  name: string
): Promise<DepartmentsResponse> => {
  const endpoint = `/api/v3/partners/${partnerId}/departments`;
  const sortParam = sortDirection === 'desc' ? `-${sortColumn}` : sortColumn;
  let queryParams = `include=image&page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sortParam}&query=${name}`;
  const response = await axios.get(`${endpoint}?${queryParams}`);
  return response.data;
};

export const useDepartments = () => {
  const user = useCurrentUser();
  const partnerId = user?.primaryPartner.id;
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useDepartmentsStore();

  return useQuery(['departments', partnerId, pageNumber, pageSize, sortColumn, sortDirection, name], () =>
    getDepartments('departments', partnerId, pageNumber, pageSize, sortColumn, sortDirection, name)
  );
};
