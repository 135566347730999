import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageView, { defaultImageMap, imageShapeMap, ImageViewStyles, TranscodingSize } from 'components/ImageView';
import { Color } from 'core';
import { IImage } from 'models/image';
import React from 'react';
import styled from 'styled-components';

export const ImageEditOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

interface IImageBadge {
  image?: IImage | null;
  transcodedSize?: TranscodingSize;
  entity: string;
  style?: any;
  imageContainerStyle?: any;
  onEdit?: () => void;
  children?: React.ReactNode;
}

const ImageBadge = (props: IImageBadge) => {
  const transcodedSize: TranscodingSize = props.transcodedSize || 'medium';

  // TODO: Standardize image treatment across assignment types
  const scormStyle = {
    background: 'none',
    boxShadow: 'none',
  };
  const style = Object.assign(
    { height: '40px', width: '40px', background: `${Color.sideNavDark}`, objectFit: 'cover' },
    ImageViewStyles[imageShapeMap[props.entity]],
    props.entity === 'scorm' ? scormStyle : {},
    props.style
  );

  const imageContainerStyle = Object.assign({ position: 'relative' }, props.imageContainerStyle);

  return (
    <div style={imageContainerStyle}>
      <ImageView
        image={props.image}
        transcodedSize={transcodedSize}
        fallbackImageUrl={defaultImageMap[props.entity]}
        style={style}
      />
      {props.onEdit && (
        <ImageEditOverlay
          onClick={props.onEdit}
          style={{
            ...ImageViewStyles[imageShapeMap[props.entity]],
          }}
        >
          <FontAwesomeIcon icon={faEdit} style={{ color: 'white', fontSize: '22px' }} />
        </ImageEditOverlay>
      )}
      {props.children}
    </div>
  );
};

export default ImageBadge;
