export function stringSort(fieldName: string, direction: string) {
  return (a: object, b: object) => {
    const aString: string = String(a[fieldName] || '');
    const bString: string = String(b[fieldName] || '');

    switch (direction) {
      case 'asc':
        return aString.localeCompare(bString);
      default:
        return bString.localeCompare(aString);
    }
  };
}
