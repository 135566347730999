import { Color } from 'core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IProps {}

const SkipToMainButtonObj = styled.button`
  position: absolute;
  left: auto;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  background: transparent;
  color: transparent;
  border: none;
  font-size: 14px;
  font-family: Lato, sans-serif;
  padding: 0;
  elevation: 100;
  z-index: 1000;

  &:focus {
    width: auto;
    height: auto;
    background: ${Color.primaryGreen};
    color: ${Color.white};
    padding: 5px 10px;
  }
`;

export const SkipToMainButton = React.memo<IProps>(props => {
  const { t } = useTranslation();

  const handleClick = (e: any) => {
    e.currentTarget.blur();
    window.parent.postMessage('skipToMain', '*');
  };
  return <SkipToMainButtonObj onClick={handleClick}>{t('Skip to main content')}</SkipToMainButtonObj>;
});
