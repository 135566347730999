import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as colours from 'core/colors';

type Props = {
  text: string;
};

export default function MemreTrianglePointer({ text }: Props) {
  const TriangleUp = styled(Box)(() => ({
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `5px solid ${colours.veryLightGray}`,
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        pb: '8px',
        ml: '-2px',
      }}
    >
      <TriangleUp />
      <Typography variant="caption" sx={{ position: 'absolute', top: '4px' }}>
        {text}
      </Typography>
    </Box>
  );
}
