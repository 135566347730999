import { faArrowDown, faArrowUp, faBook, faChalkboard, faChartPie, faShieldAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { Avatar, Box, Button, Chip, MenuItem, Select, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Pagination } from 'components/reports/controls/Pagination';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import { Color } from 'core';
import { useDeletePartnerUserRole } from 'hooks/useDeletePartnerUserRole';
import { useMutatePartnerUserRole } from 'hooks/useMutatePartnerUserRole';
import { usePartnerUserRoles } from 'hooks/usePartnerUserRoles';
import { StaffTableToolbar } from 'pages/admin-dashboard/staff/StaffTableToolbar';
import { useStaffStore } from 'pages/admin-dashboard/staff/store';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatPartnerUserRole } from 'utils/formatPartnerUserRole';

export const StaffTable = () => {
  const { pageNumber, setPageNumber, pageSize, setPageSize, sortDirection, setSortDirection } = useStaffStore();
  const { data: staff } = usePartnerUserRoles();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [deletePartnerUserRole] = useDeletePartnerUserRole();

  useEffect(() => {
    if (staff) {
      setTotalPages(Math.ceil(staff.length / pageSize));
      setTotalCount(staff.length);
    }
  }, [staff, pageSize]);

  // Pagination handlers
  const goToPageOne = () => setPageNumber(1);
  const goToNextPage = () => pageNumber < totalPages && setPageNumber(pageNumber + 1);
  const goToPreviousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
  const goToLastPage = () => setPageNumber(totalPages);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1);
  };

  // Sorting handler
  const handleSortingChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setPageNumber(1);
  };

  // Table columns
  const columns: ColumnDef<any>[] = [
    {
      id: 'name',
      accessorFn: (row) => row.original.user.name,
      cell: ({ row }) => <p>{row.original.user.name}</p>,
      header: () => (
        <ColumnHeader title="Name" sort="name" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'email',
      accessorFn: (row) => row.original.user.email,
      cell: ({ row }) => <p>{row.original.user.email}</p>,
      header: () => <ColumnHeader title="Email" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
    {
      id: 'role',
      accessorFn: (row) => row.original.role,
      cell: RoleCell,
      header: () => <ColumnHeader title="Role" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
    {
      id: 'actions',
      cell: ActionsCell,
      header: () => <ColumnHeader title="Actions" sort="" sortDirection={sortDirection} onSort={() => { }} />,
    },
  ];

  const table = useReactTable({
    data: staff || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (!['name'].includes(header)) {
      console.warn('Cannot sort by anything other than name');
      return;
    }

    handleSortingChange();
  };

  return (
    <>
      <TableContainer>
        <StaffTableToolbar />
        <StyledTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} />
        </StyledTable>
      </TableContainer>

      {/* Pagination Component */}
      <Pagination
        totalCount={totalCount}
        page={pageNumber}
        pageSize={pageSize}
        goToPageOne={goToPageOne}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );

  // Cell component for Actions column
  function ActionsCell({ row }) {
    const handleDelete = async () => {
      if (window.confirm('Are you sure you want to remove this staff member?')) {
        try {
          await deletePartnerUserRole({
            partnerUserRoleId: row.original.id,
          });
        } catch (error) {
          console.error('Error deleting staff member:', error);
        }
      }
    };

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
        <Tooltip title="Remove Staff">
          <IconButton onClick={handleDelete} size="small" style={{ padding: '0.5rem', color: Color.veryDarkOrange }}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
};

interface ColumnHeaderProps {
  title: string;
  sort: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
}

const ColumnHeader = ({ title, sort, sortDirection, onSort }: ColumnHeaderProps) => (
  <TableHeaderContainer
    style={Object.assign({ justifyContent: title === "Actions" ? 'center' : 'left' }, sort === '' ? {} : { cursor: 'pointer' })}
    onClick={onSort}
  >
    <TableHeaderText>{title}</TableHeaderText>
    {sort === 'name' && <SortArrow icon={sortDirection === 'desc' ? faArrowDown : faArrowUp} />}
  </TableHeaderContainer>
);

const TableContainer = styled.div`
  margin-top: 20px;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const roleOptions = [
  { value: 'admin', label: 'Admin', icon: faShieldAlt },
  { value: 'course_manager', label: 'Course Manager', icon: faChalkboard },
  { value: 'content_manager', label: 'Content Manager', icon: faBook },
  { value: 'course_viewer', label: 'Course Viewer', icon: faChartPie },
];

function RoleCell({ row }) {
  console.log("RoleCell", row);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRole, setSelectedRole] = useState(row?.original.role);
  const [previousRole, setPreviousRole] = useState(row?.original.role);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [mutatePartnerUserRole] = useMutatePartnerUserRole();

  const formattedRole = formatPartnerUserRole(selectedRole);

  const roleOption = roleOptions.find((option) => option.value === selectedRole);
  const avatar = (
    <Avatar>
      <FontAwesomeIcon icon={roleOption?.icon || faShieldAlt} />
    </Avatar>
  )

  const handleChipClick = () => {
    setIsEditing(true);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await mutatePartnerUserRole({
        partnerUserRoleId: row.original.id,
        payload: { role: selectedRole },
      });
      setPreviousRole(selectedRole);
      setIsEditing(false);
      setError(null);
    } catch (err) {
      console.error('Error updating role:', err);
      setError('Failed to update role.');
      // Revert to previous role on error
      setSelectedRole(previousRole);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setSelectedRole(previousRole);
    setIsEditing(false);
    setError(null);
  };

  return (
    <Box>
      {isEditing ? (
        <Box display="flex" alignItems="center" gap={1}>
          <Select
            value={selectedRole}
            onChange={handleRoleChange}
            variant="outlined"
            size="small"
            style={{ minWidth: 150 }}
          >
            {roleOptions.map((roleOption) => (
              <MenuItem key={roleOption.value} value={roleOption.value}>
                {roleOption.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            onClick={handleSave}
            disabled={isSaving}
            size="small"
            color="secondary"
            variant="contained"
          >
            Save
          </Button>
          <Button
            onClick={handleCancel}
            disabled={isSaving}
            size="small"
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
        </Box>
      ) : (
        <Chip
          label={formattedRole}
          avatar={avatar}
          size="small"
          color={formattedRole === 'Admin' ? 'secondary' : 'primary'}
          variant="outlined"
          onClick={handleChipClick}
          style={{ cursor: 'pointer' }}
        />
      )}
    </Box>
  );
}
