import { devlog, immer } from 'zstore';
import create from 'zustand';

export type CourseAssignmentsTableState = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
};

const makeCourseAssignmentsTableStore = () => {
  return create<CourseAssignmentsTableState>(
    devlog(
      immer((set: any) => ({
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => set(() => ({ pageNumber })),
        pageSize: 10,
        setPageSize: (pageSize: number) => set(() => ({ pageSize })),
      }))
    )
  );
};

export const useCourseAssignmentsTableStore = makeCourseAssignmentsTableStore();
