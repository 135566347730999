import { FormControlLabel, Switch } from '@mui/material';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import { useState } from 'react';

export const LearnerExperience = () => {
  const user = useCurrentUser();
  const [isSoundEnabled, setIsSoundEnabled] = useState(user?.primaryPartner.userOverrides.disableEffectAudio || false);
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);

  const handleSoundEnabledToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSoundEnabled(e.target.checked);
    debouncedPartnerUpdate({
      user_overrides: {
        disable_effect_audio: e.target.checked,
      },
    });
  };

  return (
    <ConfigItem title="Learner Experience">
      <FormControlLabel
        control={<Switch checked={isSoundEnabled} onChange={handleSoundEnabledToggle} color="secondary" />}
        label="Enable Sounds in Learner Experience"
      />
    </ConfigItem>
  );
};
