import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { useStaffStore } from 'pages/admin-dashboard/staff/store';

const getPartnerUserRoles = async (
  _key: string,
  partnerId: string,
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
  query: string
) => {
  const endpoint: string = `/api/v3/partners/${partnerId}/partner_user_roles`;
  const sortParam = sortDirection === 'desc' ? `-${sortColumn}` : sortColumn;
  const queryParams = `include=user&page[size]=${pageSize}&page[number]=${pageNumber}&query=${query}&sort=${sortParam}&tab=default`;
  const response = await axios.get(`${endpoint}?${queryParams}`);

  return normalizeJSONAPIResponse(response.data);
};

export const usePartnerUserRoles = () => {
  const user = useCurrentUser();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useStaffStore();

  return useQuery(
    ['partnerUserRoles', user?.primaryPartner.id, pageNumber, pageSize, sortColumn, sortDirection, name],
    getPartnerUserRoles,
    {
      enabled: user?.primaryPartner.id,
    }
  );
};
