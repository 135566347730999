import Input from '@material-ui/core/Input';
import React from 'react';

const baseContainerStyle = {};
const baseLabelStyle = {
  paddingRight: '10px'
};
const baseInputStyle = {};

interface ILevelGoalInput {
  level: any;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  containerStyle?: any;
  labelStyle?: any;
  inputStyle?: any;
}

const LevelGoalInput = (props: ILevelGoalInput) => {
  const containerStyle = Object.assign({}, baseContainerStyle, props.containerStyle);
  const labelStyle = Object.assign({}, baseLabelStyle, props.labelStyle);
  const inputStyle = Object.assign({}, baseInputStyle, props.inputStyle);

  return (
    <div style={containerStyle}>
      <span style={labelStyle}>Level</span>
      <Input
        value={props.level}
        onChange={props.handleInputChange}
        inputProps={{
          step: 0.1,
          min: 0.0,
          max: 3.0,
          type: 'number'
        }}
        autoFocus={true}
        style={inputStyle}
        disableUnderline={true}
      />
    </div>
  );
};

export default LevelGoalInput;
