import { PrimaryButton } from 'components/buttons/v4';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryParams } from 'utils/pathUtils';
import styled from 'styled-components';
import { BrightInput } from 'components/forms/BrightInput';
import { Card, CardHeader, CardHeaderContainer } from 'core/layout';

interface ILtiUserConfirmPageProps {
  match: { params: { courseId: string } };
}

const LtiConfirmFrame = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
`;

const DebugInfo = styled.div`
  margin-top: 20px;
  font-size: 0.8em;
  color: #ccc;
`;

export const LtiUserConfirmPage = React.memo<ILtiUserConfirmPageProps>((props) => {
  const emailAddress = decodeURIComponent(queryParams(window.location).email_address ?? '');
  const clientId = decodeURIComponent(queryParams(window.location).client_id ?? '');
  const externalUserId = decodeURIComponent(queryParams(window.location).external_user_id ?? '');
  const { t } = useTranslation();

  const listenForEnterKey = (event) => {
    if (event.key === 'Enter') {
      confirmLtiUser();
    }
  };

  function confirmLtiUser() {
    document.forms['ltiUserConfirmationForm'].submit();
  }

  return (
    <LtiConfirmFrame>
      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('Confirm a new user')}</CardHeader>
        </CardHeaderContainer>
        <div>
          {t('Lti User Confirm Message', { emailAddress: emailAddress })}
          <form name="ltiUserConfirmationForm" method="post" action="/lti/v13/redirect">
            <BrightInput
              placeholder={'123456'}
              autoFocus={true}
              onKeyPress={listenForEnterKey}
              required={true}
              name={'lti_confirmation_token'}
            />
            <input type="hidden" name="client_id" value={clientId}></input>
            <input type="hidden" name="external_user_id" value={externalUserId}></input>
            <PrimaryButton click={() => confirmLtiUser()}>{t('Continue')}</PrimaryButton>
          </form>
        </div>
      </Card>
      <DebugInfo>
        Troubleshooting Info: Email: {emailAddress || ''} | Client: {clientId ?? ''} | Id: {externalUserId ?? ''}
      </DebugInfo>
    </LtiConfirmFrame>
  );
});
