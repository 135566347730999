import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { Color } from 'core';
import { useTranslation } from 'react-i18next';

interface IProps {
  text: string;
  canEdit?: boolean;
  enableEdit?: () => void;
}

const Wrapper = styled.div`
  border: 2px solid transparent;
`;

const EditButton = styled.button`
  background: transparent;
  border: transparent;
  padding: 0px;
  margin: 0px 0px 0px 0.5em;
`;

const InlineIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  color: ${Color.lightGray};
  cursor: pointer;
`;

export const Text = (props: IProps) => {
  const canEdit = props.canEdit ?? true;
  const { t } = useTranslation();

  const editIconClick = () => {
    if (props.enableEdit) {
      props.enableEdit();
    }
  };

  return (
    <Wrapper>
      <div style={{ display: 'inline' }}>{props.text}</div>
      {canEdit && (
        <EditButton onClick={editIconClick} aria-label={t('Edit')}>
          <InlineIcon icon={faEdit} />
        </EditButton>
      )}
    </Wrapper>
  );
};
