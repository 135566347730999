import { performActionWithConfirmation } from '../siteAdminUtils';
import axios from 'axios';

export function SiteAdminUserDetailEmails(props: any) {
  const { user } = props;

  const confirmEmail = async (emailId) => {
    await performActionWithConfirmation('Are you sure you wish to confirm this email?', async () => {
      await axios.put(`/api/v3/admin/user_emails/${emailId}`, { confirmed_at: new Date().toISOString() });
      window.alert('Email confirmed');
      window.location.reload();
    });
  };

  const setPrimaryEmail = async (emailId) => {
    await performActionWithConfirmation('Are you sure you wish to set this email as primary?', async () => {
      await axios.put(`/api/v3/admin/user_emails/${emailId}/set_primary`);
      window.alert('Primary email updated');
      window.location.reload();
    });
  };
  return (
    <article>
      <table style={{ textAlign: 'left' }}>
        <thead>
          <tr>
            <th>Address</th>
            <th>Is Confirmed</th>
            <th>Is Primary</th>
            <th>Confirm</th>
            <th>Set as Primary</th>
          </tr>
        </thead>
        <tbody>
          {user.emails.map((email) => (
            <tr key={email.id}>
              <td>{email.address}</td>
              <td>{email.confirmed ? 'Yes' : 'No'}</td>
              <td>{email.primary ? 'Yes' : 'No'}</td>
              <td>{!email.confirmed && <button onClick={() => confirmEmail(email.id)}>Confirm</button>}</td>
              <td>{!email.primary && <button onClick={() => setPrimaryEmail(email.id)}>Set as Primary</button>}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </article>
  );
}
