import { MemreFlexBox, MemreButton, MemreText } from 'components/core';
import * as Color from 'core/colors';

export default function MemreHeroSection({ learningJourney }) {
  const [firstItem, ...restOfItems] = learningJourney;

  return (
    <MemreFlexBox
      direction="column"
      gap={1}
      sx={{
        p: 3,
        background: 'linear-gradient(328deg, rgba(9,5,65,1) 0%, rgba(9,9,121,1) 41%, rgba(82,23,172,1) 100%)',
        borderRadius: '8px',
      }}
    >
      <MemreText
        sx={{
          color: Color.white,
          py: 0.5,
          px: 1.5,
          fontSize: '0.65em',
          fontWeight: 700,
          letterSpacing: '0.15em',
          width: 'fit-content',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            padding: '1px',
            opacity: 0.7,
            background: 'linear-gradient(-45deg, #93D5E6 30%, #D2C206)',
            borderRadius: 'inherit',
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
          },
        }}
      >
        RECOMMENDED
      </MemreText>
      <MemreFlexBox direction="row" align="center" justify="space-between" gap={2}>
        <MemreFlexBox direction="column" sx={{ flex: 1 }} gap={1}>
          <MemreText sx={{ color: Color.white, fontSize: '1.25em', fontWeight: 900, lineHeight: 1.5 }}>
            {firstItem.name}
          </MemreText>
        </MemreFlexBox>
        <MemreButton
          color="primary"
          size="large"
          text={firstItem.cta}
          iconName="arrowFromBracket"
          href={firstItem.url}
          sx={{
            color: Color.white,
            background: 'linear-gradient(165deg, rgba(0,161,161,1) 0%, rgba(0,161,71,1) 25%, rgba(90,161,0,1) 100%)',
            px: 4,
            py: 2,
            fontSize: '1.05em',
          }}
        />
      </MemreFlexBox>
      {restOfItems.length > 0 && (
        <MemreText
          sx={{
            color: Color.white,
            mt: 3,
            mb: 0.8,
            py: 0.5,
            px: 1.5,
            fontSize: '0.65em',
            fontWeight: 700,
            letterSpacing: '0.15em',
            width: 'fit-content',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              inset: 0,
              padding: '1px',
              opacity: 0.7,
              background: 'linear-gradient(-45deg, #93D5E6 30%, #D2C206)',
              borderRadius: 'inherit',
              mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              maskComposite: 'exclude',
            },
          }}
        >
          UP NEXT
        </MemreText>
      )}
      {restOfItems.length > 0 && (
        <MemreFlexBox direction="column" gap={0.5}>
          {restOfItems.map((item) => (
            <MemreText sx={{ color: Color.white, fontSize: '0.9em', lineHeight: 1.4, opacity: 0.8 }}>
              • {item.name}{' '}
              <MemreButton
                variant="text"
                text={`${item.cta} →`}
                href={item.url}
                sx={{
                  color: 'white',
                  background: 'rgba(255,255,255,0.1)',
                  py: 0.1,
                  px: 1,
                  ml: 0.5,
                  fontSize: '0.8em',
                  letterSpacing: '0.05em',
                  textDecoration: 'underline',
                  '&:hover': { background: 'rgba(255,255,255,0.05)' },
                }}
              />
            </MemreText>
          ))}
        </MemreFlexBox>
      )}
    </MemreFlexBox>
  );
}
