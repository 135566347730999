import { Size } from 'core';
import { IQuizPart } from 'models/quiz';
import React, { FC } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

interface Props {
  renderSequenceItems: (isDraggable: boolean, items: IQuizPart[]) => JSX.Element[];
  items: IQuizPart[];
  setItems: (items: IQuizPart[]) => void;
  setShowCorrectOrder: (showCorrectOrder: boolean) => void;
  displayItems: IQuizPart[];
}

export const UnsubmittedSequenceBody: FC<Props> = ({
  renderSequenceItems,
  items,
  setItems,
  setShowCorrectOrder,
  displayItems,
}) => {
  const handleDragEnd = (result: any) => {
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination ? result.destination.index : result.source.index, 0, removed);
    setItems(newItems);
    setShowCorrectOrder(false);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="main">
        {(provided: any) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {renderSequenceItems(true, displayItems)}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const List = styled.ul`
  margin: 0px auto;
  max-width: ${Size.defaultCardWidth}px;
  position: relative;
  padding: 0px;
`;
