import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCoursesPageStore } from 'components/courses/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TableSearchBar } from 'components/tables/TableSearchBar';

export const CoursesTableToolbar = () => {
  const { setPageNumber, name, setName } = useCoursesPageStore();
  const { t } = useTranslation();

  return (
    <ToolbarContainer>
      <TableSearchBar
        value={name}
        onChange={setName}
        resetPage={() => setPageNumber(1)}
        placeholder={t('Search by name')}
        style={{ width: '100%' }}
      />
      <StatusFilter />
    </ToolbarContainer>
  );
};

const StatusFilter = () => {
  const { statusFilter, setStatusFilter } = useCoursesPageStore();

  const statusFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'published', label: 'Published' },
    { value: 'unpublished', label: 'Unpublished' },
    { value: 'archived', label: 'Archived' },
    { value: 'shared', label: 'With Shared Assignments' },
  ];

  const [value, setValue] = useState(statusFilterOptions.find((option) => option.value === statusFilter) || null);
  const [inputValue, setInputValue] = useState(
    statusFilterOptions.find((option) => option.value === statusFilter)?.label || ''
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const selectedOption: any = statusFilterOptions.find((option) => option.value === statusFilter);
    setValue(selectedOption);
    setInputValue(selectedOption ? selectedOption.label : '');
  }, [statusFilter]);

  return (
    <Autocomplete
      id="status-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={(_event, newValue: any) => {
        if (newValue) {
          setValue(newValue);
          setStatusFilter(newValue.value);
        } else {
          setValue(null);
          setStatusFilter('all');
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={statusFilterOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      style={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Status Filter" variant="outlined" />}
    />
  );
};

const ToolbarContainer = styled.div`
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
  grid-gap: 0em 1em;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;
