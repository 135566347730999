import * as _ from 'lodash';
import { CALL_API } from 'middleware/api';

export interface ISessionData {
  temp_login_token: string;
}

export const handleCreateSession = (sessionData: ISessionData) => {
  return {
    [CALL_API]: {
      endpoint: '/api/v3/sessions',
      httpMethod: 'POST',
      data: _.merge(sessionData, { include: 'oauth2-access-token' })
    }
  };
};

export const handleDestroySession = () => {
  return {
    [CALL_API]: {
      endpoint: '/api/v3/sessions',
      httpMethod: 'DELETE'
    }
  };
};
