import _ from 'lodash';
import { CALL_API } from 'middleware/api';

export interface IUserEmail {
  id: string;
  address: string;
  confirmed: boolean;
  primary: boolean;
}

export const handleMakePrimaryEmail = userEmailId => {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/my/emails/${userEmailId}/make_primary`,
      httpMethod: 'PUT',
      data: { id: userEmailId }
    }
  };
};

export const handleResendEmailConfirmation = userEmailId => {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/my/email_confirmations`,
      httpMethod: 'POST',
      data: { email_id: userEmailId }
    }
  };
};

export const handleAddUserEmail = emailAddress => {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/my/emails`,
      httpMethod: 'POST',
      data: { email_address: emailAddress }
    }
  };
};

export const handleDeleteUserEmail = userEmailId => {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/my/emails/${userEmailId}`,
      httpMethod: 'DELETE'
    }
  };
};

export const REMOVE_USER_EMAIL = Symbol('Remove User Email');

export const removeUserEmail = (state, action) => {
  const { userId, userEmailId } = action;

  const users = _.cloneDeep(state.users);
  _.remove(users[userId].relationships.emails.data, (userEmail: any) => userEmail.id === userEmailId);

  return {
    ...state,
    users
  };
};
