import { useState } from 'react';
import { performActionWithConfirmation } from '../siteAdminUtils';
import axios from 'axios';

export function SiteAdminUserDetailLtiUsers(props: any) {
  const { user } = props;
  const [showLtiUsers, setShowLtiUsers] = useState(false);

  const confirmLtiUser = async (ltiUserId, email) => {
    await performActionWithConfirmation(
      `Are you sure you wish to permanently confirm email ${email} into this user? This action may merge another user into this, if the email is already registered`,
      async () => {
        await axios.post('/api/v2/admin/lti/confirm_lti_user', {
          lti_user_id: ltiUserId,
          email,
        });
        window.alert('Lti User has been confirmed, please refresh');
      }
    );
  };

  const destroyLtiUser = async (ltiUserId) => {
    await performActionWithConfirmation(`Are you sure you wish to delete this user's lti_user?`, async () => {
      await axios.delete(`/api/v2/admin/lti/destroy_lti_user?lti_user_id=${ltiUserId}`);
      window.alert('Lti User has been deleted, please refresh');
    });
  };

  return (
    <>
      <h2>
        <a
          href="#"
          className="alt-button"
          onClick={(e) => {
            e.preventDefault();
            setShowLtiUsers(!showLtiUsers);
          }}
        >
          <span>
            {showLtiUsers ? '-' : '+'} Lti Users ({user.ltiUsers.length})
          </span>
        </a>
      </h2>

      {showLtiUsers && (
        <article>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Oauth Client Name</th>
                  <th>External id</th>
                  <th>Email address</th>
                  <th>Confirm</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {user.ltiUsers.map((ltiUser) => (
                  <tr key={ltiUser.id}>
                    <td>{ltiUser.id}</td>
                    <td>{ltiUser.oauth2Client.name}</td>
                    <td>{ltiUser.externalId}</td>
                    <td>{ltiUser.emailAddress}</td>
                    <td>
                      {ltiUser.requiresConfirmation && (
                        <button onClick={() => confirmLtiUser(ltiUser.id, ltiUser.emailAddress)}>Confirm</button>
                      )}
                    </td>
                    <td>
                      <button onClick={() => destroyLtiUser(ltiUser.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      )}
    </>
  );
}
