import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

const icons = {
  arrowFromBracket: () =>
    import('@fortawesome/free-solid-svg-icons/faArrowRightFromBracket').then((mod) => mod.faArrowRightFromBracket),
  building: () => import('@fortawesome/free-solid-svg-icons/faBuilding').then((mod) => mod.faBuilding),
  user: () => import('@fortawesome/free-solid-svg-icons/faUsers').then((mod) => mod.faUsers),
  trash: () => import('@fortawesome/free-solid-svg-icons/faTrash').then((mod) => mod.faTrash),
  check: () => import('@fortawesome/free-solid-svg-icons/faCircleCheck').then((mod) => mod.faCircleCheck),
  document: () => import('@fortawesome/free-solid-svg-icons/faFileLines').then((mod) => mod.faFileLines),
  video: () => import('@fortawesome/free-solid-svg-icons/faVideo').then((mod) => mod.faVideo),
  text: () => import('@fortawesome/free-solid-svg-icons/faFileLines').then((mod) => mod.faFileLines),
  image: () => import('@fortawesome/free-solid-svg-icons/faImage').then((mod) => mod.faImage),
  sound: () => import('@fortawesome/free-solid-svg-icons/faHeadphones').then((mod) => mod.faHeadphones),
  rotate: () => import('@fortawesome/free-solid-svg-icons/faRotate').then((mod) => mod.faRotate),
  back: () => import('@fortawesome/free-solid-svg-icons/faChevronLeft').then((mod) => mod.faChevronLeft),
  embed: () => import('@fortawesome/free-solid-svg-icons/faCode').then((mod) => mod.faCode),
  close: () => import('@fortawesome/free-solid-svg-icons/faTimes').then((mod) => mod.faTimes),
  key: () => import('@fortawesome/free-solid-svg-icons/faKey').then((mod) => mod.faKey),
  plus: () => import('@fortawesome/free-solid-svg-icons/faPlus').then((mod) => mod.faPlus),
  mail: () => import('@fortawesome/free-solid-svg-icons/faEnvelope').then((mod) => mod.faEnvelope),
  paperPlane: () => import('@fortawesome/free-solid-svg-icons/faPaperPlane').then((mod) => mod.faPaperPlane),
  alertTriangle: () =>
    import('@fortawesome/free-solid-svg-icons/faExclamationTriangle').then((mod) => mod.faExclamationTriangle),
};

export default function MemreIcon({
  iconName,
  color,
  fontSize = '0.8em',
}: {
  iconName: keyof typeof icons;
  color?: string;
  fontSize?: string;
}) {
  const [icon, setIcon] = useState<any>(null);

  useEffect(() => {
    if (iconName && icons[iconName]) {
      icons[iconName]().then((iconModule) => {
        setIcon(iconModule);
      });
    }
  }, [iconName]);

  return icon ? <FontAwesomeIcon style={{ fontSize, color }} icon={icon} /> : null;
}
