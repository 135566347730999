import TablePagination from '@mui/material/TablePagination';
import React from 'react';

const ADJUST_PAGE_NUMBER = 1;

const MemreCustomTablePagination = ({
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPageOptions,
}) => {
  const zeroBasedPage = page - ADJUST_PAGE_NUMBER;

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={zeroBasedPage}
      onPageChange={(event, newPage) => onPageChange(event, newPage + ADJUST_PAGE_NUMBER)} // Convert back to one-based
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

export default MemreCustomTablePagination;
