import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useStaffStore } from 'pages/admin-dashboard/staff/store';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

type DeletePartnerUserRoleParams = {
  partnerUserRoleId: string;
};

const deletePartnerUserRole = (params: DeletePartnerUserRoleParams) => {
  const { partnerUserRoleId } = params;
  return axios.delete(`/api/v3/partner_user_roles/${partnerUserRoleId}`);
};

export const useDeletePartnerUserRole = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useStaffStore();

  return useMutation(deletePartnerUserRole, {
    onSuccess: () => {
      makeToast({
        text: 'Successfully removed staff.',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to remove staff. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries([
        'partnerUserRoles',
        user?.primaryPartner.id,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        name,
      ]);
    },
  });
};
