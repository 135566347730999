import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

interface ReorderItemsParams {
  setId: string;
  orderedItemIds: string[];
}

const reorderItems = ({ setId, orderedItemIds }: ReorderItemsParams): any => {
  return axios.post(`/api/v3/sets/${setId}/items_ordering`, {
    ordering: orderedItemIds,
  });
};

export const useReorderItems = (setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(reorderItems, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to reorder items',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
  });
};
