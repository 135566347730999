import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ModalPosition } from 'components/Modal';
import { List, ListIcon, ModalHeader } from 'components/modals/common';
import { Color } from 'core';
import { Card, CardHeader, CardHeaderContainer, PageTitle } from 'core/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const ListWrapper = styled.div`
  text-align: left; // override Article
`;

interface IProps {
  setName: any;
  onCancel: () => void;
  onShare: () => void;
}

export const ConfirmShareContent = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>
          <b>{t("You're about to share:")}</b> {props.setName}
        </CenterTitle>
      </ModalHeader>

      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('What happens when you share assignments?')}</CardHeader>
        </CardHeaderContainer>
        <ListWrapper>
          <List>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('Assignment can be added to courses by instructors, content, and course managers.')}
            </li>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('Changes to this assignment may reflect in other courses.')}
            </li>
            <li>
              <ListIcon color={Color.primaryBlue}>
                <FontAwesomeIcon icon={faCheck} />
              </ListIcon>
              {t('A hard copy of the assignment can be added to additional courses.')}
            </li>
          </List>
        </ListWrapper>
      </Card>
      <ConfirmOrCancel
        confirmText={t('Share')}
        handleConfirmClick={props.onShare}
        confirmIsDisabled={false}
        handleCancelClick={props.onCancel}
        confirmIsPending={false}
      />
    </ModalPosition>
  );
};
