import _ from 'lodash';

export default function getMedian(numbers: number[]) {
  const sortedNumbers = _.sortBy(numbers);
  const middleIndex = Math.floor(sortedNumbers.length / 2);

  if (sortedNumbers.length % 2 === 0) {
    return (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
  } else {
    return sortedNumbers[middleIndex];
  }
}
