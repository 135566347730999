import { PrimaryButton } from 'components/buttons/v4';
import ImageView from 'components/ImageView';
import { FullScreenLink } from 'components/links';
import { Color, Size } from 'core';
import { isRightToLeft } from 'i18n';
import AudioPlayer from 'learn/components/AudioPlayer';
import { MultipagePdf } from 'learn/components/MultipagePdf';
import SanitizedHtml, { allowIframeConfig } from 'learn/components/SanitizedHtml';
import { TitleText } from 'learn/components/TitleText';
import { IInstructionalContent } from 'models/instructionalContent';
import { QuizResult } from 'models/presentation';
import { IQuizPart } from 'models/quiz';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import { getICTitleTextKey } from 'utils/quizUtils';

interface IOwnProps extends WithTranslation {
  instructionalContent: IInstructionalContent;
  goalType: string;
  isPreview: boolean;
  itemIndex: number;
  totalItemCount: number;
  incrementItemIndex: () => void;
  getNextItem: (
    answers: IQuizPart[],
    enteredText: string,
    quizResult?: QuizResult,
    ratingValue?: number,
    instructionalContentToReview?: IInstructionalContent
  ) => void;
  exit: () => void;
}

interface IState {
  isMobileSafari: boolean;
}

const HeaderTextContainer = styled.div`
  max-width: ${Size.defaultCardWidth}px;
  background: ${Color.lightPurple};
  margin-bottom: 30px;
  height: 40px;
`;

const Container = styled.div`
  background: white;
  margin: 25px auto 0px auto;
  max-width: ${Size.defaultCardWidth}px;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
`;

const ImageContainer = styled.div`
  & > img {
    max-width: 660px;
    max-height: 40rem;
    align-self: center;
  }
`;

const PartContainer = styled.div`
  display: flex;
  margin: 25px auto 0px 25px;
`;

const Video = styled.video`
  max-width: ${Size.defaultCardWidth - 50}px;
  object-fit: contain;
  margin: 0px auto;
  display: flex;
`;

const EmbedContainer = styled(PartContainer)`
  margin: 25px auto;
  max-width: 100%;

  iframe {
    margin: 0 auto;
  }
`;

const IFrame = styled.iframe`
  height: 600px;
  margin-left: 25px;
  margin-right: 25px;
  width: 100%;
  display: flex;
  border: none;
`;

const RecallButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const IFrameContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

class InstructionalContent extends React.PureComponent<IOwnProps, IState> {
  private iframeRef;
  private rightToLeft: boolean;
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.submitClicked = this.submitClicked.bind(this);
    this.rightToLeft = isRightToLeft();
    this.state = {
      ...this.state,
      isMobileSafari: false
    };
  }

  public componentDidMount(): void {
    window.scrollTo(0, 0);

    // detect if mobile safari: https://stackoverflow.com/a/29696509
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    this.setState({ isMobileSafari: iOSSafari });
  }

  public render() {
    const { instructionalContent, t, goalType } = this.props;
    const i18nKey = getICTitleTextKey(goalType);

    return (
      <div>
        <Container>
          <HeaderTextContainer>
            <TitleText
              text={t(i18nKey)}
              name={'card-title'}
              style={{
                marginLeft: '25px',
                marginRight: '25px',
                letterSpacing: '2px',
                lineHeight: '20px',
                textAlign: this.rightToLeft ? 'right' : 'left'
              }}
            />
          </HeaderTextContainer>

          <TitleText
            text={instructionalContent.title}
            name={'card-paragraph'}
            style={{
              marginLeft: '25px',
              textAlign: this.rightToLeft ? 'right' : 'left',
              paddingRight: this.rightToLeft ? '25px' : '0px'
            }}
          />
          {instructionalContent.parts.map(part => {
            if (part.image) {
              return (
                <div key={part.id} style={{ margin: '0px auto' }}>
                  <ImageContainer>
                    <ImageView image={part.image} transcodedSize={'medium'} />
                  </ImageContainer>
                  {part.caption && (
                    <TitleText
                      text={part.caption}
                      style={{ textAlign: this.rightToLeft ? 'right' : 'left' }}
                      name="card-body"
                    />
                  )}
                  <br />
                </div>
              );
            }
            if (part.textHtml) {
              return (
                <div key={part.id}>
                  <SanitizedHtml
                    source={part.textHtml}
                    style={{ margin: '0px 25px 0px 25px', textAlign: this.rightToLeft ? 'right' : 'left' }}
                  />
                  <br />
                </div>
              );
            }
            if (part.sound) {
              return (
                <PartContainer key={part.id}>
                  <AudioPlayer src={part.sound.url} />
                  <br />
                </PartContainer>
              );
            }
            if (part.html) {
              return (
                <div key={part.id}>
                  <EmbedContainer
                    key={part.id}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(part.html, allowIframeConfig)
                    }}
                  />
                  <br />
                </div>
              );
            }
            if (part.document) {
              if (this.state.isMobileSafari) {
                return <MultipagePdf file={part.document.url}></MultipagePdf>;
              } else {
                return (
                  <IFrameContainer key={part.id}>
                    <FullScreenLink targetRef={this.iframeRef} />
                    <IFrame ref={this.iframeRef} src={part.document.url} allowFullScreen={true} />
                    <br />
                  </IFrameContainer>
                );
              }
            }
            if (part.video) {
              return (
                <div key={part.id}>
                  <Video controls={true}>
                    <source src={part.video.url} />
                  </Video>
                  <br />
                </div>
              );
            }
            return null;
          })}
        </Container>
        <RecallButtonContainer>
          <PrimaryButton click={this.submitClicked}>{t('Got it! Next.')}</PrimaryButton>
        </RecallButtonContainer>
      </div>
    );
  }

  private submitClicked = () => {
    if (this.props.isPreview) {
      if (this.props.itemIndex === this.props.totalItemCount - 1) {
        this.props.exit();
      } else {
        this.props.incrementItemIndex();
      }
    } else {
      this.props.getNextItem([], '', 'Seen');
    }
  };
}

export default withTranslation()(InstructionalContent);
