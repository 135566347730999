import normalize from 'json-api-normalizer';
import { capitalize } from 'lodash';
import build from 'redux-object';

// This method takes in a JSON API response and returns the `data` portion with the following changes:
// 1. Attributes are flattened into the top level
// 2. Relationships are attached from the included array into the top level
// 3. Keys are converted to camelCase
export const normalizeJSONAPIResponse = (response) => {
  if (response == null || response.data == null) {
    return null;
  }
  const normalizedResponse = normalize(response, { endpoint: 'results' });
  const results = normalizedResponse.meta.results.data.map((entry) => {
    return build(normalizedResponse, entry.type, entry.id, { includeType: true });
  });
  return Array.isArray(response.data) ? results : results[0];
};

export const humanizeV3Error = (error) => {
  if (error == null) {
    return null;
  }
  if (error.source == null) {
    return error.title;
  }
  return capitalize(error.source) + ' ' + error.title;
};
