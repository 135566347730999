export type IPresentation =
  | IMultipleChoicePresentation
  | IFillInTheBlankPresentation
  | IInstructionalContentPresentation
  | IRegionClickInTheDarkPresentation
  | IRegionMultipleChoicePresentation;

export type QuizResult = 'Correct' | 'Incorrect' | 'AlmostCorrect' | 'Seen';
interface IBasePresentation {
  recall_time_millis: number;
  quiz_time_millis: number;
  confirmation_time_millis: number;
  study_time_millis: number;
  occurred_at: string;
  item_id: '';
  learning_engine_guid: string;
  session_guid: string;
  quiz_result: QuizResult;
}

export interface IMultipleChoicePresentation extends IBasePresentation {
  quiz_type: string;
  quiz_data_attributes: {
    choices: IPresentationMultipleChoice[];
    choices_made: number[];
  };
}

export interface IPresentationMultipleChoice {
  image?: string;
  text_html?: string;
  correct: boolean;
}

export interface IRatingPresentation extends IBasePresentation {
  quiz_type: string;
  quiz_data_attributes: {
    rating_value: number;
  };
}

export interface IFillInTheBlankPresentation extends IBasePresentation {
  quiz_type: string;
  quiz_data_attributes: {
    // no hint in new learn
    hint_used: false;
    entered_text: string;
  };
}

export interface IRegionClickInTheDarkPresentation extends IBasePresentation {
  quiz_type: string;
  quiz_data_attributes: {
    hint_used: false;
    // TODO: migrate Learning Engine to fully support regions, have this be target_polygon + clicked_position
    target_text: string;
    entered_text: string;
  };
}

export interface IPresentationRegionChoice {
  polygon: string;
}

export interface IRegionMultipleChoicePresentation extends IBasePresentation {
  quiz_type: string;
  quiz_data_attributes: {
    choices: IPresentationRegionChoice[];
    choices_made: number[];
  };
}

export interface ISequenceChoice {
  'sequence-position': number;
  correct: boolean;
}

export interface ISequencePresentation extends IBasePresentation {
  quiz_type: string;
  quiz_data_attributes: {
    choices: ISequenceChoice[];
    choices_made: number[];
  };
}

export interface IInstructionalContentPresentation {
  recall_time_millis: null;
  quiz_time_millis: null;
  confirmation_time_millis: null;
  study_time_millis: number;
  occurred_at: string;
  item_id: null;
  learning_engine_guid: string;
  session_guid: string;
  quiz_type: 'InstructionalStudy' | 'V4InstructionalStudy';
  quiz_result: QuizResult;
}

export enum PresentationScreens {
  Recall,
  Quiz,
  Confirmation,
  Study
}
