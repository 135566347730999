import { FormGroup } from '@material-ui/core';
import ArrowImg from 'assets/images/down.png';
import { PrimaryButton } from 'components/buttons/v4';
import { Color, Size } from 'core';
import FillInTheBlankAnswer, { FillInTheBlankAnswerStyle } from 'learn/components/FillInTheBlankAnswer';
import MultipleChoiceAnswer, { MultipleChoiceAnswerStyle } from 'learn/components/MultipleChoiceAnswer';
import QuizCard from 'learn/components/QuizCard';
import QuizCardExpanded from 'learn/components/QuizCardExpanded';
import Rating from 'learn/components/Rating';
import Region from 'learn/components/Region';
import Sequence from 'learn/components/Sequence';
import { TitleText } from 'learn/components/TitleText';
import * as _ from 'lodash';
import { IInstructionalContent } from 'models/instructionalContent';
import { IPartner } from 'models/partner';
import { QuizResult } from 'models/presentation';
import { IQuizPart } from 'models/quiz';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteProps } from 'react-router';
import styled from 'styled-components';
import StringForComparison from 'utils/StringForComparison';

interface IOwnState {
  multipleChoiceAnswers?: IQuizPart[];
  sequenceAnswers?: IQuizPart[];
  fillInTheBlankAnswer: string;
  clickPositionText?: string;
  isSubmitted: boolean;
  isCorrect: boolean;
  showAnswers: boolean;
  showRecall: boolean;
  quizResult?: QuizResult;
  showNeedsAnswerMessage: boolean;
  ratingValue?: number;
  isExiting: boolean;
}

const initialState: IOwnState = {
  showAnswers: false,
  showRecall: true,
  isSubmitted: false,
  isCorrect: false,
  quizResult: undefined,
  clickPositionText: undefined,
  multipleChoiceAnswers: undefined,
  sequenceAnswers: undefined,
  fillInTheBlankAnswer: '',
  showNeedsAnswerMessage: false,
  ratingValue: undefined,
  isExiting: false,
};

interface IOwnProps extends RouteProps, WithTranslation {
  goalType: string;
  isPreview: boolean;
  showAnswers?: boolean;
  quiz: any;
  nextScreen: () => void;
  exit: () => void;
  itemIndex: number;
  totalItemCount: number;
  incrementItemIndex: () => void;
  setItemIndexToItemWithId: (itemId: string) => void;
  getNextItem: (
    answers: IQuizPart[],
    enteredText: string,
    quizResult?: QuizResult,
    ratingValue?: number,
    instructionalContentToReview?: IInstructionalContent
  ) => void;
  partner?: IPartner;
}
const RecallCardContainer = styled.div`
  max-width: ${Size.defaultCardWidth}px;
  min-height: 200px;
  margin: 25px auto 0px auto;
`;

const RecallButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ReviewContentContainer = styled.div`
  display: flex;
  width: 100%;
`;
const ReviewContent = styled.a`
  font-size: 16px;
  color: ${Color.darkGray};
  margin: 0px auto;
  padding: 20px 0 0 0;
  text-decoration: underline;
  cursor: pointer;
`;

class QuizPage extends React.PureComponent<IOwnProps, IOwnState> {
  constructor(props: any) {
    super(props);

    if (this.props.goalType === 'survey') {
      initialState.showRecall = false;
      this.props.nextScreen();
    }

    this.state = initialState;
  }

  public componentDidMount(): void {
    window.scrollTo(0, 0);
    if (this.props.showAnswers) {
      this.selectPreviewAnswers();
    }
  }

  public render() {
    const { quiz, t } = this.props;

    const isMultipleChoice: boolean = ['MultipleChoiceSingleSelect', 'MultipleChoiceMultiSelect'].includes(
      quiz?.quizType
    );
    const isImageOnlyMultipleChoice: boolean =
      isMultipleChoice && quiz?.parts.every((part: any) => !part.textHtml && part.image);

    if (this.state.showRecall) {
      return (
        <div style={{ paddingTop: '25px', paddingBottom: '20px' }}>
          <RecallCardContainer>
            <QuizCard quiz={this.props.quiz} goalType={this.props.goalType} />
          </RecallCardContainer>
          <RecallButtonContainer>
            <PrimaryButton shape="round" size="large" click={this.hideRecall} aria-label={t('Continue')}>
              <img src={ArrowImg} alt="down arrow" />
            </PrimaryButton>
          </RecallButtonContainer>
        </div>
      );
    }

    return (
      <div style={{ paddingBottom: '20px' }}>
        <QuizCardExpanded quiz={quiz} goalType={this.props.goalType} />
        {isImageOnlyMultipleChoice && (
          <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', maxWidth: '1080px', margin: '0 auto' }}>
            <FormGroup aria-label={this.isMultiSelect() ? t('Multiple Select Answer') : t('Single Select Answer')}>
              {quiz.parts.map((part: any) => (
                <MultipleChoiceAnswer
                  answer={part}
                  imageOnly={true}
                  onClick={this.answerSelected}
                  key={part.id}
                  answerStyle={this.getMultipleChoiceAnswerStyle(part)}
                  isMultiSelect={this.isMultiSelect()}
                  isSubmitted={this.state.isSubmitted}
                  isSelected={(this.state.multipleChoiceAnswers && this.state.multipleChoiceAnswers.indexOf(part) > -1) || false}
                />
              ))}
            </FormGroup>
          </div>
        )}
        {isMultipleChoice && !isImageOnlyMultipleChoice && (
          <FormGroup aria-label={this.isMultiSelect() ? t('Multiple Select Answer') : t('Single Select Answer')}>
            {quiz.parts.map((part: any) => {
              return (
                <MultipleChoiceAnswer
                  answer={part}
                  imageOnly={false}
                  onClick={this.answerSelected}
                  key={part.id}
                  answerStyle={this.getMultipleChoiceAnswerStyle(part)}
                  isMultiSelect={this.isMultiSelect()}
                  isSubmitted={this.state.isSubmitted}
                  isSelected={(this.state.multipleChoiceAnswers && this.state.multipleChoiceAnswers.indexOf(part) > -1) || false}
                />
              );
            })}
          </FormGroup>
        )}
        {['RegionVisiblePolygons', 'RegionHiddenPolygons'].includes(quiz?.quizType) && (
          <Region
            quiz={quiz}
            click={this.handleRegionClicked}
            isSubmitted={this.state.isSubmitted}
            selectedAnswers={this.state.multipleChoiceAnswers}
            showAnswers={this.props.showAnswers}
          />
        )}
        {quiz?.quizType === 'FillInTheBlank' && (
          <FillInTheBlankAnswer
            onFillInTheBlankChange={this.handleFillInTheBlankChange}
            userAnswer={this.state.fillInTheBlankAnswer}
            answer={quiz.parts[0]}
            quizResult={this.state.quizResult}
            style={this.getFillInTheBlankStyle()}
            showAnswers={this.props.showAnswers}
          />
        )}
        {quiz?.quizType === 'Sequence' && (
          <Sequence
            quiz={quiz}
            onSequenceChange={this.handleSequenceChange}
            isSubmitted={this.state.isSubmitted}
            showAnswers={this.props.showAnswers}
          />
        )}
        {quiz?.quizType === 'Rating' && <Rating quiz={quiz} onRatingValueChange={this.handleRatingValueChange} />}
        {this.state.isSubmitted && (
          <RecallButtonContainer>
            <PrimaryButton click={this.nextClicked}>
              {this.state.isCorrect ? t('Awesome! Next.') : t('Got it! Next.')}
            </PrimaryButton>
          </RecallButtonContainer>
        )}
        {this.state.isSubmitted && quiz.attachedItems && quiz.attachedItems.length > 0 && (
          <ReviewContentContainer>
            <ReviewContent onClick={this.reviewClicked}>{t('Review Instructional Material')} </ReviewContent>
          </ReviewContentContainer>
        )}
        {!this.state.isSubmitted && (
          <span>
            <RecallButtonContainer>
              <PrimaryButton click={this.submitClicked}>{t('Submit answer')}</PrimaryButton>
            </RecallButtonContainer>
            {this.state.showNeedsAnswerMessage && (
              <TitleText
                name="error-message"
                text={
                  ['RegionHiddenPolygons', 'RegionVisiblePolygons'].includes(quiz?.quizType)
                    ? t('Please select a location on the image.')
                    : t('Please enter an answer!')
                }
                style={{
                  margin: '10px auto 0px auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              />
            )}
          </span>
        )}
      </div>
    );
  }

  private reviewClicked = () => {
    if (!this.props.quiz.attachedItems[0]) {
      return;
    }
    this.advanceToNextItem(this.props.quiz.attachedItems[0].attachedItem);
  };

  private gradeFillInTheBlankAnswer = (): QuizResult => {
    const userAnswer = new StringForComparison(this.state.fillInTheBlankAnswer);
    const correctAnswer = new StringForComparison(this.props.quiz.parts[0].text);
    let quizResult: QuizResult = 'Incorrect';

    if (userAnswer.isEquivalentInSomeFormTo(correctAnswer)) {
      quizResult = 'Correct';
    } else if (userAnswer.isPrettyCloseTo(correctAnswer)) {
      quizResult = 'AlmostCorrect';
    }

    return quizResult;
  };

  private submitClicked = () => {
    if (this.state.isExiting) {
      return; // Don't let double submission while browser is hard loading next page
    }
    let quizResult;
    switch (this.props.quiz.quizType) {
      case 'FillInTheBlank':
        if (!this.state.fillInTheBlankAnswer) {
          this.setState({ showNeedsAnswerMessage: true });
          return;
        } else {
          quizResult = this.gradeFillInTheBlankAnswer();
        }
        break;
      case 'MultipleChoiceSingleSelect':
      case 'MultipleChoiceMultiSelect':
        if (!this.state.multipleChoiceAnswers) {
          this.setState({ showNeedsAnswerMessage: true });
          return;
        }
        quizResult = this.isMultipleChoiceCorrect() ? 'Correct' : 'Incorrect';
        break;
      case 'Rating':
        if (!this.state.ratingValue) {
          this.setState({ showNeedsAnswerMessage: true });
          return;
        }
        quizResult = 'Correct'; // This is arbitrary
        break;
      case 'RegionHiddenPolygons':
      case 'RegionVisiblePolygons':
        if (!this.state.multipleChoiceAnswers && !this.state.clickPositionText) {
          this.setState({ showNeedsAnswerMessage: true });
          return;
        }
        // region has same checking as multiple choice
        quizResult = this.isMultipleChoiceCorrect() ? 'Correct' : 'Incorrect';
        break;
      case 'Sequence':
        quizResult = this.state.isCorrect ? 'Correct' : 'Incorrect';
        break;
      default:
        throw new Error(
          "Error, this should never happen. If it does, it's probably due to adding a quiz type without updating logic here."
        );
    }
    this.props.nextScreen();

    const skipConfirmationScreen = this.shouldSkipConfirmationScreen();
    this.setState(
      {
        quizResult,
        isSubmitted: !skipConfirmationScreen,
        isCorrect: quizResult === 'Correct',
      },
      () => {
        if (skipConfirmationScreen) {
          this.advanceToNextItem(); // Skip confirmation
        }
      }
    );
  };

  private shouldSkipConfirmationScreen = () => {
    const partnerSettings = this.props.partner?.partnerSettings;
    const hideAssessmentAnswers = partnerSettings && !partnerSettings.assessmentShowsAnswers;
    return this.props.goalType === 'survey' || (hideAssessmentAnswers && this.props.goalType === 'assessment');
  };

  private nextClicked = () => {
    this.advanceToNextItem();
  };

  private exit = () => {
    this.setState({ isExiting: true });
    this.props.exit();
  };

  private advanceToNextItem = (instructionalContentToReview?: IInstructionalContent) => {
    if (this.props.isPreview) {
      if (instructionalContentToReview) {
        this.props.setItemIndexToItemWithId(instructionalContentToReview.id);
        this.clearData();
      } else if (this.isLastItem()) {
        this.exit();
        if (this.props.goalType === 'survey') {
          this.clearData();
        }
      } else {
        this.props.incrementItemIndex();
        this.clearData();
      }
    } else {
      this.props.getNextItem(
        this.state.multipleChoiceAnswers || this.state.sequenceAnswers || [],
        this.state.fillInTheBlankAnswer || this.state.clickPositionText || '',
        this.state.quizResult,
        this.state.ratingValue,
        instructionalContentToReview
      );
      this.clearData();
    }
  };

  private isLastItem = () => {
    return this.props.itemIndex === this.props.totalItemCount - 1;
  };

  private clearData = () => {
    this.setState(initialState);
  };

  private hideRecall = () => {
    this.setState({
      showRecall: false,
    });
    this.props.nextScreen();
  };

  private selectPreviewAnswers = () => {
    switch (this.props.quiz.quizType) {
      case 'MultipleChoiceSingleSelect':
      case 'MultipleChoiceMultiSelect':
      case 'RegionHiddenPolygons':
      case 'RegionVisiblePolygons':
        this.setState({
          multipleChoiceAnswers: _.filter(this.props.quiz.parts || [], 'isCorrect'),
        });
        break;
      case 'Sequence':
      case 'FillInTheBlank':
        break;
      default:
        throw new Error(
          "Error, this should never happen. If it does, it's probably due to adding a quiz type without updating preview logic here."
        );
    }
  };

  // TODO - separate components for these?
  // Multiple Choice
  private answerSelected = (answer: IQuizPart) => {
    if (this.state.isSubmitted) {
      return;
    }
    this.setState({ showNeedsAnswerMessage: false });
    let selectedAnswers = this.state.multipleChoiceAnswers || [];
    const index = selectedAnswers.indexOf(answer);
    // need to copy array to trigger re-render since we're in a pure component
    selectedAnswers = selectedAnswers.slice();
    // if multi-select, toggle the selected answer
    if (this.isMultiSelect()) {
      if (index > -1) {
        selectedAnswers.splice(index, 1);
      } else {
        selectedAnswers.push(answer);
      }
    } else {
      // if single select, replace the current answer
      selectedAnswers = [answer];
    }

    this.setState({ multipleChoiceAnswers: selectedAnswers });
  };

  private handleRegionClicked = (clickPositionText: string, answer?: IQuizPart) => {
    this.setState({ clickPositionText });
    if (answer) {
      // clear click in the dark wrong answer
      this.answerSelected(answer);
    } else {
      // clear selected answer
      this.setState({ multipleChoiceAnswers: [] });
    }
  };

  private isMultipleChoiceCorrect = (): boolean => {
    const { multipleChoiceAnswers: selectedAnswers } = this.state;
    if (!selectedAnswers || selectedAnswers.length === 0) {
      return false;
    }

    const incorrectAnswers = selectedAnswers.filter((a) => !a.isCorrect);
    const missedAnswers = this.props.quiz.parts.filter((a) => a.isCorrect && selectedAnswers.indexOf(a) === -1);
    return incorrectAnswers.length === 0 && missedAnswers.length === 0;
  };

  private getMultipleChoiceAnswerStyle = (part: IQuizPart): MultipleChoiceAnswerStyle => {
    let style: MultipleChoiceAnswerStyle = 'unselected';
    const selectedAnswers = this.state.multipleChoiceAnswers || [];
    const isAnswerSelected = selectedAnswers.indexOf(part) > -1;
    if (isAnswerSelected && !this.state.isSubmitted) {
      style = 'selected';
    }
    if (this.state.isSubmitted) {
      if (part.isCorrect) {
        style = this.isMultiSelect() && !isAnswerSelected ? 'missed' : 'correct';
      } else if (isAnswerSelected) {
        style = 'incorrect';
      }
    }
    return style;
  };

  private isMultiSelect = (): boolean => {
    const { quiz } = this.props;
    return quiz && quiz.quizType === 'MultipleChoiceMultiSelect';
  };

  // Rating
  private handleRatingValueChange = (ratingValue: number) => {
    this.setState({
      ratingValue,
      showNeedsAnswerMessage: false,
    });
  };

  private handleFillInTheBlankChange = (fillInTheBlankAnswer: string): void => {
    this.setState({ fillInTheBlankAnswer, showNeedsAnswerMessage: false });
  };

  private getFillInTheBlankStyle = (): FillInTheBlankAnswerStyle => {
    let style: FillInTheBlankAnswerStyle = 'default';
    if (this.state.isSubmitted) {
      style = this.state.isCorrect ? 'correct' : 'incorrect';
    }
    return style;
  };

  // Sequence
  private handleSequenceChange = (sequenceAnswers: IQuizPart[], isSequenceCorrect: boolean) => {
    this.setState({ sequenceAnswers, isCorrect: isSequenceCorrect });
  };
}

export default withTranslation()(QuizPage);
