import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PrimaryButton } from 'components/buttons/v4';
import { BrightTextarea } from 'components/forms/BrightTextarea';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ModalPosition } from 'components/Modal';
import { ModalHeader } from 'components/modals/common';
import { Color } from 'core';
import { Card, CardHeader, CardHeaderContainer, CardSubHeader, PageTitle } from 'core/layout';
import { API_DATA_SUCCESS } from 'middleware/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleCreateCourseUser } from 'store/course-user';
import { handleGetLearnerStats } from 'store/learner-stats';
import styled from 'styled-components';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const SuccessfulInvitesContainer = styled.div`
  margin: 10px 0 10px 0;
`;

const FailedInvitesContainer = styled.div`
  color: ${Color.red};
  margin: 10px 0 10px 0;
`;

interface IProps {
  courseId: string;
  partnerId: string | null;
  isPublished: boolean;
  close: () => void;
}

export const AddLearnersByEmail = React.memo<IProps>((props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const placeholder = t('e.g. name@organization.com, name2@organization.com, name3@organization.com');
  const [shouldSendEmail, setShouldSendEmail] = useState(props.isPublished);
  const [confirmIsPending, setConfirmIsPending] = useState(false);
  const [inviteList, setInviteList] = useState('');
  const [successfulInvites, setSuccessfulInvites] = useState<string[]>([]);
  const [failedInvites, setFailedInvites] = useState<string[]>([]);

  const handleConfirmClick = async () => {
    setConfirmIsPending(true);

    const emails = inviteList
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email !== '');
    const promises = emails.map((emailAddress) => {
      return dispatch(
        handleCreateCourseUser(props.courseId, emailAddress, shouldSendEmail, 'enrolled_via_direct_invite', false)
      );
    });
    const results = await Promise.all(promises);
    setConfirmIsPending(false);
    setInviteList('');

    const goodResults: string[] = [];
    const badResults: string[] = [];
    results.forEach((result, i) => {
      if ((result as any).type === API_DATA_SUCCESS) {
        goodResults.push(emails[i]);
      } else {
        badResults.push(emails[i]);
      }
    });
    if (goodResults.length > 0) {
      dispatch(handleGetLearnerStats(props.courseId));
    }
    setSuccessfulInvites(goodResults);
    setFailedInvites(badResults);
  };

  const updateInviteList = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInviteList(event.target.value);
  };
  const canSendInvites = successfulInvites.length === 0 || failedInvites.length > 0;

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>{t('Manage Learners by Email')}</CenterTitle>
      </ModalHeader>
      <Card>
        <CardHeaderContainer>
          <CardHeader id="header">{t('Add learners')}</CardHeader>
          {canSendInvites && (
            <CardSubHeader>
              {t('Type or paste a list of individual email addresses below, separated by comma:')}
            </CardSubHeader>
          )}
        </CardHeaderContainer>

        <div>
          {canSendInvites && (
            <BrightTextarea
              value={inviteList}
              placeholder={placeholder}
              onChange={updateInviteList}
              aria-labelledby="header"
            />
          )}
          {successfulInvites.length > 0 && (
            <SuccessfulInvitesContainer>
              <div>{t('Add Learners By Email Success Text', { count: successfulInvites.length })}</div>
              {successfulInvites.map((invite, i) => (
                <div key={i}>{invite}</div>
              ))}
            </SuccessfulInvitesContainer>
          )}
          {failedInvites.length > 0 && (
            <FailedInvitesContainer>
              <div>{t('Add Learners By Email Failure Text', { count: failedInvites.length })}</div>
              {failedInvites.map((invite, i) => (
                <div key={i}>{invite}</div>
              ))}
            </FailedInvitesContainer>
          )}
          {canSendInvites && props.isPublished && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldSendEmail}
                  onChange={() => setShouldSendEmail((shouldSendEmail) => !shouldSendEmail)}
                  style={{ padding: '12px', color: Color.primaryBlue, fontSize: '0.8rem' }}
                  data-type="checkbox"
                />
              }
              label={<Typography style={{ fontSize: '18px' }}>{t('Send email invites')}</Typography>}
            />
          )}
        </div>
      </Card>
      {canSendInvites && (
        <ConfirmOrCancel
          cancelText={t('Cancel')}
          confirmText={t('Add Learners')}
          handleCancelClick={props.close}
          handleConfirmClick={handleConfirmClick}
          confirmIsDisabled={inviteList.length === 0 || !canSendInvites}
          confirmIsPending={confirmIsPending}
        />
      )}
      {!canSendInvites && <PrimaryButton click={props.close}>{t('Done')}</PrimaryButton>}
    </ModalPosition>
  );
});
