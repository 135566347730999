import CircularProgress from '@material-ui/core/CircularProgress';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { usePeople, UsePeopleParams } from 'hooks/usePeople';
import React, { useState } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { useReportStore } from 'zstore';

export const PeopleFilter = () => {
  const userId = useReportStore((state) => state.userId);
  const setUserId = useReportStore((state) => state.setUserId);
  const setPage = useReportStore((state) => state.setPage);
  const params: UsePeopleParams = {
    page: 1,
    pageSize: 200,
    group: null,
    userId: null,
    sort: 'name',
    sortDirection: 'asc',
    limited: true,
  };
  const { data: response, isLoading } = usePeople(params);

  const people = response?.data.map((person: any) => ({ id: person.id, name: !!person.name ? person.name : 'NULL' }));

  const [value, setValue] = useState<any>(people?.find((user: any) => user.id === userId) || null);
  const [inputValue, setInputValue] = useState(people?.find((user: any) => user.id === userId)?.name || '');
  const [open, setOpen] = useState(false);

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  return (
    <Autocomplete
      id="user-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={isLoading}
      value={value}
      onChange={(_event: any, newValue: any) => {
        if (!!newValue) {
          setValue(newValue);
          setUserId(newValue.id);
          setPage(1);
        } else {
          setValue(null);
          setUserId(null);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      options={people?.sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) || []}
      getOptionLabel={(option: any) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ margin: '20px' }}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select person"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      selectOnFocus
      disableListWrap
    />
  );
};

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props: any, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
