import { Color } from 'core';
import React from 'react';
import { formatReadiness } from 'utils/formatReadiness';

interface IReadinessBadge {
  readinessScore?: number;
  style?: object;
}

export const ReadinessBadge = (props: IReadinessBadge) => {
  const { readinessScore } = props;
  const readiness: number = readinessScore ?? 0;
  const readinessLoading = readinessScore === undefined;

  const readinessColor: string = readinessLoading ? Color.lightGray : Color.primaryGreenBright;
  const readinessText = readinessLoading ? '' : formatReadiness(readiness);
  return (
    <span
      style={Object.assign(
        {
          backgroundColor: Color.hexToRGB(readinessColor, Math.max(readiness, 0.1)),
          borderRadius: '12px',
          width: '65px',
          height: '37px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black',
          fontSize: '18px',
          whiteSpace: 'pre',
        } as any,
        props.style
      )}
    >
      {readinessText}
    </span>
  );
};
