import { Color } from 'core';
import { TitleText } from 'learn/components/TitleText';
import React from 'react';

interface IAssignmentFormHeader {
  assignmentName: string;
  formTitle: string;
}

const AssignmentFormHeader = (props: IAssignmentFormHeader) => {
  return (
    <div style={{ marginTop: '32px', textAlign: 'center' }}>
      <p style={{ fontSize: '0.8em', marginBottom: '0', color: Color.slateGray }}>
        <em>{props.assignmentName}</em>
      </p>
      <TitleText text={props.formTitle} style={{ marginTop: '5px' }} />
    </div>
  );
};

export default AssignmentFormHeader;
