import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import * as Color from 'core/colors';
import useDebounce from 'hooks/useDebounce';
import { TABLE_DEFAULT, TableColumnDef, TableParmas } from 'models/table';
import { ChangeEvent, Fragment, useState } from 'react';
import { MemreText } from './index';
import MemreCustomTablePagination from './MemreCustomTablePagination';
import MemreFlexBox from './MemreFlexBox';
import MemreLoading from './MemreLoading';
import MemreNoResults from './MemreNoResults';

const COLUMN_OPTIONS = [5, 10, 25, 50, 100];

const Loading = () => (
  <MemreFlexBox direction="column" align="center" justify="center" gap={2} sx={{ p: 3 }}>
    <MemreLoading /> <MemreText sx={{ color: Color.darkGray }}>Loading</MemreText>{' '}
  </MemreFlexBox>
);

export default function MemreTable({
  id,
  useData,
  columns,
  totalCount,
  handleRowClick,
  sortBy,
}: {
  id: any;
  useData: (params: TableParmas) => { data: any; isLoading: boolean };
  columns: Array<TableColumnDef<any>>;
  totalCount: number;
  handleRowClick?: (params: any) => void;
  sortBy?: string;
}) {
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT.PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(TABLE_DEFAULT.PAGE_NUMBER);
  const [searchTerm, setSearchTerm] = useState(TABLE_DEFAULT.SEARCH_TERM);
  const [sortByColumn, setSortByColumn] = useState(sortBy || TABLE_DEFAULT.SORT_BY_COLUMN);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data, isLoading } = useData({
    id,
    pageSize,
    pageNumber,
    searchTerm: debouncedSearchTerm,
    sortByColumn,
  });

  const table = useReactTable<any>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const headerGroups = table.getHeaderGroups();

  const rows = isLoading ? [] : table.getRowModel()?.rows;

  const rowsPerPageOptions: any = COLUMN_OPTIONS.filter((option) => option <= totalCount);
  const firstLargerOption = COLUMN_OPTIONS.find((option) => option > totalCount);
  if (firstLargerOption) {
    rowsPerPageOptions.push({ label: 'All', value: firstLargerOption });
  }

  const orderDirection = 'asc';

  const handleChangeSoreByColumn = (columnId: string | undefined) => {
    if (columnId) {
      setSortByColumn(columnId);
      setPageNumber(1);
    }
  };

  const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (_, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: Color.veryLightGray,
              borderBottom: `1px solid  ${Color.lightGray}`,
            }}
          >
            {headerGroups.map((headerGroup) => (
              <Fragment key={headerGroup.id}>
                <TableRow>
                  {headerGroup.headers.map((header) => {
                    const columnDef = header.column.columnDef as TableColumnDef<any>;
                    return (
                      <TableCell
                        key={header.id}
                        sx={{
                          cursor: 'pointer',
                          py: 1,
                          px: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        <div
                          onClick={columnDef.sortable ? () => handleChangeSoreByColumn(columnDef.sortable) : undefined}
                        >
                          <MemreFlexBox
                            sx={{
                              width: columnDef.fillWidth ? '100%' : 'auto',
                              flexGrow: columnDef.fillWidth ? 1 : 0,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              flexDirection: columnDef.rightAlign ? 'row-reverse' : 'row',
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                            {columnDef.sortable && (
                              <TableSortLabel
                                active={sortByColumn === header.id}
                                direction={sortByColumn === header.id ? orderDirection : 'asc'}
                              />
                            )}
                          </MemreFlexBox>
                        </div>
                        {columnDef.searchable && (
                          <TextField
                            size="small"
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                            placeholder={`Search ${header.id}`}
                            style={{
                              backgroundColor: Color.white,
                              marginTop: '0.2rem',
                              marginLeft: '-2px',
                            }}
                            inputProps={{
                              style: {
                                padding: '4px 10px',
                                fontSize: '0.8rem',
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  style={{
                                    fontSize: '0.8rem',
                                    color: Color.lightGray,
                                  }}
                                />
                              ),
                            }}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={headerGroup.headers.length}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}
                {rows.length === 0 && !isLoading && (
                  <TableRow>
                    <TableCell sx={{ px: 3 }} colSpan={headerGroup.headers.length}>
                      <MemreNoResults />
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <TableRow
                key={row.id}
                onClick={() => (handleRowClick ? handleRowClick(row) : null)}
                sx={{
                  cursor: handleRowClick ? 'pointer' : 'default',
                  '&:hover': {
                    backgroundColor: Color.hexToRGB(Color.veryLightGray, 0.3),
                  },
                }}
              >
                {row.getVisibleCells().map((cell: any) => (
                  <TableCell
                    sx={{
                      px: 3,
                      width: cell.column.columnDef.fillWidth ? '100%' : 'auto',
                      flexGrow: cell.column.columnDef.fillWidth ? 1 : 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    align={cell.column.columnDef.rightAlign ? 'right' : 'left'}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MemreCustomTablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={totalCount}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
