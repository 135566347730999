import axios from 'axios';
import { useQuery } from 'react-query';

type LearnerStatsParams = {
  'page[number]': string;
  'page[size]': string;
  sortBy: string;
  sortDirection: string;
  nameFilter?: string;
  groupFilter?: string;
  setId?: string;
};

export const getLearnerStats = async (
  _key: string,
  courseId: string | null,
  sortBy: string,
  sortDirection: 'asc' | 'desc',
  page: number,
  pageSize: number,
  nameFilter: string,
  groupFilter: string,
  setId: string
) => {
  if (!courseId) {
    console.warn('Cannot get learner stats without courseId');
    return;
  }

  let params: LearnerStatsParams = {
    'page[number]': `page[number]=${page}`,
    'page[size]': `page[size]=${pageSize}`,
    sortBy: `sort_by=${sortBy}`,
    sortDirection: `sort_direction=${sortDirection}`
  };
  let queryString = `${params['page[number]']}&${params['page[size]']}&${params.sortBy}&${params.sortDirection}`;
  if (nameFilter !== '') {
    params.nameFilter = `name_filter=${nameFilter}`;
    queryString = queryString.concat(`&${params.nameFilter}`);
  }
  if (groupFilter) {
    params.groupFilter = `group_filter=${encodeURIComponent(groupFilter)}`;
    queryString = queryString.concat(`&${params.groupFilter}`);
  }
  if (setId) {
    params.setId = `set_id=${setId}`;
    queryString = queryString.concat(`&${params.setId}`);
  }

  const baseUrl = `/api/v4/courses/${courseId}/learner_stats`;
  const endpoint = `${baseUrl}?${queryString}`;

  try {
    const response = await axios.get(endpoint);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const useLearnerStats = (
  courseId: string | null,
  sortBy: string,
  sortDirection: 'asc' | 'desc',
  page: number,
  pageSize: number,
  nameFilter: string,
  groupFilter: string | null,
  setId: string | null
) => {
  return useQuery(
    ['learnerStats', courseId, sortBy, sortDirection, page, pageSize, nameFilter, groupFilter, setId],
    getLearnerStats,
    {
      enabled: courseId,
    }
  );
};
