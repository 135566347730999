import { useState, useEffect } from 'react';

type AutosaveStatus = 'idle' | 'saving' | 'success' | 'error';

export const useAutosaveStatus = () => {
  const [autosaveStatus, setAutosaveStatus] = useState<AutosaveStatus>('idle');

  useEffect(() => {
    if (autosaveStatus === 'success' || autosaveStatus === 'error') {
      const timer = setTimeout(() => {
        setAutosaveStatus('idle');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autosaveStatus]);

  return [autosaveStatus, setAutosaveStatus] as [AutosaveStatus, React.Dispatch<React.SetStateAction<AutosaveStatus>>];
};
