import { ThemeProvider } from '@mui/material';
import LoadingComponent from 'components/LoadingComponent';
import { Modal } from 'components/Modal';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { useGroup } from 'hooks/useGroup';
import { GroupCoursesEnrollmentModal } from 'pages/admin-dashboard/groups/GroupCoursesEnrollmentModal';
import { GroupCoursesTable } from 'pages/admin-dashboard/groups/GroupCoursesTable';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useState } from 'react';

type Props = {
  match: {
    params: {
      groupId: string;
    };
  };
};

export const GroupCoursesPage: React.FC<Props> = ({ match }) => {
  const { groupId } = match.params;
  const { data: group, isLoading } = useGroup(groupId);
  const [showEnrollModal, setShowEnrollModal] = useState(false);

  return (
    <LoadingComponent
      isLoading={isLoading}
      component={
        <ThemeProvider theme={theme}>
          <Modal
            show={showEnrollModal}
            element={<GroupCoursesEnrollmentModal groupId={groupId} close={() => setShowEnrollModal(false)} />}
          />
          <div style={{ padding: '2rem' }}>
            <MemreFlexBox align="center" direction="row" justify="space-between">
              <MemreFlexBox align="center" direction="column">
                <MemreText variant="overline">{group?.name}</MemreText>
                <MemreText variant="h3">Courses</MemreText>
              </MemreFlexBox>
              <MemreButton
                text="Enroll in Courses"
                variant="contained"
                color="secondary"
                iconName="plus"
                onClick={() => setShowEnrollModal(true)}
              />
            </MemreFlexBox>
            <GroupCoursesTable groupId={groupId} />
          </div>
        </ThemeProvider>
      }
    />
  );
};

// TODO: support enrolling group in courses
// NOTE: legacy implementation opens a modal with a full courses table: multiselect, search, filter, pagination, etc
