export function partnerRoleDisplay(partnerRole: string): string {
  switch (partnerRole) {
    case 'admin':
      return 'Admin';
    case 'course manager':
      return 'Course Manager';
    case 'content manager':
      return 'Content Manager';
    case 'course_viewer':
      return 'Course Viewer';
    case 'instructor':
      return 'Instructor';
    case 'editor':
      return 'Editor';
    case 'department_staff':
      return 'Department Staff';
    default:
      return 'Learner';
  }
}
