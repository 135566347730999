import Skeleton from '@material-ui/lab/Skeleton';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { LearnersTableToolbar } from 'components/LearnersTableToolbar';
import { ReadinessBadge } from 'components/ReadinessBadge';
import { Pagination } from 'components/reports/controls/Pagination';
import { ColumnHeader } from 'components/tables/learner-stats/ColumnHeader';
import { Container } from 'components/tables/learner-stats/Container';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { DataContainer, DataTable } from 'components/tables/learner-stats/elements';
import { Color } from 'core';
import { useLearnersTableData } from 'hooks/useLearnersTableData';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { GoalProgressCell } from 'sets/components/GoalProgressCell';
import { TAssignment } from 'types';
import convertProgressToInteger from 'utils/convertProgressToInteger';
import { formatStringForSortComparison } from 'utils/tableUtils';
import { transformMilliseconds } from 'utils/timeUtils';
import { LearnersTableState, useLearnersTableStore } from 'zstore';

interface AssignmentLearnersTableProps {
  courseId: string;
  assignment: TAssignment;
}

export const AssignmentLearnersTable = (props: AssignmentLearnersTableProps) => {
  const { courseId, assignment } = props;

  return <Container toolbar={<LearnersTableToolbar />} data={<Data courseId={courseId} assignment={assignment} />} />;
};

interface DataProps {
  courseId: string;
  assignment: TAssignment;
}

const Data = (props: DataProps) => {
  const { courseId, assignment } = props;

  const pageSize = 10;
  const page = useLearnersTableStore((state: LearnersTableState) => state.page);
  const setPage = useLearnersTableStore((state: LearnersTableState) => state.setPage);
  const sort = useLearnersTableStore((state: LearnersTableState) => state.sort);
  const setSort = useLearnersTableStore((state: LearnersTableState) => state.setSort);
  const sortDirection = useLearnersTableStore((state: LearnersTableState) => state.sortDirection);
  const setSortDirection = useLearnersTableStore((state: LearnersTableState) => state.setSortDirection);

  const { isLoading, data: learnerStats, totalCount, totalPages } = useLearnersTableData(courseId, assignment.id);

  const renderReadinessCell = (row: any) => {
    return (
      <ReadinessBadge readinessScore={row.original.readiness} style={{ fontSize: '16px', color: Color.reportGray }} />
    );
  };

  const [columns, setColumns] = useState<ColumnDef<any>[]>([]);

  const COLUMNS = {
    NAME: {
      id: 'name',
      accessorFn: (row) => row.user.name,
      header: () => <ColumnHeader title="Name" />,
    },
    PROGRESS: {
      id: 'progress',
      accessorFn: (row: any) => row.progress,
      cell: (props: any) => (
        <GoalProgressCell progress={props.row.original.progress} goalReachedAt={props.row.original.goalReachedAt} />
      ),
      header: () => <ColumnHeader title="Progress to Goal" />,
    },
    LAST_VISIT: {
      id: 'lastVisit',
      accessorFn: (row) => (row.lastStudyTime ? moment(row.lastStudyTime).fromNow() : ''),
      header: () => <ColumnHeader title="Last Studied" />,
    },
    TOTAL_TIME: {
      id: 'totalTime',
      accessorFn: (row) => transformMilliseconds(row.totalStudyTimeMillis, 'total'),
      header: () => <ColumnHeader title="Total Study Time" />,
    },
    READINESS: {
      id: 'readiness',
      accessorFn: (row: any) => row.readiness,
      cell: (props: any) => renderReadinessCell(props.row),
      header: () => <ColumnHeader title="Readiness" />,
    },
    PERCENT_STUDIED: {
      id: 'percentStudied',
      accessorFn: (row) => convertProgressToInteger(row.percentStarted).toString().concat('%'),
      header: () => <ColumnHeader title="Studied" rightAlign={true} />,
    },
    PERCENT_REVIEWED: {
      id: 'percentReviewed',
      accessorFn: (row) => convertProgressToInteger(row.percentReviewed).toString().concat('%'),
      header: () => <ColumnHeader title="Reviewed" rightAlign={true} />,
    },
  };

  const assignmentColumns = [
    COLUMNS.NAME,
    COLUMNS.PROGRESS,
    COLUMNS.LAST_VISIT,
    COLUMNS.TOTAL_TIME,
    COLUMNS.READINESS,
    COLUMNS.PERCENT_STUDIED,
    COLUMNS.PERCENT_REVIEWED,
  ];

  const defaultColumns = [
    COLUMNS.NAME,
    COLUMNS.PROGRESS,
    COLUMNS.LAST_VISIT,
    COLUMNS.TOTAL_TIME,
    COLUMNS.PERCENT_STUDIED,
    COLUMNS.PERCENT_REVIEWED,
  ];

  const determineColumns = () => {
    switch (assignment.goalType) {
      case 'set':
        setColumns(assignmentColumns);
        break;
      // This case should only be hit for SCORM
      default:
        setColumns(defaultColumns);
    }
  };

  useEffect(() => {
    determineColumns();
  }, [JSON.stringify(learnerStats)]);

  const table = useReactTable({
    data: learnerStats,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const toggleSortDirection = (): void => {
    if (sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
  };

  const handleHeaderClick = (e: any): void => {
    // TODO: Ensure this approach works with localization ... or fix it
    const header = formatStringForSortComparison(e.currentTarget.innerText);
    if (header === 'readiness') {
      return;
    }
    setPage(1);
    if (header === sort) {
      toggleSortDirection();
    } else {
      setSort(header);
      setSortDirection('asc');
    }
  };

  const goToPageOne = () => {
    setPage(1);
  };

  const goToNextPage = () => {
    if (!!totalPages && page < totalPages) {
      setPage(page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToLastPage = () => {
    if (!!totalPages) {
      setPage(totalPages);
    }
  };

  // Show skeleton UI while waiting for table data
  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={650} />;
  }

  return (
    <>
      <DataContainer>
        <DataTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} rightAlignedColumnIds={['percentStudied', 'percentReviewed']} />
        </DataTable>

        {!isLoading && learnerStats.length === 0 && (
          <p style={{ textAlign: 'center', margin: '20px' }}>No records to display.</p>
        )}

        <Pagination
          totalCount={totalCount}
          page={page}
          pageSize={pageSize}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </DataContainer>
    </>
  );
};
