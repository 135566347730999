import Skeleton from '@material-ui/lab/Skeleton';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { LearnersTableToolbar } from 'components/LearnersTableToolbar';
import { Pagination } from 'components/reports/controls/Pagination';
import { ColumnHeader } from 'components/tables/learner-stats/ColumnHeader';
import { Container } from 'components/tables/learner-stats/Container';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { DataContainer, DataTable } from 'components/tables/learner-stats/elements';
import { useLearnersTableData } from 'hooks/useLearnersTableData';
import moment from 'moment';
import { TAssignment } from 'types';
import { formatStringForSortComparison } from 'utils/tableUtils';
import { LearnersTableState, useLearnersTableStore } from 'zstore';

interface AssessmentLearnersTableProps {
  courseId: string;
  assessment: TAssignment;
}

export const AssessmentLearnersTable = (props: AssessmentLearnersTableProps) => {
  const { courseId, assessment } = props;

  return <Container toolbar={<LearnersTableToolbar />} data={<Data courseId={courseId} assessment={assessment} />} />;
};

interface DataProps {
  courseId: string;
  assessment: TAssignment;
}

const Data = (props: DataProps) => {
  const { courseId, assessment } = props;

  const pageSize = 10;
  const page = useLearnersTableStore((state: LearnersTableState) => state.page);
  const setPage = useLearnersTableStore((state: LearnersTableState) => state.setPage);
  const sort = useLearnersTableStore((state: LearnersTableState) => state.sort);
  const setSort = useLearnersTableStore((state: LearnersTableState) => state.setSort);
  const sortDirection = useLearnersTableStore((state: LearnersTableState) => state.sortDirection);
  const setSortDirection = useLearnersTableStore((state: LearnersTableState) => state.setSortDirection);

  const { isLoading, data: learnerStats, totalCount, totalPages } = useLearnersTableData(courseId, assessment.id);

  const columns: ColumnDef<any>[] = [
    {
      id: 'name',
      accessorFn: (row) => row.user.name,
      header: () => <ColumnHeader title="Name" />,
    },
    {
      id: 'completedAt',
      accessorFn: (row) => (row.goalReachedAt ? moment(row.goalReachedAt).format('MMMM DD YYYY, h:mm a') : ''),
      header: () => <ColumnHeader title="Completed At" />,
    },
    {
      id: 'assessmentScore',
      accessorFn: (row: any) => (row.assessmentScore ? `${row.assessmentScore}%` : ''),
      header: () => <ColumnHeader title="Assessment Score" rightAlign={true} />,
    },
  ];

  const table = useReactTable({
    data: learnerStats,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const toggleSortDirection = (): void => {
    if (sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
  };

  const handleHeaderClick = (e: any): void => {
    // TODO: Ensure this approach works with localization ... or fix it
    const header = formatStringForSortComparison(e.currentTarget.innerText);
    setPage(1);
    if (header === sort) {
      toggleSortDirection();
    } else {
      setSort(header);
      setSortDirection('asc');
    }
  };

  const goToPageOne = () => {
    setPage(1);
  };

  const goToNextPage = () => {
    if (!!totalPages && page < totalPages) {
      setPage(page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToLastPage = () => {
    if (!!totalPages) {
      setPage(totalPages);
    }
  };

  // Show skeleton UI while waiting for table data
  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={650} />;
  }

  return (
    <>
      <DataContainer>
        <DataTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} rightAlignedColumnIds={['assessmentScore']} extraPadding={true} />
        </DataTable>

        {!isLoading && learnerStats.length === 0 && (
          <p style={{ textAlign: 'center', margin: '20px' }}>No records to display.</p>
        )}

        <Pagination
          totalCount={totalCount}
          page={page}
          pageSize={pageSize}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </DataContainer>
    </>
  );
};
