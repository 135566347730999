import { Link } from 'react-router-dom';

type StandardLinkProps = {
  className?: string;
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
  hardRedirect?: boolean;
};

export const StandardLink: React.FC<StandardLinkProps> = (props: StandardLinkProps) => {
  const { className = '', to = '#', onClick = () => {}, children, hardRedirect = false } = props;
  const fullClassName = `underline text-gray-800 hover:text-gray-500 ${className}`;
  return hardRedirect ? (
    <a href={to} onClick={onClick} className={fullClassName}>
      {children}
    </a>
  ) : (
    <Link to={to} onClick={onClick} className={fullClassName}>
      {children}
    </Link>
  );
};
