import { AttachIc } from 'components/item-editor/AttachIc';
import { CautionTape } from 'components/item-editor/CautionTape';
import { AnswersFormFields } from 'components/item-editor/AnswersFormFields';
import { GraduatedDifficultyFormFields } from 'components/item-editor/GraduatedDifficultyFormFields';
import { useItemEditorStore } from 'components/item-editor/store';
import styled from 'styled-components';
import { FlashcardFrontFormFields } from './FlashcardFrontFormFields';
import { FlashcardBacksFormFields } from './FlashcardBacksFormFields';

export const FlashcardEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <Container>
      <FlashcardFrontFormFields />
      <FlashcardBacksFormFields />
      <CautionTape />
      <AttachIc />
      <GraduatedDifficultyFormFields />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
