import { ThemeProvider } from '@mui/material';
import { MemreFlexBox, MemrePageContainer } from 'components/core';
import { Header } from 'pages/admin-dashboard/people/Header';
import { MemberIdDownloadBanner } from 'pages/admin-dashboard/people/MemberIdDownloadBanner';
import { Table, TableToolbar } from 'pages/admin-dashboard/people/Table';
import theme from 'pages/learner-dashboard/visulisations/theme';

export const PeoplePage = () => {
  return (
    <ThemeProvider theme={theme}>
      <MemrePageContainer>
        <MemreFlexBox gap={4} direction="column" sx={{ padding: '20px' }}>
          <Header />
          <MemberIdDownloadBanner />
          <div>
            <TableToolbar />
            <Table />
          </div>
        </MemreFlexBox>
      </MemrePageContainer>
    </ThemeProvider>
  );
};
