import { COLOR } from '../constants';

export default function Gradient({
  id,
  endColor = COLOR.BLUE,
  startColor = COLOR.TRANSPARENT,
}: {
  id: string;
  endColor?: string;
  startColor?: string;
}): JSX.Element {
  return (
    <svg width="0" height="0">
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{
              stopColor: startColor,
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: endColor,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
