import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

const deleteAnnotation = (id: string) => {
  return axios.delete(`/api/v3/annotations/${id}`);
};

export const useDeleteAnnotation = (itemId: string, setId: string) => {
  const { makeToast } = useStore();

  return useMutation(deleteAnnotation, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
