import { keyframes } from 'styled-components';

export const bounceInLeft = keyframes`
   0% {
    opacity: 0;
    left: -100%;
   };
   40% {
      opacity: 1;
      left: 0;
   };
   60% {
      left: -20%;
   };
   70% {
      left: 0;
   };
   80% {
      left: -10%;
   };
   100% {
      left: 0;
   };
`;

export const autohide = keyframes`{
  0% {
    opacity: 1;
  };
  100% {
    opacity: 0;
  };
}`;
