import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as Color from './colors';

export const BaseSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 2,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: Color.ceregoGreen,
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: Color.ceregoGreen,
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 20,
    height: 20
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {}
}))(Switch);
