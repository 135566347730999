import { useRef, useState } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { InlineButton } from 'components/buttons/v4';
import { useTranslation } from 'react-i18next';

interface Props {
  file: string;
}

export const MultipagePdf = (props: Props) => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const { pdfDocument } = usePdf({
    file: props.file,
    page,
    canvasRef,
  });

  return (
    <div>
      {!pdfDocument && <span>{t('Loading...')}</span>}
      <canvas ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav>
          <ul className="pager">
            <li className="previous">
              <InlineButton disabled={page === 1} click={() => setPage(page - 1)}>
                {t('Previous')}
              </InlineButton>
            </li>
            <li className="next">
              <InlineButton disabled={page === pdfDocument?.numPages} click={() => setPage(page + 1)}>
                {t('Next')}
              </InlineButton>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};
