import { IImage } from 'models/image';
import { IPartner } from 'models/partner';
import moment from 'moment';

export type GoalType = 'set' | 'assessment' | 'survey' | 'scorm';
export type AssignmentStatus = 'Published' | 'Unpublished' | 'Scheduled' | 'Gated' | 'Building' | 'Error';

export interface ISet {
  id: string;
  description: string;
  name: string;
  goalType: GoalType;
  creator?: IPartner; // Could also be IUser if there's no partner, but that shouldn't happen in V4Nav.
  image: IImage;
  learnVersion: 3 | 4;
  isShared: boolean;
  itemsCount: number;
  meta?: any;
  type?: string;
  readiness?: number;
}

export const dueBeforePublishDate = (dueDate: string | null, publishDate: string | null): boolean => {
  // You ain't due before the publish date if we ain't sure on one count or the other
  if (!dueDate || !publishDate) return false;
  return moment(dueDate).isBefore(publishDate);
};
