import { useState } from 'react';
import { performActionWithConfirmation } from '../siteAdminUtils';
import axios from 'axios';
import React from 'react';

export function SiteAdminUserDetailLibrary(props: any) {
  const { user } = props;
  const [showLibrary, setShowLibrary] = useState(false);

  const decimalToPercentage = (decimal) => {
    if (typeof decimal !== 'number') return '0%';
    return `${(decimal * 100).toFixed(2)}%`;
  };

  const lastLtiResultForSubscription = (subscription) => {
    const ltiResults = subscription.ltiResults;
    return ltiResults[ltiResults.length - 1];
  };

  const enqueueGradePassback = async () => {
    await performActionWithConfirmation(`Are you sure you want to enqueue grade passbacks?`, async () => {
      await axios.post('/api/v2/admin/lti/enqueue_grade_passback_jobs', { user_id: user.id, debug: true });
      window.alert('Grades have been sent, LMS should receive them within 1-2 minutes');
    });
  };

  return (
    <article>
      <h2>
        <a
          href="#"
          className="alt-button"
          onClick={(e) => {
            e.preventDefault();
            setShowLibrary(!showLibrary);
          }}
        >
          <span>
            {showLibrary ? '-' : '+'} Library ({user.goalSettings.length} sets, {user.goalListSubscribers.length}{' '}
            series)
          </span>
        </a>
      </h2>

      {showLibrary && (
        <article>
          {process.env.NODE_ENV === 'production' && (
            <div>
              <p>The below button will enqueue grade passback jobs.</p>
              <button onClick={enqueueGradePassback}>
                Enqueues a grade passback job for every LtiResult for this user
              </button>
            </div>
          )}
          <h2>
            <span>Sets in library ({user.goalSettings.length})</span>
          </h2>
          <div>
            <table style={{ textAlign: 'left' }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Progress</th>
                  <th>Goal</th>
                  <th>Percent Started</th>
                  <th>Last Study</th>
                  <th>In Library</th>
                </tr>
                <tr style={{ backgroundColor: '#f0f0f0' }}>
                  <th>Grade SourcedID</th>
                  <th>Grade Message</th>
                  <th>Grade</th>
                  <th>Grade Updated</th>
                  <th>Grade Attempt</th>
                  <th>LtiResult Created</th>
                  <th>LtiResult ID</th>
                </tr>
              </thead>
              <tbody>
                {user.goalSettings.map((goalSetting) => (
                  <React.Fragment key={goalSetting.id}>
                    <tr>
                      <td>
                        <a href={`#`} target="_blank" rel="noopener noreferrer">
                          {goalSetting.goalId}
                        </a>
                      </td>
                      <td>{goalSetting.goalName}</td>
                      <td>{decimalToPercentage(goalSetting.progress)}</td>
                      <td>{goalSetting.levelGoal}</td>
                      <td>{decimalToPercentage(goalSetting.percentStarted)}</td>
                      <td>{goalSetting.lastStudyTime}</td>
                      <td>{goalSetting.librarySubscriptionState}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#f0f0f0' }}>
                      <td>{lastLtiResultForSubscription(goalSetting)?.lisResultSourcedid}</td>
                      <td>{lastLtiResultForSubscription(goalSetting)?.lastDescription}</td>
                      <td>{lastLtiResultForSubscription(goalSetting)?.lastScore}</td>
                      <td>{lastLtiResultForSubscription(goalSetting)?.lastSuccessAt}</td>
                      <td>{lastLtiResultForSubscription(goalSetting)?.lastFailureAt}</td>
                      <td>{lastLtiResultForSubscription(goalSetting)?.createdAt}</td>
                      <td>{lastLtiResultForSubscription(goalSetting)?.id}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      )}
    </article>
  );
}
