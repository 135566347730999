import SecondaryButton from 'components/buttons/v4/SecondaryButton';
import * as Color from 'core/colors';
import * as CommonButton from 'core/v4button';
import React from 'react';
import styled from 'styled-components';

const ButtonElement = styled(SecondaryButton).attrs(
  ({ shape = 'pill', context = 'light', size = 'small', disabled = false }: CommonButton.IButton) => {
    const defaultStyles: any = {
      borderColor: Color.primaryBlue,
      color: Color.primaryBlue,
      backgroundColor: Color.primaryGray,
      hoverBackgroundColor: Color.primaryBlue,
      hoverColor: Color.primaryBlue
    };
    const attributeStyles: any = {
      ...CommonButton.BaseStyles
    };
    let stateStyles: any = {};

    return {
      ...defaultStyles,
      ...attributeStyles[shape],
      ...attributeStyles[context],
      ...attributeStyles[size],
      ...stateStyles
    };
  }
)`
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  color: ${props => props.color};
  tab-index: ${props => props.tabIndex};

  :hover {
    background-color: ${props => (props.disabled ? props.backgroundColor : props.hoverBackgroundColor)};
    color: ${props => (props.disabled ? props.color : props.hoverColor)};
  }
`;

function InlineButton({ type = 'button', ...props }: CommonButton.IButton) {
  const { children, click } = props;

  return (
    <ButtonElement type={type} onClick={click} {...props}>
      <span>{children}</span>
    </ButtonElement>
  );
}

export default InlineButton;
