import { MemreFlexBox, MemreText } from 'components/core';
import { ReactNode } from 'react';

type Props = {
  overline?: string;
  heading?: string | ReactNode;
};
export default function AreaHeading({ overline, heading }: Props) {
  return (
    <MemreFlexBox gap={0.3} direction="column">
      <MemreText variant="overline">{overline}</MemreText>
      <MemreText variant="h6">{heading}</MemreText>
    </MemreFlexBox>
  );
}
