import { flexRender } from '@tanstack/react-table';
import { Color } from 'core';
import React from 'react';

// TODO: Properly type table prop as instance of React Table
interface Props {
  table: any;
  handleHeaderClick: (e: any) => void;
  clickableHeaders?: Array<string>;
}

export const TableHeader = (props: Props) => {
  const { table, handleHeaderClick, clickableHeaders } = props;

  return (
    <thead style={{ backgroundColor: Color.grayTransparent }}>
      {table.getHeaderGroups().map((headerGroup: any) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header: any) => (
            <th
              key={header.id}
              colSpan={header.colSpan}
              style={{
                padding: '2px 20px',
                cursor: !!clickableHeaders && clickableHeaders.includes(header.id) ? 'pointer' : 'inherit',
              }}
              onClick={handleHeaderClick}
            >
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
