export default function toTitleCase(str: string): string {
  const LOWER_CASE_WORDS = [
    'a',
    'an',
    'the',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'at',
    'by',
    'from',
    'in',
    'of',
    'on',
    'to',
    'with',
  ];

  return !str
    ? ''
    : str
        .toLowerCase()
        .split(' ')
        .map((word, index) => {
          if (index === 0 || !LOWER_CASE_WORDS.includes(word)) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }
          return word;
        })
        .join(' ');
}
