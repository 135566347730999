import * as React from 'react';
import Sound from 'react-sound';

import { SmallSoundButton, SoundButton } from 'components/buttons';

interface IOwnProps {
  src: string;
  size?: string;
}

interface IOwnState {
  playStatus: Sound.status;
}

export default class AudioPlayer extends React.PureComponent<IOwnProps, IOwnState> {
  private timer: any;
  private progress: number;
  private progressBar: any;
  private duration: any;

  constructor(props) {
    super(props);
    this.state = { playStatus: Sound.status.STOPPED };
    this.duration = 0;
    this.progress = 0;
    this.progressBar = React.createRef();
    this.soundClicked = this.soundClicked.bind(this);
  }

  public render() {
    const { playStatus } = this.state;
    switch (this.props.size) {
      case 'small':
        return (
          <React.Fragment>
            <SmallSoundButton
              targetRef={this.progressBar}
              click={this.soundClicked}
              playStatus={this.state.playStatus}
              soundStatus={Sound.status}
            />
            {this.props.src && (
              <Sound
                url={this.props.src}
                playStatus={playStatus}
                onLoading={this.getDurationData}
                onPlaying={this.updateProgress}
                onPause={this.pauseProgress}
                onFinishedPlaying={this.finishedPlaying}
              />
            )}
          </React.Fragment>
        );
      default:
        return (
          <div>
            <SoundButton click={this.soundClicked} />
            {this.props.src && <Sound url={this.props.src} playStatus={playStatus} />}
          </div>
        );
    }
  }

  private pauseProgress = () => {
    clearInterval(this.timer);
  };

  private updateProgressUI = percent => {
    const circle = this.progressBar.current;
    const r = parseInt(circle.attributes.r.value, undefined);
    const c = 500 - Math.PI * (r * 2);
    const offset = 500 - (500 - c) * (percent / 100);
    circle.style.strokeDashoffset = offset;
  };

  private finishedPlaying = () => {
    const playStatus = Sound.status.STOPPED;
    this.setState({ playStatus });
  };

  private getDurationData = bytesLoaded => {
    this.duration = bytesLoaded.duration;
  };

  private updateProgress = sound => {
    const position = Math.ceil(sound.position);
    const duration = Math.ceil(sound.duration);

    this.progress = Math.ceil((position * 100) / duration);
    this.updateProgressUI(this.progress);
  };

  private soundClicked = () => {
    // TODO: change UI based on play/paused/stopped
    let playStatus = Sound.status.PLAYING;
    switch (this.state.playStatus) {
      case Sound.status.PLAYING:
        playStatus = Sound.status.PAUSED;
        break;
      case Sound.status.STOPPED:
        playStatus = Sound.status.PLAYING;
        break;
      default:
        break;
    }
    this.setState({ playStatus });
  };
}
