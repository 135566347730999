import axios from 'axios';
import { useGroupMembersStore } from 'pages/admin-dashboard/groups/store';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

type DeleteGroupUserParams = {
  groupId: string;
  userId: string;
};

const deleteGroupUser = (params: DeleteGroupUserParams) => {
  return axios.delete(`/api/v3/groups/${params.groupId}/users/${params.userId}`);
};

export const useDeleteGroupUser = (groupId: string) => {
  const { makeToast } = useStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupMembersStore();

  return useMutation(deleteGroupUser, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to remove user from group',
        isError: true,
      });
    },
    onSuccess: () => {
      makeToast({
        text: 'User successfully removed from group',
        isError: false,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['groupMembers', groupId, pageNumber, pageSize, sortColumn, sortDirection, name]);
    },
  });
};
