import learnerBubbles from 'assets/images/learner-bubbles.svg';
import { Restricted } from 'components/Restricted';
import { primaryBlack } from 'core/colors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getBaseAngularUrl } from 'utils/pathUtils';

interface IProps {
  courseId: string;
}

const Link = styled.a`
  color: ${primaryBlack};
  font-size: 0.9rem;

  span {
    vertical-align: middle;
    margin-left: 5px;
  }
  img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }
`;

export const KnowledgeBankLink = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <Restricted
      allowedRoles={['admin', 'course manager', 'instructor', 'learner', 'course viewer']}
      component={
        <Link
          href={`${getBaseAngularUrl()}/courses/${props.courseId}/analytics?navigationOverride=v3&isV4Embed=1`}
          style={{ textDecoration: 'none' }}
          target={'_blank'}
        >
          <img src={learnerBubbles} />
          <span>{t('Knowledge Bank')}</span>
        </Link>
      }
    />
  );
};
