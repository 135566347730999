import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ModalPosition } from 'components/Modal';
import { ModalHeader } from 'components/modals/common';
import { Color } from 'core';
import { Card, PageTitle } from 'core/layout';
import { updateAccount } from 'store/account';

interface IProps {
  onCancel: () => void;
  purchasedSeats: number;
  occupiedSeats: number;
  partnerId: string;
  interval: string;
  pricePerSeatInPennies: number;
  minUsers: number;
}

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const UpdateLicenseGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 0; // override
`;

const RightDiv = styled.div`
  padding: 1.5em;
  background: linear-gradient(-45deg, ${Color.darkGradientGreen}, ${Color.lightGradientGreen});
  color: ${Color.white};
`;

const TopDiv = styled.div`
  border-bottom: 1px solid ${Color.veryLightPurple};
  margin-bottom: 0.125em;
  padding: 1.5em;
`;

const BottomDiv = styled.div`
  background: linear-gradient(${Color.gradientPurple}, ${Color.white} 15%);
  border-top: 1px solid ${Color.veryLightPurple};
  padding: 1.5em;
`;

const LineItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
`;

const Operator = styled.div`
  font-size: 1.5em;
  color: ${Color.reportGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Unit = styled.div`
  color: ${Color.reportGray};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Amount = styled.div`
  font-size: 3em;
  font-weight: bold;
  text-align: right;
  color: ${Color.primaryBlack};
`;

const SeatNumber = styled.div`
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0.125em;
`;

const NoteLabel = styled.div`
  margin-top: 1.5em;
`;

const NoteText = styled.div`
  line-height: 1.5em;
`;

const BilledAnnually = styled.div`
  text-align: right;
`;

const SeatGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
`;

const SeatInputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3em;
  grid-gap: 0em 1em;
`;

const SeatLabelBox = styled.div`
  display: flex;
`;

const SeatInput = styled.input`
  background: none;
  border: none;
  outline: none;
  color: ${Color.primaryBlack};
  width: 100%;
  text-align: right;
  font-weight: bold;
  font-family: inherit;
  font-size: 3em;
  padding: 0.5rem;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const ErrorMessage = styled.div`
  color: ${Color.red};
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding-left: 1em;
`;

const GreyText = styled.div`
  color: ${Color.reportGray};
`;

const AddSeats = styled.div`
  color: ${Color.primaryBlue};
  cursor: pointer;
  text-align: right;
  padding: 0.5em;
`;

const Container = styled.div`
  box-shadow: inset 0 1px 0.25em 0 rgba(0, 0, 0, 0.3);
  border: 2px solid transparent;
  display: grid;
  color: ${Color.secondaryGray};
  border-radius: 0.25em;
  margin: 0.25em 0 1em 0;
`;

export const UpdateLicensing = (props: IProps) => {
  const [seats, setSeats] = useState(props.purchasedSeats);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dollarsPerSeat = props.pricePerSeatInPennies / 100;
  const monthlyDollarsPerSeat = props.interval === 'yearly' ? dollarsPerSeat / 12 : dollarsPerSeat;

  function incrementSeatsBy(num) {
    setSeats(seats + num);
  }

  function noteText() {
    return {
      yearly: t(
        'Increasing the number of seats beyond a previous invoice will generate a prorated charge to be incurred immediately.'
      ),
      monthly: t(
        'Increasing the number of seats beyond a previous invoice will generate a prorated charge to be incurred at the beginning of the following billing cycle.'
      )
    }[props.interval];
  }

  function errorMessage() {
    if (seats < props.minUsers) {
      return t('Update Licensing Modal Minimum Error Text', { count: props.minUsers });
    } else if (seats < props.occupiedSeats) {
      return t('You cannot select seat quantity lower than currently in use.');
    }
  }

  function updateLicense() {
    dispatch(updateAccount({ license_quantity: seats }, props.partnerId));
    props.onCancel();
  }

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>
          <b>{t('Update Licensing')}</b>
        </CenterTitle>
      </ModalHeader>

      <Card>
        <UpdateLicenseGrid>
          <div>
            <TopDiv>
              <SeatGrid>
                <div />
                <div>
                  <SeatLabelBox>
                    <GreyText>{t('SEATS:')}</GreyText>
                    <ErrorMessage>{errorMessage()}</ErrorMessage>
                  </SeatLabelBox>
                  <Container style={errorMessage() ? { border: `2px solid ${Color.red}`, boxShadow: 'none' } : {}}>
                    <SeatInputGrid>
                      <div>
                        <SeatInput
                          type="number"
                          value={seats.toString().replace(/^0+/, '')}
                          onChange={e => {
                            setSeats(parseInt(e.target.value) || 0);
                          }}
                        />
                      </div>
                      <div>
                        <AddSeats
                          style={{ borderBottom: `1px solid ${Color.veryLightPurple}` }}
                          onClick={() => incrementSeatsBy(5)}
                        >
                          <b>{t('+5')}</b>
                        </AddSeats>
                        <AddSeats onClick={() => incrementSeatsBy(10)}>
                          <b>{t('+10')}</b>
                        </AddSeats>
                      </div>
                    </SeatInputGrid>
                  </Container>
                </div>
              </SeatGrid>
              <LineItemGrid>
                <Operator>
                  <div>x</div>
                </Operator>
                <Unit>
                  <div>
                    <i>{t('per')}</i>
                  </div>
                  <div>{t('SEAT RATE:')}</div>
                </Unit>
                <Amount>${monthlyDollarsPerSeat.toFixed(2)}</Amount>
              </LineItemGrid>
            </TopDiv>
            <BottomDiv>
              <LineItemGrid>
                <Operator>=</Operator>
                <Unit>
                  <div>
                    <i>{t('new per')}</i>
                  </div>
                  <div>{t('MONTH RATE:')}</div>
                </Unit>
                <Amount>${(seats * monthlyDollarsPerSeat).toFixed(2)}</Amount>
              </LineItemGrid>
              {props.interval === 'yearly' && (
                <BilledAnnually>
                  <b>${(seats * monthlyDollarsPerSeat * 12).toFixed(2)}</b> {t('billed annually')}
                </BilledAnnually>
              )}
            </BottomDiv>
          </div>
          <RightDiv>
            <div>{t('CURRENT SEATS:')}</div>
            <SeatNumber>{props.purchasedSeats}</SeatNumber>
            <div>{t('OCCUPIED SEATS:')}</div>
            <SeatNumber>{props.occupiedSeats}</SeatNumber>
            <NoteLabel>{t('NOTE:')}</NoteLabel>
            <NoteText>{noteText()}</NoteText>
          </RightDiv>
        </UpdateLicenseGrid>
      </Card>

      <ConfirmOrCancel
        confirmText={t('Update')}
        handleConfirmClick={updateLicense}
        confirmIsDisabled={!!errorMessage()}
        confirmIsPending={false}
        handleCancelClick={props.onCancel}
      />
    </ModalPosition>
  );
};
