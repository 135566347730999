import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import { AnnotationProps } from 'components/item-editor/instructional-content/Annotation';
import { useIcEditorStore } from 'components/item-editor/instructional-content/store';
import { useDeleteAnnotation } from 'hooks/useDeleteAnnotation';
import { useUpdateAnnotation } from 'hooks/useUpdateAnnotation';

export const AnnotationActions = (props: AnnotationProps) => {
  const { annotation, itemId, setId } = props;
  const { icAnnotations, deleteIcAnnotation, swapAnnotationPositions } = useIcEditorStore();
  const [deleteAnnotation] = useDeleteAnnotation(itemId, setId);
  const [updateAnnotation] = useUpdateAnnotation(itemId, setId);

  const handleMoveUp = async () => {
    if (annotation.position === 1) {
      return;
    }

    const payload = {
      data: {
        attributes: {
          position: annotation.position - 1,
        },
      },
    };

    try {
      await updateAnnotation({ id: annotation.id, payload });
      swapAnnotationPositions(annotation.id, 'up');
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleMoveDown = async () => {
    if (annotation.position === icAnnotations.length) {
      return;
    }

    const payload = {
      data: {
        attributes: {
          position: annotation.position + 1,
        },
      },
    };

    try {
      await updateAnnotation({ id: annotation.id, payload });
      swapAnnotationPositions(annotation.id, 'down');
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleDeleteAnnotation = async () => {
    try {
      await deleteAnnotation(annotation.id);
      deleteIcAnnotation(annotation.id);
    } catch (error) {
      console.error('Error deleting annotation:', error);
    }
  };

  return (
    <CardActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
      <IconButton onClick={handleMoveUp}>
        <ArrowUpward />
      </IconButton>
      <IconButton onClick={handleMoveDown}>
        <ArrowDownward />
      </IconButton>
      <IconButton onClick={handleDeleteAnnotation}>
        <DeleteIcon />
      </IconButton>
    </CardActions>
  );
};
