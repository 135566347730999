import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';

type UserMetricsParams = {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  metric: string;
  partnerId: string;
  query?: string;
};

export const getUserMetrics = async (_key: string, params: UserMetricsParams) => {
  const { partnerId, pageNumber, pageSize, sortBy, metric, query } = params;

  if (!partnerId) {
    console.warn('Cannot get user metrics without partnerId');
    return;
  }

  let endpoint = `/api/v3/user_metrics?include=user,user.image&page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sortBy}&tab=${metric}&partner_id=${partnerId}`;
  if (query) {
    endpoint += `&query=${query}`;
  }

  const res = await axios.get(endpoint);

  return res;
};

type UserMetricsHookParams = {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  metric: string;
  query?: string;
};

export const useUserMetrics = (hookParams: UserMetricsHookParams) => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const { pageNumber, pageSize, sortBy, metric, query } = hookParams;
  const params = { pageNumber, pageSize, sortBy, metric, partnerId: partner?.id || '', query };

  return useQuery(['userMetrics', params], getUserMetrics, {
    enabled: !!partner?.id,
  });
};
