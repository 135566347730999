import axios from 'axios';
import { useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

interface CreatePartnerDomainPayload {
  partner_id: number;
  domain_name: string;
}

const createPartnerDomain = async ({ id, payload }: { id: string; payload: CreatePartnerDomainPayload }) => {
  const response = await axios.post(`/api/v3/partners/${id}/partner_domains`, payload);
  return normalizeJSONAPIResponse(response.data);
};

export const useCreatePartnerDomain = () => {
  const { makeToast } = useStore();

  return useMutation(createPartnerDomain, {
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
