import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import correctIcon from 'assets/images/correct.svg';
import incorrectIcon from 'assets/images/incorrect.svg';
import { Color, Size } from 'core';
import { hoverShadow } from 'core/v4button';
import { isRightToLeft } from 'i18n';
import SanitizedHtml from 'learn/components/SanitizedHtml';
import ZoomableImageContainer from 'learn/components/ZoomableImageContainer';
import { IQuizPart } from 'models/quiz';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type MultipleChoiceAnswerStyle = 'selected' | 'unselected' | 'correct' | 'incorrect' | 'missed';

type StyleProps = {
  rightToLeft?: boolean;
  isSubmitted?: boolean;
};

const ContainerRow = styled.div<StyleProps>`
  display: flex;
  flex-direction: ${(props) => (props.rightToLeft ? 'row-reverse' : 'row')};
  align-items: center;
`;

const Container = styled.button<StyleProps>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  flex: 1 1 auto;
  overflow-y: auto;
  max-width: ${Size.defaultCardWidth}px;
  margin: 0px auto;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-family: inherit;
  font-size: 16px;

  &:hover,
  &:focus {
    background-color: ${(props) => (props.isSubmitted ? Color.white : Color.learnHoverGray)};
    box-shadow: ${(props) => (props.isSubmitted ? 'none' : hoverShadow)};
  }
`;

const ImageOnlyContainer = styled.button<StyleProps>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  flex: 1 1 auto;
  overflow-y: auto;
  max-width: ${Size.defaultCardWidth}px;
  margin: 0px auto;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${(props) => (props.isSubmitted ? Color.white : Color.learnHoverGray)};
    box-shadow: ${(props) => (props.isSubmitted ? 'none' : hoverShadow)};
  }
`;

const LargeImageContainer = styled.div`
  & img {
    height: 250px;
    cursor: pointer;
    display: flex;
  }
`;

const ImageContainer = styled.div<StyleProps>`
  & img {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    cursor: pointer;
    display: flex;
    padding-right: ${(props) => (props.rightToLeft ? '0' : '9px')};
    padding-left: ${(props) => (props.rightToLeft ? '9px' : '0')};
  }
`;

const IconImage = styled.img`
  width: 24px;
  height: 24px;
  padding: 12px;
  display: flex;
  flex: 0 0 auto;
`;

const ExplanationContainer = styled.div``;

const TextContainer = styled.div<StyleProps>`
  display: flex;
  flex-direction: ${(props) => (props.rightToLeft ? 'row-reverse' : 'column')};
`;

const ExplanationPrefix = styled.h4<StyleProps>`
  font-size: 14px;
  color: ${Color.darkGray};
  display: flex;
  margin 0 0 0 41px;
  margin-right: ${(props) => (props.rightToLeft ? '41px' : '0')}
  flex-direction: ${(props) => (props.rightToLeft ? 'row-reverse' : 'row')}
`;

const ExplanationText = styled.div`
  font-size: 14px;
  color: ${Color.slateGray};
  margin: 0 41px;
  padding: 0;
`;

const flexDirection: 'row-reverse' | undefined = isRightToLeft() ? 'row-reverse' : undefined;
// Material UI overrides
const styles = (_theme: any) => ({
  root: {
    border: 'none',
    boxShadow: 'none',
    padding: '0',
    margin: '0',
    background: 'none',
    minHeight: '0',
    display: isRightToLeft() ? 'flex' : undefined,
    flexDirection,
  },
  content: {
    padding: '0',
    margin: '0',
    '&.expanded': {
      margin: '0',
      marginTop: '0',
    },
    minHeight: '0',
  },
  expanded: {
    marginTop: '0 !important',
  },
});

const selectedBorder = {
  border: `solid 1px ${Color.mainBlue}`,
};
const unselectedBorderContainer = {
  border: `solid 1px ${Color.veryLightBlue}`,
};
const incorrectBorder = {
  border: `solid 2px ${Color.red}`,
};
const correctBorder = {
  border: `solid 2px ${Color.lightGreen}`,
};

const IconDisplay = ({ answerStyle }) => {
  let src = '';
  let alt = '';
  let type = '';

  if (answerStyle === 'incorrect') {
    src = incorrectIcon;
    alt = 'Incorrect';
    type = 'multiple-choice-incorrect-icon';
  }

  if (answerStyle === 'correct') {
    src = correctIcon;
    alt = 'Correct';
    type = 'multiple-choice-correct-icon';
  }

  if (!src) {
    return <></>;
  }

  return <IconImage data-type={type} src={src} alt={alt} />;
};

const Explanation = ({ answer, isSelected, isSubmitted }) => {
  const { t } = useTranslation();
  let prefixText = '';

  if (!answer.explanationHtml || !isSubmitted) {
    return <></>;
  }

  if (answer.isCorrect) {
    prefixText = isSelected ? t("Why it's right:") : t("Why it should've been selected:");
  } else if (isSelected) {
    prefixText = t("Why it's wrong:");
  }

  return (
    <ExplanationContainer style={{ margin: '15px 5px' }}>
      <ExplanationPrefix id="explanation-prefix">{prefixText}</ExplanationPrefix>
      <ExplanationText id="explanation-content">
        <SanitizedHtml source={answer.explanationHtml} />
      </ExplanationText>
    </ExplanationContainer>
  );
};

const ImageOnlyAnswer = ({ image, answer, answerStyle, onClick, style, isSelected, isSubmitted }) => {
  return (
    <ImageOnlyContainer onClick={onClick} style={style} tabIndex={isSubmitted ? -1 : 0} isSubmitted={isSubmitted}>
      <ContainerRow>
        <IconDisplay answerStyle={answerStyle} />
        <LargeImageContainer>
          <ZoomableImageContainer image={image} transcodedSize={'large'} />
        </LargeImageContainer>
      </ContainerRow>
      <Explanation answer={answer} isSelected={isSelected} isSubmitted={isSubmitted} />
    </ImageOnlyContainer>
  );
};

interface Props {
  answer: IQuizPart;
  onClick: any;
  isSelected: boolean;
  isSubmitted: boolean;
  answerStyle: MultipleChoiceAnswerStyle;
  isMultiSelect: boolean;
  classes?: any;
  imageOnly: boolean;
}

const MultipleChoiceAnswer: React.FC<Props> = ({
  answer,
  onClick,
  isSelected,
  isSubmitted,
  answerStyle,
  isMultiSelect,
  imageOnly,
  classes,
}) => {
  const { t } = useTranslation();
  const image = answer.image;
  const rightToLeft = isRightToLeft();

  let customStyle = unselectedBorderContainer;
  switch (answerStyle) {
    case 'selected':
      customStyle = selectedBorder;
      break;
    case 'missed':
    // fallthrough
    case 'correct':
      customStyle = correctBorder;
      break;
    case 'incorrect':
      customStyle = incorrectBorder;
      break;
    default:
      break;
  }

  const showIcon = answerStyle === 'correct' || answerStyle === 'incorrect';

  const imageOnlyContainerStyle = {
    margin: '10px',
    alignItems: 'center',
  };

  const containerStyle = Object.assign({}, customStyle, imageOnly ? imageOnlyContainerStyle : {});
  const regex = /(<([^>]+)>)/gi;

  const getContainerAriaLabel = (): string => {
    const actionText = answerStyle === 'selected' ? t('Unselect Answer') : t('Select Answer');

    if (!isSubmitted) {
      return `${actionText}: ${answer.textHtml?.replace(regex, '') || ''}`;
    }

    let ariaLabel = '';
    if (isSelected && answer.isCorrect) {
      ariaLabel += ` You picked the correct answer: ${answer.textHtml?.replace(regex, '')}`;

      if (answer.explanationHtml) {
        ariaLabel += ` Why it's right: ${answer.explanationHtml.replace(regex, '')}`;
      }
    } else if (isSelected && !answer.isCorrect) {
      ariaLabel += ` You picked an incorrect answer: ${answer.textHtml?.replace(regex, '')}`;

      if (answer.explanationHtml) {
        ariaLabel += ` Why it's wrong: ${answer.explanationHtml.replace(regex, '')}`;
      }
    } else if (!isSelected && answer.isCorrect) {
      ariaLabel += ` You should have picked this answer: ${answer.textHtml?.replace(regex, '')}`;

      if (answer.explanationHtml) {
        ariaLabel += ` Why it should've been selected: ${answer.explanationHtml.replace(regex, '')}`;
      }
    }

    return ariaLabel;
  };

  if (imageOnly && image) {
    return (
      <ImageOnlyAnswer
        image={image}
        answer={answer}
        answerStyle={answerStyle}
        onClick={() => onClick(answer)}
        style={containerStyle}
        isSelected={isSelected}
        isSubmitted={isSubmitted}
      />
    );
  }

  return (
    <Container
      onClick={() => onClick(answer)}
      style={containerStyle}
      tabIndex={isSubmitted ? -1 : 0}
      aria-label={getContainerAriaLabel()}
      aria-live="polite"
      aria-atomic="true"
      isSubmitted={isSubmitted}
    >
      <TextContainer aria-hidden="true" data-testid={answer.testId} rightToLeft={rightToLeft}>
        <Accordion
          expanded={!!answer.explanationHtml && isSubmitted}
          classes={{ root: classes.root, expanded: classes.expanded }}
        >
          <AccordionSummary
            classes={{
              content: classes.content,
              expanded: classes.expanded,
              root: classes.root,
            }}
            tabIndex={-1}
            role={'none'}
          >
            <ContainerRow rightToLeft={rightToLeft}>
              {!showIcon && !isMultiSelect && !imageOnly && (
                <Radio checked={!!isSelected} data-type="mc-radio-btn" tabIndex={-1} disabled={isSubmitted} />
              )}
              {answerStyle === 'incorrect' && (
                <IconImage data-type="multiple-choice-incorrect-icon" src={incorrectIcon} />
              )}
              {answerStyle === 'correct' && <IconImage data-type="multiple-choice-correct-icon" src={correctIcon} />}
              {!showIcon && isMultiSelect && (
                <Checkbox
                  checked={answerStyle === 'selected'}
                  style={{ padding: '12px' }}
                  data-type="checkbox"
                  tabIndex={-1}
                  disabled={isSubmitted}
                />
              )}
              {image && !imageOnly && (
                <ImageContainer rightToLeft={rightToLeft}>
                  <ZoomableImageContainer image={image} transcodedSize={'medium'} />
                </ImageContainer>
              )}
              <SanitizedHtml
                source={answer.textHtml}
                style={{
                  color: Color.slateGray,
                  flex: '1 1 auto',
                  margin: '3px 0 0 0',
                }}
              />
            </ContainerRow>
          </AccordionSummary>
          {answer.explanationHtml && (answer.isCorrect || isSelected) && (
            <AccordionDetails
              className={classes.content}
            >
              <ExplanationContainer>
                {answer.isCorrect && (
                  <ExplanationPrefix rightToLeft={rightToLeft} id="explanation-prefix">
                    {isSelected ? t("Why it's right:") : t("Why it should've been selected:")}
                  </ExplanationPrefix>
                )}
                {!answer.isCorrect && isSelected && (
                  <ExplanationPrefix rightToLeft={rightToLeft} id="explanation-prefix">
                    {' '}
                    {t("Why it's wrong:")}{' '}
                  </ExplanationPrefix>
                )}
                <ExplanationText id="explanation-content">
                  <SanitizedHtml source={answer.explanationHtml} />
                </ExplanationText>
              </ExplanationContainer>
            </AccordionDetails>
          )}
        </Accordion>
      </TextContainer>
    </Container>
  );
};

export default withStyles(styles)(MultipleChoiceAnswer);
