import _ from 'lodash';
import { IQuiz, IQuizPart } from 'models/quiz';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Params {
  quiz: IQuiz;
  isSubmitted: boolean;
  showAnswers: boolean;
  onSequenceChange: (sequence: IQuizPart[], isCorrect: boolean) => void;
}

interface Signature {
  items: IQuizPart[];
  setItems: (items: IQuizPart[]) => void;
  showCorrectOrder: boolean;
  setShowCorrectOrder: (showCorrectOrder: boolean) => void;
  selectedTab: 'Your Answer' | 'Correct Answer';
  setSelectedTab: (selectedTab: 'Your Answer' | 'Correct Answer') => void;
  flipKey: string;
  correctCount: () => number;
  isSequenceCorrect: (sequence: IQuizPart[]) => boolean;
  showUserAnswers: () => void;
  showCorrectOrderItems: () => void;
  ariaLabel: string;
}

export const useSequence = (params: Params): Signature => {
  const { quiz, isSubmitted, showAnswers, onSequenceChange } = params;

  const { t } = useTranslation();
  const [items, setItems] = useState<IQuizPart[]>([]);
  const [showCorrectOrder, setShowCorrectOrder] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'Your Answer' | 'Correct Answer'>('Your Answer');
  const [flipKey, setFlipKey] = useState('');
  const [ariaLabel, setAriaLabel] = useState('');

  useEffect(() => {
    const initialItems = showAnswers ? _.orderBy(quiz.parts, 'correctPosition') : quiz.parts;
    setItems(initialItems);
    setFlipKey(_.map(initialItems, 'id').join(''));
    setShowCorrectOrder(showAnswers);
    onSequenceChange(initialItems, isSequenceCorrect(initialItems));
  }, [quiz, showAnswers, onSequenceChange]);

  useEffect(() => {
    onSequenceChange(items, isSequenceCorrect(items));
  }, [items, onSequenceChange]);

  useEffect(() => {
    setAriaLabel(getAriaLabel());
  }, [isSubmitted, showAnswers, selectedTab]);

  const getAriaLabel = () => {
    const displayItems = showCorrectOrder ? _.orderBy(items, 'correctPosition') : items;
    let label = '';

    if (isSubmitted) {
      if (!isSequenceCorrect(items)) {
        label = t('Sequence Quiz Confirmation Text Subtitle', {
          correctCount: correctCount(),
          totalCount: items.length,
        });
        // If the selectedTab is "Your Answer", append the user's answer
        // Otherwise, append the correct answer
        if (selectedTab === 'Your Answer') {
          label += ` ${t('Your Answer')}: ${displayItems.map((item) => item.textMarkdown).join(', ')}`;
        } else {
          label += ` ${t('Correct Answer')}: ${displayItems.map((item) => item.textMarkdown).join(', ')}`;
        }
      } else {
        label = t('You got it!');
      }
    } else {
      label = t('Move the blocks into the right order');
    }

    return label;
  };

  const correctCount = useCallback(() => items.filter((item, idx) => item.correctPosition === idx + 1).length, [items]);

  const isSequenceCorrect = useCallback(
    (sequence: IQuizPart[]) => sequence.every((item, idx) => item.correctPosition === idx + 1),
    []
  );

  const showUserAnswers = () => {
    setFlipKey(_.map(items, 'id').join(''));
    setShowCorrectOrder(false);
    setSelectedTab('Your Answer');
  };

  const showCorrectOrderItems = () => {
    const correctOrderItems = _.orderBy(items, 'correctPosition');
    setFlipKey(_.map(correctOrderItems, 'id').join(''));
    setShowCorrectOrder(true);
    setSelectedTab('Correct Answer');
  };

  return {
    items,
    setItems,
    showCorrectOrder,
    setShowCorrectOrder,
    selectedTab,
    setSelectedTab,
    flipKey,
    correctCount,
    isSequenceCorrect,
    showUserAnswers,
    showCorrectOrderItems,
    ariaLabel,
  };
};
