import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

type CreatePartnerUserTagTypePayload = {
  label: string;
  partner_id: number;
};

const createPartnerUserTagType = (payload: CreatePartnerUserTagTypePayload) => {
  return axios.post(`/api/v3/partners/${payload.partner_id}/partner_user_tag_types`, payload);
};

export const useCreatePartnerUserTagType = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();

  return useMutation(createPartnerUserTagType, {
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['partnerUserTagTypes', user?.primaryPartner.id]);
    },
  });
};
