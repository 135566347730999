import produce from 'immer';
import { TAnnotation } from 'types';
import { devlog, immer } from 'zstore';
import create from 'zustand';

type IcEditorState = {
  icAnnotations: TAnnotation[];
  setIcAnnotations: (annotations: TAnnotation[]) => void;
  isCreatingAnnotation: boolean;
  setIsCreatingAnnotation: (b: boolean) => void;
  isShowingEmbedForm: boolean;
  showEmbedForm: () => void;
  hideEmbedForm: () => void;
  swapAnnotationPositions: (annotationId: string, direction: 'up' | 'down') => void;
  addIcAnnotation: (annotation: TAnnotation) => void;
  updateIcAnnotation: (annotationId: string, updatedFields: Partial<TAnnotation>) => void;
  deleteIcAnnotation: (annotationId: string) => void;
};

const makeIcEditorStore = () => {
  return create<IcEditorState>(
    devlog(
      immer((set: any) => ({
        icAnnotations: [],

        setIcAnnotations: (annotations: TAnnotation[]) =>
          set((_state: IcEditorState) => ({ icAnnotations: annotations })),

        isCreatingAnnotation: false,

        setIsCreatingAnnotation: (b: boolean) => set((_state: IcEditorState) => ({ isCreatingAnnotation: b })),

        isShowingEmbedForm: false,

        showEmbedForm: () => set((_state: IcEditorState) => ({ isShowingEmbedForm: true })),

        hideEmbedForm: () => set((_state: IcEditorState) => ({ isShowingEmbedForm: false })),

        swapAnnotationPositions: (annotationId: string, direction: 'up' | 'down') =>
          set(
            produce((state: IcEditorState) => {
              const annotations = state.icAnnotations;
              const currentIndex = annotations.findIndex((a: TAnnotation) => a.id === annotationId);

              if (currentIndex !== -1) {
                const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

                if (newIndex >= 0 && newIndex < annotations.length) {
                  const currentAnnotation = annotations[currentIndex];
                  const swapAnnotation = annotations[newIndex];

                  // Swap positions
                  annotations[currentIndex] = { ...currentAnnotation, position: swapAnnotation.position };
                  annotations[newIndex] = { ...swapAnnotation, position: currentAnnotation.position };

                  annotations.sort((a, b) => a.position - b.position);
                }
              }
            })
          ),

        addIcAnnotation: (annotation: TAnnotation) =>
          set((state: IcEditorState) => {
            state.icAnnotations.push(annotation);
          }),

        updateIcAnnotation: (annotationId: string, updatedFields: Partial<TAnnotation>) =>
          set(
            produce((state: IcEditorState) => {
              const index = state.icAnnotations.findIndex((a: TAnnotation) => a.id === annotationId);
              if (index !== -1) {
                state.icAnnotations[index] = { ...state.icAnnotations[index], ...updatedFields };
              }
            })
          ),

        deleteIcAnnotation: (annotationId: string) =>
          set(
            produce((state: IcEditorState) => {
              state.icAnnotations = state.icAnnotations.filter((a: TAnnotation) => a.id !== annotationId);
            })
          ),
      }))
    )
  );
};

export const useIcEditorStore = makeIcEditorStore();
