import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ResponsiveBar } from '@nivo/bar';
import { diverging } from 'core/colors';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIFFICULTIES } from 'utils/assessContentDifficulty';

const BAR_ORDER = ['Very Hard', 'Hard', 'Moderate', 'Easy', 'Very Easy'];

const OVERLINE = 'DIFFICULTY';

const RECOMMENDATION_TEXT = {
  [CONTENT_DIFFICULTIES.VERY_HARD]: {
    heading: 'Very Hard',
    suggestion:
      'Learners find this content quite difficult. You may want to reduce the difficulty of the Very Hard questions.',
  },
  [CONTENT_DIFFICULTIES.VERY_EASY]: {
    heading: 'Very Easy',
    suggestion:
      'Learners find this content quite easy. You may want to increase the difficulty of the Very Easy questions.',
  },
  [CONTENT_DIFFICULTIES.HARD]: {
    heading: 'Hard',
    suggestion:
      'Learners find this content slightly difficult. You may want to reduce the difficulty of the Very Hard and Hard questions.',
  },
  [CONTENT_DIFFICULTIES.EASY]: {
    heading: 'Easy',
    suggestion:
      'Learners find this content slightly easy. You may want to increase the difficulty of the Very Easy and Easy questions.',
  },
  [CONTENT_DIFFICULTIES.EXTREME]: {
    heading: 'Extreme',
    suggestion:
      'Learners find this content quite extreme. You may want to adjust the difficulty of the Very Hard and Very Easy questions.',
  },
};

export default function DifficultyDistributionCard({ countDifficulties, overallQuestionDifficulty }) {
  const { t } = useTranslation();

  const text = {
    overline: t(OVERLINE),
    heading: t(RECOMMENDATION_TEXT[overallQuestionDifficulty].heading),
    suggestion: t(RECOMMENDATION_TEXT[overallQuestionDifficulty].suggestion),
  };

  const formattedCounts = Object.entries(countDifficulties).map(([key, value]) => ({ key, value }));
  const sortedDifficulties = _.sortBy(formattedCounts, (d) => BAR_ORDER.indexOf(d.key));

  const commonProps = {
    data: sortedDifficulties,
    indexBy: 'key',
    enableLabel: false,
    enableGridX: false,
    enableGridY: false,
    padding: 0.05,
    margin: { bottom: 25 },
    colors: (bar) => diverging[bar.index],
    tooltip: ({ data }) => `${data.key}: ${data.value}`,
  };

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <Stack direction={{ xs: 'column', sm: 'row', md: 'column', lg: 'row' }}>
        <CardContent>
          <Typography sx={{ fontWeight: 'light', letterSpacing: 1, fontSize: 10 }} color="text.secondary">
            {text.overline}
          </Typography>
          <Typography sx={{ mb: 1.5, fontWeight: 600 }} variant="h6">
            {text.heading}
          </Typography>
          <Typography color="text.secondary" sx={{ fontStyle: 'italic', fontSize: 14 }}>
            {text.suggestion}
          </Typography>
        </CardContent>
        <Box sx={{ flexGrow: 1, minWidth: '40%', height: 120, p: 2 }}>
          <ResponsiveBar {...commonProps} />
        </Box>
      </Stack>
    </Card>
  );
}
