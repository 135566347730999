import { CALL_API } from 'middleware/api';

export type IStudyType = 'recommended' | 'force_review' | 'single_item' | 'fading' | 'all';

// filter: recommended, fading, force_review, or nil (all)
export const handleGetSetStudyItems = (setId: string, filter: IStudyType = 'force_review') => {
  const filterParam = `filter[status]=${filter}`;
  return {
    [CALL_API]: {
      endpoint: `/api/v4/sets/${setId}/study_items?${filterParam}&is_cerego_commercial=true`,
      httpMethod: 'GET'
    }
  };
};
