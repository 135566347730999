import axios from 'axios';
import { IImage } from 'models/image';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

export const updateImage = async ({ id, payload }: { id: string; payload: any }): Promise<IImage> => {
  const response = await axios.put(`/api/v3/images/${id}`, payload);
  const imageData = normalizeJSONAPIResponse(response.data);

  return imageData;
};
