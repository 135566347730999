import React from 'react';
import { Route } from 'react-router-dom';
import { UnauthenticatedLayout } from './UnauthenticatedLayout';

export const UnauthenticatedLayoutRoute = ({ component: Component, showSignIn = true, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <UnauthenticatedLayout showSignIn={showSignIn}>
          <Component {...props} />
        </UnauthenticatedLayout>
      )}
    />
  );
};
