import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { TAnnotation } from 'types';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

interface UpdateAnnotationParams {
  id: string;
  payload: any;
}

const updateAnnotation = async ({ id, payload }: UpdateAnnotationParams): Promise<TAnnotation | null> => {
  let updatedAnnotation: TAnnotation | null = null;
  try {
    const response = await axios.put(`/api/v3/annotations/${id}`, payload);
    updatedAnnotation = normalizeJSONAPIResponse(response.data);
  } catch (err: any) {
    console.error(err);
  }

  return updatedAnnotation;
};

export const useUpdateAnnotation = (itemId: string = '', setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(updateAnnotation, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
