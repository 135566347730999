import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

type CreatePartnerUserParams = {
  partnerId: string;
  payload: CreatePartnerUserPayload;
};

type CreatePartnerUserPayload = {
  email: string;
  enrolled_via: 'enrolled_via_direct_invite';
  is_instructor: boolean;
  send_email_invite: boolean;
};

const createPartnerUserRole = ({ partnerId, payload }: CreatePartnerUserParams) => {
  return axios.post(`/api/v3/partners/${partnerId}/users`, payload);
};

export const useCreatePartnerUser = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();

  return useMutation(createPartnerUserRole, {
    onSuccess: () => {
      makeToast({
        text: 'Successfully invited new user.',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Error inviting user. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['partnerUsers', user?.primaryPartner.id]);
    },
  });
};
