import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAssignments } from 'hooks/useAssignments';
import React, { useEffect, useState } from 'react';
import { TAssignment } from 'types';
import { useReportStore } from 'zstore';

interface Props {
  includeAll?: boolean;
}

export const AssignmentFilter = (props: Props) => {
  const { includeAll } = props;
  const courseId = useReportStore((state) => state.courseId);
  const assignmentId = useReportStore((state) => state.assignmentId);
  const setAssignmentId = useReportStore((state) => state.setAssignmentId);
  const setPage = useReportStore((state) => state.setPage);
  const [options, setOptions] = useState<Array<any>>([]);
  const { data: assignments, isLoading } = useAssignments(courseId);
  const [value, setValue] = useState<any>(
    assignments?.find((assignment: TAssignment) => assignment.id === assignmentId) || null
  );
  const [inputValue, setInputValue] = useState(
    assignments?.find((assignment: TAssignment) => assignment.id === assignmentId)?.name || ''
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const a = assignments?.find((assignment: TAssignment) => assignment.id === assignmentId);
    if (!!a) {
      setValue(a);
      setInputValue(a.name);
      setPage(1);
    } else {
      setValue(null);
      setInputValue('');
    }
  }, [courseId]);

  useEffect(() => {
    if (assignments) {
      if (includeAll) {
        setOptions(
          assignments?.sort((a: TAssignment, b: TAssignment) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        );
      } else {
        setOptions(
          assignments?.sort((a: TAssignment, b: TAssignment) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        );
      }
    } else {
      setOptions([]);
    }
  }, [JSON.stringify(assignments)]);

  return (
    <Autocomplete
      id="assignment-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={isLoading}
      value={value}
      onChange={(_event: any, newValue: any) => {
        if (!!newValue) {
          setValue(newValue);
          if (newValue.id === 0) {
            setAssignmentId(null);
          } else {
            setAssignmentId(newValue.id);
          }
        } else {
          setValue(null);
          setAssignmentId(null);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option: any) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ margin: '20px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={includeAll && !value ? 'All assignments' : 'Select assignment'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      selectOnFocus
    />
  );
};
