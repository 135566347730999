import * as Color from 'core/colors';
import styled from 'styled-components';

export interface IButton {
  type?: 'button' | 'submit' | 'reset';
  targetRef?: any;
  text?: string;
  click: () => void;
  style?: any;
}

export const BaseButton = styled.button`
  color: white;
  background-color: ${Color.ceregoGreen};
  border: 1px solid #4b8700;
  box-shadow: 0 1px 10px 0 #bec3c9;
  :hover {
    background-color: #7ab72d;
  }
  :focus {
    box-shadow: 0 1px 4px 0 #4a90e2;
    outline: none;
  }
`;
