import React from 'react';

export function determineMenuOffset(
  event: React.ChangeEvent<HTMLInputElement>,
  verticalPadding: number = 0,
  horizontalPadding: number = 0
) {
  const docElem = document.documentElement;
  const docBod = document.body;
  const scrollTop = (docElem && docElem.scrollTop) || docBod.scrollTop;
  const bounds = event.currentTarget.getBoundingClientRect();
  const x = bounds.x + horizontalPadding;
  const y = bounds.y + scrollTop + verticalPadding;

  return [x, y];
}
