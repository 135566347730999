import { ThemeProvider } from '@mui/material';
import { MemreFlexBox, MemrePageContainer } from 'components/core';
import { Header } from 'pages/admin-dashboard/staff/Header';
import { StaffTable } from 'pages/admin-dashboard/staff/StaffTable';
import theme from 'pages/learner-dashboard/visulisations/theme';

export const StaffPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <MemrePageContainer>
        <MemreFlexBox direction="column" sx={{ padding: '20px' }}>
          <Header />
          <StaffTable />
        </MemreFlexBox>
      </MemrePageContainer>
    </ThemeProvider>
  );
};
