import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
`;

export const LoadingScreen = React.memo(() => {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
});
