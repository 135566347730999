import TextField from '@material-ui/core/TextField';
import { CautionTape } from 'components/item-editor/CautionTape';
import { useItemEditorStore } from 'components/item-editor/store';
import { useAutosaveStatus } from 'hooks/useAutosaveStatus';
import { useMutateConcept } from 'hooks/useMutateConcept';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AutosaveStatus } from '../AutosaveStatus';
import { useMutateItem } from 'hooks/useMutateItem';

export const RatingsEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <Container>
      <RatingsQuestionFormFields />
      <RatingsLabelsFormFields />
      <CautionTape />
    </Container>
  );
};

const RatingsLabelsFormFields = () => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();

  const [minLabelText, setMinLabelText] = useState(item?.minLabel);
  const [maxLabelText, setMaxLabelText] = useState(item?.maxLabel);

  const [mutateItem] = useMutateItem(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useAutosaveStatus();

  const handleLabelChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, isMinLabel: boolean) => {
    const newText = event.target.value;
    if (isMinLabel) {
      setMinLabelText(newText);
    } else {
      setMaxLabelText(newText);
    }
  };

  useEffect(() => {
    if (item == null) {
      return;
    }
    if (minLabelText === item.minLabel && maxLabelText === item.maxLabel) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      setAutosaveStatus('saving');
      mutateItem({
        id: item.id,
        payload: {
          data: {
            attributes: {
              'min-label': minLabelText,
              'max-label': maxLabelText,
            },
          },
        },
      })
        .then(() => setAutosaveStatus('success'))
        .catch(() => setAutosaveStatus('error'));

      // Update app state
      updateActiveItem({ minLabel: minLabelText, maxLabel: maxLabelText });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [minLabelText, maxLabelText, mutateItem, item, updateActiveItem, setAutosaveStatus]);

  return (
    <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
      <TextField
        id="lowest-rating-label"
        label={'Label for lowest rating'}
        value={minLabelText}
        onChange={(event) => handleLabelChange(event, true)}
        placeholder={'Strongly Disagree'}
        variant="outlined"
        style={{
          fontSize: '16px',
          fontWeight: 400,
          padding: '5px 0',
          width: '50%',
        }}
      />
      <TextField
        id="highest-rating-label"
        label={'Label for highest rating'}
        value={maxLabelText}
        onChange={(event) => handleLabelChange(event, false)}
        placeholder={'Strongly Agree'}
        variant="outlined"
        style={{
          fontSize: '16px',
          fontWeight: 400,
          padding: '5px 0',
          width: '50%',
        }}
      />
      <AutosaveStatus status={autosaveStatus} />
    </div>
  );
};

const RatingsQuestionFormFields = () => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();

  const [question, setQuestion] = useState(item?.question);
  const [mutateItem] = useMutateItem(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useAutosaveStatus();

  const handleConceptTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  // update concept text automatically on changed input
  // debounce to avoid making too many requests
  useEffect(() => {
    if (item == null) {
      return;
    }
    if (question !== item.question) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateItem({
          id: item.id,
          payload: {
            data: {
              attributes: {
                question,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));

        // update app state
        updateActiveItem({ question });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [item, mutateItem, question, setAutosaveStatus, updateActiveItem]);

  return (
    <>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <TextField
          id={`${item?.id}-rating-question`}
          label={'Prompt for a rating'}
          value={question}
          onChange={handleConceptTextChange}
          placeholder={'I would like to use this product again in the future.'}
          variant="outlined"
          style={{
            fontSize: '24px',
            fontWeight: 500,
            padding: '5px 0',
            width: '100%',
          }}
        />
      </div>
      <AutosaveStatus status={autosaveStatus} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
