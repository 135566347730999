import * as React from 'react';
import styled from 'styled-components';

import { Color } from 'core';
import { IButton } from 'core/button';

import PauseImg from 'assets/images/pause.svg';
import SoundImg from 'assets/images/sound.svg';

const SoundImage = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 5px;
  position: absolute;
  top: 17px;
  left: 15px;
`;

const SVGCircle = styled.circle`
  stroke: ${Color.lightGray};
  stroke-width: 2px;
  fill: transparent;
`;

const SVGCircleProgress = styled.circle`
  fill: transparent;
  stroke: ${Color.lightGreen};
  stroke-dashoffset: 500;
  stroke-dasharray: 500;
  transition: stroke-dashoffset 0.2s linear;
  stroke-width: 2px;
`;

const Container = styled.div`
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  position: relative;
`;

const SmallSoundButtonObj = styled.button`
  width: 50px;
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 0;
  margin-right: 15px;
  outline: none;
  background-color: white;
`;

interface IMediaButton extends IButton {
  playStatus: any;
  soundStatus: any;
}

export default class SmallSoundButton extends React.Component<IMediaButton> {
  public render() {
    const { targetRef, playStatus, soundStatus } = this.props;
    const isPlaying = playStatus === soundStatus.PLAYING;
    const isPaused = playStatus === soundStatus.PAUSED;

    return (
      <SmallSoundButtonObj onClick={this.handleClick}>
        <Container>
          {(isPlaying || isPaused) && (
            <svg viewBox="0 0 50 50" width="50" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <SVGCircle r="22" cx="25" cy="25" />
              <SVGCircleProgress id="ProgressBar" ref={targetRef} r="22" cx="25" cy="25" />
            </svg>
          )}
          <SoundImage src={isPlaying ? PauseImg : SoundImg} />
        </Container>
      </SmallSoundButtonObj>
    );
  }

  private handleClick = () => {
    this.props.click();
  };
}
