import { MenuButton } from 'components/buttons';
import { ContentSection, TitleSection } from 'core/layout';
import { AssignmentDetailsPageHeader } from 'learner_stats/components/AssignmentDetailsPageHeader';
import { useTranslation } from 'react-i18next';
import { Bar as MultiSelectChart } from 'sets/components/charts/Bar';
import { Container as ChartContainer } from 'sets/components/charts/Container';
import { FreeResponse as FreeResponseList } from 'sets/components/charts/FreeResponse';
import { Pie as SingleSelectChart } from 'sets/components/charts/Pie';
import { Rating as RatingChart } from 'sets/components/charts/Rating';
import { TAssignment } from 'types';

interface Props {
  set: TAssignment;
  courseId: string;
  surveyResults: any[];
}

export const SurveyReport = (props: Props) => {
  const { surveyResults, set, courseId } = props;
  const { t } = useTranslation();

  const handleCsvButtonClick = () => {
    window.open(`/api/v4/sets/${set.id}/survey_results_by_user?course_id=${courseId}`, '_blank', '');
  };

  const parseCharts = (chartData: any[]) => {
    if (!chartData) {
      return null;
    }

    const chartElements = chartData.map(({ questionText = '', type = '', ...attrs }, i) => {
      let chart: any;

      if (!questionText || !type) {
        return false;
      }

      switch (type) {
        case 'surveyQuestionResultMultipleChoices':
          chart = attrs.isMultiselect
            ? createMultiSelectChart(questionText, attrs, i)
            : createSingleSelectChart(questionText, attrs, i);
          break;
        case 'surveyQuestionResultFreeResponses':
          chart = createFreeResponseChart(questionText, attrs, i);
          break;
        case 'surveyQuestionResultRatings':
          chart = createRatingChart(questionText, attrs, i);
          break;
      }

      return chart;
    });

    return chartElements;
  };

  const createRatingChart = (questionText, questionAttrs, key) => {
    // TODO: i18n ratings?
    const { minLabel, maxLabel, ratingCounts } = questionAttrs;
    const scale = [minLabel, '1', '2', '3', maxLabel];
    const data = [
      {
        questionText,
        [minLabel]: ratingCounts['1'],
        '1': ratingCounts['2'],
        '2': ratingCounts['3'],
        '3': ratingCounts['4'],
        [maxLabel]: ratingCounts['5'],
      },
    ];

    return <RatingChart key={'chart_' + key} data={data} scale={scale} title={questionText} height="150" />;
  };

  const createFreeResponseChart = (questionText: any, questionAttrs: any, key: any) => {
    const { freeResponseAnswers } = questionAttrs;
    const data = freeResponseAnswers.map(({ answerText, user }) => ({
      user: user.name,
      response: answerText,
    }));

    return <FreeResponseList chartKey={'chart_' + key} data={data} title={questionText} height="auto" />;
  };

  const createMultiSelectChart = (questionText: any, questionAttrs: any, key: any) => {
    const { answerCounts } = questionAttrs;
    const keys: string[] = [];
    const data = answerCounts.map((answer: any, j: any) => {
      const keyVal = 'count_' + j;

      keys.push(keyVal);
      return {
        answer: answer.text,
        [keyVal]: answer.count,
      };
    });

    return <MultiSelectChart key={'chart_' + key} chartKeys={keys} data={data} title={questionText} height="300" />;
  };

  const createSingleSelectChart = (questionText: any, questionAttrs: any, key: any) => {
    const { answerCounts } = questionAttrs;
    const data = answerCounts.map((answer: any, j: any) => ({
      id: j,
      label: answer.text,
      value: answer.count,
    }));

    return <SingleSelectChart key={'chart_' + key} data={data} title={questionText} height="500" />;
  };

  if (!surveyResults) {
    return null;
  }

  return (
    <>
      <AssignmentDetailsPageHeader set={set} courseId={courseId} />
      <TitleSection>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h3>{t('Assignment Progress')}</h3>
        </div>
        <div style={{ float: 'right' }}>
          <MenuButton text={t('Download')} click={handleCsvButtonClick} hideArrow={true} />
        </div>
      </TitleSection>
      <ContentSection>
        <ChartContainer title="Survey Results" color="gray">
          {parseCharts(surveyResults)}
        </ChartContainer>
      </ContentSection>
    </>
  );
};
