import * as Color from 'core/colors';

export const STROKE = {
  COLOR: {
    DARK: Color.black,
    GREEN: Color.hexToRGB(Color.ceregoGreen, 1, 0.15),
    RED: Color.hexToRGB(Color.reportRed, 1, 0.15),
    LIGHT: Color.white,
  },
  WIDTH: {
    SMALL: 0.5,
    MEDIUM: 2,
    LARGE: 3,
  },
};

export const COLOR = {
  DARK_BLUE: Color.hexToRGB(Color.reportBlue, 0.8, 0.4),
  BLUE: Color.hexToRGB(Color.reportBlue, 0.2),
  TRANSPARENT: Color.hexToRGB(Color.reportBlue, 0.00000001),
  BLACK: Color.hexToRGB(Color.reportBlue, 1, 0.65),
  GREEN: Color.hexToRGB(Color.ceregoGreen, 0.8),
  DARK_GREEN: Color.hexToRGB(Color.ceregoGreen, 0.8, 0.4),
  WHITE: Color.white,
};

export const FONT = {
  SIZE: {
    MEDIUM: '0.85rem',
  },
  COLOR: {
    DARK: Color.black,
  },
  FAMILY: 'Lato, sans-serif',
};

export const FILL = {
  GREEN: Color.hexToRGB(Color.ceregoGreen, 0.8),
  RED: Color.hexToRGB(Color.reportRed, 1, 0.15),
  DARK_RED: Color.hexToRGB(Color.reportRed, 0.7, 0.2),
  LIGHT: Color.hexToRGB(Color.ceregoGreen, 1, 0.8),
  WHITE: Color.white,
  WHITE_BG: Color.hexToRGB(Color.white, 0.8),
  LIGHT_BLUE: Color.hexToRGB(Color.reportBlue, 0.05),
  DARK_BLUE: Color.hexToRGB(Color.reportBlue, 0.8, 0.4),
  BACKGROUND_BLUE: Color.hexToRGB(Color.reportBlue, 0.1),
  BLACK: Color.hexToRGB(Color.reportBlue, 1, 0.8),
};

export const SHADOW = {
  COLOR: Color.hexToRGB(Color.reportBlue, 0.2),
  OFFSET: 4,
};
