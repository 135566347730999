import { ClickToEdit } from 'components/ClickToEdit';
import { ContentText } from 'courses/components/ContentText';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TAssignment } from 'types';

interface Props {
  assignment: TAssignment;
  handleClickToEdit: (offset: number[], assignmentId: string, columnName: string) => void;
}

export const DueAtCell = (props: Props) => {
  const { assignment, handleClickToEdit } = props;
  const { t } = useTranslation();

  const text = !!assignment.meta.dueAt ? format(new Date(assignment.meta.dueAt), 'MM/dd/yy') : t('Anytime');

  return (
    <ClickToEdit id={assignment.id} column={'dueAt'} click={handleClickToEdit}>
      <ContentText text={text} textDecoration="underline" />
    </ClickToEdit>
  );
};
