import axios from 'axios';
import { TableParmas } from 'models/table';
import { queryCache, useMutation, useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const ITEM_INCLUDES: string =
  'sentences,sentences.image,sentences.sound,attached_items,image,facets.anchor,facets.association,facets.anchor.image,facets.anchor.sound,facets.association.image,facets.association.sound,annotations,annotations.document,annotations.image,annotations.sound,annotations.video,facets.association.distractors,facets.anchor.distractors,facets.tags';

const getInstructionalItems = async (_: string, { id, pageNumber, pageSize }: TableParmas) => {
  const response = await axios.get(
    `/api/v3/courses/${id}/items?filter[type]=InstructionalContent&include=${ITEM_INCLUDES}&page[number]=${pageNumber}&page[size]=${pageSize}`
  );
  return normalizeJSONAPIResponse(response.data);
};

const useInstructionalItemsV3 = ({ id, pageNumber, pageSize }: TableParmas) => {
  return useQuery(['instructionalItems', { id, pageNumber, pageSize }], getInstructionalItems, {
    enabled: !!id,
  });
};

export const useInvalidateInstructionalItems = <T>(cb: (data: T) => void | Promise<void>) => {
  return useMutation(
    (data: T) => {
      return Promise.resolve(cb(data));
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('instructionalItems');
      },
    }
  );
};

export default useInstructionalItemsV3;
