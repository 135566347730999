import { CancelButton, PrimaryButton } from 'components/buttons/v4';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 1em;

  > button {
    margin-right: 0.75rem;
  }
`;

interface IConfirmOrCancel {
  cancelText?: string;
  confirmText?: string;
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
  cancelIsDisabled?: boolean;
  confirmIsDisabled: boolean;
  confirmIsPending: boolean;
  destructive?: boolean;
}

export const ConfirmOrCancel = React.memo<IConfirmOrCancel>(props => {
  const { t } = useTranslation();

  const cancelText: string = props.cancelText || t('Cancel');
  const confirmText: string = props.confirmText || t('Confirm');
  // Disable confirm button when pending
  const disableConfirm: boolean = props.confirmIsPending || props.confirmIsDisabled;

  return (
    <ButtonWrapper>
      <CancelButton click={props.handleCancelClick} disabled={props.cancelIsDisabled}>
        {cancelText}
      </CancelButton>
      <PrimaryButton
        click={props.handleConfirmClick}
        disabled={disableConfirm}
        pending={props.confirmIsPending}
        destructive={props.destructive}
      >
        {confirmText}
      </PrimaryButton>
    </ButtonWrapper>
  );
});
