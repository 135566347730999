import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ceregoIconGreyRounded from 'assets/images/cerego-icon-grey-rounded.png';
import { ImageEditOverlay } from 'components/images/ImageBadge';
import { ImageWithPolygon } from 'components/item-editor/regions/ImageWithPolygon';
import { useItemEditorStore } from 'components/item-editor/store';
import { Color } from 'core';
import { useDeleteFacet } from 'hooks/useDeleteFacet';
import { useMutateItem } from 'hooks/useMutateItem';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { createImage } from 'utils/createImage';

export const BaseImage: React.FC = () => {
  const { activeItem: item, updateActiveItem, activeAssignment: assignment } = useItemEditorStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [mutateItem] = useMutateItem(item?.id, assignment?.id);
  const [deleteFacet] = useDeleteFacet('deleteFacet', assignment?.id || '', item?.id || '');

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const file = event.target.files?.[0];

    try {
      if (file) {
        const image = await createImage(file);
        updateActiveItem({ image });

        await mutateItem({
          id: item.id,
          payload: {
            data: {
              relationships: {
                image: {
                  data: {
                    id: image.id,
                  },
                },
              },
            },
          },
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleDeleteImage = async () => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    try {
      // update item image and facets in store
      updateActiveItem({
        image: null,
        facets: [],
      });

      // nuke image from item
      await mutateItem({
        id: item.id,
        payload: {
          data: {
            relationships: {
              image: {
                data: null,
              },
            },
          },
        },
      });

      // nuke item questions (i.e. facets)
      item.facets.forEach(async (facet: any) => {
        await deleteFacet(facet.id);
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  if (!item) {
    console.warn('Item not found');
    return null;
  }

  if (!item.image) {
    return (
      <div style={{ position: 'relative' }}>
        <div>
          <ImageEditOverlay
            style={{
              width: '85px',
              height: '85px',
              borderRadius: '20%',
              background: `${Color.sideNavDark}`,
              objectFit: 'cover',
              cursor: 'pointer',
            }}
            onClick={handleImageClick}
          >
            <FontAwesomeIcon icon={faEdit} style={{ color: 'white', fontSize: '22px' }} />
          </ImageEditOverlay>
          <img
            src={ceregoIconGreyRounded}
            style={{
              objectFit: 'cover',
              width: '85px',
              height: '85px',
              borderRadius: '20%',
            }}
          />
        </div>
        <FileInput ref={fileInputRef} type="file" accept="image/*" onChange={handleImageUpload} />
      </div>
    );
  }

  return (
    <ImageContainer ref={containerRef}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>Base Image</h1>
        <DeleteButton onClick={handleDeleteImage}>
          <DeleteIcon />
        </DeleteButton>
      </div>
      <ImageWithPolygon
        imageUrl={item.image.url}
        imageAlt={item.image.altTag || ''}
        polygonPoints={[]}
        highlightDimensions={{ width: 800, height: 600 }} // Fixed dimensions for base image
        containerWidth={800} // Fixed width for container
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const FileInput = styled.input`
  display: none;
`;

const DeleteButton = styled(IconButton)`
  background: rgba(255, 255, 255, 0.8); // Light background for better visibility
  &:hover {
    background: rgba(250, 50, 50, 0.8); // Change background on hover for emphasis
  }
`;
