import { Size } from 'core';
import { TitleText } from 'learn/components/TitleText';
import { IQuizPart } from 'models/quiz';
import React, { FC } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  renderSequenceItems: (showCorrectOrder: boolean, displayItems: IQuizPart[]) => JSX.Element[];
  isSequenceCorrect: (sequence: IQuizPart[]) => boolean;
  flipKey: string;
  displayItems: IQuizPart[];
  items: IQuizPart[];
}

export const CorrectSequenceBody: FC<Props> = ({
  renderSequenceItems,
  isSequenceCorrect,
  flipKey,
  displayItems,
  items,
}) => {
  const { t } = useTranslation();

  return (
    <List>
      <Flipper flipKey={flipKey}>
        {renderSequenceItems(false, displayItems)}
        {isSequenceCorrect(items) && (
          <CorrectTextContainer>
            <TitleText
              name="success-message"
              text={t('You got it!')}
              style={{ marginTop: '0px', marginBottom: '0px' }}
            />
          </CorrectTextContainer>
        )}
      </Flipper>
      <div />
    </List>
  );
};

const List = styled.ul`
  margin: 0px auto;
  max-width: ${Size.defaultCardWidth}px;
  position: relative;
  padding: 0px;
`;

const CorrectTextContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
`;
