import { ThemeProvider } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LoadingComponent from 'components/LoadingComponent';
import { MemreFlexBox, MemreHeader, MemrePageContainer, MemreSection, MemreSectionHeading } from 'components/core';
import ErrorBoundary from 'components/layouts/ErrorBoundary';
import { useAllMyCourses } from 'hooks/useAllMyCourses';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { STRIPE_KEY } from 'models/account';
import { PaymentRequiredCTA } from 'pages/learner-dashboard/PaymentRequiredCTA';
import { StudyBlock } from 'pages/learner-dashboard/StudyBlock';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';
import CoursesTable from './CoursesTable';
import KnowledgeBankCTA from './KnowledgeBankCTA';
import PartnerLibraryCTA from './PartnerLibraryCTA';
import theme from './visulisations/theme';

const stripePromise = loadStripe(STRIPE_KEY);

export const Dashboard = () => {
  const user = useCurrentUser();
  const location = useLocation();
  const { t } = useTranslation();
  const { makeToast } = useStore();
  const [showPaymentRequiredCTA, setShowPaymentRequiredCTA] = useState(false);

  const myCourses = useAllMyCourses();

  const meta = myCourses?.data?.meta;
  const paymentRequiredCourseIds = meta?.['payment-required-course-ids'] || [];
  const courses = normalizeJSONAPIResponse(myCourses?.data);
  const paymentRequiredCourse = courses?.find((c: any) => Number(c.id) === paymentRequiredCourseIds[0]);

  const coursesCount = courses?.length || 0;

  useEffect(() => {
    setShowPaymentRequiredCTA(paymentRequiredCourse?.meta.paymentRequired);
  }, [JSON.stringify(myCourses)]);

  useEffect(() => {
    if (location.search.indexOf('success=true') > -1) {
      makeToast({
        text: t('Payment successful!'),
        isError: false,
      });
    } else if (location.search.indexOf('success=false') > -1) {
      makeToast({
        text: t('Payment failed!'),
        isError: true,
      });
    }
  }, [location.search]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <LoadingComponent
          component={
            <Elements stripe={stripePromise}>
              <MemreHeader text="Learner Dashboard" />
              <MemrePageContainer>
                <MemreFlexBox direction="column" gap={4}>
                  {showPaymentRequiredCTA && <PaymentRequiredCTA closeCTA={() => setShowPaymentRequiredCTA(false)} />}
                  <StudyBlock coursesCount={coursesCount} />
                  <MemreFlexBox
                    gap={4}
                    direction={{
                      xs: 'column',
                      lg: 'row',
                    }}
                  >
                    <KnowledgeBankCTA />
                    <PartnerLibraryCTA />
                  </MemreFlexBox>
                  <MemreSection>
                    <MemreSectionHeading text="Enrolled Courses" />
                    <CoursesTable coursesCount={coursesCount} />
                  </MemreSection>
                </MemreFlexBox>
              </MemrePageContainer>
            </Elements>
          }
          isLoading={!user}
        />
      </ThemeProvider>
    </ErrorBoundary>
  );
};
