export const red = '#F1622E';
export const white = '#FFFFFF';
export const black = '#000000';
export const lightGreen = '#7AB72D';
export const ceregoGreen = '#5AA100';
export const darkGreen = '#4B8700';
export const lightGradientGreen = '#8CDC28';
export const darkGradientGreen = '#036266';
export const cerulean = '#2c73ac';
export const darkBlue = '#1A4568';
export const mainBlue = '#266395';
export const darkSkyBlue = '#3AE8FE';
export const lightSkyBlue = '#8BF1FF';
export const lightBlue = '#5182AA';
export const lightGreenTransparent = 'rgb(122, 183, 45, 0.4)';
export const veryLightPurple = '#EAEBFD';
export const gradientPurple = '#F7F7F9';
export const lightPurple = '#E3E0F9';
export const darkPurple = '#7851A9';
export const brightPurple = '#9370DB';
export const deepBlueGray = '#E4E7E8';
export const veryLightGray = '#EFEFEF';
export const learnHoverGray = '#E0E0E0';
export const lightGray = '#CCCCCC';
export const textGray = '#737373';
export const darkGray = '#555555';
export const focusedGray = '#DEDEDE';
export const grayTransparent = 'rgb(101, 103, 123, 0.1)';
export const veryDarkOrange = '#A93A18';
export const darkOrange = '#BC411B';
export const orange = '#FF8456';
export const veryLightBlue = '#EBEEF2';
export const lightBlack = '#333333';
export const darkBlueGray = '#292F3B';
export const primaryGreen = '#498500';
export const primaryGreenBright = '#7ABB42';
export const quizCardHeaderBackground = '#d6f3f7';
export const pink = '#e73e79';

export const reportButtonGray = '#97A3B4';
export const reportBlue = '#4A90E2';
export const reportRed = '#F2622E';
export const reportGray = '#65677b';
export const reportOrange = '#F0B048';

export const successGreen = '#D1E9bC';
export const incorrectRed = '#FEF0EB';

export const sideNavDark = '#101317';

export const diverging = [veryDarkOrange, orange, veryLightBlue, reportBlue, mainBlue];
export const categorical = [lightGreen, lightBlue, darkOrange, darkPurple, darkGreen, orange, mainBlue, reportOrange];

// V4 styles
export const primaryBlue = '#333aec';
export const secondaryBlue = 'rgba(51, 58, 236, 0.1)';
export const primaryGray = '#f4f7f8';
export const secondaryGray = '#aab2c0';
export const primaryBlack = '#2e353f';
export const activeBlue = '#282eba';
export const lightRed = 'rgba(236, 65, 51, 0.1)';
export const regularRed = '#e53900';
export const activeRed = '#c22300';
export const redText = '#e52900';
export const slateGray = '#454b54';

export const brightGreen = '#7ccb3d'; // Like a brighter lightGreen

// https://stackoverflow.com/a/28056903
export function hexToRGB(hex: string, alpha?: number, darken?: number) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  if (darken) {
    const darknessFactor = 1 - darken;
    r = Math.max(Math.floor(r * darknessFactor), 0);
    g = Math.max(Math.floor(g * darknessFactor), 0);
    b = Math.max(Math.floor(b * darknessFactor), 0);
  }

  if (alpha) {
    return `rgba(${r},${g},${b},${alpha})`;
  } else {
    return `rgba(${r},${g},${b})`;
  }
}
