import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useItemEditorStore } from 'components/item-editor/store';
import { useTranslation } from 'react-i18next';

export const CautionTape = () => {
  const { t } = useTranslation();
  const { activeAssignment: assignment } = useItemEditorStore();

  const isMirrored = assignment?.meta.isMirrored;
  const isPublished = assignment?.meta.published;

  if (!isMirrored && !isPublished) {
    return null;
  }

  return (
    <Alert severity="warning" style={{ marginBottom: '20px' }}>
      <AlertTitle>{t('Warning! Use caution when editing this assignment.')}</AlertTitle>
      {isPublished && (
        <p>
          <strong>{t('This assignment has been published. ')}</strong>
          {t('Editing items in this assignment may make the material unstudiable or change user progress scores.')}
        </p>
      )}
      {isMirrored && (
        <p>
          <strong>{t('This assignment has been shared. ')}</strong>
          {t('Edits made to this assignment will apply across all courses the assignment has been added to.')}
        </p>
      )}
    </Alert>
  );
};
