import { CALL_API } from 'middleware/api';

export const handleCreateCourseUser = (
  courseId: string,
  email: string,
  sendEmailInvite: boolean,
  enrolledVia: 'enrolled_via_direct_invite',
  isInstructor: boolean
) => {
  return async dispatch => {
    const actionResponse = await dispatch({
      [CALL_API]: {
        endpoint: `/api/v3/courses/${courseId}/users`,
        httpMethod: 'POST',
        data: {
          email,
          send_email_invite: sendEmailInvite,
          enrolled_via: enrolledVia,
          is_instructor: isInstructor
        }
      }
    });

    return actionResponse;
  };
};

export const handleDeleteCourseUser = (courseId: string, userId: string) => {
  return async dispatch => {
    const actionResponse = await dispatch({
      [CALL_API]: {
        endpoint: `/api/v3/courses/${courseId}/users/${userId}`,
        httpMethod: 'DELETE'
      }
    });

    return actionResponse;
  };
};
