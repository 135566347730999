import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getUser = async (key: string, partnerId: string, userId: string) => {
  const endpoint: string = `/api/v3/partners/${partnerId}/users/${userId}`;
  const response = await axios.get(endpoint);

  return normalizeJSONAPIResponse(response.data);
};

export const useUser = (partnerId: string, userId: string, isProfileUser: boolean) => {
  return useQuery(['user', partnerId, userId], getUser, { enabled: partnerId && userId && !isProfileUser });
};
