import {
  faCaretDown,
  faCaretUp,
  faCog,
  faRandom,
  faSignOutAlt,
  faUser,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import externalLinkIcon from 'assets/images/angle-arrow.png';
import ceregoLogo from 'assets/images/cerego-logo-white.svg';
import { DropDown } from 'components/DropDown';
import ImageBadge from 'components/images/ImageBadge';
import { Modal } from 'components/Modal';
import { Billing } from 'components/modals/Billing';
import { PartnersPicker } from 'components/PartnersPicker';
import { Restricted } from 'components/Restricted';
import { SideNavSection } from 'components/SideNavSection';
import { Color } from 'core';
import { primaryGreen } from 'core/colors';
import { DividingLine } from 'core/layout';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import { handleGetPartners } from 'store/partner';
import { getCurrentUser, handleGetProfile } from 'store/profile';
import { handleDestroySession } from 'store/session';
import { updateUser } from 'store/user';
import styled from 'styled-components';
import { ANONYMOUS_NAME, LINKS } from 'utils/constants';
import { partnerRoleDisplay } from 'utils/partnerUtils';
import { getBaseReactUrl } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';
import { sleep } from 'utils/timeUtils';
import { getRole } from 'utils/userManager';
import useStore from 'zstore';

interface Props extends RouteProps {
  updateUrl: (path: string) => void;
  billingStatus?: 'success' | 'cancel';
  isMobile?: boolean;
}

export const SideNav = (props: Props) => {
  const currentUser = useSelector((state) => getCurrentUser(state)) || {};
  const dispatch = useDispatch();
  const [showBillingModal, setShowBillingModal] = React.useState(!!props.billingStatus);
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const [showPartnerPicker, setShowPartnerPicker] = React.useState(false);
  const [appcuesLoaded, setAppcuesLoaded] = React.useState(false);
  const partnerImage = currentUser.primaryPartner?.image;
  const partnersMeta = useSelector(getPartnersMeta) || {};
  const { totalCount: partnersCount = 0 } = partnersMeta;
  const { t } = useTranslation();
  const location = useLocation();
  const { showModal } = useStore();
  const currentUserDisplayName = currentUser.name == ANONYMOUS_NAME ? t('Guest') : currentUser.name;
  const currentUserRoleDisplay = partnerRoleDisplay(currentUser?.primaryPartner?.meta?.role);

  useEffect(() => {
    dispatch(handleGetPartners(1, ''));
  }, [dispatch]);

  useEffect(() => {
    // listen for messages from the angular app inside the iframe
    window.addEventListener('message', handleMessageReceived, true);
    // clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener('message', handleMessageReceived);
    };
  });

  const handleOutsideClick = (_event: any): void => {
    if (document.activeElement === document.getElementById('angular-embed')) {
      setShowUserMenu(false);
    }
  };

  // Hands-on management of outside click handlers
  React.useEffect(() => {
    window.addEventListener('blur', handleOutsideClick);
    return () => window.removeEventListener('blur', handleOutsideClick);
  });

  const handleMessageReceived = (event: any) => {
    if (event.data === 'partnerImageUpdated') {
      dispatch(handleGetProfile());
    }
  };

  const handleDashboardClick = () => {
    props.updateUrl('v4/dashboard');
  };

  const handleV4AdminDashboardClick = () => {
    props.updateUrl('v4/admin');
  };

  const handleCoursesClick = () => {
    props.updateUrl('courses');
  };

  const goToReports = (): void => {
    props.updateUrl('reports');
  };

  const handleControlPanelClick = () => {
    props.updateUrl('control-panel');
  };

  const handleSiteAdminClick = () => {
    props.updateUrl('admin');
  };

  const handleV4SiteAdminClick = () => {
    props.updateUrl('v4/site-admin');
  };

  const handleUserSettingsClick = () => {
    props.updateUrl('settings');
  };

  const handleUpgradeClick = () => {
    AnalyticsService.getInstance().track('upgrade_now_clicked');
    setShowBillingModal(true);
  };

  async function handleSignOutClick() {
    await dispatch(handleDestroySession());
    const hostname = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';
    window.location.href = hostname + '/signin';
  }

  const handleBillingClose = () => {
    if (props.billingStatus !== 'success') {
      AnalyticsService.getInstance().track('cancel_billing_clicked');
    }
    setShowBillingModal(false);
  };

  const goToUserProfile = () => {
    props.updateUrl(`user/${currentUser.id}`);
  };

  // this gives us the partner count
  function getPartnersMeta(state: any) {
    return state.data.meta['/api/v3/my/partners']?.meta;
  }

  let freeTrialExpiresText = '';
  const currentUserRole = getRole(currentUser);
  if (currentUser?.primaryPartner?.expiresAt) {
    const today = moment().startOf('day');
    const expiresAt = moment(currentUser.primaryPartner.expiresAt);
    const expiresInDays = expiresAt.diff(today, 'days');
    if (expiresInDays <= 0) {
      freeTrialExpiresText = 'Your trial has expired';
    } else {
      freeTrialExpiresText = t('Side Nav Free Trial Expired Length Text', { count: expiresInDays });
    }
  }

  // recursively attempt to load appcues since we don't know when the snippet will finish
  const loadAppcuesLaunchpad = async () => {
    const appcues = (window as any).Appcues;
    // TODO: remove stable flag once marketing asks us to.
    if (process.env.REACT_APP_ENV !== 'stable' || appcuesLoaded) {
      return;
    }
    if (appcues) {
      appcues.loadLaunchpad('#appcues-launchpad', {
        position: 'right',
      });
      setAppcuesLoaded(true);
    } else {
      await sleep(1000);
      loadAppcuesLaunchpad();
    }
  };

  useEffect(() => {
    // load Appcues for launchpad
    const script = document.createElement('script');
    script.src = '//fast.appcues.com/66778.js';
    script.async = true;
    document.body.appendChild(script);
    loadAppcuesLaunchpad();
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const switchToParent = () => {
    dispatch(updateUser({ primary_partner_id: currentUser.primaryPartner.parentId }, currentUser.id));
    window.location.assign(`${getBaseReactUrl()}/app/nav/dashboard`);
  };

  if (showPartnerPicker) {
    return <Modal show={showPartnerPicker} element={<PartnersPicker />} />;
  }

  if (showBillingModal) {
    return (
      <Modal
        show={showBillingModal}
        element={<Billing onClose={handleBillingClose} isSuccess={props.billingStatus === 'success'} />}
      />
    );
  }

  return (
    <Container aria-hidden={showModal ? 'true' : 'false'} style={showModal ? { display: 'none' } : { display: 'flex' }}>
      <div>
        <CeregoLogoButton onClick={handleDashboardClick}>
          <CeregoLogo src={ceregoLogo} />
        </CeregoLogoButton>
        <ImageBadge entity="partner" style={PartnerLogoStyle} image={partnerImage} />
        <PartnerName>{currentUser.primaryPartner?.name}</PartnerName>
        <PartnerRole>
          {t('Role:')} {currentUserRoleDisplay}
        </PartnerRole>

        <Restricted
          allowedRoles={[]}
          component={
            <div>
              <SiteAdminDetails> Partner ID: {currentUser.primaryPartner?.id} </SiteAdminDetails>
              {currentUser.primaryPartner?.parentId && (
                <SiteAdminDetailsButton onClick={switchToParent}>
                  <SiteAdminDetails>Parent Partner ID: {currentUser.primaryPartner?.parentId}</SiteAdminDetails>
                </SiteAdminDetailsButton>
              )}
            </div>
          }
        />

        <InfoLabel>{t('Signed in as:')}</InfoLabel>
        <div style={{ minHeight: '75px' }}>
          <OutsideClickHandler onOutsideClick={() => setShowUserMenu(false)}>
            {(currentUserDisplayName || currentUser.email) && (
              <UsernameContainer onClick={() => setShowUserMenu(!showUserMenu)}>
                <GreenLabel>{currentUserDisplayName || currentUser.email}</GreenLabel>
                <CaretDown icon={showUserMenu ? faCaretUp : faCaretDown} />
              </UsernameContainer>
            )}

            {showUserMenu && (
              <DropDown top={-1.5}>
                <UserDropDownMenu>
                  <ImageBadge entity="user" style={UserAvatarStyle} image={currentUser.image} />
                  <UserName>{currentUserDisplayName}</UserName>
                  <UserEmail>{currentUser.email}</UserEmail>

                  <UserMenuOption>
                    <UserMenuLink onClick={goToUserProfile}>
                      {t('User Profile')} <FontAwesomeIcon icon={faUser} />
                    </UserMenuLink>
                  </UserMenuOption>

                  {partnersCount > 1 && (
                    <UserMenuOption>
                      <UserMenuLink onClick={() => setShowPartnerPicker(true)}>
                        {t('Switch Accounts')} <FontAwesomeIcon icon={faRandom} />
                      </UserMenuLink>
                    </UserMenuOption>
                  )}

                  <UserMenuOption>
                    <UserMenuLink onClick={handleUserSettingsClick}>
                      {t('Settings')} <FontAwesomeIcon icon={faCog} />
                    </UserMenuLink>
                  </UserMenuOption>

                  <Restricted
                    allowedRoles={[]}
                    component={
                      <UserMenuOption>
                        <UserMenuLink onClick={handleSiteAdminClick}>
                          {t('Admin')} <FontAwesomeIcon icon={faUserTie} />
                        </UserMenuLink>
                      </UserMenuOption>
                    }
                  />

                  <Restricted
                    allowedRoles={[]}
                    component={
                      <UserMenuOption>
                        <UserMenuLink onClick={handleV4SiteAdminClick}>
                          {t('V4 Site Admin')} <FontAwesomeIcon icon={faUserTie} />
                        </UserMenuLink>
                      </UserMenuOption>
                    }
                  />

                  <UserMenuOption>
                    <UserMenuLink onClick={handleSignOutClick}>
                      {t('Sign Out')} <FontAwesomeIcon icon={faSignOutAlt} />
                    </UserMenuLink>
                  </UserMenuOption>
                </UserDropDownMenu>
              </DropDown>
            )}
          </OutsideClickHandler>
        </div>
        <DividingLine />
        <SideNavSection
          onClick={handleDashboardClick}
          isActive={location.pathname.startsWith('/nav/v4/dashboard')}
          label={t('Dashboard')}
        />
        <DividingLine />

        <Restricted
          allowedRoles={['admin', 'course manager', 'content manager', 'instructor', 'course viewer']}
          component={
            <div>
              <SideNavSection
                id="side-nav-to-courses"
                onClick={handleCoursesClick}
                isActive={location.pathname.startsWith('/nav/courses')}
                label={t('Courses')}
              />
              <DividingLine />
            </div>
          }
        />

        <Restricted
          allowedRoles={['admin']}
          component={
            <div>
              <SideNavSection
                onClick={handleV4AdminDashboardClick}
                isActive={location.pathname.startsWith('/nav/v4/admin')}
                label={t('Admin')}
              />
              <DividingLine />
            </div>
          }
        />

        <Restricted
          allowedRoles={['admin', 'course manager', 'content manager', 'instructor', 'course viewer']}
          component={
            <div>
              <SideNavSection
                onClick={goToReports}
                isActive={location.pathname.startsWith('/nav/reports')}
                label={t('Reports')}
              />
              <DividingLine />
            </div>
          }
        />

        <Restricted
          allowedRoles={[]}
          component={
            <div>
              <SideNavSection
                onClick={handleControlPanelClick}
                isActive={location.pathname.startsWith('/nav/control-panel')}
                label={t('Control Panel')}
              />
              <DividingLine />
            </div>
          }
        />

        {!appcuesLoaded && process.env.REACT_APP_ENV === 'stable' && <div style={{ height: '200px' }} />}
        {appcuesLoaded && process.env.REACT_APP_ENV === 'stable' && (
          <div>
            <ExternalLinkContainer style={{ marginTop: '25px' }}>
              <Label>{t('Announcements')}</Label>
              <div style={{ marginLeft: '14px', alignSelf: 'center', color: primaryGreen }} id="appcues-launchpad" />
            </ExternalLinkContainer>
            <DividingLine />
          </div>
        )}

        <ExternalLinkContainer style={{ marginTop: '25px' }} href={LINKS.HELP} target="_blank">
          <ExternalLinkLabel>{t('Help')}</ExternalLinkLabel>
          <ExternalLinkIcon src={externalLinkIcon} />
        </ExternalLinkContainer>
        {currentUser?.primaryPartner?.expiresAt && (
          <React.Fragment>
            <InfoLabel>{freeTrialExpiresText}</InfoLabel>
            {currentUserRole === 'admin' && (
              <UpgradeButton onClick={handleUpgradeClick}>
                <GreenLabel>{t('UPGRADE NOW')}</GreenLabel>
              </UpgradeButton>
            )}
          </React.Fragment>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: ${Color.sideNavDark};
  width: 200px;
  min-width: 200px;

  @media (max-width: 767px) {
    height: 100vh;
    position: absolute;
    z-index: 12;
  }

  > div {
    height: 100vh;
    width: 200px;
    //position: fixed;
    z-index: 11; // this puts the side nav on top of content
  }
`;

const CeregoLogoButton = styled.button`
  width: 100%;
  align-items: center;
  background: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
`;

const CeregoLogo = styled.img`
  width: 102px;
  height: 19px;
`;

const PartnerLogoStyle = {
  width: '60px',
  height: '60px',
  border: `3px solid ${Color.lightGray}`,
  margin: '30px auto 10px auto',
  display: 'flex',
};

const PartnerName = styled.h3`
  color: ${Color.lightGray};
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  line-height: 19px;
  min-height: 19px;
  padding: 0px 20px;
  margin: 5px 0;
`;

const PartnerRole = styled(PartnerName)`
  font-size: 12px;
`;

const SiteAdminDetails = styled(PartnerName)`
  font-size: 12px;
`;

const SiteAdminDetailsButton = styled.button`
  width: 100%;
  align-items: center;
  background: transparent;
  border: none;
`;

const UpgradeButton = styled.button`
  width: 100%;
  background: transparent;
  border: none;
`;

const Label = styled.h4`
  color: ${Color.lightGray};
  font-weight: 400;
  font-size: 12px;
  margin-left: 20px;
  line-height: 19px;
`;

const InfoLabel = styled(Label)`
  margin: 25px 0px 0px 20px;
`;

const GreenLabel = styled(Label)`
  color: ${Color.ceregoGreen};
  overflow: hidden;
  cursor: pointer;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
`;

const CaretDown = styled(FontAwesomeIcon)`
  color: ${Color.ceregoGreen};
  width: 14px;
  height: 14px;
  margin-left: 8px;
`;

const UsernameContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;
  padding-left: 0px;
  font-family: inherit;
  font-size: 12px;
`;

const ExternalLinkContainer = styled.a`
  display: flex;
  cursor: pointer;
  margin-bottom: 0.25em;
  text-decoration: none;
`;

const ExternalLinkIcon = styled.img`
  width: 8.5px;
  height: 9px;
  margin: auto 0 auto 8px;
  padding-bottom: 1em;
`;

const ExternalLinkLabel = styled(Label)`
  margin-top: 0;
`;

const UserDropDownMenu = styled.div`
  width: 15em;
  user-select: none;
  padding: 1em 0em 0em 0em;
`;

const UserName = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.3125em;
  padding: 0.75em;
  word-break: break-word;
`;

const UserEmail = styled.div`
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 1.5em;
  padding: 0.75em;
  word-break: break-word;
`;

const UserAvatarStyle = {
  width: '3em',
  height: '3em',
  borderRadius: '3em',
  margin: '0em auto 0.5em auto',
  display: 'flex',
};

const UserMenuOption = styled.div`
  border-top: solid 1px ${Color.veryLightGray};
`;

const UserMenuLink = styled.button`
  width: 100%;
  padding: 1.25em;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
  display: grid;
  grid-template-columns: 1fr 1em;
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: 16px;
  text-align: left;

  &:focus {
    background-color: ${Color.primaryGray};
    color: ${Color.primaryBlue};
  }
  &:hover {
    background-color: ${Color.primaryGray};
    color: ${Color.primaryBlue};
  }
`;
