import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { TAnnotation } from 'types';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

interface CreateICTextAnnotationParams {
  itemId: string;
}

const createICTextAnnotation = async ({ itemId }: CreateICTextAnnotationParams): Promise<TAnnotation> => {
  const createAnnotationResponse = await axios.post(`/api/v3/items/${itemId}/annotations`, {
    item_id: itemId,
    include: 'video,document,sound,video',
    data: {
      attributes: {
        'note-type': 'text',
        text: '',
        'text-html': '<p>Write something here...</p>',
      },
    },
  });
  const newTextAnnotation = normalizeJSONAPIResponse(createAnnotationResponse.data);

  return newTextAnnotation;
};

export const useCreateICTextAnnotation = (itemId: string = '', setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(createICTextAnnotation, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
