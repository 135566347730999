import { Typography } from '@material-ui/core';
import axios from 'axios';
import { Color } from 'core';
import { Card, ResponsiveCard, Row } from 'core/layout';
import { BaseSwitch } from 'core/switch';
import decamelize from 'decamelize';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { TPartner, TPartnerSettings } from 'types';

const InputField = styled.input`
  outline: none;
  font-size: 1em;
  font-family: inherit;
  height: 1.5em;
  padding: 0.5em;
  width: 50%;
`;

const FormContainer = styled(ResponsiveCard)`
  margin-top: 20px;
  width: 100%;
`;

const Label = styled(Typography)`
  padding-right: 1.5em;
`;

const FieldFlexRow = styled(Row)`
  width: auto;
  padding: 15px !important;
`;

const CardField = styled(Card)`
  width: 100%;
`;

const SaveButton = styled.button`
  background: ${Color.lightGreen};
  border: transparent;
  border-radius: 5px;
  color: ${Color.white};
  padding: 15px;
  font-weight: bold;
  width: 150px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const fieldLabels = {
  accountManagementEnabled: 'Account Management Enabled',
  allowApiAccess: 'Allow API Access',
  allowContentImportExport: 'Allow Content Import/Export',
  allowDataExportsApi: 'Allow Data Exports API',
  assessmentShowsAnswers: 'Assessment Shows Answers',
  blockClicktracking: 'Block Clicktracking',
  canEditAssessmentAnswers: 'Can Edit Assessment Answers',
  createdAt: 'Created At',
  customTagEnabled: 'Custom Tag Enabled',
  defaultGoal: 'Default Goal',
  departmentsEnabled: 'Departments Enabled',
  editorCanCreate: 'Editor Can Create',
  emailDomainWhitelistingEnabled: 'Email Domain Whitelisting Enabled',
  graduateDifficultyDefault: 'Graduate Difficulty Default',
  instructorCanEditCourse: 'Instructor Can Edit Course',
  isSalesLead: 'Is Sales Lead',
  joinUrlEnabled: 'Join URL Enabled',
  learnVersion: 'Learn Version',
  libraryEnabled: 'Library Enabled',
  ltiCourseInSetContext: 'LTI Course in Set Context',
  memberIdEnabled: 'Member ID Enabled',
  memberIdSlug: 'Member ID Slug',
  notificationDisabled: 'Notification Disabled',
  notificationManagementEnabled: 'Notification Management Enabled',
  partnerAdminBlockEnabled: 'Disable Partner Admin Access',
  partnerId: 'Partner ID',
  passwordPrefix: 'Password Prefix',
  paymentsCurrency: 'Payments Currency',
  paymentsEnabled: 'Payments Enabled',
  paymentsRequired: 'Payments Required',
  price: 'Price',
  reportLmsId: 'Report LMS ID',
  scormSetCreationEnabled: 'SCORM Set Creation Enabled',
  showNewNav: 'Show New Nav',
  standingEnabled: 'Standing Enabled',
  strictSessionExpirationInMinutes: 'Strict Session Expiration in Minutes',
  stripeProductId: 'Stripe Product ID',
  updatedAt: 'Updated At',
  webhookEventsUrl: 'Webhook Events URL',
};

const editableFields = new Set<keyof TPartnerSettings>([
  'allowApiAccess',
  'assessmentShowsAnswers',
  'canEditAssessmentAnswers',
  'instructorCanEditCourse',
  'graduateDifficultyDefault',
  'memberIdEnabled',
  'memberIdSlug',
  'partnerAdminBlockEnabled',
]);

interface IPartnerFormProps {
  title: string;
  partner: TPartner;
}

export const PartnerSettingsForm = (props: IPartnerFormProps) => {
  const [formData, setFormData] = useState<TPartnerSettings>(props.partner.partnerSettings);

  const onSubmit = useCallback(async () => {
    if (!props.partner) {
      console.warn('Cannot update partner settings because no partner is present.');
      return;
    }
    const cleanFields = Object.entries(formData).reduce((acc, [key, value]) => {
      if (editableFields.has(key as keyof TPartnerSettings) && value !== null && value !== undefined) {
        acc[decamelize(key, { separator: '_' })] = value;
      }
      return acc;
    }, {});

    return await axios.put(`/api/v3/partners/${props.partner.id}`, {
      partner_settings: cleanFields,
    });
  }, [props.partner, formData]);

  const [updateSettings, { isLoading }] = useMutation(onSubmit, {
    onError: () => {
      alert('There was an error saving partner settings. Please try again.');
    },
  });

  return (
    <FormContainer>
      <Typography variant="h5">{props.title}</Typography>
      <form onSubmit={(e) => e.preventDefault()}>
        {Object.keys(props.partner.partnerSettings)
          .sort()
          .map((key) => {
            if (key === 'partnerId') return null;
            switch (typeof props.partner.partnerSettings[key]) {
              case 'boolean':
                return (
                  <CardField>
                    <FieldFlexRow justifyContent="space-between" alignItems="center">
                      <Label variant="body1">{fieldLabels[key]}</Label>
                      <BaseSwitch
                        disabled={!editableFields.has(key as keyof TPartnerSettings)}
                        onChange={() => setFormData({ ...formData, [key]: !formData[key] })}
                        checked={formData[key]}
                      />
                    </FieldFlexRow>
                  </CardField>
                );
              default:
                return (
                  <CardField>
                    <FieldFlexRow justifyContent="space-between" alignItems="center">
                      <Label variant="body1">{fieldLabels[key] || key}</Label>
                      <InputField
                        type={typeof props.partner.partnerSettings[key] === 'number' ? 'number' : 'text'}
                        name={key}
                        id={key}
                        readOnly={!editableFields.has(key as keyof TPartnerSettings)}
                        value={formData[key]}
                        onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                      />
                    </FieldFlexRow>
                  </CardField>
                );
            }
          })}
        <Row alignItems="center" justifyContent="flex-end">
          <SaveButton disabled={isLoading} onClick={() => updateSettings()}>
            {isLoading ? 'Saving...' : 'Save'}
          </SaveButton>
        </Row>
      </form>
    </FormContainer>
  );
};
