import { FormHelperText, MenuItem, Select } from '@material-ui/core';
import axios from 'axios';
import { PrimaryButton } from 'components/buttons/v4';
import { CardHeader, CardHeaderContainer, ResponsiveCard } from 'core/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/profile';

export const ChangeUserLanguageCard = () => {
  const user = useSelector(state => getCurrentUser(state));
  const [locale, setLocale] = React.useState(user.locale || 'browser');
  const [saving, setSaving] = React.useState(false);
  const { t } = useTranslation();

  async function saveLocale() {
    setSaving(true);
    const localeValue = locale === 'browser' ? null : locale;
    await axios.put(`/api/v2/users/${user.id}`, { locale: localeValue });
    window.location.reload();
  }

  function handleLocaleChange(event) {
    setLocale(event.target.value);
  }

  return (
    <ResponsiveCard>
      <CardHeaderContainer>
        <CardHeader>{t('Change Your Language')}</CardHeader>
      </CardHeaderContainer>
      <div>
        <Select value={locale} onChange={handleLocaleChange}>
          <MenuItem value={'browser'}>{t('Browser Setting')}</MenuItem>
          <MenuItem value={'en'}>English ({t('English')})</MenuItem>
          <MenuItem value={'ar'}>عربي ({t('Arabic')})</MenuItem>
          <MenuItem value={'zh-CN'}>简体中文 ({t('Chinese, Simplified')})</MenuItem>
          <MenuItem value={'zh-TW'}>繁體中文 ({t('Chinese, Traditional')})</MenuItem>
          <MenuItem value={'ja'}>日本語 ({t('Japanese')})</MenuItem>
          <MenuItem value={'es'}>Español ({t('Spanish')})</MenuItem>
        </Select>
        <FormHelperText>
          {t('Determines the displayed language for Cerego. Assignments may not be affected by this setting.')}
        </FormHelperText>
      </div>
      <div>
        <PrimaryButton type="submit" click={saveLocale} pending={saving}>
          {t('Save')}
        </PrimaryButton>
      </div>
    </ResponsiveCard>
  );
};
