import { Color } from 'core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useStore from 'zstore';

interface IModalPositionProps {
  top?: number;
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${Color.primaryGray};
  z-index: 100;
`;

// TODO would be better if the positioning were in the modal component itself
export const ModalPosition = styled.div`
  margin: ${(props: IModalPositionProps) => (props.top ? props.top : '1.5')}em 0;
`;

const ModalElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  show: boolean;
  element: any;
  angularEmbed?: boolean;
}

export const Modal = (props: Props) => {
  const { show, element, angularEmbed } = props;
  const { setShowModal } = useStore();

  useEffect(() => {
    setShowModal(show);

    return () => setShowModal(false);
  }, [show]);

  return show ? (
    <ModalContainer>
      <ModalElement style={angularEmbed ? { height: '100%' } : {}}>{element}</ModalElement>
    </ModalContainer>
  ) : null;
};
