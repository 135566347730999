import React, { useEffect, useState } from 'react';
import ceregoLogo from 'assets/images/cerego-logo.svg';
import { useLocation } from 'react-router';
import { fetchWithCSRFToken } from './registrationUtils';
import { PageTitle } from 'core/layout';
import { ErrorMessage } from 'core/typography';

export function ConfirmEmailPage() {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      try {
        await fetchWithCSRFToken(`/api/v3/activation_tokens/${token}?activation_token_type=email_confirmation`);
        window.location.href = '/app/nav/dashboard';
      } catch (err) {
        console.error('Error verifying email confirmation token:', err);
        setError('Invalid or expired email confirmation link. Please request a new one.');
      }
    })();
  }, [token]);

  return (
    <>
      <div className="mb-4">
        <PageTitle>Confirming Your Email</PageTitle>
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}
