import { Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as colours from 'core/colors';
import { useState } from 'react';
import { MemreFlexBox } from './index';

export default function MemreSectionHeading({
  text,
  cta,
  onChange,
}: {
  text: string | string[];
  cta?: any;
  onChange?: any;
}) {
  const isTabs = Array.isArray(text);

  const [selectedTab, setSelectedTab] = useState(0);

  const onChangeMid = (_: any, newValue: number) => {
    setSelectedTab(newValue);
    onChange(newValue);
  };

  return (
    <MemreFlexBox
      gap={2}
      justify="space-between"
      direction="row"
      align="center"
      sx={{
        p: 3,
        pb: isTabs ? 0 : 3,
        borderBottom: `1px solid ${colours.veryLightGray}`,
      }}
    >
      {(isTabs && (
        <Tabs value={selectedTab} onChange={onChangeMid}>
          {(text as string[])?.map((t: string, i: number) => (
            <Tab label={t} key={i} sx={{ pb: 3 }} />
          ))}
        </Tabs>
      )) || <Typography variant="h4">{text}</Typography>}
      {cta}
    </MemreFlexBox>
  );
}
