import LoadingComponent from 'components/LoadingComponent';
import { ManageTagsLink } from 'components/ManageTagsLink';
import { ReadinessTable } from 'components/ReadinessTable';
import { NotFound } from 'components/NotFound';
import { UserProfileHeader } from 'components/UserProfileHeader';
import { ContentSection, PageContainer } from 'core/layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useUser } from 'hooks/useUser';
import React from 'react';
import styled from 'styled-components';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';

const MainFrame = styled.div`
  height: 100vh;
  display: flex;
  flex-grow: 1;
  border: none;
`;

const TableContainer = styled.div`
  padding: 2em 1.75em;
`;

interface IUserProfile {
  match: { params: { userId: string } };
}

export const UserProfile = (props: IUserProfile) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const partner = currentUser?.primaryPartner;
  const { userId } = props.match.params;

  const isPartnerAdmin: boolean = partner.meta.role === 'admin';
  const isProfileUser: boolean = currentUser.id === userId;
  const authorized: boolean = isPartnerAdmin || isProfileUser;

  const { data: user, isLoading: userLoading } = useUser(partner.id, userId, isProfileUser);
  const isLoading: boolean = userLoading || !currentUser;
  const profileUser = isProfileUser ? currentUser : user;
  const userName: string = profileUser?.name;
  const userEmail: string = profileUser?.email;

  useDocumentTitle(t('User Profile'));

  // We don't want to show profile info before we have current user info
  // Nor do we want to jump the gun and show an "Unauthorized" message
  if (isLoading) {
    return <LoadingComponent isLoading={true} />;
  }

  if (authorized) {
    return (
      <MainFrame>
        <PageContainer>
          <ContentSection>
            <UserProfileHeader name={userName} email={userEmail} />
          </ContentSection>
          <TableContainer>
            <ReadinessTable userId={userId} />
          </TableContainer>
          {isPartnerAdmin && profileUser && (
            <ContentSection>
              <ManageTagsLink partnerId={partner.id} userId={profileUser.id} />
            </ContentSection>
          )}
        </PageContainer>
      </MainFrame>
    );
  }

  return (
    <MainFrame>
      <PageContainer>
        <ContentSection>
          <NotFound />
        </ContentSection>
      </PageContainer>
    </MainFrame>
  );
};
