import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Color } from 'core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  totalCount: number | null;
  page: number | null;
  pageSize: number;
  goToPageOne: () => void;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  goToLastPage: () => void;
  onPageSizeChange?: (newSize: number) => void;
}

export const Pagination = (props: Props) => {
  const { totalCount, page, pageSize, goToPageOne, goToPreviousPage, goToNextPage, goToLastPage, onPageSizeChange } =
    props;
  const { t } = useTranslation();

  if (totalCount === null || page === null) {
    return null;
  }

  const totalPages = Math.ceil(totalCount / pageSize);
  const firstItem = pageSize * (page - 1) + 1;
  const lastItem = Math.min(pageSize * page, totalCount);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(event.target.value);
    if (onPageSizeChange) {
      onPageSizeChange(newSize);
    }
  };

  return (
    <Container>
      <InfoContainer>
        {t('Shared Content Table Assignment Pagination Text', {
          firstItem,
          lastItem,
          totalCount,
        })}
        <PageInfo>{t('Page {{current}} of {{total}}', { current: page, total: totalPages })}</PageInfo>
      </InfoContainer>

      {onPageSizeChange && (
        <PageSizeContainer>
          <label htmlFor="pageSizeSelect">{t('Items per page')}:</label>
          <PageSizeSelect
            id="pageSizeSelect"
            value={pageSize}
            onChange={handlePageSizeChange}
            aria-label={t('Select number of items per page')}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </PageSizeSelect>
        </PageSizeContainer>
      )}

      <NavigationContainer>
        <PaginationButton onClick={goToPageOne} disabled={page === 1} aria-label={t('Go to first page')}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </PaginationButton>
        <PaginationButton onClick={goToPreviousPage} disabled={page === 1} aria-label={t('Go to previous page')}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </PaginationButton>
        <PaginationButton onClick={goToNextPage} disabled={page === totalPages} aria-label={t('Go to next page')}>
          <FontAwesomeIcon icon={faAngleRight} />
        </PaginationButton>
        <PaginationButton onClick={goToLastPage} disabled={page === totalPages} aria-label={t('Go to last page')}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </PaginationButton>
      </NavigationContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${Color.reportGray};
  margin: 20px 5px 10px 0;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const PageInfo = styled.span`
  margin-left: 1em;
  color: ${Color.secondaryGray};
  font-size: 0.9em;
`;

const PageSizeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;

  label {
    margin-right: 0.5em;
    font-size: 0.9em;
  }
`;

const PageSizeSelect = styled.select`
  padding: 4px 8px;
  border: 1px solid ${Color.secondaryGray};
  border-radius: 4px;
  background-color: white;
  color: ${Color.black};

  &:focus {
    outline: none;
    border-color: ${Color.primaryBlue};
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationButton = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  color: ${Color.darkGray};
  cursor: pointer;
  padding: 0 8px;
  font-size: 1em;

  &:hover {
    color: ${Color.primaryBlue};
  }

  &:disabled {
    color: ${Color.secondaryGray};
    cursor: not-allowed;

    &:hover {
      color: ${Color.secondaryGray};
    }
  }
`;
