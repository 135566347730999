import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ContentInsights } from 'components/reports/ContentInsights';
import { DailyStudyTime } from 'components/reports/DailyStudyTime';
import { LearnerStats } from 'components/reports/LearnerStats';
import { People } from 'components/reports/People';
import { Color } from 'core';
import { useUserRole } from 'hooks/useUserRole';
import React, { useState } from 'react';
import { UserRole } from 'types';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

export const ReportsPage = () => {
  const { t } = useTranslation();
  const [report, setReport] = useState<ReportType>('Select Report');

  useDocumentTitle(t('Reports'));

  return (
    <div style={{ width: '100%' }}>
      <h1 style={{ marginLeft: '20px' }}>Reports</h1>
      <div
        style={{
          marginTop: '20px',
          paddingTop: '20px',
          backgroundColor: Color.white,
          border: `1px solid ${Color.lightGray}`,
          borderRadius: '5px',
          paddingBottom: '50px',
        }}
      >
        <ReportSelector report={report} setReport={setReport} />

        {report === 'Daily Study Time' && <DailyStudyTime />}
        {report === 'Content Insights' && <ContentInsights />}
        {report === 'Learner Stats' && <LearnerStats />}
        {report === 'People' && <People />}
      </div>
    </div>
  );
};

interface ReportSelectorProps {
  report: ReportType;
  setReport: (r: ReportType) => void;
}

const ReportSelector = (props: ReportSelectorProps) => {
  const { report, setReport } = props;
  const { role: userRole, isSiteAdmin: userIsSiteAdmin } = useUserRole();
  const { t } = useTranslation();

  const REPORTS: Array<ReportMeta> = [
    {
      id: 'Select Report',
      name: t('Select Report'),
      description: t('Select a report to get started.'),
      permittedRoles: ['admin', 'course manager', 'content manager', 'instructor', 'editor', 'course viewer'],
    },
    {
      id: 'Content Insights',
      name: t('Content Insights'),
      description: t('Track learner performance on assignments, item by item.'),
      permittedRoles: ['admin', 'course manager', 'content manager', 'course viewer', 'instructor'],
    },
    {
      id: 'Daily Study Time',
      name: t('Daily Study Time'),
      description: t('See how much time learners are spending on an assignment, day by day.'),
      permittedRoles: ['admin', 'course manager', 'course viewer', 'instructor'],
    },
    {
      id: 'Learner Stats',
      name: t('Learner Stats'),
      description: t('Track learner progress on courses and assignments.'),
      permittedRoles: ['admin', 'course manager', 'course viewer', 'instructor'],
    },
    {
      id: 'People',
      name: t('People'),
      description: t('See a list of everyone in your account.'),
      permittedRoles: ['admin'],
    },
  ];

  const getReportMetaByName = (name: ReportType, role: UserRole, isSiteAdmin: boolean): ReportMeta => {
    const reports = REPORTS.filter((r: ReportMeta) => isSiteAdmin || r.permittedRoles.includes(role));
    const report = reports.find((r: ReportMeta) => r.name === name);
    if (!report) {
      throw new Error(`Could not find report with name ${name}`);
    }

    return report;
  };

  const reports = REPORTS.filter((r: ReportMeta) => userIsSiteAdmin || r.permittedRoles.includes(userRole));
  const [value, setValue] = useState<ReportMeta | null>(null);
  const [inputValue, setInputValue] = useState(
    getReportMetaByName(report, userRole, userIsSiteAdmin).name.toString() || ''
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
        id="reports-filter"
        value={value}
        onChange={(_event: any, newValue: any) => {
          if (newValue === null) {
            setValue(null);
            setReport('Select Report');
          } else {
            setValue(newValue);
            setReport(newValue.name);
          }
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue: string) => {
          setInputValue(newInputValue);
        }}
        options={reports.filter((r: ReportMeta) => r.name !== 'Select Report')}
        getOptionLabel={(option: ReportMeta) => option.name}
        style={{ margin: '20px', width: '50%' }}
        renderInput={(params) => <TextField {...params} label={t('Select report')} variant="outlined" />}
      />
      <p style={{ width: '50%', margin: '20px', color: Color.reportGray }}>
        {getReportMetaByName(!report ? 'Select Report' : report, userRole, userIsSiteAdmin).description}
      </p>
    </div>
  );
};

type ReportType = 'Select Report' | 'Daily Study Time' | 'Content Insights' | 'Learner Stats' | 'People';

type ReportMeta = {
  id: ReportType;
  name: string;
  description: string;
  permittedRoles: Array<UserRole>;
};
