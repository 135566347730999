import TextField from '@material-ui/core/TextField';
import { AttachIc } from 'components/item-editor/AttachIc';
import { CautionTape } from 'components/item-editor/CautionTape';
import { GraduatedDifficultyFormFields } from 'components/item-editor/GraduatedDifficultyFormFields';
import { useItemEditorStore } from 'components/item-editor/store';
import { useAutosaveStatus } from 'hooks/useAutosaveStatus';
import { useMutateConcept } from 'hooks/useMutateConcept';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AutosaveStatus } from '../AutosaveStatus';

export const FreeResponseEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <Container>
      <FreeResponseQuestionFormFields />
      <CautionTape />
    </Container>
  );
};

const FreeResponseQuestionFormFields = () => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();

  const concept = item?.facets[0]?.anchor;
  const [conceptText, setConceptText] = useState(concept?.text);
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useAutosaveStatus();

  const handleConceptTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConceptText(event.target.value);
  };

  // update concept text automatically on changed input
  // debounce to avoid making too many requests
  useEffect(() => {
    if (item == null) {
      return;
    }
    if (conceptText !== concept.text) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateConcept({
          id: concept.id,
          payload: {
            data: {
              attributes: {
                'text-html': conceptText,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));

        // update app state
        const updatedFacets = item.facets.map((facet) => ({
          ...facet,
          anchor: {
            ...facet.anchor,
            text: conceptText,
          },
        }));
        updateActiveItem({ facets: updatedFacets });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [conceptText, mutateConcept, concept, item, setAutosaveStatus, updateActiveItem]);

  return (
    <>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <TextField
          id={`${concept}-text`}
          label={'Ask a Question'}
          value={conceptText}
          onChange={handleConceptTextChange}
          placeholder={'Ask question here'}
          variant="outlined"
          style={{
            fontSize: '24px',
            fontWeight: 500,
            padding: '5px 0',
            width: '100%',
          }}
        />
      </div>
      <AutosaveStatus status={autosaveStatus} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
