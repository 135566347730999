import { faArrowDown, faArrowUp, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DownloadCSV } from 'components/DownloadCSV';
import { Restricted } from 'components/Restricted';
import { SortArrow } from 'components/SortArrow';
import { ItemDifficulty, formatDifficulty } from 'components/reports/cells/ItemDifficulty';
import { Pagination } from 'components/reports/controls/Pagination';
import { AssignmentFilter } from 'components/reports/filters/AssignmentFilter';
import { CourseFilter } from 'components/reports/filters/CourseFilter';
import { StudentFilter } from 'components/reports/filters/StudentFilter';
import { Color } from 'core';
import { useAssignments } from 'hooks/useAssignments';
import { useContentInsights } from 'hooks/useContentInsights';
import { useCourse } from 'hooks/useCourse';
import { useStudents } from 'hooks/useStudents';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TAssignment } from 'types';
import { formatStringForCSV } from 'utils/formatStringForCSV';
import { getEditSetUrl } from 'utils/pathUtils';
import { useReportStore } from 'zstore';
import Cards from './cards';

export const ContentInsights = () => {
  return (
    <>
      <Filters />
      <Data />
    </>
  );
};

const Filters = () => {
  return (
    <div>
      <CourseFilter />
      <AssignmentFilter />
      <Restricted
        allowedRoles={['admin', 'course manager', 'instructor', 'course viewer']}
        component={<StudentFilter />}
      />
    </div>
  );
};

const formatPercentCorrect = (template: string, percent: number): string => {
  if (template === 'Ic') {
    return 'N/A';
  }

  if (percent < 100 && percent > 99) {
    return '99%';
  }

  return Math.ceil(percent).toString().concat('%');
};

const Data = () => {
  const courseId = useReportStore((state) => state.courseId);
  const { data: course } = useCourse(courseId || '');
  const assignmentId = useReportStore((state) => state.assignmentId);
  const studentId = useReportStore((state) => state.studentId);
  const { data: students } = useStudents(courseId);
  const student = students?.find((s: any) => s.id === studentId);
  const [totalCount, setTotalCount] = useState(0);
  const [csvData, setCsvData] = useState<Array<any>>([]);

  const { data: response, isLoading } = useContentInsights(courseId, assignmentId, studentId, 1, 200);
  const { data: assignments } = useAssignments(courseId);
  const assignment = assignments?.find((a: TAssignment) => a.id === assignmentId);

  const data = response?.data;
  const meta = response?.meta;

  const contentInsights = data ?? [];

  useEffect(() => {
    if (!!contentInsights) {
      setCsvData(
        contentInsights.map((d: any) => ({
          Course: course?.name || 'N/A',
          Assignment: assignment?.name || 'N/A',
          Student: student?.name || 'Everybody',
          'Item Text': formatStringForCSV(d.anchor),
          'Item Type': formatItemType(d.template),
          Difficulty: d['times_seen'] === 0 ? 'N/A' : formatDifficulty(d['retention_modifier']),
          'Times Seen': d['times_seen'],
          'Times Correct': d.template === 'Ic' ? 'N/A' : d['times_correct'],
          'Percent Correct': formatPercentCorrect(d.template, d['percent_correct']),
          'Student Count': d['students_count'],
        }))
      );
    }
  }, [JSON.stringify(contentInsights)]);

  useEffect(() => {
    if (!!response) {
      setTotalCount(meta['total_count']);
    }
  }, [JSON.stringify(data)]);

  const renderHeader = (title: string) => {
    return (
      <TableHeaderContainer>
        <TableHeaderText>{title}</TableHeaderText>
      </TableHeaderContainer>
    );
  };

  const formatItemType = (itemType: string): string => {
    switch (itemType) {
      case 'Ic':
        return 'Instructional Content';
      case 'Q&A':
        return 'Question & Answer';
      case 'S':
        return 'Sequence';
      case 'R':
        return 'Diagram';
      case 'V':
        return 'Vocabulary';
      case 'A':
        return 'Association Collection';
      case 'Pt':
        return 'Pattern';
      case 'Ps':
        return 'Cloze Container';
      default:
        return itemType;
    }
  };

  const renderDifficultyCell = (row: any) => {
    const isIC = row.row.original['template'] === 'Ic';
    const neverSeen = row.row.original['times_seen'] === 0;

    return <ItemDifficulty na={isIC || neverSeen} retentionModifier={row.row.original['retention_modifier']} />;
  };

  // TODO: Type row
  const columns: ColumnDef<any>[] = [
    {
      id: 'itemText',
      accessorFn: (row: IContentInsightsRowData) => row.anchor,
      header: () => renderHeader('Item Text'),
    },
    {
      id: 'itemType',
      accessorFn: (row: IContentInsightsRowData) => formatItemType(row.template),
      header: () => renderHeader('Item Type'),
    },
    {
      id: 'difficulty',
      accessorFn: (row: IContentInsightsRowData) => row['retention_modifier'],
      cell: (row: ITableRow) => renderDifficultyCell(row),
      header: () => renderHeader('Difficulty'),
    },
    {
      id: 'timesSeen',
      accessorFn: (row: IContentInsightsRowData) => row['times_seen'],
      header: () => renderHeader('Times Seen'),
    },
    {
      id: 'timesCorrect',
      accessorFn: (row: IContentInsightsRowData) => (row.template === 'Ic' ? 'N/A' : row['times_correct']),
      header: () => renderHeader('Times Correct'),
    },
    {
      id: 'percentCorrect',
      accessorFn: (row: IContentInsightsRowData) => formatPercentCorrect(row.template, row['percent_correct']),
      header: () => renderHeader('Percent Correct'),
    },
    {
      id: 'studentCount',
      accessorFn: (row: IContentInsightsRowData) => row['students_count'],
      header: () => renderHeader('Student Count'),
    },
    {
      id: 'editSet',
      accessorFn: (row: IContentInsightsRowData) => row['set_id'],
      header: '',
      cell: (row: ITableRow) => (
        <Restricted
          allowedRoles={['admin', 'course manager', 'content manager', 'instructor', 'editor']}
          component={
            <button
              style={{ background: 'transparent', border: 'none', color: Color.textGray }}
              onClick={() => handleRowClick(row)}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          }
        />
      ),
    },
  ];

  const table = useReactTable({
    data: contentInsights,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const goToPageOne = (): void => {
    if (table.getCanPreviousPage()) {
      table.setPageIndex(0);
    }
  };

  const goToPreviousPage = (): void => {
    if (table.getCanPreviousPage()) {
      table.previousPage();
    }
  };

  const goToNextPage = (): void => {
    if (table.getCanNextPage()) {
      table.nextPage();
    }
  };

  const goToLastPage = (): void => {
    if (table.getCanNextPage()) {
      table.setPageIndex(table.getPageCount() - 1);
    }
  };

  const rightAlignedColumns = ['timesSeen', 'timesCorrect', 'percentCorrect', 'studentCount'];

  const handleRowClick = (row: ITableRow): void => {
    if (assignmentId && !!assignment) {
      window.open(
        getEditSetUrl(assignment.learnVersion, assignmentId, row.row.original['item_id'].toString()),
        '_blank'
      );
    } else {
      console.error('Cannot navigate to item editor with null assignmentId');
    }
  };

  // Show skeleton UI while waiting for table data
  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={500} />;
  }

  // Show empty space if data hasn't yet been requested
  if (!data) {
    return <></>;
  }

  // Show empty table message if we know no data is available for this report
  if (data.length === 0) {
    return (
      <DataContainer>
        <div style={{ height: '100%', margin: '20px' }}>
          <p>No records found.</p>
        </div>
      </DataContainer>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <span style={{ marginRight: '20px' }}>
          <DownloadCSV data={csvData || []} filename={`content-insights-${new Date().toISOString()}.csv`}>
            Download
          </DownloadCSV>
        </span>
      </div>
      <Cards contentInsights={contentInsights} />
      <DataContainer>
        <DataTable>
          <thead style={{ backgroundColor: Color.grayTransparent }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan} style={{ padding: '2px 20px' }}>
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: rightAlignedColumns.includes(header.column.id) ? 'right' : 'left',
                        }}
                        {...{ onClick: header.column.getToggleSortingHandler() }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getIsSorted() === 'asc' && <SortArrow icon={faArrowUp} />}
                        {header.column.getIsSorted() === 'desc' && <SortArrow icon={faArrowDown} />}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                style={{
                  borderTop: '1px solid rgb(224, 224, 224, 1)',
                  borderBottom: '1px solid rgb(224, 224, 224, 1)',
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      padding: '10px 20px',
                      textAlign: rightAlignedColumns.includes(cell.column.id) ? 'right' : 'left',
                      color: Color.reportGray,
                      fontSize: '14px',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </DataTable>

        <Pagination
          totalCount={totalCount}
          page={table.getState().pagination.pageIndex + 1}
          pageSize={table.getState().pagination.pageSize}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </DataContainer>
    </>
  );
};

interface IContentInsightsRowData {
  anchor: string;
  item_id: number;
  percent_correct: number;
  retention_modifier: number;
  students_count: number;
  template: string;
  times_correct: number;
  times_seen: number;
}

interface IRow {
  original: IContentInsightsRowData;
}

interface ITableRow {
  cell: any;
  column: any;
  getValue: any;
  renderValue: any;
  row: IRow;
  table: any;
}

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.54);
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 520px;
`;

const DataTable = styled.table`
  border-collapse: collapse;
  border: 1px solid ${Color.grayTransparent};
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  color: ${Color.primaryBlack};
  font-size: 1em;
  font-weight: 400;
`;
