import { faArrowDown, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Pagination } from 'components/reports/controls/Pagination';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import { useDeleteGroupCourse } from 'hooks/useDeleteGroupCourse';
import { useGroupCourses } from 'hooks/useGroupCourses';
import { usePaginatedMeta } from 'hooks/usePaginatedMeta';
import moment from 'moment';
import { GroupCoursesTableToolbar } from 'pages/admin-dashboard/groups/GroupCoursesTableToolbar';
import { useGroupCoursesStore } from 'pages/admin-dashboard/groups/store';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

type Props = {
  groupId: string;
};

export const GroupCoursesTable = ({ groupId }: Props) => {
  const { data: groupCoursesResponse } = useGroupCourses(groupId);
  const groupCourses = normalizeJSONAPIResponse(groupCoursesResponse?.data);
  const { totalCount, totalPages } = usePaginatedMeta(groupCoursesResponse);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [deleteGroupCourse] = useDeleteGroupCourse(groupId);

  // Pagination and sorting state
  const { pageNumber, setPageNumber, pageSize, setPageSize, sortDirection, setSortDirection } = useGroupCoursesStore();

  console.group('GroupCoursesTable');
  console.log('groupCourses:', groupCourses);
  console.groupEnd();

  // Pagination handlers
  const goToPageOne = () => setPageNumber(1);
  const goToNextPage = () => !!totalPages && pageNumber < totalPages && setPageNumber(pageNumber + 1);
  const goToPreviousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
  const goToLastPage = () => !!totalPages && setPageNumber(totalPages);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1);
  };

  // Sorting handler
  const handleSortingChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setPageNumber(1);
  };

  const handleSelectAllMembers = () => {
    if (selectedMembers.length === groupCourses.length) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(groupCourses.map((course: any) => course.id));
    }
  };

  const handleSelectMember = (memberId: string) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(memberId) ? prevSelected.filter((id) => id !== memberId) : [...prevSelected, memberId]
    );
  };

  const handleUnenroll = (id: string) => {
    deleteGroupCourse({
      groupId,
      courseId: id,
    });
  };

  // Table columns
  const columns: ColumnDef<any>[] = [
    {
      id: 'select',
      header: () => (
        <input
          type="checkbox"
          checked={groupCourses && selectedMembers.length === groupCourses.length}
          onChange={handleSelectAllMembers}
        />
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedMembers.includes(row.original.id)}
          onChange={() => handleSelectMember(row.original.id)}
        />
      ),
    },
    {
      id: 'name',
      accessorFn: (row) => row.original.name,
      cell: ({ row }) => <Link to={`/nav/courses/${row.original.id}`}>{row.original.name}</Link>,
      header: () => (
        <ColumnHeader title="Name" sort="name" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'instructors',
      accessorFn: (row) => row.original.instructorNames,
      cell: ({ row }) => <p>{row.original.instructorNames.join(', ')}</p>,
      header: () => <ColumnHeader title="Instructors" sort="" sortDirection={sortDirection} onSort={() => { }} />,
    },
    {
      id: 'learner-count',
      accessorFn: (row) => row.original.usersCount,
      cell: ({ row }) => <p>{row.original.usersCount}</p>,
      header: () => <ColumnHeader title="Learner Count" sort="" sortDirection={sortDirection} onSort={() => { }} />,
    },
    {
      id: 'assignment-count',
      accessorFn: (row) => row.original.assignmentsCount,
      cell: ({ row }) => <p>{row.original.assignmentsCount}</p>,
      header: () => <ColumnHeader title="Assignment Count" sort="" sortDirection={sortDirection} onSort={() => { }} />,
    },
    {
      id: 'created-at',
      accessorFn: (row) => row.original.createdAt,
      cell: ({ row }) => {
        const createdAt = new Date(row.original.createdAt);
        return <p>{moment(createdAt).format('MMMM Do, YYYY')}</p>;
      },
      header: () => <ColumnHeader title="Created On" sort="" sortDirection={sortDirection} onSort={() => { }} />,
    },
    {
      id: 'actions',
      header: () => <ColumnHeader title="Actions" sort="" sortDirection={sortDirection} onSort={() => { }} />,
      cell: ({ row }) => (
        <ActionButtons>
          <IconButton title="Unenroll" onClick={() => handleUnenroll(row.original.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </ActionButtons>
      ),
    },
  ];

  const table = useReactTable({
    data: groupCourses || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (!['name'].includes(header)) {
      console.warn('Cannot sort by anything other than name');
      return;
    }
    handleSortingChange();
  };

  return (
    <>
      <TableContainer>
        <GroupCoursesTableToolbar />
        <StyledTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} />
        </StyledTable>
      </TableContainer>

      {/* Pagination Component */}
      <Pagination
        totalCount={totalCount}
        page={pageNumber}
        pageSize={pageSize}
        goToPageOne={goToPageOne}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

/** Styled Components **/

const TableContainer = styled.div`
  margin-top: 20px;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

/** Components **/

interface ColumnHeaderProps {
  title: string;
  sort: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
}

const ColumnHeader = ({ title, sort, sortDirection, onSort }: ColumnHeaderProps) => (
  <TableHeaderContainer
    style={Object.assign({ justifyContent: 'left' }, sort === '' ? {} : { cursor: 'pointer' })}
    onClick={onSort}
  >
    <TableHeaderText>{title}</TableHeaderText>
    {sort === 'name' && <SortArrow icon={sortDirection === 'desc' ? faArrowDown : faArrowUp} />}
  </TableHeaderContainer>
);

// TODO: support unenrolling multiple courses at once
// TODO: better empty / loading / error states
