import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { ReactNode } from 'react';

type MemreContainerProps = {
  children: ReactNode;
  direction?: 'row' | 'column' | { [key: string]: 'row' | 'column' };
  size?: 'large' | 'normal' | 'small';
  sx?: SxProps<Theme>;
};

export default function MemreContainer({ children, direction = 'row', size = 'normal', sx = {} }: MemreContainerProps) {
  const styles: any =
    {
      large: {
        p: '20px',
        gap: '20px',
      },
      normal: {},
      small: {},
    }[size] || {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction,
        ...styles,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
