import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { fetchWithCSRFToken } from './registrationUtils';
import { humanizeV3Error } from 'utils/modelUtils';
import { PageTitle } from 'core/layout';
import { BrightInput } from 'components/forms/BrightInput';
import { ErrorMessage } from 'core/typography';
import { PrimaryButton } from 'components/buttons/v4';

export function ResetPasswordPage() {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [minimumPasswordLength, setMinimumPasswordLength] = useState(8);

  const goToSignin = () => {
    setLoading(true);
    window.location.href = '/app/signin';
  };

  const apiEndpoint = `/api/v3/passwords/${token}`;

  useEffect(() => {
    (async () => {
      try {
        const userResponse = await fetchWithCSRFToken(apiEndpoint);
        const minPasswordLength = userResponse.data.meta['min-password-length'];
        setMinimumPasswordLength(minPasswordLength);
      } catch (err) {
        console.error('Error verifying password reset token:', err);
        setError('Invalid or expired password reset link. Please request a new one.');
      }
    })();
  }, [apiEndpoint]);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (loading) return;

    // Clear out old messages
    setMessage('');
    setError('');

    // Basic client-side check to ensure passwords match
    if (newPassword !== confirmNewPassword) {
      setError("Passwords don't match. Please try again.");
      return;
    }

    setLoading(true);

    try {
      await fetchWithCSRFToken(
        apiEndpoint,
        {
          password: newPassword,
          password_confirmation: confirmNewPassword,
          // id: token, //it's in url, so is it necessary?
        },
        'PUT'
      );

      setMessage('Your password has been reset successfully!');
      setResetSuccess(true); // Mark that the reset is successful
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (err: any) {
      console.error('Error resetting password:', err);
      // if 404, it means the token is invalid
      if (err.response.status === 404) {
        setError('Invalid or expired password reset link. Please request a new one.');
      } else {
        const errorString =
          humanizeV3Error(err.response.data.errors) ??
          "We couldn't reset your password. Please verify your link or try again.";
        setError(errorString);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mb-4">
        <PageTitle>Set a new password</PageTitle>
      </div>

      {!resetSuccess && (
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label className="font-bold">New Password</label>
            <BrightInput
              className="!my-1"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder={`${minimumPasswordLength}+ characters`}
              required
              disabled={loading}
            />
          </div>

          <div className="mb-4">
            <label className="font-bold">Confirm New Password</label>
            <BrightInput
              className="!my-1"
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder={`${minimumPasswordLength}+ characters`}
              required
              disabled={loading}
            />
          </div>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <PrimaryButton type="submit" pending={loading} click={() => {}}>
            Update Password
          </PrimaryButton>
        </form>
      )}

      {resetSuccess && (
        <div style={{ textAlign: 'center' }}>
          <div className="text-green-600 mb-4">{message}</div>
          <PrimaryButton pending={loading} click={goToSignin}>
            Go to Sign In
          </PrimaryButton>
        </div>
      )}
    </>
  );
}
