import * as _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { WEB_API_KEY } from '../../config';
import { CALL_API } from '../../middleware/api';
import { IInstructionalContent } from '../../models/instructionalContent';
import {
  IFillInTheBlankPresentation,
  IInstructionalContentPresentation,
  IMultipleChoicePresentation,
  IPresentation,
  IPresentationMultipleChoice,
  IPresentationRegionChoice,
  IRatingPresentation,
  IRegionClickInTheDarkPresentation,
  IRegionMultipleChoicePresentation,
  ISequenceChoice,
  ISequencePresentation,
  PresentationScreens,
  QuizResult
} from '../../models/presentation';
import { IQuiz, IQuizPart, ISequenceQuizPart } from '../../models/quiz';
import { handleTimerClear, handleTimerStart, handleTimerStop, ITimer } from './timer';

interface IStudyData {
  sessionGuid: string;
  startTime: number;
  currentScreen: PresentationScreens;
  presentations: {
    [key: string]: IPresentation;
  };
}

const initialState: IStudyData = {
  sessionGuid: uuid(),
  startTime: Date.now(),
  presentations: {},
  currentScreen: PresentationScreens.Recall
};

export const ADVANCE_PRESENTATION_SCREEN = 'ADVANCE_PRESENTATION_SCREEN';
export const CREATE_PRESENTATION = 'CREATE_PRESENTATION';
export const REMOVE_PRESENTATIONS = 'REMOVE_PRESENTATIONS';

export default function (state = initialState, action): IStudyData {
  switch (action.type) {
    case CREATE_PRESENTATION:
      let newPresentation;

      if (action.data.quiz.type === 'quizzes') {
        if (action.data.goalType === 'survey') {
          action.data.timer.studyTimeMillis = action.data.timer.quizTimeMillis;
          action.data.timer.confirmationTimeMillis = 0;
          action.data.timer.recallTimeMillis = 0;
        }
        switch (action.data.quiz.quizType) {
          case 'FillInTheBlank':
            newPresentation = buildFillInTheBlankPresentation(
              action.data.quiz,
              action.data.enteredText,
              action.data.quizResult,
              action.data.timer,
              state.sessionGuid
            );
            break;
          case 'MultipleChoiceSingleSelect':
          case 'MultipleChoiceMultiSelect':
            newPresentation = buildMultipleChoicePresentation(
              action.data.quiz,
              action.data.answers,
              action.data.quizResult,
              action.data.timer,
              state.sessionGuid
            );
            break;
          case 'Rating':
            newPresentation = buildRatingPresentation(
              action.data.quiz,
              action.data.ratingValue,
              action.data.quizResult,
              action.data.timer,
              state.sessionGuid
            );
            break;
          case 'Sequence':
            newPresentation = buildSequencePresentation(
              action.data.quiz,
              action.data.answers,
              action.data.quizResult,
              action.data.timer,
              state.sessionGuid
            );
            break;
          case 'RegionHiddenPolygons':
            newPresentation = buildRegionClickInTheDarkPresentation(
              action.data.quiz,
              action.data.enteredText,
              action.data.quizResult,
              action.data.timer,
              state.sessionGuid
            );
            break;
          case 'RegionVisiblePolygons':
            newPresentation = buildRegionMultipleChoicePresentation(
              action.data.quiz,
              action.data.answers,
              action.data.quizResult,
              action.data.timer,
              state.sessionGuid
            );
            break;
          default:
            throw new Error(
              "Error, this should never happen. If it does, it's probably due to adding a quiz type without updating presentation creation logic here."
            );
        }
      } else {
        newPresentation = buildInstructionalPresentation(
          action.data.quiz,
          action.data.timer,
          state.sessionGuid,
          action.data.quizResult
        );
      }
      return _.merge(
        {},
        state,
        _.merge(
          {},
          {
            presentations: { [uuid()]: newPresentation }
          }
        )
      );
    case REMOVE_PRESENTATIONS:
      const newPresentations = _.omit(state.presentations, action.presentationGuids);
      return {
        ...state,
        presentations: newPresentations
      };
    case ADVANCE_PRESENTATION_SCREEN:
      return {
        ...state,
        currentScreen: action.nextScreen
      };
    default:
      return state;
  }
}

function buildInstructionalPresentation(
  quiz: IQuiz,
  timer: ITimer,
  sessionGuid: string,
  quizResult: QuizResult
): IInstructionalContentPresentation {
  const now = new Date();

  const newPresentation: IInstructionalContentPresentation = {
    recall_time_millis: null,
    quiz_time_millis: null,
    confirmation_time_millis: null,
    study_time_millis: timer.studyTimeMillis,
    occurred_at: now.toISOString(),
    // TODO: get item_id from new v4 API
    item_id: null,
    learning_engine_guid: quiz.memoryGuid,
    session_guid: sessionGuid,
    quiz_type: 'InstructionalStudy',
    quiz_result: quizResult
  };
  return newPresentation;
}

function buildFillInTheBlankPresentation(
  quiz: IQuiz,
  enteredText: string,
  quizResult: QuizResult,
  timer: ITimer,
  sessionGuid: string
): IFillInTheBlankPresentation {
  const now = new Date();

  const newPresentation: IFillInTheBlankPresentation = {
    recall_time_millis: timer.recallTimeMillis,
    quiz_time_millis: timer.quizTimeMillis,
    confirmation_time_millis: timer.confirmationTimeMillis,
    study_time_millis: timer.studyTimeMillis,
    occurred_at: now.toISOString(),
    item_id: '',
    learning_engine_guid: quiz.memoryGuid,
    session_guid: sessionGuid,
    quiz_type: quiz.learningEngineQuizType,
    quiz_result: quizResult,
    quiz_data_attributes: {
      hint_used: false,
      entered_text: enteredText
    }
  };
  return newPresentation;
}

function buildRatingPresentation(
  quiz: IQuiz,
  ratingValue: number,
  quizResult: QuizResult,
  timer: ITimer,
  sessionGuid: string
): IRatingPresentation {
  const now = new Date();

  const newPresentation: IRatingPresentation = {
    recall_time_millis: timer.recallTimeMillis,
    quiz_time_millis: timer.quizTimeMillis,
    confirmation_time_millis: timer.confirmationTimeMillis,
    study_time_millis: timer.studyTimeMillis,
    occurred_at: now.toISOString(),
    item_id: '',
    learning_engine_guid: quiz.memoryGuid,
    session_guid: sessionGuid,
    quiz_type: quiz.learningEngineQuizType,
    quiz_result: quizResult,
    quiz_data_attributes: {
      rating_value: ratingValue
    }
  };
  return newPresentation;
}

function buildMultipleChoicePresentation(
  quiz: IQuiz,
  answers: IQuizPart[],
  quizResult: QuizResult,
  timer: ITimer,
  sessionGuid: string
): IMultipleChoicePresentation {
  const now = new Date();

  const choices = createPresentationChoices(quiz);
  const choiceIndices = answers.map(answer => _.indexOf(quiz.parts, answer));

  const newPresentation: IMultipleChoicePresentation = {
    recall_time_millis: timer.recallTimeMillis,
    quiz_time_millis: timer.quizTimeMillis,
    confirmation_time_millis: timer.confirmationTimeMillis,
    study_time_millis: timer.studyTimeMillis,
    occurred_at: now.toISOString(),
    item_id: '',
    learning_engine_guid: quiz.memoryGuid,
    session_guid: sessionGuid,
    quiz_type: quiz.learningEngineQuizType,
    quiz_result: quizResult,
    quiz_data_attributes: {
      choices,
      choices_made: choiceIndices
    }
  };
  return newPresentation;
}

function createPresentationChoices(quiz: IQuiz): IPresentationMultipleChoice[] {
  const choices: IPresentationMultipleChoice[] = [];
  for (const part of quiz.parts) {
    // Don't send undefined up to API, prune keys that are false or undefined
    const optionalChoiceParams = _.pickBy(
      {
        text_html: part.textHtml,
        image: part.image && part.image.url,
        concept_id: part.conceptId
      },
      _.identity
    );

    const choice: IPresentationMultipleChoice = _.merge(
      {
        correct: part.isCorrect
      },
      optionalChoiceParams
    );

    choices.push(choice);
  }
  return choices;
}

function buildRegionMultipleChoicePresentation(
  quiz: IQuiz,
  answers: IQuizPart[],
  quizResult: QuizResult,
  timer: ITimer,
  sessionGuid: string
): IRegionMultipleChoicePresentation {
  const now = new Date();

  const choices = createRegionPresentationChoices(quiz);
  const choiceIndex = _.indexOf(quiz.parts, answers[0]);

  const newPresentation: IRegionMultipleChoicePresentation = {
    recall_time_millis: timer.recallTimeMillis,
    quiz_time_millis: timer.quizTimeMillis,
    confirmation_time_millis: timer.confirmationTimeMillis,
    study_time_millis: timer.studyTimeMillis,
    occurred_at: now.toISOString(),
    item_id: '',
    learning_engine_guid: quiz.memoryGuid,
    session_guid: sessionGuid,
    quiz_type: quiz.learningEngineQuizType,
    quiz_result: quizResult,
    quiz_data_attributes: {
      choices,
      choices_made: [choiceIndex]
    }
  };
  return newPresentation;
}

function createSequencePresentationChoices(quiz: IQuiz, isCorrect: boolean): ISequenceChoice[] {
  // only mark as correct if entire sequence is in order
  return _.map(quiz.parts, (part, index) => {
    return {
      'sequence-position': part.correctPosition || index,
      correct: isCorrect
    };
  });
}

function buildSequencePresentation(
  quiz: IQuiz,
  answers: ISequenceQuizPart[],
  quizResult: QuizResult,
  timer: ITimer,
  sessionGuid: string
): ISequencePresentation {
  const now = new Date();

  const choices = createSequencePresentationChoices(quiz, quizResult === 'Correct');
  const choiceIndex = _.map(answers, 'correctPosition');

  const newPresentation: ISequencePresentation = {
    recall_time_millis: timer.recallTimeMillis,
    quiz_time_millis: timer.quizTimeMillis,
    confirmation_time_millis: timer.confirmationTimeMillis,
    study_time_millis: timer.studyTimeMillis,
    occurred_at: now.toISOString(),
    item_id: '',
    learning_engine_guid: quiz.memoryGuid,
    session_guid: sessionGuid,
    quiz_type: quiz.learningEngineQuizType,
    quiz_result: quizResult,
    quiz_data_attributes: {
      choices,
      choices_made: choiceIndex
    }
  };
  return newPresentation;
}

function createRegionPresentationChoices(quiz: IQuiz): IPresentationRegionChoice[] {
  const choices: IPresentationRegionChoice[] = [];
  for (const part of quiz.parts) {
    const choice: IPresentationRegionChoice = {
      polygon: part.polygon || ''
    };

    choices.push(choice);
  }
  return choices;
}

function buildRegionClickInTheDarkPresentation(
  quiz: IQuiz,
  enteredText: string,
  quizResult: QuizResult,
  timer: ITimer,
  sessionGuid: string
): IRegionClickInTheDarkPresentation {
  const now = new Date();

  const correctPart = _.find(quiz.parts, 'isCorrect');
  const newPresentation: IRegionClickInTheDarkPresentation = {
    recall_time_millis: timer.recallTimeMillis,
    quiz_time_millis: timer.quizTimeMillis,
    confirmation_time_millis: timer.confirmationTimeMillis,
    study_time_millis: timer.studyTimeMillis,
    occurred_at: now.toISOString(),
    item_id: '',
    learning_engine_guid: quiz.memoryGuid,
    session_guid: sessionGuid,
    quiz_type: quiz.learningEngineQuizType,
    quiz_result: quizResult,
    quiz_data_attributes: {
      hint_used: false,
      entered_text: enteredText,
      target_text: (correctPart && correctPart.polygon) || ''
    }
  };
  return newPresentation;
}

export const handlePostPresentations = () => {
  return async (dispatch, getState) => {
    const presentationGuids = _.keys(getState().learn.presentationData.presentations);
    const presentations = _.values(getState().learn.presentationData.presentations);
    const actionResponse = await dispatch({
      [CALL_API]: {
        endpoint: '/api/v2/study',
        httpMethod: 'POST',
        data: {
          api_key: WEB_API_KEY,
          presentations: JSON.stringify(presentations)
        }
      }
    });
    if (actionResponse.error) {
      console.error('ERROR POSTING PRESENTATIONS');
    } else {
      // only remove presentations from the redux store if the API call was successful
      // this means we might send up duplicates, but it's ok, the backend filters them
      dispatch(handleRemovePresentations(presentationGuids));
    }
    return actionResponse;
  };
};

export function handleAdvancePresentationScreen(goalType) {
  return async (dispatch, getState) => {
    const { currentScreen } = getState().learn.presentationData;
    dispatch(handleTimerStop(currentScreen));
    dispatch({
      type: ADVANCE_PRESENTATION_SCREEN,
      nextScreen: currentScreen + 1
    });
    dispatch(handleTimerStart());
  };
}

export function handleCreatePresentation(
  quiz: IQuiz | IInstructionalContent,
  answers: IQuizPart[],
  enteredText: string,
  goalType: string,
  quizResult?: QuizResult,
  ratingValue?: number
) {
  return async (dispatch, getState) => {
    dispatch(handleTimerStop(PresentationScreens.Confirmation));
    dispatch({
      type: CREATE_PRESENTATION,
      data: {
        quiz,
        answers,
        enteredText,
        goalType,
        quizResult,
        ratingValue,
        timer: getState().learn.timer
      }
    });
    dispatch({
      type: ADVANCE_PRESENTATION_SCREEN,
      nextScreen: PresentationScreens.Recall
    });
    dispatch(handleTimerClear());
    dispatch(handleTimerStart());
    await dispatch(handlePostPresentations());
  };
}

export function handleRemovePresentations(presentationGuids: string[]) {
  return dispatch => {
    dispatch({
      type: REMOVE_PRESENTATIONS,
      presentationGuids
    });
  };
}
