import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { lightGray, textGray, veryLightGray } from '../core/colors';

interface IOwnProps {
  type?: 'csv' | 'image';
  partnerId?: string;
  onUploadComplete: (res: any) => void;
  uploadType?: 'users' | 'items';
}

const width = '180px';
const height = '150px';
const Container = styled.div`
  width: ${width};
  height: ${height};
  background-color: ${veryLightGray};
  border: 2px dashed ${lightGray};
  border-radius: 4px;
`;

const UploadCallToActionContainer = styled.div`
  width: ${width};
  height: ${height};
  display: table;
  vertical-align: middle;
  position: absolute;
`;
const UploadCallToActionInnerContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: ${textGray};

  div {
    margin: 5px 0;
  }
`;

const DragDropInput = styled.input`
  width: ${width};
  height: ${height};
  cursor: pointer;
  position: absolute;
  text-indent: 200%;
  opacity: 0;
`;

const Image = styled.img`
  width: ${width};
  height: ${height};
  position: absolute;
  object-fit: contain;
`;
type AssetType = 'image' | 'csv';

interface UploadedAsset {
  url: string;
}

export const AssetUploader = (props: IOwnProps) => {
  const [uploadedAsset, setUploadedAsset] = React.useState<UploadedAsset>();
  const [uploadedPercent, setUploadedPercent] = React.useState(0);
  const [showError, setShowError] = React.useState(false);
  const { t } = useTranslation();
  const options = {
    csv: {
      fileType: '.csv',
      uploadText: t('upload a CSV file'),
      postUrl: `/api/v3/partners/${props.partnerId}/user_csv_uploads`,
      errorMessage: t('Error Uploading CSV'),
    },
    image: {
      fileType: 'image',
      uploadText: t('upload an image'),
      postUrl: `/api/v3/images`,
      errorMessage: t('Error Uploading Image'),
    },
  };
  const assetType: AssetType = props.type || 'image';
  const acceptedFileType = options[assetType].fileType;

  const onUploadProgress = (progressEvent) => {
    setUploadedPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  };

  const onInputChange = async (event) => {
    const file = event.target.files[0];
    const data = new FormData();

    data.append('file', file);
    if (props.uploadType) {
      data.append('upload_type', props.uploadType);
    }

    setUploadedPercent(1);
    setShowError(false);
    try {
      const result = await axios.post(options[assetType].postUrl, data, {
        onUploadProgress,
      });
      const asset = normalizeJSONAPIResponse(result.data);
      setUploadedAsset(asset);
      props.onUploadComplete(asset);
    } catch (error) {
      setShowError(true);
    } finally {
      setUploadedPercent(0);
    }
  };

  return (
    <Container>
      {!uploadedAsset && (
        <UploadCallToActionContainer>
          <UploadCallToActionInnerContainer>
            {uploadedPercent === 0 && !showError && (
              <div>
                <div>
                  <FontAwesomeIcon icon={faUpload} style={{ fontSize: '32px' }} />
                </div>
                <div>
                  <strong>{t('Drag & Drop')}</strong>
                </div>
                <div style={{ fontSize: '12px' }}>
                  {t('Or')}{' '}
                  <u>
                    <strong>{options[assetType].uploadText}</strong>
                  </u>
                </div>
              </div>
            )}
            {uploadedPercent > 0 && !showError && (
              <div>{t('Image Upload Modal Percent Uploaded Text', { uploadedPercent })}</div>
            )}
            {showError && <div>{options[assetType].errorMessage}</div>}
          </UploadCallToActionInnerContainer>
        </UploadCallToActionContainer>
      )}
      <DragDropInput type={'file'} accept={acceptedFileType} onChange={onInputChange} />
      {assetType === 'image' && uploadedAsset && <Image src={uploadedAsset.url} />}
      {assetType === 'csv' && uploadedAsset && (
        <UploadCallToActionContainer>
          <UploadCallToActionInnerContainer>{t('CSV upload complete!')}</UploadCallToActionInnerContainer>{' '}
        </UploadCallToActionContainer>
      )}
    </Container>
  );
};
