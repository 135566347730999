import { ResponsivePie } from '@nivo/pie';
import { Color } from 'core';
import React from 'react';
import styled from 'styled-components';

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: ${Color.textGray};
  padding: 0.5em;
  max-width: 25em;
`;
const Responses = styled.strong`
  color: ${Color.lightBlack};
`;
const segmentDisplayOffset = 1;

interface IOwnProps {
  title?: string;
  height?: string;
  data: any[];
}

export class Pie extends React.Component<IOwnProps> {
  public render() {
    const { data } = this.props;
    const margins = { top: 20, right: 10, bottom: 180, left: 10 };
    const pieTotal = data.reduce((total, { value }) => total + value, 0);
    const pieData = data.map(({ label, id, value }) => ({ id: label, label: id, value }));
    const chartLegend: any = {
      anchor: 'bottom',
      direction: 'column',
      translateY: 160,
      itemWidth: 400,
      itemHeight: 25,
      itemTextColor: Color.textGray,
      symbolSize: 18,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemTextColor: Color.lightBlack
          }
        }
      ]
    };
    const chartProps: any = {
      data: this.normalizePieData(pieData, pieTotal),
      margin: margins,
      innerRadius: 0.3,
      sortByValue: true,
      padAngle: 1.5,
      cornerRadius: 3,
      colors: Color.categorical,
      borderWidth: 0.5,
      borderColor: { from: 'color', modifiers: [['darker', 0.5]] },
      animate: true,
      motionStiffness: 90,
      motionDamping: 15,
      enableRadialLabels: true,
      radialLabelsSkipAngle: 20,
      radialLabelsLinkStrokeWidth: 2,
      radialLabelsLinkDiagonalLength: 10,
      radialLabelsLinkHorizontalLength: 50,
      radialLabelsLinkColor: { from: 'color' },
      radialLabel: ({ value }) => this.normalizePieLabel(value, pieTotal),
      enableSlicesLabels: false,
      legends: [chartLegend],
      tooltip: ({ id, value }) => this.normalizePieTooltip(value, pieTotal)
    };

    return <ResponsivePie {...chartProps} />;
  }
  public normalizePieTooltip(value, pieTotal) {
    const s = value !== 1 ? 's' : '';

    return (
      <Tooltip>
        <Responses>
          {pieTotal > 0 ? value : value - segmentDisplayOffset} Response{s}
        </Responses>
      </Tooltip>
    );
  }
  public normalizePieLabel(value, pieTotal) {
    return pieTotal > 0 ? Math.ceil((value / pieTotal) * 100) + '%' : '0%';
  }
  public normalizePieData(data, pieTotal) {
    return pieTotal > 0
      ? data
      : data.map(pieSegment => ({
          ...pieSegment,
          value: pieSegment.value + segmentDisplayOffset
        }));
  }
}
