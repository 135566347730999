import Box from '@mui/material/Box';
import * as colours from 'core/colors';

type Props = {
  duration: number;
  height?: number;
};

export default function MemreDuration({ duration, height = 55 }: Props) {
  const lineCount = duration * 2;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        width: '100%',
        gap: 2,
        mb: '5px',
        mr: '5px',
      }}
    >
      <Box sx={{ height, width: '100%', bgcolor: colours.hexToRGB(colours.brightGreen, 0.2) }}>
        <Box sx={{ height, width: '60%', mx: 'auto', bgcolor: colours.hexToRGB(colours.brightGreen, 0.4) }}>
          <Box sx={{ height, width: '34%', mx: 'auto', bgcolor: colours.hexToRGB(colours.brightGreen, 0.6) }} />
        </Box>
      </Box>
      <Box
        sx={{
          height,
          width: '100%',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
        }}
      >
        {new Array(lineCount).fill(0).map((a, i) => (
          <Box
            key={i}
            sx={{
              height,
              flex: 1,
              borderRight: i === 59 ? '' : '0.4px solid white',
              opacity: 0.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
