import { faBuilding, faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import styled from 'styled-components';

import { Color } from 'core';
import { handleGetPartners } from 'store/partner';
import { getCurrentUser } from 'store/profile';
import { updateUser } from 'store/user';
import { getBaseReactUrl } from 'utils/pathUtils';

interface IUserAvatarProps {
  url: string;
}

const PositionContainer = styled.div`
  position: fixed;
  top: 1.5em;
`;

const PartnerContainer = styled.div`
  color: ${Color.darkGray}
  background: ${Color.white};
  border: 1px solid ${Color.veryLightGray};
  border-radius: 0.125em;
  width: 50em;
`;

const Title = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  color: ${Color.lightBlack}
  border-bottom: 1px solid ${Color.veryLightGray};
  padding: 1em;
`;

const SearchContainer = styled.div`
  border: 1px solid ${Color.lightGray};
  background-color: ${Color.primaryGray};
  color: ${Color.darkGray};
  padding: 0.5em 1em;
  margin: 1em;
`;

const SearchInput = styled.input`
  background: none;
  width: 95%;
  margin-left: 1em;
  border: none;
  outline: none;
`;

const PartnersList = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1em;
  padding: 1em;
`;

const DepartmentIcon = styled.div`
  color: ${Color.lightGreen};
`;

const Partner = styled.div`
  background-color: ${Color.primaryGray};
  padding: 0.4em;
`;

const PartnerImage = styled.div`
  width: 2.5em;
  height: 2.5em;
  border-radius: 2.5em;
  background: #fff url(${(props: IUserAvatarProps) => props.url}) no-repeat center center;
  background-size: cover;
`;

const PartnerListItem = styled.div`
  display: grid;
  grid-template-columns: 2.5em 1fr 0.8em;
  grid-gap: 0.5em;
  align-items: center;
  cursor: pointer;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  margin: 0.5em 0;
`;

const PageNumber = styled.div`
  height: 1.75em;
  width: 1.75em;
  margin: 0 0.5em;
  border-radius: 1em;
  font-weight: bold;
  font-size: 0.8rem;
  text-align: center;
  line-height: 1.9em;
  cursor: pointer;
  &.current {
    background: ${Color.lightGreen};
    color: ${Color.white};
  }
`;

export const PartnersPicker = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');

  const currentUser = useSelector(state => getCurrentUser(state)) || {};
  const partners = useSelector(getPartners) || [];
  const partnersMeta = useSelector(getPartnersMeta) || {};
  const { totalPages, totalCount } = partnersMeta;
  const pageSize = 15;
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(handleGetPartners(pageNumber, searchText));
  }, [pageNumber, searchText, dispatch]);

  function getPartners(state) {
    // raw partners are the partners from the api call, redux stores all partners ever requested, we only want partners
    // from the api call
    const rawPartners = state.data.meta['/api/v3/my/partners']?.data || [];
    const partnerIds = rawPartners.map(partner => partner.id);
    const partners = build(state.data, 'partners') || [];

    // note partners in redux are stored in order of id, not name, we want name
    return _.sortBy(
      partners.filter(partner => partnerIds.includes(partner.id)),
      'name'
    );
  }

  // this gives us the total pages and total count
  function getPartnersMeta(state) {
    return state.data.meta['/api/v3/my/partners']?.meta;
  }

  function fetchPartners(pageNumber) {
    setPageNumber(pageNumber);
  }

  function handleSearchTextChange(e) {
    setPageNumber(1);
    setSearchText(e.target.value);
  }

  async function choosePartner(partnerId) {
    await dispatch(updateUser({ primary_partner_id: partnerId }, currentUser.id));
    window.location.assign(`${getBaseReactUrl()}/app/nav/dashboard`);
  }

  const partnersArray = partners.map((partner, index) => (
    <Partner key={index}>
      <PartnerListItem onClick={() => choosePartner(partner.id)}>
        <PartnerImage url={partner.image?.url} />
        <div>{partner.name}</div>
        {partner.parent && (
          <DepartmentIcon>
            <FontAwesomeIcon icon={faBuilding} />
          </DepartmentIcon>
        )}
      </PartnerListItem>
    </Partner>
  ));

  const decrementPage = () => setPageNumber(pageNumber => Math.max(1, pageNumber - 1));
  const incrementPage = () => setPageNumber(pageNumber => Math.min(totalPages, pageNumber + 1));

  return (
    <PositionContainer>
      <PartnerContainer>
        <Title>Switch to another account ({totalCount})</Title>
        <SearchContainer>
          <FontAwesomeIcon icon={faSearch} />
          <SearchInput placeholder={t('Search Partners')} value={searchText} onChange={handleSearchTextChange} />
        </SearchContainer>
        <PartnersList>{partnersArray}</PartnersList>
        <Pagination>
          {pageNumber > 1 && (
            <div onClick={decrementPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ cursor: 'pointer', verticalAlign: 'middle' }} />
            </div>
          )}

          {pageNumber === totalPages && totalCount > pageSize * 2 && (
            <PageNumber onClick={() => fetchPartners(pageNumber - 2)}>
              {t('Partners Picker Page Number Minus Two', { pageNumber: pageNumber - 2 })}
            </PageNumber>
          )}
          {pageNumber > 1 && (
            <PageNumber onClick={() => fetchPartners(pageNumber - 1)}>
              {t('Partners Picker Page Number Minus One', { pageNumber: pageNumber - 1 })}
            </PageNumber>
          )}

          <PageNumber className="current">{t('Partners Picker Page Number', { pageNumber })}</PageNumber>

          {pageNumber < totalPages && (
            <PageNumber onClick={() => fetchPartners(pageNumber + 1)}>
              {t('Partners Picker Page Number Plus One', { pageNumber: pageNumber + 1 })}
            </PageNumber>
          )}
          {pageNumber === 1 && totalCount > pageSize * 2 && (
            <PageNumber onClick={() => fetchPartners(pageNumber + 2)}>
              {t('Partners Picker Page Number Plus Two', { pageNumber: pageNumber + 2 })}
            </PageNumber>
          )}

          {pageNumber < totalPages && (
            <div onClick={incrementPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ cursor: 'pointer', verticalAlign: 'middle' }} />
            </div>
          )}
        </Pagination>
      </PartnerContainer>
    </PositionContainer>
  );
};
