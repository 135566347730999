import { TSentence } from './../types';
import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const createSentence = async (itemId) => {
  const response = await axios.post(`/api/v3/items/${itemId}/sentences`);
  return normalizeJSONAPIResponse(response.data) as TSentence;
};

export const useCreateSentence = (itemId: string = '', setId: string = '') => {
  return useMutation(createSentence, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
  });
};
