import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStudents } from 'hooks/useStudents';
import React, { useEffect, useState } from 'react';
import { useReportStore } from 'zstore';

export const StudentFilter = () => {
  const courseId = useReportStore((state) => state.courseId);
  const studentId = useReportStore((state) => state.studentId);
  const setStudentId = useReportStore((state) => state.setStudentId);
  const setPage = useReportStore((state) => state.setPage);
  const { data: students, isLoading } = useStudents(courseId);
  const [value, setValue] = useState<any>(students?.find((student: any) => student.id === studentId) || null);
  const [inputValue, setInputValue] = useState(students?.find((student: any) => student.id === studentId)?.name || '');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(null);
    setInputValue('');
  }, [courseId]);

  return (
    <Autocomplete
      id="student-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={isLoading}
      value={value}
      onChange={(_event: any, newValue: any) => {
        if (!!newValue) {
          setValue(newValue);
          setStudentId(newValue.id);
          setPage(1);
        } else {
          setValue(null);
          setStudentId(null);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      options={students?.sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) || []}
      getOptionLabel={(option: any) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ margin: '20px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select student"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      selectOnFocus
    />
  );
};
