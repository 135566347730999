import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import arTranslation from 'translations/ar.json';
import enTranslation from 'translations/en.json';
import esTranslation from 'translations/es.json';
import jaTranslation from 'translations/ja.json';
import zhCNTranslation from 'translations/zh-CN.json';
import zhTWTranslation from 'translations/zh-TW.json';
import { getCookieJSON } from 'utils/cookieUtils';
import { REACT_APP_ENV } from 'utils/environmentUtils';

const resources = {
  en: {
    translation: enTranslation
  },
  es: {
    translation: esTranslation
  },
  ja: {
    translation: jaTranslation
  },
  'zh-CN': {
    translation: zhCNTranslation
  },
  'zh-TW': {
    translation: zhTWTranslation
  },
  ar: {
    translation: arTranslation
  }
};

function toUpperCaseAfterDash(str) {
  return str.replace(/(-.+)/, match => match.toUpperCase());
}

function getNormalizedBrowserLanguage(browserLanguage) {
  const language = browserLanguage
    .toLowerCase()
    .replace('-hant', '-tw')
    .replace('-hans', '-cn');

  if (['zh-cn', 'zh-tw'].includes(language.slice(0, 5))) {
    return toUpperCaseAfterDash(language.slice(0, 5));
  } else if (language.startsWith('zh')) {
    return 'zh-CN';
  } else {
    return language.slice(0, 2);
  }
}

export function getLanguage() {
  const allowedLanguages = ['en', 'ja', 'es', 'zh-CN', 'zh-TW', 'ar'];
  const desiredLanguage = getCookieJSON('_cerego_user').locale || getNormalizedBrowserLanguage(navigator.language);
  return allowedLanguages.includes(desiredLanguage) ? desiredLanguage : 'en';
}

export function isRightToLeft(): boolean {
  return getLanguage() === 'ar';
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: getLanguage(),
  nsSeparator: false,
  keySeparator: false, // docs recomment setting this to false and not using keys with separators like "test.translation" if using flat json
  interpolation: {
    escapeValue: false // react already safe from xss
  },
  debug: REACT_APP_ENV === 'development' // missing translation keys will print to the console in development mode
});

export default i18n;
