import content from 'assets/images/content.svg';
import axios from 'axios';
import ArticleContainer from 'components/ArticleContainer';
import { PrimaryButton } from 'components/buttons/v4';
import { Modal } from 'components/Modal';
import { SearchSharedContent } from 'components/modals/SearchSharedContent';
import { PageContainer } from 'core/layout';
import { LtiLinkResourceTable } from 'courses/components/LtiLinkResourceTable';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { handleGetAssignments } from 'store/assignment';
import { handleGetCourse } from 'store/course';
import { queryParams } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';

interface ILtiLinkResourcePageProps {
  match: { params: { courseId: string } };
}

export const LtiLinkResourcePage = React.memo<ILtiLinkResourcePageProps>((props) => {
  const { courseId } = props.match.params;
  const ltiDeepLinkUrl = decodeURIComponent(queryParams(window.location).deep_link_url);
  const deploymentId = decodeURIComponent(queryParams(window.location).deployment_id);
  const deepLinkingSettingsData = decodeURIComponent(queryParams(window.location).deep_linking_settings_data);
  const dispatch = useDispatch();
  const [courseContent, setCourseContent] = useState<Array<any>>();
  const course = useSelector(getCourse) || {};
  const setIds = useSelector(getSetIds) || [];
  const rawCourseContent = useSelector(getAssignmentsData) || [];
  const hasContent = courseContent && courseContent.length > 0;
  const [showSharedContentModal, setShowSharedContentModal] = React.useState(false);
  const [jwt, setJwt] = React.useState();
  const { t } = useTranslation();

  // Submit form to Tool Consumer once JWT is created
  useEffect(() => {
    if (jwt) {
      document.forms['ltiDeepLinkForm'].submit();
    }
  }, [jwt]);

  const processContent = (resBlob: any) => {
    return resBlob
      .map((content: any) => {
        return {
          id: content.id,
          courseId,
          creator: { id: content.creator.id, type: content.creator.type },
          name: content.name,
          assignmentType: content.goalType,
          participation: content.meta.percentStarted,
          publish_at: content.meta.publishAt,
          due_at: content.meta.dueAt,
          custom_scoring_target: content.meta.levelGoal,
          privacy_type_id: content.privacyType.id,
          learnVersion: content.learnVersion,
          position: content.meta.position,
          image: content.image,
          published: content.meta.published,
          canEdit: content.meta.canEdit,
        };
      })
      .sort((a: any, b: any) => {
        return a.position - b.position;
      });
  };

  function getAssignmentsData(state: any) {
    const assignmentsData = build(state.data, 'sets');
    return assignmentsData && setIds
      ? assignmentsData.filter((assignment: any) => setIds.includes(assignment.id))
      : null;
  }

  useEffect(() => {
    if (rawCourseContent) {
      const processedContent = processContent(rawCourseContent);
      // Initial load
      if (!courseContent && processedContent.length > 0) {
        setCourseContent(processedContent);
      }
      // Update (e.g. adding an assignment from shared content)
      else if (
        // Compare sorted ids to avoid overriding row re-ordering
        courseContent &&
        !_.isEqual(
          processedContent.map((assignment: any) => assignment.id).sort(),
          courseContent.map((assignment) => assignment.id).sort()
        )
      ) {
        setCourseContent(processedContent);
      }
    }
  }, [rawCourseContent]);

  function getCourse(state: any) {
    return build(state.data, 'courses')?.find((course: any) => course.id === courseId);
  }

  function getSetIds(state: any) {
    return state.data.meta[`/api/v4/courses/${courseId}/assignments`]?.data.map((assignment: any) => assignment.id);
  }

  useEffect(() => {
    dispatch(handleGetCourse(courseId));
    dispatch(handleGetAssignments(courseId));
  }, [courseId, dispatch]);

  const contentSegmentNav = (
    <PrimaryButton click={() => handleSearchSharedContentClick()}>{t('Add an Assignment')}</PrimaryButton>
  );

  function handleSearchSharedContentClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Search shared' });
    setShowSharedContentModal(true);
  }

  async function linkResource(assignment: any) {
    const clientId = queryParams(window.location).client_id;
    const response = await axios.post('/api/lti/v13/assignment_link', {
      set_id: assignment['id'],
      course_id: courseId,
      client_id: clientId,
      deep_link_url: ltiDeepLinkUrl,
      deployment_id: deploymentId,
      deep_linking_settings_data: deepLinkingSettingsData,
    });
    setJwt(response.data['jwt']);
  }

  if (showSharedContentModal) {
    return (
      <Modal
        show={showSharedContentModal}
        element={<SearchSharedContent courseId={courseId} onCancel={() => setShowSharedContentModal(false)} />}
      />
    );
  }

  return (
    <PageContainer>
      <ArticleContainer
        iconImage={content}
        title={t('Course Assignments')}
        showIcon={!hasContent}
        segmentNav={contentSegmentNav}
      >
        {hasContent && <LtiLinkResourceTable course={course} data={courseContent} linkResource={linkResource} />}
      </ArticleContainer>
      <form name="ltiDeepLinkForm" method="post" action={ltiDeepLinkUrl}>
        <input type="hidden" name="JWT" value={jwt}></input>
      </form>
    </PageContainer>
  );
});
