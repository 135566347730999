import axios from 'axios';
import { IImage } from 'models/image';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

export const createImage = async (image: File): Promise<IImage> => {
  const uploadData = new FormData();
  uploadData.append('file', image);
  const response = await axios.post('/api/v3/images', uploadData);
  const imageData = normalizeJSONAPIResponse(response.data);

  return imageData;
};
