import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { TAnnotation } from 'types';
import { createVideo } from 'utils/createVideo';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

interface CreateICVideoAnnotationParams {
  itemId: string;
  video: File;
}

const createICVideoAnnotation = async ({ itemId, video }: CreateICVideoAnnotationParams): Promise<TAnnotation> => {
  const videoId = (await createVideo(video)).id;

  // create annotation
  const createAnnotationResponse = await axios.post(`/api/v3/items/${itemId}/annotations`, {
    item_id: itemId,
    include: 'video,document,sound,video',
    data: {
      relationships: {
        video: {
          data: {
            id: videoId,
          },
        },
      },
    },
  });
  const newVideoAnnotation = normalizeJSONAPIResponse(createAnnotationResponse.data);
  return newVideoAnnotation;
};

export const useCreateICVideoAnnotation = (itemId: string = '', setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(createICVideoAnnotation, {
    onSuccess: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
