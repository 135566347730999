import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, MenuItem, ThemeProvider } from '@mui/material';
import Select from '@mui/material/Select';
import {
  MemreButton,
  MemreFlexBox,
  MemrePageContainer,
  MemreText
} from 'components/core';
import { useCreatePartnerUserRole } from 'hooks/useCreatePartnerUserRole';
import { useCurrentUser } from 'hooks/useCurrentUser';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useState } from 'react';
import { PartnerUserRole } from 'utils/formatPartnerUserRole';
import useStore from 'zstore';

type Props = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const InviteStaff = (props: Props) => {
  const { setIsModalOpen } = props;
  const user = useCurrentUser();
  const [createPartnerUserRole] = useCreatePartnerUserRole();
  const [staffMembers, setStaffMembers] = useState([{ email: '', role: 'admin' }]);
  const { makeToast } = useStore();

  // Adds a new empty staff member to the form
  const handleAddStaffMember = () => {
    setStaffMembers([...staffMembers, { email: '', role: 'admin' }]);
  };

  // Removes a staff member from the form
  const handleRemoveStaffMember = (index: number) => {
    const newStaffMembers = [...staffMembers];
    newStaffMembers.splice(index, 1);
    setStaffMembers(newStaffMembers);
  };

  // Updates the email or role of a staff member
  const handleStaffMemberChange = (index: number, field: 'email' | 'role', value: string) => {
    const newStaffMembers = [...staffMembers];
    newStaffMembers[index][field] = value;
    setStaffMembers(newStaffMembers);
  };

  // Checks if all required fields are filled
  const isFormValid = () => {
    return staffMembers.every((member) => member.email && member.role);
  };

  // Handles form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user?.primaryPartner?.id) {
      console.error('Partner ID is missing');
      makeToast({
        text: 'Partner ID is missing',
        isError: true,
      });
      return;
    }

    if (!isFormValid()) {
      console.error('Form is not valid');
      makeToast({
        text: 'Please fill in all required fields',
        isError: true,
      });
      return;
    }

    try {
      // Send requests to invite each staff member
      await Promise.all(
        staffMembers.map((staffMember) =>
          createPartnerUserRole({
            partnerId: user.primaryPartner.id,
            payload: {
              email: staffMember.email,
              role: staffMember.role as PartnerUserRole,
            },
          })
        )
      );
      makeToast({
        text: 'Staff members invited successfully',
        isError: false,
      });
      setStaffMembers([{ email: '', role: 'admin' }]);
    } catch (error) {
      console.error('Error inviting staff members:', error);
      makeToast({
        text: 'Error inviting staff members',
        isError: true,
      });
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          height: '100%',
          width: '100%',
          zIndex: '100',
          position: 'absolute',
          top: '0',
          left: '0',
          backgroundColor: 'white',
        }}
      >
        <MemrePageContainer>
          <MemreFlexBox gap={4} direction="column" sx={{ padding: '1rem' }}>
            <MemreFlexBox gap={4} direction="row" justify="space-between" align="center">
              <MemreText variant="h3">Invite Staff</MemreText>
              <MemreButton
                text="Close"
                variant="outlined"
                onClick={() => setIsModalOpen(false)}
                color="error"
                iconName="close"
                size="small"
              />
            </MemreFlexBox>
            <form onSubmit={handleSubmit}>
              {staffMembers.map((staffMember, index) => (
                <MemreFlexBox key={index} gap={2} align="center" sx={{ marginBottom: '1rem' }}>
                  <TextField
                    type="email"
                    label="Email"
                    value={staffMember.email}
                    onChange={(e) => handleStaffMemberChange(index, 'email', e.target.value)}
                    color="secondary"
                    required
                    fullWidth
                  />

                  <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                    <InputLabel color="secondary">Role</InputLabel>
                    <Select value={staffMember.role} label="Role" onChange={(e: any) => handleStaffMemberChange(index, 'role', e.target.value)} sx={{ paddingTop: '0.4rem', paddingBottom: '0.4rem', paddingLeft: '0.6rem', paddingRight: '0.6rem' }}>
                      <MenuItem key="admin" value="admin">
                        Admin
                      </MenuItem>
                      <MenuItem key="course_manager" value="course_manager">
                        Course Manager
                      </MenuItem>
                      <MenuItem key="content_manager" value="content_manager">
                        Content Manager
                      </MenuItem>
                      <MenuItem key="course_viewer" value="course_viewer">
                        Course Viewer
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <MemreButton
                    text="Remove"
                    variant="text"
                    onClick={() => handleRemoveStaffMember(index)}
                    color="error"
                    size="small"
                  />
                </MemreFlexBox>
              ))}
              <MemreFlexBox gap={2}>
                <MemreButton
                  text="New Staff Member"
                  variant="outlined"
                  color="secondary"
                  onClick={handleAddStaffMember}
                />
                <MemreButton
                  text="Invite"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => handleSubmit(e)}
                  iconName="paperPlane"
                  disabled={!isFormValid()}
                />
              </MemreFlexBox>
            </form>
          </MemreFlexBox>
        </MemrePageContainer>
      </div>
    </ThemeProvider>
  );
};
