import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageUploaderModal } from 'components/ImageUploaderModal';
import { Modal } from 'components/Modal';
import { Restricted } from 'components/Restricted';
import { AvatarButton } from 'components/buttons';
import { CopyLTIButton } from 'components/buttons/CopyLTIButton';
import { CancelButton, SecondaryButton } from 'components/buttons/v4';
import { Color } from 'core';
import { CardContainer, ContentSection, Masthead, PageTitle, TitleSection } from 'core/layout';
import { BaseSwitch } from 'core/switch';
import { EditableText } from 'courses/components/EditableText';
import { useCurrentUser } from 'hooks/useCurrentUser';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { handleGetCourse, handleGetLimitedCourses } from 'store/course';
import { updateSet } from 'store/set';
import styled from 'styled-components';
import { TAssignment } from 'types';
import { getEditSetUrl, getPreviewSetUrl } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';
import { capitalize } from 'utils/stringUtils';

const SetAvatar = styled.div`
  display: inline-block;
  position: relative;
`;

const ButtonSet = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5em;

  > div:last-child {
    flex-grow: 2;
    text-align: right;
  }
`;

const MainButtonWrapper = styled.span`
  button {
    margin: 0em 0.5em 0 0;
  }
`;

interface IProps {
  set: TAssignment;
  courseId: string;
}

export const AssignmentDetailsPageHeader = React.memo<IProps>((props) => {
  const dispatch = useDispatch();
  const { set, courseId } = props;
  const { t } = useTranslation();

  const course = useSelector(getCourse) || {};
  const courses = useSelector(getCourses);
  const [coursesSharingAssignmentCount, setCoursesSharingAssignmentCount] = useState(-1);
  const [name, setName] = useState('');
  const [showSetImageUploadModal, setShowSetImageUploadModal] = React.useState<boolean>(false);
  const [setImage, setSetImage] = React.useState(set.image || null);
  // privacy types
  const privateOnly = 1;
  const coursesOnly = 4;

  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const partnerId = partner?.id;

  useEffect(() => {
    dispatch(handleGetCourse(courseId));
  }, [courseId, dispatch]);

  useEffect(() => {
    if (partnerId) {
      dispatch(handleGetLimitedCourses(partnerId as unknown as string));
    }
  }, [partnerId, dispatch]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      setCoursesSharingAssignmentCount(findCoursesSharingAssignmentCount(courses));
    }
  }, [courses, dispatch, findCoursesSharingAssignmentCount]);

  function getCourses(state: any) {
    return build(state.data, 'limitedCourses');
  }

  function getCourse(state: any) {
    return build(state.data, 'courses')?.find((course: any) => course.id === courseId);
  }

  // Helpers

  function findCoursesSharingAssignmentCount(courses: any) {
    let count = -1; // Exclude the current assignment from the count
    courses.forEach((course: any) => {
      if (course.sets.map((set: any) => set.id).includes(set.id)) {
        count++;
      }
    });
    return count;
  }

  // Component State Handlers

  function handleNameChange(e: any) {
    setName(e.target.value);
  }

  function handleSetImageClick() {
    setShowSetImageUploadModal(true);
  }

  function handleSetImageUploadModalCancel() {
    setShowSetImageUploadModal(false);
  }

  function handleSetImageUploadModalSave(image: any) {
    AnalyticsService.getInstance().track('assignment_edited', { edit_category: 'Assignment image' });
    dispatch(updateSet({ data: { attributes: { icon_image_id: image.id } } }, set.id));
    setSetImage(image);
    setShowSetImageUploadModal(false);
  }

  function toggleSetIsShared() {
    const privacyTypeId = set.isShared ? privateOnly : coursesOnly;
    dispatch(updateSet({ data: { attributes: { privacy_type_id: privacyTypeId } } }, set.id));
    set.isShared = !set.isShared;
  }

  function updateSetTitle(title: any) {
    AnalyticsService.getInstance().track('assignment_edited', { edit_category: 'Assignment title' });
    dispatch(updateSet({ data: { attributes: { name: title } } }, set.id));
    set.name = title;
  }

  function handleEditAssignment() {
    if (!window.top) {
      console.warn('Cannot handle edit assignment, window.top is null');
      return;
    }

    window.top.location.href = getEditSetUrl(set.learnVersion, set.id);
  }

  function handlePreviewSet() {
    if (!window.top) {
      console.warn('Cannot handle preview set, window.top is null');
      return;
    }

    window.top.location.href = getPreviewSetUrl(set.learnVersion, set.id);
  }

  const canEdit: boolean = set.meta.canEdit && set.goalType !== 'scorm';
  const canPreview: boolean = set.goalType !== 'scorm';

  if (showSetImageUploadModal) {
    return (
      <Modal
        show={showSetImageUploadModal}
        element={
          <ImageUploaderModal
            onSaveImage={handleSetImageUploadModalSave}
            onCancel={handleSetImageUploadModalCancel}
            title={t('Update Course Image')}
          />
        }
      />
    );
  }

  return (
    <>
      <Masthead>
        <TitleSection style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ marginRight: '1.75em' }}>
            <SetAvatar>
              <AvatarButton
                click={handleSetImageClick}
                image={setImage}
                entity={set.goalType}
                canEdit={canEdit}
                imageContainerStyle={{ height: '100%', width: '100%' }}
              />
            </SetAvatar>
          </div>
          <div style={{ flexGrow: 2 }}>
            <PageTitle>
              <EditableText
                value={name || set.name}
                onChange={handleNameChange}
                text={set.name ? set.name : t('Untitled Set')}
                aria-label={t('Assignment Title')}
                doneEditingCallback={() => {
                  updateSetTitle(name);
                }}
                canEdit={canEdit}
              />
            </PageTitle>
            {t('Assignment Details Page Header Items Text', {
              goalType: capitalize(set.goalType),
              courseName: course.name,
              count: set.itemsCount,
            })}
            <ButtonSet>
              <MainButtonWrapper>
                {canEdit && <SecondaryButton click={handleEditAssignment}>{t('Edit Assignment')}</SecondaryButton>}
                {canPreview && <CancelButton click={handlePreviewSet}>{t('Preview')}</CancelButton>}
              </MainButtonWrapper>

              <Restricted
                allowedRoles={['admin', 'course manager', 'content manager', 'instructor']}
                component={<CopyLTIButton set={set} />}
              />
            </ButtonSet>
          </div>
        </TitleSection>
      </Masthead>
      <ContentSection>
        {set.isShared && (
          <Restricted
            allowedRoles={['instructor']}
            component={
              <CardContainer>
                <div style={{ float: 'left' }}>
                  <div
                    style={{
                      background: Color.primaryGray,
                      width: '71px',
                      height: '71px',
                      margin: '1em 2em 1em 1em',
                      borderRadius: '50%',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faLink}
                      style={{ color: Color.primaryBlue, position: 'relative', top: '26px', left: '26px' }}
                    />
                  </div>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <p>
                    <strong>{t('From Shared Assignments')}</strong>
                  </p>
                  <p>{t('Updates to this assignment will appear automatically in your course.')}</p>
                </div>
              </CardContainer>
            }
          />
        )}
        <Restricted
          allowedRoles={['admin', 'course manager', 'content manager', 'editor', 'course viewer']}
          component={
            <CardContainer>
              <BaseSwitch onChange={toggleSetIsShared} checked={set.isShared} />{' '}
              {set.isShared ? t('Added to shared assignments') : t('Not a shared assignment')}
              <div style={{ margin: '8px' }}>
                {set.isShared && coursesSharingAssignmentCount > 0 && (
                  <Trans
                    i18nKey="Assignment Details Page Header Assigned Courses Text"
                    count={coursesSharingAssignmentCount}
                  >
                    <strong>{coursesSharingAssignmentCount} other courses</strong> have assigned this
                  </Trans>
                )}
                {set.isShared && (
                  <div style={{ color: Color.cerulean, fontWeight: 600, margin: '8px 0' }}>
                    {t('Be mindful that any edits you make will appear to active learners in other courses.')}
                  </div>
                )}
                {!set.isShared && (
                  <>{t('Assignment Details Page Header Sharing Explanation Text', { goalType: set.goalType })}</>
                )}
              </div>
            </CardContainer>
          }
        />
      </ContentSection>
    </>
  );
});
