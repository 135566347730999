import { Color } from 'core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MoreVert } from '@material-ui/icons';

export interface IProps {
  click: (event) => void;
  'aria-controls'?: string;
}

const MoreVertButtonObj = styled.button`
  padding: 10px;
  border-radius: 25px;
  overflow: hidden;
  background: transparent;
  border: none;
  text-decoration-color: transparent;

  &:hover,
  &:focus {
    background-color: ${Color.grayTransparent};
  }
`;

export const MoreVertButton = React.memo<IProps>((props) => {
  const { t } = useTranslation();

  return (
    <MoreVertButtonObj
      onClick={props.click}
      aria-label={t('Actions')}
      aria-haspopup="menu"
      aria-controls={props['aria-controls']}
    >
      <MoreVert />
    </MoreVertButtonObj>
  );
});
