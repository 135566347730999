import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import { AssetUploader } from 'components/AssetUploader';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ModalPosition } from 'components/Modal';
import { Color } from 'core';
import { Card, CardHeader, CardHeaderContainer, CardSubHeader, PageTitle } from 'core/layout';
import { useInterval } from 'hooks/useInterval';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentUser } from 'store/profile';
import styled from 'styled-components';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { ModalHeader } from './common';

interface IProps {
  courseId?: string;
  partnerId?: number;
  onCancel: () => void;
}

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const InputWrapper = styled.div`
  border: solid 1px ${Color.lightGray};
  background-color: ${Color.primaryGray};
  color: ${Color.lightBlack};
  padding: 0.5em 1em;
  border-radius: 0.25em;
`;

const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: 0.95rem;
  width: 100%;
`;

const TemplateLink = styled.a`
  color: ${Color.primaryBlue};
  cursor: pointer;
  text-decoration: underline;
`;

const AssignmentCreatedText = styled.span`
  display: flex;
`;

const CSV_REFRESH_INTERVAL = 1500;
const MAX_RETRIES = 80;
export const BulkUpload = (props: IProps) => {
  const [name, setName] = useState('');
  const [isPending, setIsPending] = React.useState<boolean>(false);
  const [enableDistractors, setEnableDistractors] = useState(true);
  const currentUser = useSelector(state => getCurrentUser(state));
  const [csv, setCsv] = React.useState({} as any);
  const [newSetId, setNewSetId] = React.useState();
  const [showError, setShowError] = React.useState(false);
  const { courseId } = props;
  const { t } = useTranslation();
  const [retryCount, setRetryCount] = useState(0);

  const handleNameChange = e => {
    setName(e.target.value);
  };

  const handleUploadCSV = csv => {
    setCsv(csv);
  };

  const onCreate = async () => {
    const data = {
      set_name: name,
      course_id: courseId,
      enable_distractor_generation: enableDistractors
    };
    setIsPending(true);
    try {
      await axios.post(`/api/v4/user_csv_uploads/${csv.id}/parse_background`, data);
    } catch (err) {
      setIsPending(false);
      setShowError(true);
    }
  };

  const hasValidSetName = () => {
    return name.trim() && !/^\d+$/.test(name);
  };

  useInterval(
    async () => {
      try {
        const response = await axios.get(`/api/v3/user_csv_uploads/${csv.id}`);
        setRetryCount(retryCount + 1);
        if (retryCount > MAX_RETRIES) {
          setShowError(true);
          setIsPending(false);
        }
        const uploadedCsv = normalizeJSONAPIResponse(response.data);
        if (uploadedCsv.state === 'persist_completed') {
          const setId = uploadedCsv.setId;
          setNewSetId(setId);
          setIsPending(false);
        } else if (uploadedCsv.state === 'error') {
          setShowError(true);
          setIsPending(false);
        }
      } catch (err) {
        setShowError(true);
        setIsPending(false);
      }
    },
    isPending ? CSV_REFRESH_INTERVAL : null
  );

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>
          <b>{t('Bulk Upload')}</b>
        </CenterTitle>
      </ModalHeader>

      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('Add a learning set title')}</CardHeader>
        </CardHeaderContainer>
        <div>
          <InputWrapper>
            <Input
              autoFocus={true}
              value={name}
              onChange={handleNameChange}
              placeholder={t('e.g. Product Marketing 101')}
            />
          </InputWrapper>
        </div>
      </Card>

      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('Upload a CSV')}</CardHeader>
          <CardSubHeader>
            {t('Download our CSV template')}{' '}
            <TemplateLink
              href="/item_bulk_upload_template.csv"
              target="_blank"
              download="cerego_bulk_upload_template.csv"
            >
              {t('here')}
            </TemplateLink>
          </CardSubHeader>
        </CardHeaderContainer>
        <CardHeaderContainer>
          <AssetUploader
            onUploadComplete={handleUploadCSV}
            type="csv"
            partnerId={currentUser?.primaryPartner.id}
            uploadType="items"
          />
        </CardHeaderContainer>
        <CardHeaderContainer>
          <CardSubHeader style={{ fontSize: '13px' }}>
            {t(
              'Each row in the CSV you upload will be a Question & Answer item in the new assignment Bulk Upload creates.'
            )}
          </CardSubHeader>
        </CardHeaderContainer>
      </Card>

      <Card>
        <FormControlLabel
          control={
            <Checkbox
              checked={enableDistractors}
              onChange={() => setEnableDistractors(enableDistractors => !enableDistractors)}
              data-type="checkbox"
            />
          }
          label={
            <Typography style={{ color: Color.reportGray }}>
              {t('Automatically generate Smart Answers for empty distractor columns')}
            </Typography>
          }
        />
      </Card>
      {!newSetId && (
        <ConfirmOrCancel
          confirmText={t('Create')}
          handleConfirmClick={onCreate}
          confirmIsDisabled={!csv.id || !hasValidSetName()}
          cancelIsDisabled={isPending}
          confirmIsPending={isPending}
          handleCancelClick={props.onCancel}
        />
      )}

      {newSetId && (
        <AssignmentCreatedText>
          <div>{t('Assignment created!')}</div>
          &nbsp;
          <Link
            to={{
              pathname: `/nav/courses/${courseId}/sets/${newSetId}`
            }}
          >
            <div>{t('Click to view details')}</div>
          </Link>
        </AssignmentCreatedText>
      )}
      {showError && (
        <div style={{ marginTop: '1em' }}>
          {t('Could not process CSV. Please check that your CSV headers match the template above.')}
        </div>
      )}
    </ModalPosition>
  );
};
