import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { Color } from 'core';
import { useCreateOAuthClient } from 'hooks/useCreateOAuthClient';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMutateOAuthClient } from 'hooks/useMutateOAuthClient';
import { useOAuthClients } from 'hooks/useOAuthClients';
import { OAuthClientForm } from 'pages/admin-dashboard/oauth-config/OAuthConfigForm';
import React, { useEffect, useState } from 'react';
import { OAuthClient } from 'types';
import useStore from 'zstore';

export const OAuthConfig: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [editingClient, setEditingClient] = useState<OAuthClient | null>(null);
  const { data: rawOauthClients } = useOAuthClients();
  const [oauthClients, setOauthClients] = useState<OAuthClient[]>(rawOauthClients || []);
  const [createOAuthClient] = useCreateOAuthClient();
  const [mutateOAuthClient] = useMutateOAuthClient();
  const user = useCurrentUser();
  console.log('oauthClients', oauthClients);
  const { makeToast } = useStore();

  useEffect(() => {
    setOauthClients(rawOauthClients || []);
  }, [rawOauthClients]);

  // Toggle form visibility for creating a new client
  const handleAddNewClick = () => {
    setEditingClient(null);
    setShowForm(true);
  };

  // Handle cancel action
  const handleCancel = () => {
    setShowForm(false);
    setEditingClient(null);
  };

  // Handle create action
  const handleCreate = async (data: OAuthClient): Promise<void> => {
    try {
      await createOAuthClient({
        access_token_url: data.accessTokenUrl,
        client_type: data.clientType,
        id: user?.primaryPartner.slug,
        name: data.name,
        redirect_uri: data.redirectUri,
        website: data.website,
        public_jwk_url: data.publicJwkUrl || undefined,
      });
      setShowForm(false);
    } catch (error) {
      console.error('Error creating OAuth client:', error);
      makeToast({
        text: 'Error creating OAuth client. Please try again.',
        isError: true,
      });
    }
  };

  // Handle edit button click
  const handleEditClick = (client: OAuthClient): void => {
    setEditingClient(client);
    setShowForm(true);
  };

  // Handle update action
  const handleUpdate = async (data: OAuthClient): Promise<void> => {
    if (!editingClient) return;

    const payload = {
      id: user?.primaryPartner.slug,
      identifier: editingClient.identifier || editingClient.key || '',
      name: data.name,
      website: data.website,
      redirect_uri: data.redirectUri,
      access_token_url: data.accessTokenUrl || '',
      public_jwk_url: data.publicJwkUrl || '',
    };

    try {
      await mutateOAuthClient({ clientId: editingClient.id, payload });
      setShowForm(false);
      setEditingClient(null);
    } catch (error) {
      console.error('Error updating OAuth client:', error);
      makeToast({
        text: 'Error updating OAuth client. Please try again.',
        isError: true,
      });
    }
  };

  return (
    <MemreFlexBox direction="column" gap={3}>
      <MemreFlexBox gap={2} direction="row" align="center" justify="space-between">
        <MemreText variant="h4">OAuth Clients</MemreText>
        {!showForm && (
          <MemreButton
            text="New OAuth Client"
            variant="contained"
            color="secondary"
            onClick={handleAddNewClick}
            iconName="plus"
            size="medium"
          />
        )}
      </MemreFlexBox>

      {/* Form for Adding or Editing OAuth Client */}
      {showForm && (
        <OAuthClientForm
          initialData={editingClient || undefined}
          onCancel={handleCancel}
          onSubmit={editingClient ? handleUpdate : handleCreate}
          isEditing={!!editingClient}
        />
      )}

      {/* List of Configured OAuth Clients */}
      <MemreFlexBox direction="column" gap={2}>
        {oauthClients.length === 0 ? (
          <MemreText>No OAuth clients configured yet.</MemreText>
        ) : (
          <List>
            {oauthClients.map((client) => (
              <React.Fragment key={client.id}>
                <ListItem alignItems="flex-start" sx={{ paddingLeft: '0', paddingRight: '0' }}>
                  <ListItemText
                    primary={
                      <MemreText variant="h6" sx={{ marginBottom: '0.5rem' }}>
                        {client.name}
                      </MemreText>
                    }
                    secondary={
                      <div>
                        <div style={{ marginBottom: '0.5rem' }}>
                          <MemreText variant="body1" sx={{ color: Color.darkGray }}>
                            Type
                          </MemreText>
                          <MemreText variant="body2" sx={{ color: Color.textGray }}>
                            {client.clientType === 'lti_12' ? 'LTI 1.1/1.2/SAML' : 'LTI 1.3'}
                          </MemreText>
                        </div>
                        <div style={{ marginBottom: '0.5rem' }}>
                          <MemreText variant="body1" sx={{ color: Color.darkGray }}>
                            Key
                          </MemreText>
                          <MemreText variant="body2" sx={{ color: Color.textGray }}>
                            {client.key}
                          </MemreText>
                        </div>
                        {!!client.secret && (
                          <div style={{ marginBottom: '0.5rem' }}>
                            <MemreText variant="body1" sx={{ color: Color.darkGray }}>
                              Secret
                            </MemreText>
                            <MemreText variant="body2" sx={{ color: Color.textGray }}>
                              {client.secret}
                            </MemreText>
                          </div>
                        )}
                      </div>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title="Edit OAuth Client">
                      <IconButton
                        edge="end"
                        onClick={() => handleEditClick(client)}
                        color="secondary"
                        size="small"
                        sx={{ padding: '0.5rem', color: Color.mainBlue }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        )}
      </MemreFlexBox>
    </MemreFlexBox>
  );
};
