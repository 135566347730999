import { CALL_API } from 'middleware/api';

export const handleGetPartnerSets = (pageNumber: number, partnerId: string, query: string, goalType: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/partner/${partnerId}/sets?include=image&page[number]=${[
        pageNumber
      ]}&sort=name&query=${query}&include_course_information=true&privacy_types=4`, //TODO: allow goal_type filter
      httpMethod: 'GET'
    }
  };
};
