import TextField from '@material-ui/core/TextField';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { useItemEditorStore } from 'components/item-editor/store';
import { useMutateConcept } from 'hooks/useMutateConcept';
import React, { useEffect, useState } from 'react';
import { useAutosaveStatus } from 'hooks/useAutosaveStatus';
import { ConceptImage } from '../ConceptImage';
import { ConceptSound } from '../ConceptSound';

export const ForeignAndNativeTermFormFields = () => {
  return (
    <>
      <TermFormFields conceptRelation="anchor" />
      <TermFormFields conceptRelation="association" />
    </>
  );
};

interface TermFormFieldsProps {
  conceptRelation: 'anchor' | 'association';
}

export const TermFormFields = (props: TermFormFieldsProps) => {
  const { conceptRelation } = props;
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const concept = item?.facets[0][conceptRelation];
  const [conceptText, setConceptText] = useState(concept.text);
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useAutosaveStatus();

  const handleConceptTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConceptText(event.target.value);
  };

  // update concept text automatically on changed input
  // debounce to avoid making too many requests
  useEffect(() => {
    if (item == null) {
      return;
    }
    if (conceptText !== concept.text) {
      const delayDebounceFn = setTimeout(() => {
        setAutosaveStatus('saving');
        mutateConcept({
          id: concept.id,
          payload: {
            data: {
              attributes: {
                'text-html': conceptText,
              },
            },
          },
        })
          .then(() => setAutosaveStatus('success'))
          .catch(() => setAutosaveStatus('error'));

        // update app state (This must be done after sending the mutation request)
        // There's only one facet for vocabulary items
        const updatedFacets = item.facets.map((facet) => ({
          ...facet,
          [conceptRelation]: {
            ...facet[conceptRelation],
            text: conceptText,
          },
        }));
        updateActiveItem({ facets: updatedFacets });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [conceptText, item?.id, mutateConcept, concept.text, conceptRelation]);

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  const textFieldLabel = conceptRelation === 'anchor' ? 'Foreign Term' : 'Native Term';
  const textFieldPlaceholder =
    conceptRelation === 'anchor'
      ? 'Enter a foreign word you want to study'
      : 'Enter translation in your native language';

  return (
    <>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <TextField
          id={`${conceptRelation}-text`}
          label={textFieldLabel}
          value={conceptText}
          onChange={handleConceptTextChange}
          placeholder={textFieldPlaceholder}
          variant="outlined"
          style={{
            fontSize: '24px',
            fontWeight: 500,
            padding: '5px 0',
            width: '100%',
          }}
        />

        <ConceptImage concept={concept} />
        <ConceptSound concept={concept} />
      </div>
      <AutosaveStatus status={autosaveStatus} />
    </>
  );
};
