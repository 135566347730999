import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const CsvUploadModal = ({ onClose }: { onClose: () => void }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadId, setUploadId] = useState<string | null>(null);
  const [step, setStep] = useState<'upload' | 'matchColumns' | 'preview' | 'confirm' | 'complete'>('upload');
  const [nameColumn, setNameColumn] = useState<string>('');
  const [identifierColumn, setIdentifierColumn] = useState<string>('');
  const [identifierType, setIdentifierType] = useState<'email' | 'member_id'>('email');
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [csvPreview, setCsvPreview] = useState<any[]>([]);
  const [importSummary, setImportSummary] = useState<any>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const user = useCurrentUser();
  const memberIdSlug = user?.primaryPartner.partnerSettings.memberIdSlug;

  // New state to track if the CSV is for removal
  const [isRemoval, setIsRemoval] = useState<boolean>(false);

  // Handle file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  // Upload the selected file and extract CSV headers and preview data
  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      setStatus('loading');
      const response = await axios.post(`/api/v3/partners/${user.primaryPartner.id}/user_csv_uploads`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Extract headers and preview data from response
      const csvExampleContent = response.data.data.meta['csv-example-content'];
      if (!csvExampleContent || !Array.isArray(csvExampleContent)) {
        throw new Error('Invalid CSV content format.');
      }

      const headers = csvExampleContent.map((column: any[]) => column[0]);

      // Check if the CSV is for removal based on the presence of 'remove_user' column
      setIsRemoval(headers.includes('remove_user'));

      // Extract data rows
      const numRows = csvExampleContent[0][1].split(',').length;
      const previewData: any[] = [];

      for (let i = 0; i < numRows; i++) {
        const row: { [key: string]: string } = {};
        for (const column of csvExampleContent) {
          const columnName = column[0];
          const columnData = column[1].split(',');
          row[columnName] = columnData[i] || '';
        }
        previewData.push(row);
      }

      setCsvHeaders(headers);
      setCsvPreview(previewData);

      setUploadId(response.data.data.id);
      setStatus('success');
      setStep('matchColumns');
    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorMessage('Failed to upload file. Please try again.');
      setStatus('error');
    }
  };

  // Initiate the import (dry run)
  const handleImport = async () => {
    if (!nameColumn || !identifierColumn) {
      alert('Please select both name and identifier columns.');
      return;
    }

    const payload = {
      dry_run: true,
      name_column: nameColumn,
      email_column: identifierType === 'email' ? identifierColumn : undefined,
      id_column: identifierType === 'member_id' ? identifierColumn : undefined,
    };

    try {
      setStatus('loading');
      await axios.post(`/api/v3/user_csv_uploads/${uploadId}/parse_background`, payload);
      setStatus('success');
      setStep('preview');
    } catch (error) {
      console.error('Error initiating import:', error);
      setErrorMessage('Failed to start import. Please try again.');
      setStatus('error');
    }
  };

  // Fetch import summary
  const fetchImportSummary = async () => {
    try {
      setStatus('loading');
      const response = await axios.get(`/api/v3/user_csv_uploads/${uploadId}`);
      setImportSummary(response.data.data);
      setStatus('success');
    } catch (error) {
      console.error('Error fetching import summary:', error);
      setErrorMessage('Failed to fetch import summary. Please try again.');
      setStatus('error');
    }
  };

  useEffect(() => {
    if (step === 'preview' && uploadId) {
      fetchImportSummary();
    }
  }, [step, uploadId]);

  // Confirm and execute the import
  const handleConfirmImport = async () => {
    const payload = {
      dry_run: false,
      name_column: nameColumn,
      email_column: identifierType === 'email' ? identifierColumn : undefined,
      id_column: identifierType === 'member_id' ? identifierColumn : undefined,
    };

    try {
      setStatus('loading');
      await axios.post(`/api/v3/user_csv_uploads/${uploadId}/parse_background`, payload);
      setStatus('success');
      setStep('complete');
    } catch (error) {
      console.error('Error confirming import:', error);
      setErrorMessage('Failed to complete import. Please try again.');
      setStatus('error');
    }
  };

  // Close modal and reset state
  const closeModal = () => {
    onClose();
    setStatus('idle');
    setErrorMessage('');
    setSelectedFile(null);
    setStep('upload');
  };

  return (
    <ModalOverlay>
      <ModalContent>
        {/* Steps: upload, matchColumns, preview, confirm, complete */}
        {step === 'upload' && (
          <>
            <h2>Upload CSV File</h2>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            {status === 'error' && <ErrorText>{errorMessage}</ErrorText>}
            <ButtonContainer>
              <button onClick={closeModal}>Cancel</button>
              <button onClick={handleFileUpload} disabled={status === 'loading'}>
                {status === 'loading' ? 'Uploading...' : 'Next'}
              </button>
            </ButtonContainer>
          </>
        )}
        {step === 'matchColumns' && (
          <>
            <h2>Match Columns</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '10px',
              }}
            >
              <label>
                Name Column:
                <select value={nameColumn} onChange={(e) => setNameColumn(e.target.value)}>
                  <option value="">Select Column</option>
                  {csvHeaders.map((header) => (
                    <option key={header} value={header}>
                      {header}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Identifier Type:
                <select
                  value={identifierType}
                  onChange={(e) => setIdentifierType(e.target.value as 'email' | 'member_id')}
                >
                  <option value="email">Email</option>
                  <option value="member_id">{memberIdSlug || 'Member ID'}</option>
                </select>
              </label>
              <label>
                {identifierType === 'email' ? 'Email Column:' : `${memberIdSlug || 'Member ID'} Column:`}
                <select value={identifierColumn} onChange={(e) => setIdentifierColumn(e.target.value)}>
                  <option value="">Select Column</option>
                  {csvHeaders.map((header) => (
                    <option key={header} value={header}>
                      {header}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            {/* Show CSV preview */}
            <table>
              <thead>
                <tr>
                  {csvHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvPreview.map((row, index) => (
                  <tr key={index}>
                    {csvHeaders.map((header) => (
                      <td key={header}>{row[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {status === 'error' && <ErrorText>{errorMessage}</ErrorText>}
            <ButtonContainer>
              <button onClick={closeModal}>Cancel</button>
              <button onClick={handleImport} disabled={status === 'loading'}>
                {status === 'loading' ? 'Processing...' : 'Next'}
              </button>
            </ButtonContainer>
          </>
        )}
        {step === 'preview' && (
          <>
            <h2>Import Summary</h2>
            {status === 'loading' && <p>Loading summary...</p>}
            {status === 'success' && importSummary && (
              <>
                {/* Display appropriate message based on whether users are added or removed */}
                {isRemoval ? (
                  <p>
                    {importSummary.meta['parsed-count'].removed} users will be <strong>removed</strong>.
                  </p>
                ) : (
                  <p>
                    {importSummary.meta['parsed-count'].added} users will be <strong>added</strong>.
                  </p>
                )}
                <ButtonContainer>
                  <button onClick={closeModal}>Cancel</button>
                  <button onClick={handleConfirmImport}>{isRemoval ? 'Confirm Removal' : 'Confirm Import'}</button>
                </ButtonContainer>
              </>
            )}
            {status === 'error' && <ErrorText>{errorMessage}</ErrorText>}
          </>
        )}
        {step === 'complete' && (
          <>
            <h2>Import Complete</h2>
            <p>
              The import has been completed successfully.{' '}
              {isRemoval ? 'Users have been removed from the system.' : 'Users have been added to the system.'}
            </p>
            <ButtonContainer>
              <button onClick={closeModal}>Close</button>
            </ButtonContainer>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 500px;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button[type='button'] {
    background-color: #ccc;
  }

  button[type='submit'] {
    background-color: #007bff;
    color: white;
  }
`;
