import axios from 'axios';
import { BrightInput } from 'components/forms/BrightInput';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Box, Content } from 'components/modals/common';
import { ContentSection, PageContainer, PageTitle, TitleSection } from 'core/layout';
import { ErrorMessage } from 'core/typography';
import * as _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import useStore from 'zstore';

interface IUpdateSAMLSettingsPage {
  match: { params: { partnerSlug: string } };
}

const ClientInputHeader = styled.h3`
  margin-bottom: 0px;
`;

export const UpdateSAMLSettingsPage = React.memo<IUpdateSAMLSettingsPage>((props) => {
  const { partnerSlug } = props.match.params;
  const [updateSAMLSettingsIsPending, setUpdateSAMLSettingsIsPending] = React.useState<boolean>(false);
  const [metadataURL, setMetadataURL] = React.useState<string>();
  const [showError, setShowError] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const returnURL = `/nav/partners/${partnerSlug}/oauth`;
  const { makeToast } = useStore();
  const history = useHistory();

  const updateSAMLSettings = async () => {
    setUpdateSAMLSettingsIsPending(true);

    try {
      await axios.post(`/api/v4/saml/${partnerSlug}/update_settings`, { metadata_url: metadataURL });
      makeToast({
        text: t('Successfully updated SAML settings!'),
        isError: false,
      });
      history.push(returnURL);
    } catch {
      setShowError(true);
    } finally {
      setUpdateSAMLSettingsIsPending(false);
    }
  };

  const onCancel = () => {
    history.push(returnURL);
  };

  const validMetadataURL = () => {
    return !_.isEmpty(metadataURL);
  };

  return (
    <PageContainer>
      <TitleSection>
        <PageTitle>{t('Update SAML Settings')}</PageTitle>
      </TitleSection>
      <ContentSection>
        <Box>
          <Content>
            <ClientInputHeader>{t('SAML Metadata URL')}</ClientInputHeader>
            <BrightInput
              placeholder={'https://yourdomain.com/saml/metadata'}
              autoFocus={true}
              onChange={(e) => setMetadataURL(e.target.value)}
              required={true}
            />

            {showError && (
              <div>
                <ErrorMessage>{t('An error occurred while updating SAML settings.')}</ErrorMessage>
                <ErrorMessage>
                  {t('Please ensure you have entered a valid URL that responds with a SAML metadata response.')}
                </ErrorMessage>
              </div>
            )}
            <ConfirmOrCancel
              cancelText={t('Cancel')}
              confirmText={t('Update SAML Settings')}
              handleCancelClick={onCancel}
              handleConfirmClick={updateSAMLSettings}
              confirmIsDisabled={!validMetadataURL()}
              confirmIsPending={updateSAMLSettingsIsPending}
            />
          </Content>
        </Box>
      </ContentSection>
    </PageContainer>
  );
});
