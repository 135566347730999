import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

const deleteConcept = (conceptId: string) => {
  return axios.delete(`/api/v3/concepts/${conceptId}`);
};

export const useDeleteConcept = (setId: string, itemId: string, conceptIdForDistractors?: string) => {
  const { makeToast } = useStore();

  return useMutation(deleteConcept, {
    onSuccess: () => {
      makeToast({
        text: 'Concept deleted successfully!',
        isError: false,
      });
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
      if (conceptIdForDistractors) {
        queryCache.invalidateQueries(['distractors', conceptIdForDistractors]);
      }
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Failed to delete concept',
        isError: true,
      });
    },
  });
};
