import axios from 'axios';
import { useQuery } from 'react-query';

const getPaginatedAssignments = async (
  _key: string,
  courseId: string | null,
  pageNumber: number,
  pageSize: number,
  searchTerm: string
) => {
  if (!courseId) {
    console.warn('Cannot get course assignments without courseId');
    return;
  }

  const params = new URLSearchParams();
  params.append('page[number]', pageNumber.toString());
  params.append('page[size]', pageSize.toString());
  params.append('include_difficulty', 'true');
  if (searchTerm) {
    params.append('query', searchTerm);
  }

  const baseUrl = `/api/v4/courses/${courseId}/assignments`;
  const endpoint = `${baseUrl}?${params.toString()}`;

  try {
    const response = await axios.get(endpoint);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const usePaginatedAssignments = (
  courseId: string | null,
  pageNumber: number,
  pageSize: number,
  searchTerm: string
) => {
  return useQuery(['paginatedAssignments', courseId, pageNumber, pageSize, searchTerm], getPaginatedAssignments, {
    enabled: !!courseId,
  });
};
