import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import build from 'redux-object';
import styled from 'styled-components';
import { Color } from 'core';
import UserSessionJourneyCard from 'learn/components/UserSessionJourneyCard';
import LearnHeader from 'learn/components/LearnHeader';
import { ISet } from 'models/set';
import { handleGetSet } from 'store/set';
import { HttpStatusCodes } from 'utils/httpStatusCodes';
import { postMessageExitLearnApp } from 'utils/iframeMessageUtils';
import { locationState, queryParams, redirectToV3 } from 'utils/pathUtils';
import { getBtnText, getMessaging } from 'utils/userSessionJourneyMessaging';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IOwnProps extends RouteProps, WithTranslation {
  match: { params: { id: string } };
  handleGetSet: (id: string) => any;
  set: ISet;
  memoryAggregate: any; // TODO
}

interface IOwnState {
  toStudySession: boolean;
  messaging: any;
  btnText: any;
  studyType: 'force_review' | 'recommended';
}

const Container = styled.div`
  background-color: ${Color.primaryGray};
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export class UserSessionJourneyPage extends React.Component<IOwnProps, IOwnState> {
  public returnUrl = queryParams(this.props.location).returnUrl || locationState(this.props.location).returnUrl;
  public isLTI = !!queryParams(this.props.location).lti || !!locationState(this.props.location).lti;
  private isAssessment: boolean = false;
  private isSurvey: boolean = false;
  private mainFocusRef: any = React.createRef();

  constructor(props) {
    super(props);
    this.state = { ...this.state };
    this.exit = this.exit.bind(this);
    this.handlePrimaryBtnClick = this.handlePrimaryBtnClick.bind(this);
    this.handleSecondaryBtnClick = this.handleSecondaryBtnClick.bind(this);
    this.launchStudySession = this.launchStudySession.bind(this);
  }

  public componentDidMount(): void {
    this.props.handleGetSet(this.props.match.params.id).then((response) => {
      if (this.props.set?.learnVersion === 3) {
        redirectToV3(`/learn/sets/${this.props.set.id}`);
      } else if (response.error?.status === HttpStatusCodes.Forbidden && this.isLTI) {
        window.location.href = '/';
        return;
      }

      const { unstartedItemsCount, studiedItemsCount, percentCorrect, eligibleItemsCount, progress, seeNextAt } =
        this.props.memoryAggregate;
      this.isAssessment = this.props.set.goalType === 'assessment';
      this.isSurvey = this.props.set.goalType === 'survey';
      const justStudied =
        this.props.location && this.props.location.state && (this.props.location.state as any).justStudied;
      const messaging = getMessaging(
        studiedItemsCount,
        unstartedItemsCount,
        percentCorrect,
        eligibleItemsCount,
        progress,
        justStudied,
        seeNextAt,
        this.isAssessment,
        this.isSurvey
      );
      const btnText = getBtnText(
        unstartedItemsCount,
        eligibleItemsCount,
        progress,
        justStudied,
        this.isAssessment,
        this.isSurvey
      );
      this.setState({ messaging, btnText });
    });
  }

  componentWillMount = () => {
    // listen for messages from the angular app inside the iframe
    window.addEventListener('message', this.handleMessageReceived, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener('message', this.handleMessageReceived);
  };

  handleMessageReceived = (event) => {
    if (event.data === 'skipToMain') {
      this.mainFocusRef.current?.focus();
    }
  };

  public render() {
    const { set, t } = this.props;
    if (this.state.toStudySession) {
      const to = {
        pathname: `/learn/sets/${this.props.match.params.id}`,
        search: `?studyType=${this.state.studyType}`,
        state: { returnUrl: this.returnUrl, lti: this.isLTI },
      };
      return <Redirect to={to} />;
    }

    var title = t('Learn');
    if (set?.name) {
      title += ' | ' + set.name;
    }
    document.title = title + ' | Cerego';

    return (
      <Container className="UserSessionJourneyPage">
        <LearnHeader exit={this.exit} set={this.props.set} showExit={!this.isLTI} />
        {this.state.messaging && (
          <UserSessionJourneyCard
            ref={this.mainFocusRef}
            messaging={this.state.messaging}
            btnText={this.state.btnText}
            showPrimaryButton={this.showPrimaryButton()}
            progress={this.props.memoryAggregate.progress}
            handlePrimaryBtnClick={this.handlePrimaryBtnClick}
            handleSecondaryBtnClick={this.handleSecondaryBtnClick}
            showDetails={!this.isAssessmentOrSurvey()}
          />
        )}
      </Container>
    );
  }

  private exit() {
    postMessageExitLearnApp();
    redirectToV3(this.returnUrl);
  }

  private launchStudySession(studyType) {
    this.setState({ toStudySession: true, studyType });
  }

  private isGoodForNow() {
    const { unstartedItemsCount, eligibleItemsCount } = this.props.memoryAggregate;
    return unstartedItemsCount === 0 && (eligibleItemsCount === 0 || this.isAssessmentOrSurvey());
  }

  private isAssessmentOrSurvey() {
    return this.isAssessment || this.isSurvey;
  }

  private showPrimaryButton() {
    if (this.isGoodForNow()) {
      return !this.isLTI;
    }
    return true;
  }

  private handlePrimaryBtnClick() {
    if (this.isGoodForNow()) {
      this.exit();
    } else {
      this.launchStudySession('recommended');
    }
  }

  private handleSecondaryBtnClick() {
    this.launchStudySession('force_review');
  }
}

const mapStateToProps = (state, props) => {
  return {
    set: build(state.data, 'sets', props.match.params.id),
    memoryAggregate:
      build(state.data, 'sets', props.match.params.id) &&
      build(state.data, 'sets', props.match.params.id).meta &&
      build(state.data, 'sets', props.match.params.id).meta.memoryAggregate,
  };
};

const mapDispatchToProps = {
  handleGetSet,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserSessionJourneyPage));
