import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroupCoursesStore, useGroupsStore } from 'pages/admin-dashboard/groups/store';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

type CreateGroupCourseParams = {
  courseId: string;
  groupId: string;
};

const createGroupCourse = async (params: CreateGroupCourseParams) => {
  const response = await axios.post(`/api/v3/courses/${params.courseId}/groups`, {
    id: params.groupId,
  });
  return normalizeJSONAPIResponse(response.data);
};

export const useCreateGroupCourse = (groupId: string) => {
  const { makeToast } = useStore();
  const user = useCurrentUser();
  const {
    pageNumber: groupsPageNumber,
    pageSize: groupsPageSize,
    sortColumn: groupsSortColumn,
    sortDirection: groupsSortDirection,
    name: groupsName,
  } = useGroupsStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name, statusFilter } = useGroupCoursesStore();

  return useMutation(createGroupCourse, {
    onSettled: () => {
      queryCache.invalidateQueries([
        'groups',
        user?.primaryPartner.id,
        groupsPageNumber,
        groupsPageSize,
        groupsSortColumn,
        groupsSortDirection,
        groupsName,
      ]);
      queryCache.invalidateQueries(['group', groupId]);
      queryCache.invalidateQueries([
        'groupCourses',
        groupId,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        name,
        statusFilter,
      ]);
    },
    onSuccess: () => {
      makeToast({
        text: 'Group successfully enrolled in course',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
