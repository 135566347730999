import { devlog, immer } from 'zstore';
import create from 'zustand';

export type TStatusFilter = 'all' | 'published' | 'unpublished' | 'archived' | 'shared';

type CoursesPageState = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  statusFilter: TStatusFilter;
  setStatusFilter: (statusFilter: TStatusFilter) => void;
  name: string;
  setName: (name: string) => void;
  sortColumn: string;
  setSortColumn: (sortColumn: string) => void;
  sortDirection: 'asc' | 'desc';
  setSortDirection: (sortDirection: 'asc' | 'desc') => void;
};

const makeCoursesPageStore = () => {
  return create<CoursesPageState>(
    devlog(
      immer((set: any) => ({
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => set(() => ({ pageNumber })),
        pageSize: 25,
        setPageSize: (pageSize: number) => set(() => ({ pageSize })),
        statusFilter: 'all',
        setStatusFilter: (statusFilter: TStatusFilter) => set(() => ({ statusFilter })),
        name: '',
        setName: (name: string) => set(() => ({ name })),
        sortColumn: 'last_updated',
        setSortColumn: (sortColumn: string) => set(() => ({ sortColumn })),
        sortDirection: 'desc',
        setSortDirection: (sortDirection: 'asc' | 'desc') => set(() => ({ sortDirection })),
      }))
    )
  );
};

export const useCoursesPageStore = makeCoursesPageStore();
