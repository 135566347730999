import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Datepicker from 'components/Datepicker';
import ErrorMessage from 'components/ErrorMessage';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Color } from 'core';
import AssignmentFormHeader from 'courses/components/AssignmentFormHeader';
import LevelGoalInput from 'courses/components/LevelGoalInput';
import { useAssignments } from 'hooks/useAssignments';
import useMutateAssignment from 'hooks/useMutateAssignment';
import { AssignmentStatus, dueBeforePublishDate } from 'models/set';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, isInPast, now } from 'utils/dateUtils';
import getAssignmentStatus from 'utils/getAssignmentStatus';

interface IHelperText {
  text: string;
}

const HelperText = (props: IHelperText) => {
  return (
    <p
      style={{
        padding: '0',
        margin: '0',
        fontSize: '0.8em',
        color: `${Color.slateGray}`,
        textAlign: 'left',
        paddingLeft: '31px',
        marginTop: '-8px',
      }}
    >
      {props.text}
    </p>
  );
};

interface IAssignmentStatusForm {
  assignment: any;
  course: any;
  onCancel: () => void;
}

const getDefaultPublishDate = (assignment: any): string => {
  if (assignment.meta.published && assignment.meta.publishAt) {
    return assignment.meta.publishAt;
  } else {
    return now();
  }
};

const getDefaultPrereqAssignmentId = (assignment: any): string => {
  return assignment.meta.prereqAssignmentId ?? '-1';
};

const getDefaultPrereqValue = (assignment: any): string => {
  return assignment.meta.prereqValue ?? '';
};

const AssignmentStatusForm = (props: IAssignmentStatusForm) => {
  const [radioSelection, setRadioSelection] = useState<AssignmentStatus | null>(
    getAssignmentStatus(props.assignment, props.course)
  );
  const [publishDate, setPublishDate] = useState<string>(getDefaultPublishDate(props.assignment));
  const [prereqAssignmentId, setPrereqAssignmentId] = useState<string>(getDefaultPrereqAssignmentId(props.assignment));
  const [prereqValue, setPrereqValue] = useState<string>(getDefaultPrereqValue(props.assignment));
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [prereqAssignments, setPrereqAssignments] = useState<Array<any>>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();

  const { data: assignments } = useAssignments(props.course.id);
  const [mutateAssignment, { status: mutationStatus }] = useMutateAssignment();

  const HELPER_TEXT = {
    Published: t('This assignment will be available to learners.'),
    Unpublished: t('This assignment will not be available to learners.'),
    Scheduled: t('This assignment will be made available to learners on the selected date.'),
    Gated: t(
      'This assignment will be made available to learners when they have achieved a milestone in another assignment.'
    ),
  };

  const getFormErrors = (): string => {
    switch (radioSelection) {
      case 'Published':
        const prematurePublish: boolean = radioSelection === 'Published' && props.course.state !== 'published';
        if (prematurePublish) {
          return t('Cannot publish assignment before course is published');
        }
        break;
      case 'Unpublished':
        break;
      case 'Scheduled':
        if (isInPast(publishDate)) {
          return t('Cannot publish assignment in the past');
        }
        if (dueBeforePublishDate(props.assignment.meta.dueAt, publishDate)) {
          return t('Cannot publish assignment after due date ').concat(formatDate(props.assignment.meta.dueAt));
        }
        break;
      case 'Gated':
        const prereqAssignmentNotSelected: boolean = prereqAssignmentId === '-1';
        const gatedFormIncomplete: boolean = radioSelection === 'Gated' && prereqAssignmentNotSelected;
        const prereqValueNaN: boolean = isNaN(Number(prereqValue));
        const prereqValueOutOfRange: boolean = parseFloat(prereqValue) < 0 || parseFloat(prereqValue) > 3;
        const invalidPrereqValue: boolean = prereqValueNaN || prereqValueOutOfRange;
        if (gatedFormIncomplete) {
          return t('Please select a prerequisite assignment');
        }
        if (invalidPrereqValue) {
          return t('Please enter a number between 0 and 3');
        }
        break;
      default:
        console.warn(`Cannot getFormErrors for unrecognized radioSelection ${radioSelection}`);
    }

    return '';
  };

  // Disable save button for states we don't want to save
  React.useEffect(() => {
    const errors: string = getFormErrors();
    if (errors) {
      setErrorMessage(errors);
    } else {
      setErrorMessage('');
    }
  }, [radioSelection, publishDate, prereqAssignmentId, prereqValue]);

  // Maintain array of assignments that are valid prerequisites
  React.useEffect(() => {
    if (!assignments) {
      return;
    }

    setPrereqAssignments(
      assignments.filter((assignment) => assignment.id !== props.assignment.id && assignment.goalType === 'set')
    );
  }, [assignments]);

  const focusPrereqValueInput = (): void => {
    document.getElementById('prereq-value-input')?.focus();
  };

  // When errorMessage changes, update disableSave
  React.useEffect(() => {
    if (errorMessage) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [errorMessage]);

  // Focus PrereqValueInput when it changes
  React.useEffect(() => {
    focusPrereqValueInput();
  }, [prereqValue]);

  // Update PrereqValueInput with PrereqAssignmentSelect default
  React.useEffect(() => {
    const prereqTarget: string = prereqAssignments
      .filter((assignment) => assignment.meta.assignmentId === prereqAssignmentId)
      .map((assignment) => assignment.meta.levelGoal.toFixed(1).toString())
      .shift();
    if (prereqTarget) {
      setPrereqValue(prereqTarget);
    }
  }, [prereqAssignmentId]);

  const handlePrereqAssignmentChange = (event: any): void => {
    setPrereqAssignmentId(event.target.value);
  };

  const handleRadioChange = (event: any): void => {
    setRadioSelection(event.target.value);
  };

  const handlePublishDateChange = (publishDateSelection: string): void => {
    setPublishDate(publishDateSelection);
  };

  const handleSave = async () => {
    const formattedPrereqValue: string = Number(prereqValue).toFixed(1).toString();

    let payload = {};
    switch (radioSelection) {
      case 'Unpublished':
        payload = {
          published: false,
          publish_at: null,
          prereq_assignment_id: null,
          prereq_type: null,
          prereq_value: null,
        };
        break;
      case 'Scheduled':
        payload = {
          published: false,
          publish_at: publishDate,
          prereq_assignment_id: null,
          prereq_type: null,
          prereq_value: null,
        };
        break;
      case 'Published':
        payload = {
          published: true,
          publish_at: null,
          prereq_assignment_id: null,
          prereq_type: null,
          prereq_value: null,
        };
        break;
      case 'Gated':
        payload = {
          published: true,
          publish_at: null,
          prereq_assignment_id: prereqAssignmentId,
          prereq_type: 'Level',
          prereq_value: formattedPrereqValue,
        };
        break;
      default:
        throw new Error(`Unrecognized radio selection ${radioSelection}`);
    }

    await mutateAssignment({
      ids: { setId: props.assignment.id, courseId: props.course.id },
      payload: payload,
    });
    props.onCancel();
  };

  const handlePrereqValueChange = (event: any): void => {
    setPrereqValue(event.target.value);
  };

  interface IGuidedHelperText {
    selection: string;
  }

  const GuidedHelperText = (props: IGuidedHelperText) => {
    const { selection } = props;

    return (
      <>
        <FormControlLabel value={selection} control={<Radio color="primary" />} label={t(selection)} />
        <HelperText text={HELPER_TEXT[selection]} />
      </>
    );
  };

  return (
    <div style={{ width: '400px' }}>
      <AssignmentFormHeader assignmentName={props.assignment.name} formTitle={t('Status')} />

      <FormControl component="fieldset" style={{ width: '400px' }}>
        <RadioGroup aria-label={t('Status')} name="status-form" value={radioSelection} onChange={handleRadioChange}>
          <GuidedHelperText selection="Unpublished" />
          <GuidedHelperText selection="Published" />
          <GuidedHelperText selection="Scheduled" />
          <GuidedHelperText selection="Gated" />
        </RadioGroup>
      </FormControl>

      <div style={{ marginTop: '5px' }}>
        {radioSelection === 'Scheduled' && <Datepicker onChange={handlePublishDateChange} />}
        {radioSelection === 'Gated' && (
          <div style={{ width: '100%', margin: '20px 0' }}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="branching-assignments-select-label">{t('Prerequisite')}</InputLabel>
              <Select
                name="prereq-assignments-select"
                id="prereq-assignments-selector"
                value={prereqAssignmentId}
                onChange={handlePrereqAssignmentChange}
                style={{ width: '100%' }}
              >
                <MenuItem key="default" value="-1">
                  <em>{t('None')}</em>
                </MenuItem>
                {prereqAssignments.map((prereqAssignment: any) => (
                  <MenuItem key={prereqAssignment.meta.assignmentId} value={prereqAssignment.meta.assignmentId}>
                    {prereqAssignment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div
              style={{
                width: '100%',
                marginBottom: '20px',
                marginTop: '20px',
                textAlign: 'left',
                borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
              }}
            >
              <LevelGoalInput
                level={prereqValue}
                handleInputChange={handlePrereqValueChange}
                inputStyle={{ width: '87%' }}
              />
            </div>
          </div>
        )}
      </div>

      <div style={{ marginTop: '1em' }}>
        <ConfirmOrCancel
          cancelText={t('Cancel')}
          confirmText={t('Update')}
          handleCancelClick={props.onCancel}
          handleConfirmClick={handleSave}
          confirmIsDisabled={disableSave}
          confirmIsPending={mutationStatus === 'loading'}
        />
      </div>

      <ErrorMessage error={errorMessage} />
    </div>
  );
};

export default AssignmentStatusForm;
