import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getPartnerDomains = async (_key: string, partnerId: string) => {
  const response = await axios.get(
    `/api/v3/partners/${partnerId}/partner_domains`
  );
  return normalizeJSONAPIResponse(response.data);
};

export const usePartnerDomains = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;

  return useQuery(['partnerDomains', partner.id], getPartnerDomains, {
    enabled: partner,
  });
};
