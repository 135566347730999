import { flexRender } from '@tanstack/react-table';
import { Color } from 'core';
import React from 'react';

interface Props {
  table: any;
  rightAlignedColumnIds?: Array<string>;
  extraPadding?: boolean;
  onRowClick?: (row: any) => void;
  emptyTableComponent?: React.ReactNode;
}

export const TableBody = (props: Props) => {
  const { table, rightAlignedColumnIds, extraPadding, onRowClick, emptyTableComponent } = props;

  const rightColumns = rightAlignedColumnIds ?? [];

  // Check if there are no rows and the emptyTableComponent is provided
  const isEmptyTable = table.getRowModel().rows.length === 0 && emptyTableComponent;

  return (
    <tbody>
      {isEmptyTable ? (
        // If the table is empty, render the provided emptyTableComponent
        <tr>
          <td colSpan={1000} style={{ textAlign: 'center', padding: '20px' }}>
            {emptyTableComponent}
          </td>
        </tr>
      ) : (
          // Otherwise, render the rows as usual
          table.getRowModel().rows.map((row: any) => (
            <tr
              key={row.id}
              style={{
                borderTop: '1px solid rgb(224, 224, 224, 1)',
                borderBottom: '1px solid rgb(224, 224, 224, 1)',
              }}
              onClick={() => (!!onRowClick ? onRowClick(row) : {})}
            >
              {row.getVisibleCells().map((cell: any) => (
                <td
                  key={cell.id}
                  style={{
                    padding: extraPadding ? '20px' : '10px 20px',
                    textAlign: rightColumns.includes(cell.column.id) ? 'right' : 'left',
                    color: Color.reportGray,
                    fontSize: '14px',
                    cursor: !!onRowClick ? 'pointer' : 'inherit',
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))
        )}
    </tbody>
  );
};
