import { CheckCircle, MoreVert } from '@material-ui/icons';
import { DropDown, DropDownList, DropDownListItem } from 'components/DropDown';
import { Color } from 'core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserEmail } from 'store/user-email';
import styled from 'styled-components';

interface IOwnProps {
  emails: IUserEmail[];
  removeEmail: (emailId: string) => void;
  resendEmailConfirmation: (emailId: string) => void;
  makePrimaryEmail: (emailId: string) => void;
}

const Container = styled.ul`
  padding: 0;
  margin: 0;

  > li:not(:last-child) {
    border-bottom: solid 1px ${Color.veryLightGray};
  }

  > li {
    height: 70px;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;

    > *:first-child {
      margin-right: 15px;
    }

    > *:last-child {
      margin-left: auto;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: ${Color.primaryBlack};
    }
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  border-radius: 50%;

  &:hover {
    background-color: ${Color.primaryGray};
  }

  &:focus {
    background-color: ${Color.primaryGray};
  }
`;

export const EmailList = (props: IOwnProps) => {
  const { emails, removeEmail, resendEmailConfirmation, makePrimaryEmail } = props;
  const [emailDropDownKey, setEmailDropdownKey] = useState(-1);
  const { t } = useTranslation();

  async function handleMakePrimaryEmailClick(userEmailId) {
    await makePrimaryEmail(userEmailId);
    setEmailDropdownKey(-1);
  }

  return (
    <>
      {emails.length > 0 && (
        <Container>
          {emails.map((email, key) => (
            <li key={key}>
              <CheckCircle style={{ color: Color.ceregoGreen, visibility: email.primary ? 'visible' : 'hidden' }} />
              <div>
                <p style={{ color: email.confirmed ? Color.primaryBlack : Color.textGray }}>{email.address}</p>
              </div>
              <div style={{ cursor: 'pointer' }}>
                <MenuButton
                  onClick={() => setEmailDropdownKey(key === emailDropDownKey ? -1 : key)}
                  aria-label={key === emailDropDownKey ? t('Close Menu') : t('Open Menu')}
                  aria-hidden="false"
                  aria-haspopup="menu"
                >
                  <MoreVert />
                </MenuButton>
                {emailDropDownKey === key && (
                  <DropDown top={0.5} left={-6}>
                    <DropDownList>
                      <DropDownListItem disabled={email.primary}>
                        <button onClick={() => removeEmail(email.id)}>{t('Remove')}</button>
                      </DropDownListItem>
                      <DropDownListItem disabled={email.confirmed}>
                        <button onClick={() => resendEmailConfirmation(email.id)}>{t('Resend Confirmation')}</button>
                      </DropDownListItem>
                      <DropDownListItem disabled={email.primary || !email.confirmed || emails.length === 1}>
                        <button onClick={() => handleMakePrimaryEmailClick(email.id)}>{t('Make Primary')}</button>
                      </DropDownListItem>
                    </DropDownList>
                  </DropDown>
                )}
              </div>
            </li>
          ))}
        </Container>
      )}
    </>
  );
};
