import CircularProgress from '@material-ui/core/CircularProgress';
import * as Color from 'core/colors';
import * as CommonButton from 'core/v4button';
import React from 'react';
import styled from 'styled-components';
import generateId from 'utils/generateId';

const ButtonElement = styled(CommonButton.BaseButton).attrs(
  ({
    shape = 'rectangle',
    context = 'light',
    size = 'medium',
    disabled = false,
    destructive = false,
  }: CommonButton.IButton) => {
    const defaultStyles = {
      borderColor: destructive ? Color.red : Color.primaryGreen,
      backgroundColor: destructive ? Color.red : Color.primaryGreen,
      hoverBackgroundColor: destructive
        ? Color.red
        : `linear-gradient(120deg, ${Color.ceregoGreen}, ${Color.primaryGreen});`,
      borderRadius: '4px',
      width: 'unset',
      height: 'unset',
      fontSize: '1rem',
    };
    const attributeStyles: any = {
      ...CommonButton.BaseStyles,
    };
    let stateStyles: any = {};

    return {
      ...defaultStyles,
      ...attributeStyles[shape],
      ...attributeStyles[context],
      ...attributeStyles[size],
      ...stateStyles,
    };
  }
)`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  border-color: ${(props) => props.borderColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${Color.white};
  font-size: ${(props) => props.fontSize};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.padding};

  :hover {
    background: ${(props) => (props.disabled ? props.backgroundColor : props.hoverBackgroundColor)};
  }
`;

interface Dimensions {
  height: number;
  width: number;
}

const PrimaryButton = ({ type = 'button', ...props }: CommonButton.IButton) => {
  const buttonId: string = generateId();
  const { children, click, pending } = props;
  const [buttonDimensions, setButtonDimensions] = React.useState<Dimensions | null>(null);

  const getButtonDimensions = (id: string): Dimensions | undefined => {
    const button = document.getElementById(id);
    if (button) {
      const dims: Dimensions = button.getBoundingClientRect();
      return dims;
    }
    return undefined;
  };

  const arbitraryMagicNumber: number = 19;
  const spinnerSize: number = buttonDimensions ? buttonDimensions.height / 2 : arbitraryMagicNumber;
  const buttonStyle = buttonDimensions
    ? { height: `${buttonDimensions.height}px`, width: `${buttonDimensions.width}px` }
    : {};

  React.useEffect(() => {
    if (pending) return; // If the button starts out pending, it might lock in a width that's too small
    const buttonDimensions = getButtonDimensions(buttonId);
    if (buttonDimensions) setButtonDimensions(buttonDimensions);
  }, [pending]);

  return (
    <ButtonElement id={buttonId} type={type} onClick={click} {...props} style={buttonStyle}>
      {pending && <CircularProgress size={spinnerSize} style={{ color: Color.white }} />}
      {!pending && <span>{children}</span>}
    </ButtonElement>
  );
};

export default PrimaryButton;
