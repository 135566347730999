import { LoadingScreen } from 'components/LoadingScreen';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import build from 'redux-object';
import { getCurrentUser } from 'store/profile';
import { queryParams } from 'utils/pathUtils';
import { getRole } from 'utils/userManager';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let currentUser;

  function getAuthenticationToken(state) {
    const accessTokens = build(state.data, 'oauth2AccessTokens');
    return accessTokens && accessTokens[0] && accessTokens[0].token;
  }

  function getUserAndRole(state) {
    currentUser = getCurrentUser(state);
    if (currentUser) {
      // Do this because getRole does not differentiate between site admin and partner admin
      if (currentUser.meta.isAdmin) {
        return 'site admin';
      }
      return getRole(currentUser);
    }
  }

  function getRouteComponent() {
    if (!currentUser) {
      return LoadingScreen;
    }
    if (rest.siteAdminOnly) {
      return (ltiToken || !queryParams(window.location).lti) && role === 'site admin' ? Component : LoadingScreen;
    } else if (rest.permittedRoles) {
      return (ltiToken || !queryParams(window.location).lti) && rest.permittedRoles.includes(role)
        ? Component
        : LoadingScreen;
    } else {
      return ltiToken || !queryParams(window.location).lti ? Component : LoadingScreen;
    }
  }

  const ltiToken = useSelector((state: any) => getAuthenticationToken(state));
  const role = useSelector((state: any) => getUserAndRole(state));
  const RouteComponent = getRouteComponent();
  return (
    <Route
      {...rest}
      render={(props) => {
        return <RouteComponent {...Object.assign(props, rest)} />;
      }}
    />
  );
};
