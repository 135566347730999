import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MemreButton } from 'components/core';
import { Pagination } from 'components/reports/controls/Pagination';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import { useCreateDepartment } from 'hooks/useCreateDepartment';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDepartments } from 'hooks/useDepartments';
import { DepartmentsTableToolbar } from 'pages/admin-dashboard/departments/DepartmentsTableToolbar';
import { useDepartmentsStore } from 'pages/admin-dashboard/departments/store';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const DepartmentsConfig = () => {
  const user = useCurrentUser();
  const partnerId = user?.primaryPartner.id;
  const { pageNumber } = useDepartmentsStore();

  // Mutation hook for creating a department
  const [createDepartment, { status: creationStatus }] = useCreateDepartment();

  // Local state for managing the create form
  const [isCreatingDepartment, setIsCreatingDepartment] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useState('');

  // Scroll to top when page number changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber]);

  // Handler for form submission
  const handleCreateDepartment = async (e: React.FormEvent) => {
    e.preventDefault();
    await createDepartment({ partnerId, name: newDepartmentName });
    setNewDepartmentName('');
    setIsCreatingDepartment(false);
  };

  return (
    <div>
      {isCreatingDepartment ? (
        <form onSubmit={handleCreateDepartment}>
          <TextField
            label="Department Name"
            margin="normal"
            value={newDepartmentName}
            onChange={(e) => setNewDepartmentName(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
          />
          <FormButtons>
            <MemreButton
              text={creationStatus === 'loading' ? 'Creating...' : 'Submit'}
              variant="contained"
              color="secondary"
              onClick={handleCreateDepartment}
              size="medium"
            />
            <MemreButton
              text="Cancel"
              variant="outlined"
              color="secondary"
              onClick={() => setIsCreatingDepartment(false)}
              size="medium"
            />
          </FormButtons>
        </form>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MemreButton
            text="Create New Department"
            variant="contained"
            color="secondary"
            onClick={() => setIsCreatingDepartment(true)}
            iconName="plus"
            size="medium"
          />
        </div>
      )}

      {/* Header and Search Toolbar */}
      <DepartmentsTableToolbar />

      <DepartmentsTable />
    </div>
  );
};

interface ColumnHeaderProps {
  title: string;
  sort: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
}

const ColumnHeader = ({ title, sort, sortDirection, onSort }: ColumnHeaderProps) => (
  <TableHeaderContainer
    style={Object.assign(
      { justifyContent: title === 'Name' ? 'left' : 'right' },
      sort === '' ? {} : { cursor: 'pointer' }
    )}
    onClick={onSort}
  >
    <TableHeaderText>{title}</TableHeaderText>
    {sort === 'name' && <SortArrow icon={sortDirection === 'desc' ? faArrowDown : faArrowUp} />}
  </TableHeaderContainer>
);

const DepartmentsTable = () => {
  const { pageNumber, setPageNumber, pageSize, setPageSize, sortDirection, setSortDirection } = useDepartmentsStore();
  const { data, isLoading } = useDepartments();
  const departments = data?.data || [];
  const departmentsMeta = data?.meta;
  const totalCount = departmentsMeta ? departmentsMeta['total-count'] : 0;
  const totalPages = departmentsMeta ? departmentsMeta['total-pages'] : 0;

  // Pagination handlers
  const goToPageOne = () => setPageNumber(1);
  const goToNextPage = () => pageNumber < totalPages && setPageNumber(pageNumber + 1);
  const goToPreviousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
  const goToLastPage = () => setPageNumber(totalPages);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1);
  };

  // Sorting handler
  const handleSortingChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setPageNumber(1);
  };

  // Table columns
  const columns: ColumnDef<any>[] = [
    {
      id: 'name',
      accessorFn: (row) => row.original.attributes.name,
      cell: ({ row }) => row.original.attributes.name,
      header: () => (
        <ColumnHeader title="Name" sort="name" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'learners',
      accessorFn: (row) => row.original.attributes['users-count'],
      cell: ({ row }) => row.original.attributes['users-count'],
      header: () => (
        <ColumnHeader title="Learners" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'price',
      accessorFn: (row) => row.original.attributes['partner-settings'].price,
      cell: ({ row }) => `$${row.original.attributes['partner-settings'].price.toFixed(2)}`,
      header: () => (
        <ColumnHeader title="Price Per Course" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
  ];
  const table = useReactTable({
    data: departments,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (!['name'].includes(header)) {
      console.warn('Cannot sort by anything other than name');
      return;
    }

    handleSortingChange();
  };

  // Loading state
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {/* Departments Table */}
      <TableContainer>
        <StyledTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} rightAlignedColumnIds={['learners', 'price']} />
        </StyledTable>
      </TableContainer>

      {/* Pagination Component */}
      <Pagination
        totalCount={totalCount}
        page={pageNumber}
        pageSize={pageSize}
        goToPageOne={goToPageOne}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

const FormButtons = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
