import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { AnnotationActions } from 'components/item-editor/instructional-content/AnnotationActions';
import { EmbedAnnotation } from 'components/item-editor/instructional-content/EmbedAnnotation';
import { ImageAnnotation } from 'components/item-editor/instructional-content/ImageAnnotation';
import { SoundAnnotation } from 'components/item-editor/instructional-content/SoundAnnotation';
import { TextAnnotation } from 'components/item-editor/instructional-content/TextAnnotation';
import { VideoAnnotation } from 'components/item-editor/instructional-content/VideoAnnotation';
import { memo } from 'react';
import { TAnnotation } from 'types';
import { deepCompareArrays } from 'utils/deepCompareArrays';

export interface AnnotationProps {
  annotation: TAnnotation;
  itemId: string;
  setId: string;
}

export const Annotation = memo(
  (props: AnnotationProps) => {
    const { itemId, setId, annotation } = props;

    const AnnotationContent = () => {
      switch (annotation.noteType) {
        case 'text':
          return <TextAnnotation annotation={annotation} itemId={itemId} setId={setId} />;
        case 'image':
          return <ImageAnnotation annotation={annotation} />;
        case 'sound':
          return <SoundAnnotation annotation={annotation} />;
        case 'embed':
          return <EmbedAnnotation annotation={annotation} />;
        case 'video':
          return <VideoAnnotation annotation={annotation} />;
        default:
          return <></>;
      }
    };

    return (
      <Card style={{ marginBottom: '15px' }}>
        <CardContent>
          <AnnotationContent />
        </CardContent>
        <AnnotationActions itemId={itemId} setId={setId} annotation={annotation} />
      </Card>
    );
  },
  (oldProps, newProps) => {
    return deepCompareArrays([oldProps as any], [newProps as any]);
  }
);
