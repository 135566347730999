import axios from 'axios';
import { useMutation } from 'react-query';
import useStore from 'zstore';

// TODO: fix this to actually delete domains
// NOTE: this has apparently never worked, so it's not like it's a regression
const deletePartnerDomain = ({ partnerId, domainId }) => {
  return axios.delete(`/api/v3/partners/${partnerId}/partner_domains/${domainId}`);
};

export const useDeletePartnerDomain = () => {
  const { makeToast } = useStore();

  return useMutation(deletePartnerDomain, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to delete domain',
        isError: true,
      });
    },
  });
};
