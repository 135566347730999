import * as Plot from '@observablehq/plot';
import { useTooltipStore } from 'hooks/useTooltipStore';
import { useEffect, useRef, useState } from 'react';

export default function MemrePlot({ data, aspectRatio = 2, tooltipFunction }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const newWidth = containerRef.current?.clientWidth || 0;
    setWidth(newWidth);
    setHeight(newWidth / aspectRatio);
  }, [containerRef.current?.clientWidth]);

  const showTooltip = useTooltipStore((state) => state.showTooltip);
  const hideTooltip = useTooltipStore((state) => state.hideTooltip);

  useEffect(() => {
    if (!data) {
      return;
    }
    const chart = Plot.plot({ ...data, width, height });

    const handleMouseMove = (event: any) => {
      const targetElement = event.target as HTMLElement;
      const tooltipKey = targetElement.getAttribute('aria-label');

      if (tooltipKey) {
        const rect = targetElement.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;

        const restraints = { bounds: { x, y } };

        showTooltip({ content: tooltipFunction(tooltipKey), restraints });
      } else {
        hideTooltip();
      }
    };

    if (chartRef.current) {
      chartRef.current?.appendChild(chart);

      const elements = chart.querySelectorAll('[title]');
      elements.forEach((el) => {
        const title = el.getAttribute('title');
        if (title) {
          el.setAttribute('data-memre-tooltip', title);
        }
      });

      chart.addEventListener('mousemove', handleMouseMove);
      chart.addEventListener('mouseout', hideTooltip);
    }

    return () => {
      chart.removeEventListener('mousemove', handleMouseMove);
      chart.removeEventListener('mouseout', hideTooltip);
      chart.remove();
    };
  }, [chartRef.current, data]);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
      }}
    >
      <div ref={chartRef} />
    </div>
  );
}
