import correctIcon from 'assets/images/correct.svg';
import dragIcon from 'assets/images/dragIndicator.png';
import incorrectIcon from 'assets/images/incorrect.svg';
import { Color } from 'core';
import AudioPlayer from 'learn/components/AudioPlayer';
import SanitizedHtml from 'learn/components/SanitizedHtml';
import ZoomableImageContainer from 'learn/components/ZoomableImageContainer';
import { IQuizPart } from 'models/quiz';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Flipped } from 'react-flip-toolkit';
import styled from 'styled-components';
import { isRightToLeft } from 'i18n';

export interface IOwnProps {
  item: IQuizPart;
  index: number;
  showIcon?: boolean;
  isDraggable: boolean;
}

interface IItemProps {
  isDragging?: boolean;
  rightToLeft?: boolean;
}

interface IOwnState {
  isModalOpen?: boolean;
}

const Item = styled.li<IItemProps>`
  background: white;
  min-height: 62px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: ${props => (props.rightToLeft ? 'row-reverse' : 'row')}
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid ${Color.veryLightBlue};
  box-shadow: ${(props: IItemProps) => (props.isDragging ? '0 5px 25px 0 rgba(0, 0, 0, 0.2)' : 'none')};
  padding: 20px;
`;

const DragIcon = styled.img`
  user-select: none;
  width: 30px;
`;

const CorrectIcon = styled.img`
  width: 20px;
`;

const ImageContainer = styled.div`
  & img {
    width: 140px;
    cursor: pointer;
  }
  margin-right: 20px;
`;

// flex items always assume 100% height, but the drag icon is shrunk, so it has to be centered
const DragIconContainer = styled.div`
  align-self: center;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  button {
    margin-right: 0;
  }
`;

export class SequenceItem extends React.Component<IOwnProps, IOwnState> {
  private rightToLeft;
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
    this.rightToLeft = isRightToLeft();
  }

  public render() {
    const { item, index, isDraggable, showIcon } = this.props;
    return (
      <div key={item.id} data-testid={item.testId}>
        {isDraggable && (
          <Draggable key={item.id} draggableId={item.id} index={index} shouldRespectForceTouch={true} dir="rtl">
            {(provided, snapshot) => (
              <Item
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                rightToLeft={this.rightToLeft}
              >
                <ContentContainer>
                  {item.image && (
                    <ImageContainer>
                      <ZoomableImageContainer image={item.image} transcodedSize={'medium'} />
                    </ImageContainer>
                  )}
                  <SanitizedHtml source={item.textHtml} />
                  {item.sound && <AudioPlayer src={item.sound.url} size="small" />}
                </ContentContainer>
                <DragIconContainer>
                  <DragIcon src={dragIcon} />
                </DragIconContainer>
              </Item>
            )}
          </Draggable>
        )}
        {!isDraggable && (
          <Flipped key={item.id} flipId={item.id}>
            <Item rightToLeft={this.rightToLeft}>
              <ContentContainer>
                {item.image && (
                  <ImageContainer>
                    <ZoomableImageContainer image={item.image} transcodedSize={'medium'} />
                  </ImageContainer>
                )}
                <SanitizedHtml source={item.textHtml} />
              </ContentContainer>
              {showIcon && <CorrectIcon src={item.correctPosition !== index + 1 ? incorrectIcon : correctIcon} />}
            </Item>
          </Flipped>
        )}
      </div>
    );
  }
}
