import {
  faAppleAlt,
  faArrowDown,
  faArrowUp,
  faBook,
  faChalkboard,
  faChartPie,
  faKey,
  faSearch,
  faShieldAlt,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Pagination } from 'components/reports/controls/Pagination';
import { TableSearchBar } from 'components/tables/TableSearchBar';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import { Color } from 'core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDeletePartnerUser } from 'hooks/useDeletePartnerUser';
import { usePaginatedMeta } from 'hooks/usePaginatedMeta';
import { usePartnerUsers } from 'hooks/usePartnerUsers';
import { debounce } from 'lodash';
import { ChangePasswordModal } from 'pages/admin-dashboard/people/ChangePasswordModal';
import { usePeopleStore } from 'pages/admin-dashboard/people/store';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatPartnerUserRole } from 'utils/formatPartnerUserRole';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

export const Table = () => {
  const user = useCurrentUser();
  const { pageNumber, setPageNumber, pageSize, setPageSize, sortDirection, setSortDirection } = usePeopleStore();
  const { data: response } = usePartnerUsers();
  const { totalCount, totalPages } = usePaginatedMeta(response);
  const people = normalizeJSONAPIResponse(response?.data);
  const [deletePartnerUser] = useDeletePartnerUser();

  // Pagination handlers
  const goToPageOne = () => setPageNumber(1);
  const goToNextPage = () => !!totalPages && pageNumber < totalPages && setPageNumber(pageNumber + 1);
  const goToPreviousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
  const goToLastPage = () => setPageNumber(totalPages || 0);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1);
  };

  // Sorting handler
  const handleSortingChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setPageNumber(1);
  };

  // Table columns
  const columns: ColumnDef<any>[] = [
    {
      id: 'id',
      accessorFn: (row) => row.original.id,
      cell: ({ row }) => <p>{row.original.id}</p>,
      header: () => <ColumnHeader title="ID" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
    {
      id: 'name',
      accessorFn: (row) => row.original.name,
      cell: ({ row }) => <p>{row.original.name}</p>,
      header: () => (
        <ColumnHeader title="Name" sort="name" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'email',
      accessorFn: (row) => row.original.email,
      cell: ({ row }) => <p>{row.original.email}</p>,
      header: () => <ColumnHeader title="Email" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
    {
      id: 'member-id',
      accessorFn: (row) => row.original.userPartnerId?.memberId,
      cell: ({ row }) => <p>{row.original.userPartnerId?.memberId}</p>,
      header: () => (
        <ColumnHeader title="Member ID" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'role',
      accessorFn: (row) => row.original.role,
      cell: RoleCell,
      header: () => <ColumnHeader title="Role" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
    {
      id: 'actions',
      cell: ActionsCell,
      header: () => <ColumnHeader title="Actions" sort="" sortDirection={sortDirection} onSort={() => {}} />,
    },
  ];

  function RoleCell({ row }) {
    const formattedRole = formatPartnerUserRole(row.original.meta.role);

    let avatar: any = null;
    switch (formattedRole) {
      case 'Learner':
        avatar = <FontAwesomeIcon icon={faUser} />;
        break;
      case 'Admin':
        avatar = <FontAwesomeIcon icon={faShieldAlt} />;
        break;
      case 'Content Manager':
        avatar = <FontAwesomeIcon icon={faBook} />;
        break;
      case 'Instructor':
        avatar = <FontAwesomeIcon icon={faAppleAlt} />;
        break;
      case 'Course Manager':
        avatar = <FontAwesomeIcon icon={faChalkboard} />;
        break;
      case 'Course Viewer':
        avatar = <FontAwesomeIcon icon={faChartPie} />;
        break;
      default:
        break;
    }

    return (
      <Chip
        label={formatPartnerUserRole(row.original.meta.role)}
        avatar={<Avatar>{avatar}</Avatar>}
        size="small"
        color={formattedRole === 'Learner' ? 'default' : formattedRole === 'Admin' ? 'secondary' : 'primary'}
        variant="outlined"
      />
    );
  }

  // Cell component for Actions column
  function ActionsCell({ row }) {
    const accountManagementEnabled = user?.primaryPartner?.partnerSettings?.accountManagementEnabled;
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

    const handleDelete = async () => {
      if (window.confirm('Are you sure you want to remove this user?')) {
        try {
          await deletePartnerUser({
            partnerId: user?.primaryPartner.id,
            userId: row.original.id,
          });
        } catch (error) {
          console.error('Error deleting user:', error);
        }
      }
    };

    const handleChangePassword = () => {
      setShowChangePasswordModal(true);
    };

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
        {accountManagementEnabled && (
          <>
            <Tooltip title="Change Password">
              <IconButton
                onClick={handleChangePassword}
                size="small"
                style={{ padding: '0.5rem', color: Color.mainBlue }}
              >
                <FontAwesomeIcon icon={faKey} />
              </IconButton>
            </Tooltip>
            {showChangePasswordModal && (
              <ChangePasswordModal
                userId={row.original.id}
                partnerId={user?.primaryPartner.id}
                onClose={() => setShowChangePasswordModal(false)}
              />
            )}
          </>
        )}
        <Tooltip title="Remove User">
          <IconButton onClick={handleDelete} size="small" style={{ padding: '0.5rem', color: Color.veryDarkOrange }}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  const table = useReactTable({
    data: people || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (!['name'].includes(header)) {
      console.warn('Cannot sort by anything other than name');
      return;
    }

    handleSortingChange();
  };

  return (
    <>
      <TableContainer>
        <StyledTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} />
        </StyledTable>
      </TableContainer>

      {/* Pagination Component */}
      <Pagination
        totalCount={totalCount}
        page={pageNumber}
        pageSize={pageSize}
        goToPageOne={goToPageOne}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

interface ColumnHeaderProps {
  title: string;
  sort: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
}

const ColumnHeader = ({ title, sort, sortDirection, onSort }: ColumnHeaderProps) => (
  <TableHeaderContainer
    style={Object.assign(
      { justifyContent: title === 'Actions' ? 'center' : 'left' },
      sort === '' ? {} : { cursor: 'pointer' }
    )}
    onClick={onSort}
  >
    <TableHeaderText>{title}</TableHeaderText>
    {sort === 'name' && <SortArrow icon={sortDirection === 'desc' ? faArrowDown : faArrowUp} />}
  </TableHeaderContainer>
);

const TableContainer = styled.div`
  margin-top: 20px;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableToolbar = () => {
  const { setPageNumber, name, setName } = usePeopleStore();

  return (
    <ToolbarContainer>
      <TableSearchBar
        value={name}
        onChange={setName}
        resetPage={() => setPageNumber(1)}
        placeholder="Search by name or email..."
      />
    </ToolbarContainer>
  );
};

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
`;
