import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';
import { FONT, SHADOW } from '../constants';

type XTicksProps = {
  start?: number;
  stop?: number;
  count?: number;
  textFormat?: (x: number) => string;
};
export default function XTicks({ start = 10, stop = 90, count = 4, textFormat = (x) => x.toFixed(0) }: XTicksProps) {
  return [
    Plot.textX(d3.ticks(start, stop, count), {
      fill: SHADOW.COLOR,
      dy: 10,
      text: () => '▲',
      textAnchor: 'middle',
      frameAnchor: 'bottom',
      fontSize: 10,
    }),
    Plot.textX(d3.ticks(start, stop, count), {
      fill: FONT.COLOR.DARK,
      dy: 22,
      text: textFormat,
      frameAnchor: 'bottom',
    }),
    Plot.axisX({ ticks: [], tickSize: 0, label: null }),
    Plot.axisY({
      ticks: [],
      tickSize: 0,
      label: null,
    }),
  ];
}
