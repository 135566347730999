import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CellWithPill from 'components/tables/CellWithPill';
import { Color } from 'core';

interface IProps extends WithTranslation {
  assignmentCount: number;
  sharedAssignmentCount: number;
}

class CourseAssignmentCell extends React.Component<IProps> {
  public render() {
    const { sharedAssignmentCount, assignmentCount, t } = this.props;
    const assignmentCellCount: number = assignmentCount ?? 0;
    if (sharedAssignmentCount > 0) {
      const pillColor = Color.primaryGreen;
      return (
        <CellWithPill
          text={t('Course Assignment Cell Text', { count: assignmentCellCount })}
          textColor={Color.reportGray}
          pillText={t('Course Assignment Cell Shared Text', { count: sharedAssignmentCount })}
          pillColor={pillColor}
          pillBackgroundColor={Color.hexToRGB(pillColor, 0.1)}
        />
      );
    } else {
      return (
        <span
          style={{
            color: Color.reportGray,
            width: '25px'
          }}
        >
          {t('Course Assignment Cell Text', { count: assignmentCellCount })}
        </span>
      );
    }
  }
}

export default withTranslation()(CourseAssignmentCell);
