import { MemreFlexBox, MemreLayout, MemreLoading, MemreSection, MemreSelectMenu, MemreText } from 'components/core';
import * as Color from 'core/colors';
import { useAssignments } from 'hooks/useAssignments';
import { useCourse } from 'hooks/useCourse';
import { useStudents } from 'hooks/useStudents';
import KnowledgeBankVis from 'pages/manager-knowledge-bank/KnowledgeBankVis';
import { useEffect, useState } from 'react';

export default function ManagerKnowledgeBank({ match }) {
  const { courseId } = match.params;
  const { data: course } = useCourse(courseId || '');
  const { data: assignments, isLoading: assignmentsLoading } = useAssignments(courseId);
  const { data: students, isLoading: studentsLoading } = useStudents(courseId);

  const [sets, setSets] = useState<any[]>([]);
  const [set, setSet] = useState(null);

  const [student, setStudent] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!assignmentsLoading && assignments) {
      const newSets = assignments.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setSets(newSets);
    }
  }, [assignmentsLoading, assignments]);

  useEffect(() => {
    if (!studentsLoading) {
      setUsers(students.map((d: any) => ({ label: d.name, value: d.id })));
    }
  }, [studentsLoading]);

  return (
    <MemreLayout isLoading={!course} headerText={course?.name} backLink="/nav/v4/dashboard">
      <MemreSection>
        <MemreFlexBox direction="column">
          {assignmentsLoading || studentsLoading ? (
            <MemreFlexBox direction="column" align="center" justify="center" gap={2} sx={{ p: 3 }}>
              <MemreLoading /> <MemreText sx={{ color: Color.darkGray }}>Loading Filters</MemreText>
            </MemreFlexBox>
          ) : (
            <MemreFlexBox direction="row" gap={2} sx={{ p: 2 }}>
              <MemreSelectMenu
                label="Filter Sets"
                items={sets}
                value={set}
                withSearch={true}
                onChange={(_e: any, n: any) => setSet(n ? n.value : null)}
              />

              <MemreSelectMenu
                label="Filter Students"
                items={users}
                value={student}
                withSearch={true}
                onChange={(_e: any, n: any) => setStudent(n ? n.value : null)}
              />
            </MemreFlexBox>
          )}
          <KnowledgeBankVis courseId={courseId} set={set} student={student} />
        </MemreFlexBox>
      </MemreSection>
    </MemreLayout>
  );
}
