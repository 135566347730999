import axios from 'axios';
import { useQuery } from 'react-query';

export const getContentInsights = async (
  _key: string,
  courseId: string | null,
  setId: string | null,
  userId: string | null,
  page: number,
  pageSize: number
) => {
  if (!courseId || !setId) {
    console.warn('Cannot get content insights without both courseId and setId');
    return;
  }

  const params = {
    setId: `set_id=${setId}`,
    userId: userId ? `user_id=${userId}` : '',
    page: `page[number]=${page}`,
    pageSize: `page[size]=${pageSize}`,
  };
  const endpoint = `/api/v4/courses/${courseId}/content_insights?${params.setId}&${params.userId}&${params.page}&${params.pageSize}`;

  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const useContentInsights = (
  courseId: string | null,
  setId: string | null,
  userId: string | null,
  page: number,
  pageSize: number
) => {
  return useQuery(['contentInsights', courseId, setId, userId, page, pageSize], getContentInsights, {
    enabled: courseId && setId,
  });
};
