import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

type Payload = {
  title: string;
  partnerId: number;
  courseId: string;
  level?: string;
  language?: string;
};

const createSmartAssignment = async (payload: Payload) => {
  const formData = new FormData();
  formData.append(
    'section',
    JSON.stringify({ title: payload.title, description: `Learn the fundamentals of ${payload.title}` })
  );
  formData.append('level', payload.level || 'Intermediate');
  formData.append('language', payload.language || 'English');
  formData.append('partner_id', payload.partnerId.toString());
  formData.append('course_id', payload.courseId);

  const response = await axios.post('/api/v4/smart_assignments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return normalizeJSONAPIResponse(response.data);
};

export const useCreateSmartAssignment = (courseId: string) => {
  return useMutation(createSmartAssignment, {
    onSuccess: () => {
      queryCache.invalidateQueries(['assignments', courseId]);
    },
    onMutate: () => {
      queryCache.invalidateQueries(['assignments', courseId]);
    },
    onSettled: () => {
      queryCache.invalidateQueries(['assignments', courseId]);
    },
  });
};
