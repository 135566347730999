import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { SurveyReport } from 'sets/components/SurveyReport';
import { handleGetSurveyResults } from 'store/survey';

interface IProps {
  set: any;
  params: { courseId: string; setId: string };
}

export const SurveyDetailsReport = React.memo<IProps>(props => {
  const dispatch = useDispatch();
  const { set } = props;
  const { courseId } = props.params;
  const surveyResults = useSelector(buildAndTransformSurveyResults);
  const reportProps = {
    set,
    courseId,
    surveyResults
  };

  useEffect(() => {
    dispatch(handleGetSurveyResults(courseId, set.id));
  }, [courseId, set.id, dispatch]);

  return <SurveyReport {...reportProps} />;

  function buildAndTransformSurveyResults(state) {
    const resultMeta = state.data.meta[`/api/v4/sets/${set.id}/survey_results`] || null;
    if (!resultMeta) {
      return null;
    }

    const multi = build(state.data, 'surveyQuestionResultMultipleChoices', null, { includeType: true });
    const free = build(state.data, 'surveyQuestionResultFreeResponses', null, { includeType: true });
    const ratings = build(state.data, 'surveyQuestionResultRatings', null, { includeType: true });

    return orderedList([multi, free, ratings], resultMeta.data);
  }

  function orderedList(responses, ordered) {
    const responseSet = responses.flat(1);
    const orderedItems = ordered.map(item => _.find(responseSet, o => o && o.id === item.id && o.type === item.type));

    return orderedItems;
  }
});
