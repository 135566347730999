import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getPartnerLibrary = async (_key: string, partnerId: string) => {
  const { data } = await axios.get(`/api/v3/partners/${partnerId}/partner_library`);
  return normalizeJSONAPIResponse(data);
};

export const usePartnerLibrary = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;

  return useQuery(['partnerLibrary', partner.id], getPartnerLibrary, {
    enabled: partner,
  });
};
