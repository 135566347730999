import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroupsStore } from 'pages/admin-dashboard/groups/store';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

const deleteGroup = (id: string) => {
  return axios.delete(`/api/v3/groups/${id}`);
};

export const useDeleteGroup = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const { makeToast } = useStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupsStore();

  return useMutation(deleteGroup, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to delete group',
        isError: true,
      });
    },
    onSuccess: () => {
      makeToast({
        text: 'Group deleted successfully',
        isError: false,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['groups', partner?.id, pageNumber, pageSize, sortColumn, sortDirection, name]);
    },
  });
};
