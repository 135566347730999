import _ from 'lodash';
import { CALL_API } from 'middleware/api';
import { MAX_PAGE_SIZE } from 'utils/constants';

export const REMOVE_INSTRUCTOR_FROM_COURSE = Symbol('Remove Instructor from Course');

export interface ICourseData {
  slug?: string;
  name?: string;
  state?: 'archived' | 'published' | 'unpublished';
  send_email_invite?: boolean;
  share_assignments?: boolean;
  in_library?: boolean;
  description?: string;
  icon_image_id?: string;
}

export const handleGetCourse = (id: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/courses/${id}?include=partner,partner.partner-library,instructors,instructors.image`,
      httpMethod: 'GET',
    },
  };
};

export const updateCourse = (courseData: ICourseData, courseId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/courses/${courseId}`,
      httpMethod: 'PUT',
      data: courseData,
    },
  };
};

export const handleDeleteCourse = (courseId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/courses/${courseId}`,
      httpMethod: 'DELETE',
    },
  };
};

export const handleInviteToCourse = (courseId: string, userIds: string[], forAdmin: boolean) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/courses/${courseId}/invitations`,
      httpMethod: 'POST',
      data: {
        user_ids: userIds,
        for_admin: forAdmin,
      },
    },
  };
};

export const getLimitedCoursesEndpoint = (partnerId: string): string => {
  return `/api/v4/partners/${partnerId}/courses?limited=true&include=sets,image&page[number]=1&page[size]=${MAX_PAGE_SIZE}&status_filter=all&sort=-last_updated`;
};

export const handleGetLimitedCourses = (partnerId: string) => {
  return {
    [CALL_API]: {
      endpoint: getLimitedCoursesEndpoint(partnerId),
      httpMethod: 'GET',
    },
  };
};

export const removeInstructorFromCourse = (state, action) => {
  const { courseId, userId } = action;

  const clonedCourses = _.cloneDeep(state.courses);
  _.remove(clonedCourses[courseId].relationships.instructors.data, (instructor: any) => instructor.id === userId);

  return {
    ...state,
    courses: clonedCourses,
  };
};
