import { Color } from 'core';
import React from 'react';
import styled from 'styled-components';

const Chart = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  border-top: 1px ${Color.deepBlueGray} solid;
`;

const Title = styled.h1`
  font-size: 1rem;
  padding: 0.5rem 0 0 1.5rem;
`;

const Subtitle = styled.h2`
  font-size: 0.75rem;
  padding: 0 0 0 1.5rem;
`;

interface BodyProps {
  height?: string;
}

const Body = styled.div<BodyProps>`
  height: ${(props) => props.height || 500}px;
`;

const Footer = styled.div``;

interface CompoundChartProps {
  title?: string;
  sub?: string;
  height?: string;
  children: any;
}

export const CompoundChart: React.FC<CompoundChartProps> = ({ title, sub, height = '500', children }) => (
  <Chart>
    {(title || sub) && (
      <Header>
        {title && <Title>{title}</Title>}
        {sub && <Subtitle>{sub}</Subtitle>}
      </Header>
    )}
    <Body height={height}>{children}</Body>
    <Footer />
  </Chart>
);
