import { useTranslation } from 'react-i18next';

const useFormattedAssignmentType = (assignmentType: string) => {
  const { t } = useTranslation();

  switch (assignmentType) {
    case 'set':
      return t('Learning Set');
    case 'assessment':
      return t('Assessment');
    case 'survey':
      return t('Survey');
    case 'scorm':
      return t('SCORM');
    default:
      throw new Error(`Cannot format assignment type for ${assignmentType}`);
  }
};

export default useFormattedAssignmentType;
