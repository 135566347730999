import Paper from '@material-ui/core/Paper';
import { useItemEditorStore } from 'components/item-editor/store';
import { TSentence } from 'types';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { IconButton, InputLabel, Tooltip } from '@material-ui/core';
import { EditorProvider } from '@tiptap/react';
import { TipTapMenuBar, tipTapExtensions } from '../TipTapMenuBar';
import { AutosaveStatus } from '../AutosaveStatus';
import { SentenceImage } from './SentenceImage';
import { SentenceSound } from './SentenceSound';
import { redText } from 'core/colors';
import { kebabCase, debounce } from 'lodash';
import { useMutateSentence } from 'hooks/useMutateSentence';
import { useDeleteSentence } from 'hooks/useDeleteSentence';
import { useAutosaveStatus } from 'hooks/useAutosaveStatus';

interface SentenceFieldProps {
  sentence: TSentence;
  term: string;
}

export const SentenceField = (props: SentenceFieldProps) => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const { sentence, term } = props;
  const [mutateSentence] = useMutateSentence(item?.id, assignment?.id);
  const [deleteSentence] = useDeleteSentence(item?.id, assignment?.id);

  const [textAutosaveStatus, setTextAutosaveStatus] = useAutosaveStatus();
  const [translationAutosaveStatus, setTranslationAutosaveStatus] = useAutosaveStatus();

  if (!item) {
    return null;
  }

  const textIsValid = isValid(sentence.textHtml, term);

  function autoHighlightTerm(text: string): string {
    if (!text) {
      return text;
    }

    // Only replace the first occurrence. The backend does not support multiple highlights
    return text.replace(new RegExp(term, 'i'), '<b>$&</b>');
  }

  const setFieldText = async (field: 'textHtml' | 'translationHtml', value: string) => {
    const setAutosaveStatus = field === 'textHtml' ? setTextAutosaveStatus : setTranslationAutosaveStatus;

    const updatedSentences = item.sentences.map((currentSentence) =>
      currentSentence.id === sentence.id ? { ...currentSentence, [field]: value } : currentSentence
    );

    // update app state
    updateActiveItem({ sentences: updatedSentences });

    try {
      setAutosaveStatus('saving');
      // update in the database
      await mutateSentence({
        id: sentence.id,
        payload: {
          data: {
            attributes: {
              [kebabCase(field)]: autoHighlightTerm(value),
            },
          },
        },
      });
      setAutosaveStatus('success');
    } catch (err) {
      setAutosaveStatus('error');
      console.error(err);
    }
  };
  const debouncedSetFieldText = debounce(setFieldText, 500);

  const handleDeleteSentence = async (): Promise<void> => {
    // update app state
    const updatedSentences = item.sentences.filter((currentSentence) => currentSentence.id !== sentence.id);
    updateActiveItem({ sentences: updatedSentences });
    // delete in the database
    await deleteSentence(sentence.id);
  };

  return (
    <SentenceContainer elevation={2}>
      <div style={{ width: '100%', marginLeft: '16px', marginRight: '16px' }}>
        <div>Note: The first occurrence of the foreign term will be blanked out during quizzing.</div>
        <SentenceEditorProvider
          label="Sentence in Foreign Language"
          content={sentence.textHtml ?? ''}
          onUpdate={(value) => debouncedSetFieldText('textHtml', value)}
        />
        {!textIsValid && <div style={{ color: redText }}>Please include the foreign term in the sentence.</div>}
        <AutosaveStatus status={textAutosaveStatus} />

        <SentenceEditorProvider
          label="Sentence in Native Language"
          content={sentence.translationHtml ?? ''}
          onUpdate={(value) => debouncedSetFieldText('translationHtml', value)}
          style={{ marginTop: '16px' }}
        />
        <AutosaveStatus status={translationAutosaveStatus} />

        {/* TODO: Do we want to support sentence transliteration? */}
      </div>

      <SentenceImage sentenceId={sentence.id} image={sentence.image} />
      <SentenceSound sentenceId={sentence.id} sound={sentence.sound} />

      <Tooltip title="Delete sentence">
        <IconButton aria-label="Delete sentence" onClick={handleDeleteSentence}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </SentenceContainer>
  );
};
// check if term is in the text
function isValid(text: string | null, term: string | null): boolean {
  if (text == null || text === '' || term === '') {
    return true;
  }

  // check if term is in text
  return text.toLowerCase().includes(term?.toLowerCase() ?? '');
}
const SentenceContainer = styled(Paper)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
`;

interface SentenceInputFieldProps {
  label: string;
  content: string;
  onUpdate: (value: string) => void;
  style?: React.CSSProperties;
}

const SentenceEditorProvider = (props: SentenceInputFieldProps) => {
  const { label, content, onUpdate, style } = props;
  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px', minHeight: '100px', ...style }}>
      <InputLabel shrink={true}>{label}</InputLabel>
      <EditorProvider
        slotBefore={<TipTapMenuBar />}
        extensions={tipTapExtensions}
        content={content}
        onUpdate={({ editor }) => onUpdate(editor.getHTML())}
      >
        <></>
      </EditorProvider>
    </div>
  );
};
