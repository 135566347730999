import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useIcEditorStore } from 'components/item-editor/instructional-content/store';

export const AddEmbedAnnotationButton = () => {
  const { showEmbedForm } = useIcEditorStore();

  const useStyles = makeStyles((theme) => ({
    annotationButton: {
      paddingHorizontal: theme.spacing(4),
      paddingVertical: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  const handleAddEmbedClick = () => {
    showEmbedForm();
  };

  return (
    <Button
      className={classes.annotationButton}
      variant="outlined"
      color="primary"
      startIcon={<FontAwesomeIcon icon={faCode} />}
      onClick={handleAddEmbedClick}
    >
      Embed
    </Button>
  );
};
