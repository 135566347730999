import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ThemeProvider } from '@mui/material';
import { Modal } from 'components/Modal';
import { PrimaryButton } from 'components/buttons/v4';
import { MemreText } from 'components/core';
import { Color } from 'core';
import { useAssignments } from 'hooks/useAssignments';
import useMutateAssignment from 'hooks/useMutateAssignment';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TAssignment } from 'types';

interface ReorderAssignmentsModalProps {
  show: boolean;
  onClose: () => void;
  course: any;
}

export const ReorderAssignmentsModal: React.FC<ReorderAssignmentsModalProps> = ({
  show,
  onClose,
  course,
}) => {
  const courseId = course?.id || null;
  const { isLoading, data: allAssignments } = useAssignments(courseId);
  const [items, setItems] = useState<TAssignment[]>([]);
  const [lastSnapshot, setLastSnapshot] = useState<TAssignment[]>([]);
  const [mutateAssignment] = useMutateAssignment(courseId);

  useEffect(() => {
    if (allAssignments) {
      setItems(allAssignments);
    }
  }, [allAssignments]);

  if (!show) return null;

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    // Save snapshot for potential undo
    setLastSnapshot(items);

    const oldIndex = items.findIndex((a) => a.id === active.id);
    const newIndex = items.findIndex((a) => a.id === over.id);
    if (oldIndex === -1 || newIndex === -1) return;

    const newItems = arrayMove(items, oldIndex, newIndex);
    setItems(newItems);

    const updatedPosition = newIndex + 1; // 1-based
    const movedItem = items[oldIndex];

    try {
      await mutateAssignment({
        ids: { setId: movedItem.id, courseId: courseId },
        payload: { position: updatedPosition },
      });
    } catch (err) {
      console.error('Failed to update assignment position', err);
    }
  };

  const handleUndo = () => {
    if (lastSnapshot.length) {
      setItems(lastSnapshot);
    }
  };

  return (
    <Modal
      show={show}
      element={
        <ThemeProvider theme={theme}>
          <Container>
            <MemreText variant="h3" sx={{ margin: '0 0 1em', textAlign: 'left' }}>
              Reorder Assignments
            </MemreText>
            {isLoading ? (
              <MemreText variant="body1">Loading…</MemreText>
            ) : (
              <>
                <MemreText variant="body1" sx={{ margin: '0 0 1em', textAlign: 'left', color: Color.textGray }}>
                  Drag items in any order. Each drop will immediately save the new position.
                </MemreText>

                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <SortableContext items={items.map((i) => i.id)} strategy={verticalListSortingStrategy}>
                    <ScrollArea>
                      {items.map((assignment) => (
                        <SortableAssignmentRow key={assignment.id} assignment={assignment} />
                      ))}
                    </ScrollArea>
                  </SortableContext>
                </DndContext>
              </>
            )}

            <ButtonRow>
              {/* Optional UNDO button—only if user has done something recently */}
              {lastSnapshot.length > 0 && (
                <UndoButton onClick={handleUndo}>
                  Undo Last Move
                </UndoButton>
              )}
              <PrimaryButton click={onClose}>Done</PrimaryButton>
            </ButtonRow>
          </Container>
        </ThemeProvider>
      }
    />
  );
};

/** A single draggable row */
const SortableAssignmentRow: React.FC<{ assignment: TAssignment }> = ({ assignment }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: assignment.id });
  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    // Subtle scale and shadow while dragging
    boxShadow: isDragging ? '0 3px 8px rgba(0,0,0,0.15)' : undefined,
    zIndex: isDragging ? 999 : 'auto',
  };

  return (
    <Row ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Handle>⋮⋮</Handle>
      <Info>
        <strong>{assignment.name}</strong>
        <Subinfo>{assignment.goalType?.toUpperCase()}</Subinfo>
      </Info>
    </Row>
  );
};

// --- Styled Components --- //

const Container = styled.div`
  position: relative;
  width: 500px;
  max-width: 90vw;
  background: #fff;
  border-radius: 8px;
  padding: 2rem 1.5rem;
  box-shadow: 0 8px 20px rgba(0,0,0,0.15);
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CloseButtonArea = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const ScrollArea = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid ${Color.primaryGray};
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
`;

const Row = styled.div`
  background: #fdfdfd;
  padding: 0.75rem;
  border-bottom: 1px solid ${Color.primaryGray};
  display: flex;
  align-items: center;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #f5f5f5;
  }
`;

const Handle = styled.span`
  cursor: grab;
  font-size: 1.2rem;
  color: ${Color.textGray};
  margin-right: 1rem;
  user-select: none;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Subinfo = styled.div`
  font-size: 0.8rem;
  color: ${Color.textGray};
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  width: 100%;
  gap: 1rem;
`;

const UndoButton = styled.button`
  background: #fff;
  border: 1px solid ${Color.primaryGray};
  color: ${Color.textGray};
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f7f7f7;
  }
`;
