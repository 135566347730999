import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useStaffStore } from 'pages/admin-dashboard/staff/store';
import { queryCache, useMutation } from 'react-query';
import { PartnerUserRole } from 'utils/formatPartnerUserRole';
import useStore from 'zstore';

type UpdatePartnerUserRoleParams = {
  partnerUserRoleId: string;
  payload: {
    role: PartnerUserRole;
  };
};

const updatePartnerUserRole = (params: UpdatePartnerUserRoleParams) => {
  const { partnerUserRoleId, payload } = params;
  return axios.put(`/api/v3/partner_user_roles/${partnerUserRoleId}`, payload);
};

export const useMutatePartnerUserRole = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useStaffStore();

  return useMutation(updatePartnerUserRole, {
    onSuccess: () => {
      makeToast({
        text: 'Successfully updated staff role.',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Failed to update staff role. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries([
        'partnerUserRoles',
        user?.primaryPartner.id,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        name,
      ]);
    },
  });
};
