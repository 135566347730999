import { Shape } from 'components/item-editor/regions/QuestionWithPolygon';
import { ASPECT_RATIO, ImageDimensions } from 'components/item-editor/regions/RegionsEditor';
import React from 'react';
import styled from 'styled-components';

interface ImageWithPolygonProps {
  imageUrl: string;
  imageAlt: string;
  polygonPoints?: Array<{ x: number; y: number }>;
  shape?: Shape | null;
  currentShape?: Shape | null;
  highlightDimensions: ImageDimensions;
  containerWidth: number;
}

export const ImageWithPolygon: React.FC<ImageWithPolygonProps> = ({
  imageUrl,
  imageAlt,
  polygonPoints = [],
  shape,
  currentShape,
  highlightDimensions,
  containerWidth,
}) => {
  const svgElements: any = [];

  if (polygonPoints.length > 0) {
    const points = polygonPoints
      .map((point) => `${point.x * highlightDimensions.width},${point.y * highlightDimensions.height}`)
      .join(' ');

    svgElements.push(
      <polygon points={points} fill="rgba(0, 0, 255, 0.4)" stroke="#0000ff" strokeWidth="1" className="region blue" />
    );
  }

  const renderShape = (shape: Shape, isCurrent: boolean = false) => {
    const stroke = isCurrent ? '#ff0000' : '#0000ff';
    const fill = isCurrent ? 'rgba(255, 0, 0, 0.4)' : 'rgba(0, 0, 255, 0.4)';
    const strokeWidth = 2;

    if (shape.type === 'ellipse') {
      return (
        <ellipse
          key={isCurrent ? 'current-ellipse' : 'ellipse'}
          cx={shape.cx * highlightDimensions.width}
          cy={shape.cy * highlightDimensions.height}
          rx={shape.rx * highlightDimensions.width}
          ry={shape.ry * highlightDimensions.height}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      );
    } else if (shape.type === 'rectangle') {
      const x = shape.x * highlightDimensions.width;
      const y = shape.y * highlightDimensions.height;
      const width = shape.width * highlightDimensions.width;
      const height = shape.height * highlightDimensions.height;

      return (
        <rect
          key={isCurrent ? 'current-rectangle' : 'rectangle'}
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      );
    } else if (shape.type === 'polygon') {
      const points = shape.points
        .map((point) => `${point.x * highlightDimensions.width},${point.y * highlightDimensions.height}`)
        .join(' ');

      if (isCurrent) {
        // Render the outline using polyline
        const polylineElement = (
          <polyline
            key="current-polygon-outline"
            points={points}
            fill="none"
            stroke={stroke}
            strokeWidth={strokeWidth}
          />
        );

        // Render circles at each point
        const circleElements = shape.points.map((point, index) => {
          const cx = point.x * highlightDimensions.width;
          const cy = point.y * highlightDimensions.height;
          const isFirstPoint = index === 0;
          const radius = isFirstPoint ? 6 : 4;
          const pointFill = isFirstPoint ? '#00ff00' : '#ffffff'; // Green for first point

          return (
            <circle
              key={`current-polygon-point-${index}`}
              cx={cx}
              cy={cy}
              r={radius}
              fill={pointFill}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
          );
        });

        return [polylineElement, ...circleElements];
      } else {
        // Render the completed polygon
        return <polygon key="polygon" points={points} fill={fill} stroke={stroke} strokeWidth={strokeWidth} />;
      }
    }
    return null;
  };

  if (shape) {
    svgElements.push(renderShape(shape));
  }

  if (currentShape) {
    svgElements.push(renderShape(currentShape, true));
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        paddingBottom: `${100 / ASPECT_RATIO}%`,
        overflow: 'hidden',
      }}
    >
      <img
        src={imageUrl}
        alt={imageAlt}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: `${(highlightDimensions.width / containerWidth) * 100}%`,
          height: 'auto',
        }}
      />
      <SVGOverlay viewBox={`0 0 ${highlightDimensions.width} ${highlightDimensions.height}`} preserveAspectRatio="none">
        {svgElements}
      </SVGOverlay>
    </div>
  );
};

const SVGOverlay = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
