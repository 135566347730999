import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import welcomeImage from 'assets/images/hello.svg';
import LoadingComponent from 'components/LoadingComponent';
import { ArticleIcon } from 'components/TableFrame';
import { CoursesTableToolbar } from 'components/courses/CoursesTableToolbar';
import { useCoursesPageStore } from 'components/courses/store';
import ImageBadge from 'components/images/ImageBadge';
import { Pagination } from 'components/reports/controls/Pagination';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import { Color } from 'core';
import CourseAssignmentCell from 'courses/components/CourseAssignmentCell';
import CoursePublishDateCell from 'courses/components/CoursePublishDateCell';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGetCourses } from 'hooks/useGetCourses';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatStringForSortComparison } from 'utils/tableUtils';

export const CoursesTable = () => {
  const [coursesWithSets, setCoursesWithSets] = useState<any[]>([]);
  const [refetch, setRefetch] = useState<boolean>(false);
  const { t } = useTranslation();
  const user = useCurrentUser();
  const primaryPartnerId = user?.primaryPartner.id;

  const {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    sortColumn,
    sortDirection,
    setSortColumn,
    setSortDirection,
    name,
  } = useCoursesPageStore();
  const { data, status: queryStatus } = useGetCourses(primaryPartnerId, refetch);
  const courses = data?.data || [];
  const coursesMeta = data?.meta;
  const totalCount = coursesMeta ? coursesMeta['total-count'] : 0;
  const totalPages = coursesMeta ? coursesMeta['total-pages'] : 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber]);

  useEffect(() => {
    if (name) {
      const updatedCourses = courses.map((course) => ({ ...course, courseId: course.id }));

      const filteredSets = courses.flatMap((course) =>
        course.sets
          .filter((set: any) => set.name.toLowerCase().includes(name.toLowerCase()))
          .map((set: any) => ({ ...set, courseName: course.name, courseId: course.id }))
      );

      const combinedData = [...updatedCourses, ...filteredSets].sort((a, b) => a.courseId - b.courseId);

      setCoursesWithSets(combinedData);
    }
  }, [name, courses]);

  useEffect(() => {
    setRefetch(courses.some((course) => course.state === 'building'));
  }, [courses]);

  const getAssignmentCaption = (set: any) => {
    const suffix = ` ${t('in')} ${set.courseName}`;
    const captions = {
      set: t('Learning Set'),
      survey: t('Survey'),
      assessment: t('Assessment'),
      scorm: t('SCORM module'),
    };
    return captions[set.goalType] ? captions[set.goalType].concat(suffix) : '';
  };

  const noSetsMessage = (
    <div style={{ color: Color.darkGray, textAlign: 'center' }}>
      <ArticleIcon src={welcomeImage} style={{ marginTop: '3em' }} />
      <h2>Welcome!</h2>
      <p>Get started creating your first course.</p>
    </div>
  );

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const getNameSpan = (name: string) => (
    <span style={{ textOverflow: 'ellipsis' }}>{_.truncate(name, { length: 40 })}</span>
  );

  const columns: ColumnDef<any>[] = [
    {
      id: 'name',
      accessorFn: (row) => row.name,
      cell: ({ row }) => {
        const rowData = row.original;
        const isCourseType = isCourse(rowData);
        return (
          <div style={{ display: 'flex' }}>
            <SCourseImageContainer style={{ flex: 1 }}>
              {isCourseType ? (
                <ImageBadge image={rowData.image} style={courseImageStyles} entity="course" />
              ) : (
                <FontAwesomeIcon
                  icon={faArrowUp}
                  style={{
                    display: 'inline',
                    color: Color.primaryBlack,
                    fontSize: '16px',
                    marginLeft: '10px',
                    top: '3px',
                    position: 'relative',
                    marginRight: '25px',
                  }}
                />
              )}
            </SCourseImageContainer>
            <span style={{ flex: 10 }}>
              {isCourseType ? (
                <Link
                  style={{ color: Color.primaryBlack, fontWeight: 500, textDecoration: 'underline' }}
                  to={`courses/${rowData.id}`}
                  tabIndex={0}
                >
                  {getNameSpan(rowData.name)}
                </Link>
              ) : (
                <span style={{ fontWeight: 600, color: Color.primaryBlack }}>{rowData.name}</span>
              )}
              {isCourseType && (
                <div style={{ fontSize: '.8em', marginTop: '.2em', color: Color.reportGray, textOverflow: 'ellipsis' }}>
                  {_.truncate(rowData.instructorNames.join(', '), { length: 40 }) || t('No Instructors Yet')}
                </div>
              )}
              {!isCourseType && (
                <div style={{ fontSize: '.8em', marginTop: '.2em', color: Color.reportGray }}>
                  {getAssignmentCaption(rowData)}
                </div>
              )}
            </span>
          </div>
        );
      },
      header: () => (
        <ColumnHeader
          title={t('Name')}
          sort={sortColumn}
          sortDirection={sortDirection}
          onSort={() => handleSortingChange('name')}
        />
      ),
    },
    {
      id: 'status',
      accessorFn: (row) => row.state,
      cell: ({ row }) => {
        const rowData = row.original;
        return isCourse(rowData) ? (
          <CoursePublishDateCell state={rowData.state} stateUpdatedAt={rowData.stateUpdatedAt} />
        ) : (
          <span />
        );
      },
      header: () => (
        <ColumnHeader
          title={t('Status')}
          sort={sortColumn}
          sortDirection={sortDirection}
          onSort={() => handleSortingChange('status')}
        />
      ),
    },
    {
      id: 'learners',
      accessorFn: (row) => row.studentUsersCount,
      cell: ({ row }) => <span>{row.original.studentUsersCount}</span>,
      header: () => <ColumnHeader title={t('Learners')} sort={''} sortDirection={sortDirection} onSort={() => {}} />,
    },
    {
      id: 'assignments',
      accessorFn: (row) => row.goalsCount,
      cell: ({ row }) => {
        const rowData = row.original;
        return rowData.type === 'limitedCourses' ? (
          <CourseAssignmentCell
            sharedAssignmentCount={rowData.sets?.filter((set: any) => set.isShared).length}
            assignmentCount={rowData.goalsCount}
          />
        ) : null;
      },
      header: () => <ColumnHeader title={t('Assignments')} sort={''} sortDirection={sortDirection} onSort={() => {}} />,
    },
    {
      id: 'last_updated',
      accessorFn: (row) => row.updatedAt,
      cell: ({ row }) => <p>{moment(row.original.updatedAt).format('MM/DD/YY')}</p>,
      header: () => (
        <ColumnHeader
          title={t('Last Updated')}
          sort={sortColumn}
          sortDirection={sortDirection}
          onSort={() => handleSortingChange('last_updated')}
        />
      ),
    },
  ];

  const handleSortingChange = (sortBy: string) => {
    if (sortBy === sortColumn) {
      toggleSortDirection();
    } else {
      setSortColumn(sortBy);
      setSortDirection('asc');
    }
  };

  const table = useReactTable({
    data: name ? coursesWithSets : courses,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (['learners', 'assignments'].includes(header)) {
      console.warn('Cannot sort by learners or assignments');
      return;
    }

    handleSortingChange(header);
  };

  const goToPageOne = () => setPageNumber(1);
  const goToNextPage = () => pageNumber < totalPages && setPageNumber(pageNumber + 1);
  const goToPreviousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
  const goToLastPage = () => setPageNumber(totalPages);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1);
  };

  if (queryStatus === 'loading') {
    return <LoadingComponent component={<></>} isLoading={true} />;
  }

  return (
    <div>
      <CoursesTableToolbar />
      <TableContainer>
        <StyledTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} />
        </StyledTable>
        {courses.length === 0 && noSetsMessage}
        <Pagination
          totalCount={totalCount}
          page={pageNumber}
          pageSize={pageSize}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </TableContainer>
    </div>
  );
};

interface ColumnHeaderProps {
  title: string;
  sort: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
}

const ColumnHeader = ({ title, sort, sortDirection, onSort }: ColumnHeaderProps) => (
  <TableHeaderContainer
    style={Object.assign({ justifyContent: 'left' }, sort === '' ? {} : { cursor: 'pointer' })}
    onClick={onSort}
  >
    <TableHeaderText>{title}</TableHeaderText>
    {sort === formatStringForSortComparison(title) && (
      <SortArrow icon={sortDirection === 'desc' ? faArrowDown : faArrowUp} />
    )}
  </TableHeaderContainer>
);

const SCourseImageContainer = styled.div`
  display: inline-block;
`;

const courseImageStyles = {
  height: '32px',
  width: '32px',
  verticalAlign: 'middle',
  marginRight: '25px',
};

const TableContainer = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const isCourse = (record: any): boolean => record?.type === 'limitedCourses';
