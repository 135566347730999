import axios from 'axios';
import { queryCache, useMutation } from 'react-query';

const updateItem = ({ id, payload }) => {
  return axios.put(`/api/v3/items/${id}`, payload);
};

export const useMutateItem = (itemId: string = '', setId: string = '') => {
  return useMutation(updateItem, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
    },
    onSettled: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
  });
};
