import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { InviteStaff } from 'pages/admin-dashboard/staff/InviteStaff';
import { useState } from 'react';

export const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <MemreFlexBox gap={4} direction="row" justify="space-between" align="center">
        <MemreText variant="h3">Staff</MemreText>
        <MemreButton
          text="Invite Staff"
          variant="outlined"
          onClick={() => setIsModalOpen(true)}
          color="secondary"
          iconName="paperPlane"
          size="small"
        />
      </MemreFlexBox>

      {isModalOpen && <InviteStaff setIsModalOpen={setIsModalOpen} />}
    </>
  );
};
