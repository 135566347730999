import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextStyle from '@tiptap/extension-text-style';
import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import ceregoIconGreyRounded from 'assets/images/cerego-icon-grey-rounded.png';
import { ImageEditOverlay } from 'components/images/ImageBadge';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { useItemEditorStore } from 'components/item-editor/store';
import { Color as MemreColor } from 'core';
import { useDeleteFacet } from 'hooks/useDeleteFacet';
import { useMutateFacet } from 'hooks/useMutateFacet';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { createImage } from 'utils/createImage';
import { createSound } from 'utils/createSound';
import useStore from 'zstore';

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Subscript,
  Superscript,
];

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '8px',
      }}
    >
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        bullet list
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        ordered list
      </button>
      <button
        onClick={() => editor.chain().focus().toggleSubscript().run()}
        className={editor.isActive('subscript') ? 'is-active' : ''}
      >
        subscript
      </button>
      <button
        onClick={() => editor.chain().focus().toggleSuperscript().run()}
        className={editor.isActive('superscript') ? 'is-active' : ''}
      >
        superscript
      </button>
    </div>
  );
};

export const Step = ({ facet, index }) => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const [deleteFacet] = useDeleteFacet('deleteFacet', assignment?.id || '', item?.id || '');
  const [mutateFacet] = useMutateFacet(item?.id, assignment?.id);
  const [autosaveStatus, setAutosaveStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');
  const imageFileInputRef = useRef<HTMLInputElement>(null);
  const soundFileInputRef = useRef<HTMLInputElement>(null);
  const { makeToast } = useStore();

  useEffect(() => {
    if (autosaveStatus === 'success' || autosaveStatus === 'error') {
      const timer = setTimeout(() => {
        setAutosaveStatus('idle');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autosaveStatus]);

  const debouncedTextChange = debounce(async (editor) => {
    setAutosaveStatus('saving');

    try {
      await mutateFacet({
        id: facet.id,
        payload: {
          data: {
            relationships: {
              association: {
                data: {
                  attributes: {
                    'text-html': editor.getHTML(),
                  },
                },
              },
            },
          },
        },
      });
      setAutosaveStatus('success');
    } catch (error) {
      console.error('Error updating facet:', error);
      setAutosaveStatus('error');
    }
  }, 500);

  const handleTextChange = ({ editor }) => {
    debouncedTextChange(editor);
  };

  const deleteStep = async () => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    updateActiveItem({
      facets: item.facets.filter((f) => f.id !== facet.id),
    });

    await deleteFacet(facet.id);
  };

  const handleSoundClick = () => {
    if (soundFileInputRef.current) {
      soundFileInputRef.current.click();
    }
  };

  const handleSoundUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const file = event.target.files?.[0];

    try {
      if (file) {
        const sound = await createSound(file);

        // update app state
        updateActiveItem({
          facets: item.facets.map((f: any) => {
            if (f.id === facet.id) {
              return { ...f, association: { ...f.association, sound } };
            }
            return f;
          }),
        });

        // update the sound in the database
        await mutateFacet({
          id: facet.id,
          payload: {
            data: {
              relationships: {
                association: {
                  data: {
                    relationships: {
                      sound: {
                        data: {
                          id: sound.id,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        });
      }
    } catch (error: any) {
      // TODO: roll back optimistic update
      // TODO: show error toast
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  };

  const handleDeleteSound = async () => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    // update app state
    updateActiveItem({
      facets: item.facets.map((f: any) => {
        if (f.id === facet.id) {
          return { ...f, association: { ...f.association, sound: null } };
        }
        return f;
      }),
    });

    // update the sound in the database
    await mutateFacet({
      id: facet.id,
      payload: {
        data: {
          relationships: {
            association: {
              data: {
                relationships: {
                  sound: {
                    data: null,
                  },
                },
              },
            },
          },
        },
      },
    });
  };

  const handleImageClick = () => {
    if (imageFileInputRef.current) {
      imageFileInputRef.current.click();
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const file = event.target.files?.[0];

    try {
      if (file) {
        const image = await createImage(file);

        // update app state
        updateActiveItem({
          facets: item.facets.map((f: any) => {
            if (f.id === facet.id) {
              return { ...f, association: { ...f.association, image } };
            }
            return f;
          }),
        });

        await mutateFacet({
          id: facet.id,
          payload: {
            data: {
              relationships: {
                association: {
                  data: {
                    relationships: {
                      image: {
                        data: {
                          id: image.id,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        });
      }
    } catch (error: any) {
      // TODO: roll back optimistic update
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  };

  const handleDeleteImage = async (): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    // update app state
    updateActiveItem({
      facets: item.facets.map((f: any) => {
        if (f.id === facet.id) {
          return { ...f, association: { ...f.association, image: null } };
        }
        return f;
      }),
    });

    await mutateFacet({
      id: facet.id,
      payload: {
        data: {
          relationships: {
            association: {
              data: {
                relationships: {
                  image: {
                    data: null,
                  },
                },
              },
            },
          },
        },
      },
    });
  };

  return (
    <StepContainer>
      <StepIndex>{index + 1}</StepIndex>
      <EditorContainer>
        <EditorProvider
          slotBefore={<MenuBar />}
          extensions={extensions}
          content={facet.association.textHtml}
          onUpdate={handleTextChange}
        >
          <></>
        </EditorProvider>
        <AutosaveStatus status={autosaveStatus} />
      </EditorContainer>

      <div style={{ position: 'relative' }}>
        <ImageEditOverlay
          style={{
            width: '85px',
            height: '85px',
            borderRadius: '20%',
            background: `${MemreColor.sideNavDark}`,
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={handleImageClick}
        >
          <FontAwesomeIcon icon={faEdit} style={{ color: 'white', fontSize: '22px' }} />
        </ImageEditOverlay>
        <img
          src={facet.association.image ? facet.association.image.url : ceregoIconGreyRounded}
          style={{
            objectFit: 'cover',
            width: '85px',
            height: '85px',
            borderRadius: '20%',
          }}
        />
        <FileInput ref={imageFileInputRef} type="file" accept="image/*" onChange={handleImageUpload} />
        {!!facet.association.image && (
          <Button variant="text" color="secondary" onClick={handleDeleteImage}>
            Delete Image
          </Button>
        )}
      </div>

      {!facet.association.sound ? (
        <>
          <IconButton onClick={handleSoundClick}>
            <VolumeUpIcon />
          </IconButton>
          <FileInput ref={soundFileInputRef} type="file" accept="audio/*" onChange={handleSoundUpload} />
        </>
      ) : (
        <>
          <audio controls>
            <source src={facet.association.sound?.url} type={'audio/mpeg'} />
            Your browser does not support the audio element.
          </audio>
          <IconButton onClick={handleDeleteSound}>
            <DeleteIcon />
          </IconButton>
        </>
      )}

      <IconButton onClick={deleteStep}>
        <DeleteIcon />
      </IconButton>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const StepIndex = styled.p`
  margin-right: 10px;
  font-weight: bold;
`;

const EditorContainer = styled.div`
  flex-grow: 1;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
`;

const FileInput = styled.input`
  display: none;
`;
