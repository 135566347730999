import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { ReadinessTooltip } from 'components/ReadinessTooltip';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import React from 'react';
import { formatStringForSortComparison } from 'utils/tableUtils';
import { LearnersTableState, useLearnersTableStore } from 'zstore';

interface Props {
  title: string;
  rightAlign?: boolean;
}

export const ColumnHeader = (props: Props) => {
  const { title, rightAlign } = props;
  const sort = useLearnersTableStore((state: LearnersTableState) => state.sort);
  const sortDirection = useLearnersTableStore((state: LearnersTableState) => state.sortDirection);

  return (
    <TableHeaderContainer style={{ justifyContent: !!rightAlign ? 'right' : 'left' }}>
      <TableHeaderText>{title}</TableHeaderText>
      {sort === formatStringForSortComparison(title) && sortDirection === 'desc' && <SortArrow icon={faArrowDown} />}
      {sort === formatStringForSortComparison(title) && sortDirection === 'asc' && <SortArrow icon={faArrowUp} />}
      {title === 'Readiness' && <ReadinessTooltip />}
    </TableHeaderContainer>
  );
};
