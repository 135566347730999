import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import * as Color from 'core/colors';
import _ from 'lodash';
import { useState } from 'react';
import getCumulativeSum from 'utils/getCumulativeSum';
import getMedian from 'utils/getMedian';
import toTitleCase from 'utils/toTitleCase';
import { MemreFlexBox } from '../core';

type Props = {
  data: any[];
  fields: string[];
  fieldNames: string[];
  denomination: string;
  topNumber?: number;
};

export default function MemrePeopleList({ data, fields, fieldNames, denomination, topNumber = 0 }: Props) {
  const [ascending, setAscending] = useState(true);
  const [sortIndex, setSortIndex] = useState(0);

  // @ts-ignore lodash very typings are out of sync with MUI and react
  const get = (d, i) => Math.max(0, parseFloat(_.get(d, fields[i])));

  const sortedData = _.sortBy(data, (d) => (ascending ? -get(d, sortIndex) : get(d, sortIndex)));

  const topResults = fields
    .map((f, i) => Math.max(topNumber, Math.max(...data.map((d) => get(d, i)))))
    .map((n) => _.round(n / 5) * 5);

  const medians = fields.map((f, i) => _.round(getMedian(data.map((d) => get(d, i)))));

  const widths = sortedData.map((row) => {
    return fields.map((field, fi) => {
      const value = get(row, fi);
      const topResult = topResults[fi];
      return (100 / topResult) * value;
    });
  });

  const percentages = medians.map((median, i) => (100 / topResults[i]) * median);

  const changeSortDirection = (index: number) => {
    setSortIndex(index);
    setAscending(!ascending);
  };

  const strokeColor = (value: number, i: number) => {
    const thisPercentage = (100 / topResults[i]) * value;
    const aboveAverage = thisPercentage >= getCumulativeSum(percentages, i);
    return aboveAverage
      ? Color.hexToRGB(Color.veryLightGray, 0.3 * (i + 1))
      : Color.hexToRGB(Color.reportBlue, 0.3, 0.4 / (i + 1));
  };

  return (
    <div style={{ maxHeight: '75vh', overflowY: 'auto', width: '100%' }}>
      <Table stickyHeader={true} style={{ display: 'block', width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ p: 1, backgroundColor: Color.veryLightGray }}>Name</TableCell>
            {fieldNames.map((name, i) => (
              <TableCell key={`header-${i}`} sx={{ p: 1, backgroundColor: Color.veryLightGray }}>
                <TableSortLabel
                  active={sortIndex === i}
                  onClick={() => changeSortDirection(i)}
                  direction={ascending ? 'asc' : 'desc'}
                >
                  {name}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell sx={{ flex: 1, pl: 1, pr: 3, pt: 1, pb: 0, backgroundColor: Color.veryLightGray }}>
              <MemreFlexBox>
                {fields.map((f, i) => (
                  <MemreFlexBox
                    key={`mean-${i}`}
                    sx={{ width: `${percentages[i] > 10 ? percentages[i] : 0}%` }}
                    justify="flex-end"
                  >
                    {!!medians[i] && (
                      <MemreFlexBox
                        direction="column"
                        justify="center"
                        sx={{ width: '50px', marginRight: '-25px', textAlign: 'center' }}
                      >
                        <span style={{ marginBottom: '-0.7em', marginTop: '-0.2em' }}>
                          {getCumulativeSum(medians, i)}
                        </span>
                        <span style={{ fontSize: '0.5em', color: Color.darkGray, marginBottom: '-0.4em' }}>▼</span>
                      </MemreFlexBox>
                    )}
                  </MemreFlexBox>
                ))}
              </MemreFlexBox>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((d, ri) => (
            <TableRow key={`name-${d.id}`}>
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  p: 1,
                  maxWidth: '200px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    whiteSpace: 'normal',
                    overflow: 'visible',
                    textOverflow: 'clip',
                  },
                }}
              >
                {toTitleCase(d.name)}
              </TableCell>
              {fields.map((f, i) => (
                <TableCell
                  key={`field-${i}`}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 1 }}
                >
                  <b>{_.round(get(d, i))}</b> {denomination}
                </TableCell>
              ))}
              {fields.length > 0 && (
                <TableCell sx={{ pl: 1, pr: 3, py: 1 }}>
                  <svg width="100%" height="10">
                    <rect width="100%" height="10" fill={Color.veryLightGray} />
                    {fields.map((f, i) => (
                      <rect
                        key={`rect-${i}`}
                        x={`${i ? widths[ri][i - 1] : 0}%`}
                        width={`${widths[ri][i]}%`}
                        height="10"
                        fill={Color.hexToRGB(Color.reportBlue, 0.8 / (i + 1), 0.4 / (i + 1))}
                      />
                    ))}
                    {fields.map((f, i) => (
                      <line
                        key={`line-${i}`}
                        x1={`${getCumulativeSum(percentages, i)}%`}
                        y1="0"
                        x2={`${getCumulativeSum(percentages, i)}%`}
                        y2="10"
                        stroke={strokeColor(get(d, i), i)}
                        strokeWidth="2"
                      />
                    ))}
                  </svg>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
