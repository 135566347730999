import {
  faChartBar,
  faCheckSquare,
  faClone,
  faFileAlt,
  faLanguage,
  faListOl,
  faListUl,
  faMousePointer,
  faPencil,
  faQuestionCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalPosition } from 'components/Modal';
import { LegacyEditor } from 'components/item-editor/LegacyEditor';
import { InstructionalContentEditor } from 'components/item-editor/instructional-content/InstructionalContentEditor';
import { QuestionAndAnswerEditor } from 'components/item-editor/question-and-answer/QuestionAndAnswerEditor';
import { VocabularyEditor } from 'components/item-editor/vocabulary/VocabularyEditor';
import { RegionsEditor } from 'components/item-editor/regions/RegionsEditor';
import { SequenceEditor } from 'components/item-editor/sequence/SequenceEditor';
import { useItemEditorStore } from 'components/item-editor/store';
import * as Color from 'core/colors';
import { PageTitle } from 'core/layout';
import { SurveyAnswerType, TemplateType } from 'models/item';
import { formatItemTemplate } from 'utils/formatItemTemplate';
import { FlashcardEditor } from './flashcards/FlashcardEditor';
import { FreeResponseEditor } from './free_response/FreeResponseEditor';
import { RatingsEditor } from './ratings/RatingsEditor';

export const ItemEditor = () => {
  const { activeAssignment: assignment, activeItem: item, hideItemEditor } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <ModalPosition top={1.5} style={{ width: '100%', height: '95%' }}>
      <div
        id="item-editor-container"
        style={{ width: '100%', height: '100%', marginLeft: 'auto', marginRight: 'auto', maxWidth: '1120px' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: '50px',
            marginRight: '50px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <PageTitleIcon templateType={item.templateType} answerType={item.answerType} />
            <PageTitle>{formatItemTemplate(item.templateType, item.answerType)}</PageTitle>
          </div>
          <button
            onClick={hideItemEditor}
            style={{
              border: '0',
              backgroundColor: 'inherit',
            }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: '24px', color: Color.darkGray }} />
          </button>
        </div>
        {item.templateType === 'instructional_contents' ? (
          <InstructionalContentEditor />
        ) : item.templateType === 'question_and_answers' ? (
          <QuestionAndAnswerEditor />
        ) : item.templateType === 'vocabulary' ? (
          <VocabularyEditor />
        ) : item.templateType === 'sequences' ? (
          <SequenceEditor />
        ) : item.templateType === 'regions' ? (
          <RegionsEditor />
        ) : item.templateType === 'associations' ? (
          <FlashcardEditor />
        ) : item.templateType === 'free_responses' ? (
          <FreeResponseEditor />
        ) : item.templateType === 'ratings' ? (
          <RatingsEditor />
        ) : item.templateType === 'survey_question_and_answers' ? (
          // QuestionAndAnswerEditor adjusts itself appropriately if the active assignment is a survey
          <QuestionAndAnswerEditor />
        ) : (
          <LegacyEditor />
        )}
      </div>
    </ModalPosition>
  );
};

interface PageTitleIconProps {
  templateType: TemplateType;
  answerType?: SurveyAnswerType;
}

const PageTitleIcon = (props: PageTitleIconProps) => {
  const { templateType, answerType } = props;

  switch (templateType) {
    case 'instructional_contents':
      return (
        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '36px', color: Color.darkPurple, marginRight: '10px' }} />
      );
    case 'question_and_answers':
      return (
        <FontAwesomeIcon icon={faListUl} style={{ fontSize: '36px', color: Color.darkPurple, marginRight: '10px' }} />
      );
    case 'vocabulary':
      return <FontAwesomeIcon icon={faLanguage} style={{ fontSize: '36px', color: Color.pink, marginRight: '10px' }} />;
    case 'sequences':
      return (
        <FontAwesomeIcon icon={faListOl} style={{ fontSize: '36px', color: Color.darkPurple, marginRight: '10px' }} />
      );
    case 'regions':
      return (
        <FontAwesomeIcon
          icon={faMousePointer}
          style={{ fontSize: '36px', color: Color.darkPurple, marginRight: '10px' }}
        />
      );
    case 'associations':
      return (
        <FontAwesomeIcon icon={faClone} style={{ fontSize: '36px', color: Color.darkGreen, marginRight: '10px' }} />
      );
    case 'free_responses':
      return (
        <FontAwesomeIcon icon={faPencil} style={{ fontSize: '36px', color: Color.mainBlue, marginRight: '10px' }} />
      );
    case 'ratings':
      return (
        <FontAwesomeIcon icon={faChartBar} style={{ fontSize: '36px', color: Color.mainBlue, marginRight: '10px' }} />
      );
    case 'survey_question_and_answers':
      return answerType === 'single_correct' ? (
        <FontAwesomeIcon
          icon={faQuestionCircle}
          style={{ fontSize: '36px', color: Color.mainBlue, marginRight: '10px' }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faCheckSquare}
          style={{ fontSize: '36px', color: Color.mainBlue, marginRight: '10px' }}
        />
      );
    default:
      throw new Error(`Unrecognized templateType: ${templateType}`);
  }
};
