import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, ListItem, ListItemSecondaryAction, TextField } from '@mui/material';
import { Color } from 'core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUserTagTypeUpdate } from 'hooks/useDebouncedPartnerUserTagTypeUpdate';
import { useDeletePartnerUserTagType } from 'hooks/useDeletePartnerUserTagType';
import React, { useState } from 'react';
import { PartnerUserTagType } from 'types';

interface Props {
  field: PartnerUserTagType;
}

export const CustomUserField = (props: Props) => {
  const { field } = props;
  const user = useCurrentUser();
  const [userFieldInput, setUserFieldInput] = useState(field.label || '');
  const debouncedPartnerUserTagTypeUpdate = useDebouncedPartnerUserTagTypeUpdate(1000);
  const [deletePartnerUserTagType] = useDeletePartnerUserTagType();
  const [inputError, setInputError] = useState('');

  const handleDeletePartnerUserTagType = () => {
    if (
      window.confirm(
        'Are you sure you want to delete this field? WARNING: Any user data stored in this field will be removed, and any learners enrolled in a course with a smart group using this field will be unenrolled.'
      )
    ) {
      deletePartnerUserTagType({ partnerId: user?.primaryPartner.id, tagId: field.id });
    }
  };

  const handleUpdatePartnerUserTagType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setUserFieldInput(newValue);
    setInputError('');

    if (!newValue.trim()) {
      setInputError('Field name cannot be empty');
      return;
    }

    debouncedPartnerUserTagTypeUpdate({
      id: field.id,
      label: newValue.trim(),
      partner_id: user?.primaryPartner.id,
    });
  };

  return (
    <ListItem divider>
      <TextField
        label="Field Name"
        margin="normal"
        value={userFieldInput}
        onChange={handleUpdatePartnerUserTagType}
        variant="outlined"
        color="secondary"
        size="small"
        error={Boolean(inputError)}
        helperText={inputError}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={handleDeletePartnerUserTagType} size="small">
          <FontAwesomeIcon icon={faTrash} color={Color.veryDarkOrange} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
