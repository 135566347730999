import { BrightInput } from 'components/forms/BrightInput';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ModalPosition } from 'components/Modal';
import { ModalHeader } from 'components/modals/common';
import { Color } from 'core';
import { Card, PageTitle } from 'core/layout';
import { API_DATA_SUCCESS } from 'middleware/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleGetProfile } from 'store/profile';
import { handleAddUserEmail } from 'store/user-email';
import styled from 'styled-components';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const ErrorMessage = styled.h4`
  color: ${Color.red};
  font-size: 14px;
  margin: 0;
  padding-top: 0;
`;

interface ICourseCreate {
  close: () => void;
}

export const AddUserEmailModal = React.memo<ICourseCreate>((props) => {
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState('');
  const [emailFailureMessage, setEmailFailureMessage] = useState('');
  const { t } = useTranslation();

  async function addEmail() {
    const actionResponse: any = await dispatch(handleAddUserEmail(emailAddress));

    if (actionResponse.type === API_DATA_SUCCESS) {
      await dispatch(handleGetProfile());
      props.close();
    } else {
      const errorMessage = actionResponse.error.data.response;
      setEmailFailureMessage(errorMessage.substring('Validation failed: '.length));
    }
  }

  return (
    <ModalPosition top={1.5}>
      <ModalHeader id="header">
        <CenterTitle>{t('Add an Email')}</CenterTitle>
      </ModalHeader>
      <Card>
        <div>
          <BrightInput
            placeholder={t('email address')}
            autoFocus={true}
            onChange={(e) => setEmailAddress(e.target.value)}
            type="email"
            aria-labelledby="header"
          />
        </div>
        {emailFailureMessage && <ErrorMessage>{emailFailureMessage}</ErrorMessage>}
      </Card>

      <ConfirmOrCancel
        cancelText={t('Cancel')}
        confirmText={t('Create')}
        handleCancelClick={props.close}
        handleConfirmClick={addEmail}
        confirmIsDisabled={!emailAddress}
        confirmIsPending={false}
      />
    </ModalPosition>
  );
});
