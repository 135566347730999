import { API_DATA_SUCCESS, CALL_API } from 'middleware/api';
export const REMOVE_SET = Symbol('Remove Set');

export interface ISetData {
  data: object;
}

export const handleGetSet = (id: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/sets/${id}?include=image,creator.logo-image`,
      httpMethod: 'GET'
    }
  };
};

export const updateSet = (setData: ISetData, setId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/sets/${setId}`,
      httpMethod: 'PUT',
      data: setData
    }
  };
};

export const deleteSet = (setId: string) => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/sets/${setId}`,
      httpMethod: 'DELETE'
    }
  };
};

export const handleDeleteSet = (setId: string) => {
  return async dispatch => {
    const actionResponse = await dispatch(deleteSet(setId));

    if (actionResponse.type === API_DATA_SUCCESS) {
      dispatch({ type: REMOVE_SET, setId });
    }

    if (actionResponse.error) {
      throw new Error('Failed to delete Set!');
    }
    return actionResponse;
  };
};

export const handleGetMySets = () => {
  return {
    [CALL_API]: {
      endpoint: `/api/v3/my/authored_sets?include=image`,
      httpMethod: 'GET'
    }
  };
};

export const removeSet = (state, action) => {
  const { setId } = action;

  const sets = Object.assign({}, state.sets);
  delete sets[setId];

  return {
    ...state,
    sets
  };
};
