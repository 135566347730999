import { Box, FormControlLabel, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { MemreFlexBox } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDebouncedPartnerUpdate } from 'hooks/useDebouncedPartnerUpdate';
import { useMutatePartnerLibrary } from 'hooks/useMutatePartnerLibrary';
import { debounce } from 'lodash';
import { IImage } from 'models/image';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createImage } from 'utils/createImage';
import useStore from 'zstore';

export const CourseLibrary: React.FC = () => {
  const user = useCurrentUser();
  const [isCourseLibraryEnabled, setIsCourseLibraryEnabled] = useState(
    user?.primaryPartner?.partnerSettings?.libraryEnabled || false
  );
  const [courseLibraryName, setCourseLibraryName] = useState(user?.primaryPartner?.partnerLibrary?.name || '');
  const [libraryBackground, setLibraryBackground] = useState<IImage | null>(
    user?.primaryPartner?.partnerLibrary?.image || null
  );
  const debouncedPartnerUpdate = useDebouncedPartnerUpdate(1000);
  const [mutatePartnerLibrary] = useMutatePartnerLibrary();
  const { makeToast } = useStore();

  // Refs to hold mutable values that don't trigger re-renders
  const userIdRef = useRef(user?.primaryPartner?.id);
  const mutatePartnerLibraryRef = useRef(mutatePartnerLibrary);

  // Update refs when dependencies change
  useEffect(() => {
    userIdRef.current = user?.primaryPartner?.id;
    mutatePartnerLibraryRef.current = mutatePartnerLibrary;
  }, [user?.primaryPartner?.id, mutatePartnerLibrary]);

  // Define the debounced function using useCallback and useRef
  const debouncedPartnerLibraryUpdate = useCallback(
    debounce(async (payload: any) => {
      try {
        await mutatePartnerLibraryRef.current({
          id: userIdRef.current,
          payload,
        });
      } catch (error: any) {
        console.error(error);
        makeToast({
          text: 'Uh-oh! Something went wrong. Please try again.',
          isError: true,
        });
      }
    }, 1000),
    [] // Empty dependency array ensures this function is created only once
  );

  // Cancel debounce on unmount
  useEffect(() => {
    return () => {
      debouncedPartnerLibraryUpdate.cancel();
    };
  }, [debouncedPartnerLibraryUpdate]);

  const handleLibraryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCourseLibraryName(e.target.value);
    debouncedPartnerLibraryUpdate({
      data: {
        attributes: {
          name: e.target.value,
        },
      },
    });
  };

  const handleCourseLibraryToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCourseLibraryEnabled(e.target.checked);
    debouncedPartnerUpdate({
      partner_settings: {
        library_enabled: e.target.checked,
      },
    });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleLibraryBackgroundUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    try {
      if (file) {
        const image = await createImage(file);
        setLibraryBackground(image);
        debouncedPartnerLibraryUpdate({
          data: {
            relationships: {
              image: {
                data: {
                  id: image.id,
                },
              },
            },
          },
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  // Trigger file input click
  const handleBackgroundClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <ConfigItem title="Course Library Settings">
      <FormControlLabel
        control={<Switch checked={isCourseLibraryEnabled} onChange={handleCourseLibraryToggle} color="secondary" />}
        label="Enable Course Library"
      />
      {isCourseLibraryEnabled && (
        <>
          <MemreFlexBox gap={2} direction="row" align="center" justify="center">
            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
              <Tooltip title="Click to upload background image" arrow>
                <Box
                  onClick={handleBackgroundClick}
                  sx={{
                    cursor: 'pointer',
                    width: 120,
                    height: 120,
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 1,
                    border: '1px solid #ccc',
                    '&:hover .hoverOverlay': {
                      opacity: 1,
                    },
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLibraryBackgroundUpload}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  {libraryBackground ? (
                    <img
                      src={libraryBackground.url}
                      alt="Background Preview"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="subtitle2" color="textSecondary">
                        No Background
                      </Typography>
                    </Box>
                  )}
                  {/* Hover overlay */}
                  <Box
                    className="hoverOverlay"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.4)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      paddingLeft: '0.7rem',
                      paddingRight: '0.7rem',
                    }}
                  >
                    <Typography variant="subtitle1">Change Background</Typography>
                  </Box>
                </Box>
              </Tooltip>
            </Box>
            <TextField
              label="Name of Library"
              fullWidth
              margin="normal"
              value={courseLibraryName}
              onChange={handleLibraryNameChange}
              sx={{ mt: 2 }}
            />
          </MemreFlexBox>
        </>
      )}
    </ConfigItem>
  );
};
