import { usePaginatedAssignments } from 'hooks/usePaginatedAssignments';
import { usePaginatedMeta } from 'hooks/usePaginatedMeta';
import { CourseAssignmentsTableState, useCourseAssignmentsTableStore } from 'pages/course-detail/store';
import { useEffect, useState } from 'react';
import getAssignmentStatus from 'utils/getAssignmentStatus';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

type Signature = {
  isLoading: boolean;
  data: Array<any>;
  totalCount: number | null;
  totalPages: number | null;
};

export const useCourseAssignmentsTableData = (courseId: string, searchTerm: string): Signature => {
  const [data, setData] = useState<any>([]);
  const pageSize = useCourseAssignmentsTableStore((state: CourseAssignmentsTableState) => state.pageSize);
  const pageNumber = useCourseAssignmentsTableStore((state: CourseAssignmentsTableState) => state.pageNumber);

  const { data: response, isLoading } = usePaginatedAssignments(courseId, pageNumber, pageSize, searchTerm);

  const { totalCount, totalPages } = usePaginatedMeta(response);

  useEffect(() => {
    if (!!response) {
      const normalizedData = normalizeJSONAPIResponse(response.data);
      const assignmentsWithStatus = normalizedData.map((assignment: any) => ({
        ...assignment,
        status: getAssignmentStatus(assignment, courseId),
      }));
      setData(assignmentsWithStatus);
    }
  }, [JSON.stringify(response)]);

  return { isLoading, data, totalCount, totalPages };
};
