import emptyDashboard from 'assets/images/empty-dashboard.svg';
import { MemreFlexBox, MemreText } from 'components/core';
import * as Color from 'core/colors';
import { useTranslation } from 'react-i18next';

export default function MemreNoResults() {
  const { t } = useTranslation();

  return (
    <MemreFlexBox direction="column" align="center" justify="center">
      <img src={emptyDashboard} alt="No Results" style={{ width: '160px', height: '160px' }} />
      <MemreText sx={{ color: Color.darkGray, mb: 2, mt: -2 }}>{t('No results found.')}</MemreText>
    </MemreFlexBox>
  );
}
