import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

export const DEFAULT_PAGE_SIZE = 200;

const getTagTypes = async (_key: string, partnerId: string, pageSize: number = DEFAULT_PAGE_SIZE) => {
  const endpoint: string = `/api/v3/partners/${partnerId}/partner_user_tag_types?page[size]=${pageSize}`;
  const response = await axios.get(endpoint);

  return normalizeJSONAPIResponse(response.data);
};

export const usePartnerUserTagTypes = () => {
  const user = useCurrentUser();
  return useQuery(['partnerUserTagTypes', user?.primaryPartner.id], getTagTypes, { enabled: user?.primaryPartner.id });
};
