import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { useItemEditorStore } from 'components/item-editor/store';
import { useMutateConcept } from 'hooks/useMutateConcept';
import { useRef } from 'react';
import styled from 'styled-components';
import { TConcept, TSound } from 'types';
import { createSound } from 'utils/createSound';
import useStore from 'zstore';

interface ConceptSoundProps {
  concept: TConcept;
}

export const ConceptSound = (props: ConceptSoundProps) => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const { concept } = props;
  const sound = concept.sound;
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { makeToast } = useStore();

  const handleSoundClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function showErrorToast() {
    makeToast({
      text: 'Uh-oh! Something went wrong. Please try again.',
      isError: true,
    });
  }
  async function updateSound(sound: TSound | null) {
    if (!item) {
      console.warn('Item not found');
      return;
    }
    try {
      // update app state
      const updatedFacets = item.facets.map((facet) => {
        const isAnchor = facet.anchor?.id === concept.id;
        const isAssociation = facet.association?.id === concept.id;
        if (!isAnchor && !isAssociation) {
          return facet;
        }
        const relationship = isAnchor ? 'anchor' : 'association';
        return {
          ...facet,
          [relationship]: {
            ...facet[relationship],
            sound,
          },
        };
      });
      updateActiveItem({
        facets: updatedFacets,
      });

      // update the sound in the database
      await mutateConcept({
        id: concept.id,
        payload: {
          data: {
            relationships: {
              sound: {
                data: {
                  id: sound?.id,
                },
              },
            },
          },
        },
      });
    } catch (error: any) {
      // TODO: roll back optimistic update
      console.error(error);
      showErrorToast();
    }
  }

  const handleSoundUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.[0];

    try {
      if (file) {
        const sound = await createSound(file);
        updateSound(sound);
      }
    } catch (error: any) {
      console.error(error);
      showErrorToast();
    }
  };

  const handleDeleteSound = async () => {
    updateSound(null);
  };

  if (!sound) {
    return (
      <>
        <IconButton onClick={handleSoundClick}>
          <VolumeUpIcon />
        </IconButton>
        <FileInput ref={fileInputRef} type="file" accept="audio/*" onChange={handleSoundUpload} />
      </>
    );
  }

  return (
    <div>
      <audio controls>
        <source src={sound.url} type={sound.mimeType || 'audio/mpeg'} />
        Your browser does not support the audio element.
      </audio>
      <IconButton onClick={handleDeleteSound}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

const FileInput = styled.input`
  display: none;
`;
