import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMutateUser } from 'hooks/useMutateUser';
import React, { useState } from 'react';
import styled from 'styled-components';

interface ChangePasswordModalProps {
  userId: string;
  partnerId: string;
  onClose: () => void;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ userId, partnerId, onClose }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [mutateUser] = useMutateUser();
  const user = useCurrentUser();

  const isFormValid = password.length >= user?.primaryPartner.minPasswordLength && password === passwordConfirmation;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isFormValid) {
      return;
    }

    try {
      await mutateUser({
        id: userId,
        partner_id: parseInt(partnerId),
        password: password,
        password_confirmation: passwordConfirmation,
      });
      onClose();
    } catch (error) {
      console.error('Error changing password:', error);
      setErrorMessage('Failed to change password.');
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Change Password</h2>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <label>New Password:</label>
            <Input
              type="password"
              value={password}
              onChange={(e) => {
                const value = e.target.value;
                setPassword(value);
                setErrorMessage('');

                if (value.length < user?.primaryPartner.minPasswordLength) {
                  setPasswordError(
                    `Password must be at least ${user?.primaryPartner.minPasswordLength} characters long.`
                  );
                } else {
                  setPasswordError('');
                }

                if (passwordConfirmation && value !== passwordConfirmation) {
                  setPasswordConfirmationError('Passwords do not match.');
                } else {
                  setPasswordConfirmationError('');
                }
              }}
              required
              hasError={!!passwordError}
            />
            {passwordError && <ErrorText>{passwordError}</ErrorText>}
          </FormGroup>
          <FormGroup>
            <label>Confirm Password:</label>
            <Input
              type="password"
              value={passwordConfirmation}
              onChange={(e) => {
                const value = e.target.value;
                setPasswordConfirmation(value);
                setErrorMessage('');

                if (value !== password) {
                  setPasswordConfirmationError('Passwords do not match.');
                } else {
                  setPasswordConfirmationError('');
                }
              }}
              required
              hasError={!!passwordConfirmationError}
            />
            {passwordConfirmationError && <ErrorText>{passwordConfirmationError}</ErrorText>}
          </FormGroup>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          <ButtonGroup>
            <SubmitButton type="submit" disabled={!isFormValid}>
              Submit
            </SubmitButton>
            <CancelButton type="button" onClick={onClose}>
              Cancel
            </CancelButton>
          </ButtonGroup>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

// Styled components for the modal and form elements
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2em;
  border-radius: 0.25em;
  max-width: 500px;
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: 1em;
  label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
  }
`;

const Input = styled.input<{ hasError: boolean }>`
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  border: 1px solid ${(props) => (props.hasError ? 'red' : '#ccc')};
  border-radius: 4px;
  &:focus {
    border-color: ${(props) => (props.hasError ? 'red' : '#666')};
    outline: none;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0.5em;
  font-size: 0.9em;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5em;
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
  padding: 0.5em 1em;
  margin-right: 1em;
  background-color: ${(props) => (props.disabled ? '#ccc' : '#007bff')};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const CancelButton = styled.button`
  padding: 0.5em 1em;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
