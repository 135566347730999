import React from 'react';
import { Link } from 'react-router-dom';

interface Section {
  title: string;
  links: SectionLink[];
}

interface SectionLink {
  name: string;
  url?: string;
  fullURL?: string;
}

export function SiteAdminPage() {
  const sections: Section[] = [
    {
      title: 'Support tools',
      links: [{ name: 'User search', url: 'users' }],
    },
    {
      title: 'Sales tools',
      links: [
        { name: 'Partner Creation', url: 'partners' },
        { name: 'Cloning Laboratory', url: 'cloning-lab' },
      ],
    },
    {
      title: 'Engineering tools',
      links: [
        { name: 'Sidekiq Dashboard', fullURL: '/admin/sidekiq' },
        { name: 'LTI Launcher', url: 'lti-launcher' },
      ],
    },
  ];

  return (
    <div style={styles.container}>
      {sections.map((section, index) => (
        <div key={index} style={styles.section}>
          <h2 style={styles.title}>{section.title}</h2>
          <ul style={styles.list}>
            {section.links.map((link, idx) => (
              <li key={idx}>
                {link.fullURL ? (
                  <a href={link.fullURL} style={styles.link}>
                    {link.name}
                  </a>
                ) : (
                  <Link to={`/nav/v4/site-admin/${link.url}`} style={styles.link}>
                    {link.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '20px',
    color: '#333',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
  },
  link: {
    textDecoration: 'none',
    color: 'green',
    fontSize: '16px',
    display: 'block',
    margin: '5px 0',
  },
};
