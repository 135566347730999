import ImageView, { TranscodingSize } from 'components/ImageView';
import { ImageModal } from 'learn/components/ImageModal';
import { IImage } from 'models/image';
import * as React from 'react';
import styled from 'styled-components';

type ZoomableImageContainerProps = {
  image: IImage;
  transcodedSize: TranscodingSize;
};

type OwnState = {
  isModalOpen?: boolean;
};

const ZoomButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  marginbottom: 10;
`;

export default class ZoomableImageContainer extends React.Component<ZoomableImageContainerProps, OwnState> {
  constructor(props) {
    super(props);
    this.state = { isModalOpen: false };
  }

  public render() {
    const { image, transcodedSize } = this.props;
    return (
      <ZoomButton onClick={this.imageClicked} aria-label="Zoom Image">
        <ImageView image={image} transcodedSize={transcodedSize} />
        <ImageModal image={image} open={this.state.isModalOpen} onClose={this.closeModalClicked} />
      </ZoomButton>
    );
  }

  private imageClicked = (e: any) => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  private closeModalClicked = () => {
    this.setState({ isModalOpen: false });
  };
}
