import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

export type UpdatePartnerUserTagTypePayload = {
  id: string;
  label: string;
  partner_id: number;
};

const updatePartnerUserTagType = (payload: UpdatePartnerUserTagTypePayload) => {
  return axios.put(`/api/v3/partners/${payload.partner_id}/partner_user_tag_types/${payload.id}`, payload);
};

export const useMutatePartnerUserTagType = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();

  return useMutation(updatePartnerUserTagType, {
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['partnerUserTagTypes', user?.primaryPartner.id]);
    },
  });
};
