import axios from 'axios';
import { queryCache, useMutation } from 'react-query';

const createAssignment = ({ courseId, payload }) => {
  return axios.post(`/api/v3/courses/${courseId}/sets`, payload);
};

export const useCreateAssignment = (courseId: string = '') => {
  const staleQueryKey: Array<string> | string = courseId ? ['assignments', courseId] : 'assignments';

  return useMutation(createAssignment, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
    },
    onSettled: () => {
      queryCache.invalidateQueries(staleQueryKey);
    },
  });
};
