import learn from 'learn/store/learn';
import { combineReducers } from 'redux';
import data from 'store/data';
import error from 'store/error';

const rootReducer = combineReducers({
  data,
  error,
  learn
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
