import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { performActionWithConfirmation } from './siteAdminUtils';
import { sleep } from 'utils/timeUtils';

export function SiteAdminCloningLab() {
  const [setCloneParams, setSetCloneParams] = useState({
    setCloneSourceId: '',
    setCloneCourseId: '',
    setCloneTargetId: '',
  });
  const [cloneWaiting, setCloneWaiting] = useState(false);
  const [cloneMessage, setCloneMessage] = useState('');

  const getIds = (idString) => idString.split(/[\s,]+/);

  const resetSetCloneParams = () => {
    setSetCloneParams({
      setCloneSourceId: '',
      setCloneCourseId: '',
      setCloneTargetId: '',
    });
  };

  const onCloneSetSuccess = async (jobId, sourceSetId, courseId) => {
    try {
      const response = await axios.get(`/api/v3/set_clones/${jobId}`);
      const data = response.data;
      if (data.status === 'completed') {
        const setCloneOutputPath = `/app/nav/courses/${courseId}/sets/${data.target_set_id}`;
        setCloneMessage(
          (prev) =>
            prev +
            `<div>The clone of ${sourceSetId} is ready: <a href="${setCloneOutputPath}" target="_blank">Clone of ${sourceSetId}</a></div>`
        );
      } else {
        await sleep(2000);
        await onCloneSetSuccess(jobId, sourceSetId, courseId);
      }
    } catch (error: any) {
      setCloneMessage((prev) => prev + `<div>Failed to clone ${sourceSetId}: ${error.message}</div>`);
    }
  };

  const cloneSets = async (event) => {
    event.preventDefault();
    setCloneWaiting(true);
    setCloneMessage('');

    const setIds = getIds(setCloneParams.setCloneSourceId);

    if (setIds.length > 1 && setCloneParams.setCloneTargetId) {
      alert("You can't specify multiple set ids along with a target set id.");
      setCloneWaiting(false);
      return;
    }

    const promises = setIds.map(async (setId) => {
      const courseId = setCloneParams.setCloneCourseId;
      const params = {
        set_id: setId,
        data: {
          attributes: {
            course_id: courseId,
            destination_set_id: setCloneParams.setCloneTargetId || null,
          },
        },
      };

      try {
        const result: any = await axios.post(`/api/v3/sets/${setId}/set_clones`, params);

        const jobId = result.data.id;
        await onCloneSetSuccess(jobId, setId, courseId);
      } catch (error: any) {
        setCloneMessage((prev) => prev + `<div>Failed to clone ${setId}: ${error.message}</div>`);
      }
    });

    await Promise.all(promises);

    setCloneWaiting(false);
    setCloneMessage((prev) => prev + '<div>ALL DONE!</div>');
    resetSetCloneParams();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSetCloneParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isCloneDisabled = !setCloneParams.setCloneSourceId || !setCloneParams.setCloneCourseId;

  return (
    <div style={{ margin: '20px' }}>
      <h1>Cerego Cloning Laboratory</h1>

      <p>
        Cloning a set or item creates an entirely new copy of the item, with no links to the previous item. Memory
        permanence will not transfer.
      </p>

      <h2>Set Clone</h2>
      <form onSubmit={cloneSets}>
        <div>
          <input
            name="setCloneSourceId"
            placeholder="Set id(s) to clone - REQUIRED"
            value={setCloneParams.setCloneSourceId}
            onChange={handleInputChange}
          />{' '}
          Set id(s) to clone - REQUIRED
        </div>
        <div>
          <input
            name="setCloneCourseId"
            type="number"
            placeholder="Course id to add cloned set to - REQUIRED"
            value={setCloneParams.setCloneCourseId}
            onChange={handleInputChange}
          />{' '}
          Course id to add cloned set to - REQUIRED
        </div>
        {/*
          Disable this functionality unless really needed
          <div>
          <input
            name="setCloneTargetId"
            type="number"
            placeholder="Set target id (optional)"
            value={setCloneParams.setCloneTargetId}
            onChange={handleInputChange}
          />{' '}
          Set target id (optional)
        </div> */}
        <button type="submit" disabled={isCloneDisabled}>
          Clone
        </button>
        {cloneWaiting && <div>Cloning...</div>}
        {cloneMessage && <div dangerouslySetInnerHTML={{ __html: cloneMessage }} />}
      </form>
    </div>
  );
}
