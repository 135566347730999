// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBwQiGA9a8OZjtj2kGO0UjAPd-aPVQv-DQ',
  authDomain: 'ceregolearn.firebaseapp.com',
  databaseURL: 'https://ceregolearn.firebaseio.com',
  projectId: 'ceregolearn',
  storageBucket: 'ceregolearn.appspot.com',
  messagingSenderId: '641098823401',
  appId: '1:641098823401:web:d9508b9df52e7bf251a278',
  measurementId: 'G-GXNW4H1MYJ',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
