import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import emptyDashboard from 'assets/images/empty-dashboard.svg';
import { Pagination } from 'components/reports/controls/Pagination';
import { Container } from 'components/tables/learner-stats/Container';
import { DataContainer, DataTable } from 'components/tables/learner-stats/elements';
import { TableBody } from 'components/tables/TableBody';
import { TableHeader } from 'components/tables/TableHeader';
import { useUserMetrics } from 'hooks/useUserMetrics';
import { Searchbar } from 'pages/admin-dashboard/Searchbar';
import { useState } from 'react';
import styled from 'styled-components';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { useAdminDashStore } from 'zstore';

export const UserMetricsTable = () => {
  const { page, setPage, query } = useAdminDashStore();
  const pageSize = 10;
  const [sortBy, setSortBy] = useState('-diligence');
  const [selectedMetric, setSelectedMetric] = useState('diligence');
  const { data: rawUserMetrics } = useUserMetrics({
    pageNumber: page,
    pageSize,
    sortBy,
    metric: selectedMetric,
    query,
  });
  const userMetrics = normalizeJSONAPIResponse(rawUserMetrics?.data);
  const userMetricsMeta = rawUserMetrics?.data.meta;
  const totalCount = !!userMetricsMeta ? userMetricsMeta['total-count'] : null;

  const renderHeader = (title: string, rightAlign: boolean = false) => {
    if (title === 'Learner') {
      return (
        <TableHeaderContainer style={{ justifyContent: rightAlign ? 'right' : 'left' }}>
          <TableHeaderText>{title}</TableHeaderText>
          {sortBy.includes('name') && sortBy.indexOf('-') > -1 && <SortArrow icon={faArrowDown} />}
          {sortBy.includes('name') && sortBy.indexOf('-') === -1 && <SortArrow icon={faArrowUp} />}
        </TableHeaderContainer>
      );
    }

    return (
      <TableHeaderContainer style={{ justifyContent: rightAlign ? 'right' : 'left' }}>
        <TableHeaderText>{title}</TableHeaderText>
        {title !== '' && sortBy.includes(title) && sortBy.indexOf('-') > -1 && <SortArrow icon={faArrowDown} />}
        {title !== '' && sortBy.includes(title) && sortBy.indexOf('-') === -1 && <SortArrow icon={faArrowUp} />}
      </TableHeaderContainer>
    );
  };

  const renderRank = (row: any) => {
    const rank = row.row.index + 1;
    return <Typography>{rank}</Typography>;
  };

  const renderLearner = (row: any) => {
    const learnerName = row.row.original.user.name;
    return <Typography>{learnerName}</Typography>;
  };

  const renderMetric = (row: any) => {
    const displayMetric =
      Object.keys(row.row.original.percentiles).length > 0
        ? row.row.original.percentiles[`${selectedMetric}Percentile`]
        : row.row.original[selectedMetric];

    if (!displayMetric) {
      return null;
    }

    return <Typography>{displayMetric.toFixed(2)}</Typography>;
  };

  const columns: ColumnDef<any>[] = [
    {
      id: 'rank',
      accessorFn: (row: any) => row.name,
      cell: (row: any) => renderRank(row),
      header: () => renderHeader('Rank'),
    },
    {
      id: 'learner name',
      accessorFn: (row: any) => row.name,
      cell: (row: any) => renderLearner(row),
      header: () => renderHeader('Learner'),
    },
    {
      id: 'metric',
      accessorFn: (row: any) => row.meta.lastStudyTime,
      cell: (row: any) => renderMetric(row),
      header: () => renderHeader(selectedMetric, true),
    },
  ];

  const table = useReactTable({
    data: userMetrics,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const handleHeaderClick = (e: any): void => {
    const target = e.target.innerText.toLowerCase();
    if (target === 'rank') {
      return;
    }
    if (target === 'learner') {
      setSortBy(sortBy === 'name' ? '-name' : 'name');
    }
    if (target === selectedMetric) {
      setSortBy(sortBy === selectedMetric ? `-${selectedMetric}` : selectedMetric);
    }
  };

  const goToPageOne = () => {
    setPage(1);
  };

  const goToNextPage = () => {
    if (!userMetricsMeta) {
      console.warn('userMetricsMeta is not defined');
      return;
    }

    if (page < userMetricsMeta['total-pages']) {
      setPage(page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToLastPage = () => {
    if (!userMetricsMeta) {
      console.warn('userMetricsMeta is not defined');
      return;
    }

    setPage(userMetricsMeta['total-pages']);
  };

  const rightAlignedColumns = ['diligence', 'agility', 'knowledge', 'metric'];
  const clickableHeaders = ['rank', 'learner name', 'metric', 'diligence', 'agility', 'knowledge'];

  return (
    <Container
      toolbar={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            paddingLeft: '25px',
            paddingRight: '25px',
            paddingTop: '10px',
          }}
        >
          <Searchbar />
          <Select
            labelId="metric-select-label"
            id="metric-select"
            value={selectedMetric}
            onChange={(e) => {
              setSelectedMetric(e.target.value as string);
              setSortBy(`-${e.target.value}`);
            }}
          >
            <MenuItem value={'diligence'}>Diligence</MenuItem>
            <MenuItem value={'agility'}>Agility</MenuItem>
            <MenuItem value={'knowledge'}>Knowledge</MenuItem>
          </Select>
        </div>
      }
      data={
        <DataContainer>
          {!userMetrics ||
            (userMetrics.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  paddingLeft: '25px',
                  paddingRight: '25px',
                }}
              >
                <img src={emptyDashboard} alt="Empty Dashboard" style={{ width: '30%', height: '30%' }} />
              </div>
            ))}
          {userMetrics?.length > 0 && (
            <>
              <DataTable>
                <TableHeader table={table} handleHeaderClick={handleHeaderClick} clickableHeaders={clickableHeaders} />
                <TableBody
                  table={table}
                  rightAlignedColumnIds={rightAlignedColumns}
                  extraPadding={true}
                  onRowClick={() => { }}
                />
              </DataTable>

              <Pagination
                totalCount={totalCount}
                page={page}
                pageSize={pageSize}
                goToPageOne={goToPageOne}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
              />
            </>
          )}
        </DataContainer>
      }
    />
  );
};

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.54);
`;

const SortArrow = styled(FontAwesomeIcon)`
  height: 0.75rem;
  color: rgb(0, 0, 0, 0.54);
`;
