import { MemreContainer, MemreFlexBox, MemrePeopleList } from 'components/core';
import { MemreHistogram } from 'components/visualisations';
import * as Color from 'core/colors';
import _ from 'lodash';
import getMedian from 'utils/getMedian';
import AreaHeading from '../learner-dashboard/visulisations/AreaHeading';
import LearnMore from '../learner-dashboard/visulisations/LearnMore';

const OVERLINE = {
  STUDENTS: 'Average Time Since Items Studied',
  SETS: 'Average Time Since Last Study Session',
};

export default function SinceLastStudy({ data, isStudentsView }) {
  const sinceLastStudy = data.map((d) => d.sinceLastStudy);
  const denomination = data[0]?.lastStudyDenom;
  const median = getMedian(sinceLastStudy);

  const overline = isStudentsView ? OVERLINE.STUDENTS : OVERLINE.SETS;
  const heading = `${median} ${denomination}`;

  const sortedData = _.sortBy(data, (d) => d.sinceLastStudy);

  return (
    <MemreContainer
      size="large"
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      sx={{
        borderRight: `1px solid ${Color.veryLightGray}`,
      }}
    >
      <MemreFlexBox direction="column" gap={2} sx={{ flexGrow: 1 }}>
        <MemreFlexBox justify="space-between" align="center">
          <AreaHeading overline={overline} heading={heading} />
          <LearnMore width={500}>
            <AreaHeading overline={overline} heading={heading} />
            <MemrePeopleList
              data={sortedData}
              denomination={denomination}
              fields={['sinceLastStudy']}
              fieldNames={['Time']}
            />
          </LearnMore>
        </MemreFlexBox>
        <MemreContainer sx={{ gap: 2, pr: '5px', justifyContent: 'space-between' }}>
          <MemreHistogram data={sinceLastStudy} />
        </MemreContainer>
      </MemreFlexBox>
    </MemreContainer>
  );
}
