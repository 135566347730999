import { faArchive, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import learners from 'assets/images/learners.svg';
import publish from 'assets/images/publish.svg';
import axios from 'axios';
import { CourseLearnersTable } from 'components/CourseLearnersTable';
import { DropDown, DropDownList, DropDownListItem } from 'components/DropDown';
import { ImageUploaderModal } from 'components/ImageUploaderModal';
import { KnowledgeBankLink } from 'components/KnowledgeBankLink';
import { ManageInstructorsModal } from 'components/ManageInstructorsModal';
import { Modal } from 'components/Modal';
import TableFrame, { ArticleIcon } from 'components/TableFrame';
import { AvatarButton, MenuButton } from 'components/buttons';
import { DeleteButton, PrimaryButton } from 'components/buttons/v4';
import { BulkUpload } from 'components/modals/BulkUpload';
import { CreateNewSet } from 'components/modals/CreateNewSet';
import { DeleteCourse } from 'components/modals/DeleteCourse';
import { LibraryCourse } from 'components/modals/LibraryCourse';
import { PublishCourse } from 'components/modals/PublishCourse';
import { SearchSharedContent } from 'components/modals/SearchSharedContent';
import { Color } from 'core';
import { ContentSection, Masthead, PageContainer, TitlePrefix, TitleSection } from 'core/layout';
import { AddLearnersByEmail } from 'courses/components/AddLearnersByEmail';
import { AddLearnersByGroup } from 'courses/components/AddLearnersByGroup';
import BuildingCourse from 'courses/components/BuildingCourse';
import { EditableText } from 'courses/components/EditableText';
import { RemoveLearners } from 'courses/components/RemoveLearners';
import { useAssignments } from 'hooks/useAssignments';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useIsInPartner } from 'hooks/useIsInPartner';
import { CourseAssignmentsTable } from 'pages/course-detail/CourseAssignmentsTable';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { queryCache } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { handleGetCourse, updateCourse } from 'store/course';
import { getLearnerStatsEndpoint, handleGetLearnerStats, selectLearnerStats } from 'store/learner-stats';
import styled from 'styled-components';
import { autohide } from 'utils/animations';
import { PARTNER_IDS } from 'utils/constants';
import { getErrorStringFromAPIError } from 'utils/errorUtils';
import { copyToClipboard } from 'utils/navigatorUtils';
import { getBaseAngularUrl, getBaseReactUrl } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';
import { getRole } from 'utils/userManager';
import useStore from 'zstore';
import { UploadSCORM } from 'pages/course-detail/UploadSCORM';

interface ICoursesPageProps {
  match: { params: { courseId: string } };
}

export const CourseDetailPage = (props: ICoursesPageProps) => {
  const { courseId } = props.match.params;
  const dispatch = useDispatch();
  const course = useSelector(getCourse) || {};
  const learnerStats = useSelector(getLearnerStats) || [];
  const instructors = useSelector(getInstructors) || {};
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [showCourseImageUploadModal, setShowCourseImageUploadModal] = useState(false);
  const [showSharedContentModal, setShowSharedContentModal] = useState(false);
  const [slug, setSlug] = useState('');
  const [newGoalType, setNewGoalType] = useState('set');
  const [showAddContent, setShowAddContent] = useState(false);
  const [showPublishMenu, setShowPublishMenu] = useState(false);
  const [showCreateNewSet, setShowCreateNewSet] = useState(false);
  const [showDeleteCourse, setShowDeleteCourse] = useState(false);
  const [showLibraryCourse, setShowLibraryCourse] = useState(false);
  const [showPublishCourse, setShowPublishCourse] = useState(false);
  const [showAddLearnersDropdown, setShowAddLearnersDropdown] = useState(false);
  const [showAddLearnersByEmail, setShowAddLearnersByEmail] = useState(false);
  const [showAddLearnersByGroup, setShowAddLearnersByGroup] = useState(false);
  const [showRemoveLearners, setShowRemoveLearners] = useState(false);
  const [showManageInstructorsModal, setShowManageInstructorsModal] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [showNotPublishableAlert, setShowNotPublishableAlert] = useState(false);
  const [showUploadSCORM, setShowUploadSCORM] = useState(false);
  const { t } = useTranslation();
  const learnerStatsLoading = useSelector(getLearnerStatsLoading);
  const ceregoImportFile: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const { data: assignments } = useAssignments(courseId);

  const user = useCurrentUser();

  // Permissions Related Values
  const role = getRole(user);
  const courseRole = course?.meta?.role;
  const canCreateCourse = user?.primaryPartner?.meta.canCreateCourse;
  const isCourseInstructor = courseRole === 'instructor';
  const isContentManager = role === 'content manager';
  const isCourseViewer = role === 'course viewer';
  const canEditContent = course?.meta?.canEdit ?? false;
  const isQLearn = useIsInPartner(PARTNER_IDS.QLEARN);

  const disableUiForInvitingUsers = course?.partner?.partnerSettings?.disableUiForInvitingUsers ?? false;
  const currentUserHasCourseUserEditCapability = canEditContent && !isContentManager;

  const canEditLearnersAndInstructors = currentUserHasCourseUserEditCapability && !disableUiForInvitingUsers;
  const canViewLearners = currentUserHasCourseUserEditCapability || isCourseViewer || isCourseInstructor;
  const canPublishCourse = currentUserHasCourseUserEditCapability;

  const { makeToast, selectCourse } = useStore();

  // Maintain active courseId in zstore
  useEffect(() => {
    selectCourse(courseId);
  }, [courseId]);

  function getLearnerStatsLoading(state) {
    return state.data.meta[getLearnerStatsEndpoint(courseId)]?.loading;
  }

  function handleSlugChange(e) {
    setSlug(e.target.value);
  }

  function saveSlug() {
    dispatch(updateCourse({ slug }, courseId));
  }

  function getLearnerStats(state) {
    return selectLearnerStats(state, courseId);
  }

  function getCourse(state) {
    return build(state.data, 'courses')?.find((course) => course.id === courseId);
  }

  function getInstructors() {
    return course.instructors;
  }

  function handleDescriptionChange(e) {
    AnalyticsService.getInstance().track('course_edited', { edit_category: 'Course description' });
    setDescription(e.target.value);
  }

  function reloadAssignments() {
    queryCache.invalidateQueries(['assignments', courseId]);
  }

  function handleNameChange(e) {
    setName(e.target.value);
  }

  useEffect(() => {
    const containerNode = document.getElementById('navbar-container');
    containerNode?.scrollIntoView();
  }, []);

  useEffect(() => {
    dispatch(handleGetLearnerStats(courseId));
    dispatch(handleGetCourse(courseId));
  }, [courseId, dispatch]);

  useEffect(() => {
    setName(course.name || '');
  }, [course.name]);

  useEffect(() => {
    setDescription(course.description || '');
  }, [course.description]);

  useEffect(() => {
    setSlug(course.slug || '');
  }, [course.slug]);

  useEffect(() => {
    reloadAssignments();
  }, [course.state]);

  const learnerDropdownItems = [
    { click: handleAddLearnersByEmailClick, text: t('Add by email') },
    { click: handleAddLearnersByGroupClick, text: t('Add by group') },
  ];
  const learnerSegmentNav = (
    <SegmentNav style={{ display: 'flex' }}>
      <div style={{ display: 'inline-block', marginRight: '30px' }}>
        <KnowledgeBankLink courseId={courseId} />
      </div>
      <div style={{ display: canEditLearnersAndInstructors && !isQLearn ? 'block' : 'none' }}>
        <OutsideClickHandler onOutsideClick={() => setShowAddLearnersDropdown(false)}>
          <MenuButton text="Add" click={handleAddLearnerClick} open={showAddLearnersDropdown} />
          {showAddLearnersDropdown && <DropDown left={-10} listItems={learnerDropdownItems} />}
        </OutsideClickHandler>
      </div>
      <div style={{ display: canEditLearnersAndInstructors && !isQLearn ? 'block' : 'none' }}>
        <RemoveButton onClick={handleRemoveLearnersClick}>Remove All</RemoveButton>
      </div>
    </SegmentNav>
  );

  //////////// Importing a .cerego file//////////////
  const canImportAndExport = () => {
    const importEnabled = course?.partner?.partnerSettings?.allowContentImportExport;
    return canEditContent && importEnabled;
  };

  const handleImportClick = () => {
    ceregoImportFile.current?.click(); // Trigger file input selector
  };
  const onSelectedCeregoImportFile = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (loadEvent) => {
      const text = loadEvent?.target?.result;
      if (text) {
        try {
          await axios.post(`/api/v3/courses/${course.id}/sets/import`, { import_data_string: text });
        } catch (error) {
          alert(`Error importing: ${getErrorStringFromAPIError(error)}`);
        }
      }
      reloadAssignments();
    };
    reader.readAsText(event.target.files[0]);
    setShowAddContent(false);
  };

  const addContentItems = [{ click: handleSearchSharedContentClick, text: t('Shared assignments') }];

  if (['admin', 'course manager', 'content manager'].includes(role)) {
    addContentItems.push({ click: () => createSet('set'), text: t('New learning set') });
    addContentItems.push({ click: () => createSet('assessment'), text: t('New assessment') });
    addContentItems.push({ click: () => createSet('survey'), text: t('New survey') });
    addContentItems.push({ click: handleUploadScormClick, text: t('Upload SCORM') });
    addContentItems.push({ click: handleBulkUploadClick, text: t('Bulk upload') });
  }

  if (canImportAndExport()) {
    addContentItems.push({ click: handleImportClick, text: t('Import .cerego file') });
  }

  const contentSegmentNav = (
    <SegmentNav style={canEditContent ? {} : { visibility: 'hidden' }}>
      <OutsideClickHandler onOutsideClick={() => setShowAddContent(false)}>
        <MenuButton text="Add" click={handleAddContentClick} open={showAddContent} />
        {showAddContent && <DropDown left={-10} listItems={addContentItems} close={() => setShowAddContent(false)} />}
      </OutsideClickHandler>
    </SegmentNav>
  );

  const saveCourseTitle = (): void => {
    AnalyticsService.getInstance().track('course_edited', { edit_category: 'Course title' });
    dispatch(updateCourse({ name }, courseId));
  };

  function copySlugLink() {
    copyToClipboard(`${getBaseEnrollmentUrl()}/${slug}`);
  }

  function getBaseEnrollmentUrl() {
    return `${getBaseAngularUrl()}/join/${course.partner?.slug}`;
  }

  function handleBulkUploadClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Bulk upload' });
    setShowBulkUpload(true);
  }

  function handleAddLearnerClick() {
    setShowAddLearnersDropdown((showAddLearnersDropdown) => !showAddLearnersDropdown);
  }

  function handleRemoveLearnersClick() {
    setShowRemoveLearners((showRemoveLearners) => !showRemoveLearners);
  }

  function handleAddContentClick() {
    setShowAddContent((showAddContent) => !showAddContent);
  }

  function handleAvatarButtonClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add course image' });
    setShowCourseImageUploadModal(true);
  }

  function handleAddLearnersByEmailClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add learner email' });
    setShowAddLearnersDropdown(false);
    setShowAddLearnersByEmail(true);
  }

  function handleAddLearnersByGroupClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add learner group' });
    setShowAddLearnersDropdown(false);
    setShowAddLearnersByGroup(true);
  }

  function handleImageUploadModalCancel() {
    setShowCourseImageUploadModal(false);
  }

  function handleImageUploadModalSave(image) {
    AnalyticsService.getInstance().track('course_edited', { edit_category: 'Course image' });
    dispatch(updateCourse({ icon_image_id: image.id }, courseId));
    setShowCourseImageUploadModal(false);
  }

  function handlePublishClick() {
    setShowPublishMenu((showPublishMenu) => !showPublishMenu);
  }

  function handleUploadScormClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Upload SCORM' });
    setShowUploadSCORM(true);
    // window.location.href = `/app/nav/v3/scorm_upload?courseId=${course.id}&partnerId=${course.partner?.id}`;
  }

  function handleSearchSharedContentClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Search shared' });
    setShowSharedContentModal(true);
  }

  function handleSuccessfulInvites(successfulInviteCount: number) {
    setShowManageInstructorsModal(false);
    makeToast({
      text: t('Course Detail Page Instructors Invited Text', { count: successfulInviteCount }),
      isError: false,
    });
  }

  function handleDeleteCourseClick() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Delete course' });
    setShowDeleteCourse(true);
  }

  function publishCourse(inLibrary, inviteLearners, shareAssignments) {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Publish course' });
    dispatch(
      updateCourse(
        {
          state: 'published',
          send_email_invite: inviteLearners,
          in_library: inLibrary,
          share_assignments: shareAssignments,
        },
        courseId
      )
    );
    setShowPublishCourse(false);
    setShowLibraryCourse(false);
  }

  function unpublishCourse() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Unpublish course' });
    dispatch(updateCourse({ state: 'unpublished' }, courseId));
    setShowPublishMenu(false);
  }

  function deleteCourse() {
    axios.delete(`/api/v3/courses/${courseId}`).then((response) => {
      window.location.href = `${getBaseReactUrl()}/app/nav/courses`;
    });
  }

  async function cloneCourse() {
    try {
      await axios.post(`/api/v3/courses/${courseId}/clone`, {
        with_assignments: true,
        with_goals: true,
        with_release_date: true,
        with_due_date: true,
      });
      window.location.href = `${getBaseReactUrl()}/app/nav/courses`;
    } catch (error) {
      alert(`Error cloning: ${getErrorStringFromAPIError(error)}`);
    }
  }

  function archiveCourse() {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Archive course' });
    dispatch(updateCourse({ state: 'archived' }, courseId));
  }

  function exportCourse() {
    window.open(`/api/v3/courses/${courseId}/export`);
  }

  function shouldPublishButtonBeDisabled() {
    const noAssignments: boolean = course.goalsCount === 0 || assignments?.length === 0;
    // Publish button is disabled if there are no assignments or if the user is a content manager
    return noAssignments || !canPublishCourse;
  }

  function toggleShowNotPublishableAlertThenHide() {
    setShowNotPublishableAlert(!showNotPublishableAlert);
    setTimeout(function () {
      setShowNotPublishableAlert(false);
    }, autohideDelay);
  }

  function getNotPublishableAlertText() {
    if (role === 'content manager') {
      return t('Content Managers cannot publish courses.');
    }
    if (course.goalsCount === 0) {
      return t('Add an assignment to publish!');
    }
    return '';
  }

  function showPublishButton(
    buttonType = 'Primary',
    buttonText = 'Publish',
    buttonHandler = () => false,
    disabled = shouldPublishButtonBeDisabled()
  ) {
    return buttonType === 'Delete' ? (
      <DeleteButton click={buttonHandler} disabled={disabled}>
        {buttonText}
      </DeleteButton>
    ) : (
      <div onClick={() => toggleShowNotPublishableAlertThenHide()}>
        <PrimaryButton click={buttonHandler} disabled={disabled}>
          {buttonText}
        </PrimaryButton>
        {showNotPublishableAlert && <AlertText>{getNotPublishableAlertText()}</AlertText>}
      </div>
    );
  }

  const createSet = async (goalType: string) => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add learning set' });
    // checking params
    if (!['set', 'survey', 'assessment'].includes(goalType)) {
      return;
    }

    switch (goalType) {
      case 'set':
        AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add learning set' });
        break;
      case 'survey':
        AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add survey' });
        break;
      case 'assessment':
        AnalyticsService.getInstance().track('button_clicked', { button_type: 'Add assessment' });
        break;
      default:
        break;
    }

    setNewGoalType(goalType);
    setShowCreateNewSet(true);
  };

  let sectionHeaders = {
    assignments: t('Add Assignments'),
    learners: t('Add Learners'),
    publish: t('Publish'),
  };

  if (course.state === 'published') {
    sectionHeaders = {
      assignments: t('Manage Assignments'),
      learners: t('Manage Learners'),
      publish: '',
    };
  }

  // for section 3 - publish
  let statusTitle;
  let statusColor;
  let publishText;
  let dropdownText;
  let publishButton;
  let showPublishIcon = false;
  let publishDropDown = [] as string[];
  const dropDownOptions = {
    publish: {
      header: t('Publish'),
      subtext: t('Open up your course to learners, and start tracking activity and analytics'),
      click: () => setShowPublishCourse(true),
    },
    library: {
      header: t('Publish to the learning library'),
      subtext: t('Anyone with library access can self-enroll in this course'),
      click: () => setShowLibraryCourse(true),
    },
    archive: {
      header: t('Archive'),
      subtext: t('Learners may keep studying, but their course progress will no longer update'),
      click: () => archiveCourse(),
    },
    export: {
      header: t('Export'),
      subtext: t('Export the content of this course'),
      click: () => exportCourse(),
    },
    unpublish: {
      header: t('Unpublish'),
      subtext: t('Learners lose access to assignments'),
      click: () => unpublishCourse(),
    },
    delete: {
      header: t('Delete'),
      subtext: t('Permanently delete this course'),
      click: () => handleDeleteCourseClick(),
    },
    clone: {
      header: t('Clone this course'),
      subtext: t('Create an unpublished copy (includes content except surveys)'),
      click: () => cloneCourse(),
    },
  };

  // remember javascript switch uses strict comparison
  switch (course.state) {
    case 'published':
      statusColor = Color.darkGreen;
      publishText = t('Archive, delete, and more');
      dropdownText = t('View Options');
      if (course.inLibrary) {
        statusTitle = t('Published to learning library');
        publishDropDown = ['archive', 'unpublish'];
      } else {
        statusTitle = t('Published course');
        publishDropDown = ['library', 'archive', 'unpublish'];
      }
      break;

    case 'unpublished':
      statusTitle = t('Unpublished course');
      statusColor = Color.reportGray;
      publishText = t('Ready to open this course to learners?');
      dropdownText = t('More Options');
      publishButton = {
        type: 'Primary',
        text: t('Publish this Course'),
        click: () => setShowPublishCourse(true),
      };
      showPublishIcon = true;
      publishDropDown = ['delete'];
      break;

    case 'archived':
      statusTitle = t('Archived course');
      statusColor = Color.mainBlue;
      publishText = t('Publish, delete, and more');
      dropdownText = t('View Options');
      publishDropDown = ['publish', 'library', 'delete'];
      break;
  }
  if (canImportAndExport()) {
    publishDropDown.push('export');
  }
  if (canCreateCourse) {
    publishDropDown.push('clone');
  }

  const listItems = publishDropDown.map((item, index) => {
    return (
      <DropDownListItem key={index}>
        <button onClick={dropDownOptions[item].click} aria-label={dropDownOptions[item].header}>
          <div>{dropDownOptions[item].header}</div>
          <Subtext>{dropDownOptions[item].subtext}</Subtext>
        </button>
      </DropDownListItem>
    );
  });

  const LearnersEmpty = () => {
    return (
      <>
        <ArticleIcon src={learners} style={{ marginTop: '3em' }} />
        <h3>{t('No learners yet')}</h3>
        {course.partner?.partnerSettings?.joinUrlEnabled && <p>{t('Add learners or use the enrollment link below')}</p>}
        {!course.partner?.partnerSettings?.joinUrlEnabled && <p>{t('Add learners')}</p>}
      </>
    );
  };

  const PublishMain = () => {
    return (
      <>
        {showPublishIcon && <ArticleIcon src={publish} style={{ marginTop: '3em' }} />}
        <PublishWrapper>
          {publishText && <PublishText>{publishText}</PublishText>}
          {publishButton && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {showPublishButton(publishButton.type, publishButton.text, publishButton.click)}
            </div>
          )}
          {dropdownText && (
            <div style={{ display: 'inline-block' }}>
              <OutsideClickHandler onOutsideClick={() => setShowPublishMenu(false)}>
                <MenuButton text={dropdownText} click={handlePublishClick} open={showPublishMenu} />
                {showPublishMenu && (
                  <DropDown>
                    <DropDownList>{listItems}</DropDownList>
                  </DropDown>
                )}
              </OutsideClickHandler>
            </div>
          )}
        </PublishWrapper>
      </>
    );
  };

  var title = t('Course Details');
  if (course.name) {
    title += ' | ' + course.name;
  }
  useDocumentTitle(title);

  if (showDeleteCourse) {
    return (
      <Modal
        show={showDeleteCourse}
        element={
          <DeleteCourse courseName={course.name} onCancel={() => setShowDeleteCourse(false)} onDelete={deleteCourse} />
        }
      />
    );
  }

  if (showManageInstructorsModal) {
    return (
      <Modal
        show={showManageInstructorsModal}
        element={
          <ManageInstructorsModal
            instructors={instructors}
            courseId={courseId}
            close={() => setShowManageInstructorsModal(false)}
            handleSuccessfulInvites={handleSuccessfulInvites}
          />
        }
      />
    );
  }

  if (showRemoveLearners) {
    return (
      <Modal
        show={showRemoveLearners}
        element={
          <RemoveLearners
            courseId={courseId}
            close={() => setShowRemoveLearners(false)}
            learnerCount={course.studentUsersCount}
          />
        }
      />
    );
  }

  if (showAddLearnersByGroup) {
    return (
      <Modal
        show={showAddLearnersByGroup}
        element={
          <AddLearnersByGroup
            courseId={courseId}
            isPublished={course.state === 'published'}
            partnerId={course.partner?.id}
            close={() => setShowAddLearnersByGroup(false)}
          />
        }
      />
    );
  }

  if (showAddLearnersByEmail) {
    return (
      <Modal
        show={showAddLearnersByEmail}
        element={
          <AddLearnersByEmail
            courseId={courseId}
            isPublished={course.state === 'published'}
            partnerId={course.partner?.id}
            close={() => setShowAddLearnersByEmail(false)}
          />
        }
      />
    );
  }

  if (showCourseImageUploadModal) {
    return (
      <Modal
        show={showCourseImageUploadModal}
        element={
          <ImageUploaderModal
            onSaveImage={handleImageUploadModalSave}
            onCancel={handleImageUploadModalCancel}
            title={t('Update Course Image')}
          />
        }
      />
    );
  }

  if (showBulkUpload) {
    return (
      <Modal
        show={showBulkUpload}
        element={<BulkUpload onCancel={() => setShowBulkUpload(false)} courseId={course.id} />}
      />
    );
  }

  if (showSharedContentModal) {
    return (
      <Modal
        show={showSharedContentModal}
        element={<SearchSharedContent courseId={courseId} onCancel={() => setShowSharedContentModal(false)} />}
      />
    );
  }

  if (showLibraryCourse) {
    return (
      <Modal
        show={showLibraryCourse}
        element={
          <LibraryCourse
            courseName={course.name}
            libraryName={course.partner?.partnerLibrary?.name}
            onPublish={publishCourse}
            onCancel={() => setShowLibraryCourse(false)}
          />
        }
      />
    );
  }

  if (showPublishCourse) {
    return (
      <Modal
        show={showPublishCourse}
        element={
          <PublishCourse
            libraryName={course.partner?.partnerLibrary?.name}
            courseName={course.name}
            learnerCount={course.studentUsersCount}
            libraryEnabled={course.partner?.partnerSettings?.libraryEnabled}
            onPublish={publishCourse}
            onCancel={() => setShowPublishCourse(false)}
            showShareOptions={canEditContent}
          />
        }
      />
    );
  }

  if (showCreateNewSet) {
    return (
      <Modal
        show={showCreateNewSet}
        element={
          <CreateNewSet
            goalType={newGoalType}
            courseId={courseId}
            partnerId={course.partner?.id}
            onCancel={() => setShowCreateNewSet(false)}
          />
        }
      />
    );
  }

  if (showUploadSCORM) {
    return (
      <Modal
        show={showUploadSCORM}
        element={<UploadSCORM courseId={courseId} onCancel={() => setShowUploadSCORM(false)} />}
      />
    );
  }

  if (course.state === 'building') {
    return (
      <>
        <div style={{ margin: 'auto', textAlign: 'center' }}>
          <BuildingCourse course={course} />
          <div>
            <DeleteButton click={handleDeleteCourseClick}>Delete</DeleteButton>
          </div>
        </div>
      </>
    );
  }

  // TODO: Enable retrying failed courses
  if (course.state === 'error') {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <h2>{t('Looks like something went wrong.')}</h2>
        <h3>{t(`We were unable to build your course: "${course.name}".`)}</h3>
        <h3>{t(`Please delete this course and try again.`)}</h3>
        <div>
          <DeleteButton click={handleDeleteCourseClick}>Delete</DeleteButton>
        </div>
        <h3>{t(`Or you may work with the partial course that was created before it encountered an error.`)}</h3>
        <div>
          <PrimaryButton click={unpublishCourse}>Create</PrimaryButton>
        </div>
      </div>
    );
  }

  return (
    <PageContainer>
      <Masthead>
        <TitlePrefix color={statusColor}>
          <span className="CourseStatus">
            <span className="StatusIcon">
              <FontAwesomeIcon icon={course.status === 'archived' ? faArchive : faBookmark} />
            </span>
            {statusTitle}:
          </span>
          <span>{t('Course Detail Page Assignment Count Text', { count: course.goalsCount })}</span>
          <span>{t('Course Detail Page Learner Count Text', { count: course.studentUsersCount })}</span>
        </TitlePrefix>
        <TitleSection style={{ padding: '0 1.75em' }}>
          <CourseTitle>
            <EditableText
              value={name}
              onChange={handleNameChange}
              text={course.name}
              aria-label={t('Course Name')}
              doneEditingCallback={saveCourseTitle}
              canEdit={canEditContent}
            />
          </CourseTitle>
          <CourseAvatar>
            <AvatarButton
              canEdit={canEditContent}
              click={handleAvatarButtonClick}
              image={course.image}
              entity="course"
              imageContainerStyle={{ height: '100%', width: '100%' }}
            />
          </CourseAvatar>
        </TitleSection>
        <ContentSection>
          <h3 style={{ fontSize: '0.8rem' }}>{t('Instructors')}</h3>
          <InstructorList>
            {instructors.length > 0 &&
              instructors.map((instructor: any, i: number) => (
                <li key={i}>
                  {canEditLearnersAndInstructors && (
                    <a onClick={() => setShowManageInstructorsModal(true)}>{instructor.name}</a>
                  )}
                  {!canEditLearnersAndInstructors && instructor.name}
                </li>
              ))}
            {instructors.length === 0 &&
              (canEditLearnersAndInstructors ? (
                <a onClick={() => setShowManageInstructorsModal(true)}>{t('Add an instructor')}</a>
              ) : (
                <span>{t('No instructors')}</span>
              ))}
          </InstructorList>
          <h3 id="description-header" style={{ fontSize: '0.8rem' }}>
            {t('Description')}
          </h3>
          <EditableText
            value={description}
            onChange={handleDescriptionChange}
            text={course.description}
            aria-labelledby="description-header"
            doneEditingCallback={() => dispatch(updateCourse({ description }, courseId))}
            canEdit={canEditContent}
          />
        </ContentSection>
      </Masthead>

      {canViewLearners && (
        <TableFrame
          id="course-learners-table-frame"
          mainComponent={<CourseLearnersTable course={course} />}
          emptyComponent={<LearnersEmpty />}
          hasContent={learnerStats.length > 0}
          isLoading={learnerStatsLoading}
          segmentNav={learnerSegmentNav}
          title={sectionHeaders.learners}
        />
      )}

      <TableFrame
        id="course-assignments-table-frame"
        mainComponent={<CourseAssignmentsTable course={course} canEditContent={canEditContent} />}
        segmentNav={contentSegmentNav}
        title={sectionHeaders.assignments}
      />

      {canViewLearners && course.partner?.partnerSettings?.joinUrlEnabled && (
        <Box>
          <h3>{t('Course Enrollment Link')}</h3>
          <p>
            {t('Learners can self-enroll in your course with this link. You can customize the last piece of the link.')}
          </p>
          <SlugLink>
            <div>
              {getBaseEnrollmentUrl()}/{!canEditContent ? slug : ''}
            </div>
            {canEditContent && <SlugInput id="slugInput" value={slug} onChange={handleSlugChange} onBlur={saveSlug} />}
          </SlugLink>
          {course.state === 'published' && (
            // TODO change this button to the purple one
            <div style={{ display: 'flex', alignItems: 'center' }} className={'mine'}>
              <PrimaryButton size="small" shape="pill" click={copySlugLink}>
                {t('Copy Link')}
              </PrimaryButton>
              <span className="Notice">{t('This link activates when the course is published.')}</span>
            </div>
          )}
        </Box>
      )}

      {canEditContent && (
        <TableFrame
          mainComponent={<PublishMain />}
          emptyComponent={<></>}
          hasContent={true}
          isLoading={false}
          segmentNav={<></>}
          title={sectionHeaders.publish}
        />
      )}
      <input
        type="file"
        id="ceregoImportFile"
        ref={ceregoImportFile}
        style={{ display: 'none' }}
        onChange={onSelectedCeregoImportFile}
        accept=".cerego"
      />
    </PageContainer>
  );
};

const InstructorList = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    display: inline-block;
    margin-right: 0.5em;

    &:not(:last-child)::after {
      content: ',';
    }
  }

  a {
    text-decoration: none;
    color: ${Color.primaryBlue};
    cursor: pointer;
  }
`;

export const SegmentNav = styled.nav`
  display: inline-block;
  margin-left: 1.5rem;
  margin-bottom: -0.25rem;
  font-size: 0.8rem;
  vertical-align: bottom;

  > * {
    margin-right: 1rem;
  }
`;

const CourseAvatar = styled.div`
  position: relative;
  float: right;
  margin: -1em 0.5em;
`;

const Box = styled.div`
  border: solid 1px ${Color.veryLightGray};
  background-color: ${Color.white};
  border-radius: 0.25em;
  margin-top: 1em;
  padding: 1em;
  text-align: left;
  margin-right: 1.75em;
  margin-left: 1.75em;
  padding: 1em;
  h3 {
    color: ${Color.lightBlack};
    font-weight: bold;
    font-size: 1.2em;
    border-bottom: 1px solid ${Color.veryLightGray};
    margin: 0 -1em 0.5em;
    padding: 0 1em 0.75em;
  }
  p {
    color: ${Color.darkBlueGray};
  }
  .Notice {
    font-weight: 500;
    font-size: 0.75em;
    margin-left: 0.5em;
    color: ${Color.mainBlue};
  }
`;

const SlugLink = styled.div`
  border: solid 1px ${Color.lightGray};
  background-color: ${Color.primaryGray};
  color: ${Color.lightBlue};
  padding: 0.5em 1em;
  margin-bottom: 1.5em;
  display: grid;
  grid-template-columns: auto 1fr;
`;

const SlugInput = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: 0.95rem;
`;

const PublishText = styled.h3`
  font-weight: bold;
  font-size: 1.125em;
  color: ${Color.reportGray};
`;

const PublishWrapper = styled.div`
  padding: 1em 0 9em 0;

  > div {
    margin-bottom: 1em;
  }
`;

const Subtext = styled.div`
  font-size: 0.8rem;
  color: ${Color.reportGray};
  margin-top: 0.5em;
`;

const autohideDelay = 3000;

const AlertText = styled.div`
  font-size: 0.8rem;
  color: ${Color.red};
  font-weight: bold;
  margin-top: 1em;
  animation-name: ${autohide};
  animation-duration: 0.01s;
  animation-delay: ${autohideDelay}ms;
  animation-fill-mode: forwards;
`;

export const CourseTitle = styled.h1`
  font-size: 2rem;
  margin: 0;
  width: 100%;
`;

const RemoveButton = styled.button`
  display: inline-block;
  color: ${Color.red};
  background-color: ${Color.white};
  border: 2px ${Color.red} solid;
  border-radius: 2em;
  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
  padding: 0.5em 1em;
  cursor: pointer;

  &:hover {
    background-color: ${Color.red};
    color: ${Color.white};
  }
`;
