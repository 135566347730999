import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextStyle from '@tiptap/extension-text-style';
import { EditorEvents, EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useIcEditorStore } from 'components/item-editor/instructional-content/store';
import { useUpdateAnnotation } from 'hooks/useUpdateAnnotation';
import { debounce } from 'lodash';
import { TAnnotation } from 'types';

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Subscript,
  Superscript,
];

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          bullet list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          ordered list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
        >
          code block
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          blockquote
        </button>
        <button
          onClick={() => editor.chain().focus().toggleSubscript().run()}
          className={editor.isActive('subscript') ? 'is-active' : ''}
        >
          subscript
        </button>
        <button
          onClick={() => editor.chain().focus().toggleSubscript().run()}
          className={editor.isActive('subscript') ? 'is-active' : ''}
        >
          superscript
        </button>
        <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          undo
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          redo
        </button>
      </div>
    </div>
  );
};

export const TextAnnotation = ({
  annotation,
  itemId,
  setId,
}: {
  annotation: TAnnotation;
  itemId: string;
  setId: string;
}) => {
  const { updateIcAnnotation } = useIcEditorStore();
  const [updateAnnotation] = useUpdateAnnotation(itemId, setId);

  const debouncedUpdate = debounce(async (editor: any) => {
    const payload = {
      data: {
        attributes: {
          'text-html': editor.getHTML(),
        },
      },
    };
    const updatedAnnotation = await updateAnnotation({ id: annotation.id, payload });

    if (updatedAnnotation) {
      updateIcAnnotation(updatedAnnotation.id, { ...updatedAnnotation });
    }
  }, 500);

  const handleUpdate = ({ editor }: EditorEvents['update']) => {
    debouncedUpdate(editor);
  };

  return (
    <EditorProvider
      slotBefore={<MenuBar />}
      extensions={extensions}
      content={annotation.textHtml}
      onUpdate={handleUpdate}
    >
      <></>
    </EditorProvider>
  );
};
