import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroupMembersStore, useGroupsStore } from 'pages/admin-dashboard/groups/store';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

type CreateGroupUserParams = {
  groupId: string;
  userEmail?: string;
  memberId?: string;
};

const createGroupUser = async (params: CreateGroupUserParams) => {
  let payload: { group_id: string; email?: string; member_id?: string } | null = null;
  if (params.userEmail) {
    payload = {
      group_id: params.groupId,
      email: params.userEmail,
    };
  } else if (params.memberId) {
    payload = {
      group_id: params.groupId,
      member_id: params.memberId,
    };
  } else {
    throw new Error('Either userEmail or memberId must be provided.');
  }

  const response = await axios.post(`/api/v3/groups/${params.groupId}/users`, payload);
  return normalizeJSONAPIResponse(response.data);
};

export const useCreateGroupUser = (groupId: string) => {
  const { makeToast } = useStore();
  const user = useCurrentUser();
  const {
    pageNumber: groupsPageNumber,
    pageSize: groupsPageSize,
    sortColumn: groupsSortColumn,
    sortDirection: groupsSortDirection,
    name: groupsName,
  } = useGroupsStore();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupMembersStore();

  return useMutation(createGroupUser, {
    onSettled: () => {
      queryCache.invalidateQueries([
        'groups',
        user?.primaryPartner.id,
        groupsPageNumber,
        groupsPageSize,
        groupsSortColumn,
        groupsSortDirection,
        groupsName,
      ]);
      queryCache.invalidateQueries(['group', groupId]);
      queryCache.invalidateQueries(['groupMembers', groupId, pageNumber, pageSize, sortColumn, sortDirection, name]);
    },
    onSuccess: () => {
      makeToast({
        text: 'User(s) successfully added to the group',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
