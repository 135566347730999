import { Color } from 'core';
import React from 'react';
import Moment from 'react-moment';

interface IInvoiceHistoryProps {
  invoices: Invoice[];
}

type Invoice = {
  total: number;
  date: Date;
};

const InvoiceHistoryRow = props => {
  const { index, invoice } = props;
  // Styling for first row and every other row to display arrow pointing to first row and alternating background color.
  const backgroundColor = index % 2 === 0 ? Color.primaryGray : Color.veryLightGray;
  const borderLeft = index !== 0 ? '1px solid gray' : 'none';
  const paddingLeft = index !== 0 ? '2em' : 'none';
  const fontSize = index === 0 ? '1.5em' : '1em';
  const dollarsAndCents = (invoice.total / 100)
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    .replace('$', '')
    .split('.');
  const dollars = dollarsAndCents[0];
  const cents = dollarsAndCents[1];
  return (
    <div
      style={{
        backgroundColor,
        height: '3rem',
        lineHeight: '3rem',
        paddingLeft,
        borderLeft
      }}
    >
      {index === 0 && (
        <svg style={{ width: '2rem', height: '3rem' }}>
          <line x1="0" x2="1em" y1="0" y2="1.5em" stroke="gray" strokeWidth="1" />
          <line x1="1em" x2="0" y1="1.5em" y2="3em" stroke="gray" strokeWidth="1" />
        </svg>
      )}
      <span style={{ fontSize }}>
        <Moment format="MMM D, YYYY" style={index === 0 ? { position: 'relative', top: '-.75em' } : {}}>
          {invoice.date}
        </Moment>{' '}
        <span style={{ float: 'right' }}>
          ${dollars}
          <sup style={{ verticalAlign: 'top', fontSize: '.5em', position: 'relative', top: '-.25em' }}>.{cents}</sup>
        </span>
      </span>
    </div>
  );
};

export const InvoiceHistory = React.memo<IInvoiceHistoryProps>(props => {
  return (
    <div>
      <div style={{ height: '3em', lineHeight: '3em', paddingLeft: '2em', borderLeft: '1px solid gray' }}>
        <h4>Invoice History</h4>
      </div>

      <div>
        {props.invoices.map((invoice, index) => {
          return <InvoiceHistoryRow invoice={invoice} index={index} key={index} />;
        })}
        <div
          style={{
            backgroundColor: Color.primaryGray,
            height: '1000px',
            paddingLeft: '2em',
            borderLeft: '1px solid gray'
          }}
        />
      </div>
    </div>
  );
});
