// tslint:disable:max-line-length
// tslint:disable:no-conditional-assignment
import i18n from '../i18n';

const oneSecInMsec = 1000;
const oneMinInMsec = oneSecInMsec * 60;
const oneHourInMsec = oneMinInMsec * 60;
const oneDayInMsec = oneHourInMsec * 24;
const oneWeekInMsec = oneDayInMsec * 7;
const oneMonthInMsec = oneDayInMsec * 30;
const oneYearInMsec = oneDayInMsec * 365;

export const getMessaging = (
  startedItemsCount,
  unstartedItemsCount,
  percentCorrect,
  eligibleItemsCount,
  progress,
  justStudied,
  seeNextAt,
  isAssessment,
  isSurvey
) => {
  const NEXT_REVIEW_DURATION_MSEC = new Date(seeNextAt).getTime() - new Date().getTime();
  const totalItemsCount = startedItemsCount + unstartedItemsCount;

  const firstTimeUnstartedMessaging = {
    image: 'map.png',
    primaryText: i18n.t('Get started!'),
    secondaryText: null
  };

  const assessmentUnstartedMessaging = {
    image: 'map.png',
    primaryText: i18n.t('Take this assessment.'),
    secondaryText: i18n.t('User Session Journey Unstarted Assessment', { count: unstartedItemsCount })
  };

  const assessmentStartedMessaging = {
    image: 'map.png',
    primaryText: i18n.t('Continue with this assessment.'),
    secondaryText: i18n.t('User Session Journey Assessment Started Secondary', { unstartedItemsCount, totalItemsCount })
  };

  let assessmentCompleteSecondary: string | null = null;
  if (typeof percentCorrect === 'number') {
    assessmentCompleteSecondary = i18n.t('User Session Journey Assessment Complete Secondary', {
      percentCorrect: Math.floor(percentCorrect * 100)
    });
  }
  const assessmentCompleteMessaging = {
    image: 'map.png',
    primaryText: i18n.t('You’ve completed this assessment.'),
    secondaryText: assessmentCompleteSecondary
  };

  const surveyUnstartedMessaging = {
    image: 'map.png',
    primaryText: i18n.t('Take this survey.'),
    secondaryText: i18n.t('User Session Journey Survey Unstarted Secondary', { count: unstartedItemsCount })
  };

  const surveyStartedMessaging = {
    image: 'map.png',
    primaryText: i18n.t('Continue with this survey.'),
    secondaryText: i18n.t('User Session Journey Survey Started Secondary', { unstartedItemsCount, totalItemsCount })
  };

  const surveyCompleteMessaging = {
    image: 'map.png',
    primaryText: i18n.t('Thanks for taking this survey!'),
    secondaryText: null
  };

  const startMoreItemsMessaging = {
    image: 'purple-mountain.png',
    primaryText: i18n.t('Keep Learning!'),
    secondaryText: i18n.t('User Session Journey Assignment Started Secondary', { count: unstartedItemsCount })
  };
  const fadingOnlyMessaging = {
    image: 'blue-mountain.png',
    primaryText: i18n.t("Let's Review!"),
    secondaryText: i18n.t('User Session Journey Fading Only Secondary', { count: eligibleItemsCount })
  };
  const goodForNowMessaging = {
    image: 'purple-camp.png',
    primaryText: i18n.t('Take a break!'),
    secondaryText: i18n.t(
      'It will improve your learning, and studying ahead won’t improve your progress. Plus, you’ve earned it!'
    )
  };
  const progressCompletedMessagingSecondaryText =
    eligibleItemsCount > 0
      ? i18n.t("Feel free to keep reviewing if you'd like to further cement this knowledge into your long-term memory.")
      : i18n.t(
          "Come back later to check for items to review if you'd like to further cement this knowledge into your long-term memory."
        );
  const progressCompletedMessaging = {
    image: 'blue-fireworks.png',
    primaryText: i18n.t("You've completed this assignment!"),
    secondaryText: progressCompletedMessagingSecondaryText
  };
  const justStudiedSecondaryText =
    NEXT_REVIEW_DURATION_MSEC <= oneDayInMsec
      ? i18n.t('You’ve learned everything for today. Take a short break, and tomorrow we’ll see what you remember!')
      : i18n.t('User Session Journey Just Studied Next Review', {
          duration: humanizeDuration(NEXT_REVIEW_DURATION_MSEC)
        });
  const justStudiedMessaging = {
    image: 'purple-camp.png',
    primaryText: i18n.t('Hooray!'),
    secondaryText: justStudiedSecondaryText
  };

  let messaging = {};
  if (isAssessment) {
    if (progress === 0) {
      messaging = assessmentUnstartedMessaging;
    } else if (unstartedItemsCount === 0) {
      messaging = assessmentCompleteMessaging;
    } else {
      messaging = assessmentStartedMessaging;
    }
  } else if (isSurvey) {
    if (progress === 0) {
      messaging = surveyUnstartedMessaging;
    } else if (unstartedItemsCount === 0) {
      messaging = surveyCompleteMessaging;
    } else {
      messaging = surveyStartedMessaging;
    }
  } else {
    if (progress === 0) {
      messaging = firstTimeUnstartedMessaging;
    } else if (progress === 1) {
      messaging = progressCompletedMessaging;
    } else if (unstartedItemsCount > 0) {
      messaging = startMoreItemsMessaging;
    } else if (eligibleItemsCount > 0) {
      messaging = fadingOnlyMessaging;
    } else if (justStudied) {
      messaging = justStudiedMessaging;
    } else {
      messaging = goodForNowMessaging;
    }
  }

  return { ...messaging, unstartedItemsCount, progress };
};

export const getBtnText = (unstartedItemsCount, eligibleItemsCount, progress, justStudied, isAssessment, isSurvey) => {
  let primaryBtnText;
  const isAssessmentOrSurvey = isAssessment || isSurvey;
  if (unstartedItemsCount === 0 && (eligibleItemsCount === 0 || isAssessmentOrSurvey)) {
    primaryBtnText = justStudied ? i18n.t('Done') : i18n.t('Back');
  } else if (progress === 1) {
    primaryBtnText = i18n.t('Review');
  } else {
    primaryBtnText = i18n.t("Let's do this!");
  }

  const secondaryBtnText =
    !isSurvey && !isAssessment && !justStudied && unstartedItemsCount === 0 && eligibleItemsCount === 0
      ? i18n.t('Study Anyway')
      : null;

  return { primaryBtnText, secondaryBtnText };
};

const humanizeDuration = duration => {
  let units;
  let result;
  if ((units = Math.floor(duration / oneYearInMsec)) > 0) {
    result = unitsToString(units, 'year');
  } else if ((units = Math.floor(duration / oneMonthInMsec)) > 0) {
    result = unitsToString(units, 'month');
  } else if ((units = Math.floor(duration / oneWeekInMsec)) > 0) {
    result = unitsToString(units, 'week');
  } else if ((units = Math.floor(duration / oneDayInMsec)) > 0) {
    result = unitsToString(units, 'day');
  } else if ((units = Math.floor(duration / oneHourInMsec)) > 0) {
    result = unitsToString(units, 'hour');
  } else if ((units = Math.floor(duration / oneMinInMsec)) > 0) {
    result = unitsToString(units, 'minute');
  } else if ((units = Math.floor(duration / oneSecInMsec)) > 0) {
    result = unitsToString(units, 'second');
  } else {
    result = '< one second';
  }
  return result;
};

const unitsToString = (count, unitName) => {
  return `${count} ${unitName}${count > 1 ? 's' : ''}`;
};
