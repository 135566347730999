import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useStore, { LearnerDashboardState, useLearnerDashboardStore } from '../zstore';

export default function useOpenCoursePage() {
  const history = useHistory();
  const paymentRequiredCourseIds = useLearnerDashboardStore(
    (state: LearnerDashboardState) => state.paymentRequiredCourseIds
  );
  const { makeToast } = useStore();
  const { t } = useTranslation();

  return (courseId: any) => {
    if (paymentRequiredCourseIds.includes(courseId)) {
      makeToast({
        text: t('Course requires payment.'),
        isError: false,
      });
    } else {
      history.push(`/nav/v4/dashboard/courses/${courseId}`);
    }
  };
}
