import Dialog from '@material-ui/core/Dialog';
import closeButton from 'assets/images/close-circle.svg';
import ImageView from 'components/ImageView';
import { IImage } from 'models/image';
import * as React from 'react';
import styled from 'styled-components';

type ModalProps = {
  image: IImage;
  onClose?: any;
  open?: any;
};

const ImageContainer = styled.div`
  margin: 15px;

  & > img {
    display: table-cell;
    width: 100%
    max-height: 75%
    margin: 0px auto;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

const CloseImage = styled.img`
  height: 30px;
  max-width: 30px;
  object-fit: contain;
  display: flex;
  align-self: center;
`;

export class ImageModal extends React.Component<ModalProps> {
  public render() {
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          open={this.props.open || false}
          maxWidth="xl"
          aria-label="Click to close"
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <ImageContainer>
            <ImageView image={this.props.image} transcodedSize={'large'} />
            <CloseButton onClick={this.handleClose} aria-label="Close">
              <CloseImage src={closeButton} />
            </CloseButton>
          </ImageContainer>
        </Dialog>
      </div>
    );
  }
  public handleClose = () => {
    this.props.onClose();
  };
}
