import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Color } from 'core';

interface IBrightTextarea {
  placeholder?: string;
  autoFocus?: boolean;
  required?: boolean;
  bright?: boolean;
  style?: any;
  value: string;
  onChange?(event): void;
  onKeyPress?(event): void;
  'aria-labelledby'?: string;
}

const BaseTextarea = styled.textarea`
  color: ${Color.slateGray};
  line-height: 22px;
  font-size: 18px;
  padding: 15px;
  margin: 20px auto 20px auto;
  width: 100%;
  background-color: ${Color.primaryGray};
  border-radius: 5px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  resize: vertical;
  font-family: inherit;
`;

export const BrightTextarea = React.memo<IBrightTextarea>((props) => {
  const { t } = useTranslation();

  return (
    <BaseTextarea
      placeholder={props.placeholder || t('Enter some text here')}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      autoFocus={props.autoFocus}
      required={props.required}
      style={props.bright ? {} : styles.dim}
      value={props.value}
      aria-labelledby={props['aria-labelledby']}
    />
  );
});

const styles = {
  dim: {
    outline: 'none',
  },
};
