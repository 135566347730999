import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MenuButton } from 'components/buttons';
import { Pagination } from 'components/reports/controls/Pagination';
import { GroupFilter } from 'components/reports/filters/GroupFilter';
import { PeopleFilter } from 'components/reports/filters/PeopleFilter';
import { SortArrow } from 'components/SortArrow';
import { Color } from 'core';
import { ExportToCsv } from 'export-to-csv';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { getPeople, usePeople, UsePeopleParams } from 'hooks/usePeople';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useReportStore } from 'zstore';

export const People = () => {
  return (
    <>
      <Filters />
      <Data />
    </>
  );
};

const Filters = () => {
  return (
    <div>
      <PeopleFilter />
      <GroupFilter />
    </div>
  );
};

const Data = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const { t } = useTranslation();
  const memberIdEnabled = partner?.partnerSettings.memberIdEnabled;

  const [columns, setColumns] = useState<ColumnDef<any>[]>([]);

  const page = useReportStore((state) => state.page);
  const setPage = useReportStore((state) => state.setPage);
  const pageSize = 10;
  const [sort, setSort] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const group = useReportStore((state) => state.group);
  const userId = useReportStore((state) => state.userId);
  const params: UsePeopleParams = {
    page,
    pageSize,
    group,
    userId,
    sort,
    sortDirection,
    limited: false,
  };
  const { data: response, isLoading } = usePeople(params);

  const data = response?.data || null;
  const meta = response?.meta;

  useEffect(() => {
    if (!!response) {
      setTotalCount(meta['total_count']);
      setTotalPages(meta['total_pages']);
    }
  }, [JSON.stringify(data)]);

  const renderHeader = (title: string) => {
    let headerText = title.replace('_', ' ');
    if (title.toLowerCase() === 'member_id') {
      headerText = partner.partnerSettings.memberIdSlug;
    }

    return (
      <TableHeaderContainer>
        <TableHeaderText>{headerText}</TableHeaderText>
        {sort === title.toLowerCase().replace(' ', '_') && sortDirection === 'desc' && <SortArrow icon={faArrowDown} />}
        {sort === title.toLowerCase().replace(' ', '_') && sortDirection === 'asc' && <SortArrow icon={faArrowUp} />}
      </TableHeaderContainer>
    );
  };

  const coreHeaders = ['name', 'email', 'id', 'member_id', 'role', 'date_added', 'last_login'];

  useEffect(() => {
    if (response?.data.length > 0) {
      let cols: ColumnDef<any>[] = [];
      cols.push(
        ...[
          {
            id: 'name',
            accessorFn: (row: any) => row['name'],
            header: () => renderHeader('name'),
          },
          {
            id: 'email',
            accessorFn: (row: any) => row['email'],
            header: () => renderHeader('email'),
          },
          {
            id: 'id',
            accessorFn: (row: any) => row['id'],
            header: () => renderHeader('user_id'),
          },
        ]
      );
      if (memberIdEnabled) {
        cols.push({
          id: 'member_id',
          accessorFn: (row: any) => row['member_id'] ?? '',
          header: () => renderHeader('member_id'),
        });
      }
      cols.push(
        ...[
          {
            id: 'role',
            accessorFn: (row: any) =>
              row['role']
                ? row['role']
                    .replace('_', ' ')
                    .split(' ')
                    .map((word: string) => word[0].toUpperCase() + word.substring(1))
                    .join(' ')
                : '',
            header: () => renderHeader('role'),
          },
          {
            id: 'date_added',
            accessorFn: (row: any) => new Date(row['date_added']),
            header: () => renderHeader('date_added'),
          },
          {
            id: 'last_login',
            accessorFn: (row: any) => new Date(row['last_login']),
            header: () => renderHeader('last_login'),
          },
        ]
      );
      const columnHeaders = Object.keys(response.data[0]);
      columnHeaders
        .filter((header: string) => !coreHeaders.includes(header))
        .forEach((header: string) => {
          cols.push({
            id: header,
            accessorFn: (row: any) => row[header],
            header: () => renderHeader(header),
          });
        });
      setColumns(cols);
    }
  }, [JSON.stringify(response)]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const goToPageOne = (): void => {
    setPage(1);
  };

  const goToPreviousPage = (): void => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToNextPage = (): void => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const goToLastPage = (): void => {
    setPage(totalPages);
  };

  const displayDateCell = (date: any): string => {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      const formattedDate = momentDate.format('MMM DD, YYYY');
      return formattedDate;
    }
    return '';
  };

  const toggleSortDirection = (): void => {
    if (sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
  };

  const handleHeaderClick = (e: any): void => {
    // TODO: Ensure this approach works with localization ... or fix it
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (['name', 'email', 'date_added', 'last_login'].includes(header)) {
      setPage(1);
      if (header === sort) {
        toggleSortDirection();
      } else {
        setSort(header);
        setSortDirection('asc');
      }
    }
  };

  const parseStatsForCsv = (data: Array<any>) => {
    return data.map((person: any) => {
      const headers = {
        [t('Group')]: group || '',
        [t('Name')]: person.name ?? '',
        [t('User ID')]: person.id,
        [t('Email')]: person.email ?? '',
      };

      if (memberIdEnabled) {
        headers[partner.partnerSettings.memberIdSlug] = person['member_id'] ?? '';
      }

      headers[t('Role')] = person['role']
        ? person['role']
            .replace('_', ' ')
            .split(' ')
            .map((word: string) => word[0].toUpperCase() + word.substring(1))
            .join(' ')
        : '';
      headers[t('Date Added')] = person['date_added'] ?? '';
      headers[t('Last Login')] = person['last_login'] ?? '';

      // Add partner user tags
      Object.keys(person).forEach((key: string) => {
        if (!coreHeaders.includes(key)) {
          headers[key] = person[key] ?? '';
        }
      });
      return headers;
    });
  };

  const downloadCsv = (data: Array<any>) => {
    if (!data) {
      return;
    }

    const parsedStatsForCsv = parseStatsForCsv(data);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `people-${new Date().toISOString()}`,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(parsedStatsForCsv);
    setIsDownloading(false);
  };

  const handleCsvButtonClick = async (): Promise<void> => {
    setIsDownloading(true);
    const allPeople = await getAllPeople(partner?.id, group);
    downloadCsv(allPeople);
  };

  // Show skeleton UI while waiting for table data
  if (isLoading) {
    return <Skeleton variant="rect" width="100%" height={500} />;
  }

  // Show empty space if data hasn't yet been requested
  if (!data || !columns || columns.length === 0) {
    return <></>;
  }

  // Show empty table message if we know no data is available for this report
  if (data.length === 0) {
    return (
      <DataContainer>
        <div style={{ height: '100%', margin: '20px' }}>
          <p>No records found.</p>
        </div>
      </DataContainer>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <span style={{ marginRight: '20px' }}>
          <MenuButton pending={isDownloading} text={t('Download')} click={handleCsvButtonClick} hideArrow={true} />
        </span>
      </div>

      <>
        <DataContainer>
          <DataTable>
            <thead style={{ backgroundColor: Color.grayTransparent }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ padding: '2px 20px' }}
                      onClick={handleHeaderClick}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  style={{
                    borderTop: '1px solid rgb(224, 224, 224, 1)',
                    borderBottom: '1px solid rgb(224, 224, 224, 1)',
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        padding: '20px',
                        color: Color.reportGray,
                        fontSize: '14px',
                      }}
                    >
                      {flexRender(
                        ['date_added', 'last_login'].includes(cell.column.id)
                          ? displayDateCell(cell.row.original[cell.column.id])
                          : cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </DataTable>
        </DataContainer>

        <Pagination
          totalCount={totalCount}
          page={page}
          pageSize={pageSize}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </>
    </>
  );
};

const getAllPeople = async (partnerId: string, groupFilter: string | null): Promise<Array<any>> => {
  let people: Array<any> = [];

  const page = 1;
  const pageSize = 200;

  let params: UsePeopleParams = {
    page,
    pageSize,
    group: groupFilter,
    userId: null,
    sort: 'name',
    sortDirection: 'asc',
    limited: false,
  };
  const res = await getPeople('key', partnerId, params);
  if (!res) {
    throw new Error('Failed to fetch people.');
  }
  const resData: any = res.data;
  const totalPages = res.meta['total_pages'];
  people.push(...resData);

  for (let i = 2; i <= totalPages; i++) {
    params.page = i;
    const res = await getPeople('key', partnerId, params);
    if (!res) {
      throw new Error('Failed to fetch people.');
    }
    const resData: any = res.data;
    people.push(...resData);
  }

  return people;
};

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.54);
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 520px;
  overflow: auto;
`;

const DataTable = styled.table`
  border-collapse: collapse;
  border: 1px solid ${Color.grayTransparent};
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  color: ${Color.primaryBlack};
  font-size: 1em;
  font-weight: 400;
`;
