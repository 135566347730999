import Typography from '@mui/material/Typography';
import { MemreCalendarDay, MemreContainer, MemreFlexBox } from 'components/core';
import * as colours from 'core/colors';
import formatDate from 'utils/formatDate';
import AreaHeading from './AreaHeading';
import LearnMore from './LearnMore';

function createDaysArray(dateString: string) {
  const inputDate = new Date(dateString);
  const dayInMs = 24 * 60 * 60 * 1000;
  const days:
    | Array<{
        date: number;
        month: string;
        score: number;
      }>
    | undefined = [];

  const differentInMs = +inputDate - +new Date();
  const differenceInDays = differentInMs / (1000 * 3600 * 24);
  const startPosition = differenceInDays <= 1 ? 0 : differenceInDays <= 2 ? -1 : -2;

  for (let i = startPosition; i <= startPosition + 4; i++) {
    const adjust = i ? i * dayInMs : 0;
    const currentDate = new Date(inputDate.getTime() + adjust);
    const date = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'short' });

    const score: number = i === 0 ? 1 : i === -1 || i === 1 ? 0.5 : 0.2;

    days.push({
      date: Number(date),
      month,
      score,
    });
  }

  return days;
}

export default function IdealDays({ idealDay }) {
  const days = createDaysArray(idealDay);
  const date = formatDate(idealDay);

  return (
    <MemreFlexBox direction="column" gap={1} sx={{ flexGrow: 1 }}>
      <MemreFlexBox justify="space-between" align="center">
        <AreaHeading overline="Ideal Day" heading={date} />
        <LearnMore>
          <AreaHeading overline="Ideal Day" heading={date} />
          <Typography sx={{ color: colours.darkGray, fontSize: 14 }}>
            We've calculated the best day for you to study based on your previous learning patterns. Stay tuned for more
            personalized recommendations as we gather more data.
          </Typography>
        </LearnMore>
      </MemreFlexBox>
      <MemreContainer sx={{ gap: 2, pr: '5px', justifyContent: 'space-between' }}>
        {days.map((d, i) => (
          <MemreCalendarDay key={i} date={d.date} month={d.month} score={d.score} />
        ))}
      </MemreContainer>
    </MemreFlexBox>
  );
}
