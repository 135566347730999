import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Chip } from '@mui/material';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MemreButton } from 'components/core';
import { Pagination } from 'components/reports/controls/Pagination';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { SortArrow, TableHeaderContainer, TableHeaderText } from 'components/tables/learner-stats/elements';
import { useDeleteGroup } from 'hooks/useDeleteGroup';
import { usePaginatedGroups } from 'hooks/usePaginatedGroups';
import { GroupsTableToolbar } from 'pages/admin-dashboard/groups/GroupsTableToolbar';
import { useGroupsStore } from 'pages/admin-dashboard/groups/store';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const GroupsTable = () => {
  const { pageNumber, setPageNumber, pageSize, setPageSize, sortDirection, setSortDirection } = useGroupsStore();
  const { data: groups } = usePaginatedGroups();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteGroup] = useDeleteGroup();

  useEffect(() => {
    if (groups) {
      setTotalPages(Math.ceil(groups.length / pageSize));
      setTotalCount(groups.length);
    }
  }, [groups, pageSize]);

  // Pagination handlers
  const goToPageOne = () => setPageNumber(1);
  const goToNextPage = () => pageNumber < totalPages && setPageNumber(pageNumber + 1);
  const goToPreviousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
  const goToLastPage = () => setPageNumber(totalPages);

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1);
  };

  // Sorting handler
  const handleSortingChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setPageNumber(1);
  };

  const handleDelete = (groupId: string) => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      deleteGroup(groupId);
    }
  };

  // Table columns
  const columns: ColumnDef<any>[] = [
    {
      id: 'name',
      accessorFn: (row) => row.original.name,
      cell: ({ row }) => (
        <div>
          <Link style={{ textDecoration: 'underline' }} to={`groups/${row.original.id}`}>
            {row.original.name}
          </Link>
        </div>
      ),
      header: () => (
        <ColumnHeader title="Name" sort="name" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'group type',
      accessorFn: (row) => row.original.rulesJson,
      cell: ({ row }) => (
        <Chip
          label={row.original.rulesJson ? 'Smart' : 'Classic'}
          size="small"
          color={row.original.rulesJson ? 'secondary' : 'primary'}
          variant="outlined"
        />
      ),
      header: () => (
        <ColumnHeader title="Group Type" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'members',
      accessorFn: (row) => row.original.membersCount,
      cell: ({ row }) => row.original.membersCount,
      header: () => <ColumnHeader title="Members" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
    {
      id: 'courses',
      accessorFn: (row) => row.original.coursesCount,
      cell: ({ row }) => row.original.coursesCount,
      header: () => <ColumnHeader title="Courses" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
    {
      id: 'assignments',
      accessorFn: (row) => row.original.assignmentsCount,
      cell: ({ row }) => row.original.assignmentsCount,
      header: () => (
        <ColumnHeader title="Assignments" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />
      ),
    },
    {
      id: 'delete',
      cell: ({ row }) => (
        <MemreButton
          text="Delete"
          variant="outlined"
          color="error"
          size="small"
          iconName="trash"
          onClick={() => handleDelete(row.original.id)}
        />
      ),
      header: () => <ColumnHeader title="Delete" sort="" sortDirection={sortDirection} onSort={handleSortingChange} />,
    },
  ];

  const table = useReactTable({
    data: groups || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleHeaderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const header = e.currentTarget.innerText.toLowerCase().replace(' ', '_');
    if (!['name'].includes(header)) {
      console.warn('Cannot sort by anything other than name');
      return;
    }

    handleSortingChange();
  };

  return (
    <>
      <TableContainer>
        <GroupsTableToolbar />
        <StyledTable>
          <TableHeader table={table} handleHeaderClick={handleHeaderClick} />
          <TableBody table={table} rightAlignedColumnIds={['members', 'courses', 'assignments']} />
        </StyledTable>
      </TableContainer>

      {/* Pagination Component */}
      <Pagination
        totalCount={totalCount}
        page={pageNumber}
        pageSize={pageSize}
        goToPageOne={goToPageOne}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

interface ColumnHeaderProps {
  title: string;
  sort: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
}

const ColumnHeader = ({ title, sort, sortDirection, onSort }: ColumnHeaderProps) => (
  <TableHeaderContainer
    style={Object.assign(
      { justifyContent: ['Members', 'Courses', 'Assignments'].includes(title) ? 'right' : 'left' },
      sort === '' ? {} : { cursor: 'pointer' }
    )}
    onClick={onSort}
  >
    <TableHeaderText>{title}</TableHeaderText>
    {sort === 'name' && <SortArrow icon={sortDirection === 'desc' ? faArrowDown : faArrowUp} />}
  </TableHeaderContainer>
);

const TableContainer = styled.div`
  margin-top: 20px;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

// BUG: second delete related action doesn't optimistically update the table
// NOTE: second delete, or first delete after a cancel
// TODO: fix this
