import React, { useState } from 'react';
import styled from 'styled-components';
import { transformMilliseconds } from 'utils/timeUtils';

const EmployeeImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 10px;
`;

const EmployeeInfo = styled.div`
  margin-bottom: 10px;
`;

const KnowledgeEventsToggle = styled.button`
  background: #f0f0f0;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
`;

const KnowledgeEvents = styled.div`
  background: #f9f9f9;
  padding: 10px;
  margin-top: 10px;
`;

const Event = styled.div`
  margin: 20px 0;
  padding: 10px;
  border: 3px solid #ddd;
`;

export const EmployeeCard = ({ employee }) => {
  const [showEvents, setShowEvents] = useState(false);

  const toggleEvents = () => {
    setShowEvents(!showEvents);
  };

  const sorted_knowledge_events = employee['knowledge-events'].sort((a, b) => {
    return new Date(b['date-occurred']).getTime() - new Date(a['date-occurred']).getTime();
  });

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmployeeImage src={employee.image ?? 'https://placehold.co/300x300/AAA/FFF'} alt={employee.name} />
          <EmployeeInfo>
            <div>
              <strong>{employee['user-id']}</strong>
            </div>
            <div style={{ marginTop: 9 }}>
              <div>Learning Engine Memory Details</div>
              <ul style={{ marginTop: 2 }}>
                <li>
                  <strong>Recommended next review:</strong> {new Date(employee.memory['see-next-at']).toLocaleString()}
                </li>
                <li>
                  <strong>Last Study Time:</strong> {new Date(employee.memory['last-study-time']).toLocaleString()}
                </li>
                <li>
                  <strong>Review Interval (Memory Strength):</strong>{' '}
                  {transformMilliseconds(employee.memory['review-interval'])}
                </li>
                <li>
                  <strong>Current Retention:</strong> {(employee.memory['current-retention'] * 100).toFixed(0)}%
                </li>
              </ul>
            </div>
            <div>{employee['knowledge-summary']}</div>
          </EmployeeInfo>
        </div>
      </div>
      <KnowledgeEventsToggle onClick={toggleEvents}>
        {showEvents ? 'Hide Knowledge Events' : 'Show Knowledge Events'}
      </KnowledgeEventsToggle>
      <KnowledgeEvents style={{ display: showEvents ? 'block' : 'none' }}>
        <strong>Knowledge Events:</strong>
        {sorted_knowledge_events.map((event) => (
          <Event key={event['commit-hash'] + event.description}>
            <div>Date: {event['date-occurred']}</div>
            <div>Description: {event.description}</div>
            <div>Score: {event.score}</div>
            <div>
              Commit:{' '}
              <a
                href={`https://github.com/ceregousa/smart_api/commit/${event['commit-hash']}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Commit
              </a>
            </div>
          </Event>
        ))}
      </KnowledgeEvents>
    </div>
  );
};
