import { useItemEditorStore } from 'components/item-editor/store';
import { getEditSetUrl } from 'utils/pathUtils';

export const LegacyEditor = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  const editSetItemUrl: string = getEditSetUrl(assignment.learnVersion, assignment.id, item.id)
    .split('?')[0]
    .concat('?isV4Embed=true');

  return (
    <iframe
      id="item-editor-frame"
      style={{ display: 'flex', flexGrow: 1, border: 'none', width: '100%', height: '100%' }}
      src={editSetItemUrl}
    />
  );
};
