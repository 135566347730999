import getOrdinalSuffix from 'utils/getOrdinalSuffix';

export default function formatDate(dateString: Date | string, format = 'day-date-month') {
  const date = new Date(dateString);

  const dayOfWeek = date.toLocaleDateString('en-GB', { weekday: 'long' });

  const dayOfMonth = date.getDate();
  const daySuffix = getOrdinalSuffix(dayOfMonth);

  const month = date.toLocaleDateString('en-GB', { month: 'long' });

  return {
    'day-date-month': `${dayOfWeek} ${dayOfMonth}${daySuffix} ${month}`,
    'date-month': `${dayOfMonth}${daySuffix} ${month}`,
  }[format];
}
