import axios from 'axios';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const ITEM_INCLUDES: string =
  'sentences,sentences.image,sentences.sound,attached_items,image,facets.anchor,facets.association,facets.anchor.image,facets.anchor.sound,facets.association.image,facets.association.sound,annotations,annotations.document,annotations.image,annotations.sound,annotations.video,facets.association.distractors,facets.anchor.distractors,facets.tags';
// TODO: either properly handle pagination or don't paginate at all
const PAGE_NUMBER = 1;
const PAGE_SIZE = 50;

const getAssignmentItems = async (_key: string, setId: string) => {
  const response = await axios.get(
    `/api/v3/sets/${setId}/items?filter[include_unstudiable]=true&include=${ITEM_INCLUDES}&page[number]=${PAGE_NUMBER}&page[size]=${PAGE_SIZE}`
  );
  return normalizeJSONAPIResponse(response.data);
};

export const useAssignmentItems = (setId: string) => {
  return useQuery(['assignmentItems', setId], getAssignmentItems, {
    enabled: setId,
    refetchOnWindowFocus: false,
  });
};
