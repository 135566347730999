import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGroupsStore } from 'pages/admin-dashboard/groups/store';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

type NewGroupPayload = {
  name: string;
  description: string | null;
};

interface CreateGroupParams {
  partnerId: string;
  payload: NewGroupPayload;
}

const createGroup = async ({ partnerId, payload }: CreateGroupParams) => {
  const response = await axios.post(`/api/v3/partners/${partnerId}/groups`, payload);
  return normalizeJSONAPIResponse(response.data);
};

export const useCreateGroup = () => {
  const { makeToast } = useStore();
  const user = useCurrentUser();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupsStore();

  return useMutation(createGroup, {
    onSettled: () => {
      queryCache.invalidateQueries([
        'groups',
        user?.primaryPartner.id,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        name,
      ]);
    },
    onSuccess: () => {
      makeToast({
        text: 'Group created successfully',
        isError: false,
      });
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
