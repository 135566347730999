import { Avatar } from '@mui/material';
import axios from 'axios';
import {
  MemreButton,
  MemreCollapseText,
  MemreFlexBox,
  MemreIcon,
  MemreProgressBar,
  MemreSection,
  MemreSectionHeading,
  MemreText,
} from 'components/core';
import * as Color from 'core/colors';

export default function CourseDetails({ course, user }) {
  const unenroll = () => {
    if (window.confirm('Are you sure you want to unenroll from this course?')) {
      (async () => {
        if (course && user) {
          await axios.delete(`/api/v3/courses/${course.id}/users/${user.id}`);
          window.location.href = '/app/nav/v4/dashboard';
        }
      })();
    }
  };

  const coursePercentage = Math.round(course?.meta.progress * 100);

  return (
    <MemreSection>
      <MemreSectionHeading text="Course Details" />
      <MemreFlexBox direction="column" sx={{ p: 3 }}>
        <MemreFlexBox gap={6} direction="row">
          <MemreCollapseText text={course?.description || ' '} sx={{ flex: 1 }} />
          <MemreFlexBox gap={3} direction="column" sx={{ minWidth: '340px' }}>
            <MemreFlexBox direction="row" gap={2}>
              {course?.partner?.image?.smallUrl ? (
                <Avatar src={course?.partner?.image?.smallUrl} />
              ) : (
                <MemreIcon iconName="building" color={Color.veryLightGray} fontSize="1.9em" />
              )}
              <MemreFlexBox direction="column">
                <MemreText variant="overline">Organization</MemreText>
                <MemreText>{course?.partner?.name}</MemreText>
              </MemreFlexBox>
            </MemreFlexBox>
            <MemreFlexBox direction="row" gap={2}>
              <MemreIcon iconName="user" color={Color.veryLightGray} fontSize="1.9em" />
              <MemreFlexBox direction="column">
                <MemreText variant="overline">Instructors</MemreText>
                <MemreText>
                  {course?.instructorNames?.length === 0
                    ? 'This course has no instructors'
                    : course?.instructorNames.join(', ')}
                </MemreText>
              </MemreFlexBox>
            </MemreFlexBox>
            <MemreFlexBox direction="row" justify="flex-end" sx={{ marginTop: '-8px', marginBottom: '-18px' }}>
              <MemreButton
                text="Unenroll"
                size="small"
                color="warning"
                variant="outlined"
                iconName="trash"
                onClick={unenroll}
              />
            </MemreFlexBox>
          </MemreFlexBox>
        </MemreFlexBox>
        <MemreFlexBox gap={1} direction="column">
          <MemreText>
            Progress: <b>{coursePercentage}%</b>
          </MemreText>
          <MemreProgressBar progress={coursePercentage} hideNumber={true} />
        </MemreFlexBox>
      </MemreFlexBox>
    </MemreSection>
  );
}
