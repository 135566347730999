import { Box, List, TextField } from '@mui/material';
import { MemreButton, MemreFlexBox, MemreText } from 'components/core';
import { useCreatePartnerUserTagType } from 'hooks/useCreatePartnerUserTagType';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePartnerUserTagTypes } from 'hooks/usePartnerUserTagTypes';
import { ConfigItem } from 'pages/admin-dashboard/partner-config/ConfigItem';
import { CustomUserField } from 'pages/admin-dashboard/partner-config/CustomUserField';
import { useState } from 'react';
import { PartnerUserTagType } from 'types';

export const CustomUserFields = () => {
  const user = useCurrentUser();
  const [userFieldInput, setUserFieldInput] = useState('');
  const { data: partnerUserTagTypes } = usePartnerUserTagTypes();
  const [createPartnerUserTagType] = useCreatePartnerUserTagType();
  const [inputError, setInputError] = useState('');

  const handleCreateNewPartnerUserTagType = async () => {
    if (!userFieldInput.trim()) {
      setInputError('Field name cannot be empty');
      return;
    }

    try {
      await createPartnerUserTagType({
        partner_id: user?.primaryPartner.id,
        label: userFieldInput.trim(),
      });
      setUserFieldInput('');
      setInputError('');
    } catch (error) {
      console.error('Error creating custom user field:', error);
      setInputError('Failed to create field. Please try again.');
    }
  };

  return (
    <ConfigItem title="Custom User Fields">
      <MemreFlexBox direction="column" gap={1}>
        <MemreText variant="body1">
          Create and manage custom user fields to collect additional information from your users.
        </MemreText>
        <MemreFlexBox gap={2} align="center">
          <TextField
            label="Field Name"
            value={userFieldInput}
            onChange={(e) => {
              setUserFieldInput(e.target.value);
              setInputError('');
            }}
            variant="outlined"
            color="secondary"
            size="small"
            error={Boolean(inputError)}
            helperText={inputError}
          />
          <MemreButton
            text="Add Field"
            variant="contained"
            color="secondary"
            onClick={handleCreateNewPartnerUserTagType}
            iconName="plus"
            disabled={!userFieldInput.trim()}
          />
        </MemreFlexBox>
        <Box mt={2}>
          <List>
            {partnerUserTagTypes?.map((field: PartnerUserTagType) => (
              <CustomUserField field={field} key={field.id} />
            ))}
          </List>
        </Box>
      </MemreFlexBox>
    </ConfigItem>
  );
};
