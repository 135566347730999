import { AttachIc } from 'components/item-editor/AttachIc';
import { CautionTape } from 'components/item-editor/CautionTape';
import { BaseImage } from 'components/item-editor/regions/BaseImage';
import { Questions } from 'components/item-editor/regions/Questions';
import { useItemEditorStore } from 'components/item-editor/store';
import React from 'react';
import styled from 'styled-components';

export const ASPECT_RATIO = 4 / 3;

export interface ImageDimensions {
  width: number;
  height: number;
}

export const RegionsEditor: React.FC = () => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <Container>
      <CautionTape />
      <BaseImage />
      <Questions />
      <AttachIc />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;
