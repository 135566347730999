import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { AssetUploader } from 'components/AssetUploader';
import { Layout, Size } from 'core';
import { IImage } from 'models/image';

interface IOwnProps {
  title: string;
  onSaveImage: (image: IImage) => void; // Called when the user clicks the save button after uploading an image.
  onCancel: () => void;
}

const Container = styled.div`
  width: ${Size.defaultCardWidth};
`;

const CenterTitle = styled(Layout.PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

export const ImageUploaderModal = (props: IOwnProps) => {
  const { title, onSaveImage, onCancel } = props;
  const [uploadedImage, setUploadedImage] = React.useState<IImage>();
  const { Card } = Layout;
  const { t } = useTranslation();

  const onUploadImage = (image: IImage) => {
    setUploadedImage(image);
  };

  const onSaveClick = () => {
    if (uploadedImage) {
      onSaveImage(uploadedImage);
    } else {
      console.warn('No uploaded image to save');
    }
  };

  return (
    <Container>
      <CenterTitle>{title}</CenterTitle>
      <Card>
        <div>
          <AssetUploader onUploadComplete={onUploadImage} />
        </div>
      </Card>
      <ConfirmOrCancel
        confirmText={t('Save')}
        handleConfirmClick={onSaveClick}
        confirmIsDisabled={uploadedImage == null}
        handleCancelClick={onCancel}
        confirmIsPending={false}
      />
    </Container>
  );
};
