import axios from 'axios';
import { useQuery } from 'react-query';
import getAssignmentStatus from 'utils/getAssignmentStatus';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

import { TableParmas } from 'models/table';

const getAssignments = async (_: string, { id, pageNumber, searchTerm, pageSize, sortByColumn }: TableParmas) => {
  const response = await axios.get(
    `/api/v3/courses/${id}/assignments?include=image&page[number]=${pageNumber}&page[size]=${pageSize}&query=${searchTerm}&sort=${sortByColumn}&tab=default`
  );
  const normalizedData = normalizeJSONAPIResponse(response.data);

  return normalizedData.map((assignment: any) => ({
    ...assignment,
    status: getAssignmentStatus(assignment, id),
  }));
};

const useAssignmentsV3 = ({ id, pageNumber, searchTerm, pageSize, sortByColumn }: TableParmas) => {
  return useQuery(['assignments', { id, pageNumber, searchTerm, pageSize, sortByColumn }], getAssignments, {
    enabled: !!id,
  });
};

export default useAssignmentsV3;
