import { ThemeProvider } from '@mui/material';
import ErrorBoundary from 'components/layouts/ErrorBoundary';
import LoadingComponent from 'components/LoadingComponent';
import theme from '../../pages/learner-dashboard/visulisations/theme';
import MemreTooltip from '../visualisations/MemreTooltip';
import { MemreFlexBox, MemreHeader, MemrePageContainer } from './index';

export default function MemreLayout({ children, isLoading, headerText, backLink }) {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        {headerText && <MemreHeader text={headerText} backLink={backLink} />}
        <MemrePageContainer>
          <LoadingComponent
            component={
              <MemreFlexBox gap={4} direction="column">
                {children}
              </MemreFlexBox>
            }
            isLoading={isLoading}
          />
          <MemreTooltip />
        </MemrePageContainer>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
