import { Slide, Snackbar, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { MemreFlexBox, MemreIcon, MemreText } from './index';
import MemreButton from './MemreButton';
import theme from 'pages/learner-dashboard/visulisations/theme';

type Props = {
  message?: string;
  heading?: string;
  cb?: () => void;
  learnMoreLink?: string;
};

export default function MemreSnackbar({ message, heading, cb, learnMoreLink }: Props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!message);
  }, [message, cb]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  // Primary action: toggling UI.
  const handlePrimaryClick = (e) => {
    if (cb) {
      setOpen(false);
      cb();
    }
  };

  // Secondary action: open the Learn More link.
  const handleLearnMoreClick = (e) => {
    if (learnMoreLink) {
      window.open(learnMoreLink, '_blank', 'noopener,noreferrer');
    }
  };

  // Determine primary button text based on the heading.
  let primaryButtonText = 'New UI';
  if (heading === 'Updated Page Design') {
    primaryButtonText = 'Use Classic UI';
  } else if (heading === 'New Page Design Available') {
    primaryButtonText = 'Try New UI';
  }

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={open}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div>
          <MemreFlexBox
            direction="column"
            gap={2}
            sx={{
              backgroundColor: '#fff', // light background
              borderRadius: '8px',
              boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)', // lighter shadow for light theme
              transition: 'background-color 0.3s ease-in-out',
              p: 2,
              minWidth: '300px',
            }}
          >
            {/* Header row: heading text on the left and close icon on the right */}
            <MemreFlexBox direction="row" justify="space-between" align="center">
              <MemreText sx={{ color: '#000', fontWeight: 'bold' }}>{heading}</MemreText>
              <MemreButton
                text=""
                iconName="close"
                onClick={handleCloseModal}
                variant="text"
                sx={{ minWidth: 'auto', padding: 0 }}
              />
            </MemreFlexBox>

            {/* Message text */}
            {message && (
              <MemreText variant="caption" sx={{ color: '#000' }}>
                {message}
              </MemreText>
            )}

            {/* Button row: left aligned, with small buttons */}
            <MemreFlexBox direction="row" gap={1} justify="flex-start">
              {cb && (
                <MemreButton
                  text={primaryButtonText}
                  onClick={handlePrimaryClick}
                  variant="contained"
                  color="primary"
                  size="small"
                />
              )}
              {learnMoreLink && (
                <MemreButton
                  text="Learn More"
                  onClick={handleLearnMoreClick}
                  variant="outlined"
                  color="primary"
                  size="small"
                />
              )}
            </MemreFlexBox>
          </MemreFlexBox>
        </div>
      </Snackbar>
    </ThemeProvider>
  );
}
