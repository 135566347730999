import { useCreatePartnerUser } from 'hooks/useCreatePartnerUser';
import { useCurrentUser } from 'hooks/useCurrentUser';
import React, { useState } from 'react';
import styled from 'styled-components';

export const AddUserModal = ({ onClose }: { onClose: () => void }) => {
  const [emailInput, setEmailInput] = useState('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [createPartnerUser] = useCreatePartnerUser();
  const user = useCurrentUser();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');
    setErrorMessage('');

    // Split and trim emails
    const emailList = emailInput
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email);

    if (emailList.length === 0) {
      setErrorMessage('Please enter at least one valid email address.');
      setStatus('error');
      return;
    }

    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate emails
    for (const email of emailList) {
      if (!emailRegex.test(email)) {
        setErrorMessage(`Invalid email address: ${email}`);
        setStatus('error');
        return;
      }
    }

    try {
      // Send invite requests
      await Promise.all(
        emailList.map((email) =>
          createPartnerUser({
            partnerId: user.primaryPartner.id,
            payload: {
              email,
              enrolled_via: 'enrolled_via_direct_invite',
              is_instructor: false,
              send_email_invite: true,
            },
          })
        )
      );
      setStatus('success');
      setEmailInput('');
    } catch (error) {
      console.error('Error inviting users:', error);
      setErrorMessage('An error occurred while inviting users. Please try again.');
      setStatus('error');
    }
  };

  const closeModal = () => {
    onClose();
    setStatus('idle');
    setErrorMessage('');
    setEmailInput('');
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Add Users by Email</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="emailInput">Enter email addresses separated by commas:</label>
          <EmailTextarea
            id="emailInput"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            placeholder="e.g., user1@example.com, user2@example.com"
            required
          />
          {status === 'error' && <ErrorText>{errorMessage}</ErrorText>}
          {status === 'success' && <SuccessText>Users invited successfully!</SuccessText>}
          <ButtonContainer>
            <button type="button" onClick={closeModal}>
              Cancel
            </button>
            <button type="submit" disabled={status === 'loading'}>
              {status === 'loading' ? 'Inviting...' : 'Invite Users'}
            </button>
          </ButtonContainer>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 500px;
`;

const EmailTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const SuccessText = styled.p`
  color: green;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button[type='button'] {
    background-color: #ccc;
  }

  button[type='submit'] {
    background-color: #007bff;
    color: white;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 10px;
`;
