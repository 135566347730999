import { Color } from 'core';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';

export const DownloadCSV = styled<any>(CSVLink)`
  display: inline-block;
  color: ${Color.primaryBlue};
  background-color: ${Color.white};
  border: 2px ${Color.primaryBlue} solid;
  border-radius: 2em;
  font-size: 0.75rem;
  font-weight: 700;
  font-family: inherit;
  white-space: nowrap;
  padding: 0.5em 1em;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: none;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? Color.white : Color.primaryBlue)};
    color: ${(props) => (props.disabled ? Color.primaryBlue : Color.white)};
  }
`;
