import Skeleton from '@mui/material/Skeleton';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import content from 'assets/images/content.svg';
import { Modal } from 'components/Modal';
import { ArticleIcon } from 'components/TableFrame';
import { MoreVertButton } from 'components/buttons/MoreVertButton';
import { SecondaryButton } from 'components/buttons/v4';
import ImageBadge from 'components/images/ImageBadge';
import { ConfirmShareContent } from 'components/modals/ConfirmShareContent';
import { ItemDifficulty } from 'components/reports/cells/ItemDifficulty';
import { Pagination } from 'components/reports/controls/Pagination';
import { ColumnHeader } from 'components/tables/learner-stats/ColumnHeader';
import { Container } from 'components/tables/learner-stats/Container';
import { TableBody } from 'components/tables/learner-stats/TableBody';
import { TableHeader } from 'components/tables/learner-stats/TableHeader';
import { DataContainer, DataTable } from 'components/tables/learner-stats/elements';
import { Color } from 'core';
import AssignmentDueDateForm from 'courses/components/AssignmentDueDateForm';
import AssignmentGoalForm from 'courses/components/AssignmentGoalForm';
import AssignmentStatusForm from 'courses/components/AssignmentStatusForm';
import { ContentParticipation } from 'courses/components/ContentParticipation';
import CourseAssignmentsTableMenu from 'courses/components/CourseAssignmentsTableMenu';
import { useCourseAssignmentsTableData } from 'hooks/useCourseAssignmentsTableData';
import useMutateSet from 'hooks/useMutateSet';
import { PRIVACY_TYPES } from 'models/assignment';
import { ContentTitle } from 'pages/course-detail/ContentTitle';
import { DueAtCell } from 'pages/course-detail/DueAtCell';
import { GoalCell } from 'pages/course-detail/GoalCell';
import { ReorderAssignmentsModal } from 'pages/course-detail/ReorderAssignmentsModal';
import { StatusCell } from 'pages/course-detail/StatusCell';
import { CourseAssignmentsTableState, useCourseAssignmentsTableStore } from 'pages/course-detail/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/profile';
import styled from 'styled-components';
import { TAssignment } from 'types';
import { determineMenuOffset } from 'utils/menuUtils';
import useStore from 'zstore';
import { TableSearchBar } from 'components/tables/TableSearchBar';

interface Props {
  course: any;
  canEditContent: boolean;
}

interface AssignmentsTableToolbarProps {
  onReorderClick: () => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setPageNumber: (page: number) => void;
}

const AssignmentsTableToolbar = (props: AssignmentsTableToolbarProps) => {
  const { onReorderClick, searchTerm, setSearchTerm, setPageNumber } = props;
  return (
    <ToolbarContainer>
      <TableSearchBar
        value={searchTerm}
        onChange={setSearchTerm}
        resetPage={() => setPageNumber(1)}
        placeholder="Search assignments"
      />
      <SecondaryButton click={onReorderClick}>Reorder</SecondaryButton>
    </ToolbarContainer>
  );
};

export const CourseAssignmentsTable = (props: Props) => {
  const { course, canEditContent } = props;
  const [showReorderModal, setShowReorderModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const setPageNumber = useCourseAssignmentsTableStore((state) => state.setPageNumber);

  const handleReorderClick = () => {
    setShowReorderModal(true);
  };

  return (
    <>
      <Container
        toolbar={
          <AssignmentsTableToolbar
            onReorderClick={handleReorderClick}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setPageNumber={setPageNumber}
          />
        }
        data={<Data course={course} canEditContent={canEditContent} searchTerm={searchTerm} />}
      />
      {showReorderModal && (
        <ReorderAssignmentsModal show={showReorderModal} course={course} onClose={() => setShowReorderModal(false)} />
      )}
    </>
  );
};

interface DataProps {
  course: any;
  canEditContent: boolean;
  searchTerm: string;
}

const Data = (props: DataProps) => {
  const { course, canEditContent, searchTerm } = props;
  const { t } = useTranslation();
  const currentUser = useSelector((state) => getCurrentUser(state)) || {};
  const [showAssignmentMenu, setShowAssignmentMenu] = useState<boolean>(false);
  const [showAssignmentStatusForm, setShowAssignmentStatusForm] = useState<boolean>(false);
  const [showAssignmentDueDateForm, setShowAssignmentDueDateForm] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] = useState<TAssignment | null>(null);
  const [showConfirmShareAssignment, setShowConfirmShareAssignment] = useState<boolean>(false);
  const [offset, setOffset] = useState<number[]>([0.5, 0.5]);

  const pageNumber = useCourseAssignmentsTableStore((state: CourseAssignmentsTableState) => state.pageNumber);
  const setPageNumber = useCourseAssignmentsTableStore((state: CourseAssignmentsTableState) => state.setPageNumber);
  const { isLoading, data: assignments, totalCount, totalPages } = useCourseAssignmentsTableData(course.id, searchTerm);

  const [mutateSet] = useMutateSet(course.id);
  const selectAssignment = useStore((state) => state.selectAssignment);

  // see if user is instructor
  const currentUserIsInstructor = course.instructors?.filter((instructor: any) => currentUser.id === instructor.id);

  const canViewActions = (): boolean => {
    return currentUserIsInstructor || currentUser?.primaryPartner?.meta?.canManageContent;
  };

  const handleMenuClick = (event: React.ChangeEvent<HTMLInputElement>, rowData: TAssignment) => {
    if (showAssignmentMenu) {
      closeActionsMenu();
      return;
    }
    const newOffset = determineMenuOffset(event, 0, -190);
    setOffset(newOffset);
    setShowAssignmentMenu(true);
    setSelectedAssignment(rowData);
    selectAssignment(rowData.id);
  };

  const closeActionsMenu = () => setShowAssignmentMenu(false);

  // toggling privacy
  const isShared = (): boolean => {
    return parseInt(selectedAssignment?.privacyType.id ?? '0', 10) === PRIVACY_TYPES.COURSES_ONLY;
  };

  const toggleAssignmentPrivacy = (): void => {
    if (!selectedAssignment) return;
    const privacyTypeId = isShared() ? PRIVACY_TYPES.PRIVATE_ONLY : PRIVACY_TYPES.COURSES_ONLY;
    mutateSet({
      id: selectedAssignment.id,
      payload: { data: { attributes: { privacy_type_id: privacyTypeId } } },
    });
    setShowConfirmShareAssignment(false);
  };

  const onShare = (): void => {
    if (isShared()) {
      toggleAssignmentPrivacy();
    } else {
      setShowConfirmShareAssignment(true);
    }
  };

  // open & close overlays
  const openOverlay = () => setShowOverlay(true);
  const closeOverlay = () => setShowOverlay(false);

  // handle editing modals
  const handleClickToEdit = (theOffset: number[], assignmentId: string, columnName: string): void => {
    if (!canEditContent) return;
    setOffset(theOffset);
    const foundAssignment = assignments.find((a: TAssignment) => a.id === assignmentId) || null;
    setSelectedAssignment(foundAssignment);
    selectAssignment(assignmentId);

    switch (columnName) {
      case 'levelGoal':
        openOverlay();
        break;
      case 'publishType':
      case 'publishAt':
        setShowAssignmentStatusForm(true);
        break;
      case 'dueAt':
        setShowAssignmentDueDateForm(true);
        break;
      default:
        throw new Error(`Cannot handle click to edit for column: ${columnName}`);
    }
  };

  // define columns
  const columns: ColumnDef<any>[] = [
    {
      id: 'image',
      accessorFn: (row) => row.image,
      header: () => <ColumnHeader title="" />,
      cell: (props: any) => (
        <ImageBadge image={props.row.original.image} entity={props.row.original.goalType} transcodedSize="small" />
      ),
    },
    {
      id: 'name',
      accessorFn: (row) => row.name,
      header: () => <ColumnHeader title={t('Title')} />,
      cell: (props: any) => <ContentTitle assignment={props.row.original} courseId={course.id} />,
    },
    {
      id: 'percentStarted',
      accessorFn: (row) => row.meta.percentStarted,
      header: () => <ColumnHeader title={t('% Class Started')} />,
      cell: (props: any) => <ContentParticipation percent={props.row.original.meta.percentStarted} />,
    },
    {
      id: 'rawDifficulty',
      accessorFn: (row) => row.rawDifficulty,
      header: () => <ColumnHeader title={t('Difficulty')} />,
      cell: (props: any) => {
        const rowData = props.row.original;
        if (rowData.meta.percentStarted === 0 || !rowData.rawDifficulty) {
          return <span style={{ fontStyle: 'italic' }} />;
        }
        return <ItemDifficulty retentionModifier={rowData.rawDifficulty} />;
      },
    },
    {
      id: 'status',
      accessorFn: (row) => row.status,
      header: () => <ColumnHeader title={t('Status')} />,
      cell: (props: any) => (
        <StatusCell assignment={props.row.original} course={course} handleClickToEdit={handleClickToEdit} />
      ),
    },
    {
      id: 'dueAt',
      accessorFn: (row) => row.meta.dueAt,
      header: () => <ColumnHeader title={t('Due Date')} />,
      cell: (props: any) => <DueAtCell assignment={props.row.original} handleClickToEdit={handleClickToEdit} />,
    },
    {
      id: 'levelGoal',
      accessorFn: (row) => row.meta.levelGoal,
      header: () => <ColumnHeader title={t('Goal')} />,
      cell: (props: any) => <GoalCell assignment={props.row.original} handleClickToEdit={handleClickToEdit} />,
    },
    {
      id: 'actions',
      header: () => (
        <span style={{ textAlign: 'center' }}>
          <HeaderText>{t('Actions')}</HeaderText>
        </span>
      ),
      cell: (props) => {
        if (!canViewActions()) return null;
        return (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <MoreVertButton
              click={(event) => handleMenuClick(event, props.row.original)}
              aria-controls="course-assignments-table-menu"
            />
          </span>
        );
      },
    },
  ];

  const table = useReactTable({
    data: assignments,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // pagination logic
  const goToPageOne = () => {
    setPageNumber(1);
  };
  const goToNextPage = () => {
    if (totalPages && pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const goToLastPage = () => {
    if (totalPages) {
      setPageNumber(totalPages);
    }
  };

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={650} />;
  }

  if (!assignments?.length) {
    return (
      <NoAssignmentsContainer>
        <ArticleIcon src={content} style={{ marginTop: '3em' }} />
        <h3>{t('Build your course curriculum')}</h3>
      </NoAssignmentsContainer>
    );
  }

  // handle overlay modals
  if (showOverlay && selectedAssignment) {
    return (
      <Modal
        show={showOverlay}
        element={
          <AssignmentGoalForm
            courseId={course.id}
            assignment={selectedAssignment}
            assignmentGoal={selectedAssignment?.meta?.levelGoal}
            courseAssignmentIds={assignments
              .filter((a: any) => a.goalType !== 'assessment' && a.goalType !== 'survey')
              .map((a: any) => a.id)}
            onCancel={closeOverlay}
          />
        }
      />
    );
  }

  if (showConfirmShareAssignment && selectedAssignment) {
    return (
      <Modal
        show={showConfirmShareAssignment}
        element={
          <ConfirmShareContent
            setName={selectedAssignment?.name}
            onShare={toggleAssignmentPrivacy}
            onCancel={() => setShowConfirmShareAssignment(false)}
          />
        }
      />
    );
  }

  if (showAssignmentStatusForm && selectedAssignment) {
    return (
      <Modal
        show={showAssignmentStatusForm}
        element={
          <AssignmentStatusForm
            assignment={selectedAssignment}
            course={course}
            onCancel={() => setShowAssignmentStatusForm(false)}
          />
        }
      />
    );
  }

  if (showAssignmentDueDateForm && selectedAssignment) {
    return (
      <Modal
        show={showAssignmentDueDateForm}
        element={
          <AssignmentDueDateForm
            assignment={selectedAssignment}
            courseId={course.id}
            onCancel={() => setShowAssignmentDueDateForm(false)}
          />
        }
      />
    );
  }

  return (
    <>
      <DataContainer>
        <DataTable>
          <TableHeader table={table} handleHeaderClick={() => {}} />
          <TableBody table={table} />
        </DataTable>

        <Pagination
          totalCount={totalCount ?? 0}
          page={pageNumber}
          pageSize={10}
          goToPageOne={goToPageOne}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </DataContainer>

      <div id="course-assignments-table-menu">
        {showAssignmentMenu && selectedAssignment && (
          <CourseAssignmentsTableMenu
            isShared={isShared()}
            onShare={onShare}
            handleOutsideClick={closeActionsMenu}
            course={course}
            currentUser={currentUser}
            selectedSet={selectedAssignment}
            left={offset[0] / 16}
            top={offset[1] / 16}
          />
        )}
      </div>
    </>
  );
};

const ToolbarContainer = styled.div`
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
  grid-gap: 0em 1em;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;

const NoAssignmentsContainer = styled.div`
  text-align: center;
  margin-top: 3em;
  color: ${Color.textGray};
`;

const HeaderText = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: Lato;
`;
