import { MemreContainer, MemreFlexBox, MemrePeopleList } from 'components/core';
import { MemreProgressBar } from 'components/visualisations';
import * as Color from 'core/colors';
import { useEffect, useState } from 'react';
import AreaHeading from '../learner-dashboard/visulisations/AreaHeading';
import LearnMore from '../learner-dashboard/visulisations/LearnMore';

const OVERLINE = {
  STUDENTS: 'Memories',
  SETS: 'Memories',
};

export default function SecureMemories({ data, isStudentsView }) {
  const [percentages, setPercentages] = useState([0]);

  useEffect(() => {
    if (!isStudentsView && data[0]?.itemCount) {
      const totals = data.reduce(
        (acc, item) => {
          acc.totalSecure += item.itemCount.secure;
          acc.totalFading += item.itemCount.fading;
          acc.totalItems += item.itemCount.total;
          return acc;
        },
        { totalSecure: 0, totalFading: 0, totalItems: 0 }
      );

      const securePercentage = (totals.totalSecure / totals.totalItems) * 100;
      const fadingPercentage = (totals.totalFading / totals.totalItems) * 100;
      const notSeenPercentage = 100 - securePercentage - fadingPercentage;

      setPercentages(() => [securePercentage, fadingPercentage, notSeenPercentage]);
    } else if (isStudentsView) {
      const totalCount = data.length;
      const totalSecure = data.filter((d) => d.distanceToGoal === 100 && d.goodForNow >= 85).length || 0;
      const totalFading =
        data.filter((d) => d.distanceToGoal !== 100 && d.distanceToGoal !== 0 && d.goodForNow <= 85).length || 0;

      const securePercentage = (totalSecure / totalCount) * 100;
      const fadingPercentage = (totalFading / totalCount) * 100;
      const notSeenPercentage = 100 - securePercentage - fadingPercentage;
      setPercentages(() => [securePercentage, fadingPercentage, notSeenPercentage]);
    }
  }, [data, isStudentsView]);

  const overline = isStudentsView ? OVERLINE.STUDENTS : OVERLINE.SETS;
  const headingSections = ['Secure', 'Fading', 'Not Seen'];
  const heading = (
    <>
      {percentages.map((n, idx) => (
        <span key={idx}>
          {n.toFixed()}
          <span style={{ fontSize: '0.8em', paddingRight: 4, opacity: 0.7 }}>% {headingSections[idx]}</span>
        </span>
      ))}
    </>
  );

  return (
    <MemreContainer
      size="large"
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      sx={{
        borderRight: `1px solid ${Color.veryLightGray}`,
      }}
    >
      <MemreFlexBox direction="column" gap={2} sx={{ flexGrow: 1 }}>
        <MemreFlexBox justify="space-between" align="center">
          <AreaHeading overline={overline} heading={heading} />
          <LearnMore width={500}>
            <AreaHeading overline={overline} heading={heading} />
            <MemrePeopleList
              data={data}
              denomination={isStudentsView ? data[0]?.nextAtDenom : '%'}
              fields={isStudentsView ? ['nextAt'] : ['itemCount.securePercent', 'itemCount.fadingPercent']}
              fieldNames={isStudentsView ? ['Next Review In'] : ['secure', 'fading']}
              topNumber={100}
            />
          </LearnMore>
        </MemreFlexBox>
        <MemreContainer sx={{ gap: 2, pr: '5px', justifyContent: 'space-between' }}>
          <MemreProgressBar data={percentages} />
        </MemreContainer>
      </MemreFlexBox>
    </MemreContainer>
  );
}
