import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import axios from 'axios';
import { ModalPosition } from 'components/Modal';
import { ModalHeader } from 'components/modals/common';
import * as Size from 'core/size';
import { useCurrentUser } from 'hooks/useCurrentUser';
import React, { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isGovcloud } from 'utils/environmentUtils';
import { getErrorStringFromAPIError } from 'utils/errorUtils';
import { ManualCreateForm } from 'views/course-create/ManualCreateForm';
import { SmartCreate } from 'views/course-create/smart-create/SmartCreate';

interface ICourseCreate {
  onCancel: () => void;
  onSmartCreate: () => void;
}

export const CourseCreate = (props: ICourseCreate) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const ceregoImportFile: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const creatingManualCourse: boolean = currentTab === 0;
  const creatingSmartCourse: boolean = currentTab === 1;

  const canImport = user?.primaryPartner.partnerSettings.allowContentImportExport;

  const handleTabChange = (_event: React.ChangeEvent<{}>, tabSelection: number) => {
    setCurrentTab(tabSelection);

    const importingCourse = tabSelection === 2;
    // Not the best UX, but simply clicking the tab will open the selector dialog and do the import for now
    if (importingCourse) {
      ceregoImportFile.current?.click(); // Trigger file input selector
    }
  };

  const onSelectedCeregoImportFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (loadEvent) => {
      const text = loadEvent?.target?.result;
      if (text) {
        try {
          await axios.post(`/api/v3/courses/import`, { import_data_string: text, partner_id: user?.primaryPartner.id });
        } catch (error) {
          alert(`Error importing: ${getErrorStringFromAPIError(error)}`);
        }
      }
      window.location.reload();
    };

    if (event.target.files && event.target.files[0]) {
      reader.readAsText(event.target.files[0]);
    } else {
      alert('Error importing: No file selected');
    }
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const handleSmartCreate = () => {
    props.onSmartCreate();
  };

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label={t('Manual Create')} />
          <Tab label={t('Smart Create')} />
          {!isGovcloud() && canImport && <Tab label={t('Import .cerego file')} />}
        </Tabs>
      </ModalHeader>

      <div style={{ width: Size.defaultCardWidth }}>
        {creatingManualCourse && <ManualCreateForm handleCancel={handleCancel} />}
        {creatingSmartCourse && <SmartCreate handleCancel={handleCancel} handleSmartCreate={handleSmartCreate} />}
      </div>

      <input
        type="file"
        id="ceregoImportFile"
        ref={ceregoImportFile}
        style={{ display: 'none' }}
        onChange={onSelectedCeregoImportFile}
        accept=".cerego"
      />
    </ModalPosition>
  );
};
