import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getOverage = async (key: string, partnerId: string) => {
  const response = await axios.get(`/api/v4/partners/${partnerId}/license_quantity_overages`);
  const normalized = normalizeJSONAPIResponse(response.data);
  return normalized.length > 0 ? normalized[0] : false;
};

const useOverage = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;
  const [enabled, setEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (partner) {
      const isSelfServe: boolean = partner.isSelfServe;
      const isPartnerAdmin: boolean = partner.meta.role === 'admin';
      setEnabled(isSelfServe && isPartnerAdmin);
    }
  }, [partner]);

  return useQuery(['overage', partner?.id], getOverage, {
    enabled: partner?.id && enabled,
    onSettled: () => {
      setEnabled(false);
    }
  });
};

export default useOverage;
