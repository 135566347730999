import { LoadingScreen } from 'components/LoadingScreen';
import InstructionalContent from 'learn/components/InstructionalContent';
import LearnHeader from 'learn/components/LearnHeader';
import QuizPage from 'learn/components/QuizPage';
import RetrySummaryPage from 'learn/components/RetrySummaryPage';
import { handleAdvancePresentationScreen, handleCreatePresentation } from 'learn/store/presentation';
import { handleTimerStart } from 'learn/store/timer';
import * as _ from 'lodash';
import { IAPIModel } from 'models/apiModel';
import { IInstructionalContent } from 'models/instructionalContent';
import { QuizResult } from 'models/presentation';
import { IQuiz, IQuizPart } from 'models/quiz';
import { ISet } from 'models/set';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { AnyAction } from 'redux';
import build from 'redux-object';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/index';
import { handleGetSet } from 'store/set';
import { handleGetSetStudyItems, IStudyType } from 'store/study-item';
import styled from 'styled-components';
import { AnalyticsService } from 'utils/AnalyticsService';
import { postMessageEndSession, postMessageExitLearnApp } from 'utils/iframeMessageUtils';
import { locationState, queryParams, redirectToV3 } from 'utils/pathUtils';

interface IOwnProps extends RouteProps, WithTranslation {
  match: { params: { id: string }; url: string };
  set: ISet;
  quizParts?: { [key: number]: IAPIModel };
  quizzes?: [IQuiz];
  images?: { [key: number]: IAPIModel };
  error?: any;
  handleAdvancePresentationScreen: (goalType: string) => Promise<any>;
  handleCreatePresentation: (
    quiz: IQuiz | IInstructionalContent,
    answers: IQuizPart[],
    enteredText: string,
    goalType: string,
    quizResult?: QuizResult,
    ratingValue?: number
  ) => Promise<void>;
  instructionalItems?: [IInstructionalContent];
  itemStudyItemsMeta?: any;
  setStudyItemsMeta?: any;
  handleGetSetStudyItems: (id: string, filter: IStudyType) => { [action: string]: { endpoint: string } };
  handleGetSet: (id: string) => { [action: string]: { endpoint: string } };
  handleTimerStart: () => ThunkAction<void, AppState, undefined, AnyAction>;
}

interface IOwnState extends AppState {
  initialized: boolean;
  itemsToShow: Array<IInstructionalContent | IQuiz>;
  itemsToRetry: Array<IInstructionalContent | IQuiz>;
  isRetry: boolean;
  presentationPromise: any;
  reshownItemIds: string[];
  showAnswers?: boolean;
  showReviewSummaryPage: boolean;
  toUserSessionJourney: boolean;
  itemIndex: number;
}

// TODO: get design feedback
const ErrorMessage = styled.h4`
  color: #f1622e;
  font-size: 14px;
  margin: 0px auto;
  margin-top: 25px;
  text-align: center;
`;

class Learn extends React.Component<IOwnProps, IOwnState> {
  public isPreview = !!queryParams(this.props.location).preview;
  public isLTI = !!queryParams(this.props.location).lti || !!locationState(this.props.location).lti;
  public returnUrl = queryParams(this.props.location).returnUrl || locationState(this.props.location).returnUrl;
  private didRecordAnalytics: boolean = false;
  private presentationPromise: any;
  private totalItemCount: number = 0;
  private isAssessment: boolean = false;
  private goalType: string = 'set';
  private throttledTimerStart: any;
  private mainFocusRef: any = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showAnswers: this.isPreview,
      reshownItemIds: [],
      itemsToRetry: [],
      itemsToShow: [],
      toUserSessionJourney: false,
      itemIndex: 0,
    };

    this.exit = this.exit.bind(this);
    this.getNextItem = this.getNextItem.bind(this);
    this.nextScreen = this.nextScreen.bind(this);
    this.retryClicked = this.retryClicked.bind(this);
  }

  public async componentDidMount() {
    const setId = this.props.match.params.id;
    let getStudyItems;

    const studyType = queryParams(this.props.location).studyType;
    getStudyItems = () => this.props.handleGetSetStudyItems(setId, studyType);

    await Promise.all([this.props.handleGetSet(setId), getStudyItems()]);
    const meta = this.props.setStudyItemsMeta || this.props.itemStudyItemsMeta;
    if (meta == null) {
      return;
    }
    const itemsToShow = this.orderedList(this.props.quizzes, this.props.instructionalItems || [], meta);
    this.totalItemCount = itemsToShow.length;
    this.goalType = this.props.set.goalType;

    let itemIndex = 0;
    if (queryParams(this.props.location).forceItem) {
      const guid = queryParams(this.props.location).forceItem;
      itemIndex = _.findIndex(itemsToShow, ['memoryGuid', guid]);
      this.setState({ itemIndex });
    }

    this.setState({ itemsToShow, initialized: true });
    this.setSeleniumProperties(itemsToShow[itemIndex]);
    this.initializeTimer();
  }

  public componentWillUnmount(): void {
    this.deinitializeTimer();
    window.removeEventListener('message', this.handleMessageReceived);
  }

  public async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.initialized &&
      prevState !== this.state &&
      this.state.itemsToShow.length === 0 &&
      this.state.itemsToRetry.length === 0 &&
      !this.state.toUserSessionJourney
    ) {
      this.exit();
    }
  }
  componentWillMount = () => {
    // listen for messages from the angular app inside the iframe
    window.addEventListener('message', this.handleMessageReceived, true);
  };

  handleMessageReceived = (event) => {
    if (event.data === 'skipToMain') {
      this.mainFocusRef.current?.focus();
    }
  };

  public render() {
    const { set, t } = this.props;
    const { itemsToShow, itemsToRetry, isRetry, showReviewSummaryPage, itemIndex } = this.state;
    const totalRetryCount = itemsToRetry.length;
    const items = isRetry ? itemsToRetry : itemsToShow;
    const totalCount = isRetry ? totalRetryCount : this.totalItemCount;
    const progressCount = Math.min(totalCount - items.length + 1, totalCount);
    const currentItem = items[itemIndex];

    if (this.state.toUserSessionJourney) {
      const to = {
        pathname: `/sets/${this.props.match.params.id}/journey`,
        state: { returnUrl: this.returnUrl, justStudied: true, lti: this.isLTI },
      };
      return <Redirect to={to} />;
    }

    var title = t('Learn');
    if (set?.name) {
      title += ' | ' + set.name;
    }
    document.title = title + ' | Cerego';

    return (
      <div className="App">
        <LearnHeader
          set={set}
          totalItemCount={totalCount}
          progressCount={progressCount}
          isPreview={this.isPreview}
          showAnswers={this.state.showAnswers}
          showExit={this.isPreview || !(this.isLTI || this.goalType === 'assessment')}
          toggleShowAnswers={this.toggleShowAnswers}
          exit={this.exit}
          itemIndex={itemIndex}
          decrementItemIndex={this.decrementItemIndex}
          incrementItemIndex={this.incrementItemIndex}
          goalType={this.goalType}
        />
        <div ref={this.mainFocusRef}>
          {showReviewSummaryPage && (
            <RetrySummaryPage
              open={showReviewSummaryPage}
              retryClicked={this.retryClicked}
              totalCount={this.totalItemCount}
              totalCorrect={this.totalItemCount - totalRetryCount}
            />
          )}
          {!showReviewSummaryPage && currentItem && currentItem.quizType !== 'InstructionalStudy' && (
            <QuizPage
              goalType={this.goalType}
              isPreview={this.isPreview}
              key={
                (currentItem.displayKey || currentItem.id) +
                this.state.showAnswers /* Reload on change to showAnswers */
              }
              quiz={currentItem as IQuiz}
              showAnswers={this.state.showAnswers && this.goalType !== 'survey'}
              nextScreen={this.nextScreen}
              itemIndex={itemIndex}
              totalItemCount={totalCount}
              incrementItemIndex={this.incrementItemIndex}
              setItemIndexToItemWithId={this.setItemIndexToItemWithId}
              getNextItem={this.getNextItem}
              exit={this.exit}
              partner={set.creator}
            />
          )}
          {!showReviewSummaryPage && currentItem?.quizType === 'InstructionalStudy' && (
            <InstructionalContent
              key={currentItem.displayKey || currentItem.id}
              instructionalContent={currentItem as IInstructionalContent}
              isPreview={this.isPreview}
              goalType={this.goalType}
              itemIndex={itemIndex}
              totalItemCount={totalCount}
              incrementItemIndex={this.incrementItemIndex}
              getNextItem={this.getNextItem}
              exit={this.exit}
            />
          )}
          {items.length === 0 && !showReviewSummaryPage && <LoadingScreen />}
          {this.props.error && (
            <div>
              <ErrorMessage>{t('Oops! Something went wrong. Please try again.')}</ErrorMessage>
            </div>
          )}
        </div>
      </div>
    );
  }

  private initializeTimer() {
    this.props.handleTimerStart();

    this.throttledTimerStart = _.throttle(this.props.handleTimerStart, 1000, { leading: true, trailing: false });
    document.addEventListener('mousemove', this.throttledTimerStart, false);
    document.addEventListener('touchstart', this.throttledTimerStart, false);
    document.addEventListener('click', this.throttledTimerStart, false);
    document.addEventListener('keyup', this.throttledTimerStart, false);
  }

  private deinitializeTimer() {
    document.removeEventListener('mousemove', this.throttledTimerStart, false);
    document.removeEventListener('touchstart', this.throttledTimerStart, false);
    document.removeEventListener('click', this.throttledTimerStart, false);
    document.removeEventListener('keyup', this.throttledTimerStart, false);
  }

  private orderedList = (quizzes, instructionalContent, ordered) => {
    const orderedItems: Array<IInstructionalContent | IQuiz> = [];
    ordered.forEach((item) => {
      const nextItem = _.find(instructionalContent, ['id', item.id]) || _.find(quizzes, ['id', item.id]);
      if (nextItem) {
        orderedItems.push(nextItem);
      }
    });
    return orderedItems;
  };

  private retryClicked = () => {
    this.setState({ showReviewSummaryPage: false, isRetry: true });
  };

  private toggleShowAnswers = () => {
    this.setState((prevState) => ({
      showAnswers: !prevState.showAnswers,
    }));
  };

  // write ids of correct quiz parts to the window for selenium tests to map to data attributes
  private setSeleniumProperties = (item: IQuiz | IInstructionalContent) => {
    if (!item) {
      return;
    }
    let parts: any = item.parts;
    if (item.quizType === 'Sequence') {
      parts = _.orderBy(parts, 'correctPosition');
    }
    const correctIds = parts
      .filter((p) => p.isCorrect || item.quizType === 'FillInTheBlank' || item.quizType === 'Sequence')
      .map((p) => p.testId);
    (window as any).seleniumQuizPartIds = correctIds;
  };

  private exit = async () => {
    if (!this.isPreview) {
      await this.presentationPromise;
    }
    postMessageEndSession();
    if (this.isPreview) {
      redirectToV3(this.returnUrl);
      postMessageExitLearnApp();
    } else {
      this.setState({ toUserSessionJourney: true });
    }
  };

  private getNextItem = async (
    answers: IQuizPart[],
    enteredText: string,
    quizResult?: QuizResult,
    ratingValue?: number,
    instructionalContentToReview?: IInstructionalContent
  ) => {
    this.logPresentations(
      this.state.itemsToShow[0] ?? this.state.itemsToRetry[0],
      answers,
      enteredText,
      quizResult,
      ratingValue
    );
    this.recordAnalyticsIfNeeded();

    this.setState(this.shiftLastItem(quizResult, instructionalContentToReview), () => {
      this.setSeleniumProperties(this.state.isRetry ? this.state.itemsToRetry[0] : this.state.itemsToShow[0]);
    });
  };

  private logPresentations(quiz, answers, enteredText, quizResult, ratingValue) {
    if (!this.isPreview) {
      // non-blocking, create presentations in the background
      this.presentationPromise = this.props.handleCreatePresentation(
        quiz,
        answers,
        enteredText,
        this.goalType,
        quizResult,
        ratingValue
      );
    }
  }

  private setItemIndexToItemWithId = (itemId: string) => {
    const itemIds = this.state.itemsToShow.map((item) => item.id);
    const indexOfItem = itemIds.indexOf(itemId);
    if (indexOfItem >= 0) {
      this.setState({ itemIndex: indexOfItem });
    }
  };

  private incrementItemIndex = () => {
    this.setState((prevState) => ({ itemIndex: Math.min(this.state.itemsToShow.length - 1, prevState.itemIndex + 1) }));
  };

  private decrementItemIndex = () => {
    this.setState((prevState) => ({ itemIndex: Math.max(0, prevState.itemIndex - 1) }));
  };

  private isSurveyOrAssessment(): boolean {
    return ['survey', 'assessment'].includes(this.goalType);
  }

  private shiftLastItem(quizResult, instructionalContentToReview) {
    return (state) => {
      const itemsToShow = [...state.itemsToShow];
      const itemsToRetry = [...state.itemsToRetry];
      const reshownItemIds = [...state.reshownItemIds];
      const { isRetry } = state;
      const first = itemsToShow[0] || itemsToRetry[0];
      const items = isRetry ? itemsToRetry : itemsToShow;

      let displayKey = first.id;

      // if the item is a quiz and they get it wrong, attach to the back once to show again later.
      // if the item is IC (i.e. has no quiz type), don't show it later.
      const isCorrect = quizResult !== 'Incorrect' && quizResult !== 'AlmostCorrect';
      if (
        !isCorrect &&
        (first as IQuiz).quizType &&
        reshownItemIds.indexOf(first.id) === -1 &&
        !this.isSurveyOrAssessment()
      ) {
        itemsToRetry.push(first);
        reshownItemIds.push(first.id);
        // make displayKey unique so the quiz is re-rendered when it's redisplayed
        displayKey += '1';
        (first as IQuiz).displayKey = displayKey;
      }

      items.splice(0, 1);

      // user clicked "review instructional content"
      if (instructionalContentToReview) {
        items.splice(0, 0, instructionalContentToReview);
      }

      const showReviewSummaryPage =
        itemsToShow.length === 0 && itemsToRetry.length > 0 && !isRetry && !this.isSurveyOrAssessment();

      return { itemsToShow, reshownItemIds, itemsToRetry, showReviewSummaryPage };
    };
  }

  private nextScreen = () => {
    this.props.handleAdvancePresentationScreen(this.props.set.goalType);
  };

  private recordAnalyticsIfNeeded = () => {
    if (this.didRecordAnalytics || this.isPreview) {
      return;
    }
    AnalyticsService.getInstance().track('first_quiz_of_study_session');
    this.didRecordAnalytics = true;
  };
}

const mapStateToProps = (state, props) => {
  const forceItem = queryParams(props.location).forceItem;

  return {
    set: build(state.data, 'sets', props.match.params.id),
    quizzes:
      build(state.data, 'quizzes', null, { includeType: true }) &&
      build(state.data, 'quizzes', null, { includeType: true }).filter((quiz) => {
        // strip -1 and -0 so we don't put them in the DOM
        quiz.parts.map((part) => {
          part.testId = part.id.replace(/-\d$/g, '');
          if (quiz.quizType === 'FillInTheBlank') {
            // FITB answer test id is the text because it needs to indicate correct answer
            part.testId = part.text;
          }
          return part;
        });
        return quiz;
      }),
    error: state.error,
    instructionalItems: build(state.data, 'instructionalItems'),
    itemStudyItemsMeta:
      state.data.meta &&
      state.data.meta[`/api/v4/items/${forceItem}/study_items`] &&
      state.data.meta[`/api/v4/items/${forceItem}/study_items`].data,
    setStudyItemsMeta:
      state.data.meta &&
      state.data.meta[`/api/v4/sets/${props.match.params.id}/study_items`] &&
      state.data.meta[`/api/v4/sets/${props.match.params.id}/study_items`].data,
  };
};

const mapDispatchToProps = {
  handleGetSetStudyItems,
  handleGetSet,
  handleTimerStart,
  handleCreatePresentation,
  handleAdvancePresentationScreen,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Learn));
