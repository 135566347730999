import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';
import { MemreFlexBox, MemreText, MemreButton } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { OAuthClient, OAuthType } from 'types';

type OAuthClientFormProps = {
  initialData?: Partial<OAuthClient>;
  onCancel: () => void;
  onSubmit: (data: OAuthClient) => void;
  isEditing?: boolean;
};

export const OAuthClientForm: React.FC<OAuthClientFormProps> = ({
  initialData = {},
  onCancel,
  onSubmit,
  isEditing = false,
}) => {
  const [formType, setFormType] = useState<OAuthType>('LTI 1.1/1.2/SAML');
  const [clientName, setClientName] = useState(initialData.name || '');
  const [clientWebsite, setClientWebsite] = useState(initialData.website || '');
  const [clientRedirectUri, setClientRedirectUri] = useState(initialData.redirectUri || '');
  const [clientAccessTokenUrl, setClientAccessTokenUrl] = useState(initialData.accessTokenUrl || '');
  const [clientPublicJwkUrl, setClientPublicJwkUrl] = useState<string | undefined>(initialData.publicJwkUrl || '');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const user = useCurrentUser();

  useEffect(() => {
    if (initialData.type) {
      setFormType(initialData.type);
    }
    if (initialData.clientType) {
      setFormType(initialData.clientType === 'lti_13' ? 'LTI 1.3' : 'LTI 1.1/1.2/SAML');
    }
  }, [initialData]);

  const validate = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    if (!clientName.trim()) {
      newErrors.clientName = 'Client name is required';
    }
    if (formType === 'LTI 1.3') {
      if (!clientWebsite.trim()) {
        newErrors.clientWebsite = 'Client website is required';
      }
      if (!clientRedirectUri.trim()) {
        newErrors.clientRedirectUri = 'Client redirect URI is required';
      }
      if (!clientAccessTokenUrl.trim()) {
        newErrors.clientAccessTokenUrl = 'Access token URL is required';
      }
      if (!clientPublicJwkUrl?.trim()) {
        newErrors.clientPublicJwkUrl = 'Public JWK URL is required';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    onSubmit({
      id: user?.primaryPartner.slug,
      type: formType,
      clientType: formType === 'LTI 1.3' ? 'lti_13' : 'lti_12',
      name: clientName.trim(),
      website: clientWebsite.trim(),
      redirectUri: clientRedirectUri.trim(),
      accessTokenUrl: clientAccessTokenUrl.trim(),
      publicJwkUrl: clientPublicJwkUrl?.trim() || null,
    });
  };

  return (
    <Box sx={{ marginTop: '20px', border: '1px solid #ddd', padding: '20px', borderRadius: '8px' }}>
      <MemreText variant="h6" sx={{ mb: 2 }}>
        {isEditing ? 'Edit OAuth Client' : 'New OAuth Client'}
      </MemreText>

      <MemreFlexBox direction="column" gap={3}>
        <FormControl fullWidth>
          <InputLabel color="secondary" id="oauth-type-label">
            OAuth Type
          </InputLabel>
          <Select
            labelId="oauth-type-label"
            value={formType}
            label="OAuth Type"
            onChange={(e) => {
              setFormType(e.target.value as OAuthType);
              // Clear errors when type changes
              setErrors({});
            }}
            color="secondary"
            size="small"
          >
            <MenuItem value="LTI 1.1/1.2/SAML">LTI 1.1/1.2/SAML</MenuItem>
            <MenuItem value="LTI 1.3">LTI 1.3</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Client Name"
          fullWidth
          value={clientName}
          onChange={(e) => {
            setClientName(e.target.value);
            setErrors((prev) => ({ ...prev, clientName: '' }));
          }}
          variant="outlined"
          color="secondary"
          size="small"
          error={Boolean(errors.clientName)}
          helperText={errors.clientName}
        />

        {formType === 'LTI 1.3' && (
          <MemreFlexBox direction="column" gap={2}>
            <MemreText variant="subtitle1">LTI 1.3 Client Details</MemreText>

            <TextField
              label="Client Website"
              fullWidth
              value={clientWebsite}
              onChange={(e) => {
                setClientWebsite(e.target.value);
                setErrors((prev) => ({ ...prev, clientWebsite: '' }));
              }}
              variant="outlined"
              color="secondary"
              size="small"
              error={Boolean(errors.clientWebsite)}
              helperText={errors.clientWebsite}
            />

            <TextField
              label="Client Redirect URI"
              fullWidth
              value={clientRedirectUri}
              onChange={(e) => {
                setClientRedirectUri(e.target.value);
                setErrors((prev) => ({ ...prev, clientRedirectUri: '' }));
              }}
              variant="outlined"
              color="secondary"
              size="small"
              error={Boolean(errors.clientRedirectUri)}
              helperText={errors.clientRedirectUri}
            />

            <TextField
              label="Client Access Token URL"
              fullWidth
              value={clientAccessTokenUrl}
              onChange={(e) => {
                setClientAccessTokenUrl(e.target.value);
                setErrors((prev) => ({ ...prev, clientAccessTokenUrl: '' }));
              }}
              variant="outlined"
              color="secondary"
              size="small"
              error={Boolean(errors.clientAccessTokenUrl)}
              helperText={errors.clientAccessTokenUrl}
            />

            <TextField
              label="Client Public JWK URL"
              fullWidth
              value={clientPublicJwkUrl}
              onChange={(e) => {
                setClientPublicJwkUrl(e.target.value);
                setErrors((prev) => ({ ...prev, clientPublicJwkUrl: '' }));
              }}
              variant="outlined"
              color="secondary"
              size="small"
              error={Boolean(errors.clientPublicJwkUrl)}
              helperText={errors.clientPublicJwkUrl}
            />
          </MemreFlexBox>
        )}

        {/* Action Buttons */}
        <MemreFlexBox direction="row" gap={2} justify="flex-end" sx={{ marginTop: '20px' }}>
          <MemreButton text="Cancel" variant="outlined" color="secondary" onClick={onCancel} size="medium" />
          <MemreButton
            text={isEditing ? 'Update' : 'Create'}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            size="medium"
          />
        </MemreFlexBox>
      </MemreFlexBox>
    </Box>
  );
};
