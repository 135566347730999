import * as React from 'react';
import styled from 'styled-components';

import { Color } from 'core';

type TitleTextProps = {
  text: string;
  name?: 'card-title' | 'card-paragraph' | 'card-body' | 'error-message' | 'success-message';
  style?: any;
};

const BaseText = styled.h3`
  font-size: 0.8rem;
  color: ${Color.lightBlack};
`;

const CardParagraph = styled(BaseText)`
  font-size: 1.25em;
  color: ${Color.primaryBlack};
`;

const ErrorMessage = styled(CardParagraph)`
  color: ${Color.red};
`;

const SuccessMessage = styled(CardParagraph)`
  color: ${Color.lightGreen};
`;
const CardTitle = styled(BaseText)`
  line-height: 2em;
  font-weight: 900;
`;

const CardBody = styled(BaseText)``;

export class TitleText extends React.Component<TitleTextProps> {
  public render() {
    const { name, text, style } = this.props;
    switch (name) {
      case 'card-title':
        return <CardTitle style={style}>{text}</CardTitle>;
      case 'card-body':
        return <CardBody style={style}>{text}</CardBody>;
      case 'error-message':
        return <ErrorMessage style={style}>{text}</ErrorMessage>;
      case 'success-message':
        return <SuccessMessage style={style}>{text}</SuccessMessage>;
      default:
        return <CardParagraph style={style}>{text}</CardParagraph>;
    }
  }
}
