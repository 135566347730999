import { UpdatePartnerUserTagTypePayload, useMutatePartnerUserTagType } from 'hooks/useMutatePartnerUserTagType';
import { debounce } from 'lodash';
import useStore from 'zstore';

export const useDebouncedPartnerUserTagTypeUpdate = (debounceMs: number) => {
  const [mutatePartnerUserTagType] = useMutatePartnerUserTagType();
  const { makeToast } = useStore();

  return debounce(async (payload: UpdatePartnerUserTagTypePayload) => {
    try {
      mutatePartnerUserTagType(payload);
      makeToast({
        text: 'Changes saved successfully!',
        isError: false,
      });
    } catch (error: any) {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    }
  }, debounceMs);
};
