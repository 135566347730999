import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { InvoiceHistory } from 'admin/components/InvoiceHistory';
import billingIcon from 'assets/images/billing-icon.svg';
import axios from 'axios';
import { PrimaryButton } from 'components/buttons/v4';
import { Modal } from 'components/Modal';
import { UpdateLicensing } from 'components/modals/UpdateLicensing';
import { Color } from 'core';
import {
  Card,
  CardHeaderContainer,
  ContentSection,
  PageContainer,
  PageSubTitle,
  PageTitle,
  TitleSection,
} from 'core/layout';
import { MINIMUM_LICENSE_QUANTITY, STRIPE_KEY } from 'models/account';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import build from 'redux-object';
import { handleGetInvoices } from 'store/invoice';
import { handleGetPaymentMethod } from 'store/payment-method';
import { getCurrentUser } from 'store/profile';
import styled from 'styled-components';
import { getBaseReactUrl, queryParams } from 'utils/pathUtils';
import useStore from 'zstore';

const stripePromise = loadStripe(STRIPE_KEY);

const TitleContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

const LicensingTitle = styled.span`
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
`;

const RightContainer = styled.span`
  display: flex;
`;

const BillingCardHeaderContainer = styled(CardHeaderContainer)`
  display: flex;
  justify-content: space-between;
`;

const SeatDescriptionLabel = styled.p`
  font-size: 12px;
  font-style: italic;
  margin-top: auto;
  margin-bottom: auto;
`;

const SeatCountLabel = styled.h2`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
`;

const SeatsContainer = styled.span`
  display: flex;
  margin-right: 1em;
`;

const BottomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const CardOutline = styled.div`
  background: ${Color.slateGray};
  color: ${Color.white};
  border-radius: 0.5em;
  width: 10em;
  height: 2em;
  padding: 5em 1em 1em 1em;
`;

const CardOutlineText = styled.div`
  font-size: 0.85em;
  text-align: right;
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const BillingIcon = styled.img`
  height: 50px;
  width: 40px;
  margin-right: 0.75rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 0.75em;
`;

const UpdateLink = styled.a`
  color: ${Color.primaryBlue};
  cursor: pointer;
  text-decoration: underline;
`;

export const BillingDetails = () => {
  // TODO: we should 404 or redirect to dashboard if the user doesn't have a paid Stripe account and tries to view this page
  // We have a ticket for this: CP-2003
  const currentUser = useSelector((state) => getCurrentUser(state)) || null;
  const stripe = useStripe();

  const location = useLocation();
  const paymentUpdateStatus = queryParams(useLocation()).paymentUpdateStatus;
  const [showUpdateLicensing, setShowUpdateLicensing] = useState(false);
  const dispatch = useDispatch();
  const paymentMethod = useSelector(getPaymentMethod);
  const invoices = useSelector(getRecentInvoices);
  const { t } = useTranslation();
  const { makeToast } = useStore();

  useEffect(() => {
    dispatch(handleGetPaymentMethod(currentUser.primaryPartner.id));
    dispatch(handleGetInvoices(currentUser.primaryPartner.id));
  }, [currentUser.primaryPartner.id]);

  useEffect(() => {
    if (paymentUpdateStatus === 'success') {
      makeToast({
        text: t('Billing information was updated successfully'),
        isError: false,
      });
    }
  }, [paymentUpdateStatus]);

  const handleUpdateClick = async () => {
    const data = {
      successUrl: `${getBaseReactUrl()}/app${location.pathname}?paymentUpdateStatus=success`,
      cancelUrl: `${getBaseReactUrl()}/app${location.pathname}?paymentUpdateStatus=cancel`,
      id: currentUser.primaryPartner.id,
    };
    const stripeSessionResult = await axios.put(`/api/v4/stripe/checkouts/${currentUser?.primaryPartner.id}`, data);
    if (!stripe) {
      throw new Error('Cannot redirect to checkout with null stripe object');
    }

    await stripe.redirectToCheckout({
      sessionId: stripeSessionResult.data.sessionId,
    });
  };
  function getRecentInvoices(state: any) {
    const invoices = build(state.data, 'invoices') || [];
    return invoices.sort((a: any, b: any) => (a.periodEnd > b.periodEnd ? 1 : -1)).slice(0, 12);
  }

  function getPaymentMethod(state: any) {
    const rawCards = state.data.meta[`/api/v4/partners/${currentUser.primaryPartner.id}/payment_methods`]?.data || [];
    const ids = rawCards.map((card: any) => card.id);
    const cards = build(state.data, 'cards') || [];
    return cards.filter((card: any) => ids.includes(card.id))[0] || {};
  }

  const { last4 = 'XXXX', expMonth = '00', expYear = '00', name, postalCode } = paymentMethod;

  function formattedExpirationDate() {
    const month = expMonth.toString().length < 2 ? `0${expMonth}` : expMonth;
    const year = expYear.toString().slice(-2);
    return `${month}/${year}`;
  }

  const { usersCount: occupiedSeats = 0, account, id: partnerId } = currentUser?.primaryPartner;
  const { licenseQuantity: purchasedSeats = 0, plan = {} } = account || {};
  const { price: pricePerSeat = 0, interval = 'monthly', minUsers = MINIMUM_LICENSE_QUANTITY } = plan;

  if (showUpdateLicensing) {
    return (
      <Modal
        show={showUpdateLicensing}
        element={
          <UpdateLicensing
            purchasedSeats={purchasedSeats}
            occupiedSeats={occupiedSeats}
            partnerId={partnerId}
            interval={interval}
            pricePerSeatInPennies={pricePerSeat}
            minUsers={minUsers}
            onCancel={() => setShowUpdateLicensing(false)}
          />
        }
      />
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <PageContainer>
        <TitleSection>
          <TitleContainer>
            <HeaderContainer>
              <BillingIcon src={billingIcon} />
              <PageTitle style={{ margin: '0' }}>{t('Billing')}</PageTitle>
            </HeaderContainer>
            <PageSubTitle>{t('Configure payment processing and licensing')}</PageSubTitle>
          </TitleContainer>
        </TitleSection>
        <ContentSection>
          <Card>
            <BillingCardHeaderContainer>
              <LicensingTitle>{t('Licensing')}</LicensingTitle>
              <RightContainer>
                <SeatsContainer>
                  <SeatDescriptionLabel>{t('Seats Purchased')}</SeatDescriptionLabel>
                  <SeatCountLabel>{currentUser?.primaryPartner?.account?.licenseQuantity}</SeatCountLabel>
                </SeatsContainer>
                <SeatsContainer>
                  <SeatDescriptionLabel>{t('Seats Used')}</SeatDescriptionLabel>
                  <SeatCountLabel>{currentUser?.primaryPartner?.usersCount}</SeatCountLabel>
                </SeatsContainer>
                <PrimaryButton size="small" shape="pill" click={() => setShowUpdateLicensing(true)}>
                  {t('Update')}
                </PrimaryButton>
              </RightContainer>
            </BillingCardHeaderContainer>
          </Card>
          <BottomGrid>
            <div>
              <h4>{t('Payment Method')}</h4>
              <DetailsGrid>
                <CardOutline>
                  <CardOutlineText>XXXX-XXXX-XXXX-{last4}</CardOutlineText>
                  <CardOutlineText>{formattedExpirationDate()}</CardOutlineText>
                </CardOutline>
                <div>
                  <div>{name}</div>
                  {postalCode && <div>{postalCode}</div>}
                  <div>
                    <UpdateLink onClick={handleUpdateClick}>{t('Update')}</UpdateLink>
                  </div>
                </div>
              </DetailsGrid>
            </div>
            <InvoiceHistory invoices={invoices} />
          </BottomGrid>
        </ContentSection>
        <Modal
          show={showUpdateLicensing}
          element={
            <UpdateLicensing
              purchasedSeats={purchasedSeats}
              occupiedSeats={occupiedSeats}
              partnerId={partnerId}
              interval={interval}
              pricePerSeatInPennies={pricePerSeat}
              minUsers={minUsers}
              onCancel={() => setShowUpdateLicensing(false)}
            />
          }
        />
      </PageContainer>
    </Elements>
  );
};
