import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

const deleteItem = (id: string) => {
  return axios.delete(`/api/v3/items/${id}`);
};

export const useDeleteItem = (itemId: string = '', setId: string = '') => {
  const { makeToast } = useStore();

  return useMutation(deleteItem, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
      makeToast({
        text: 'Failed to delete item',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
  });
};
