import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDepartmentsStore } from 'pages/admin-dashboard/departments/store';
import { queryCache, useMutation } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import useStore from 'zstore';

interface CreateDepartmentParams {
  partnerId: string;
  name: string;
}

const createDepartment = async ({ partnerId, name }: CreateDepartmentParams) => {
  const response = await axios.post(`/api/v3/partners/${partnerId}/departments`, { name });
  return normalizeJSONAPIResponse(response.data);
};

export const useCreateDepartment = () => {
  const { makeToast } = useStore();
  const user = useCurrentUser();
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useDepartmentsStore();

  return useMutation(createDepartment, {
    onSettled: () => {
      queryCache.invalidateQueries([
        'departments',
        user?.primaryPartner.id,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        name,
      ]);
    },
    // Handle errors
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
  });
};
