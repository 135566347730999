import { createTheme } from '@material-ui/core/styles';
import { Color } from 'core';

export const MuiTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        color: 'inherit',
      },
    },
  },
  palette: {
    primary: {
      light: Color.lightGreen,
      main: Color.ceregoGreen,
      dark: Color.darkBlue,
    },
    secondary: {
      light: Color.lightBlue,
      main: Color.mainBlue,
      dark: Color.darkBlue,
    },
    error: {
      main: Color.red,
    },
  },
  typography: {
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  },
});
