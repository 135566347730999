import { Button, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export default function MemreCollapseText({
  text,
  maxLines = 3,
  sx = {},
}: {
  text: string;
  maxLines?: number;
  sx?: any;
}) {
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(getComputedStyle(textRef.current).lineHeight);
      const maxHeight = lineHeight * maxLines;
      if (textRef.current.scrollHeight > maxHeight) {
        setIsOverflowing(true);
      }
    }
  }, [text, maxLines]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Typography
        ref={textRef}
        variant="body1"
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: expanded ? 'none' : maxLines,
          WebkitBoxOrient: 'vertical',
          overflow: expanded ? 'visible' : 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: expanded ? 'none' : `${maxLines * 1.6}em`,
        }}
      >
        {text}
      </Typography>
      {isOverflowing && (
        <Button
          size="small"
          color="secondary"
          onClick={toggleExpand}
          sx={{ textTransform: 'none', marginTop: '0.5rem', float: 'right' }}
        >
          {expanded ? 'Show less ▴' : 'Read more ▾'}
        </Button>
      )}
    </div>
  );
}
