import React from 'react';
import { TAnnotation } from 'types';

export const VideoAnnotation = ({ annotation }: { annotation: TAnnotation }) => {
  return (
    <video controls style={{ maxWidth: '100%', height: 'auto' }}>
      <source src={annotation.video.url} type={annotation.video.mimeType || 'video/mp4'} />
            Your browser does not support the video tag.
    </video>
  )
}
