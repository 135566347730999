import axios from 'axios';
import { useGroupMembersStore } from 'pages/admin-dashboard/groups/store';
import { useQuery } from 'react-query';

const getGroupMembers = async (
  _key: string,
  groupId: string,
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
  name: string
) => {
  const endpoint = `/api/v3/groups/${groupId}/users`;
  const sortParam = sortDirection === 'desc' ? `-${sortColumn}` : sortColumn;
  let queryParams = `page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sortParam}&tab=default`;
  if (name) {
    queryParams += `&query=${name}`;
  }

  try {
    const response = await axios.get(`${endpoint}?${queryParams}`);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const useGroupMembers = (groupId: string) => {
  const { pageNumber, pageSize, sortColumn, sortDirection, name } = useGroupMembersStore();

  return useQuery(['groupMembers', groupId, pageNumber, pageSize, sortColumn, sortDirection, name], getGroupMembers);
};
