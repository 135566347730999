import { flexRender } from '@tanstack/react-table';
import { Color } from 'core';
import React from 'react';

// TODO: Properly type table prop as instance of React Table
interface Props {
  table: any;
  handleHeaderClick: (e: any) => void;
}

export const TableHeader = (props: Props) => {
  const { table, handleHeaderClick } = props;

  return (
    <thead style={{ backgroundColor: Color.grayTransparent }}>
      {table.getHeaderGroups().map((headerGroup: any) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header: any, i: number) => (
            <th key={header.id} colSpan={header.colSpan} style={{ padding: '2px 20px' }} onClick={handleHeaderClick}>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
