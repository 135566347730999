import { ReactComponent as Checkmark } from 'assets/images/correct.svg';
import { ReactComponent as XMark } from 'assets/images/incorrect.svg';
import { Color, Size } from 'core';
import { ConfirmationText } from 'learn/components/ConfirmationText';
import * as _ from 'lodash';
import { IQuiz, IQuizPart } from 'models/quiz';
import * as React from 'react';
import styled from 'styled-components';

type RegionProps = {
  quiz: IQuiz;
  click: any;
  isSubmitted?: boolean;
  showAnswers?: boolean;
  selectedAnswers?: IQuizPart[];
};

type OwnState = {
  imageLoaded: boolean;
  imageHeight: number;
  imageWidth: number;
  imageTop: number;
  imageLeft: number;
  clickPosition?: IPoint;
};

interface IPoint {
  x: number;
  y: number;
}

const RegionImage = styled.img`
  max-width: ${Size.defaultCardWidth}px;
  height: auto;
  margin: auto;
`;

const RegionImageContainer = styled.div`
  display: flex;
  max-width: ${Size.defaultCardWidth}px;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  flex: 1 1 auto;
  overflow-y: auto;
  margin: 0px auto;
  margin-bottom: 15px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`;

const ClickPosition = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  border-radius: 12px;
  border: 2px solid white;
  background-color: ${Color.lightBlue};
`;

const RegionSVG = styled.svg`
  position: absolute;
  top: 20px;
`;

const RegionPolygon = styled.polygon`
  opacity: 0.8;
  fill-opacity: 0.6;
  stroke-width: 4;
  stroke: ${Color.textGray};
  fill: ${Color.lightGray};
`;

const RegionCircle = styled.circle`
  opacity: 0.8;
  fill-opacity: 0.6;
  radius: 20px;
  stroke-width: 4;
  stroke: ${Color.textGray};
  fill: ${Color.textGray};
`;

const HiddenStyle = {
  opacity: 0,
};

const BlueStyle = {
  stroke: Color.darkSkyBlue,
  fill: Color.lightSkyBlue,
};

const GreenStyle = {
  stroke: Color.lightGreen,
  fill: Color.ceregoGreen,
};

const GreyStyle = {
  stroke: Color.textGray,
  fill: Color.lightGray,
};

const OrangeStyle = {
  stroke: Color.darkOrange,
  fill: Color.darkOrange,
};

const IncorrectMark = styled(XMark)`
  height: 12px;
  width: 12px;
  padding: 4px;
  position: absolute;
  border: 2px solid ${Color.darkOrange};
  border-radius: 12px;
  background-color: white;
`;

const CorrectMark = styled(Checkmark)`
  height: 12px;
  width: 12px;
  padding: 4px;
  position: absolute;
  border: 2px solid ${Color.lightGreen};
  border-radius: 12px;
  background-color: white;
`;

// Region points are bound in a 4 x 3 box...
const boundedPointRatio = 4 / 3;

export default class Region extends React.Component<RegionProps, OwnState> {
  private regionRef;
  constructor(props) {
    super(props);
    // React way to grab size of component
    this.regionRef = React.createRef();
    this.state = {
      ...this.state,
      imageLoaded: false,
      imageHeight: 0,
      imageWidth: 0,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('resize', this.setImageRect);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.setImageRect);
  }

  public componentDidUpdate(prevProps, prevState): void {
    if (!prevState.imageLoaded && this.state.imageLoaded) {
      if (this.props.showAnswers) {
        const clickPosition = this.getCorrectCenterPoint();
        this.setState({ clickPosition });
      }
    }
  }

  public render() {
    const regionParts = _.filter(this.props.quiz.parts, {
      type: 'quizPartRegionPolygons',
    });
    const regionImage = _.find(this.props.quiz.parts, {
      type: 'quizPartRegionImages',
    });
    const correctCenterPoint = this.getCorrectCenterPoint();
    const regionMarginLeft = (Size.defaultCardWidth - this.state.imageWidth) / 2;
    const positionOffset = 9;

    return (
      <div>
        <RegionImageContainer>
          {regionImage && regionImage.image && (
            <RegionImage
              src={regionImage.image.largeUrl || regionImage.image.url}
              ref={this.regionRef}
              onLoad={this.setImageRect.bind(this)}
              style={{
                maxWidth: Math.min(Size.defaultCardWidth, this.state.imageWidth || Size.defaultCardWidth),
              }}
            />
          )}
          <RegionSVG
            style={{
              height: this.state.imageHeight,
              width: this.state.imageWidth,
              marginLeft: regionMarginLeft,
            }}
            onClick={this.handleClick.bind(this, null)}
          >
            {this.state.imageLoaded &&
              regionParts.map((part) => {
                const regionComponentStyle = this.getRegionComponentStyle(part);
                const points = this.convertDecimalToPixelPoints(part.polygon || '');
                const centerPoint = this.getCenterPoint(points);
                if (points.length === 1) {
                  return [
                    <RegionCircle
                      style={regionComponentStyle}
                      cx={points[0].x}
                      cy={points[0].y}
                      points={''}
                      r={'20'}
                      key={part.id}
                      onClick={this.handleClick.bind(this, part)}
                      data-testid={part.testId}
                    />,
                    this.props.isSubmitted && part.isCorrect && (
                      <use
                        key={part.id + 'checked'}
                        style={{ left: centerPoint.x, top: centerPoint.y }}
                        xlinkHref="../../logo.svg;"
                      />
                    ),
                    this.props.isSubmitted &&
                      !part.isCorrect &&
                      this.props.selectedAnswers &&
                      part === this.props.selectedAnswers[0] && (
                        <use
                          key={part.id + 'xed'}
                          style={{ left: centerPoint.x, top: centerPoint.y }}
                          xlinkHref="../../logo.svg;"
                        />
                      ),
                  ];
                } else {
                  return [
                    <RegionPolygon
                      style={regionComponentStyle}
                      key={part.id}
                      points={this.convertPointsToString(points)}
                      onClick={this.handleClick.bind(this, part)}
                      data-testid={part.testId}
                      data-type="region-polygon"
                    />,
                    this.props.isSubmitted && part.isCorrect && (
                      <use
                        key={part.id + 'checked'}
                        style={{ left: centerPoint.x, top: centerPoint.y }}
                        xlinkHref="../../logo.svg;"
                      />
                    ),
                    this.props.isSubmitted &&
                      !part.isCorrect &&
                      this.props.selectedAnswers &&
                      part === this.props.selectedAnswers[0] && (
                        <use
                          key={part.id + 'xed'}
                          style={{ left: centerPoint.x, top: centerPoint.y }}
                          xlinkHref="../../logo.svg;"
                        />
                      ),
                  ];
                }
              })}
          </RegionSVG>
          {this.props.isSubmitted &&
            this.props.selectedAnswers &&
            this.props.quiz &&
            this.props.quiz.explanationHtml && (
              <ConfirmationText
                style={{ marginBottom: 0, marginTop: 20 }}
                correct={this.props.selectedAnswers.length > 0 && this.props.selectedAnswers[0].isCorrect}
                subtitle={this.props.quiz.explanationHtml || ''}
              />
            )}

          {this.props.quiz.quizType === 'RegionHiddenPolygons' && !this.props.isSubmitted && this.state.clickPosition && (
            <ClickPosition
              style={{
                left: this.state.clickPosition.x + positionOffset + regionMarginLeft,
                top: this.state.clickPosition.y + positionOffset,
              }}
            />
          )}
          {this.isIncorrectCenterPointVisible() && this.state.clickPosition && (
            <IncorrectMark
              style={{
                left: this.state.clickPosition.x + positionOffset + regionMarginLeft,
                top: this.state.clickPosition.y + positionOffset,
              }}
            />
          )}
          {this.props.isSubmitted && (
            <CorrectMark
              style={{
                left: correctCenterPoint.x + positionOffset + regionMarginLeft,
                top: correctCenterPoint.y + positionOffset,
              }}
            />
          )}
        </RegionImageContainer>
      </div>
    );
  }

  private getRegionComponentStyle = (part: IQuizPart) => {
    const isSelected = this.props.selectedAnswers && part === this.props.selectedAnswers[0];
    // selected + correct
    if (isSelected && this.props.isSubmitted && part.isCorrect) {
      return GreenStyle;
      //  selected but not correct
    } else if (isSelected && this.props.isSubmitted && !part.isCorrect) {
      return OrangeStyle;
      //  the user selected some other incorrect region -- show this one as the one they should have picked
    } else if (!isSelected && this.props.isSubmitted && part.isCorrect) {
      return GreyStyle;
      // otherwise, hide all regions on click in the dark (not submitted, submitted but (not selected && incorrect)
    } else if (this.props.quiz.quizType === 'RegionHiddenPolygons') {
      return HiddenStyle;
    } else if (isSelected == null && this.props.quiz.quizType === 'RegionVisiblePolygons') {
      return BlueStyle;
      //  multiple choice, show selected
    } else if (isSelected && this.props.quiz.quizType === 'RegionVisiblePolygons') {
      return BlueStyle;
    }
  };

  private isIncorrectCenterPointVisible = () => {
    return (
      this.props.isSubmitted &&
      this.state.clickPosition &&
      !(this.props.selectedAnswers && this.props.selectedAnswers[0] && this.props.selectedAnswers[0].isCorrect)
    );
  };

  private getCorrectCenterPoint = () => {
    const correctRegion = _.find(this.props.quiz.parts, 'isCorrect');
    const point = this.getCenterPoint(this.convertDecimalToPixelPoints((correctRegion && correctRegion.polygon) || ''));
    return {
      x: point.x,
      y: point.y,
    };
  };

  private setImageRect = () => {
    // getBoundingClientRect returns the position relative to the viewport. To get bounding rectangle relative to top-left corner of the document,
    // add current scrolling position to the top and left properties (window.scrollX, window.scrollY)
    const size = this.regionRef.current.getBoundingClientRect();
    this.setState({
      imageLoaded: true,
      imageHeight: size.height,
      imageWidth: size.width,
      imageTop: size.top + window.scrollY,
      imageLeft: size.left + window.scrollX,
    });
  };

  private handleClick = (part, event) => {
    event.stopPropagation();
    if (this.props.isSubmitted) {
      return;
    }
    const clickPosition = { x: event.pageX - this.state.imageLeft, y: event.pageY - this.state.imageTop };
    this.setState({ clickPosition });
    this.props.click(this.convertPointsToString(this.convertPixelToDecimalPoints([clickPosition])), part);
  };

  private getCenterPoint = (points: IPoint[]): IPoint => {
    return {
      x: _.meanBy(points, 'x'),
      y: _.meanBy(points, 'y'),
    };
  };

  private convertPointsToString = (points) => {
    return _.map(points, (p) => {
      return p.x + ' ' + p.y;
    }).join(', ');
  };

  // converts screen pixel clicks to decimal points within an image
  private convertPixelToDecimalPoints = (pixelPoints: IPoint[]): IPoint[] => {
    return _.map(pixelPoints, (pixelPoint) => {
      return {
        x: (pixelPoint.x - this.state.imageLeft) / this.state.imageWidth,
        y: (pixelPoint.y - this.state.imageTop) / this.state.imageHeight,
      };
    });
  };

  // convert decimal points 0...1 to pixel points within the height/width
  private convertDecimalToPixelPoints = (decimalPoints: string): IPoint[] => {
    return _.map(decimalPoints.split(', '), (decimalPoint) => {
      const point = {
        x: Math.min(Math.max(parseFloat(decimalPoint.split(' ')[0]), 0), 1) * this.state.imageWidth,
        y: Math.min(Math.max(parseFloat(decimalPoint.split(' ')[1]), 0), 1) * this.state.imageHeight,
      };
      if (this.state.imageWidth / this.state.imageHeight > boundedPointRatio) {
        const ratio = boundedPointRatio / (this.state.imageWidth / this.state.imageHeight);
        point.y /= ratio;
        point.y -= (this.state.imageHeight / ratio) * ((1 - ratio) / 2);
      } else {
        const ratio = this.state.imageWidth / this.state.imageHeight / boundedPointRatio;
        point.x /= ratio;
        point.x -= (this.state.imageWidth / ratio) * ((1 - ratio) / 2);
      }
      return point;
    });
  };
}
