import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { StandardLink } from 'components/links/StandardLink';
import { PrimaryButton } from 'components/buttons/v4';

export function NativeAppLauncherPage() {
  const location = useLocation();
  const nativeAppLink = `ceregonativeapp://native_app_launcher${location.search}`;

  useEffect(() => {
    const isTouchDevice = navigator.maxTouchPoints > 0;
    const redirectUrl = isTouchDevice ? nativeAppLink : '/app/nav/v4/dashboard';
    window.location.href = redirectUrl;
  }, [nativeAppLink]);

  async function launchMobileApp() {
    window.location.href = nativeAppLink;
  }

  return (
    <>
      <div>
        <PrimaryButton click={launchMobileApp}>Launch Mobile App</PrimaryButton>
      </div>
      <div className="mt-6">
        <StandardLink hardRedirect={true} to="/app/nav/dashboard">
          Go to Web
        </StandardLink>
      </div>
    </>
  );
}
