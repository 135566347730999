import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { Box, Content } from 'components/modals/common';
import { PrimaryButton, SecondaryButton } from 'components/buttons/v4';
import { useHistory } from 'react-router';
import { getBaseReactUrl } from '../../utils/pathUtils';
import { PageContainer, ContentSection, TitleSection, PageTitle } from 'core/layout';
import styled from 'styled-components';
import { Color } from '../../core';

interface IOAuthPageProps {
  match: { params: { partnerSlug: string } };
}

const OAuthHeading = styled.h2`
  padding-bottom: 10px;
  border-bottom: 1px solid ${Color.veryLightGray};
`;

const OAuthSectionHeader = styled.div`
  font-weight: bold;
`;

const SecondaryButtonWithMargin = styled(SecondaryButton)`
  margin-left: 0.5em;
`;

export const OAuthPage = React.memo<IOAuthPageProps>((props) => {
  const { partnerSlug } = props.match.params;
  const [authData, setAuthData] = React.useState<any>(null);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    axios.get(`/api/v3/partners/${partnerSlug}/oauth2_clients`).then((res: any): void => {
      setAuthData(res.data.data);
    });
  }, [partnerSlug]);

  const createOAuthClient = () => {
    window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerSlug}/oauth/new`;
  };

  const editOAuthClient = (authId) => {
    window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerSlug}/oauth/${authId}/edit`;
  };

  const updateSAMLSettings = () => {
    history.push(`/nav/partners/${partnerSlug}/oauth/update_saml_settings`);
  };

  return (
    <PageContainer>
      <TitleSection>
        <PageTitle>{t('OAuth Clients')}</PageTitle>
        <PrimaryButton click={createOAuthClient}>{t('Add New OAuth Client')}</PrimaryButton>
      </TitleSection>
      <ContentSection>
        {authData &&
          authData.map((client, key) => (
            <Box>
              <Content>
                <OAuthHeading>{client.attributes.name}</OAuthHeading>
                <OAuthSectionHeader>{t('OAuth Key')}</OAuthSectionHeader>
                <p>{client.attributes.key}</p>
                {client.attributes['client-type'] !== 'lti_13' && (
                  <div>
                    <OAuthSectionHeader>{t('OAuth Secret')}</OAuthSectionHeader>
                    <p>{client.attributes.secret}</p>
                  </div>
                )}
                <PrimaryButton click={() => editOAuthClient(client.id)}>{t('Edit')}</PrimaryButton>

                {client.attributes['client-type'] == 'saml' && (
                  <SecondaryButtonWithMargin click={updateSAMLSettings}>
                    {t('Update SAML Settings')}
                  </SecondaryButtonWithMargin>
                )}
              </Content>
            </Box>
          ))}
      </ContentSection>
    </PageContainer>
  );
});
