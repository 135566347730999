import { flexRender } from '@tanstack/react-table';
import { Color } from 'core';
import React from 'react';

interface Props {
  table: any;
  rightAlignedColumnIds?: Array<string>;
  extraPadding?: boolean;
}

export const TableBody = (props: Props) => {
  const { table, rightAlignedColumnIds } = props;

  const rightColumns = rightAlignedColumnIds ?? [];

  return (
    <tbody>
      {table.getRowModel().rows.map((row: any) => (
        <tr
          key={row.id}
          style={{
            borderTop: '1px solid rgb(224, 224, 224, 1)',
            borderBottom: '1px solid rgb(224, 224, 224, 1)',
          }}
        >
          {row.getVisibleCells().map((cell: any) => (
            <td
              key={cell.id}
              style={{
                padding: props.extraPadding ? '20px' : '10px 20px',
                textAlign: rightColumns.includes(cell.column.id) ? 'right' : 'left',
                color: Color.reportGray,
                fontSize: '14px',
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
