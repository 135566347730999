import { Box, Tab, Tabs, ThemeProvider } from '@mui/material';
import { MemreFlexBox, MemrePageContainer, MemreText } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { DepartmentsConfig } from 'pages/admin-dashboard/departments/DepartmentsConfig';
import { OAuthConfig } from 'pages/admin-dashboard/oauth-config/OAuthConfig';
import { AppBrandingConfig } from 'pages/admin-dashboard/partner-config/AppBrandingConfig';
import { CourseEnrollmentLinks } from 'pages/admin-dashboard/partner-config/CourseEnrollmentLinks';
import { CourseLibrary } from 'pages/admin-dashboard/partner-config/CourseLibrary';
import { CoursePaymentSettings } from 'pages/admin-dashboard/partner-config/CoursePaymentSettings';
import { CustomUserFields } from 'pages/admin-dashboard/partner-config/CustomUserFields';
import { DefaultGoalLevel } from 'pages/admin-dashboard/partner-config/DefaultGoalLevel';
import { LearnerExperience } from 'pages/admin-dashboard/partner-config/LearnerExperience';
import { MemberIdSlug } from 'pages/admin-dashboard/partner-config/MemberIdSlug';
import { OrganizationDetails } from 'pages/admin-dashboard/partner-config/OrganizationDetails';
import { ProductNotifications } from 'pages/admin-dashboard/partner-config/ProductNotifications';
import theme from 'pages/learner-dashboard/visulisations/theme';
import React, { useState } from 'react';

const MainConfig: React.FC = () => (
  <Box sx={{ padding: '20px' }}>
    <OrganizationDetails />
    <MemberIdSlug />
    <CourseLibrary />
    <ProductNotifications />
    <CourseEnrollmentLinks />
    <CurrentPartnerPaymentSettings />
    <DefaultGoalLevel />
    <LearnerExperience />
    <CustomUserFields />
  </Box>
);

const OAuthConfigContainer: React.FC = () => (
  <Box sx={{ padding: '20px' }}>
    <OAuthConfig />
  </Box>
);

const DepartmentsConfigContainer: React.FC = () => {
  const user = useCurrentUser();

  if (!user?.primaryPartner.partnerSettings.departmentsEnabled) {
    return <></>;
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <DepartmentsConfig />
    </Box>
  );
};

const AppBrandingConfigContainer: React.FC = () => (
  <Box sx={{ padding: '20px' }}>
    <AppBrandingConfig />
  </Box>
);

// Helper component to conditionally render CoursePaymentSettings
const CurrentPartnerPaymentSettings: React.FC = () => {
  const user = useCurrentUser();
  return user?.primaryPartner.partnerSettings.paymentsEnabled ? <CoursePaymentSettings /> : null;
};

// PartnerConfig Component with Tabs
export const PartnerConfig: React.FC = () => {
  const user = useCurrentUser();
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <MemrePageContainer>
        <MemreFlexBox gap={2} direction="column" sx={{ padding: '1rem' }}>
          <MemreText variant="h3">Account Configuration</MemreText>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Partner Configuration Tabs"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label="Main" />
              <Tab label="OAuth" />
              {user?.primaryPartner.partnerSettings.departmentsEnabled && <Tab label="Departments" />}
              <Tab label="App Branding" />
            </Tabs>
          </Box>

          {activeTab === 0 && <MainConfig />}
          {activeTab === 1 && <OAuthConfigContainer />}
          {activeTab === 2 && <DepartmentsConfigContainer />}
          {activeTab === 3 && <AppBrandingConfigContainer />}
        </MemreFlexBox>
      </MemrePageContainer>
    </ThemeProvider>
  );
};
