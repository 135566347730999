import axios from 'axios';
import { CourseAssignmentsTableState, useCourseAssignmentsTableStore } from 'pages/course-detail/store';
import { queryCache, useMutation } from 'react-query';

const updateAssignment = ({ ids, payload }) => {
  const { setId, courseId } = ids;
  return axios.put(`/api/v3/courses/${courseId}/sets/${setId}`, payload);
};

const useMutateAssignment = (courseId: string = '') => {
  const staleQueryKey: Array<string> | string = courseId ? ['assignments', courseId] : 'assignments';
  const pageSize = useCourseAssignmentsTableStore((state: CourseAssignmentsTableState) => state.pageSize);
  const pageNumber = useCourseAssignmentsTableStore((state: CourseAssignmentsTableState) => state.pageNumber);

  return useMutation(updateAssignment, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
    },
    onSettled: () => {
      queryCache.invalidateQueries(staleQueryKey);
      queryCache.invalidateQueries(['paginatedAssignments', courseId, pageNumber, pageSize])
    }
  });
};

export default useMutateAssignment;
