// These functions correspond to ones in our AngularJS UserManager
import { UserRole } from 'types'

export const getRole = (user: any): UserRole => {
  if (!user?.primaryPartner?.meta.role) {
    return 'learner'
  }

  return user.primaryPartner.meta.role.toLowerCase().replaceAll('_', ' ');
}

export const isSiteAdmin = (user: any): boolean => {
  return user?.meta.isAdmin || false;
};
