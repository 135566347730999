import axios from 'axios';
import { TVideo } from 'types';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

export const createVideo = async (video: File): Promise<TVideo> => {
  const uploadData = new FormData();
  uploadData.append('file', video);
  const response = await axios.post('/api/v3/videos', uploadData);
  const videoData = normalizeJSONAPIResponse(response.data);

  return videoData;
};
