import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { useState } from 'react';

interface IDatepicker {
  onChange: (event: any) => void;
}

const Datepicker = (props: IDatepicker) => {
  const [date, setDate] = useState<Date>(new Date());

  const handleDateChange = (event: any): void => {
    const date = moment(event)
      .startOf('day')
      .toDate();

    setDate(date);
    props.onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        variant="static"
        inputVariant="outlined"
        format="MM/dd/yy"
        value={date}
        InputAdornmentProps={{ position: 'start' }}
        disableToolbar={false}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export default Datepicker;
