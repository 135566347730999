import axios from 'axios';
import { TStatusFilter, useCoursesPageStore } from 'components/courses/store';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

interface CoursesResponse {
  data: any[];
  meta: {
    totalCount: number;
    totalPages: number;
  };
}

const getCourses = async (
  _key: string,
  partnerId: string,
  pageNumber: number,
  pageSize: number,
  statusFilter: TStatusFilter,
  sortColumn: string,
  sortDirection: 'asc' | 'desc',
  name: string
): Promise<CoursesResponse> => {
  const endpoint = `/api/v4/partners/${partnerId}/courses`;
  const sortParam = sortDirection === 'desc' ? `-${sortColumn}` : sortColumn;
  let queryParams = `limited=true&include=sets,image&page[number]=${pageNumber}&page[size]=${pageSize}&status_filter=${statusFilter}&sort=${sortParam}`;
  if (name) {
    queryParams += `&name=${name}`;
  }
  const response = await axios.get(`${endpoint}?${queryParams}`);
  const normalizedData = normalizeJSONAPIResponse(response.data);
  return {
    data: normalizedData,
    meta: response.data.meta,
  };
};

// Refetch every five seconds
const REFETCH_INTERVAL_MS: number = 5000;
// Only refetch for up to five minutes
const MAX_FETCHES: number = 60;

export const useGetCourses = (partnerId: string, refetch: boolean = false) => {
  const [fetches, setFetches] = useState<number>(0);
  const refetchConfig: boolean | number = refetch && fetches <= MAX_FETCHES ? REFETCH_INTERVAL_MS : false;
  const { pageNumber, pageSize, statusFilter, sortColumn, sortDirection, name } = useCoursesPageStore();

  return useQuery(
    ['courses', partnerId, pageNumber, pageSize, statusFilter, sortColumn, sortDirection, name],
    () => getCourses('courses', partnerId, pageNumber, pageSize, statusFilter, sortColumn, sortDirection, name),
    {
      enabled: !!partnerId,
      refetchInterval: refetchConfig,
      onSettled: () => {
        if (refetch) {
          setFetches(fetches + 1);
        }
      },
      keepPreviousData: true,
    }
  );
};
