import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CancelButton } from 'components/buttons/v4';
import { ModalPosition } from 'components/Modal';
import { Color } from 'core';
import { PageTitle } from 'core/layout';
import { BoldText, ClickableBox, Content, ModalHeader } from './common';

interface IProps {
  libraryName: string;
  inviteLearners: boolean;
  shareAssignments: boolean;
  onCancel: () => void;
  onPublish: (inLibrary, inviteLearners, shareAssignments) => void;
}

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const PublishOptionsGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1em;
`;

const PublishOptionGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 2em 1em;
`;

const LockIcon = styled.div`
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.reportGray};
`;

export const PublishOptions = (props: IProps) => {
  const { t } = useTranslation();
  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>{t('Ready to Publish?')}</CenterTitle>
      </ModalHeader>

      <PublishOptionsGrid>
        <ClickableBox onClick={() => props.onPublish(false, props.inviteLearners, props.shareAssignments)}>
          <Content>
            <PublishOptionGrid>
              <div>
                <div className="hover-effect" style={{ marginBottom: '.5em' }}>
                  <BoldText>{t('Publish as a private course')}</BoldText>
                </div>
                <div>{t('Only learners you add or provide the course enrollment link can join')}</div>
              </div>
              <LockIcon className="hover-effect">
                <FontAwesomeIcon icon={faLock} />
              </LockIcon>
            </PublishOptionGrid>
          </Content>
        </ClickableBox>
        <ClickableBox onClick={() => props.onPublish(true, props.inviteLearners, props.shareAssignments)}>
          <Content>
            <PublishOptionGrid>
              <div>
                <div className="hover-effect" style={{ marginBottom: '.5em' }}>
                  <BoldText>{t('Publish Options Publish To Text', { libraryName: props.libraryName })}</BoldText>
                </div>
                <div>{t('Learning library users can self-enroll in your course')}</div>
              </div>
              <LockIcon className="hover-effect">
                <FontAwesomeIcon icon={faUnlock} />
              </LockIcon>
            </PublishOptionGrid>
          </Content>
        </ClickableBox>
      </PublishOptionsGrid>
      <CancelButton click={props.onCancel}>{t('Cancel')}</CancelButton>
    </ModalPosition>
  );
};
