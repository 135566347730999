import React, { useState } from 'react';
import ceregoLogo from 'assets/images/cerego-logo.svg';
import { Link } from 'react-router-dom';
import { postWithCSRFToken } from './registrationUtils';
import { PageTitle } from 'core/layout';
import { BrightInput } from 'components/forms/BrightInput';
import { ErrorMessage } from 'core/typography';
import { StandardLink } from 'components/links/StandardLink';
import { PrimaryButton } from 'components/buttons/v4';

export function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [didSendEmail, setDidSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (e: any = null) => {
    e?.preventDefault();

    if (loading) {
      return;
    }

    setMessage('');
    setError('');
    setLoading(true);

    try {
      await postWithCSRFToken('/api/v3/passwords', {
        email,
      });
      setMessage(`Password reset instructions have been sent to your ${email} email.`);
      setDidSendEmail(true);
    } catch (err) {
      console.error('Error sending reset instructions:', err);
      setError(`We couldn't find an account with that email. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mb-4">
        <PageTitle>Forgot Password?</PageTitle>
      </div>

      <form onSubmit={handleForgotPassword}>
        <div>
          <label className="font-bold">Email Address</label>
          <BrightInput
            className="!my-1"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder=" "
            required
            disabled={loading || didSendEmail}
          />
        </div>

        {message && <div className="text-green-600">{message}</div>}

        {error && <ErrorMessage>{error}</ErrorMessage>}

        {!didSendEmail && (
          <div className="mt-4">
            <PrimaryButton pending={loading} click={() => handleForgotPassword()}>
              Send Reset Instructions
            </PrimaryButton>
          </div>
        )}
      </form>
      <div className="mt-6">
        <StandardLink to="/signin">Back to Sign In</StandardLink>
      </div>
    </>
  );
}
