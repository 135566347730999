import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum OUTLIERS {
  LOWER = 'lower',
  UPPER = 'upper',
}
export const OUTLIER_RANGE = {
  [OUTLIERS.LOWER]: -0.6,
  [OUTLIERS.UPPER]: 0.4,
};

const OVERLINE = 'DIFFICULTY';

const RECOMMENDATION_TEXT = {
  [OUTLIERS.UPPER]: {
    HEADING: 'Most Difficulty Question',
    SUGGESTION:
      'This question stands out as one of the most challenging across our platform, suggesting it may warrant closer examination or revision.',
  },
  [OUTLIERS.LOWER]: {
    HEADING: 'Easiest Question',
    SUGGESTION:
      'This question appears to be exceptionally easy compared to others on our platform, indicating a potential need for review to ensure its effectiveness in engaging learners.',
  },
};

export default function OutlierCard({ outlier, direction }) {
  const { t } = useTranslation();

  const text = {
    overline: t(OVERLINE),
    heading: t(RECOMMENDATION_TEXT[direction].HEADING),
    suggestion: t(RECOMMENDATION_TEXT[direction].SUGGESTION),
  };

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <Stack direction={{ xs: 'column', sm: 'row', md: 'column', lg: 'row' }}>
        <CardContent sx={{ width: { md: '100%', lg: '60%' } }}>
          <Typography sx={{ fontWeight: 'light', letterSpacing: 1, fontSize: 10 }} color="text.secondary">
            {text.overline}
          </Typography>
          <Typography sx={{ mb: 0.5, fontWeight: 600 }} variant="h6">
            {text.heading}
          </Typography>
          <Typography color="text.secondary" sx={{ fontStyle: 'italic', fontSize: 14 }}>
            {text.suggestion}
          </Typography>
        </CardContent>
        <Box sx={{ flexGrow: 1, p: 2, opacity: 0.6, width: { md: '100%', lg: '40%' } }}>
          <Typography color="text.secondary" sx={{ fontSize: 12, lineHeight: 1.3, mt: 1 }}>
            "{outlier.anchor}"
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}
