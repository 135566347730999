import { MemreContainer, MemreFlexBox, MemreLoading, MemreNoResults, MemreText } from 'components/core';
import * as Color from 'core/colors';
import { useKnowledgeBank } from 'hooks/useKnowledgeBank';
import GoalProgress from './GoalProgress';
import SecureMemories from './SecureMemories';
import SinceLastStudy from './SinceLastStudy';
import StudyDuration from './StudyDuration';

export default function KnowledgeBankVis({ courseId, set, student }) {
  const { data, isLoading, isStudentsView } = useKnowledgeBank(courseId, set, student);

  return (
    <>
      {isLoading ? (
        <MemreFlexBox direction="column" align="center" justify="center" gap={2} sx={{ p: 3 }}>
          <MemreLoading /> <MemreText sx={{ color: Color.darkGray }}>Loading Data</MemreText>
        </MemreFlexBox>
      ) : !data[0] || !set ? (
        <MemreFlexBox
          direction="row"
          justify="center"
          sx={{
            p: 4,
            borderTop: `1px solid ${Color.lightGray}`,
            backgroundColor: Color.veryLightGray,
          }}
        >
          <MemreNoResults />
        </MemreFlexBox>
      ) : (
        <>
          <MemreFlexBox
            direction="row"
            sx={{
              borderTop: `1px solid ${Color.veryLightGray}`,
              borderBottom: `1px solid ${Color.veryLightGray}`,
            }}
          >
            <SinceLastStudy data={data} isStudentsView={isStudentsView} />
            <SecureMemories data={data} isStudentsView={isStudentsView} />
            <StudyDuration data={data} isStudentsView={isStudentsView} />
          </MemreFlexBox>

          <MemreContainer
            size="large"
            direction={{
              xs: 'column',
              lg: 'row',
            }}
          >
            <GoalProgress data={data} isStudentsView={isStudentsView} />
          </MemreContainer>
        </>
      )}
    </>
  );
}
