export interface ILtiFields {
  id?: string;
  context_id?: string;
  context_label?: string;
  context_type?: string;
  context_title?: string;
  embed?: string;
  custom_scoring_target?: number;
  custom_scoring_type?: string;
  launch_presentation_locale?: string;
  lti_version?: string;
  lti_message_type?: string;
  custom_department_id?: string;
  custom_set_id?: string;
  debug?: string;
  lis_course_offering_sourcedid?: string;
  lis_result_sourcedid?: string;
  lis_outcome_service_url?: string;
  launch_presentation_document_target?: string;
  launch_presentation_return_url?: string;
  resource_link_id?: string;
  resource_link_title?: string;
  resource_link_description?: string;
  roles?: string;
  tool_consumer_instance_contact_email?: string;
  tool_consumer_instance_description?: string;
  tool_consumer_instance_name?: string;
  tool_consumer_info_version?: string;
  tool_consumer_info_product_family_code?: string;
  tool_consumer_instance_guid?: string;
  custom_canvas_api_domain?: string;
  custom_canvas_assignment_id?: string;
  custom_canvas_assignment_points_possible?: string;
  custom_canvas_assignment_title?: string;
  custom_canvas_course_id?: string;
  custom_canvas_enrollment_state?: string;
  ext_ims_lis_basic_outcome_url?: string;
  ext_outcome_data_values_accepted?: string;
  ext_outcome_result_total_score_accepted?: string;
  ext_outcomes_tool_placement_url?: string;
  ext_roles?: string;
  custom_ref_id?: string;
  custom_bc_course_id?: string;
  custom_vbid?: string;
  custom_sku?: string;
  custom_resource_id?: string;
  custom_book_location?: string;
  custom_location?: string;
  custom_online_vbid?: string;
  custom_online_location?: string;
}

export interface ILtiPresets {
  [oauth2ClientId: number]: ILtiFields;
}

export const ltiLauncherPresets: ILtiPresets = {
  9: {
    // Elsevier
    id: '732905',
    context_id: '7142494010',
    context_label: '153460_kstone63_1001',
    context_type: 'CourseSection',
    context_title: 'Elsevier Adaptive Learning for Fundamentals Concepts and Skills for Nursing',
    embed: '',
    lis_course_offering_sourcedid: '',
    lis_result_sourcedid: '',
    lis_outcome_service_url: 'https://evolve.elsevier.com/lti/services/outcomes',
    launch_presentation_document_target: '',
    launch_presentation_return_url: 'https://evolve.elsevier.com/Courses/153460_kstone63_1001/C-z1jk/CI-plfx-if94dwci',
    resource_link_id: '7142517234',
    resource_link_title: 'Pharmacology and Preparation for Drug Administration – Part 1',
    roles: 'Student',
    tool_consumer_instance_contact_email: '',
    tool_consumer_instance_description: '',
    tool_consumer_instance_name: 'Elsevier',
    tool_consumer_info_version: '',
    tool_consumer_info_product_family_code: '',
    tool_consumer_instance_guid: '',
  },
  10: {
    // UT Austin Canvas
    id: '734017',
    context_id: '64b3923dea0d1ababc58667641e7e38feb3671f5',
    context_label: 'MUS 307',
    context_type: '',
    context_title: '3-JAZZ APPRECIATION',
    custom_canvas_api_domain: 'utexas.instructure.com',
    custom_canvas_assignment_id: '3611674',
    custom_canvas_assignment_points_possible: '100',
    custom_canvas_assignment_title: 'Practice Jazz Facts 1',
    custom_canvas_course_id: '1149258',
    custom_canvas_enrollment_state: 'active',
    embed: '1',
    ext_ims_lis_basic_outcome_url: 'https://utexas.instructure.com/api/lti/v1/tools/21649/ext_grade_passback',
    ext_outcome_data_values_accepted: 'url,text',
    ext_outcome_result_total_score_accepted: 'true',
    ext_outcomes_tool_placement_url: 'https://utexas.instructure.com/api/lti/v1/turnitin/outcomes_placement/21649',
    ext_roles: 'urn:lti:instrole:ims/lis/Student,urn:lti:role:ims/lis/Learner,urn:lti:sysrole:ims/lis/User',
    lis_course_offering_sourcedid: 'MUS_307_21230_159',
    lis_result_sourcedid: '21649-1149258-3611674-3866002-9804fc28758a3142481b48e56cba0d8bbd646d2d',
    lis_outcome_service_url: 'https://utexas.instructure.com/api/lti/v1/tools/21649/grade_passback',
    launch_presentation_document_target: 'iframe',
    launch_presentation_return_url:
      'https://utexas.instructure.com/courses/1149258/external_content/success/external_tool_redirect',
    resource_link_id: '1d4ef04016134c8fbed21c119560f6900843f40d',
    resource_link_title: 'Practice Jazz Facts 1',
    roles: 'Learner',
    tool_consumer_instance_contact_email: 'notifications@instructure.com',
    tool_consumer_instance_description: '',
    tool_consumer_instance_name: 'The University Of Texas at Austin',
    tool_consumer_info_version: 'cloud',
    tool_consumer_info_product_family_code: 'canvas',
    tool_consumer_instance_guid: '6724ae47da42a559bbb358b08e21abbaadb1de4f.utexas.instructure.com',
  },
  14: {
    // Cengage
    id: '',
    context_id: '200005',
    context_label: '9781305272842',
    context_type: '',
    context_title: 'What Is Psychology? Foundations, Applications, and Integration',
    custom_ref_id: 'set_734840',
    custom_set_id: '734840',
    embed: 'true',
    lis_course_offering_sourcedid: '',
    lis_result_sourcedid: '18c04718-0e0d-42f4-b900-051339839ed3',
    lis_outcome_service_url: 'http://ng.cengage.com/nb/service/ltiOutcome/pox/',
    launch_presentation_document_target: '',
    launch_presentation_return_url: 'about: blank',
    resource_link_id: '200005-set_734840',
    resource_link_title: 'Chapter 10 DO Mastery Training (powered by Cerego)',
    roles: 'Learner',
    tool_consumer_instance_contact_email: '',
    tool_consumer_instance_description: '',
    tool_consumer_instance_name: '',
    tool_consumer_info_version: 'mt3',
    tool_consumer_info_product_family_code: 'mindtap',
    tool_consumer_instance_guid: 'ng.cengage.com',
  },
  506: {
    // Cengage V4
    id: '1036955',
    context_id: '4032002',
    context_label: '9780357374849',
    context_title: 'Psychology: Themes and Variations',
    custom_ref_id: 'set_734840',
    resource_link_id: '4032002-0',
    roles: 'Instructor',
    tool_consumer_info_version: 'mt3',
    tool_consumer_info_product_family_code: 'mindtap',
    tool_consumer_instance_guid: 'ng.cengage.com',
  },
  21: {
    // Cerego Internal
    id: '941285', // A V4 set
    context_id: 'zgd6p39fwef',
    context_label: '',
    context_type: '',
    context_title: '',
    embed: '',
    roles: 'Student',
  },
  30: {
    // StatsPL
    id: '739204',
    context_id: 'TexasAMUniversity/SCMT/303',
    context_label: '',
    context_type: '',
    context_title: '',
    embed: '',
    lis_course_offering_sourcedid: '',
    lis_result_sourcedid:
      'TexasAMUniversity/SCMT/303:lms.ceregolabs.com-i4x-TexasAMUniversity-SCMT-lti-8825874207d5477db2b3b1757785fdbc:5a1768898827fe7cf5e864bc7d5c055e',
    lis_outcome_service_url:
      'https://lms.ceregolabs.com/courses/TexasAMUniversity/SCMT/303/xblock/i4x:;_;_TexasAMUniversity;_SCMT;_lti;_8825874207d5477db2b3b1757785fdbc/handler_noauth/grade_handler',
    launch_presentation_document_target: '',
    launch_presentation_return_url: '',
    resource_link_id: 'lms.ceregolabs.com-i4x-TexasAMUniversity-SCMT-lti-8825874207d5477db2b3b1757785fdbc',
    resource_link_title: '',
    roles: 'Student',
    tool_consumer_instance_contact_email: '',
    tool_consumer_instance_description: '',
    tool_consumer_instance_name: '',
    tool_consumer_info_version: '',
    tool_consumer_info_product_family_code: '',
    tool_consumer_instance_guid: '',
  },
  32: {
    // ASU
    id: '752988',
    context_id: 'course-v1:ASUx+ASM246+3T2015',
    context_label: '',
    context_type: '',
    context_title: '',
    embed: '',
    lis_course_offering_sourcedid: '',
    lis_result_sourcedid:
      'course-v1%3AASUx%2BASM246%2B3T2015:courses.edx.org-1bf563efcfa64f4a9005032a2ab90603:68e75d630ab73e612b92b0526dc76174',
    lis_outcome_service_url:
      'https://courses.edx.org/courses/course-v1:ASUx+ASM246+3T2015/xblock/block-v1:ASUx+ASM246+3T2015+type@lti+block@1bf563efcfa64f4a9005032a2ab90603/handler_noauth/grade_handler',
    launch_presentation_document_target: '',
    launch_presentation_return_url: '',
    resource_link_id: 'courses.edx.org-1bf563efcfa64f4a9005032a2ab90603',
    resource_link_title: '',
    roles: 'Student',
    tool_consumer_instance_contact_email: '',
    tool_consumer_instance_description: '',
    tool_consumer_instance_name: '',
    tool_consumer_info_version: '',
    tool_consumer_info_product_family_code: '',
    tool_consumer_instance_guid: '',
  },
  38: {
    // DartmouthX
    id: '752983',
    context_id: 'course-v1:DartmouthX+DART.MUS.01X+2015_T3',
    context_label: '',
    context_type: '',
    context_title: '',
    embed: '',
    lis_course_offering_sourcedid: '',
    lis_result_sourcedid:
      'course-v1%3ADartmouthX%2BDART.MUS.01X%2B2015_T3:courses.edx.org-fb8bdb1b7d664d7baa9594e64e1419f3:4656f78cbc317ca694cfa1aed746a7fd',
    lis_outcome_service_url:
      'https://courses.edx.org/courses/course-v1:DartmouthX+DART.MUS.01X+2015_T3/xblock/block-v1:DartmouthX+DART.MUS.01X+2015_T3+type@lti+block@fb8bdb1b7d664d7baa9594e64e1419f3/handler_noauth/grade_handler',
    launch_presentation_document_target: '',
    launch_presentation_return_url: '',
    resource_link_id: 'courses.edx.org-fb8bdb1b7d664d7baa9594e64e1419f3',
    resource_link_title: '',
    roles: 'Student',
    tool_consumer_instance_contact_email: '',
    tool_consumer_instance_description: '',
    tool_consumer_instance_name: '',
    tool_consumer_info_version: '',
    tool_consumer_info_product_family_code: '',
    tool_consumer_instance_guid: '',
  },
  40: {
    // VitalSource
    id: '',
    context_id: '482',
    context_label: 'Biology: Concepts and Investigations',
    context_type: 'CourseSection',
    context_title: 'Biology: Concepts and Investigations',
    custom_bc_course_id: 'BC-999',
    custom_vbid: '9780072916904',
    custom_sku: '9780072916904',
    custom_resource_id: '9780072916904',
    custom_book_location: '',
    custom_location: '/6/8!/3',
    custom_online_vbid: '9780072916904',
    custom_online_location: '/6/8!/3',
    embed: '',
    lis_course_offering_sourcedid: '',
    lis_result_sourcedid: '',
    lis_outcome_service_url: '',
    launch_presentation_document_target: '',
    launch_presentation_return_url: '',
    resource_link_id: '56537251',
    resource_link_title: 'UNIT 1: The Cellular Basis of Life',
    resource_link_description: 'UNIT 1: The Cellular Basis of Life',
    roles: 'Instructor',
    tool_consumer_instance_contact_email: '',
    tool_consumer_instance_description: 'bookshelf',
    tool_consumer_instance_name: 'bookshelf',
    tool_consumer_info_version: '',
    tool_consumer_info_product_family_code: 'bookshelf',
    tool_consumer_instance_guid: 'bookshelf',
  },
  183: {
    // BBC
    id: '886012',
    context_id: 'zgd6p39',
    context_label: '',
    context_type: '',
    context_title: '',
    embed: '',
    roles: 'Student',
  },
};

// Use this template for new presets
//        id: ""
//        context_id: ""
//        context_label: ""
//        context_type: ""
//        context_title: ""
//        embed: ""
//        lis_course_offering_sourcedid: ""
//        lis_result_sourcedid: ""
//        lis_outcome_service_url: ""
//        launch_presentation_document_target: ""
//        launch_presentation_return_url: ""
//        resource_link_id: ""
//        resource_link_title: ""
//        roles: ""
//        tool_consumer_instance_contact_email: ""
//        tool_consumer_instance_description: ""
//        tool_consumer_instance_name: ""
//        tool_consumer_info_version: ""
//        tool_consumer_info_product_family_code: ""
//        tool_consumer_instance_guid: ""
