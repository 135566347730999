import * as _ from 'lodash';

export default function (progress: number = 0): number {
  // If there is some progress always show at least 1%
  if (progress > 0 && progress < 0.01) {
    return 1;
  }

  return _.floor(progress * 100);
}
