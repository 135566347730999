import axios from 'axios';
import { queryCache, useMutation } from 'react-query';
import useStore, { State } from 'zstore';

const deleteFacet = (facetId: string) => {
  return axios.delete(`/api/v3/facets/${facetId}`);
};

export const useDeleteFacet = (_key: string, setId: string, itemId: string) => {
  const makeToast = useStore((state: State) => state.makeToast);

  return useMutation(deleteFacet, {
    onSuccess: () => {
      makeToast({
        text: 'Facet deleted successfully!',
        isError: false,
      });
      queryCache.invalidateQueries(`item-${itemId}`);
      queryCache.invalidateQueries(['assignmentItems', setId]);
    },
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Failed to delete facet',
        isError: true,
      });
    },
  });
};
