import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';
import useStore from 'zstore';

// id is the partner slug for some reason lol
// TODO: stop ever using slug for internal logic
type CreateOAuthClientPayload = {
  access_token_url?: string;
  client_type: string;
  id: string;
  name: string;
  redirect_uri?: string;
  website?: string;
  public_jwk_url?: string;
};

export const DEFAULT_OAUTH_CLIENT: CreateOAuthClientPayload = {
  access_token_url: 'https://canvas.instructure.com/login/oauth2/token',
  client_type: 'lti_12',
  id: '',
  name: '',
  redirect_uri: 'https://canvas.instructure.com/api/lti/authorize_redirect',
  website: 'https://canvas.instructure.com',
};

const createOAuthClient = (payload: CreateOAuthClientPayload) => {
  return axios.post(`/api/v3/partners/${payload.id}/oauth2_clients`, payload);
};

export const useCreateOAuthClient = () => {
  const user = useCurrentUser();
  const { makeToast } = useStore();

  return useMutation(createOAuthClient, {
    onError: (error: any) => {
      console.error(error);
      makeToast({
        text: 'Uh-oh! Something went wrong. Please try again.',
        isError: true,
      });
    },
    onSettled: () => {
      queryCache.invalidateQueries(['oauthClients', user?.primaryPartner.slug]);
    },
  });
};
