import { Checkbox, IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { EditorEvents, EditorProvider } from '@tiptap/react';
import { AnswerImage } from 'components/item-editor/AnswerImage';
import { AutosaveStatus } from 'components/item-editor/AutosaveStatus';
import { TipTapMenuBar, tipTapExtensions } from 'components/item-editor/TipTapMenuBar';
import { useItemEditorStore } from 'components/item-editor/store';
import { useCreateDistractor } from 'hooks/useCreateDistractor';
import { useCreateFacet } from 'hooks/useCreateFacet';
import { useDeleteConcept } from 'hooks/useDeleteConcept';
import { useDeleteFacet } from 'hooks/useDeleteFacet';
import { useMutateConcept } from 'hooks/useMutateConcept';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TAnswer } from 'types';

interface AnswerFieldProps {
  answer: TAnswer;
  showAnswerCorrectnessToggle?: boolean;
  answers: TAnswer[];
  showExplanationField: boolean;
  updateAnswer: (answerId: string, updates: Partial<TAnswer>) => void;
  removeAnswer: (answerId: string) => void;
}

export const AnswerField = (props: AnswerFieldProps) => {
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();
  const {
    answer,
    showAnswerCorrectnessToggle = true,
    answers,
    updateAnswer,
    removeAnswer,
    showExplanationField,
  } = props;
  const [deleteConcept] = useDeleteConcept(assignment?.id || '', item?.id || '');
  const [deleteFacet] = useDeleteFacet('deleteFacet', assignment?.id || '', item?.id || '');
  const [createDistractor] = useCreateDistractor(item?.id || '', assignment?.id || '', answer.questionId);
  const [createFacet] = useCreateFacet(item?.id || '', assignment?.id || '');
  const [updatingCorrectness, setUpdatingCorrectness] = useState(false);
  const [mutateConcept] = useMutateConcept(item?.id, assignment?.id, answer.questionId);
  const [autosaveStatus, setAutosaveStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');

  useEffect(() => {
    if (autosaveStatus === 'success' || autosaveStatus === 'error') {
      const timer = setTimeout(() => {
        setAutosaveStatus('idle');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autosaveStatus]);

  const debouncedAnswerTextChange = debounce(async (editor: any) => {
    setAutosaveStatus('saving');

    const payload = {
      data: {
        attributes: {
          'text-html': editor.getHTML(),
        },
      },
    };

    try {
      await mutateConcept({ id: answer.conceptId, payload });
    } catch (error: any) {
      console.error(error);
    } finally {
      setAutosaveStatus('success');
    }
  }, 500);

  const debouncedExplanationTextChange = debounce(async (editor: any) => {
    setAutosaveStatus('saving');

    const payload = {
      data: {
        attributes: {
          'explanation-html': editor.getHTML(),
        },
      },
    };

    try {
      await mutateConcept({ id: answer.conceptId, payload });
    } catch (error: any) {
      console.error(error);
    } finally {
      setAutosaveStatus('success');
    }
  }, 500);

  const handleAnswerTextChange = ({ editor }: EditorEvents['update']) => {
    debouncedAnswerTextChange(editor);
  };

  const handleExplanationTextChange = ({ editor }: EditorEvents['update']) => {
    debouncedExplanationTextChange(editor);
  };

  const toggleAnswerCorrectness = async (): Promise<void> => {
    try {
      setUpdatingCorrectness(true);
      if (!answer) {
        console.warn('Answer not found');
        return;
      }

      if (!item) {
        console.warn('Item not found');
        return;
      }

      const isCorrect = answer.isCorrect;

      if (isCorrect && answers.filter((a: TAnswer) => a.isCorrect).length === 1) {
        console.warn('Cannot mark answer as correct if it is the only correct answer');
        return;
      }

      if (!answer.conceptId) {
        console.warn('Answer does not have a conceptId');
        return;
      }

      if (isCorrect) {
        if (!answer.facetId) {
          console.warn('Answer does not have a facetId');
          return;
        }

        const distractor = await createDistractor({
          conceptId: answer.questionId,
          payload: {
            attributes: {
              text: answer.text,
              'text-html': answer.text,
              'allow-random-distractors': true,
              'explanation-html': answer.explanation,
              explanation: answer.explanation,
              'created-at': answer['created-at'],
              'is-studiable': true,
            },
            relationships: {
              image: {
                data: answer.image,
              },
            },
          },
        });
        await deleteFacet(answer.facetId);
        updateAnswer(answer.conceptId, {
          conceptId: distractor.id,
          facetId: null,
          isCorrect: false,
        });
      } else {
        const facet = await createFacet({
          itemId: item.id,
          payload: {
            relationships: {
              association: {
                data: {
                  id: Number(answer.conceptId),
                  attributes: {
                    text: answer.text,
                    'text-html': answer.text,
                    'explanation-html': answer.explanation,
                    explanation: answer.explanation,
                  },
                  relationships: {
                    image: {
                      data: answer.image
                        ? {
                            id: Number(answer.image.id),
                            type: 'images',
                            attributes: {
                              ...answer.image,
                            },
                          }
                        : null,
                    },
                  },
                },
              },
            },
          },
        });
        await deleteConcept(answer.conceptId);
        updateAnswer(answer.conceptId, { facetId: facet.id, conceptId: facet.association.id, isCorrect: true });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setUpdatingCorrectness(false);
    }
  };

  const deleteAnswer = (): void => {
    if (answer.isCorrect) {
      if (!answer.facetId) {
        console.warn('Answer does not have a facetId');
        return;
      }
      deleteFacet(answer.facetId);
    } else {
      if (!answer.conceptId) {
        console.warn('Answer does not have a conceptId');
        return;
      }
      deleteConcept(answer.conceptId);
    }

    removeAnswer(answer.conceptId);
  };

  return (
    <AnswerContainer
      key={`answer-${answer.conceptId || 'empty-concept'}-${answer.facetId || 'empty-facet'}`}
      elevation={2}
    >
      {showAnswerCorrectnessToggle && (
        <div>
          <Checkbox
            disabled={updatingCorrectness}
            checked={answer.isCorrect}
            onChange={toggleAnswerCorrectness}
            color="primary"
          />
          <InputLabel shrink={true}>{answer.isCorrect ? 'Correct' : ''}</InputLabel>
        </div>
      )}

      <AnswerImage answer={answer} updateAnswer={updateAnswer} />

      <div style={{ width: '100%', marginLeft: '16px', marginRight: '16px' }}>
        <div
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            minHeight: '100px',
            marginBottom: '16px',
          }}
        >
          <InputLabel shrink={true}>Answer</InputLabel>
          <EditorProvider
            slotBefore={<TipTapMenuBar />}
            extensions={tipTapExtensions}
            content={answer.textHtml}
            onUpdate={handleAnswerTextChange}
          >
            <></>
          </EditorProvider>
        </div>
        {showExplanationField && (
          <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px', minHeight: '100px' }}>
            <InputLabel shrink={true}>Explanation</InputLabel>
            <EditorProvider
              slotBefore={<TipTapMenuBar />}
              extensions={tipTapExtensions}
              content={answer.explanationHtml}
              onUpdate={handleExplanationTextChange}
            >
              <></>
            </EditorProvider>
          </div>
        )}
        <AutosaveStatus status={autosaveStatus} />
      </div>
      {answer.isCorrect && answers.filter((a: TAnswer) => a.isCorrect).length === 1 ? (
        <Tooltip title="Every question must have at least one correct answer">
          <IconButton aria-label="Delete answer" onClick={() => {}}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Delete answer">
          <IconButton aria-label="Delete answer" onClick={deleteAnswer}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </AnswerContainer>
  );
};

const AnswerContainer = styled(Paper)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
`;
