export default function getInstructionalItemsType(annotations) {
  const types = annotations?.map((annotation: any) => annotation.noteType);

  if (!types || !types.length) {
    return null;
  }

  const priority = {
    text: 1,
    image: 2,
    sound: 3,
    video: 4,
    document: 5,
    embed: 6,
  };

  return types.reduce((primary, current) => {
    return priority[current] > (priority[primary] || 0) ? current : primary;
  }, types[0]);
}
