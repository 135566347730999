import { CALL_API } from 'middleware/api';
import build from 'redux-object';

export const getAssignmentsEndpoint = (courseId: string, includeQueryString: boolean = true): string => {
  const baseUrl: string = `/api/v4/courses/${courseId}/assignments`;

  if (includeQueryString) {
    return `${baseUrl}?include=image&page[size]=200`;
  }

  return baseUrl;
};

const getSetIds = (state: any, courseId: string) => {
  return state.data.meta[`/api/v4/courses/${courseId}/assignments`]?.data.map(assignment => assignment.id);
};

export const getAssignmentsData = (state: any, courseId: string) => {
  const assignmentsData = build(state.data, 'sets');
  const setIds = getSetIds(state, courseId);
  return assignmentsData && setIds ? assignmentsData.filter(assignment => setIds.includes(assignment.id)) : null;
};

export const handleGetAssignments = (courseId: string) => {
  return {
    [CALL_API]: {
      endpoint: getAssignmentsEndpoint(courseId),
      httpMethod: 'GET'
    }
  };
};
