import { CALL_API } from 'middleware/api';
import build from 'redux-object';

export const handleGetProfile = () => {
  return {
    [CALL_API]: {
      endpoint:
        '/api/v3/my/profile?include=primary_partner,primary_partner.account,primary_partner.image,emails,primary_partner.account.plan,primary_partner.partner_library,primary_partner.partner_library.image,primary_partner.ios_brand_image',
      httpMethod: 'GET',
    },
  };
};

export const getCurrentUser = (state: any) => {
  const currentUserId =
    state.data.meta &&
    state.data.meta['/api/v3/my/profile'] &&
    state.data.meta['/api/v3/my/profile'].data &&
    state.data.meta['/api/v3/my/profile'].data[0] &&
    state.data.meta['/api/v3/my/profile'].data[0].id;

  return (
    currentUserId &&
    build(state.data, 'users') &&
    build(state.data, 'users').find((user: any) => user.id === currentUserId)
  );
};
