import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ceregoLogo from 'assets/images/cerego-logo.svg';
import axios from 'axios';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { postWithCSRFToken } from './registrationUtils';
import { humanizeV3Error, normalizeJSONAPIResponse } from 'utils/modelUtils';
import { formatPartnerUserRole, PartnerUserRole } from 'utils/formatPartnerUserRole';
import { PageTitle } from 'core/layout';
import { BrightInput } from 'components/forms/BrightInput';
import { ErrorMessage } from 'core/typography';
import { StandardLink } from 'components/links/StandardLink';
import { PrimaryButton } from 'components/buttons/v4';
import { useDispatch } from 'react-redux';
import { handleDestroySession } from 'store/session';

interface ActivationTokenData {
  user: PartialUser;
  minPasswordLength: number;
  course: PartialCourse;
  partner: PartialPartner;
}

interface PartialUser {
  id: string;
  email: string;
  hasPassword: boolean;
  name: string;
}

interface PartialCourse {
  id: string;
  name: string;
  slug: string | null;
}

interface PartialPartner {
  id: string;
  name: string;
  role: PartnerUserRole;
}

type RegistrationStep =
  | 'loading'
  | 'unknownError' // A non recoverable error that doesn't have special handling
  | 'signUp'
  | 'verify' // Verify that they have a whitelisted email domain name
  | 'done'
  | 'joinUrlNotFound'
  | 'confirmationTokenUsed'
  | 'signInWithExistingAccount' // They're not signed in, but the confirmation token is for an existing account with a set password
  | 'wrongAccountWarning' // They're signed into an account that doesn't match the confirmation token
  | 'wrongAccountJoinUrl'; // They're signed into an account with email domain name that isn't whitelisted

interface CourseRegistrationPageProps {
  isPartnerRegistration?: boolean;
}

export const CourseRegistrationPage = (props: CourseRegistrationPageProps) => {
  const { isPartnerRegistration = false } = props;
  const { partnerSlug, courseSlug } = useParams<{ partnerSlug: string; courseSlug: string }>();

  const [currentUser, setCurrentUser] = useState<any>(null);
  const [userFromToken, setUserFromToken] = useState<PartialUser | null>(null);
  const [course, setCourse] = useState<PartialCourse | null>(null);
  const [partner, setPartner] = useState<PartialPartner | null>(null);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [minPasswordLength, setMinPasswordLength] = useState(8);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [selectedDomain, setSelectedDomain] = useState<string>('');
  const location = useLocation();
  const domainVerificationRequired = course && getPartnerDomainNames(partner).length > 0;

  const [step, setStep] = useState<RegistrationStep>('loading');

  const currentPath = location.pathname;
  const confirmationToken = new URLSearchParams(location.search).get('confirmation_token');

  useEffect(() => {
    (async () => {
      const currentUser = await getCurrentUser();
      console.log('currentUser', currentUser);
      setCurrentUser(currentUser);

      if (confirmationToken) {
        await initializeEmailInviteFlow(currentUser);
      } else {
        await initializeJoinURLFlow(currentUser);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function initializeEmailInviteFlow(currentUser: any) {
    try {
      const tokenData = await getDataFromActivationToken();

      if (currentUser && currentUser.id !== tokenData.user.id) {
        // They're signed into an account that doesn't match the confirmation token
        setStep('wrongAccountWarning');
      } else {
        setCourse(tokenData.course);
        setPartner(tokenData.partner);
        setMinPasswordLength(tokenData.minPasswordLength);
        setUserFromToken(tokenData.user);

        if (!tokenData.user.hasPassword) {
          // No password means the user sees the sign up form regardless of whether they're logged in or not.
          // This is so they can set their password.
          setStep('signUp');
        } else if (currentUser) {
          // If they're logged in to the proper account and it has a password then there's nothing to do.
          setStep('done');
        } else {
          // If they're not logged in and the token user has a password then we tell them to sign in to that account.
          setStep('signInWithExistingAccount');
        }
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        setStep('confirmationTokenUsed');
      } else {
        setStep('unknownError');
        setErrorFromResponse(error.response);
      }
    }
  }

  function setErrorFromResponse(response: any) {
    const errors = response?.data?.errors ?? [];
    setError(humanizeV3Error(errors[0]) ?? 'There was an error.');
  }

  function getPartnerDomainNames(partner: any) {
    if (!partner?.partnerSettings?.emailDomainWhitelistingEnabled) {
      // A partner can still have partnerDomains even if they disabled email domain whitelisting
      return [];
    }
    return partner.partnerDomains?.map((domain: any) => domain.domainName) ?? [];
  }

  function isEmailDomainAllowed(email: string, partner: any) {
    const emailDomain = email.split('@')[1] ?? '';
    const partnerDomainNames = getPartnerDomainNames(partner);
    const domainMatches = partnerDomainNames.includes(emailDomain);

    return partnerDomainNames.length === 0 || domainMatches;
  }

  function getUserEmailObject(user: any) {
    return user.emails.find((email: any) => email.address === user.email);
  }

  async function userEmailIsConfirmed(user: any) {
    const email = getUserEmailObject(user);
    return Boolean(email?.confirmed);
  }

  async function sendVerificationEmail(user) {
    const email = getUserEmailObject(user);
    console.log('sendVerificationEmail', email);
    try {
      await axios.post(`/api/v3/my/emails/${email.id}/send_confirmation_token`);
    } catch (error: any) {
      console.error(error);
      setErrorFromResponse(error.response);
    }
  }

  async function initializeJoinURLFlow(user: any) {
    try {
      const course = await getCourseFromJoinURL();
      if (!course) {
        setStep('joinUrlNotFound');
        return;
      }
      setCourse(course);
      const partner = course.partner;
      setPartner(partner);
      const partnerDomainNames = getPartnerDomainNames(partner);

      setSelectedDomain(partnerDomainNames[0] ?? '');

      if (user && !isEmailDomainAllowed(user.email, partner)) {
        setStep('wrongAccountJoinUrl');
      } else if (user && !(await userEmailIsConfirmed(user))) {
        // If user is logged into a valid account, but they haven't verified their email address then verify it
        // Otherwise they could just change their email to a whitelisted domain without actually having that email.
        await sendVerificationEmail(user);
        setStep('verify');
      } else if (user) {
        // If user is already logged in to a valid account, enroll them in the course and we're done
        await enrollInCourseIfNeeded(user, course);
        setStep('done');
      } else {
        // If user isn't already logged in then we need them to fill out the registration form.
        setStep('signUp');
      }
    } catch (error: any) {
      console.log('unknownError', error);
      setStep('unknownError');
      setErrorFromResponse(error.response);
    }
  }

  async function getCurrentUser() {
    try {
      const response = await axios.get('/api/v3/my/profile?include=emails');
      return normalizeJSONAPIResponse(response.data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        return null;
      } else {
        throw error;
      }
    }
  }

  async function enrollInCourseIfNeeded(user: any, course: PartialCourse) {
    try {
      console.log('enrollInCourseIfNeeded', user, course);
      await axios.get(`/api/v3/courses/${course.id}/users/${user.id}`);
    } catch (error: any) {
      if (error.response?.status === 404) {
        await axios.post(`/api/v3/courses/${course.id}/users`, {
          course_id: course.id,
          email: user.email,
          enrolled_via: 'enrolled_via_join_url', // They wouldn't get here if they were invited via email
          join_url_slug: course.slug,
        });
      } else {
        throw error;
      }
    }
  }

  async function getDataFromActivationToken(): Promise<ActivationTokenData> {
    const response = await axios.get(`/api/v3/activation_tokens/${confirmationToken}`, {
      params: {
        activation_token_type: isPartnerRegistration ? 'partner_invitation' : 'course_invitation',
      },
    });

    const responseMeta = response.data.meta;
    // Since we're not logged in we don't have access to the course or partner object so create from the meta
    const course: PartialCourse = {
      id: responseMeta['course-id'],
      name: responseMeta['course-name'],
      slug: null,
    };
    const partner: PartialPartner = {
      id: responseMeta['partner-id'],
      name: responseMeta['partner-name'],
      role: responseMeta['role'],
    };

    const user = normalizeJSONAPIResponse(response.data);

    return { user, course, partner, minPasswordLength: responseMeta['min-password-length'] };
  }
  const getCourseFromJoinURL = async () => {
    try {
      const response = await axios.get(`/api/v3/partners/${partnerSlug}/courses/${courseSlug}`, {
        params: {
          include: 'partner,partner.image,partner.partner_domains',
        },
      });

      const courseData = normalizeJSONAPIResponse(response.data);
      return courseData;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setError('');
    if (!partner || isSubmittingForm) {
      return;
    }
    setIsSubmittingForm(true);
    try {
      if (userFromToken) {
        await axios.put(`/api/v3/users/${userFromToken.id}`, {
          password,
          password_confirmation: passwordConfirmation,
          name,
          activation_token: confirmationToken,
          activation_token_type: isPartnerRegistration ? 'partner_invitation' : 'course_invitation',
        });
        setStep('done');
      } else {
        if (!course) {
          return;
        }

        const emailSuffix = selectedDomain ? `@${selectedDomain}` : '';
        const newUserResponse = await postWithCSRFToken('/api/v3/users?include=emails', {
          email: email + emailSuffix,
          password,
          password_confirmation: passwordConfirmation,
          name,
          course_id: course.id,
          partner_id: partner.id,
        });
        const newUser = normalizeJSONAPIResponse(newUserResponse.data);
        setCurrentUser(newUser);

        if (domainVerificationRequired) {
          sendVerificationEmail(newUser);
          setStep('verify');
        } else {
          await enrollInCourseIfNeeded(newUser, course);
          setStep('done');
        }
      }
    } catch (error: any) {
      console.error(error);
      setErrorFromResponse(error.response);
    } finally {
      setIsSubmittingForm(false);
    }
  };

  const partnerInviteTitle = partner
    ? `Confirm your invitation to the ${partner.name} Organization on Cerego.`
    : 'Loading...';
  const courseInviteTitle = course && partner ? `${partner.name} Invites You to Join ${course.name}` : 'Loading...';
  const title = isPartnerRegistration ? partnerInviteTitle : courseInviteTitle;
  const subtitle =
    isPartnerRegistration && partner
      ? `You will accept the role of ${formatPartnerUserRole(partner.role)} for this org.`
      : '';
  const disableEmailInput = !!userFromToken;

  const partnerDomainNames = course ? getPartnerDomainNames(partner) : [];

  async function onSubmitEmailVerificationCode(code: string) {
    if (!currentUser || !course) {
      console.error("Can't verify email without a user or course");
      return;
    }
    const emailObject = getUserEmailObject(currentUser);

    try {
      await axios.post(`/api/v3/my/emails/${emailObject.id}/verify_confirmation_token`, {
        confirmation_token: code,
      });
      await enrollInCourseIfNeeded(currentUser, course);
      setStep('done');
    } catch (error: any) {
      if (error.response?.status === 400) {
        setError('Invalid code. Please try a different code.');
      } else {
        setErrorFromResponse(error.response);
      }
    }
  }

  return (
    <>
      {step === 'signUp' && (
        <form onSubmit={handleRegistration}>
          <div className="mb-4">
            <PageTitle>{title}</PageTitle>
          </div>
          <h3 style={{ margin: '0 0 20px 0', fontSize: '16px', fontWeight: 'normal' }}>{subtitle}</h3>
          <div className="mb-4">
            <label className="font-bold">Full Name</label>
            <BrightInput
              className="!my-1"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g. Sarah Gogh"
              required
            />
          </div>

          <div className="mb-4">
            <label className="font-bold">Email Address</label>
            <BrightInput
              className="!my-1"
              placeholder=" "
              disabled={disableEmailInput}
              value={userFromToken?.email ?? email}
              onChange={(e) => setEmail(e.target.value)}
              required={!disableEmailInput}
            />
            {/* Domain name selector */}
            {partnerDomainNames.length > 0 && (
              <div style={{ marginTop: '5px' }}>
                <select
                  value={selectedDomain}
                  onChange={(e) => setSelectedDomain(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                >
                  {partnerDomainNames.map((domain) => (
                    <option key={domain} value={domain}>
                      @{domain}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="font-bold">Password</label>
            <BrightInput
              className="!my-1"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={minPasswordLength + '+ characters'}
              required
            />
          </div>

          <div className="mb-4">
            <label className="font-bold">Retype Password</label>
            <BrightInput
              className="!my-1"
              type="password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              placeholder={minPasswordLength + '+ characters'}
              required
            />
          </div>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <div className="mb-4">
            Already have an account?{' '}
            <StandardLink to={`/signin?redirect=${encodeURIComponent(currentPath)}`}>Sign In</StandardLink>
          </div>

          <PrimaryButton type="submit" pending={isSubmittingForm} click={() => {}}>
            Sign Up
          </PrimaryButton>
        </form>
      )}
      {step === 'loading' && <LoadingView />}
      {step === 'verify' && (
        <VerifyView
          error={error}
          email={currentUser.email}
          resendEmail={() => sendVerificationEmail(currentUser)}
          submit={onSubmitEmailVerificationCode}
        />
      )}
      {step === 'joinUrlNotFound' && <JoinUrlNotFoundView />}
      {step === 'confirmationTokenUsed' && <ConfirmationTokenUsedView />}
      {step === 'wrongAccountWarning' && <WrongAccountWarningView />}
      {step === 'wrongAccountJoinUrl' && (
        <WrongAccountJoinUrlView currentUser={currentUser} partnerDomains={partnerDomainNames} />
      )}
      {step === 'unknownError' && <UnknownErrorView error={error} />}
      {step === 'signInWithExistingAccount' && <SignInWithExistingAccountView user={userFromToken} />}
      {step === 'done' && <DoneView course={course} partner={partner} isPartnerRegistration={isPartnerRegistration} />}
    </>
  );
};

interface UnknownErrorViewProps {
  error: string;
}

function UnknownErrorView(props: UnknownErrorViewProps) {
  return (
    <div>
      <div className="mb-4">
        <PageTitle>Unknown Error</PageTitle>
      </div>
      <ErrorMessage>{props.error}</ErrorMessage>
    </div>
  );
}

interface WrongAccountJoinUrlViewProps {
  currentUser: any;
  partnerDomains: string[];
}

function WrongAccountJoinUrlView(props: WrongAccountJoinUrlViewProps) {
  const { currentUser, partnerDomains } = props;

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    setIsLoading(true);
    await dispatch(handleDestroySession());
    window.location.reload();
  };

  return (
    <div>
      <div className="mb-4">
        <PageTitle>Wrong Account</PageTitle>
      </div>
      <p>You're signed into an account ({currentUser.email}) with an email domain that doesn't match the whitelist.</p>
      <p>
        Please sign in with an account that has an email address ending in{' '}
        {partnerDomains.map((domain) => `@${domain}`).join(' or ')}.{' '}
      </p>
      <p>
        <PrimaryButton pending={isLoading} click={handleSignOut}>
          Sign Out
        </PrimaryButton>
      </p>
    </div>
  );
}

function WrongAccountWarningView() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    setIsLoading(true);
    await dispatch(handleDestroySession());
    window.location.reload();
  };

  return (
    <div>
      <div className="mb-4">
        <PageTitle>Wrong Account</PageTitle>
      </div>
      <p>You're signed into an account that doesn't match the confirmation token.</p>
      <p className="my-4">
        <PrimaryButton pending={isLoading} click={handleSignOut}>
          Sign Out
        </PrimaryButton>
      </p>
    </div>
  );
}

interface VerifyViewProps {
  error: string | null;
  email: string;
  resendEmail: () => Promise<void>;
  submit: (code: string) => void;
}

function VerifyView(props: VerifyViewProps) {
  const { email, resendEmail, submit } = props;
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function onClickResendEmail() {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      await resendEmail();
      alert("We've sent you another email with an eight-digit confirmation code.");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);
      await submit(code);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="mb-4">
        <PageTitle>Check Your Email</PageTitle>
      </div>
      <p>
        We’ve sent an eight-digit confirmation code to <b>{email}</b>. It will expire within 30 minutes, so enter your
        code soon.
      </p>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
      <div>
        <BrightInput
          className="!my-1"
          type="text"
          placeholder="Enter code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        <StandardLink onClick={onClickResendEmail}>Resend Email</StandardLink>

        <br />
        <br />
        <PrimaryButton pending={isLoading} click={handleSubmit}>
          Submit
        </PrimaryButton>
      </div>
    </div>
  );
}

function LoadingView() {
  return (
    <div>
      <h2>Loading...</h2>
    </div>
  );
}

interface SignInWithExistingAccountViewProps {
  user: PartialUser | null;
}
function SignInWithExistingAccountView(props: SignInWithExistingAccountViewProps) {
  const { user } = props;
  if (!user) {
    return null; // This should not happen
  }
  return (
    <>
      <div className="mb-4">
        <PageTitle>Sign In With Existing Account</PageTitle>
      </div>

      <p>
        {user.name ? `${user.name},` : ''} Please sign in to your <b>{user.email}</b> account.
      </p>
      <StandardLink to="/signin">Sign In</StandardLink>
    </>
  );
}

function JoinUrlNotFoundView() {
  return (
    <>
      <div className="mb-4">
        <PageTitle>Join URL Not Found</PageTitle>
      </div>
      <p>The join URL you used is not valid.</p>
    </>
  );
}

function ConfirmationTokenUsedView() {
  return (
    <>
      <div className="mb-4">
        <PageTitle>Confirmation Token Invalid</PageTitle>
      </div>
      <p>This confirmation token is invalid or has already been used.</p>
    </>
  );
}

interface DoneViewProps {
  course: PartialCourse | null;
  partner: PartialPartner | null;
  isPartnerRegistration: boolean;
}
function DoneView(props: DoneViewProps) {
  const { course, partner, isPartnerRegistration } = props;
  const [isLoading, setIsLoading] = useState(false);

  const goToDashboard = () => {
    setIsLoading(true);
    window.location.href = '/app/nav/v4/dashboard';
  };

  return (
    <div>
      <div className="mb-4">
        <PageTitle>You're all set!</PageTitle>
      </div>

      {isPartnerRegistration && <p>You're now a member of {partner?.name}.</p>}
      {!isPartnerRegistration && <p>Start learning {`${course?.name} with ${partner?.name}`}.</p>}

      <p className="my-4">
        <PrimaryButton click={goToDashboard} pending={isLoading}>
          Go to Dashboard
        </PrimaryButton>
      </p>
    </div>
  );
}
