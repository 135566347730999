import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { AnswerField } from 'components/item-editor/AnswerField';
import { useItemEditorStore } from 'components/item-editor/store';
import { useCreateDistractor } from 'hooks/useCreateDistractor';
import { useCreateFacet } from 'hooks/useCreateFacet';
import { useDistractors } from 'hooks/useDistractors';
import { useEffect, useState } from 'react';
import { TAnswer } from 'types';
import { getSuggestedDistractors } from 'utils/getSuggestedDistractors';

interface AnswersFormFieldsProps {
  wrongAnswersOnly?: boolean;

  /** Specifies the concept that the distractors will be attached to.
      Some item types attach distractors to the facet's anchor and some to the facet's association. */
  conceptIdForDistractors?: string;
  title?: string;
}

export const AnswersFormFields = (props: AnswersFormFieldsProps) => {
  const { wrongAnswersOnly = false, title } = props;
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();
  const firstFacet = item?.facets[0];
  const { conceptIdForDistractors = firstFacet.anchor.id } = props;
  const [answers, setAnswers] = useState<TAnswer[]>([]);
  const { data: distractors } = useDistractors(conceptIdForDistractors);
  const [createDistractor] = useCreateDistractor(item?.id || '', assignment?.id || '', conceptIdForDistractors);
  const [createFacet] = useCreateFacet(item?.id || '', assignment?.id || '');

  const isSurvey = assignment?.goalType === 'survey';

  function addAnswer(answer: TAnswer) {
    setAnswers([...answers, answer]);
  }
  function updateAnswer(conceptId: string, updates: Partial<TAnswer>) {
    setAnswers(answers.map((a) => (a.conceptId === conceptId ? { ...a, ...updates } : a)));
  }
  function removeAnswer(conceptId: string) {
    setAnswers(answers.filter((a) => a.conceptId !== conceptId));
  }
  const isQandA = ['question_and_answers', 'survey_question_and_answers'].includes(item?.templateType || '');
  const isVocab = item?.templateType === 'vocabulary';
  const conceptIdForDistractorsIsAnchor = conceptIdForDistractors === firstFacet.anchor.id;
  const questionIsAnchor = !conceptIdForDistractorsIsAnchor || isQandA; // Q&A is a special case
  const questionId = questionIsAnchor ? firstFacet.anchor.id : firstFacet.association.id;

  let questionForSmartAnswers = questionIsAnchor ? firstFacet.anchor.text : firstFacet.association.text;
  if (isVocab) {
    const questionPrefix = questionIsAnchor ? 'What is the native term for' : 'What is the foreign term for';
    questionForSmartAnswers = `${questionPrefix} "${questionForSmartAnswers}"?`;
  }

  // construct answers from the distractors (wrong answers) and facets (correct answers)
  const getAnswerFromFacet = (facet: any) => ({
    facetId: facet.id,
    questionId,
    conceptId: questionIsAnchor ? facet.association.id : facet.anchor.id,
    ...(questionIsAnchor ? facet.association : facet.anchor),
    isCorrect: true,
  });
  const correctAnswers = item?.facets?.map(getAnswerFromFacet) || [];

  const getAnswerFromDistractor = (distractor: any) => ({
    facetId: null,
    conceptId: distractor.id,
    questionId,
    ...distractor,
    isCorrect: false,
  });
  const wrongAnswers = distractors?.map(getAnswerFromDistractor) || [];

  useEffect(() => {
    setAnswers([...correctAnswers, ...wrongAnswers]);
  }, [distractors, item, wrongAnswersOnly]);

  const createNewAnswer = async (): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    let formattedAnswer: TAnswer;
    if (isSurvey) {
      if (answers.length >= 5) {
        console.warn('Survey questions have a maximum of 5 answers');
        return;
      }
      // All survey questions are correct answers
      const facet = await createFacet({ itemId: item.id, payload: null });
      formattedAnswer = getAnswerFromFacet(facet);
    } else {
      // create a new answer in the database
      // new answers for nonsurveys are always created as distractors, i.e. as incorrect answers
      const distractor = await createDistractor({
        conceptId: conceptIdForDistractors,
        payload: null,
      });
      formattedAnswer = getAnswerFromDistractor(distractor);
    }

    // create a new answer in the store
    addAnswer(formattedAnswer);
  };

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  const answersToShow = wrongAnswersOnly ? answers.filter((answer) => !answer.isCorrect) : answers;

  return (
    <Paper style={{ padding: '16px', marginBottom: '20px' }}>
      <Typography variant="h5" gutterBottom>
        {title ?? (wrongAnswersOnly ? 'Wrong Answer Options' : 'Answer Options')}
      </Typography>

      {answersToShow.map((answer) => (
        <AnswerField
          answer={answer}
          key={`answer-${answer.conceptId || ''}-${answer.facetId || ''}`}
          showAnswerCorrectnessToggle={!wrongAnswersOnly && !isSurvey}
          showExplanationField={!isSurvey}
          answers={answers}
          updateAnswer={updateAnswer}
          removeAnswer={removeAnswer}
        />
      ))}
      {isSurvey && answers.length >= 5 ? (
        <div>Survey questions have a maximum of 5 answers.</div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={createNewAnswer}
          style={{ marginBottom: '20px', padding: '10px' }}
        >
          Add another answer
        </Button>
      )}

      {!isSurvey && (
        <SmartAnswers
          conceptIdForDistractors={conceptIdForDistractors}
          question={questionForSmartAnswers}
          correctAnswers={correctAnswers.map((answer: any) => answer.text)}
          addAnswer={addAnswer}
        />
      )}
    </Paper>
  );
};

interface SmartAnswerProps {
  conceptIdForDistractors: string;
  question: string;
  correctAnswers: string[];
  addAnswer: (answer: TAnswer) => void;
}

const SmartAnswers = (props: SmartAnswerProps) => {
  const { question, correctAnswers, conceptIdForDistractors, addAnswer } = props;
  const { activeAssignment: assignment, activeItem: item } = useItemEditorStore();
  const [suggestedDistractors, setSuggestedDistractors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [createDistractor] = useCreateDistractor(item?.id || '', assignment?.id || '', conceptIdForDistractors);

  const handleGenerateSuggestedDistractors = async () => {
    try {
      setLoading(true);
      if (question && correctAnswers) {
        getSuggestedDistractors('suggestedDistractors', question, correctAnswers).then((distractors: string[]) =>
          setSuggestedDistractors(distractors)
        );
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestedDistractorClick = async (distractor: string) => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    try {
      if (!distractor) {
        console.warn('No distractors to add');
        return;
      }

      const newAnswer = await createDistractor({
        conceptId: conceptIdForDistractors,
        payload: {
          attributes: {
            text: distractor,
            'text-html': distractor,
            'allow-random-distractors': true,
            'explanation-html': '',
            explanation: '',
            'created-at': new Date().toISOString(),
            'is-studiable': true,
          },
          relationships: {
            image: {
              data: null,
            },
          },
        },
      });

      const formattedAnswer: TAnswer = {
        facetId: null,
        conceptId: newAnswer.id,
        questionId: item.facets[0].anchor.id,
        isCorrect: false,
        text: newAnswer.text,
        textHtml: newAnswer.textHtml,
        explanation: newAnswer.explanation,
        explanationHtml: newAnswer.explanationHtml,
        image: newAnswer.image,
        sound: null,
      };

      addAnswer(formattedAnswer);

      // remove distractor from suggested distractors
      setSuggestedDistractors(suggestedDistractors.filter((d: string) => d !== distractor));
    } catch (error: any) {
      console.error(error);
    }
  };

  // TODO: add loading state to generate button
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Smart Answers
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleGenerateSuggestedDistractors}
        style={{ marginBottom: '20px', padding: '10px' }}
        disabled={loading || suggestedDistractors.length > 0}
      >
        Generate Suggested Distractors
      </Button>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        {suggestedDistractors.length > 0 &&
          suggestedDistractors.map((distractor: string) => (
            <Button
              onClick={() => handleSuggestedDistractorClick(distractor)}
              variant="text"
              color="primary"
              style={{ marginBottom: '20px', padding: '10px' }}
              key={distractor}
            >
              <Typography variant="body1" gutterBottom key={distractor}>
                {distractor}
              </Typography>
            </Button>
          ))}
      </div>
    </>
  );
};
