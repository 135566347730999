import axios from 'axios';
import { queryCache, useMutation } from 'react-query';

const updateSet = ({ id, payload }) => {
  return axios.put(`/api/v3/sets/${id}`, payload);
};

const useMutateSet = (courseId: string = '') => {
  const staleQueryKey: Array<string> | string = courseId ? ['assignments', courseId] : 'assignments';

  return useMutation(updateSet, {
    onError: (error: any) => {
      console.error(`ERROR! ${error}`);
    },
    onSettled: () => {
      queryCache.invalidateQueries(staleQueryKey);
    }
  });
};

export default useMutateSet;
