import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { DropDown, DropDownList, DropDownListItem } from 'components/DropDown';
import { BrightInput } from 'components/forms/BrightInput';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Box, Content } from 'components/modals/common';
import { ContentSection, PageContainer, PageSubTitle, PageTitle, TitleSection } from 'core/layout';
import { ErrorMessage } from 'core/typography';
import * as _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import { getBaseReactUrl } from 'utils/pathUtils';

interface ICreateNewOAuthPageProps {
  match: { params: { partnerSlug: string } };
}

const CaretDown = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

const ClientInputHeader = styled.h3`
  margin-bottom: 0px;
`;

const OauthClientTypes = {
  LTI_12: { name: 'LTI 1.1/1.2/SAML', slug: 'lti_12' },
  LTI_13: { name: 'LTI 1.3', slug: 'lti_13' }
};

export const CreateNewOAuthPage = React.memo<ICreateNewOAuthPageProps>(props => {
  const { partnerSlug } = props.match.params;
  const [clientType, setClientType] = React.useState(OauthClientTypes['LTI_12']);
  const [showLTIVersionMenu, setShowLTIVersionMenu] = React.useState<boolean>(false);
  const [showPrefillLMSMenu, setShowPrefillLMSMenu] = React.useState<boolean>(false);
  const [saveOAuthClientIsPending, setSaveOAuthClientIsPending] = React.useState<boolean>(false);
  const [clientName, setClientName] = React.useState<string>();
  const [clientWebsite, setClientWebsite] = React.useState<string>('https://canvas.instructure.com');
  const [clientRedirectURI, setClientRedirectURI] = React.useState<string>(
    'https://canvas.instructure.com/api/lti/authorize_redirect'
  );
  const [clientAccessTokenURL, setClientAccessTokenURL] = React.useState<string>(
    'https://canvas.instructure.com/login/oauth2/token'
  );
  const [clientPublicJWKURL, setClientPublicJWKURL] = React.useState<string>(
    'https://canvas.instructure.com/api/lti/security/jwks'
  );
  const [showError, setShowError] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const saveOAuthClient = async () => {
    setSaveOAuthClientIsPending(true);

    let oauthCreationData = {
      id: partnerSlug,
      client_type: clientType.slug,
      name: clientName,
      website: clientWebsite,
      access_token_url: clientAccessTokenURL,
      redirect_uri: clientRedirectURI
    };

    //Public JWK URL only available for LTI 1.3
    if (clientType.slug === 'lti_13') {
      oauthCreationData['public_jwk_url'] = clientPublicJWKURL;
    }
    try {
      const response = await axios.post(`/api/v3/partners/${partnerSlug}/oauth2_clients`, oauthCreationData);
      setSaveOAuthClientIsPending(false);
      if (clientType.slug === 'lti_12') {
        window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerSlug}/oauth`;
      } else {
        window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerSlug}/oauth/${response.data.data.id
          }/edit?firstSetup=true`;
      }
    } catch {
      setSaveOAuthClientIsPending(false);
      setShowError(true);
    }
  };

  const onCancel = () => {
    window.location.href = `${getBaseReactUrl()}/app/nav/partners/${partnerSlug}/oauth`;
  };

  const selectLTIVersion = value => {
    setClientType(value);
    setShowLTIVersionMenu(false);
  };

  const selectPrefillLMS = value => {
    if (value === 'Canvas') {
      setClientWebsite('https://canvas.instructure.com');
      setClientRedirectURI('https://canvas.instructure.com/api/lti/authorize_redirect');
      setClientAccessTokenURL('https://canvas.instructure.com/login/oauth2/token');
      setClientPublicJWKURL('https://canvas.instructure.com/api/lti/security/jwks');
    } else if (value === 'Brightspace') {
      setClientWebsite('https://your-domain.d2l-partners.brightspace.com');
      setClientRedirectURI('https://your-domain.d2l-partners.brightspace.com/d2l/lti/authenticate');
      setClientAccessTokenURL('https://auth.brightspace.com/core/connect/token');
      setClientPublicJWKURL('https://your-domain.d2l-partners.brightspace.com/d2l/.well-known/jwks');
    }
  };

  const validLTIClient = () => {
    return (
      (clientType.slug === 'lti_12' && !_.isEmpty(clientName)) ||
      (clientType.slug === 'lti_13' &&
        !_.isEmpty(clientName) &&
        !_.isEmpty(clientWebsite) &&
        !_.isEmpty(clientRedirectURI) &&
        !_.isEmpty(clientAccessTokenURL) &&
        !_.isEmpty(clientPublicJWKURL))
    );
  };

  return (
    <PageContainer>
      <TitleSection>
        <PageTitle>{t('New OAuth Client')}</PageTitle>
        <PageSubTitle>{t('For use with LTI and SAML integrations')}</PageSubTitle>
      </TitleSection>
      <ContentSection>
        <Box>
          <Content>
            <OutsideClickHandler onOutsideClick={() => setShowLTIVersionMenu(false)}>
              <a onClick={() => setShowLTIVersionMenu(!showLTIVersionMenu)}>
                {clientType.name}
                <CaretDown icon={showLTIVersionMenu ? faCaretUp : faCaretDown} />
              </a>
              {showLTIVersionMenu && (
                <DropDown>
                  <DropDownList>
                    <DropDownListItem>
                      <button onClick={() => selectLTIVersion(OauthClientTypes['LTI_12'])}>
                        <div>{OauthClientTypes['LTI_12'].name}</div>
                      </button>
                    </DropDownListItem>
                    <DropDownListItem>
                      <button onClick={() => selectLTIVersion(OauthClientTypes['LTI_13'])}>
                        <div>{OauthClientTypes['LTI_13'].name}</div>
                      </button>
                    </DropDownListItem>
                  </DropDownList>
                </DropDown>
              )}
            </OutsideClickHandler>
            <ClientInputHeader>{t('Client Name')}</ClientInputHeader>
            <BrightInput
              placeholder={'Cerego LTI Canvas OAuth'}
              autoFocus={true}
              onChange={e => setClientName(e.target.value)}
              required={true}
            />
            {clientType.slug === 'lti_13' && (
              <div>
                <OutsideClickHandler onOutsideClick={() => setShowPrefillLMSMenu(false)}>
                  <a onClick={() => setShowPrefillLMSMenu(!showPrefillLMSMenu)}>
                    {t('Prefill Values for LMS')}
                    <CaretDown icon={showPrefillLMSMenu ? faCaretUp : faCaretDown} />
                  </a>
                  {showPrefillLMSMenu && (
                    <DropDown>
                      <DropDownList>
                        <DropDownListItem>
                          <button onClick={() => selectPrefillLMS('Canvas')}>
                            <div>Canvas</div>
                          </button>
                        </DropDownListItem>
                        <DropDownListItem>
                          <button onClick={() => selectPrefillLMS('Brightspace')}>
                            <div>Brightspace</div>
                          </button>
                        </DropDownListItem>
                      </DropDownList>
                    </DropDown>
                  )}
                </OutsideClickHandler>

                <ClientInputHeader>{t('Client Website')}</ClientInputHeader>
                <div>{t('This must match the client that is issuing keys')}</div>
                <BrightInput
                  placeholder={'https://canvas.instructure.com'}
                  onChange={e => setClientWebsite(e.target.value)}
                  value={clientWebsite}
                  required={true}
                />
                <ClientInputHeader>{t('Client Redirect URI')}</ClientInputHeader>
                <div>{t('This must match the OpenID redirect given by the LMS')}</div>
                <BrightInput
                  placeholder={'https://canvas.instructure.com/api/lti/authorize_redirect'}
                  onChange={e => setClientRedirectURI(e.target.value)}
                  value={clientRedirectURI}
                  required={true}
                />
                <ClientInputHeader>{t('Client Access Token URL')}</ClientInputHeader>
                <div>{t('This must match the OAuth token endpoint given by the LMS')}</div>
                <BrightInput
                  placeholder={'https://canvas.instructure.com/login/oauth2/token'}
                  onChange={e => setClientAccessTokenURL(e.target.value)}
                  value={clientAccessTokenURL}
                  required={true}
                />
                <ClientInputHeader>{t('Client Public JWK URL')}</ClientInputHeader>
                <div>{t('This URL must give a response in the form of:')}</div>
                <div></div>
                <div>{'keys: [{ kty:RSA, e:AQAB, n:---, kid:2000-01-01T00:00:00Z, alg:RS246, use:sig }, ...]'}</div>
                <BrightInput
                  placeholder={'https://canvas.instructure.com/api/lti/security/jwks'}
                  onChange={e => setClientPublicJWKURL(e.target.value)}
                  value={clientPublicJWKURL}
                  required={true}
                />
              </div>
            )}
            {showError && (
              <div>
                <ErrorMessage>{t('An error occurred while saving your oauth client.')}</ErrorMessage>
                {clientType.slug === 'lti_13' && (
                  <ErrorMessage>{t('Please ensure your client public JWK URL gives a valid response.')}</ErrorMessage>
                )}
              </div>
            )}
            <ConfirmOrCancel
              cancelText={t('Cancel')}
              confirmText={clientType.slug === 'lti_13' ? t('Next') : t('Create')}
              handleCancelClick={onCancel}
              handleConfirmClick={saveOAuthClient}
              confirmIsDisabled={!validLTIClient()}
              confirmIsPending={saveOAuthClientIsPending}
            />
          </Content>
        </Box>
      </ContentSection>
    </PageContainer>
  );
});
