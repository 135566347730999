import * as Plot from '@observablehq/plot';
import { useEffect, useRef } from 'react';
import * as Color from '../../core/colors';
import StripePattern from './assets/StripePattern';
import XTicks from './Atoms/XTicks';
import { COLOR, FILL } from './constants';

export default function MemreProgressBar({ data }: { data: number[] }) {
  const chartRef = useRef<HTMLDivElement>(null);
  const linePatternId = 'progress-bar-line-pattern';

  useEffect(() => {
    const chart = Plot.plot({
      marks: [
        Plot.rectY([{}], {
          x1: 0,
          x2: data[0] - 0.5,
          y1: 0,
          y2: 1,
          fill: FILL.BLACK,
        }),
        Plot.rectY([{}], {
          x1: data[0] + 0.5,
          x2: data[0] + data[1] - 0.5,
          y1: 0,
          y2: 1,
          fill: COLOR.DARK_BLUE,
        }),
        Plot.rectY([{}], {
          x1: data[0] + data[1] + 0.5,
          x2: 100,
          y1: 0,
          y2: 1,
          fill: COLOR.BLUE,
        }),
        Plot.rectY([{}], {
          x1: data[0] + 0.5,
          x2: data[0] + data[1] - 0.5,
          y1: 0,
          y2: 1,
          fill: `url(#${linePatternId})`,
        }),
        ...XTicks({}),
      ],
      height: 90,
      width: 400,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      x: {
        domain: [0, 100],
      },
    });

    if (chartRef.current) {
      chartRef.current?.appendChild(chart);
    }

    return () => {
      chart.remove();
    };
  }, [data]);

  return (
    <>
      <StripePattern id={linePatternId} color={Color.hexToRGB(Color.reportBlue, 0.4, 0.3)} />
      <div ref={chartRef} />
    </>
  );
}
