import React from 'react';
import styled from 'styled-components';
import generateId from 'utils/generateId';
import { Color } from '../core';

interface ISideNavSection {
  onClick: () => void;
  isActive: boolean;
  label: string;
  id?: string;
}

const NavSection = styled.button`
  height: 50px;
  cursor: pointer;
  &:hover {
    background-color: ${Color.primaryBlack};
  }
  line-height: 50px;
  padding: 0px;
  role: button;
  background: transparent;
  border: none;
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 16px;
`;

export const SideNavSection = React.memo<ISideNavSection>((props) => {
  const SideNavSectionLabel = styled.h2`
    color: ${Color.primaryGray};
    font-size: 0.875em;
    margin: 0;
    text-align: left;
    padding: 0px 20px;
    background-color: ${props.isActive ? Color.primaryBlack : 'inherit'};
  `;

  return (
    <NavSection onClick={props.onClick}>
      <SideNavSectionLabel id={!!props.id ? props.id : generateId()}>{props.label}</SideNavSectionLabel>
    </NavSection>
  );
});
