import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardContent from '@material-ui/core/CardContent';
import { Color } from 'core';
import { CardHeader } from 'core/layout';
import { useTranslation } from 'react-i18next';
import { SUPPORT_URLS } from 'utils/constants';

export const IntroCard = () => {
  const { t } = useTranslation();

  return (
    <CardContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <CardHeader>{t('Turn any topic into a fully-featured course')}</CardHeader>
      <button
        onClick={() => window.open(SUPPORT_URLS.SMART_CREATE, '_blank')}
        style={{ backgroundColor: 'inherit', border: 'none', color: Color.textGray }}
      >
        <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
      </button>
    </CardContent>
  );
};
