import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';

const getPartnerSummaryCounts = async (_key: string, partnerId: string | null) => {
  if (!partnerId) {
    console.warn('Cannot get partner summary counts without partnerId');
    return;
  }

  const response = await axios.get(`/api/v3/partners/${partnerId}/summary_counts`);
  return normalizeJSONAPIResponse(response.data);
};

export const usePartnerSummaryCounts = () => {
  const user = useCurrentUser();
  const partner = user?.primaryPartner;

  return useQuery(['summaryCounts', partner?.id], getPartnerSummaryCounts, {
    enabled: !!partner?.id,
  });
};
