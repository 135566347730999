import { Color, Size } from 'core';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PrimaryButton, SecondaryButton } from 'components/buttons/v4';

interface IOwnProps extends WithTranslation {
  ref: any;
  messaging: any;
  btnText: any;
  progress: number;
  handlePrimaryBtnClick: any;
  handleSecondaryBtnClick: any;
  showPrimaryButton: boolean;
  showDetails: boolean;
}

const Container = styled.div`
  flex: 1 1 auto;
  padding: 20px;
  padding-bottom: 20%;
  position: relative;
  text-align: center;
  @media (max-width: ${Size.phoneWidth}px) {
    font-size: 0.8rem;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  font-weight: 900;
  font-size: 3em;
  color: ${Color.darkBlueGray};
  margin-top: 1em;
  text-transform: uppercase;
  max-width: 800px;
  @media (max-width: ${Size.phoneWidth}px) {
    font-size: 2.5em;
  }
`;

const Subheader = styled.div`
  color: ${Color.darkBlueGray};
  margin-top: 10px;
  margin-bottom: 40px;
  max-width: 800px;
  @media (max-width: ${Size.phoneWidth}px) {
    margin-bottom: 20px;
  }
`;

const Detail = styled.div`
  position: absolute;
  left: 50px;
  bottom: 50px;
  font-weight: 900;
  color: ${Color.darkBlueGray};
  text-transform: uppercase;
  @media (max-width: ${Size.phoneWidth}px) {
    bottom: auto;
    left: 10px;
    top: 20px;
    font-size: 0.6em;
  }
`;

const DetailHighlight = styled.span`
  color: ${Color.ceregoGreen};
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: auto;
  width: 50%;
  @media (max-width: ${Size.phoneWidth}px) {
    width: 100%;
  }
`;

const SecondaryButtonWithMargin = styled(SecondaryButton)`
  margin-top: 1em;
`;

function UserSessionJourneyCard(props: IOwnProps) {
  const { primaryText, secondaryText, image } = props.messaging;
  const { primaryBtnText, secondaryBtnText } = props.btnText;
  const { progress, handlePrimaryBtnClick, handleSecondaryBtnClick, showDetails, t } = props;
  const progressString = `${Math.floor(progress * 100)}%`;
  return (
    <Container ref={props.ref}>
      <Content>
        <Header>{primaryText}</Header>
        <Subheader>{secondaryText}</Subheader>
        {props.showPrimaryButton && (
          <PrimaryButton click={handlePrimaryBtnClick} shape="pill">
            {primaryBtnText}
          </PrimaryButton>
        )}
        {secondaryBtnText && (
          <SecondaryButtonWithMargin click={handleSecondaryBtnClick} shape="pill">
            {secondaryBtnText}
          </SecondaryButtonWithMargin>
        )}
      </Content>
      <Image src={require(`../../assets/images/user_session_journey/${image}`)} />
      {showDetails && (
        <Detail>
          {t('Assignment Progress:')}&nbsp;
          <DetailHighlight>{t('User Session Journey Card progress', { progressString })}</DetailHighlight>
        </Detail>
      )}
    </Container>
  );
}

export default withTranslation()(UserSessionJourneyCard);
