import React from 'react';
import { ClickToEdit } from 'components/ClickToEdit';
import { ContentText } from 'courses/components/ContentText';
import { TAssignment } from 'types';

interface Props {
  assignment: TAssignment;
  handleClickToEdit: (offset: number[], assignmentId: string, columnName: string) => void;
}

export const GoalCell = (props: Props) => {
  const { assignment, handleClickToEdit } = props;

  const getText = () => {
    const levelNum = Number(assignment.meta.levelGoal);
    if (levelNum < 1) {
      return 'Exposure';
    }

    if (levelNum < 2) {
      return 'Proficiency';
    }

    return 'Mastery';
  };

  if (assignment.goalType !== 'set' && assignment.goalType !== 'scorm') {
    return <ContentText />;
  }

  return (
    <ClickToEdit id={assignment.id} column={'levelGoal'} click={handleClickToEdit}>
      <ContentText text={getText()} textDecoration="underline" />
    </ClickToEdit>
  );
};
