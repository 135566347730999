import { PresentationScreens } from '../../models/presentation';

const AUTO_PAUSE_TIMEOUT_MSEC = 5 * 60 * 1000;
let timerId;

export interface ITimer {
  isActive: boolean;
  // Time of presentation start, or time of re-start after the timeout modal
  currentStartTime: Date;
  // Stores how much time has passed in between timeout modal pauses
  currentElapsedTime: number;
  // These keys correspond to attributes in presentation
  studyTimeMillis: number;
  recallTimeMillis: number;
  quizTimeMillis: number;
  confirmationTimeMillis: number;
}

const initialState: ITimer = {
  isActive: false,
  currentStartTime: new Date(),
  currentElapsedTime: 0,
  studyTimeMillis: 0,
  recallTimeMillis: 0,
  quizTimeMillis: 0,
  confirmationTimeMillis: 0
};

export const TIMER_PAUSE = 'TIMER_PAUSE';
export const TIMER_START = 'TIMER_START';
export const TIMER_STOP = 'TIMER_STOP';
export const TIMER_CLEAR = 'TIMER_CLEAR';

export default function(state = initialState, action): ITimer {
  switch (action.type) {
    case TIMER_START:
      return { ...state, isActive: true, currentStartTime: new Date() };
    case TIMER_PAUSE:
      const currentElapsedTime = new Date().getTime() - state.currentStartTime.getTime() + state.currentElapsedTime;
      return { ...state, isActive: false, currentElapsedTime };
    case TIMER_STOP:
      const timeStoppedAt = new Date();
      const finalElapsedTime = timeStoppedAt.getTime() - state.currentStartTime.getTime() + state.currentElapsedTime;
      let currentTimer;
      switch (action.screen) {
        case PresentationScreens.Quiz:
          currentTimer = 'quizTimeMillis';
          break;
        case PresentationScreens.Recall:
          currentTimer = 'recallTimeMillis';
          break;
        case PresentationScreens.Confirmation:
          currentTimer = 'confirmationTimeMillis';
          break;
      }
      return {
        ...state,
        [currentTimer]: finalElapsedTime,
        // study time is the elapsed time across all screens
        studyTimeMillis: state.studyTimeMillis + finalElapsedTime,
        currentElapsedTime: 0,
        isActive: false
      };
    case TIMER_CLEAR:
      return {
        ...initialState,
        currentStartTime: new Date()
      };
    default:
      return state;
  }
}

export function handleTimerPause() {
  return dispatch => {
    dispatch({
      type: TIMER_PAUSE
    });
  };
}

export function handleTimerStart() {
  return (dispatch, getState) => {
    const isActive = getState().learn.timer.isActive;

    clearTimeout(timerId);
    timerId = setTimeout(() => {
      dispatch(handleTimerPause());
    }, AUTO_PAUSE_TIMEOUT_MSEC);

    !isActive &&
      dispatch({
        type: TIMER_START
      });
  };
}

export function handleTimerStop(screen: PresentationScreens) {
  return dispatch => {
    dispatch({
      type: TIMER_STOP,
      screen
    });
  };
}

export function handleTimerClear() {
  return dispatch => {
    dispatch({
      type: TIMER_CLEAR
    });
  };
}
