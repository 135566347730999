import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { useItemEditorStore } from 'components/item-editor/store';
import { useAssignmentItems } from 'hooks/useAssignmentItems';
import { IItem } from 'models/item';

export const AttachIc = () => {
  const { activeAssignment: assignment, activeItem: item, updateActiveItem } = useItemEditorStore();
  const { data: itemsData } = useAssignmentItems(assignment?.id || '');

  // filter itemsData to only include instructional content
  const icData = itemsData.filter((item: IItem) => item.templateType === 'instructional_contents');

  const attachedIC = item && item.attachedItems.length > 0 ? item.attachedItems[0] : null;

  const attachIC = async (event: any): Promise<void> => {
    if (!item) {
      console.warn('Item not found');
      return;
    }

    const icId = event.target.value;

    // update the item in the store
    const newItem = icData.find((item: IItem) => item.id === icId);
    updateActiveItem({ attachedItems: [newItem] });

    // update item in the database
    // delete the old attached item, if present
    if (item.attachedItems.length > 0) {
      await axios.delete(`/api/v3/items/${item.id}/attached_items/${item.attachedItems[0].id}`);
    }
    // then create a new one
    await axios.post(`/api/v3/items/${item.id}/attached_items`, {
      data: {
        attributes: {
          attached_item_id: icId,
        },
      },
    });

    // TODO: handle error
    // roll back the optimistic ui update
    // show a toast?
  };

  if (!item || !assignment) {
    console.warn('Item or assignment not found');
    return null;
  }

  return (
    <FormControl variant="outlined">
      <InputLabel id="attach-ic-label">Attach Instructional Content</InputLabel>
      <Select
        labelId="attach-ic-label"
        id="attach-ic"
        value={attachedIC ? attachedIC.id : ''}
        label="Attach Instructional Content"
        onChange={attachIC}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {icData.map((ic: IItem) => (
          <MenuItem key={ic.id} value={ic.id}>
            {ic.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    );
};
