import learnerBubbles from 'assets/images/learner-bubbles.svg';
import { MemreContainer, MemreFlexBox, MemreSection, MemreText } from 'components/core';
import { Color } from 'core';
import { useTranslation } from 'react-i18next';
import { getBaseReactUrl } from 'utils/pathUtils';

export default function KnowledgeBankCTA() {
  const { t } = useTranslation();
  const goToKnowledgeBank = () => {
    window.location.href = `${getBaseReactUrl()}/app/nav/library`;
  };

  return (
    <MemreSection sx={{ flex: 1, cursor: 'pointer', borderRadius: '6px' }}>
      <div onClick={goToKnowledgeBank}>
        <MemreContainer
          size="large"
          sx={{
            backgroundImage: `url(${learnerBubbles})`,
            backgroundSize: '120% 120%',
            backgroundPosition: '120px',
            backgroundColor: Color.black,
            borderRadius: '6px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
          }}
        >
          <MemreFlexBox direction="column" gap={2}>
            <MemreText variant="h4" sx={{ color: Color.white }}>
              {t('Knowledge Bank')}
            </MemreText>
            <MemreText sx={{ color: Color.white }}>{t('Visualize your knowledge →')}</MemreText>
          </MemreFlexBox>
        </MemreContainer>
      </div>
    </MemreSection>
  );
}
