import { Size } from 'core';
import { useSequence } from 'hooks/useSequence';
import { isRightToLeft } from 'i18n';
import { CorrectSequenceBody } from 'learn/components/CorrectSequenceBody';
import { IncorrectSequenceBody } from 'learn/components/IncorrectSequenceBody';
import { SequenceItem } from 'learn/components/SequenceItem';
import { TitleText } from 'learn/components/TitleText';
import { UnsubmittedSequenceBody } from 'learn/components/UnsubmittedSequenceBody';
import _ from 'lodash';
import { IQuiz, IQuizPart } from 'models/quiz';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  quiz: IQuiz;
  onSequenceChange: (sequenceParts: IQuizPart[], isCorrect: boolean) => void;
  showAnswers?: boolean;
  isSubmitted: boolean;
  classes?: any;
}

const QuizSequence: FC<Props> = ({ quiz, onSequenceChange, showAnswers = false, isSubmitted, classes }) => {
  const { t } = useTranslation();
  const rightToLeft = isRightToLeft();

  const {
    items,
    setItems,
    showCorrectOrder,
    setShowCorrectOrder,
    selectedTab,
    flipKey,
    correctCount,
    isSequenceCorrect,
    showUserAnswers,
    showCorrectOrderItems,
    ariaLabel,
  } = useSequence({ quiz, isSubmitted, showAnswers, onSequenceChange });

  const renderSequenceItems = useCallback(
    (isDraggable: boolean, displayItems: IQuizPart[]) =>
      displayItems.map((item, index) => (
        <SequenceItem isDraggable={isDraggable} item={item} index={index} key={item.id} />
      )),
    []
  );

  const displayItems = showCorrectOrder ? _.orderBy(items, 'correctPosition') : items;

  let viewState: 'unsubmitted' | 'correct' | 'incorrect' | null;
  if (!isSubmitted) {
    viewState = 'unsubmitted';
  } else if (isSequenceCorrect(items)) {
    viewState = 'correct';
  } else if (!isSequenceCorrect(items)) {
    viewState = 'incorrect';
  } else {
    viewState = null;
  }

  return (
    <>
      <AriaLiveAnnouncer message={ariaLabel} />
      <Container>
        <TitleText
          text={t('Move the blocks into the right order')}
          style={{ margin: '20px 0 10px 0', textAlign: rightToLeft ? 'right' : 'left' }}
        />

        {viewState === 'unsubmitted' && (
          <UnsubmittedSequenceBody
            renderSequenceItems={renderSequenceItems}
            items={items}
            setItems={setItems}
            setShowCorrectOrder={setShowCorrectOrder}
            displayItems={displayItems}
          />
        )}

        {viewState === 'incorrect' && (
          <IncorrectSequenceBody
            correctCount={correctCount()}
            items={items}
            rightToLeft={rightToLeft}
            selectedTab={selectedTab}
            showUserAnswers={showUserAnswers}
            showCorrectOrderItems={showCorrectOrderItems}
            flipKey={flipKey}
            renderSequenceItems={renderSequenceItems}
            displayItems={displayItems}
          />
        )}

        {viewState === 'correct' && (
          <CorrectSequenceBody
            renderSequenceItems={renderSequenceItems}
            isSequenceCorrect={isSequenceCorrect}
            flipKey={flipKey}
            items={items}
            displayItems={displayItems}
          />
        )}
      </Container>
    </>
  );
};

export default QuizSequence;

const Container = styled.div`
  margin: 0px auto;
  max-width: ${Size.defaultCardWidth}px;
`;

const AriaLiveAnnouncer: FC<{ message: string }> = ({ message }) => (
  <div
    role="status"
    aria-live="polite"
    style={{ position: 'absolute', width: '1px', height: '1px', overflow: 'hidden', padding: 0 }}
  >
    {message}
  </div>
);
