import { useEffect, useState } from 'react';

interface Signature {
  totalCount: number | null;
  totalPages: number | null;
}

export const usePaginatedMeta = (response: any): Signature => {
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);

  useEffect(() => {
    if (!!response) {
      setTotalCount(response.data.meta['total-count']);
      setTotalPages(response.data.meta['total-pages']);
    }
  }, [JSON.stringify(response)]);

  return { totalCount, totalPages };
};
