import { devlog, immer } from 'zstore';
import create from 'zustand';

type StaffState = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  name: string;
  setName: (name: string) => void;
  sortColumn: string;
  setSortColumn: (sortColumn: string) => void;
  sortDirection: 'asc' | 'desc';
  setSortDirection: (sortDirection: 'asc' | 'desc') => void;
};

const makeStaffStore = () => {
  return create<StaffState>(
    devlog(
      immer((set: any) => ({
        pageNumber: 1,
        setPageNumber: (pageNumber: number) => set(() => ({ pageNumber })),
        pageSize: 10,
        setPageSize: (pageSize: number) => set(() => ({ pageSize })),
        name: '',
        setName: (name: string) => set(() => ({ name })),
        sortColumn: 'name',
        setSortColumn: (sortColumn: string) => set(() => ({ sortColumn })),
        sortDirection: 'asc',
        setSortDirection: (sortDirection: 'asc' | 'desc') => set(() => ({ sortDirection })),
      }))
    )
  );
};

export const useStaffStore = makeStaffStore();
