import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControlLabel, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { Color } from 'core';
import AssignmentFormHeader from 'courses/components/AssignmentFormHeader';
import LevelGoalInput from 'courses/components/LevelGoalInput';
import useMutateAssignment from 'hooks/useMutateAssignment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useStore from 'zstore';

interface IAssignmentGoalForm {
  courseId: string;
  assignment: any;
  assignmentGoal: string;
  courseAssignmentIds: Array<string>;
  onCancel: () => void;
}

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Color.primaryGray};
  overflow-y: scroll;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  min-height: 175px;
  margin: 0px auto 5px auto;
  padding-bottom: 25px;
  color: ${Color.primaryBlack};
`;

const MainContentContainer = styled.div`
  background-color: ${Color.white};
`;

const tickHeight = 25;
const trackHeight = 9;
const thumbDim = 50;

const StyledSlider = withStyles({
  root: {
    color: `${Color.primaryBlack}`,
    height: trackHeight,
  },
  active: {},
  track: {
    height: trackHeight,
  },
  rail: {
    height: trackHeight,
    opacity: 0.5,
  },
  thumb: {
    background: 'transparent',
  },
  valueLabel: {
    left: '-19px',
    top: '-45px',
    lineHeight: 2.1,
    '& *': {
      background: `${Color.primaryBlue}`,
      color: `${Color.primaryGray}`,
      height: thumbDim,
      width: thumbDim,
    },
    '& span > span': {
      borderRadius: '50%',
      fontSize: '2em',
      fontWeight: 700,
    },
  },
  mark: {
    height: tickHeight + trackHeight,
    width: 2,
    marginTop: -0.5 * tickHeight,
    backgroundColor: `${Color.primaryBlack}`,
  },
  markActive: {
    height: tickHeight + trackHeight,
    width: 2,
    marginTop: -0.5 * tickHeight,
    backgroundColor: `${Color.primaryBlack}`,
    opacity: 1,
  },
})(Slider);

const valuetext = (value: number): string => {
  return `Level ${value.toFixed(1)}`;
};

const valueLabelFormat = (value: number) => {
  return `${value.toFixed(1)}`;
};

const MasteryRegion = styled.div`
  flex: 1;
  border: 1px solid ${Color.veryLightGray};
  padding: 25px 10px 10px 10px;
  background-color: ${Color.primaryGray};
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: -20px;
`;

const CurrentGoalContainer = styled.div`
  flex: 1;
  border: 1px solid ${Color.primaryGray};
`;

const styles = {
  formTitle: {
    justifyContent: 'center',
    fontSize: '2em',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.71px',
    marginTop: '0',
  },
  input: {
    fontSize: '1.2em',
    color: `${Color.primaryBlue}`,
    fontWeight: 500,
  },
  activeRegion: {
    color: `${Color.primaryBlue}`,
    backgroundColor: `${Color.secondaryBlue}`,
  },
  levelGoalInputContainer: {
    fontSize: '1.5em',
    fontWeight: 600,
    padding: '15px',
    margin: '30px 15px 15px 15px',
    border: `1px solid ${Color.secondaryGray}`,
    backgroundColor: Color.primaryGray,
    color: Color.primaryBlue,
  },
  levelGoalInputLabel: {},
  levelGoalInput: {
    fontSize: '1.2em',
    fontWeight: 500,
  },
};

const TimeEmphasis = styled.span`
  font-weight: 600;
  border-bottom: 3px solid ${Color.ceregoGreen};
`;

const RequirementsText = styled.p`
  text-align: center;
  margin-bottom: 25px;
`;

const UpperContainer = styled.div`
  display: flex;
`;

const LowerContainer = styled.div`
  border: 1px solid ${Color.primaryGray};
`;

const SliderContainer = styled.div`
  padding: 75px 50px;
`;

const marks = [
  {
    value: 0.0,
  },
  {
    value: 0.5,
  },
  {
    value: 1.0,
  },
  {
    value: 1.5,
  },
  {
    value: 2.0,
  },
  {
    value: 2.5,
  },
  {
    value: 3.0,
  },
];

const LearningOutcomeContainer = styled.div`
  flex: 2;
  border: 1px solid ${Color.primaryGray};
  text-align: left;
  padding: 15px;
  height: 13em;
`;

const AssignmentGoalForm = (props: IAssignmentGoalForm) => {
  const [goal, setGoal] = React.useState<number>(Number(props.assignmentGoal));
  const [bulkUpdate, setBulkUpdate] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const { t } = useTranslation();
  const assignmentCount = props.courseAssignmentIds.length;
  const { makeToast } = useStore();
  const [mutateAssignment, { status: mutationStatus }] = useMutateAssignment();

  const handleSliderChangeCommitted = (_event: any, newValue: number | number[]): void => {
    if (Array.isArray(newValue)) {
      setGoal(newValue[0]);
      return;
    }

    setGoal(newValue);
  };

  const MasteryRegions = () => {
    return (
      <div style={{ display: 'flex' }}>
        <MasteryRegion style={goal < 1 ? styles.activeRegion : {}}>{t('Exposure')}</MasteryRegion>
        <MasteryRegion style={goal >= 1 && goal < 2 ? styles.activeRegion : {}}>{t('Proficiency')}</MasteryRegion>
        <MasteryRegion style={goal >= 2 && goal <= 3 ? styles.activeRegion : {}}>{t('Mastery')}</MasteryRegion>
      </div>
    );
  };

  const GoalSlider = () => {
    return (
      <React.Fragment>
        <StyledSlider
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay="on"
          marks={marks}
          value={Number(goal)}
          getAriaValueText={valuetext}
          aria-labelledby="input-slider"
          step={0.1}
          min={0.0}
          max={3.0}
          onChangeCommitted={handleSliderChangeCommitted}
        />
        <MasteryRegions />
      </React.Fragment>
    );
  };

  const handleSingularUpdate = (assignmentId: string) => {
    mutateAssignment({
      ids: { setId: assignmentId, courseId: props.courseId },
      payload: { custom_scoring_target: goal },
    });
  };

  const handleBulkUpdate = async () => {
    const promises = props.courseAssignmentIds.map((assignmentId) => {
      return handleSingularUpdate(assignmentId);
    });
    await Promise.all(promises);
  };

  const handleConfirm = async () => {
    setPending(true);
    const goalCount: number = bulkUpdate ? props.courseAssignmentIds.length : 1;

    if (bulkUpdate) {
      await handleBulkUpdate();
    } else {
      handleSingularUpdate(props.assignment.id);
    }

    makeToast({
      text: t('Course Detail Page Goal Updated Text', { count: goalCount }),
      isError: false,
    });
  };

  React.useEffect(() => {
    switch (mutationStatus) {
      case 'success':
        props.onCancel();
        setPending(false);
        break;
      case 'loading':
        setPending(true);
        break;
      default:
        break;
    }
  }, [mutationStatus]);

  const ActionFooter = () => {
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Checkbox
              checked={bulkUpdate}
              onChange={() => setBulkUpdate(!bulkUpdate)}
              value="bulkUpdate"
              color="primary"
            />
          }
          label={t('Assignment Goal Form Label Text', { count: assignmentCount })}
        />

        <ConfirmOrCancel
          cancelText={t('Cancel')}
          confirmText={t('Update')}
          handleCancelClick={() => props.onCancel()}
          handleConfirmClick={handleConfirm}
          confirmIsDisabled={false}
          confirmIsPending={pending}
        />
      </React.Fragment>
    );
  };

  const getLearningOutcomeCopy = () => {
    if (goal < 1) {
      return {
        header: t('Exposure'),
        ticks: [t('Learners have engaged with each item')],
      };
    } else if (goal < 2) {
      return {
        header: t('Proficiency'),
        ticks: [t('Learners have engaged with each item'), t('Learners build lasting, transferable knowledge')],
      };
    } else if (goal <= 3) {
      return {
        header: t('Mastery'),
        ticks: [
          t('Learners have engaged with each item'),
          t('Learners build lasting, transferable knowledge'),
          t('Learners build accessible knowledge that drives behavior change'),
        ],
      };
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === '') {
      setGoal(Number(event.target.value));
    } else {
      let newValue = Number(parseFloat(event.target.value).toFixed(1));
      if (newValue > 3) {
        newValue = 3.0;
      } else if (newValue < 0) {
        newValue = 0.0;
      }
      setGoal(newValue);
    }
  };

  const CurrentGoal = () => {
    return (
      <CurrentGoalContainer>
        <LevelGoalInput
          level={goal}
          handleInputChange={handleInputChange}
          containerStyle={styles.levelGoalInputContainer}
          labelStyle={styles.levelGoalInputLabel}
          inputStyle={styles.levelGoalInput}
        />
        <h4>{t('Current Goal')}</h4>
      </CurrentGoalContainer>
    );
  };

  const LearningOutcome = () => {
    const learningOutcomeCopy = getLearningOutcomeCopy();
    const header = learningOutcomeCopy ? learningOutcomeCopy.header : '';
    const ticks = learningOutcomeCopy ? learningOutcomeCopy.ticks : [];

    return (
      <LearningOutcomeContainer>
        <p>{t('Learning Outcome: ')}</p>
        <h2>{header}</h2>
        <ul style={{ padding: '0' }}>
          {ticks.map((tick) => (
            <li key={tick} style={{ listStyleType: 'none', margin: '5px 0' }}>
              <FontAwesomeIcon icon={faCheck} style={{ color: `${Color.primaryBlue}`, marginRight: '15px' }} />
              {tick}
            </li>
          ))}
        </ul>
      </LearningOutcomeContainer>
    );
  };

  const getCommittmentCopy = () => {
    // TODO: should this be refactored to make i18n make more sense, or is it ok as is?
    if (goal < 1.0) {
      return {
        reqA: t('Requires'),
        timeCommittment: t('a few days'),
        reqB: t('of review'),
      };
    } else if (goal < 1.5) {
      return {
        reqA: t('Requires'),
        timeCommittment: t('up to a week'),
        reqB: t('of brief daily reviews'),
      };
    } else if (goal < 2) {
      return {
        reqA: t('Requires up to'),
        timeCommittment: t('1-2 weeks'),
        reqB: t('of brief daily reviews'),
      };
    } else if (goal < 2.5) {
      return {
        reqA: t('Requires about'),
        timeCommittment: t('2-3 weeks'),
        reqB: t('of brief reviews (spaced increasingly further apart)'),
      };
    } else if (goal <= 3) {
      return {
        reqA: t('Requires about'),
        timeCommittment: t('3-4 weeks'),
        reqB: t('of brief reviews (spaced increasingly further apart)'),
      };
    } else {
      return {
        reqA: t('Requires about'),
        timeCommittment: t('4 weeks'),
        reqB: t('of brief reviews (spaced increasingly further apart)'),
      };
    }
  };

  const CommittmentCopy = () => {
    const { reqA, timeCommittment, reqB } = getCommittmentCopy();

    return (
      <div>
        <RequirementsText>
          {reqA} <TimeEmphasis>{timeCommittment}</TimeEmphasis> {reqB}
        </RequirementsText>
      </div>
    );
  };

  return (
    <PageContainer>
      <ContentContainer>
        <AssignmentFormHeader assignmentName={props.assignment.name} formTitle={t('Set your mastery goal')} />

        <MainContentContainer>
          <UpperContainer>
            <CurrentGoal />
            <LearningOutcome />
          </UpperContainer>

          <LowerContainer>
            <SliderContainer>
              <GoalSlider />
            </SliderContainer>
            <CommittmentCopy />
          </LowerContainer>
        </MainContentContainer>

        <ActionFooter />
      </ContentContainer>
    </PageContainer>
  );
};

export default AssignmentGoalForm;
