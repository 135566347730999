import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import getMedian from 'utils/getMedian';
import roundNumberUp from 'utils/roundNumberUp';
import XTicks from './Atoms/XTicks';
import { COLOR, FILL, STROKE } from './constants';

export default function MemreDensityPlot({ data }) {
  const chartRef = useRef<HTMLDivElement>(null);

  const median = getMedian(data);

  const numBins = 10;
  const minX = Math.min(...data);
  const maxX = roundNumberUp(Math.max(...data, minX + 1));
  const binWidth = (maxX - minX) / numBins;

  const bins = Array.from({ length: numBins }, (_, i) => ({
    x: minX + i * binWidth + binWidth / 2,
    y: 0,
  }));

  const x1 = Math.min(...bins.map((d) => d.x));
  const x2 = Math.max(...bins.map((d) => d.x));

  data.forEach((value) => {
    const binIndex = Math.min(Math.floor((value - minX) / binWidth), numBins - 1);
    bins[binIndex].y += 1;
  });

  bins.unshift({ x: x1 - 1, y: 0 });
  bins.push({ x: x2 + 1, y: 0 });

  useEffect(() => {
    const maxY = Math.max(...bins.map((d) => d.y));

    const lowerQuartile = d3.quantile(data, 0.25);
    const midPoint = d3.quantile(data, 0.5);
    const upperQuartile = d3.quantile(data, 0.75);

    const chart = Plot.plot({
      marks: [
        Plot.rectY([{}], {
          x1: 0,
          x2: maxX,
          y1: 0,
          y2: maxY * 1.3,
          fill: FILL.LIGHT_BLUE,
        }),
        Plot.areaY(bins, {
          x: 'x',
          y1: 0,
          y2: 'y',
          fill: FILL.DARK_BLUE,
          curve: 'monotone-x',
        }),
        Plot.ruleX([median], {
          stroke: COLOR.DARK_GREEN,
          strokeWidth: STROKE.WIDTH.SMALL,
        }),
        Plot.ruleY([0], {
          x1,
          x2,
          stroke: STROKE.COLOR.LIGHT,
          strokeWidth: STROKE.WIDTH.SMALL,
        }),
        ...XTicks({ start: _.round(maxX / 5), stop: _.round(maxX / 1.2) }),
      ],
      height: 90,
      width: 400,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      x: {
        domain: [0, maxX],
      },
    });

    if (chartRef.current) {
      chartRef.current?.appendChild(chart);
    }

    return () => {
      chart.remove();
    };
  }, [data]);

  return <div ref={chartRef} />;
}
