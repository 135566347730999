import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGroups } from 'hooks/useGroups';
import React, { useState } from 'react';
import { TGroup } from 'types';
import { useReportStore } from 'zstore';

export const GroupFilter = () => {
  const group = useReportStore((state) => state.group);
  const setGroup = useReportStore((state) => state.setGroup);
  const setPage = useReportStore((state) => state.setPage);
  const { data: groups, isLoading } = useGroups();
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState(groups?.find((g: TGroup) => g.name === group) || '');
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id="group-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={isLoading}
      value={value}
      onChange={(_event: any, newValue: any) => {
        if (!!newValue) {
          setValue(newValue);
          setGroup(newValue.name);
          setPage(1);
        } else {
          setValue(null);
          setGroup(null);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      options={groups?.sort((a: TGroup, b: TGroup) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) || []}
      getOptionLabel={(option: any) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ margin: '20px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select group"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      selectOnFocus
    />
  );
};
