import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import learnerBubbles from 'assets/images/learner-bubbles.svg';
import axios from 'axios';
import { BillingOption } from 'components/BillingOption';
import { CancelButton } from 'components/buttons/v4';
import { ModalPosition } from 'components/Modal';
import { Color } from 'core';
import { Card, CardHeaderContainer, PageTitle } from 'core/layout';
import { MINIMUM_LICENSE_QUANTITY, STRIPE_KEY } from 'models/account';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from 'store/profile';
import styled from 'styled-components';
import { REACT_APP_ENV } from 'utils/environmentUtils';
import { normalizeJSONAPIResponse } from 'utils/modelUtils';
import { getBaseReactUrl } from 'utils/pathUtils';
import { AnalyticsService } from 'utils/AnalyticsService';
import { List, ListIcon, ModalHeader, SpacedOut } from './common';

const stripePromise = loadStripe(STRIPE_KEY);

interface IProps {
  isSuccess: boolean;
  onClose: () => void;
}

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

const BottomCardSection = styled.div`
  background: white;
  padding: 0;
`;

const BillingOptionsContainer = styled.span`
  display: flex;
  justify-content: space-between;
`;

const Separator = styled.div`
  background: ${Color.veryLightGray};
  width: 1px;
`;

const BillingOptionContainer = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  width: 50%;
  justify-content: center;
`;

const PaymentDetailsContainer = styled.span`
  display: flex;
  text-align: center;
  color: ${Color.textGray};
  margin: 0;
  font-size: 0.8em;
  justify-content: center;
  padding-bottom: 1rem;
`;

const PricingLink = styled.a`
  color: ${Color.primaryBlue};
  cursor: pointer;
  text-decoration: none;
`;

const LearnerBubblesIcon = styled.img`
  display: flex;
  position: absolute;
  right: -3em;
  bottom: 3.5em;
`;

const BillingList = styled(List)`
  margin-left: 3.65em;
`;

const BillingHeaderContainer = styled(CardHeaderContainer)`
  position: relative;
`;

const EducationPaymentContainer = styled(PaymentDetailsContainer)`
  padding-top: 0;
  padding-bottom: 1.5rem;
`;

const MONTHLY_PLAN_ID =
  REACT_APP_ENV === 'production' ? 'cerego_standard_10_per_month_prod' : 'cerego_standard_10_per_month_test';
const ANNUAL_PLAN_ID =
  REACT_APP_ENV === 'production' ? 'cerego_standard_100_per_year_prod' : 'cerego_standard_100_per_year_test';

export const Billing = (props: IProps) => {
  const currentUser = useSelector((state) => getCurrentUser(state)) || {};
  const stripe = useStripe();
  const location = useLocation();
  const { t } = useTranslation();

  const handleMonthlyClick = () => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Monthly plan' });
    redirectToStripe(MONTHLY_PLAN_ID);
  };

  const handleAnnualClick = () => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'Annual plan' });
    redirectToStripe(ANNUAL_PLAN_ID);
  };

  const handlePricingPageClick = () => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'View pricing page' });
    window.open('https://www.cerego.com/pricing', '_blank');
  };

  const handleEducationLinkClick = () => {
    AnalyticsService.getInstance().track('button_clicked', { button_type: 'View EDU pricing' });
    window.open('https://www.cerego.com/lp-education-pricing-request', '_blank');
  };

  const redirectToStripe = async (plan: string) => {
    const userCount = await getPartnerUserCount();
    const quantity = Math.max(userCount, MINIMUM_LICENSE_QUANTITY);

    const stripeSessionResult = await createCheckoutSession(plan, quantity);
    const id = stripeSessionResult.data.id;
    if (!stripe) {
      throw new Error('Cannot redirect to checkout with null stripe object');
    }

    const res = await stripe.redirectToCheckout({
      sessionId: id,
    });
    // from stripe: if `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
    if (res.error.message) {
      console.error('res error ', res.error);
      // TODO: display error message
    }
  };

  const getPartnerUserCount = async () => {
    const quantityResult = await axios.get(`/api/v3/partners/${currentUser.primaryPartner.id}/summary_counts`);
    const summaries = normalizeJSONAPIResponse(quantityResult.data);
    return summaries.usersCount;
  };

  const createCheckoutSession = async (plan: string, quantity: number) => {
    const data = {
      successUrl: `${getBaseReactUrl()}/app${location.pathname}?billingStatus=success`,
      cancelUrl: `${getBaseReactUrl()}/app${location.pathname}?billingStatus=cancel`,
      plan,
      quantity,
      id: currentUser.primaryPartner.id,
    };
    const stripeSessionResult = await axios.post(`/api/v4/stripe/checkouts`, data);
    return stripeSessionResult;
  };

  return (
    <Elements stripe={stripePromise}>
      <ModalPosition top={1.5}>
        <ModalHeader>
          <CenterTitle>
            <b>{t('Upgrade your learning!')}</b>
          </CenterTitle>
        </ModalHeader>
        <Card>
          <BillingHeaderContainer>
            <BillingList>
              <li>
                <ListIcon color={Color.ceregoGreen}>
                  <FontAwesomeIcon icon={faCheck} />
                </ListIcon>
                {t('Unlimited courses and assignments')}
              </li>
              <li>
                <ListIcon color={Color.ceregoGreen}>
                  <FontAwesomeIcon icon={faCheck} />
                </ListIcon>
                {t('Native iOS and Android apps')}
              </li>
              <li>
                <ListIcon color={Color.ceregoGreen}>
                  <FontAwesomeIcon icon={faCheck} />
                </ListIcon>
                {t('Onboarding and web-based customer success')}
              </li>
            </BillingList>
            <LearnerBubblesIcon className="bubbles" src={learnerBubbles} />
          </BillingHeaderContainer>
          <BottomCardSection>
            {!props.isSuccess && (
              <BillingOptionsContainer>
                <BillingOptionContainer>
                  <BillingOption
                    title={t('Monthly Plan')}
                    subtitle={t('Pay as you go, cancel any time')}
                    dollarAmount="10"
                    centAmount="00"
                    buttonText={t('Pay Monthly')}
                    click={handleMonthlyClick}
                    showButton={!!currentUser.primaryPartner}
                  />
                </BillingOptionContainer>
                <Separator />
                <BillingOptionContainer>
                  <BillingOption
                    title={t('Annual Plan')}
                    subtitle={t('Subject to annual agreement')}
                    dollarAmount="8"
                    centAmount="33"
                    buttonText={t('Pay Annually')}
                    click={handleAnnualClick}
                    showButton={!!currentUser.primaryPartner}
                  />
                </BillingOptionContainer>
              </BillingOptionsContainer>
            )}
            {/* TODO: replace copy / UI after billing success*/}
            {props.isSuccess && <CenterTitle>{t('Your upgrade was successful!')}</CenterTitle>}
          </BottomCardSection>
          <PaymentDetailsContainer>
            <b>{t('User Minimum Text', { count: MINIMUM_LICENSE_QUANTITY })}</b>&nbsp;
            {t('commitment required. For details visit our')}&nbsp;
            <PricingLink onClick={handlePricingPageClick}>{t('pricing page')}</PricingLink>.
          </PaymentDetailsContainer>
          <EducationPaymentContainer>
            {t('Are you a qualified educational institution?')}
            <PricingLink onClick={handleEducationLinkClick}>&nbsp;{t('Contact us')}&nbsp;</PricingLink>
            {t('for education pricing.')}
          </EducationPaymentContainer>
        </Card>
        <SpacedOut>
          <CancelButton click={props.onClose}>{props.isSuccess ? t('Done') : t('Cancel')}</CancelButton>
        </SpacedOut>
      </ModalPosition>
    </Elements>
  );
};
