import { useCurrentUser } from 'hooks/useCurrentUser';

// Checks if the current user is a user in the passed partner or any of their departments
export const useIsInPartner = (partnerIds: Array<number>) => {
  const user = useCurrentUser();
  const isInPartner =
    partnerIds.includes(parseInt(user?.primaryPartner?.id)) ||
    partnerIds.includes(parseInt(user?.primaryPartner?.parentId));
  return isInPartner;
};
