import axios from 'axios';
import { useGroupCoursesStore } from 'pages/admin-dashboard/groups/store';
import { useQuery } from 'react-query';

const getGroupCourses = async (
  _key: string,
  groupId: string,
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
  name: string = '',
  statusFilter: string = 'published'
) => {
  const endpoint = `/api/v3/groups/${groupId}/courses`;
  const sortParam = sortDirection === 'desc' ? `-${sortColumn}` : sortColumn;
  const queryParams = `filter[state]=${statusFilter}&page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sortParam}&query=${name}&tab=${statusFilter}`;

  try {
    const response = await axios.get(`${endpoint}?${queryParams}`);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const useGroupCourses = (groupId: string) => {
  const { pageNumber, pageSize, sortColumn, sortDirection, name, statusFilter } = useGroupCoursesStore();

  return useQuery(
    ['groupCourses', groupId, pageNumber, pageSize, sortColumn, sortDirection, name, statusFilter],
    getGroupCourses
  );
};
