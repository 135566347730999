import axios from 'axios';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { queryCache, useMutation } from 'react-query';

const createCourse = (payload: any) => {
  return axios.post('/api/v3/courses', payload);
};

const useCreateCourse = () => {
  const user = useCurrentUser();
  const partnerId = user?.primaryPartner.id;

  return useMutation(createCourse, {
    onSettled: () => {
      queryCache.invalidateQueries(['courses', partnerId]);
    }
  });
};

export default useCreateCourse;
