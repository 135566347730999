export function getTitleTextKey(goalType): string {
  let key;
  switch (goalType) {
    case 'assessment':
      key = 'QUIZ';
      break;
    case 'survey':
      key = 'SURVEY';
      break;
    case 'set':
      key = 'PRACTICE IT';
      break;
  }
  return key;
}

export function getICTitleTextKey(goalType): string {
  let key;
  switch (goalType) {
    case 'assessment':
      key = 'STUDY';
      break;
    case 'survey':
      key = 'SURVEY';
      break;
    case 'set':
      key = 'STUDY IT';
      break;
  }
  return key;
}
