import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGroups } from 'hooks/useGroups';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TGroup } from 'types';
import { LearnersTableState, useLearnersTableStore } from 'zstore';
import { TableSearchBar } from './tables/TableSearchBar';

export const LearnersTableToolbar = () => {
  const setPage = useLearnersTableStore((state: LearnersTableState) => state.setPage);
  const name = useLearnersTableStore((state: LearnersTableState) => state.name);
  const setName = useLearnersTableStore((state: LearnersTableState) => state.setName);
  const { t } = useTranslation();

  return (
    <ToolbarContainer>
      <TableSearchBar
        value={name}
        onChange={setName}
        resetPage={() => setPage(1)}
        placeholder={t('Search by name')}
        style={{ width: '100%' }}
      />
      <GroupFilter />
    </ToolbarContainer>
  );
};

const GroupFilter = () => {
  const group = useLearnersTableStore((state: LearnersTableState) => state.group);
  const setGroup = useLearnersTableStore((state: LearnersTableState) => state.setGroup);
  const { data: groups, isLoading } = useGroups();
  const [value, setValue] = useState<any>(groups?.find((g: TGroup) => g.name === group) || null);
  const [inputValue, setInputValue] = useState(groups?.find((g: TGroup) => g.name === group) || '');
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id="group-filter"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={isLoading}
      value={value}
      onChange={(_event: any, newValue: any) => {
        if (!!newValue) {
          setValue(newValue);
          setGroup(newValue.name);
        } else {
          setValue(null);
          setGroup(null);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      options={groups?.sort((a: TGroup, b: TGroup) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) || []}
      getOptionLabel={(option: any) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select group"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const ToolbarContainer = styled.div`
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
  grid-gap: 0em 1em;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;
