import { MemreContainer, MemreSection, MemreSectionHeading } from 'components/core';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useIdealNextStudySession from 'hooks/useIdealNextStudySession';
import React from 'react';
import IdealDays from './IdealDays';
import IdealDuration from './IdealDuration';
import IdealTime from './IdealTime';

export default function IdealNextSession() {
  const user = useCurrentUser();
  const { idealDay, retentionLevels, idealTime, idealDuration } = useIdealNextStudySession(user.id);
  return (
    <MemreSection>
      <MemreSectionHeading text="Ideal Next Study Session" />
      <MemreContainer
        size="large"
        direction={{
          xs: 'column',
          lg: 'row',
        }}
      >
        {idealDay && <IdealDays idealDay={idealDay} />}
        {retentionLevels && <IdealTime retentionLevels={retentionLevels} time={idealTime} />}
        <IdealDuration idealDuration={idealDuration} />
      </MemreContainer>
    </MemreSection>
  );
}
