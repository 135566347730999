import { TableSearchBar } from 'components/tables/TableSearchBar';
import { useGroupMembersStore } from 'pages/admin-dashboard/groups/store';
import styled from 'styled-components';

export const GroupMembersTableToolbar = () => {
  const { setPageNumber, name, setName } = useGroupMembersStore();

  return (
    <ToolbarContainer>
      <TableSearchBar
        value={name}
        onChange={setName}
        resetPage={() => setPageNumber(1)}
        placeholder="Search members..."
      />
    </ToolbarContainer>
  );
};

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
`;
