import Bricks from 'bricks.js';
import { Color, Size } from 'core';
import React from 'react';
import styled from 'styled-components';

const gutter = 50;
const Wall = styled.div`
  margin: ${gutter / 4}px auto;
`;
const Brick = styled.div`
  color: ${Color.textGray};
  width: ${Size.defaultCardWidth / 2 - gutter}px;
  padding: ${gutter / 4}px;
  padding-left: ${gutter / 4 - 4}px;
  border-left: 4px transparent solid;
  border-radius: 3px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.25s ease;
  transition-properties: box-shadow, border-left;

  &:hover {
    color: ${Color.lightBlack}
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
    border-left: 4px ${Color.ceregoGreen} solid;
  }
`;
const User = styled.div`
  margin-top: 1em;
  padding-top: 0.5em;
  border-top: 1px ${Color.lightGray} dotted;
  font-size: 0.8rem;
  font-weight: 700;
  font-style: italic;
  text-align: right;
`;

interface IOwnProps {
  data: any[];
  chartKey: string;
  title?: string;
  height?: string;
}
interface IState {
  packed: string;
  sizes: object[];
}

export class FreeResponse extends React.Component<IOwnProps, IState> {
  public brickwall;

  constructor(props) {
    super(props);

    this.state = {
      packed: 'data-packed',
      sizes: [
        { columns: 2, gutter: gutter / 2 },
        { mq: '1024px', columns: 3, gutter: gutter / 2 },
        { mq: '1400px', columns: 4, gutter: gutter / 2 }
      ]
    };
  }

  public initializeBricks() {
    const { chartKey } = this.props;
    const { packed, sizes } = this.state;
    const instance = Bricks({
      position: false,
      container: `#${chartKey}`,
      packed,
      sizes,
      useWindowForScroll: true
    });

    return instance;
  }

  public componentDidMount() {
    if (this.props.data.length <= 0) {
      return;
    }

    this.brickwall = this.initializeBricks();
    this.brickwall.resize('true');
    this.brickwall.pack();
  }

  public render() {
    const { data, chartKey } = this.props;
    const bricks = data.map((record: any, i) => (
      <Brick key={'brick_' + i + '_' + chartKey}>
        <q>{record.response}</q>
        <User>{record.user}</User>
      </Brick>
    ));

    return <Wall id={chartKey}>{bricks}</Wall>;
  }
}
