import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import { ConfirmOrCancel } from 'components/forms/ConfirmOrCancel';
import { ModalPosition } from 'components/Modal';
import { ModalHeader } from 'components/modals/common';
import { Color } from 'core';
import { Card, CardHeader, CardHeaderContainer, PageTitle } from 'core/layout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import build from 'redux-object';
import { handleGetCourseGroups } from 'store/course-group';
import { handleGetGroups } from 'store/group';
import { handleGetLearnerStats } from 'store/learner-stats';
import styled from 'styled-components';

const CenterTitle = styled(PageTitle)`
  width: 100%;
  text-align: center;
  padding: 1em 0 0.5em;
`;

interface IProps {
  courseId: string;
  partnerId: string | null;
  isPublished: boolean;
  close: () => void;
}

export const AddLearnersByGroup = React.memo<IProps>((props) => {
  const dispatch = useDispatch();
  const groups = useSelector(getGroups) || [];
  const courseGroupIds = useSelector(getCourseGroupIds) || [];
  const [groupIdsAdded, setGroupIdsAdded] = useState<string[]>([]);
  const checkedGroupIds = [...courseGroupIds, ...groupIdsAdded];
  const { t } = useTranslation();

  function getGroups(state: any) {
    return build(state.data, 'groups');
  }

  function getCourseGroupIds(state: any) {
    return state.data.meta[`/api/v3/courses/${props.courseId}/groups`]?.data?.map((group: any) => group.id);
  }

  function handleGroupClick(groupId: any) {
    setGroupIdsAdded((groupIdsAdded) => [...groupIdsAdded, groupId]);
  }

  function addGroups() {
    groupIdsAdded.map(async (groupId) => {
      await axios.post(`/api/v3/courses/${props.courseId}/groups`, { id: groupId });
      dispatch(handleGetLearnerStats(props.courseId));
    });
    props.close();
  }

  useEffect(() => {
    dispatch(handleGetCourseGroups(props.courseId));
    dispatch(handleGetGroups(props.partnerId));
  }, [props.partnerId, props.courseId, dispatch]);

  return (
    <ModalPosition top={1.5}>
      <ModalHeader>
        <CenterTitle>{t('Manage Learners by Group')}</CenterTitle>
      </ModalHeader>
      <Card>
        <CardHeaderContainer>
          <CardHeader>{t('Add a Group of Learners to Your Course')}</CardHeader>
        </CardHeaderContainer>

        <div>
          {groups.length > 0 &&
            groups.map((group: any, i: number) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={checkedGroupIds.indexOf(group.id) !== -1}
                    disabled={checkedGroupIds.indexOf(group.id) !== -1}
                    onChange={() => handleGroupClick(group.id)}
                    style={{ padding: '12px', color: Color.primaryBlue, fontSize: '0.75em' }}
                    data-type="checkbox"
                  />
                }
                label={
                  <Typography style={{ fontSize: '18px' }}>
                    {t('Add Learners By Group Label Text', { groupName: group.name, count: group.membersCount })}
                  </Typography>
                }
              />
            ))}
        </div>
      </Card>
      <ConfirmOrCancel
        confirmText={t('Add Groups')}
        handleConfirmClick={addGroups}
        confirmIsDisabled={false}
        confirmIsPending={false}
        handleCancelClick={props.close}
      />
    </ModalPosition>
  );
});
