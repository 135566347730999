import { Box, Container, Typography } from '@mui/material';
import * as Color from 'core/colors';
import { Link } from 'react-router-dom';
import { MemreFlexBox, MemreIcon } from './index';

export default function MemreHeader({ text, backLink }: { text: string; backLink?: string }) {
  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: Color.hexToRGB(Color.reportBlue, 0.2, 0.4),
        mb: 4,
        py: 2,
      }}
    >
      <Container maxWidth="lg" fixed={true} sx={{ mx: 'auto' }}>
        <MemreFlexBox direction="row" align="center" gap={3} sx={{ px: 3 }}>
          {backLink && (
            <Link to={backLink}>
              <MemreIcon iconName="back" color={Color.hexToRGB(Color.reportBlue, 1, 0.7)} fontSize="1.2em" />
            </Link>
          )}
          <Typography variant="h4" sx={{ color: Color.hexToRGB(Color.reportBlue, 1, 0.7), fontWeight: 500 }}>
            {text}
          </Typography>
        </MemreFlexBox>
      </Container>
    </Box>
  );
}
